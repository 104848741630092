import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "./Modal";

const GuestDeletionCard = ({ user }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("delete");
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  console.log(user)

  const handleDeleteClick = () => {
    setModalType("delete");
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleConfirmDelete = () => {
    setShowModal(false);
    console.log("User deleted");
  };

  const handleReasonClick = () => {
    setModalType("reason");
    setShowModal(true);
  };

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const UpArrow = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="20" height="20">
      <path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L204 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"/>
    </svg>
  );

  const DownArrow = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="20" height="20">
      <path d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L204 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/>
    </svg>
  );

  const showApprove = user.status === "Pending";
  const showCancel = user.status === "Pending";
  const showCancellationDetails = user.status === "Cancelled";
  const showReasonButton = user.status === "Cancelled" || user.status === "Pending" || user.status === "Approved";
  const showApprovedDate = user.status === "Approved";

  const cardStyle = {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    minWidth: 0,
    wordWrap: 'break-word',
    backgroundColor: 'white',
    backgroundClip: 'border-box',
    border: '1px solid rgba(0, 0, 0, 0.125)',
    borderRadius: '0.5rem',
    marginBottom: '1.75rem',
    marginTop: '1.5rem'
  };

  const cardHeaderStyle = {
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.125)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1rem'
  };

  const cardBodyStyle = {
    flex: '1 1 auto',
    minHeight: '1px',
    padding: '1.25rem'
  };

  const userInfoStyle = {
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
    padding: '0.75rem',
    border: '1px solid #f3f4f6',
    borderRadius: '0.375rem',
    display: 'flex',
    margin: '0'
  };

  const buttonStyle = {
    borderRadius: '0.375rem',
    padding: '0.5rem 1rem',
    fontSize: '1.7rem',
    color: 'white',
    border: 'none'
  };

  const gridStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '0.5rem',
    padding: '0.5rem',
  };

  const gridItemStyle = {
    display: 'flex',
    flexDirection: 'column',
  };

  return (
    <div style={cardStyle}>
      <div style={cardHeaderStyle}>
        <div style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          gap: '1rem',
          width: '100%',
          fontSize: '16px'
        }}>
          <p><strong>Name:</strong> {user.name}</p>
          <p><strong>Email:</strong> {user.email}</p>
          <p><strong>Phone:</strong> {user.phone}</p>
          <p><strong>Status:</strong> {user.status}</p>
        </div>
        <div style={{fontSize: '1.675rem', marginRight: '0.75rem', cursor: 'pointer'}} onClick={toggleExpanded}>
          {expanded ? <UpArrow/> : <DownArrow/>}
        </div>
      </div>

      {expanded && (
        <div style={cardBodyStyle}>
          <div style={userInfoStyle}>
            <div style={{flexShrink: 0, width: '10rem'}}>
              <img src={user.photo} alt="User" style={{marginTop: '0.75rem', marginLeft: '0.5rem', width: '80px', height: '80px', borderRadius: '50%'}} />
            </div>
            <div style={{flexGrow: 1, ...gridStyle}}>
              <div style={gridItemStyle}>
                <p><strong>Name:</strong> {user.name}</p>
                <p><strong>Email:</strong> {user.email}</p>
                <p><strong>Phone:</strong> {user.phone}</p>
                <p><strong>Gender:</strong> {user.gender}</p>
                <p><strong>DOB:</strong> {user.dob}</p>
                <p><strong>Document Number:</strong> {user.documentNumber}</p>
              </div>
              <div style={gridItemStyle}>
              <p><strong>Guest Id:</strong> {user.guestid}</p>
                <p><strong>Age:</strong> {user.age}</p>
                <p><strong>Status:</strong> {user.status}</p>
                <p><strong>Address:</strong> {user.address}</p>
                <p><strong>Verified with Document:</strong> {user.verified}</p>
                
                {showCancellationDetails && (
                  <>
                    <p><strong>Reason for Cancellation:</strong> {user.reason}</p>
                    <p><strong>Cancelled on:</strong> {user.cancelDate}</p>
                  </>
                )}
                {showApprovedDate && (
                  <p><strong>Approved on:</strong> {user.approveDate}</p>
                )}
              </div>
            </div>
          </div>

          <div style={{display: 'flex', gap: '0.5rem', marginTop: '1rem', justifyContent: 'flex-end'}}>
            {showReasonButton && (
              <button style={{...buttonStyle, backgroundColor: '#D6B156'}} onClick={handleReasonClick}>
                <h5><strong>Reason</strong></h5>
              </button>
            )}
            {showApprove && (
              <button style={{...buttonStyle, backgroundColor: '#20c55e'}}>
                <h5><strong>Approve Request</strong></h5>
              </button>
            )}
            {showCancel && (
              <button style={{...buttonStyle, backgroundColor: '#ef4444'}} onClick={handleDeleteClick}>
                <h5><strong>Cancel Request</strong></h5>
              </button>
            )}
          </div>
        </div>
      )}
      <Modal
        isVisible={showModal}
        onClose={handleCloseModal}
        onConfirm={handleConfirmDelete}
        type={modalType}
      />
    </div>
  );
};

export default GuestDeletionCard;