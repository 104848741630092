// SubscriptionPlans.js
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { HotelSubcriptionListAction, SetCustomSubscriptionForHotelAction, AddSubcriptionToHotelAction } from "../redux/actions/subscriptionAction.js";
import NoDataFound from "./noDataFound";
import Loader from "./loader";

import Header from "./header";
import Sidenav from "./sidenav";
import { Link } from "react-router-dom";

const HotelSubscriptions = () => {
  const [isCustomSubscriptionModalOpen, setIsCustomSubscriptionModalOpen] = useState(false);
  const [hotelSubscriptions, setHotelSubscriptions] = useState([]);
  var [customSubscriptionData, setCustomSubscriptionData] = useState({});
  const [hotelID, setHotelID] = useState(null);
  const dispatch = useDispatch();

  const {hotelsubscrptionList, loader} = useSelector(
    (state) => state.subscriptionReducer
  );

  useEffect(() => {
    dispatch(HotelSubcriptionListAction())
  },[]);

  console.log("Hotel subs list", hotelsubscrptionList);

  const getDate = (date) => {
    // console.log("this is date", date, typeof date);
    date = new Date(date);
    const day = date.getDate();
    const month = date.getMonth() + 1; // +1 because getMonth() returns 0-11
    const year = date.getFullYear();

    const formattedDate = `${day.toString().padStart(2, '0')}-${month.toString().padStart(2, '0')}-${year}`;
    return formattedDate;
  }

  const handleCustomSubsModalOpen = (e, item) => {
    setIsCustomSubscriptionModalOpen(true);
    setHotelSubscriptions(item?.hotel?.subscriptionDetails)
    setHotelID(item?.hotel._id);
  }
  const handleCustomSubsModalClose = (e) => {
    if(e){
      e.preventDefault();
    }
    setIsCustomSubscriptionModalOpen(false);
    setHotelSubscriptions([]);
    setHotelID(null);
    setCustomSubscriptionData({});
  }

  console.log("Hotel Subs", hotelSubscriptions);

  const handleUpdateToCustomSubscription = (e) => {
    e.preventDefault();
    customSubscriptionData = {...customSubscriptionData, hotelId: hotelID}

    dispatch(
      SetCustomSubscriptionForHotelAction ( customSubscriptionData )
    ).then((res) => {
      if (res.status===200){
        // to fetch updated details
        dispatch(HotelSubcriptionListAction())
        toast.success(`Custom Subscription Added`,
          {position: toast.POSITION.TOP_RIGHT},
        );
      }
      else {
        console.log(res);
        toast.error(`${res.response.data.message}`,
          {position: toast.POSITION.TOP_RIGHT},
        );
      }
    }).catch(error => {
      toast.error(`Something Went Wrong`,
        {position: toast.POSITION.TOP_RIGHT,}
      );
    });

    handleCustomSubsModalClose(e);
  }

  const handleAddRegularSubscription = (e) => {
    e.preventDefault();

    customSubscriptionData = {...customSubscriptionData, hotelId: hotelID}

    dispatch(
      AddSubcriptionToHotelAction ( customSubscriptionData )
      ).then((res) => {
        if (res.status===200){
          dispatch(HotelSubcriptionListAction())
          toast.success(`Regular Subscription Added`,
            {position: toast.POSITION.TOP_RIGHT},
          );
        }
        else {
          console.log(res);
          toast.error(`${res.response.data.message}`,
            {position: toast.POSITION.TOP_RIGHT},
          );
        }
      }).catch(error => {
        toast.error(`Something Went Wrong`,
          {position: toast.POSITION.TOP_RIGHT,}
        );
      });

    handleCustomSubsModalClose(e);
  }

  return (
    <>
        <Header />
        <Sidenav />
        <div class="WrapperArea">
            <div class="WrapperBox">
            <div class="Small-Wrapper">
                <div class="NewTitleBox">
                  <div className="arrow-left">
                    <Link to="#" onClick={() => window.history.back()}>
                      <i class="fa fa-arrow-left arrow-back" aria-hidden="true"></i>
                    </Link>
                    <h4 className="Title"> Hotel Subscriptions </h4>
                  </div>
                
                </div>

                {loader ? (<Loader/>) :
                <div class="TableList">
                <table style={{ width: "130%" }}>
                  <thead>
                    <tr>
                      <th>Hotel ID</th>
                      <th>Hotel Name</th>
                      <th>Hotel Details</th>
                      <th>Hotel Phone</th>
                      <th>Total Rooms</th>
                      <th>Subscripton Id</th>
                      <th>Subscription Name</th>
                      <th>Subscription Start Date</th>
                      <th>Subscription Duration</th>
                      <th>Subscription End Date</th>
                      <th>Subscription Details</th>
                      <th>Subscription Status</th>
                      <th>Custom Subscription</th>
                      <th>customize Subscription</th>
                    </tr>
                  </thead>
                  <tbody>
                    {hotelsubscrptionList.map((item, i) => (
                      <tr>
                        <td>{item?.hotel.hotelId}</td>
                        <td>{item?.hotel.name}</td>
                        <td>
                          <div class="Actions">
                          <Link
                              class="Blue"
                              to={`/hotel-details/${item?.hotel._id}`}
                              state={item}
                          >
                            <i class="fa fa-eye"></i>
                          </Link>
                          </div>
                        </td>
                        <td>{item?.hotel.phone}</td>
                        <td>{item?.hotel.totalRooms}</td>
                        <td>
                        {item?.hotelSubscriptionDetail.is_active ? item?.hotelSubscriptionDetail.currentSubscription?.[0].subscriptionDetails.subscriptionId : "-"}
                        </td>
                        <td>
                          {item?.hotelSubscriptionDetail.is_active ? item?.hotelSubscriptionDetail.currentSubscription?.[0].subscriptionDetails.name : "-"}
                        </td>
                        <td>
                          {item?.hotelSubscriptionDetail.is_active ? getDate(item?.hotelSubscriptionDetail.currentSubscription?.[0].startDate) : "-"}
                        </td>
                        <td>
                          {item?.hotelSubscriptionDetail.is_active ? item?.hotelSubscriptionDetail.currentSubscription?.[0].subscriptionDetails.durationInDays : "-"}
                        </td>
                        <td>
                          {item?.hotelSubscriptionDetail.is_active ? getDate(item?.hotelSubscriptionDetail.currentSubscription?.[0].endDate) : "-"}
                        </td>
                        <td>
                          <div class="Actions">
                          <Link
                              class="Blue"
                              to={`/subscription-hotel-details`}
                              state={{hotelId: item?.hotel._id}}
                          >
                            <i class="fa fa-eye"></i>
                          </Link>
                          </div>
                        </td>
                        <td>
                        <div class="Actions">
                          <button
                            disabled
                            className={
                                item?.hotelSubscriptionDetail.is_active ? "subscriptionActive" : "subscriptionInactive"
                            }
                          >
                            {item?.hotelSubscriptionDetail.is_active  ? "Active" : "Inactive"} 
                          </button>
                        </div>
                        </td>
                        <td>
                        <div class="Actions">
                        <button
                            disabled
                            className={
                                item?.hotel.customisedSubscription ? "subscriptionActive" : "subscriptionInactive"
                            }
                          >
                            {item?.hotel.customisedSubscription ? "Yes" : "No"} 
                          </button>
                        </div>
                        </td>
                        <td>
                          <div class="Actions">
                            <Link class="Green" onClick={(e) => handleCustomSubsModalOpen(e, item)}>
                              <i class="fa fa-pencil"></i>
                            </Link>
                          </div>
                        </td>
                      </tr>
                      ))}
                  </tbody>
                </table>
                {loader ? ("") : hotelsubscrptionList.length > 0 ? ("") : (<NoDataFound />)}
              </div> }      
            </div>
            </div>

            {isCustomSubscriptionModalOpen &&
                    <Modal
                      show={isCustomSubscriptionModalOpen}
                      onHide={handleCustomSubsModalClose}
                      className="subscriptionBillingModalOverlay"
                    >
                      <Modal.Body class="subscriptionUpdateModalBody">
                          <div className="subscriptionUpdateModalContent">
                            <h3 className="subscriptionUpdateModalheading">Set Custom Subscription</h3>

                            <h4>Update {hotelSubscriptions?.[0]?.subType} Subscription</h4>
                            <div className="hotelCustomSubFixedDetailsContainer">
                              {/* <div className="form-group">
                                  <label>Subscription ID</label>
                                    <input
                                        type={"text"}
                                        className="form-control"
                                        disabled
                                        value = {hotelSubscriptions?.[0]?.subscriptionId}                           
                                    /> 
                              </div> */}
                              <div className="form-group">
                                <label>Subscription Type</label>
                                  <input
                                      type={"text"}
                                      className="form-control"
                                      disabled
                                      value = {hotelSubscriptions?.[0]?.subType}                           
                                  /> 
                              </div>
                              <div className="form-group">
                                <label>Subscription Name</label>
                                  <input
                                      type={"text"}
                                      className="form-control"  
                                      disabled  
                                      value={hotelSubscriptions?.[0]?.name}                   
                                  /> 
                              </div>
                          </div>

                          <div className="hotelCustomSubInputDetailsContainer">
                              <div className="form-group">
                                <label>Subscription Price / Room</label>
                                  <input
                                      type={"number"}
                                      className="form-control"
                                      placeholder = {hotelSubscriptions?.[0]?.pricePerRoom}
                                      onChange={(e) => (e.target.value) ? setCustomSubscriptionData((customSubscriptionData) => 
                                        ({...customSubscriptionData, 
                                          price1: e.target.valueAsNumber
                                        })) : 
                                        delete customSubscriptionData.price1}                         
                                  /> 
                              </div>
                              <div className="form-group">
                                <label>Subscription Discount</label>
                                  <input
                                      type={"number"}
                                      className="form-control"
                                      placeholder = {hotelSubscriptions?.[0]?.discount}    
                                      onChange={(e) => (e.target.value) ? setCustomSubscriptionData((customSubscriptionData) => 
                                        ({...customSubscriptionData, discount1: e.target.valueAsNumber})) : 
                                        delete customSubscriptionData.discount1}                       
                                  /> 
                              </div>
                          </div>


                          <h4>Update {hotelSubscriptions?.[1]?.subType} Subscription</h4>
                            <div className="hotelCustomSubFixedDetailsContainer">
                              {/* <div className="form-group">
                                  <label>Subscription ID</label>
                                    <input
                                        type={"text"}
                                        className="form-control"
                                        disabled
                                        value = {hotelSubscriptions?.[1]?.subscriptionId}                           
                                    /> 
                              </div> */}
                              <div className="form-group">
                                <label>Subscription Type</label>
                                  <input
                                      type={"text"}
                                      className="form-control"
                                      disabled
                                      value = {hotelSubscriptions?.[1]?.subType}                           
                                  /> 
                              </div>
                              <div className="form-group">
                                <label>Subscription Name</label>
                                  <input
                                      type={"text"}
                                      className="form-control"  
                                      disabled  
                                      value={hotelSubscriptions?.[1]?.name}                   
                                  /> 
                              </div>
                          </div>

                          <div className="hotelCustomSubInputDetailsContainer">
                              <div className="form-group">
                                <label>Subscription Price / Room</label>
                                  <input
                                      type={"number"}
                                      className="form-control"
                                      placeholder = {hotelSubscriptions?.[1]?.pricePerRoom}
                                      onChange={(e) => (e.target.value) ? setCustomSubscriptionData((customSubscriptionData) => 
                                        ({...customSubscriptionData, 
                                          price2: e.target.valueAsNumber
                                        })) : 
                                        delete customSubscriptionData.price2}                         
                                  /> 
                              </div>
                              <div className="form-group">
                                <label>Subscription Discount</label>
                                  <input
                                      type={"number"}
                                      className="form-control"
                                      placeholder = {hotelSubscriptions?.[1]?.discount}    
                                      onChange={(e) => (e.target.value) ? setCustomSubscriptionData((customSubscriptionData) => 
                                        ({...customSubscriptionData, discount2: e.target.valueAsNumber})) : 
                                        delete customSubscriptionData.discount2}                       
                                  /> 
                              </div>
                          </div>



                          <h4>Update {hotelSubscriptions?.[2]?.subType} Subscription</h4>
                            <div className="hotelCustomSubFixedDetailsContainer">
                              {/* <div className="form-group">
                                  <label>Subscription ID</label>
                                    <input
                                        type={"text"}
                                        className="form-control"
                                        disabled
                                        value = {hotelSubscriptions?.[2]?.subscriptionId}                           
                                    /> 
                              </div> */}
                              <div className="form-group">
                                <label>Subscription Type</label>
                                  <input
                                      type={"text"}
                                      className="form-control"
                                      disabled
                                      value = {hotelSubscriptions?.[2]?.subType}                           
                                  /> 
                              </div>
                              <div className="form-group">
                                <label>Subscription Name</label>
                                  <input
                                      type={"text"}
                                      className="form-control"  
                                      disabled  
                                      value={hotelSubscriptions?.[2]?.name}                   
                                  /> 
                              </div>
                          </div>

                          <div className="hotelCustomSubInputDetailsContainer">
                              <div className="form-group">
                                <label>Subscription Price / Room</label>
                                  <input
                                      type={"number"}
                                      className="form-control"
                                      placeholder = {hotelSubscriptions?.[2]?.pricePerRoom}
                                      onChange={(e) => (e.target.value) ? setCustomSubscriptionData((customSubscriptionData) => 
                                        ({...customSubscriptionData, 
                                          price3: e.target.valueAsNumber
                                        })) : 
                                        delete customSubscriptionData.price3}                         
                                  /> 
                              </div>
                              <div className="form-group">
                                <label>Subscription Discount</label>
                                  <input
                                      type={"number"}
                                      className="form-control"
                                      placeholder = {hotelSubscriptions?.[2]?.discount}    
                                      onChange={(e) => (e.target.value) ? setCustomSubscriptionData((customSubscriptionData) => 
                                        ({...customSubscriptionData, discount3: e.target.valueAsNumber})) : 
                                        delete customSubscriptionData.discount3}                       
                                        /> 
                              </div>
                          </div>
                        </div>


                          <div className="updateSubscriptionButtonContainer">
                              <button className="Button" onClick={(e) => handleUpdateToCustomSubscription(e)}>
                                  Update
                              </button>
                              <button className="Button Cancel" onClick={(e) => handleCustomSubsModalClose(e)}> 
                                  Cancel
                              </button>
                              <button className="Button AddRegularSubscription" onClick={(e) => handleAddRegularSubscription(e)}>
                                Add Regular Subscription
                              </button>
                          </div>
                              
                      </Modal.Body>
                    </Modal>}
          </div>

    </>
  );
};

export default HotelSubscriptions;
