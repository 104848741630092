import React, { useState } from "react";
import Header from "./header";
import Sidenav from "./sidenav";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from '../axiosInstance';
import ImageFetcher from './ImageFetcher'
import NoDataFound from "./noDataFound";
import Pagination from "react-js-pagination";
import Star from "../assets/image/star.svg";

import { minutesList, hoursList } from "../utils";
import {
  AddFoodBeverageAction,
  EditFoodBeverageAction,
  FoodBeverageCategoryListAction,
  FoodBeverageListAction,
  RemoveFoodBeverageAction,
} from "../redux/actions/foodBeverageAction";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import moment, { min } from "moment/moment";
import { CouponListAction } from "../redux/actions/couponAction";
import { ServiceCategoryListAction } from "../redux/actions/restaurantAction";
import Loader from "./loader";

const inititalState = {
  openTime: "",
  closeTime: "",
  category: "",
  servingCategory: "",
  name: "",
  mealType: "",
  servingSize: "",
  deliveryTime: "",
  price: "",
  newCategory: "",
  description: "",
  tempCategory: "",
  beverages: "ml",
  hrs: "00",
  mins: "00",
  errorMsg: {},
};

const inititalDiscountState = {
  diamond: {
    price: "",
    discount: "",
  },
  gold: {
    price: "",
    discount: "",
  },
  silver: {
    price: "",
    discount: "",
  },
  discountError: {},
};

const RestaurantFood = () => {

  const {id} =useParams()
  const hotelDetails = JSON.parse(window.localStorage.getItem("hotelDetails"));

  const [iState, updateState] = useState(inititalState);
  const [image, setImage] = useState("");
  const [stopper, setStopper] = useState(false);
  const [iDiscountState, updateDiscountState] = useState(inititalDiscountState);
  const { couponList } = useSelector((state) => state.couponReducer);
  const { serviceList } = useSelector((state) => state.restaurantReducer);

  const [tempPrice, setTempPrice] = useState("");

  const [foodbeverageId, setFoodBeverageId] = useState("");
  const [Type, setType] = useState("add");

  const [filterData, setFilterData] = useState({
    search: "",
    mealType: "",
    category: "",
    page: 1,
  });

  const dispatch = useDispatch();

  const [DeleteModal, setDelete] = useState(false);
  const handleDeleteModalClose = () => setDelete(false);
  const handleDeleteModalShow = (id) => {
    setFoodBeverageId(id);
    setDelete(true);
  };

  const [updateDiscountModal, setUpdateDiscount] = useState(false);
  const handleUpdateDiscountModalClose = () => {
    setUpdateDiscount(false);
  };
  const handleUpdateDiscountModalShow = (item) => {
    if (
      (item?.price / 100) * gold.discount <
        couponList
          ?.filter((item) => item?.category == "silver")
          ?.map((ele) => {
            return ele?.maxDiscountAmt;
          })?.[0] &&
      (item?.price / 100) * gold.discount <
        couponList
          ?.filter((item) => item?.category == "gold")
          ?.map((ele) => {
            return ele?.maxDiscountAmt;
          })?.[0] &&
      (item?.price / 100) * gold.discount <
        couponList
          ?.filter((item) => item?.category == "diamond")
          ?.map((ele) => {
            return ele?.maxDiscountAmt;
          })?.[0]
    ) {
      setFoodBeverageId(item?._id);
      setTempPrice(item?.price);
      updateDiscountState({
        ...iDiscountState,
        diamond: {
          discount: item?.diamond?.discount,
          price: item?.diamond?.price,
        },
        gold: {
          discount: item?.gold?.discount,
          price: item?.gold?.price,
        },
        silver: {
          discount: item?.silver?.discount,
          price: item?.silver?.price,
        },
      });
      if (couponList?.length == 3) {
        setUpdateDiscount(true);
      } else {
        toast.error("* Please add all coupons first !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      toast.error("* Please increase discount on coupons !", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const { foodBeverageList, foodBeverageCategoryList, loader } = useSelector(
    (state) => state.foodBeverageReducer
  );

  const {
    openTime,
    closeTime,
    category,
    cuisine,
    name,
    mealType,
    servingSize,
    deliveryTime,
    price,
    description,
    servingCategory,
    tempCategory,
    errorMsg,
    newCategory,
    beverages,
    hrs,
    mins,
  } = iState;

  const { diamond, gold, silver, discountError } = iDiscountState;
  const [AddActivitiesModal, setAddActivities] = useState(false);
  const handleAddActivitiesModalClose = () => {
    updateState({
      openTime: "",
      closeTime: "",
      servingCategory: "",
      name: "",
      image: "",
      mealType: "",
      servingSize: "",
      deliveryTime: "",
      tempCategory: "",
      price: "",
      newCategory: "",
      description: "",
      hrs: "00",
      mins: "00",
      errorMsg: {},
    });
    setAddActivities(false);
  };
  const handleAddActivitiesModalShow = (type, ele) => {
    if (couponList?.length < 3) {
      toast.error("* Please add all coupon first", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (serviceList?.servingCategories?.length < 1) {
      toast.error("* Please add service category from restaurant profile !", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (!serviceList?.hasOwnProperty('tax')) {
      toast.error("* Please add GST !", {
        position: toast.POSITION.TOP_RIGHT,
      });
    
    } else {
      if (type == "edit") {
        setType(type);
        setFoodBeverageId(ele?._id);
        setImage(ele?.image);

        updateState({
          ...iState,
          cuisine: cuisine,
          name: ele?.name,
          servingSize: ele?.servingSize?.split(" ")?.[0],
          hrs: ele?.deliveryTime?.split(":")?.[0],
          mins: ele?.deliveryTime?.split(":")?.[1],
          price: ele?.price,
          description: ele?.description,
          servingCategory: ele?.servingCategory,
          openTime: ele?.openTime,
          closeTime: ele?.closeTime,
          mealType: ele?.mealType,
          tempCategory: ele?.category,
          beverages: ele?.servingSize?.split(" ")?.[1],
        });
        setAddActivities(true);
      } else {
        setAddActivities(true);
        setType(type);
        setImage("");

        updateState({
          ...iState,
          openTime: "",
          closeTime: "",
          servingCategory: "",
          name: "",
          mealType: "",
          servingSize: "",
          deliveryTime: "",
          mins: "00",
          hrs: "00",
          tempCategory: "",
          price: "",
          newCategory: "",
          description: "",
          errorMsg: {},
        });
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name == "price" || name == "servingSize") {
      if (!/^[0-9]{0,10}$/.test(value) || e.target.value == "e") return;
      {
        updateState({ ...iState, [name]: value });
      }
    } else {
      updateState({ ...iState, [name]: value });
    }

    // }

    // else{
    //   if (!/^[A-Za-z\s]*$/.test(value)) return
    //   {
    //     updateState({...iState ,[name]:value})

    //   }

    // }
  };

  window.Buffer = window.Buffer || require("buffer").Buffer;

  const handleUpload = (e) => {
    const file = e.target.files[0];

    if (file.type == "application/pdf") {
      toast.error("Only jpeg, jpg and png are allowed ", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    if (file) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        //Initiate the JavaScript Image object.
        var image = new Image();

        //Set the Base64 string return from FileReader as source.
        image.src = e.target.result;

        //Validate the File Height and Width.
        image.onload = function () {
          var height = this.height;
          var width = this.width;
          if (
            file.type === "image/jpeg" ||
            file.type === "image/jpg" ||
            file.type === "image/png"
          ) {
            const formData = new FormData();
            formData.append('file', file);
  
            let accessToken = JSON.parse(localStorage.getItem("hotelDetails")).jwtToken;
              axiosInstance.post('/upload?type=hotel', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  'Authorization': 'Authorization'.concat(" ",accessToken),
                }
              })
            .then(response => {
              console.log(response);
            
              if (response.status !== 200) {
                throw new Error(`Server error: ${response.statusText}`);
              }
            
              return response;
            })
            .then(data => {
              if (data.error) {
                toast.error(data.error, { position: toast.POSITION.TOP_RIGHT });
              } else {
                const tempData = data.data.url;
                setImage(tempData);
              toast.success("Upload successfully", { position: toast.POSITION.TOP_RIGHT });
              }
            })
            .catch(error => {
              console.error(error);
              toast.error("Upload failed: " + error.message, { position: toast.POSITION.TOP_RIGHT });
            });
            } else {
            toast.error("Only jpeg, jpg and png are allowed ", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        };
      };
    }
  };

  const handleValidate = () => {
    let formIsValid = true;
    let errorMsg = {};
    if (!name) {
      formIsValid = false;
      errorMsg.name = "* Please enter name.";
    }
    if (!image) {
      formIsValid = false;
      errorMsg.image = "* Please upload  image.";
    }

    if (!price) {
      formIsValid = false;
      errorMsg.price = "* Please enter price.";
    }

    if (!description) {
      formIsValid = false;
      errorMsg.description = "* Please  enter description.";
    }

    if (!tempCategory) {
      formIsValid = false;
      errorMsg.category = "* Please select category .";
    }

    if ((hrs == "00") & (mins == "00")) {
      formIsValid = false;
      errorMsg.deliveryTime = "* Please enete delivery Time .";
    }

    if (!mealType) {
      formIsValid = false;
      errorMsg.mealType = "* Please select meal type .";
    }

    if (!servingCategory) {
      formIsValid = false;
      errorMsg.servingCategory = "* Please select serving category .";
    }

    if (!servingSize) {
      formIsValid = false;
      errorMsg.servingSize = "* Please enter serving size .";
    }

    updateState({ ...iState, errorMsg: errorMsg });

    return formIsValid;
  };

  const handleDiscountValidate = () => {
    let formIsValid = true;
    let discountError = {};
    if (!diamond.discount) {
      formIsValid = false;
      discountError.diamondDiscount = "* Please enter dicount.";
    } else if (diamond.discount < 0) {
      formIsValid = false;
      discountError.diamondValidDiscount = "* Discount not be negative.";
    } else if (diamond.discount >= 100) {
      formIsValid = false;
      discountError.diamondValidDiscount = "* Discount not be valid.";
    } else if (
      (tempPrice / 100) * diamond.discount >
      couponList
        ?.filter((item) => item?.category == "diamond")
        ?.map((ele) => {
          return ele?.maxDiscountAmt;
        })?.[0]
    ) {
      formIsValid = false;
      discountError.diamondValidDiscount =
        "* Discount percentage entered is higher than the total discount amount on this package.!";
    }

    if (!gold.discount) {
      formIsValid = false;
      discountError.goldDiscount = "* Please enter dicount.";
    } else if (gold.discount < 0) {
      formIsValid = false;
      discountError.goldValidDiscount = "* Discount not be negative.";
    } else if (gold.discount >= 100) {
      formIsValid = false;
      discountError.goldValidDiscount = "* Discount not be valid.";
    } else if (
      (tempPrice / 100) * gold.discount >
      couponList
        ?.filter((item) => item?.category == "gold")
        ?.map((ele) => {
          return ele?.maxDiscountAmt;
        })?.[0]
    ) {
      formIsValid = false;
      discountError.goldValidDiscount =
        "* Discount percentage entered is higher than the total discount amount on this package.!";
    }

    if (!silver.discount) {
      formIsValid = false;
      discountError.silverDiscount = "* Please enter dicount.";
    } else if (silver.discount < 0) {
      formIsValid = false;
      discountError.silverValidDiscount = "* Discount not be negative.";
    } else if (silver.discount >= 100) {
      formIsValid = false;
      discountError.silverValidDiscount = "* Discount not be valid.";
    } else if (
      (tempPrice / 100) * silver.discount >
      couponList
        ?.filter((item) => item?.category == "silver")
        ?.map((ele) => {
          return ele?.maxDiscountAmt;
        })?.[0]
    ) {
      formIsValid = false;
      discountError.silverValidDiscount =
        "* Discount percentage entered is higher than the total discount amount on this package.!";
    }

    updateDiscountState({ ...iDiscountState, discountError: discountError });

    return formIsValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formIsValid = handleValidate();
    setStopper(formIsValid);
    const hotelData = JSON.parse(localStorage.getItem("hotel"));
    let timeArray = deliveryTime?.split(":");

    if (formIsValid) {
      let couponSilver = couponList?.filter?.(
        (item) => item?.category == "silver"
      );

      let data = {
        name,
        price,
        image,
        servingSize: `${servingSize?.trim()} ${beverages}`,
        description,
        hotelId: hotelData?.hotelId?.[0]?._id,
        category: newCategory ? newCategory : tempCategory,
        totalMinutes:
          parseInt(String(timeArray?.[0]).padStart(2, "0")) * 60 +
          +parseInt(String(timeArray?.[1]).padStart(2, "0")),

        deliveryTime: hrs + ":" + mins,

        openTime,
        closeTime,
        mealType,
        servingCategory,
        hotelId:id
      };

      if (couponSilver?.[0]?.minPurchaseAmt < price) {
        setStopper(false);
        toast.error(
          `* Maximum allowed discount is ${couponSilver?.[0]?.minPurchaseAmt} as per Silver coupon. Please update the item amount or coupon discount`,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      } else {
        dispatch(AddFoodBeverageAction(data))
          .then((res) => {
            if (res.data.success === true) {
              setStopper(false);
              setAddActivities(false);
              dispatch(FoodBeverageListAction({ category , hotelId:id}));
              toast.success(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              updateState({
                ...iState,
                openTime: "",
                closeTime: "",
                servingCategory: "",
                name: "",
                image: "",
                tempCategory: "",
                mealType: "",
                servingSize: "",
                deliveryTime: "",
                price: "",
                newCategory: "",
                description: "",
                errorMsg: {},
              });
            } else {
              setStopper(false);
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          })
          .catch((err) => {
            alert(err);
          });
      }
    }
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    let couponSilver = couponList?.filter?.(
      (item) => item?.category == "silver"
    );

    let formIsValid = handleValidate();
    const hotelData = JSON.parse(localStorage.getItem("hotel"));

    setStopper(formIsValid);

    if (formIsValid) {
      let data = {
        _id: foodbeverageId,
        name,
        price,
        image,
        servingSize: `${servingSize?.trim()} ${beverages}`,
        description,
        openTime,
        closeTime,
        mealType,
        servingCategory,
        hotelId:id,
        totalMinutes: parseInt(hrs) * 60 + +parseInt(mins),

        category: newCategory ? newCategory : tempCategory,
        deliveryTime: hrs + ":" + mins,
      };
      if (couponSilver?.[0]?.minPurchaseAmt < price) {
        setStopper(false);
        toast.error(
          "* Entered price should less than minimum purchase discount amount or you can update coupons",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      } else {
        dispatch(EditFoodBeverageAction(data))
          .then((res) => {
            if (res.data.success === true) {
              setStopper(false);
              setAddActivities(false);
              dispatch(FoodBeverageListAction({ category,hotelId:id }));
              dispatch(FoodBeverageCategoryListAction({hotelId:id}));

              toast.success(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              updateState({
                openTime: "",
                closeTime: "",
                servingCategory: "",
                name: "",
                image: "",
                mealType: "",
                servingSize: "",
                deliveryTime: "",
                hrs: "00",
                mins: "00",
                price: "",
                newCategory: "",
                description: "",
                errorMsg: {},
              });
            } else {
              setStopper(false);
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          })
          .catch((err) => {
            alert(err);
          });
      }
    }
  };

  const handleUpdateDiscount = (e) => {
    e.preventDefault();

    let formIsValid = handleDiscountValidate();

    if (formIsValid) {
      let data = {
        _id: foodbeverageId,
        diamond: {
          discount: diamond?.discount,
          price: tempPrice - (tempPrice / 100) * diamond.discount,
        },
        gold: {
          discount: gold?.discount,
          price: tempPrice - (tempPrice / 100) * gold.discount,
        },
        silver: {
          discount: silver?.discount,
          price: tempPrice - (tempPrice / 100) * silver.discount,
        },

        hotelId: id,
      };

      dispatch(EditFoodBeverageAction(data))
        .then((res) => {
          if (res.data.success === true) {
            setUpdateDiscount(false);
            dispatch(FoodBeverageListAction({hotelId:id}));

            toast.success("Discount updated successfully", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          alert(err);
        });
    }
  };

  useEffect(() => {
    dispatch(FoodBeverageCategoryListAction({hotelId:id}));
    dispatch(FoodBeverageListAction({hotelId:id}));
    dispatch(CouponListAction({hotelId:id}));
    dispatch(ServiceCategoryListAction({hotelId:id}));
  }, []);

  const handleDelete = () => {
    dispatch(RemoveFoodBeverageAction({ id: foodbeverageId , hotelId:id})).then((res) => {
      if (res.data.success === true) {
        setDelete(false);
        dispatch(FoodBeverageListAction({ category ,hotelId:id}));
        dispatch(FoodBeverageCategoryListAction({hotelId:id}));
      }
    });
  };

  const handleStatusChange = (item) => {
    if (item?.visibility == true) {
      item.visibility = false;
    } else {
      item.visibility = true;
    }
    dispatch(EditFoodBeverageAction(item))
      .then((res) => {
        if (res.data.success === true) {
          dispatch(FoodBeverageListAction({ category ,hotelId:id}));
          dispatch(FoodBeverageCategoryListAction({hotelId:id}));

          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const handleFilter = (e) => {
    dispatch(
      FoodBeverageListAction({
        hotelId:id,
        filter: filterData?.category,
        search: filterData?.search,
        mealType: filterData?.mealType,
      })
    );
  };

  const handleRefresh = (e) => {
    setFilterData({
      ...filterData,
      search: "",
      filter: "",
      category: "",
      mealType: "",
    });

    dispatch(FoodBeverageListAction({ mealType: "" , hotelId:id}));
  };

  const handleMealType = (e) => {
    setFilterData((prev) => ({ ...prev, mealType: e.target.value }));
    dispatch(
      FoodBeverageListAction({
        mealType: e.target.value,
        search: filterData?.search,
        filter: filterData?.category,
        hotelId:id
      })
    );
  };
  const handleSearch = (e) => {
    dispatch(FoodBeverageListAction({ search: e.target.value, hotelId:id }));
    setFilterData((prev) => ({ ...prev, search: e.target.value }));
  };

  const handlePageChange = (pageNumber) => {
    filterData.page = pageNumber;
    dispatch(
      FoodBeverageListAction({
        page: filterData?.page,
        mealType: filterData?.mealType,
        search: filterData?.search,
        filter: filterData?.category,
        hotelId:id,
      })
    );
  };



  window.scrollTo(0, 0)



  return (
    <div>
      <Header />
      <Sidenav />


      <div class="WrapperArea">
        <div class="WrapperBox">
          <div class="HotelLocation HotelLocation2">
            <div class="back-btn">
              <Link to={`/hotel-details/${id}`}>
                <i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back{" "}
              </Link>
            </div>

            <aside>
              <h3>{hotelDetails?.name}</h3>
              <p>
                <span>
                  <i class="fa fa-map-marker"></i>
                </span>{" "}
                {hotelDetails?.address}
              </p>
            </aside>
          </div>

            <div class="CommonLinks CommonLinksNew">
              <ul>
                <li className="active">
                  <Link to={`/restaurant-food/${id}`}>Food  & Beverages</Link>
                </li>
              <li>
                <Link to={`/restaurant-msg/${id}`}>Message</Link>
              </li>
              <li>
                <Link to={`/restaurant-order-request/${id}`}>Order Requests</Link>
              </li>
              {/* <li>
                <a>Guest Notifications</a>
              </li> */}

              </ul>
              <a
              class="Button"
              style={{ color: "white", fontSize: "15px" }}
              onClick={(e) => handleAddActivitiesModalShow("add")}
            >
              Add Item
            </a>

              

            </div>
{/* 
            <div class="TitleBox">
              <h6>
                Opening time:{" "}
                <b>
                  10:00 AM to 9:00 PM
                  <span>
                    <a class="Green" data-toggle="modal">
                      <i class="fa fa-pencil"></i>
                    </a>
                  </span>
                </b>
              </h6>
            </div> */}
          

          <div class="Small-Wrapper">
            <div class="CommonTabs">
              <div class="">
                <ul class="nav nav-tabs"></ul>
                <div class="FilterArea">
                  <div class="FilterLeft">
                    <div class="form-group">
                      <label>Search</label>
                      <input
                        type="text"
                        value={filterData.search}
                        onChange={handleSearch}
                        placeholder="search ..."
                        class="form-control"
                      />
                    </div>
                    <div class="form-group">
                      <label>Category</label>
                      <select
                        class="form-control"
                        value={filterData.category}
                        onChange={(e) =>
                          setFilterData((prev) => ({
                            ...prev,
                            category: e.target.value,
                          }))
                        }
                      >
                        <option value="">Select </option>
                        {foodBeverageCategoryList?.[0]?.categories?.map(
                          (item) => (
                            <option value={item?._id}> {item?.name}</option>
                          )
                        )}
                      </select>
                    </div>
                    <div class="form-group">
                      <label>&nbsp;</label>
                      <button onClick={handleFilter} class="Button">
                        Apply
                      </button>
                      <button onClick={handleRefresh} class="Button Cancel">
                        <i class="fa fa-refresh"></i>
                      </button>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>&nbsp;</label>

                    <div class="form-group">
                      <label class="Radio">
                        Veg
                        <input
                          type="radio"
                          name="meal"
                          checked={filterData?.mealType == "Veg" ? true : false}
                          onChange={handleMealType}
                          value="Veg"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <div class="form-group">
                      <label class="Radio">
                        Non-Veg
                        <input
                          type="radio"
                          name="meal"
                          checked={
                            filterData?.mealType == "Non-Veg" ? true : false
                          }
                          onChange={handleMealType}
                          value="Non-Veg"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <div class="form-group">
                      <label class="Radio">
                        Beverages
                        <input
                          type="radio"
                          name="meal"
                          checked={
                            filterData?.mealType == "Drinks" ? true : false
                          }
                          onChange={handleMealType}
                          value="Drinks"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {loader? <Loader />:


            <div class="tab-content">
              <div class="tab-pane active" id={category}>
                <div class="TitleBox"></div>
                <div class="MenuList">
                  <ul>
                    {foodBeverageList?.[0]?.data?.[0]?.items?.map((item) => (
                      <li>
                        <div class="MenuLeft">
                          <figure>
                          <ImageFetcher imageUrl={item?.image}/>
                          </figure>
                          <figcaption>
                            <h3>{item?.name}</h3>
                            <p>
                                                <span>
                                                    <img src={Star} alt="" />
                                                </span>
                                                {item?.rating}
                                                {" "}
                                                <Link state={item} to={`/food-review-list/${id}`}>{item?.totalReview} Reviews</Link>
                                            </p>
                            <h4>
                              <span>{item?.servingSize} </span>
                            </h4>
                            <h4>
                              <span>
                                Serving Time{" "}
                                {foodBeverageCategoryList?.[0]?.servingCategories
                                  ?.filter(
                                    (element) =>
                                      element?._id == item?.servingCategory
                                  )
                                  ?.map((ele) => (
                                    <>
                                      {moment(ele?.openTime, "HH:mm").format(
                                        "h:mm A"
                                      )}
                                    </>
                                  ))}{" "}
                                To{" "}
                                {foodBeverageCategoryList?.[0]?.servingCategories
                                  ?.filter(
                                    (element) =>
                                      element?._id == item?.servingCategory
                                  )
                                  ?.map((ele) => (
                                    <>
                                      {moment(ele?.closeTime, "HH:mm").format(
                                        "h:mm A"
                                      )}
                                    </>
                                  ))}
                              </span>
                            </h4>
                          </figcaption>
                          {couponList?.length !=0 &&

                          <figcaption className="food-bav-content">
                            { item?.diamond?.discount &&
                            <h4>
                              Diamond Service Packages (
                              {item?.diamond?.discount}%) {item?.diamond?.price}
                            </h4>}
                            { item?.gold?.discount &&

                            <h4>
                              Gold Service Packages ({item?.gold?.discount}%){" "}
                              {item?.gold?.price}
                            </h4>}
                            { item?.silver?.discount &&

                            <h4>
                              Silver Service Packages ({item?.silver?.discount}
                              %) {item?.silver?.price}
                            </h4>}

                            <a
                              class="Green food-bav2"
                            onClick={() =>
                                handleUpdateDiscountModalShow(item)
                              }
                            >
                              <i class="fa fa-pencil"></i>
                            </a>
                          </figcaption>}
                        </div>
                        <div class="MenuRight">
                          <h5>
                            {item?.price} INR <span>+Taxes</span>
                          </h5>
                          <label class="switch">
                            <input
                              type="checkbox"
                              checked={item?.visibility}
                              value={item}
                              onChange={(e) => handleStatusChange(item)}
                            />
                            <span class="slider"></span>
                          </label>
                          <a
                            class="Green"
                            onClick={(e) =>
                              handleAddActivitiesModalShow("edit", item)
                            }
                          >
                            <i class="fa fa-pencil"></i>
                          </a>
                          <a
                            class="Red"
                            onClick={() => handleDeleteModalShow(item?._id)}
                          >
                            <i class="fa fa-trash"></i>
                          </a>
                        </div>
                      </li>
                    ))}
                  </ul>
                  
                </div>
                {loader ? (
            ""
          ) : foodBeverageList?.[0]?.data?.[0]?.items?.length>0 ? (
            ""
          ) : (
            <NoDataFound />
          )}


          {foodBeverageList?.[0]?.metadata?.[0]?.total > 0 ? (
            <div className="Pagination">
              <Pagination
                activePage={filterData?.page}
                itemsCountPerPage={10}
                totalItemsCount={foodBeverageList?.[0]?.metadata?.[0]?.total}
                pageRangeDisplayed={5}
                onChange={(e) => handlePageChange(e)}
              />
            </div>
          ) : (
            ""
          )}

              </div>
            </div>}
          </div>
        </div>
      </div>

      <Modal
        show={AddActivitiesModal}
        // onHide={handleAddActivitiesModalClose}
        className="ModalBox "
      >
        <Modal.Body>
          <div class="Category">
            <a onClick={handleAddActivitiesModalClose} class="CloseModal">
              ×
            </a>
            {Type == "edit" ? (
              <h3>Update Food Item</h3>
            ) : (
              <h3>Add Food Item</h3>
            )}
            <div class="form-group">
              <label></label>
              <div class="UploadBox">
                <div class="Upload">
                  <i class="fa fa-upload"></i> <span>Upload Icon</span>
                  <input
                    type="file"
                    onClick={(e) => (e.target.value = null)}
                    onChange={(e) => handleUpload(e)}
                    class="form-control"
                  />
                </div>
                {image && (
                  <div className="UploadIcon">
                    <ImageFetcher imageUrl={image}/>
                  </div>
                )}
              </div>
              {errorMsg.image && !image && (
                <p style={{ color: "red" }}>{errorMsg.image}</p>
              )}
            </div>
            <div class="form-group">
              <label>Name</label>
              <input
                value={name}
                name="name"
                onChange={handleChange}
                class="form-control"
              />
              {errorMsg.name && !name && (
                <p style={{ color: "red" }}>{errorMsg.name}</p>
              )}
            </div>
            <div class="form-group">
              <label>Meal Type</label>

              <div className="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="Radio">
                      Veg
                      <input
                        type="radio"
                        name="mealType"
                        checked={mealType == "Veg" ? true : false}
                        onChange={handleChange}
                        value="Veg"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="Radio">
                      Non-Veg
                      <input
                        type="radio"
                        name="mealType"
                        checked={mealType == "Non-Veg" ? true : false}
                        onChange={handleChange}
                        value="Non-Veg"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="Radio">
                      Beverages
                      <input
                        type="radio"
                        name="mealType"
                        checked={mealType == "Drinks" ? true : false}
                        onChange={handleChange}
                        value="Drinks"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>

              {errorMsg.mealType && !mealType && (
                <p style={{ color: "red" }}>{errorMsg.mealType}</p>
              )}
            </div>
            {category !== "Add Category" && (
              <div class="form-group">
                <label>Select Category</label>
                <select
                  value={tempCategory}
                  name="tempCategory"
                  onChange={handleChange}
                  class="form-control"
                >
                  <option value="">Select</option>

                  {foodBeverageCategoryList?.[0]?.categories?.filter((ele)=>ele?.mealType?.includes(mealType))?.map((item) => (
                    <option value={item?._id}> {item?.name}</option>
                  ))}
                </select>
                {errorMsg.category && !tempCategory && (
                  <p style={{ color: "red" }}>{errorMsg.category}</p>
                )}
              </div>
            )}
            {category == "Add Category" && (
              <div class="form-group">
                <label>Enter Category</label>

                <div className="CategoryAdd">
                  <input
                    type="text"
                    class="form-control"
                    value={newCategory}
                    name="newCategory"
                    placeholder="Enter category name"
                    onChange={handleChange}
                  />
                </div>
              </div>
            )}

            <div class="form-group">
              <label>Serving Size</label>

              {mealType == "Drinks" ? (
                <div className="RoomBox">
                  <input
                    type="number"
                    class="form-control"
                    name="servingSize"
                    value={servingSize}
                    onChange={handleChange}
                    placeholder="Enter serving size"
                  />
                  <select
                    class="form-control"
                    value={beverages}
                    name="beverages"
                    onChange={handleChange}
                  >
                    <option value={""}>Select</option>
                    <option value={"ml"}>ml</option>
                    <option value={"l"}>l</option>
                  </select>{" "}
                </div>
              ) : (
                <select
                  class="form-control"
                  value={servingSize}
                  name="servingSize"
                  onChange={handleChange}
                >
                  <option value={""}>Select</option>
                  <option value={"1"}>1</option>
                  <option value={"2"}>2</option>
                  <option value={"3"}>3</option>
                </select>
              )}              {errorMsg.servingSize && !servingSize && (
                <p style={{ color: "red" }}>{errorMsg.servingSize}</p>
              )}
            </div>
            

            <div class="form-group">
              <label>Delivery Time</label>
              <div className="Duration">
                <select value={hrs}  name="hrs" onChange={handleChange}>
                  <option value={"00"}>00</option>
                  {hoursList()?.map((item) => (
                    <option value={item}>{item}</option>
                  ))}
                </select>
                <span>:</span>
                <select value={mins}  name="mins" onChange={handleChange}>
                  <option value={"00"}>00</option>
                  {minutesList()?.map((item) => (
                    <option value={item}>{item}</option>
                  ))}
                </select>
              </div> 
              <div className="TimePicker">
                <span>HH</span>
                <span className="padding_none">:</span>
                <span>MM</span>
              </div>
              {errorMsg.deliveryTime && !deliveryTime && (
                <p style={{ color: "red" }}>{errorMsg.deliveryTime}</p>
              )}
            </div>

            <div class="form-group">
              <label>Serving Category</label>
              <select
                value={servingCategory}
                name="servingCategory"
                onChange={handleChange}
                class="form-control"
              >
                <option value="">Select</option>
                {foodBeverageCategoryList?.[0]?.servingCategories?.map(
                  (item) => (
                    <option value={item?._id}>{item?.name}</option>
                  )
                )}
              </select>
              {errorMsg.servingCategory && !servingCategory && (
                <p style={{ color: "red" }}>{errorMsg.servingCategory}</p>
              )}
            </div>

            <div class="form-group">
              <div className="RoomBox">
                {foodBeverageCategoryList?.[0]?.servingCategories
                  ?.filter((item) => item?._id == servingCategory)
                  ?.map((ele) => (
                    <>
                      <label>Serving Time</label>

                      <input
                        value={ele?.openTime}
                        name="openTime"
                        type="time"
                        disabled={true}
                        class="form-control"
                      />
                    </>
                  ))}

                {foodBeverageCategoryList?.[0]?.servingCategories
                  ?.filter((item) => item?._id == servingCategory)
                  ?.map((ele) => (
                    <>
                      <label>To</label>

                      <input
                        value={ele?.closeTime}
                        name="openTime"
                        type="time"
                        disabled={true}
                        class="form-control"
                      />
                    </>
                  ))}
              </div>
            </div>

            <div class="form-group">
              <label>Item Price</label>
              <input
                value={price}
                name="price"
                onChange={handleChange}
                type="text"
                class="form-control"
              />
              {errorMsg.price && !price && (
                <p style={{ color: "red" }}>{errorMsg.price}</p>
              )}
            </div>
            <div class="form-group">
              <label>Description</label>
              <textarea
                rows="3"
                value={description}
                name="description"
                onChange={handleChange}
                class="form-control"
              ></textarea>
              {errorMsg.description && !description && (
                <p style={{ color: "red" }}>{errorMsg.description}</p>
              )}
            </div>
            {Type == "edit" ? (
              <button class="Button" onClick={handleUpdate}>
                Update Food
              </button>
            ) : (
              <button class="Button" onClick={handleSubmit}>
                Add Food
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={DeleteModal}
        onHide={handleDeleteModalClose}
        className="ModalBox "
      >
        <Modal.Body>
          <div className="Decline">
            <button className="CloseModal" onClick={handleDeleteModalClose}>
              ×
            </button>
            <h3>Delete</h3>
            <p>Are you sure you want to delete this Food ?</p>
            <h4>
              <a onClick={handleDeleteModalClose} className="Cancel">
                Cancel
              </a>
              <a onClick={handleDelete}>Delete</a>
            </h4>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={updateDiscountModal}
        onHide={handleUpdateDiscountModalClose}
        className="ModalBox "
      >
        <Modal.Body>
          <div class="Category">
            <a onClick={handleUpdateDiscountModalClose} class="CloseModal">
              ×
            </a>
            <h3>Update Discount</h3>

            <div class="discount-package">
              <h2>Diamond Service Package</h2>
              <div class="row">
                <div className="col-12">
                  <div className="form-group">
                    <label>Discount %</label>

                    <input
                      value={diamond?.discount}
                      onChange={(e) =>
                        updateDiscountState((prev) => ({
                          ...prev,
                          diamond: { ...diamond, discount: e.target.value },
                        }))
                      }
                      type="number"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>

              {discountError?.diamondDiscount && !diamond?.discount && (
                <p style={{ color: "red" }}>{discountError?.diamondDiscount}</p>
              )}

              {discountError?.diamondValidDiscount && (
                <p style={{ color: "red" }}>
                  {discountError?.diamondValidDiscount}
                </p>
              )}
            </div>

            <div class="discount-package">
              <h2>Gold Service Package</h2>
              <div class="row">
                <div className="col-12">
                  <div className="form-group">
                    <label>Discount %</label>

                    <input
                      value={gold?.discount}
                      onChange={(e) =>
                        updateDiscountState((prev) => ({
                          ...prev,
                          gold: { ...gold, discount: e.target.value },
                        }))
                      }
                      type="number"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>

              {discountError?.goldDiscount && !gold?.discount && (
                <p style={{ color: "red" }}>{discountError?.goldDiscount}</p>
              )}
              {discountError?.goldValidDiscount && (
                <p style={{ color: "red" }}>
                  {discountError?.goldValidDiscount}
                </p>
              )}
            </div>

            <div class="discount-package">
              <h2>Silver Service Package</h2>
              <div class="row">
                <div className="col-12">
                  <div className="form-group">
                    <label>Discount %</label>

                    <input
                      value={silver?.discount}
                      onChange={(e) =>
                        updateDiscountState((prev) => ({
                          ...prev,
                          silver: { ...silver, discount: e.target.value },
                        }))
                      }
                      type="number"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>

              {discountError?.silverDiscount && !silver?.discount && (
                <p style={{ color: "red" }}>{discountError?.silverDiscount}</p>
              )}
              {discountError?.silverValidDiscount && (
                <p style={{ color: "red" }}>
                  {discountError?.silverValidDiscount}
                </p>
              )}
            </div>

            <button class="Button" onClick={handleUpdateDiscount}>
              Update
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RestaurantFood;
