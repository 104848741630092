import { dropdownConstant } from "../actionTypes";
const initialState = {
    occupiedRoom: [],
    loader: false,
    guestList:[],
    error: {},
  };
  
  export default function chatReducer(
    state = initialState,
    { type, payload }
    
  ) {
    switch (type) {

      case dropdownConstant.OCCUPIED_ROOM_INITIATE:
        return { ...state, loader: true, occupiedRoom: [] };
  
      case dropdownConstant.OCCUPIED_ROOM_SUCCESS:
        return { ...state, loader: false, occupiedRoom: payload.data };
  
      case dropdownConstant.OCCUPIED_ROOM_FAILURE:
        return { ...state, loader: false, error: payload };



        case dropdownConstant.GUEST_LIST_INITIATE:
          return { ...state, loader: true, guestList: [] };
    
        case dropdownConstant.GUEST_LIST_SUCCESS:
          return { ...state, loader: false, guestList: payload.data };
    
        case dropdownConstant.GUEST_LIST_FAILURE:
          return { ...state, loader: false, error: payload };
  
  



  
      default:
        return state;
    }
  }
  