import { trendingConstant } from "../actionTypes";
const initialState = {
    trendingList: {},
    addedTrendingList:[],
    loader: false,
    error: {},
  };
  
  export default function trendingReducer(
    state = initialState,
    { type, payload }
    
  ) {
    switch (type) {

      case trendingConstant.TRENDING_LIST_INITIATE:
        return { ...state, loader: true, trendingList: {} };
  
      case trendingConstant.TRENDING_LIST_SUCCESS:
        return { ...state, loader: false, trendingList: payload.data };
  
      case trendingConstant.TRENDING_LIST_FAILURE:
        return { ...state, loader: false, error: payload };



        case trendingConstant.ADD_TRENDING_LIST_INITIATE:
          return { ...state, loader: true, addedTrendingList: [] };
    
        case trendingConstant.ADD_TRENDING_LIST_SUCCESS:
          return { ...state, loader: false, addedTrendingList: payload.data };
    
        case trendingConstant.ADD_TRENDING_FAILURE:
          return { ...state, loader: false, error: payload };
  
  
  
      default:
        return state;
    }
  }
  