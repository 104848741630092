import React, { useEffect } from 'react'
import Header from './header'
import Sidenav from './sidenav'
import { Link, useLocation, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ReviewListAction } from '../redux/actions/authAction'
import moment from 'moment'
import ImageFetcher from './ImageFetcher'

const FoodReviewList = () => {
    const dispatch = useDispatch();
    const {id}  = useParams()
    const { reviewList } = useSelector((state) => state.authReducer);

    const {state} = useLocation()


    useEffect(()=>{
        dispatch(ReviewListAction({
            hotelId:id,
            id: state?._id
        }))

    },[])


    

  return (
    <div>
        <Header />
        <Sidenav />
        <div class="WrapperArea">
        <div class="WrapperBox">
        <div class="Small-Wrapper">
            <div class="NewTitleBox">
                            <div className='arrow-left'>
                            <Link to={`/restaurant-food/${id}`}>
                            <i class="fa fa-arrow-left arrow-back" aria-hidden="true"></i>
            </Link>
            <h4>Food &  Beverages Reviews</h4>

            </div>

            </div>

</div>
        <div class="ReviewList">
                <ul>
                    {reviewList?.map((item)=>
                    <li>
                        <figure>
                        <ImageFetcher imageUrl={item?.reviewer?.profileImage}/>
                        </figure>  
                        <figcaption>
                            <h4>{item?.reviewer?.name} <span>{item?.rating}{" "}<i class="fa fa-star"></i></span></h4>  
                            <h6><strong>Date :</strong> {moment(item?.createdAt).format('ll')}</h6>
                            <h6>Review</h6>
                            <p>{item?.reviews}</p>
                        </figcaption> 
                    </li>
                                        )}

                    
                </ul>
            </div>
        </div>
        </div>

      
    </div>
  )
}

export default FoodReviewList