
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";

import Header from "./header";
import Sidenav from "./sidenav";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { Link, useLocation } from "react-router-dom";
import { GetSubscriptionsDetailsAction } from "../redux/actions/subscriptionAction"

const SubscriptionDetails = () => {
  const [visibleoldsubsEntries, setVisibleoldsubsEntries] = useState(3);
  const [visibleFutureSubscriptionEntries, setVisibleFutureSubscriptionEntries] = useState(3);

  const [currentsubs, setcurrentsubs] = useState([]);
  const [oldsubs, setoldsubs] = useState([]);

  const [futuresubs, setFutureSubscription] = useState([]);

  const dispatch = useDispatch();
  
  const {state} = useLocation();
  console.log("sstate", state);
  const {hotelId} = useSelector(
    (state) => state.subscriptionReducer
  );
  console.log("hotelId", hotelId);  

  useEffect(() => {
    var hid;
    if (state){
        hid = state.hotelId
    }
    else {
        hid = hotelId
    }
    dispatch(
      GetSubscriptionsDetailsAction(
        { hotelId: hid }
      )
    ).then((res) => {
      if (res.status === 200){

        if(res.data.data.currentSubscription.length > 0){
          setcurrentsubs(res.data.data.currentSubscription);
        }
        if(res.data.data.oldSubscriptions.length > 0){
          setoldsubs(res.data.data.oldSubscriptions);
        }
        if(res.data.data.futureSubscriptions.length > 0){
          setFutureSubscription(res.data.data.futureSubscriptions);
        }
      }
    });
  }, [])

  const getDate = (date) => {
    date = new Date(date);
    const day = date.getDate();
    const month = date.getMonth() + 1; // +1 because getMonth() returns 0-11
    const year = date.getFullYear();

    const formattedDate = `${day.toString().padStart(2, '0')}-${month.toString().padStart(2, '0')}-${year}`;
    return formattedDate;
  }

  var visibleOldHistory = null;
  if (oldsubs){
    visibleOldHistory = oldsubs.slice(0, visibleoldsubsEntries);
  }

  var visibleFutureSubscription = null;
  if (futuresubs){
    visibleFutureSubscription = futuresubs.slice(0, visibleFutureSubscriptionEntries)
  }

  const [addedRoomsSubscriptionDetailsModal, setAddedRoomsSubscriptionDetailsModal] = useState(false);
  const [addedSubscriptionDetail, setAddedSubscriptionDetail] = useState(null);

  const handleAdditionalSubscriptionModal = (e, addedRoomsSubDetail) => {
    console.log(addedRoomsSubDetail)
    setAddedRoomsSubscriptionDetailsModal(true);
    setAddedSubscriptionDetail(addedRoomsSubDetail);
  }
  const handleAdditionalSubscriptionModalClose = () => {
    setAddedRoomsSubscriptionDetailsModal(false);
    setAddedSubscriptionDetail(null);    
  }

  const downloadSubscriptionData = (fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    var i = 0;

    let result1 = futuresubs?.map((futuresub) => {
      i = i + 1;
      return {
        "Sr. No": i,
        "Plan": futuresub?.subscriptionDetails?.name,
        "Start Date": getDate(futuresub?.startDate),
        "Duration" : futuresub?.subscriptionDetails?.durationInDays,
        "End Date" : getDate(futuresub?.endDate),
        "Amount" : futuresub?.totalPrice,
        "Type": "Future Subscription"
      };
    });
    let result2 = currentsubs?.map((currentsub) => {
      i = i + 1;
      return {
        "Sr. No": i,
        "Plan": currentsub?.subscriptionDetails?.name,
        "Start Date": getDate(currentsub?.startDate),
        "Duration" : currentsub?.subscriptionDetails?.durationInDays,
        "End Date" : getDate(currentsub?.endDate),
        "Amount" : currentsub?.totalPrice,
        "Type": "Current Subscription"
      };
    });

    let result3 = oldsubs?.map((oldSub) => {
      i = i + 1;
      return {
        "Sr. No": i,
        "Plan": oldSub?.subscriptionDetails?.name,
        "Start Date": getDate(oldSub?.startDate),
        "Duration" : oldSub?.subscriptionDetails?.durationInDays,
        "End Date" : getDate(oldSub?.endDate),
        "Amount" : oldSub?.totalPrice,
        "Type": "Old Subscription"
      };
    });

    let result = [...result1, ...result2, ...result3]
    // Combine the header row and row data into a single string
    const myJsonString = JSON.stringify(result);

    const ws = XLSX.utils.json_to_sheet(result);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

    // Highlight cells in green where "Type" is "Current Subscription"
    result.forEach((row, rowIndex) => {
      if (row.Type === "Current Subscription") {
        const cellRef = XLSX.utils.encode_cell({ c: 5, r: rowIndex }); 
        ws[cellRef].s = { fill: { fgColor: { rgb: 'C6EFCE' } } }; // green fill color
      }
    });

      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, "Subscription-Details.xlsx");
    };

  return (
    <>
      <Header />
      <Sidenav />
        <div class="WrapperArea">
          <div class="WrapperBox">
          <div class="Small-Wrapper">
            <div class="NewTitleBox">
              <div className="arrow-left">
                <Link to="/subscription-hotel">
                  <i class="fa fa-arrow-left arrow-back" aria-hidden="true"></i>
                </Link>
                <h4 className="Title">Subscription Details</h4>
              </div>
                { (currentsubs.length > 0 || oldsubs > 0 || futuresubs > 0) &&
                <>
                <ul>
                  <li>
                    {/* <Link to="/subscription-transaction" className="Button">
                      Subscription Transaction
                    </Link> */}
                    <a
                      class="add"
                      onClick={() =>
                        downloadSubscriptionData()
                      }
                    >
                      Export to Excel
                    </a>
                  </li>
                </ul>
                </>}
            </div> 
                
            { currentsubs.length > 0 && 
            <h4 className="subscriptionDetailsHeading">Current Subscription</h4> } 
            {(currentsubs.length === 0) &&
              <h5 className="subscriptionNoData">
                  No Active Subscription 
              </h5>
            }
            { (currentsubs.length > 0) && 
            <div class="TableList">
                <table>
                  <thead>
                    <tr>
                      <th>Plan</th>
                      <th>Start Date</th>
                      <th>Duration</th>
                      <th>End Date</th>
                      <th>Coupon Used</th>
                      <th>Added Rooms Subscirption</th>
                      <th>Amount</th>
                      <th>Order ID</th>
                      {/* <th>Invoice</th> */}
                    </tr>
                  </thead>

                  <tbody>
                    {currentsubs.map((currentsub) => (
                      <tr>
                        <td>{currentsub?.subscriptionDetails?.name}</td>
                        <td>{getDate(currentsub.startDate)}</td>
                        <td>{currentsub?.subscriptionDetails?.durationInDays} days</td>
                        <td>{getDate(currentsub.endDate)}</td>
                        { currentsub.couponCode ? 
                          <td>{currentsub.couponCode}</td> : <td>-</td>}
                        {currentsub?.addedRoomSubscriptionDetails?.length > 0 ? 
                          <td> 
                            <Link
                              onClick={(e) => 
                                handleAdditionalSubscriptionModal(e, currentsub?.addedRoomSubscriptionDetails)}
                            >
                                <i class="fa fa-eye"></i>
                            </Link>
                          </td> : 
                          <td>None</td> 
                        }
                        <td>{currentsub.totalPrice}</td>
                        <td>{currentsub.paymentOrderId}</td>
                        {/* <td>
                            <Link 
                              to="/subscription-transaction"
                              state = {{search: currentsub?.paymentOrderId}}
                            >
                                Invoice
                            </Link>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div> }
                <br></br>
                <br></br>
            
              {oldsubs.length > 0 && <h4 className="subscriptionDetailsHeading">Subscription History</h4> }
              { (oldsubs.length > 0) && 
                    <div class="TableList">
                        <table>
                          <thead>
                            <tr>
                              <th>Plan</th>
                              <th>Start Date</th>
                              <th>Duration</th>
                              <th>End Date</th>
                              <th>Coupon Used</th>
                              <th>Added Rooms Subscirption</th>
                              <th>Amount</th>
                              <th>Order ID</th>
                              {/* <th>Invoice</th> */}
                            </tr>
                          </thead>

                          <tbody>
                            { visibleOldHistory && visibleOldHistory.map((oldSub) => (
                              <tr>
                                <td>{oldSub?.subscriptionDetails?.name}</td>
                                <td>{getDate(oldSub.startDate)}</td>
                                <td>{oldSub?.subscriptionDetails?.durationInDays} days</td>
                                <td>{getDate(oldSub.endDate)}</td>
                                { oldSub.couponCode ? 
                                    <td>{oldSub.couponCode}</td> : <td>-</td>}
                                {oldSub?.addedRoomSubscriptionDetails?.length > 0 ? 
                                  <td> 
                                    <Link
                                      onClick={(e) => 
                                        handleAdditionalSubscriptionModal(e, oldSub?.addedRoomSubscriptionDetails)}
                                        >
                                        <i class="fa fa-eye"></i>
                                    </Link>
                                  </td> : 
                                  <td>None</td> 
                                }
                                <td>{oldSub.totalPrice}</td>
                                <td>{oldSub.paymentOrderId}</td>
                                {/* <td>
                                    <Link 
                                          to="/subscription-transaction"
                                          state = {{search: oldSub?.paymentOrderId}}
                                        >
                                          Invoice
                                    </Link>
                                </td> */}
                              </tr>
                            )) }
                          </tbody>
                        </table>
                      </div> }

                      <div className="subscriptionDetailsViewMoreContainer">
                        {visibleOldHistory ? visibleOldHistory.length < oldsubs.length && (
                          <Link to="/subscription-history" state={{hotelId: hotelId}}>
                            View More
                          </Link>
                        ): ""}
                      </div>
                      
                  { (futuresubs.length > 0) && 
                    <h4 className="subscriptionDetailsHeading">Future Subscriptions</h4> }
                  { (futuresubs.length > 0) && 
                          <div class="TableList">
                              <table>
                                <thead>
                                  <tr>
                                    <th>Plan</th>
                                    <th>Start Date</th>
                                    <th>Duration</th>
                                    <th>End Date</th>
                                    <th>Coupon Used</th>
                                    <th>Added Rooms Subscirption</th>
                                    <th>Amount</th>   
                                    <th>Order ID</th>
                                    {/* <th>Invoice</th> */}
                                  </tr>
                                </thead>

                                <tbody>
                                  { visibleFutureSubscription && visibleFutureSubscription.map((futuresub) => (
                                    <tr>
                                      <td>{futuresub?.subscriptionDetails?.name}</td>
                                      <td>{getDate(futuresub.startDate)}</td>
                                      <td>{futuresub?.subscriptionDetails?.durationInDays} days</td>
                                      <td>{getDate(futuresub.endDate)}</td>
                                      { futuresub.couponCode ? 
                                        <td>{futuresub.couponCode}</td> : <td>-</td>}
                                      {futuresub?.addedRoomSubscriptionDetails?.length > 0 ? 
                                        <td> 
                                          <Link
                                            onClick={(e) => 
                                              handleAdditionalSubscriptionModal(e, futuresub?.addedRoomSubscriptionDetails)}
                                              >
                                              <i class="fa fa-eye"></i>
                                          </Link>
                                        </td> : 
                                        <td>None</td> 
                                      }
                                      <td>{futuresub.totalPrice}</td>
                                      <td>{futuresub.paymentOrderId}</td>
                                      {/* <td>
                                        <Link 
                                            to="/subscription-transaction"
                                            state = {{search: futuresub?.paymentOrderId}}
                                        >
                                          Invoice
                                        </Link>
                                      </td> */}
                                    </tr>
                                  )) }
                                </tbody>
                              </table>
                            </div> }

                            <div className="subscriptionDetailsViewMoreContainer">
                          
                              {/* {visibleFutureSubscription ? 
                              visibleFutureSubscription.length < futuresubs.length && (
                                <Link to="/subscription-future">
                                  View More
                                </Link>
                              ): ""} */}
                            </div>




                      { addedRoomsSubscriptionDetailsModal && 
                      <Modal show={handleAdditionalSubscriptionModal} 
                              onHide={handleAdditionalSubscriptionModalClose} 
                              className="ModalBox"
                      >
                        <Modal.Body>
                          <div className="subscriptionBillingModalContent">
                            <h4 className="subscriptionBillingTitle">Added Rooms Subscription Details</h4>
                            { addedSubscriptionDetail.map((item,i) => (
                              <div className="suscriptionBillingPlanDetailsBox">
                                  <div>
                                    <span className="subscriptionBillingPlanDetails"> 
                                      New Rooms Count
                                    </span>
                                    <span className="subscriptionBillingPlanValues">
                                        {item?.totalRooms}
                                    </span> 
                                  </div>
                                  <div>
                                    <span className="subscriptionBillingPlanDetails"> 
                                      Total Amount
                                    </span>
                                    <span className="subscriptionBillingPlanValues">
                                      ₹ {item?.Amount}
                                    </span> 
                                  </div>
                                  {item?.subscriptionDurationInDays && <div>
                                    <span className="subscriptionBillingPlanDetails"> 
                                      Subscription Duration
                                    </span>
                                    <span className="subscriptionBillingPlanValues">
                                      {item?.subscriptionDurationInDays} days
                                    </span> 
                                  </div>}
                                  <div>
                                    <span className="subscriptionBillingPlanDetails"> 
                                      Date
                                    </span>
                                    <span className="subscriptionBillingPlanValues">
                                      {getDate(item?.paymentDate)}
                                    </span> 
                                  </div>
                                  <div>
                                    <span className="subscriptionBillingPlanDetails"> 
                                      Order Id
                                    </span>
                                    <span className="subscriptionBillingPlanValues">
                                       {item?.paymentOrderId}
                                    </span> 
                                  </div>
                                  {/* <div style={{display:"flex", justifyContent:"flex-end", paddingRight:"20px", paddingTop:"20px"}}>
                                      <Link 
                                          to="/subscription-transaction"
                                          state = {{search: item?.paymentOrderId}}
                                      >
                                        Invoice
                                      </Link>
                                  </div> */}
                              </div>
                            ))}              
                          </div>
                          <br></br>
                          <div>
                              <button 
                                class="subscriptionBillingCancelButton" 
                                onClick={() => handleAdditionalSubscriptionModalClose()}
                              >
                                cancel
                              </button>
                          </div>
                        </Modal.Body>
                      </Modal> }              
                </div>
              </div>
            </div>
    </>
  );
};

export default SubscriptionDetails;
