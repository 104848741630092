import React, { useState, useEffect } from "react";
import Header from "./header";
import Sidenav from "./sidenav";
import { Link, useNavigate, useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import TimePicker from "@semcore/time-picker";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { RoomCategoryListAction } from "../redux/actions/roomAction";


import {
  AddInviteGuestAction,
  CheckinwithotpAction,
} from "../redux/actions/guestAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
const initialState = {
  name: "",
  email: "",
  phone: "",
  gender: "",
  bookingId: "",
  date: "",
  stay_duration: "",
  meal: "",
  status: "",
  checkInDate: "",
  numberOfRoom: "",
  errorMsg: {},
};

const GuestsManagement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [otp, setOtp] = useState({ otp: "", errorMsg: "" });
  const { categoryList } = useSelector((state) => state.roomReducer);

  const hotelDetails = JSON.parse(window.localStorage.getItem("hotelDetails"));

  const [CheckInModal, setCheckIn] = useState(false);
  const handleCheckInModalClose = () => setCheckIn(false);
  const handleCheckInModalShow = (id) => {
    setCheckIn(true);
  };

  const [QrModal, setQr] = useState(false);
  const handleQrModalClose = () => setQr(false);
  const handleQrModalShow = (id) => {
    setQr(true);
    setCheckIn(false);
  };

  const [InviteGuestModal, setInviteGuest] = useState(false);
  const handleInviteGuestModalClose = () => {
    setInviteGuest(false)
  
  
    updateState({
      name: "",
      email: "",
      phone: "",
      gender: "",
      bookingId: "",
      date: "",
      stay_duration: "",
      meal: "",
      status: "",
      checkInDate: "",
      numberOfRoom: "",
      errorMsg: {},
    });};
  const handleInviteGuestnModalShow = (id) => {
    setInviteGuest(true);
  };

  const [iState, updateState] = useState(initialState);

  const {
    name,
    email,
    phone,
    gender,
    bookingId,
    stay_duration,
    meal,
    status,
    checkInDate,
    numberOfRoom,
    roomCategory,
    errorMsg,
  } = iState;

  const handleValidate = () => {
    let formIsValid = true;
    let errorMsg = {};
    if (!name) {
      formIsValid = false;
      errorMsg.name = "* Please enter name.";
    }
    if (!gender) {
      formIsValid = false;
      errorMsg.gender = "* Please select gender.";
    }
    if (!bookingId) {
      formIsValid = false;
      errorMsg.address = "* Please enter booking Id.";
    }
    if (!checkInDate) {
      formIsValid = false;
      errorMsg.checkInDate = "* Please enter date.";
    }
    if (!email) {
      errorMsg.email = "* Please enter email.";
      formIsValid = false;
    } else if (!/^.+?@.+?\..+$/.test(email)) {
      errorMsg.email = "* Email format is not valid";
      formIsValid = false;
    }
    if (!phone) {
      errorMsg.phone = "* Please enter phone number.";
      formIsValid = false;
    } else if (phone.length < 10) {
      errorMsg.phoneValid = "* Please enter valid phone number.";
      formIsValid = false;
    }

    if (!stay_duration) {
      errorMsg.stay_duration = "* Please enter duration.";
      formIsValid = false;
    }

    if (!meal) {
      errorMsg.meal = "* Please enter meal packege.";
      formIsValid = false;
    }

    if (!numberOfRoom) {
      errorMsg.numberOfRoom = "* Please enter number ofroom.";
      formIsValid = false;
    }

    if (!roomCategory) {
      errorMsg.roomCategory = "* Please enter room category.";
      formIsValid = false;
    }

    updateState({ ...iState, errorMsg: errorMsg });

    return formIsValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    updateState({ ...iState, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formIsValid = handleValidate();

    if (formIsValid) {
      let data = {
        email,
        phone: `+${phone}`,
        name,
        gender,
        bookingId,
        stay_duration: stay_duration,
        meal,
        status: "booking",
        checkInDate,
        numberOfRoom,
        roomCategory,
        hotelId:id,
      };

      dispatch(AddInviteGuestAction(data))
        .then((res) => {
          if (res.status == 200) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setInviteGuest(false);

            updateState({
              name: "",
              email: "",
              phone: "",
              gender: "",
              bookingId: "",
              date: "",
              stay_duration: "",
              meal: "",
              status: "",
              checkInDate: "",
              numberOfRoom: "",
              errorMsg: {},
            });
          } else {
            console.log(res);

            toast.error(res.response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          // toast.error(err.response.data.message, {
          //   position: toast.POSITION.TOP_RIGHT,
          // });
        });
    }
  };
  const handleSubmitOtp = () => {
    console.log(otp.otp);
    if (otp.otp == "") {
      otp.errorMsg = "* Please enter otp ";
    } else {
      dispatch(CheckinwithotpAction({ otp: otp?.otp, hotelId: id })).then(
        (res) => {
          if (res.status === 200) {
            // res.data.data.co_occupants?.[0]?.userId

            navigate(`/guests-verification/${id}`, { state: res.data.data });

            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      );
    }
  };


  useEffect(() => {
    dispatch(RoomCategoryListAction({hotelId :id}));

  }, []);


  window.scrollTo(0, 0);


  
  return (
    <div>
      <Header />
      <Sidenav />

      <div class="WrapperArea">
        <div class="WrapperBox">
          <div class="HotelLocation HotelLocation2">
            <div class="back-btn">
              <Link to={`/hotel-details/${id}`}>
                <i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back{" "}
              </Link>
            </div>

            <aside>
              <h3>{hotelDetails?.name}</h3>
              <p>
                <span>
                  <i class="fa fa-map-marker"></i>
                </span>{" "}
                {hotelDetails?.address}
              </p>
            </aside>
          </div>

          <div class="Small-Wrapper">
            <div class="NewTitleBox">
              <h4>Guest Stay Management</h4>
              <Link to="/guests-notifications">Guest Notifications</Link>
            </div>

            <div class="GuestsStayArea">
              <article>
                <h4>
                  Guests Check-Ins
                  <a onClick={handleCheckInModalShow}>New Check-In</a>
                </h4>
                <ul>
                  <li>
                    <a onClick={handleInviteGuestnModalShow}>
                      <h5>
                        Update Upcoming check-ins{" "}
                        <span>
                          (sent invite to guest to install App for Check-In)
                        </span>
                      </h5>
                    </a>
                  </li>
                  <li>
                    <Link to={`/guests-invited/${id}`}>
                      <h5>Invited Guests</h5>
                    </Link>
                  </li>
                  <li>
                    <Link to={`/guests-checked-in/${id}`}>
                      <h5>Check-In Guests</h5>
                    </Link>
                  </li>
                </ul>
              </article>

              <article>
                <h4>Guests Check-Outs</h4>
                <ul>
                  <li>
                    <Link to={`/guests-checkout-request/${id}`}>
                      <h5>Check-out Requests</h5>
                    </Link>
                  </li>
                  <li>
                    <Link to={`/guests-checkout-approve/${id}`}>
                      <h5>Approved Checkouts </h5>
                    </Link>
                  </li>
                  <li>
                    <Link to={`/guests-checkout-completed/${id}`}>
                      <h5>Completed Check-outs</h5>
                    </Link>
                  </li>
                </ul>
              </article>

              <article>
                <h4>Others</h4>
                <ul>
                  <li>
                    <Link to={`/guests-extended-checkout/${id}`}>
                      <h5>Extend Stay Requests</h5>
                    </Link>
                  </li>
                  <li>
                    <Link to={`/guests-approve-extended-checkout/${id}`}>
                      <h5>Extended Approved Requests</h5>
                    </Link>
                  </li>
                  <li>
                    <Link to={`/guests-billing-payment/${id}`}>
                      <h5>Billing &amp; Payments</h5>
                    </Link>
                  </li>
                </ul>
              </article>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={CheckInModal}
        onHide={handleCheckInModalClose}
        className="ModalBox "
      >
        <Modal.Body>
          <div class="Category">
            <a onClick={handleCheckInModalClose} class="CloseModal">
              ×
            </a>
            <h3>New Check-In</h3>
            <div class="CheckinNew">
              <ul>
                <li>
                  <a onClick={handleQrModalShow}>Scan QR Code</a>
                </li>
                <li>
                  <p>
                    <span>Or proceed with</span>
                  </p>
                </li>
                <li>
                  <Link to={`/guests-manual/${id}`}>Manual Check-In</Link>
                </li>
              </ul>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={InviteGuestModal}
        onHide={handleInviteGuestModalClose}
        className="ModalBox "
      >
        <Modal.Body>
          <div class="Category">
            <a onClick={handleInviteGuestModalClose} class="CloseModal">
              ×
            </a>
            <h3>Invite Guest to install App</h3>
            <div class="form-group">
              <label>Guest Name</label>
              <input
                value={name}
                name="name"
                onChange={handleChange}
                type="text"
                class="form-control"
              />
              {errorMsg.name && <p style={{ color: "red" }}>{errorMsg.name}</p>}

              <div class="form-group">
                <label>Select Gender</label>
                <select
                  value={gender}
                  name="gender"
                  onChange={handleChange}
                  class="form-control"
                >
                  <option>Select</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>
              {errorMsg.gender && (
                <p style={{ color: "red" }}>{errorMsg.gender}</p>
              )}
            </div>
            <div class="form-group">
              <label>Phone Number</label>
              <PhoneInput
                country={"in"}
                value={phone}
                enableSearch={true}
                countryCodeEditable={false}
                onChange={(phone) => updateState({ ...iState, phone })}
              />
              {errorMsg.phone && (
                <p style={{ color: "red" }}>{errorMsg.phone}</p>
              )}
            </div>
            <div class="form-group">
              <label>Email Address</label>
              <input
                type="text"
                class="form-control "
                value={email}
                name="email"
                onChange={handleChange}
              />
              {errorMsg.email && (
                <p style={{ color: "red" }}>{errorMsg.email}</p>
              )}
            </div>
            <div class="form-group">
              <label>Booking ID</label>
              <input
                type="text"
                class="form-control"
                value={bookingId}
                name="bookingId"
                onChange={handleChange}
              />
              {errorMsg.bookingId && (
                <p style={{ color: "red" }}>{errorMsg.bookingId}</p>
              )}
            </div>
            <div class="form-group">
              <label>Select Check-In date</label>
              <input
                type="date"
                class="form-control"
                value={checkInDate}
                name="checkInDate"
                onChange={handleChange}
              />
              {errorMsg.checkInDate && (
                <p style={{ color: "red" }}>{errorMsg.checkInDate}</p>
              )}
            </div>
            <div class="form-group">
              <label>Stay Duration</label>
              <input
                type="number"
                value={stay_duration}
                name="stay_duration"
                onChange={handleChange}
                class="form-control"
              />
              {errorMsg.stay_duration && (
                <p style={{ color: "red" }}>{errorMsg.stay_duration}</p>
              )}
            </div>
            <div class="form-group">
              <label>Number of Rooms</label>
              <input
                type="number"
                value={numberOfRoom}
                name="numberOfRoom"
                onChange={handleChange}
                class="form-control"
              />
              {errorMsg.numberOfRoom && (
                <p style={{ color: "red" }}>{errorMsg.numberOfRoom}</p>
              )}
            </div>
            <div class="form-group">
              <label>Room Category</label>
              <select
                name="roomCategory"
                onChange={handleChange}
                value={roomCategory}
                class="form-control"
              >
                <option>Select </option>
                {categoryList?.roomCategory?.map((item) => (
                  <option value={item}>{item}</option>
                ))}
              </select>
              {errorMsg.roomCategory && (
                <p style={{ color: "red" }}>{errorMsg.roomCategory}</p>
              )}
            </div>
            <div class="form-group">
              <label>Meal Package</label>
              <select
                value={meal}
                name="meal"
                onChange={handleChange}
                class="form-control"
              >
                <option value="">Select </option>
                <option value="CP">
                  CP (Continental Plan) With Breakfast{" "}
                </option>
                <option value="MAP">
                  MAP Buffer (Breakfast + Buffet Lunch/Dinner){" "}
                </option>
                <option value="AP">
                  AP (Buffet Breakfast + Buffet Lunch + Buffet Dinner){" "}
                </option>
                <option value="EP">EP (all meals are extra chargeable) </option>
              </select>
              {errorMsg.meal && <p style={{ color: "red" }}>{errorMsg.meal}</p>}
            </div>
            <button class="Button" data-dismiss="modal" onClick={handleSubmit}>
              Invite
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={QrModal} onHide={handleQrModalClose} className="ModalBox ">
        <Modal.Body>
          <div class="Category">
            <a onClick={handleQrModalClose} class="CloseModal">
              ×
            </a>
            <div class="QRScanGuest">
              <h4>
                Please scan customer QR or enter OTP to proceed with check-In
              </h4>
              {/* <figure>
                <img src={require("../assets/image/QRCode.png")} />
              </figure> */}
              <p>
                <span class="qr-pera">Or</span>
              </p>

              <div class="form-group">
                <label>Enter 4-Digit generated OTP Code</label>
                <input
                  onChange={(e) => setOtp({ ...otp, otp: e.target.value })}
                  type="number"
                  class="form-control"
                />
                {otp.errorMsg && <p style={{ color: "red" }}>{otp.errorMsg}</p>}
              </div>
              <a class="Button" onClick={handleSubmitOtp}>
                Confirm
              </a>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default GuestsManagement;
