import React from 'react'
import { Link } from 'react-router-dom'
import congrst from '../assets/image/congrts.png'

const Congratulation = () => {
  return (
    <div>
        
    <div class="LoginArea">
        <div class="LoginBox">
            <figure>
                <img src={congrst} alt="" class="congradulation" />
            </figure>

            <form>
                <h3>CONGRATULATIONS!
                </h3>
                <h5>Your password has been changed successfully!</h5>

                
                <Link to="/" class="Button">CONTINUE TO LOGIN</Link>
            </form>
        </div>
    </div>
    </div>
  )
}

export default Congratulation