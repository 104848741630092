import { chatConstant } from "../actionTypes";
const initialState = {
    chatHistory: [],
    chatList:[],
    chatRoomHistory:[],
    loader: false,
    error: {},
  };
  
  export default function chatReducer(
    state = initialState,
    { type, payload }
    
  ) {
    switch (type) {

      case chatConstant.CHAT_HISTORY_INITIATE:
        return { ...state, loader: true, chatHistory: [] };
  
      case chatConstant.CHAT_HISTORY_SUCCESS:
        return { ...state, loader: false, chatHistory: payload.data };
  
      case chatConstant.CHAT_HISTORY_FAILURE:
        return { ...state, loader: false, error: payload };



        case chatConstant.CHAT_LIST_INITIATE:
            return { ...state, loader: true, chatList: [] };
      
          case chatConstant.CHAT_LIST_SUCCESS:
            return { ...state, loader: false, chatList: payload.data };
      
          case chatConstant.CHAT_LIST_FAILURE:
            return { ...state, loader: false, error: payload };
    

            case chatConstant.CHAT_ROOM_HISTORY_INITIATE:
              return { ...state, loader: true, chatRoomHistory: [] };
        
            case chatConstant.CHAT_ROOM_HISTORY_SUCCESS:
              return { ...state, loader: false, chatRoomHistory: payload.data };
        
            case chatConstant.CHAT_ROOM_HISTORY_FAILURE:
              return { ...state, loader: false, error: payload };
  


  
  
      default:
        return state;
    }
  }
  