import React, { useEffect, useState } from 'react'
import Header from './header'
import Sidenav from './sidenav';

import Modal from "react-bootstrap/Modal";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { FinanceListAction } from '../redux/actions/financeAction';
import moment from 'moment/moment';
import NoDataFound from "./noDataFound";
import Pagination from "react-js-pagination";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx"
import Loader from './loader';
import { toast } from "react-toastify";


const FinanceManagement = () => {
    const dispatch = useDispatch()
    const [activePage, setActivePage] = useState(1);

    const {financeList,loader} =useSelector((state)=>state.financeReducer)

    const [userId, setUserId] =useState('')
    const [filterData, setFilterData] = useState({
        search: "",
        fromDate: "",
        toDate: "",
        timeFrame:"",
        page:1,
        status:""
      });
    


    const [ApproveModal, setApproveShow] = useState(false);
    const handleApproveClose = () => setApproveShow(false);
    const handleApproveShow = (id, status) => {
        setUserId(id)
        setApproveShow(true);
    };
  

    const [AddHotelModal, setAddHotel] = useState(false);
    const handleAddHotelModalClose = () => setAddHotel(false);
    const handleAddHotelModalShow = (id) => {
      setAddHotel(true);
    };




    useEffect(()=>{
        dispatch(FinanceListAction())

    },[])




    
      const handleFilter = (e) => {
        if(!filterData.fromDate=="" && filterData.toDate=="" ){
          toast.error("To date not be empty", {
              position: toast.POSITION.TOP_RIGHT,
            });
          
      }else{

      
        dispatch(FinanceListAction({search:filterData?.search, fromDate: filterData?.fromDate, toDate: filterData?.toDate, timeFrame:filterData?.timeFrame, status:filterData?.status}))
      }
      };
    
      const handleRefresh = (e) => {
        setFilterData({...filterData, search: "", fromDate: "",toDate:"", timeFrame:'' , status:""});
    
        dispatch(FinanceListAction())
    };

    const handleSearch = (e) => {
        dispatch(FinanceListAction({search:e.target.value}))
        setFilterData((prev) => ({ ...prev, search: e.target.value }));
      };
    
    
      const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        filterData.page = pageNumber;
        dispatch(FinanceListAction({search:filterData?.search, fromDate: filterData?.fromDate, toDate: filterData?.toDate, timeFrame:filterData?.timeFrame, status:filterData?.status, page:pageNumber}))


      };







      const downloadRowData = (rowData, fileName) => {
        const fileType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        let result = rowData?.map((item, i) => {
          return {
            "Sr. No": i + 1,
            "Trans Id": "TRAN#23423d",
            "Transaction Date": moment(item?.createdAt).format('ll'),
            "Hotel Name": item?.hotelId?.[0]?.name,
            "Location": item?.hotelId?.[0]?.address,
            "Phone Number": item?.hotelId?.[0]?.phone,
          "Transaction Amount": item?.totalAmount?.toFixed(2),
            "Transaction Type": "Credit",
            "Mode of Payment":
              "Credit Card"
,
          };
        });
    
        // Combine the header row and row data into a single string
        const myJsonString = JSON.stringify(result);
    
        const ws = XLSX.utils.json_to_sheet(result);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, "finance-record.xlsx");
      };


      window.scrollTo(0, 0)

      console.log(financeList?.totalRevenueGenrated ,'DDDDDDDDd')
  return (
    <>
    <Header />
    <Sidenav />

    <div class="WrapperArea">
        <div class="WrapperBox">
            <div class="Small-Wrapper">
                <div class="TitleBox">
                    <h4 class="Title">Finance & Analytics</h4>

                    <ul>
                        <li><a class="add" onClick={()=>downloadRowData(financeList?.[0]?.data)}>Export to Excel</a></li>
                    </ul>
                </div>

                <div class="Filter">
                    <div class="form-group">
                        <label>Search</label>
                        <input type="text"onChange={handleSearch}  value={filterData?.search} class="form-control" placeholder="Search by name or Id" />
                    </div>
                   
                    <div class="form-group">
                        <label>From Date</label>
                        <input type="date" value={filterData?.fromDate}  onChange={(e)=>setFilterData({...filterData, fromDate:e.target.value, timeFrame:""})} class="form-control" />
                    </div>
                    <div class="form-group">
                        <label>To Date</label>
                        <input type="date" disabled={!filterData?.fromDate} value={filterData?.toDate} min={filterData?.fromDate} onChange={(e)=> setFilterData({...filterData, toDate:e.target.value ,timeFrame:""})} class="form-control" />
                    </div> 
                    <div class="form-group">
                        <label>Timeframe</label>
                        <select value={filterData?.timeFrame} onChange={(e)=> setFilterData({...filterData,fromDate:"",toDate:"", timeFrame:e.target.value})} class="form-control">
                            <option value="">--Select--</option>
                            <option value="Today">Today</option>
                            <option value="Week">This Week</option>
                            <option value="Month">This Month</option>
                        </select>
                    </div> 
                    <div class="form-group">
                        <label>&nbsp;</label>
                        <button onClick={handleFilter} class="Button">Apply</button>
                        <button onClick={handleRefresh} class="Button Cancel"><i class="fa fa-refresh"></i></button>
                    </div>
                </div>

           

                <div class="Subscription-cancel">
                   
                    <aside>
                        <Link to="/analytics">View Analytics
                            <i class="fa fa-arrow-right" aria-hidden="true"></i>
                        </Link>

                        <Link to="/service-package">Service package purchases and usage
                            <i class="fa fa-arrow-right" aria-hidden="true"></i>
                        </Link>
                    </aside>
                    <div class="earning">
                        <p>Total Earnings : <span>{financeList?.totalRevenueGenrated?.toFixed(2)} INR</span></p>
                    </div>
                </div>

                
                {loader? <Loader />:
                <div class="TableList">
                    <table style= {{width: "120%"}}>
                        <thead>
                            <tr>
                                <th>Trans Id</th>
                                <th>Transaction date</th>
                                <th>Hotel Name</th>
                                <th>Location</th>
                                <th>Phone Number</th>
                                <th>Transaction Amount</th>
                                <th>Transaction Type</th>
                                <th>Mode of Payment</th>
                            </tr>
                        </thead>
                        <tbody>
                            {financeList?.hotelTrans?.[0]?.data?.map((item, i)=>
                                                        <tr>
                                                        <td>{item?.transId}</td>
                                                        <td>{moment(item?.createdAt).format('ll')}</td>
                                                        <td>{item?.hotelId?.[0]?.name}</td>
                                                        <td>{item?.hotelId?.[0]?.address}</td>
                                                        <td>{item?.hotelId?.[0]?.phone}</td>
                                                        <td>{item?.totalAmount?.toFixed(2)}</td>
                                                        <td>Credit</td>
                                                        <td>Credit Card</td>


                                                        
                                                    </tr>
                            
                            )}
                            
                            
                            
                        </tbody>
                    </table>
                </div>}
                {loader ? (
            ""
          ) : financeList?.hotelTrans?.[0]?.data ? (
            ""
          ) : (
            <NoDataFound />
          )}


          {financeList?.hotelTrans?.[0]?.metadata?.[0]?.total > 0 ? (
            <div className="Pagination">
              <Pagination
                activePage={activePage}
                itemsCountPerPage={10}
                totalItemsCount={financeList?.hotelTrans?.[0]?.metadata?.[0]?.total}
                pageRangeDisplayed={5}
                onChange={(e) => handlePageChange(e)}
              />
            </div>
          ) : (
            ""
          )}
            </div>
        </div>
    </div>

    
    <Modal
        show={AddHotelModal}
        onHide={handleAddHotelModalClose}
        className="ModalBox "
      >
        <Modal.Body>
        <div class="Category">
                            <a onClick={handleAddHotelModalClose} class="CloseModal">×</a>
                            <h3>Invite Partner Hotel</h3>
                            <div class="form-group">
                                <label>Hotel Name</label>
                                <input type="text" class="form-control" />
                            </div>
                            <div class="form-group">
                                <label>Phone Number</label>
                                <input type="text" class="form-control" />
                            </div>
                            <div class="form-group">
                                <label>Email Address</label>
                                <input type="text" class="form-control" />
                            </div>
                            <button class="Button" data-dismiss="modal">Send Invite </button>
                        </div>
        </Modal.Body>
      </Modal>
   
    


    </>
  )
}

export default FinanceManagement