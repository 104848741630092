

import { roleConstant } from "../actionTypes";

import axios from "../../axiosInstance"
import { baseUrl } from "../../config/config";
import { isLoggedIn } from "../../utils";




// ADD ROLE

export const addRoleInitiate=() =>({
    type:roleConstant.ADD_ROLE_INITIATE

})

export const addRoleSuccess=(data) =>({
    type:roleConstant.ADD_ROLE_SUCCESS,
    payload:data

})

export const addRoleFailure=(data) =>({
    type:roleConstant.ADD_ROLE_FAILURE,
    payload : data

})


export const  AddRoleAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(addRoleInitiate());
        const response = await axios.post(`${baseUrl}/admin/staff/add`, payload,{
            headers: {authorization:`${token}`}})

            if (response.status >= 200 && response.status <= 300) {
              dispatch(addRoleSuccess(response.data));
        } else {
          dispatch(addRoleFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(addRoleFailure(err));
        return err;
      }


}


// EDIT ROLE

export const editRoleInitiate=() =>({
    type:roleConstant.UPDATE_ROLE_INITIATE

})

export const editRoleSuccess=(data) =>({
    type:roleConstant.UPDATE_ROLE_SUCCESS,
    payload:data

})

export const editRoleFailure=(data) =>({
    type:roleConstant.UPDATE_ROLE_FAILURE,
    payload : data

})


export const  EditRoleAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(editRoleInitiate());
        const response = await axios.put(`${baseUrl}/admin/staff/update`, payload,{
            headers: {authorization:`${token}`}})

        if (response.status >= 200 && response.status <= 300) {
          dispatch(editRoleSuccess(response.data));
        } else {
          dispatch(editRoleFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(editRoleFailure(err));
        return err;
      }


}



// EDIT ROLE

export const statusRoleInitiate=() =>({
  type:roleConstant.STATUS_ROLE_INITIATE

})

export const statusRoleSuccess=(data) =>({
  type:roleConstant.STATUS_ROLE_SUCCESS,
  payload:data

})

export const statusRoleFailure=(data) =>({
  type:roleConstant.STATUS_ROLE_FAILURE,
  payload : data

})


export const  StatusRoleAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(statusRoleInitiate());
      const response = await axios.put(`${baseUrl}/admin/staff/block`, payload,{
          headers: {authorization:`${token}`}})

      if (response.status >= 200 && response.status <= 300) {
        dispatch(statusRoleSuccess(response.data));
      } else {
        dispatch(statusRoleFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(statusRoleFailure(err));
      return err;
    }


}




// REMOVE ROLE

export const removeRoleInitiate=() =>({
    type:roleConstant.REMOVE_ROLE_INITIATE

})

export const removeRoleSuccess=(data) =>({
    type:roleConstant.REMOVE_ROLE_SUCCESS,
    payload:data

})

export const removeRoleFailure=(data) =>({
    type:roleConstant.REMOVE_ROLE_FAILURE,
    payload : data

})


export const  RemoveRoleAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(removeRoleInitiate());
        const response = await axios.delete(`${baseUrl}/admin/staff/delete`, {
          headers: {Authorization:`${token}`},params: payload})

        if (response.data.sucess === true) {
          dispatch(removeRoleSuccess(response.data));
        } else {
          dispatch(removeRoleFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(removeRoleFailure(err));
        return err;
      }


}



//  ROLE LIST

export const foodbeverageListInitiate=() =>({
    type:roleConstant.ROLE_MANAGEMENT_INITIATE

})

export const foodbeverageListSuccess=(data) =>({
    type:roleConstant.ROLE_MANAGEMENT_SUCCESS,
    payload:data

})

export const foodbeverageListFailure=(data) =>({
    type:roleConstant.ROLE_MANAGEMENT_FAILURE,
    payload : data

})


export const  RoleListAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(foodbeverageListInitiate());
        const response = await axios.get(`${baseUrl}/admin/staff/list`, {
          headers: {Authorization:`${token}`},params: payload})

          if (response.data.success === true) {
            dispatch(foodbeverageListSuccess(response.data));
        } else {
          dispatch(foodbeverageListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(foodbeverageListFailure(err));
        return err;
      }


}


