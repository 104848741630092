import { hotelConstant } from "../actionTypes";

import axios from "../../axiosInstance"
import { baseUrl } from "../../config/config";
import { isLoggedIn } from "../../utils";




// RATING

export const ratingInitiate=() =>({
    type:hotelConstant.HOTEL_RATING_INITIATE

})

export const ratingSuccess=(data) =>({
    type:hotelConstant.HOTEL_RATING_SUCCESS,
    payload:data

})

export const ratingFailure=(data) =>({
    type:hotelConstant.HOTEL_RATING_FAILURE,
    payload : data

})


export const  RatingAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(ratingInitiate());
        const response = await axios.get(`${baseUrl}/admin/hotel/hotelreview/rating`, {
          headers: {Authorization:`${token}`},params: payload})


        if (response.data.success === true) {
          dispatch(ratingSuccess(response.data));
        } else {
          dispatch(ratingFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(ratingFailure(err));
        return err;
      }


}


// REVIEW LIST

export const reviewListInitiate=() =>({
    type:hotelConstant.HOTEL_REVIEW_LIST_INITIATE

})

export const reviewListSuccess=(data) =>({
    type:hotelConstant.HOTEL_REVIEW_LIST_SUCCESS,
    payload:data

})

export const reviewListFailure=(data) =>({
    type:hotelConstant.HOTEL_REVIEW_LIST_FAILURE,
    payload : data

})


export const  ReviewListAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(reviewListInitiate());
        const response = await axios.get(`${baseUrl}/admin/hotel/hotelreview/list`, {
          headers: {Authorization:`${token}`},params: payload})


        if (response.data.success === true) {
          dispatch(reviewListSuccess(response.data));
        } else {
          dispatch(reviewListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(reviewListFailure(err));
        return err;
      }


}



// REVIEW REPLY

export const reviewReplyInitiate=() =>({
    type:hotelConstant.HOTEL_REVIEW_REPLY_INITIATE

})

export const reviewReplySuccess=(data) =>({
    type:hotelConstant.HOTEL_REVIEW_REPLY_SUCCESS,
    payload:data

})

export const reviewReplyFailure=(data) =>({
    type:hotelConstant.HOTEL_REVIEW_REPLY_FAILURE,
    payload : data

})


export const  ReviewReplyAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(reviewReplyInitiate());
        const response = await axios.post(`${baseUrl}/admin/hotel/hotelreview/reply`, payload,{
          headers: {authorization:`${token}`}})


        if (response.data.sucess === true) {
          dispatch(reviewReplySuccess(response.data));
        } else {
          dispatch(reviewReplyFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(reviewReplyFailure(err));
        return err;
      }


}



// HOTEL LIST
export const hotelListInitiate=() =>({
  type:hotelConstant.HOTEL_LIST_INITIATE

})

export const hotelListSuccess=(data) =>({
  type:hotelConstant.HOTEL_LIST_SUCCESS,
  payload:data

})

export const hotelListFailure=(data) =>({
  type:hotelConstant.HOTEL_LIST_FAILURE,
  payload : data

})


export const  HotelListAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(hotelListInitiate());
      const response = await axios.get(`${baseUrl}/admin/hotel/hotelList`, {
        headers: {Authorization:`${token}`},params: payload})


      if (response.data.success === true) {
        dispatch(hotelListSuccess(response.data));
      } else {
        dispatch(hotelListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(hotelListFailure(err));
      return err;
    }


}



// HOTEL STATUS CHANGE
export const hotelStatusInitiate=() =>({
  type:hotelConstant.HOTEL_STATUS_CHANGE_INITIATE

})

export const hotelStatusSuccess=(data) =>({
  type:hotelConstant.HOTEL_STATUS_CHANGE_SUCCESS,
  payload:data

})

export const hotelStatusFailure=(data) =>({
  type:hotelConstant.HOTEL_STATUS_CHANGE_FAILURE,
  payload : data

})


export const  HotelStatusAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(hotelStatusInitiate());
      const response = await axios.put(`${baseUrl}/admin/hotel/changestatus`, payload,{
        headers: {authorization:`${token}`}})



      if (response.data.success === true) {
        dispatch(hotelStatusSuccess(response.data));
      } else {
        dispatch(hotelStatusFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(hotelStatusFailure(err));
      return err;
    }


}








// Hotel Details

export const hotelDetailsInitiate=() =>({
  type:hotelConstant.HOTEL_DETAILS_INITIATE

})

export const hotelDetailsSuccess=(data) =>({
  type:hotelConstant.HOTEL_DETAILS_SUCCESS,
  payload:data

})

export const hotelDetailsFailure=(data) =>({
  type:hotelConstant.HOTEL_DETAILS_FAILURE,
  payload : data

})


export const  HotelDetailsAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(hotelDetailsInitiate());
      const response = await axios.get(`${baseUrl}/admin/hotel/details/`, {
        headers: {Authorization:`${token}`},params: payload})


      if (response.data.success === true) {
        dispatch(hotelDetailsSuccess(response.data));
      } else {
        dispatch(hotelDetailsFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(hotelDetailsFailure(err));
      return err;
    }


}








// HOTEL INVITE
export const hotelInviteInitiate=() =>({
  type:hotelConstant.INVITE_HOTEL_INITIATE

})

export const hotelInviteSuccess=(data) =>({
  type:hotelConstant.INVITE_HOTEL_SUCCESS,
  payload:data

})

export const hotelInviteFailure=(data) =>({
  type:hotelConstant.INVITE_HOTEL_FAILURE,
  payload : data

})


export const  HotelInviterAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(hotelInviteInitiate());
      const response = await axios.post(`${baseUrl}/admin/hotel/invite`, payload,{
        headers: {authorization:`${token}`}})



      if (response.data.success === true) {
        dispatch(hotelInviteSuccess(response.data));
      } else {
        dispatch(hotelInviteFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(hotelInviteFailure(err));
      return err;
    }


}



// HOTEL TRANSACTION

export const transactionInitiate=() =>({
  type:hotelConstant.HOTEL_TRANSACTION_INITIATE

})

export const transactionSuccess=(data) =>({
  type:hotelConstant.HOTEL_TRANSACTION_SUCCESS,
  payload:data

})

export const transactionFailure=(data) =>({
  type:hotelConstant.HOTEL_TRANSACTION_FAILURE,
  payload : data

})


export const  TransactionListAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(transactionInitiate());
      const response = await axios.get(`${baseUrl}/admin/transaction/list`, {
        headers: {Authorization:`${token}`},params: payload})


      if (response.data.success === true) {
        dispatch(transactionSuccess(response.data));
      } else {
        dispatch(transactionFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(transactionFailure(err));
      return err;
    }


}




// HOTEL TRANSACTION

export const transactionDetailsInitiate=() =>({
  type:hotelConstant.HOTEL_TRANSACTION_DETAILS_INITIATE

})

export const transactionDetailsSuccess=(data) =>({
  type:hotelConstant.HOTEL_TRANSACTION_DETAILS_SUCCESS,
  payload:data

})

export const transactionDetailsFailure=(data) =>({
  type:hotelConstant.HOTEL_TRANSACTION_DETAILS_FAILURE,
  payload : data

})


export const  TransactionDetailsAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(transactionDetailsInitiate());
      const response = await axios.get(`${baseUrl}/admin/transaction/vieworder`, {
        headers: {Authorization:`${token}`},params: payload})


      if (response.data.success === true) {
        dispatch(transactionDetailsSuccess(response.data));
      } else {
        dispatch(transactionDetailsFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(transactionDetailsFailure(err));
      return err;
    }


}





// REMOVE HOTEL

export const hotelRemoveInitiate=() =>({
  type:hotelConstant.HOTEL_DELETE_INITIATE

})

export const hotelRemoveSuccess=(data) =>({
  type:hotelConstant.HOTEL_DELETE_SUCCESS,
  payload:data

})

export const hotelRemoveFailure=(data) =>({
  type:hotelConstant.HOTEL_DELETE_FAILURE,
  payload : data

})


export const  RemoveHotelAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(hotelRemoveInitiate());
      const response = await axios.delete(`${baseUrl}/admin/hotel/delete`, {
        headers: {Authorization:`${token}`},params: payload})

      if (response.data.sucess === true) {
        dispatch(hotelRemoveSuccess(response.data));
      } else {
        dispatch(hotelRemoveFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(hotelRemoveFailure(err));
      return err;
    }


}

// EDIT HOTEL SPLITPERCENTAGE
export const editSplitpercentageInitiate=() => ({
  type:hotelConstant.EDIT_SPLITPERCENTAGE_INITIATE
})
export const editSplitpercentageSuccess=(data) =>({
  type:hotelConstant.EDIT_SPLITPERCENTAGE_SUCCESS,
  payload:data
})
export const editSplitpercentageFailure=(data) =>({
  type:hotelConstant.EDIT_SPLITPERCENTAGE_FAILURE,
  payload : data
})
export const  EditSplitpercentageAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(editSplitpercentageInitiate());
      const response = await axios.put(`${baseUrl}/admin/setsplitpercentageforspecifichotel`, payload,{
          headers: {authorization:`${token}`}})
      if (response.status == 200) {
        dispatch(editSplitpercentageSuccess(response.data));
      } else {
        dispatch(editSplitpercentageFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(editSplitpercentageFailure(err));
      return err;
    }
}
