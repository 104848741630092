import React, { useEffect } from "react";
import Header from "./header";
import Sidenav from "./sidenav";
import { useState } from "react";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axiosInstance from '../axiosInstance';
import { useDispatch, useSelector } from "react-redux";
import ImageFetcher from './ImageFetcher'
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Check from '../assets/image/check.png'
import Modal from "react-bootstrap/Modal";

import AutoPlace from "./AutoPlace";
import Map from "./map";

import {

    PhoneOtpAction,
  } from "../redux/actions/authAction";
  




import { AdminUpdateDetailsAction } from "../redux/actions/authAction";


const initialState = {
    name: "",
    email: "",
    phone: "",
    image: "",
    address: "",
    lat: "",
    long: "",
    pinCode:"",
  address:"",
  tempPhone:"",
  spa: false,

  errorMsg: {},
};

const HotelUpdate = () => {
  
  const [iState, updateState] = useState(initialState);
  const [otpExpirePhone, setOtpExpirePhone] = useState(true);
  const { roleList } = useSelector((state) => state.roleReducer);
  const{state} = useLocation()
  const [amenities, setAminities] = useState();
  const [sendOtp, setSendOtp] = useState("");

  let timer


  const staticAmenities = [
    "Swimming Pool",
    "Laundry",
    "Wifi",
    "Restaurant",
    "Housekeeping",
    "Parking",
    "Power Backup",
    "AC",
  ];

  const [otpState, setOtpState] = useState({
    dig1: "",
    dig2: "",
    dig3: "",
    dig4: "",
    dig5: "",
    dig6: "",
  });

  const [DeleteModal, setDelete] = useState(false);
  const handleDeleteModalClose = () => {
    setOtpState({
      dig1: "",
      dig2: "",
      dig3: "",
      dig4: "",
      dig5: "",
      dig6: "",
  
    })
    setOtpError("")

    setDelete(false)};
  const handleDeleteModalShow = (id) => {
    setDelete(true);
  };


  const [otpError, setOtpError] =useState("")


  const [verified, setVerified] = useState(false);


  const [password1Shown, setPassword1Shown] = useState(false);
  const toggle1PasswordVisiblity = () => {
    setPassword1Shown(password1Shown ? false : true);
  };

  const {
    name,
    email,
    phone,
    address,
    lat,
    long,
    image,
    tempPhone,
    pinCode,
    spa,
    errorMsg,
  } = iState;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let handleValidation = () => {
    let errorMsg = {};
    let formIsValid = true;
    if (!email) {
      errorMsg.email = "* Please enter email.";
      formIsValid = false;
    } else if (!/^.+?@.+?\..+$/.test(email)) {
      errorMsg.emailValid = "* Email format is not valid";
      formIsValid = false;
    }

    if (!name) {
      errorMsg.name = "* Please enter name";
      formIsValid = false;
    }

    if (!image) {
      errorMsg.image = "* Please upload profile image";
      formIsValid = false;
    }
    if (amenities.length < 1) {
        errorMsg.amenities = "* Please select amenities.";
        formIsValid = false;
      }
  


    if (!phone) {
      errorMsg.phone = "* Please enter phone number.";
      formIsValid = false;
    } else if (phone.length < 12) {
      errorMsg.phoneValid = "* Please enter valid phone number.";
      formIsValid = false;
    }
    else if(phone!== tempPhone && !verified ){
        errorMsg.phoneVerify = "* Please verify phone number.";
        formIsValid = false;
  
    }

    updateState({
      ...iState,
      errorMsg: errorMsg,
    });
    return formIsValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateState({
      ...iState,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formIsValid = handleValidation();

    if (formIsValid) {
      let data = {
        name,
        phone: `${phone}`,
        image,
        address,
        location: {
          type: "Point",
          coordinates: [
              lat,
              long
          ]
      },
        amenities,
        pinCode,
        hotelId:state?._id
      };
      dispatch(AdminUpdateDetailsAction(data)).then(
        (res) => {
          if (res.status == "202") {
            navigate('/hotel-management')
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
        }}
      );
    }
  };


  window.Buffer = window.Buffer || require("buffer").Buffer;

  const handleUpload = (e) => {
    const file = e.target.files[0];

    if (file.type == "application/pdf") {
      toast.error("Only jpeg, jpg and png are allowed ", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    if (file) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        //Initiate the JavaScript Image object.
        var image = new Image();

        //Set the Base64 string return from FileReader as source.
        image.src = e.target.result;

        //Validate the File Height and Width.
        image.onload = function () {
          var height = this.height;
          var width = this.width;
          if (
            file.type === "image/jpeg" ||
            file.type === "image/jpg" ||
            file.type === "image/png"
          ) {
            const formData = new FormData();
            formData.append('file', file);
  
            let accessToken = JSON.parse(localStorage.getItem("hotelDetails")).jwtToken;
              axiosInstance.post('/upload?type=hotel', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  'Authorization': 'Authorization'.concat(" ",accessToken),
                }
              })
            .then(response => {
              console.log(response);
            
              if (response.status !== 200) {
                throw new Error(`Server error: ${response.statusText}`);
              }
            
              return response;
            })
            .then(data => {
              if (data.error) {
                toast.error(data.error, { position: toast.POSITION.TOP_RIGHT });
              } else {
                const tempData = data.data.url;
                updateState({ ...iState, image: tempData });
              toast.success("Upload successfully", { position: toast.POSITION.TOP_RIGHT });
              }
            })
            .catch(error => {
              console.error(error);
              toast.error("Upload failed: " + error.message, { position: toast.POSITION.TOP_RIGHT });
            });
            } else {
            toast.error("Only jpeg, jpg and png are allowed ", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        };
      };
    }
  };


  const updateHandler=async()=>{
    try {
        updateState({...iState,
            name:state?.name,
             lat:state?.location?.coordinates?.[0],
             long:state?.location?.coordinates?.[1],
             address:state?.address,
             image :state?.image,
             phone:state?.phone,
             email: state?.email,
             tempPhone:state?.phone,
             spa:state?.spa

    
        })
        setAminities(state?.amenities)

    } catch (error) {
        
    }
  }

  useEffect(()=>{
    updateHandler()
  },[state])




  const handleCheck = (e) => {
    const { name, value } = e.target;

      e.target.checked
        ? setAminities((prev) => [...prev, value])
        : setAminities((prev) => prev.filter((item) => item !== value));
    
  };

  const inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;

      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      // // console.log("next");

      const next = elmnt.target.tabIndex;
      if (next < 6) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };

  const otpHandleChange = (e) => {
    const { name, value } = e.target;
    setOtpState({
      ...otpState,
      [name]: value,
    });
  };
  const phoneValidate = () => {
    let formIsValid = true;

    if (!phone) {
      errorMsg.phone = "* Please enter phone number.";
      formIsValid = false;
    } else if (phone.length < 12) {
      errorMsg.phoneValid = "* Please enter valid phone number.";
      formIsValid = false;
    }
    updateState({ ...iState, errorMsg: errorMsg });

    return formIsValid;
  };



  const handlePhoneVerify = () => {
    let formIsValid = phoneValidate();
    if (formIsValid) {
      dispatch(PhoneOtpAction({ phone: phone }))
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            setOtpState({
              dig1: "",
              dig2: "",
              dig3: "",
              dig4: "",
              dig5: "",
              dig6: "",
          
            })
            setOtpError("")
            setOtpExpirePhone(false);
            setSendOtp(res.data.data.otp);
            runTimerPhone();
        
            

            toast.success("Otp send successfully", {
              position: toast.POSITION.TOP_RIGHT,
            });
            // document.getElementById("defaultFocus").focus()

            setDelete(true);
          } else {
            toast.error(res.response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
  
    }
  };

  const handleOtpValidation=()=>{
    let otpError=""
    let formIsValid =true

   if(Object.values(otpState).some(x=>x === '')){
    otpError="* Please enter otp"
    formIsValid= false
   }



   setOtpError(otpError)
return formIsValid



  }



  const handleOtpSubmit = () => {
    let formIsValid = handleOtpValidation()
    console.log(formIsValid)

    var data = {
      otp:
        otpState.dig1 +
        otpState.dig2 +
        otpState.dig3 +
        otpState.dig4 +
        otpState.dig5 +
        otpState.dig6,
    };

    if (formIsValid) {
      if(sendOtp !== data.otp){
        toast.error("Otp not match!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        


      }
      else{

      if (!otpExpirePhone) {
        setDelete(false);
          setVerified(true);
        

      } else {
        toast.error("Otp Expired", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }}
      setOtpState({
        dig1: "",
        dig2: "",
        dig3: "",
        dig4: "",
        dig5: "",
        dig6: "",
      });
      setOtpError("")


    }
  };



  const runTimerPhone = () => {
    timer = window.setTimeout(() => {
      setOtpExpirePhone(true);
    }, 1000 * 5*60);
  };



  
  
  return (
    <>
      <Header />
      <Sidenav />
      <div class="WrapperArea">
        <div class="WrapperBox">

          <div class="Small-Wrapper">
          <div class="NewTitleBox">
            <h4>Hotel Update</h4>

          </div>


            <div class="CommonForm">
              <form>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Hotel Name</label>
                      <input
                        value={name}
                        name="name"
                        onChange={handleChange}
                        type="text"
                        class="form-control"
                      />
                      {errorMsg.name && !name && (
                        <p style={{ color: "red" }}>{errorMsg.name}</p>
                      )}
                    </div>
                  </div>
                  <div class={image ? "col-sm-4" : "col-sm-6"}>
                    <div class="form-group upload-file">
                      <label>Hotel Image</label>
                      <input
                        type="file"
                        onClick={(e) => (e.target.value = null)}
                        onChange={(e) => handleUpload(e)}
                        class="form-control"
                      />
                      {errorMsg.image && !image && (
                        <p style={{ color: "red" }}>{errorMsg.image}</p>
                      )}
                    </div>
                  </div>
                  {image && (
                    <div className="col-sm-2">
                      <div className="avtar-img">
                      <ImageFetcher imageUrl={image}/>
                      </div>
                    </div>
                  )}
                  
                  <div class="col-sm-6">
                    <div class="form-group HotelNumber">
                      <label>Phone Number</label>
                      <PhoneInput
                        country={'in'}
                        value={phone}
                        enableSearch={true}
                        disabled={verified}
                        onChange={(phone) => updateState({ ...iState, phone:`+${phone}` })}
                      />
                                          {tempPhone!= phone &&(!verified ? 
                    <span className="Verify" onClick={handlePhoneVerify}>
                      Verify
                    </span>:                     <span className="Verify"><img src={Check} /></span>)}


                      {errorMsg.phone && !phone && (
                        <p style={{ color: "red" }}>{errorMsg.phone}</p>
                      )}
                                            {errorMsg.phoneValid && phone?.length <12 && (
                        <p style={{ color: "red" }}>{errorMsg.phoneValid}</p>
                      )}

{errorMsg.phoneVerify && phone!== tempPhone && !verified && (
                        <p style={{ color: "red" }}>{errorMsg.phoneVerify}</p>
                      )}




                    </div>
                  </div>
                  
                <div class="col-sm-6">
                  <div class="form-group mb-0">
                    <label>Do you have spa</label>
                    <select
                      class="form-control"
                      value={spa}
                      name="spa"
                      onChange={handleChange}
                    >
                      <option>--select--</option>
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                  </div>
                </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Email Address</label>
                      <input
                        value={email}
                        name="email"
                        onChange={handleChange}
                        type="email"
                        disabled={true}
                        class="form-control"
                      />
                      {errorMsg.email && !email && (
                        <p style={{ color: "red" }}>{errorMsg.email}</p>
                      )}

{errorMsg.emailValid&& (!/^.+?@.+?\..+$/.test(email)) && (
                        <p style={{ color: "red" }}>{errorMsg.emailValid}</p>
                      )}


                      
                    </div>
                  </div>
                  
                  
                
                  
                <div class="col-sm-12">
                  <div class="form-group">
                    <div className="AddressBox">
                      <article>
                        <AutoPlace
                          updateState={updateState}
                          iState={iState}
                          address={address}
                          verified={true}
                        />
                      </article>
                    <Map  iState={iState} updateState={updateState} />
                    </div>

                    {errorMsg.address && !address && (
                      <p style={{ color: "red" }}>{errorMsg.address}</p>
                    )}

                    <span class="Verify">
                      <img src="images/location.svg" alt="" />
                    </span>
                  </div>
                </div> 
                <div class="col-sm-12">
                  <div class="form-group">
                    <label>Amenities</label>
                    <div className="amenities">
                      <ul>
                        {staticAmenities?.map((item) => (
                          <li>
                            <label class="CheckBox">
                              {item}
                              <input
                                type="checkbox"
                                value={item}
                                name={item}
                                checked={amenities?.includes(item)}
                                onChange={handleCheck}
                              />
                              <span class="checkmark"></span>
                            </label>
                          </li>
                        ))}

                        {errorMsg.amenities && amenities.length < 1 && (
                          <p style={{ color: "red" }}>{errorMsg.amenities}</p>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
                </div>

                <a onClick={handleSubmit} class="Button">
                  Update
                </a>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={DeleteModal}
        onHide={handleDeleteModalClose}
        className="ModalBox "
      >
        <Modal.Body>
          <div class="Category">
            <a onClick={handleDeleteModalClose} class="CloseModal">
              ×
            </a>
            <h3>OTP Verification </h3>
            <form>
              <div class="form-group">
                <div class="OTPBox">
                  <input
                    value={otpState.dig1}
                    name="dig1"
                    onChange={otpHandleChange}
                    type="text"
                    class="form-control"
                    tabIndex="1"
                    maxLength="1"
                    id='defaultFocus'
                    autoFocus
                    onKeyUp={(e) => inputfocus(e)}
                  />
                  <input
                    value={otpState.dig2}
                    name="dig2"
                    onChange={otpHandleChange}
                    type="text"
                    tabIndex="2"
                    maxLength="1"
                    onKeyUp={(e) => inputfocus(e)}
                    class="form-control"
                  />
                  <input
                    value={otpState.dig3}
                    name="dig3"
                    onChange={otpHandleChange}
                    type="text"
                    class="form-control"
                    tabIndex="3"
                    maxLength="1"
                    onKeyUp={(e) => inputfocus(e)}
                  />
                  <input
                    value={otpState.dig4}
                    name="dig4"
                    tabIndex="4"
                    maxLength="1"
                    onKeyUp={(e) => inputfocus(e)}
                    onChange={otpHandleChange}
                    type="text"
                    class="form-control"
                  />
                  <input
                    value={otpState.dig5}
                    name="dig5"
                    onChange={otpHandleChange}
                    type="text"
                    tabIndex="5"
                    maxLength="1"
                    onKeyUp={(e) => inputfocus(e)}
                    class="form-control"
                  />
                  <input
                    value={otpState.dig6}
                    name="dig6"
                    onChange={otpHandleChange}
                    type="text"
                    class="form-control"
                    tabIndex="6"
                    maxLength="1"
                    onKeyUp={(e) => inputfocus(e)}
                  />
            
                </div>
                {(otpState.dig1==""|| otpState.dig2==""||otpState.dig3==""||otpState.dig4==""||otpState.dig5==""||otpState.dig6=="")&&otpError && (
                    <p className="emptyField" style={{ color: "red" }}>
                      {otpError}
                    </p>
                  )}
                <p class="text-right">
                  <a onClick={handlePhoneVerify}>Resend OTP</a>
                </p>
              </div>
              <a onClick={handleOtpSubmit} class="Button">
                {" "}
                VERIFY OTP
              </a>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default HotelUpdate;
