import { foodBeverageConstant } from "../actionTypes";
const initialState = {
    foodBeverageList: [],
    foodBeverageCategoryList: [],

    loader: false,
    error: {},
  };
  
  export default function foodBeverageReducer(
    state = initialState,
    { type, payload }
    
  ) {
    switch (type) {

      case foodBeverageConstant.FOOD_BEVERAGE_LIST_INITIATE:
        return { ...state, loader: true, foodBeverageList: [] };
  
      case foodBeverageConstant.FOOD_BEVERAGE_LIST_SUCCESS:
        return { ...state, loader: false, foodBeverageList: payload.data };
  
      case foodBeverageConstant.FOOD_BEVERAGE_LIST_FAILURE:
        return { ...state, loader: false, error: payload };



        case foodBeverageConstant.FOOD_BEVERAGE_CATEGORY_LIST_INITIATE:
          return { ...state, loader: true, foodBeverageCategoryList: [] };
    
        case foodBeverageConstant.FOOD_BEVERAGE_CATEGORY_LIST_SUCCESS:
          return { ...state, loader: false, foodBeverageCategoryList: payload.data };
    
        case foodBeverageConstant.FOOD_BEVERAGE_CATEGORY_LIST_FAILURE:
          return { ...state, loader: false, error: payload };
  
  



  
  
      default:
        return state;
    }
  }
  