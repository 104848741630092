import { dropdownConstant } from "../actionTypes";

import axios from "../../axiosInstance"
import { baseUrl } from "../../config/config";
import { isLoggedIn } from "../../utils";



//  ROOM LIST

export const roomListInitiate=() =>({
    type:dropdownConstant.OCCUPIED_ROOM_INITIATE

})

export const roomListSuccess=(data) =>({
    type:dropdownConstant.OCCUPIED_ROOM_SUCCESS,
    payload:data

})

export const roomListFailure=(data) =>({
    type:dropdownConstant.OCCUPIED_ROOM_FAILURE,
    payload : data

})


export const  OccupiedRoomAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(roomListInitiate());
        const response = await axios.get(`${baseUrl}/admin/room/checkIn/occupiedroom`, {
          headers: {Authorization:`${token}`},params: payload})

        if (response.status == 200) {
          dispatch(roomListSuccess(response.data));
        } else {
          dispatch(roomListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(roomListFailure(err));
        return err;
      }


}



//  GUEST LIST

export const guestListInitiate=() =>({
  type:dropdownConstant.GUEST_LIST_INITIATE

})

export const guestListSuccess=(data) =>({
  type:dropdownConstant.GUEST_LIST_SUCCESS,
  payload:data

})

export const guestListFailure=(data) =>({
  type:dropdownConstant.GUEST_LIST_FAILURE,
  payload : data

})


export const  GuestListAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(guestListInitiate());
      const response = await axios.get(`${baseUrl}/admin/user/list`, {
        headers: {Authorization:`${token}`},params: payload})

      if (response.status == 200) {
        dispatch(guestListSuccess(response.data));
      } else {
        dispatch(guestListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(guestListFailure(err));
      return err;
    }


}



