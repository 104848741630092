import React, { useEffect ,useState} from 'react'
import Header from './header'
import Sidenav from './sidenav'
import { Link,  useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { BillingListAction } from '../redux/actions/guestAction'
import moment from 'moment';
import NoDataFound from "./noDataFound";
import Pagination from "react-js-pagination";


const GuestsBillingPayment = () => {
    const dispatch = useDispatch()


    const hotelDetails =  JSON.parse(window.localStorage.getItem("hotelDetails"))


    
    const {billingList,loader} = useSelector((state)=>state.guestReducer)
    const {id} = useParams()


    const [activePage, setActivePage] = useState(1);

    const [filterData, setFilterData] = useState({
      timeFrame: "",
      startDate: "",
      endDate: "",
      search: "",
    });
  
  
    useEffect(()=>{

        dispatch(BillingListAction({hotelId:id}))

    },[])


    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        filterData.page = pageNumber;
        dispatch(BillingListAction({hotelId:id, page: filterData?.page }));
      };
      return (
    <div>
        <Header />
        <Sidenav />

        <div class="WrapperArea">
        <div class="WrapperBox">

        <div class="HotelLocation HotelLocation2">
            <div class="back-btn">
            <Link to={`/guests-management/${id}`}>
                <i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back{" "}
              </Link>
            </div>
            
            <aside>
              <h3>{hotelDetails?.name}</h3>
              <p>
                <span>
                  <i class="fa fa-map-marker"></i>
                </span>{" "}
                {hotelDetails?.address}
              </p>
            </aside>
</div>

            <div class="NewTitleBox">
            <div className="arrow-left">
            {/* <Link to={`/guests-management/${id}`}>
                  <i class="fa fa-arrow-left arrow-back" aria-hidden="true"></i>
                </Link> */}

                <h4>Billing &amp; Payments</h4> 
            </div>

            <div>
            </div>
          </div>

            <div class="Small-Wrapper">

                <div class="Filter">  
                    <div class="form-group">
                        <label>Search</label>
                        <input type="text" class="form-control" placeholder="Search.." />
                    </div>
                    <div class="form-group">
                        <label>Room Number</label>
                        <select class="form-control">
                            <option>Select Room Number</option>
                            <option>RM103</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label>Payment Status</label>
                        <select class="form-control">
                            <option>Select Payment Status</option>
                            <option>Paid</option>
                        </select>
                    </div>   
                </div>  

                {billingList?.[0]?.data?.map((item)=>
                
                <div class="GuestUserBox">
                    
                    <figcaption>
                        <div class="GuestUserHead"> 
                            <aside>
                                <p>
                                    <label>Room Number </label>
                                    <span class="Green">{item?.rooms?.concat(item?.pastRooms)?.map((ele)=>
                                    `${ele?.fullNumber}   ` 
                                    )}</span>
                                </p>
                                {/* <p>
                                    <label>Total Spend on stay bar</label>
                                    <span>3000 INR</span>
                                </p> */}
                                <p>
                                    <label>Total Paid</label>
                                    <span>{item?.paidAmount?.toFixed(2)} INR</span>
                                </p>
                                <p>
                                    <label>Billing Info</label>
                                    <span><Link class="Green" state={'guests-billing-payment'}  to={`/guests-billing-payment-view/${item?._id}/${id}`}>View</Link></span>
                                </p>
                            </aside>
                            <aside> 
                                <p>
                                    <label>Pending Amount</label>
                                    <span class="Red"><strong>{item?.pendingAmount?.toFixed(2)} INR</strong></span>
                                </p>
                                <p>
                                    <label>Check In</label>
                                    <span>{moment(item?.checkInDate).format('ll')}</span>
                                </p>
                                <p>
                                    <label>Checkout</label>
                                    <span>{(item?.extendCheckOut?.rooms?.length > 0)? moment(item?.checkOutDate).format('ll'):moment(item?.extendCheckOut?.newCheckOutDate).format('ll') }</span>
                                </p>
                            </aside> 
                        </div> 
                    </figcaption>
                </div>
                )}

{loader ? (
                ""
              ) : billingList?.[0]?.metadata?.[0]?.total > 0 ? (
                ""
              ) : (
                <NoDataFound />
              )}  


                

            </div>
           
                      {billingList?.[0]?.metadata?.[0]?.total > 0 ? (
              <div className="Pagination">
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={billingList?.[0]?.metadata?.[0]?.total}
                  pageRangeDisplayed={5}
                  onChange={(e) => handlePageChange(e)}
                />
              </div>
            ) : (
              ""
            )}

        </div> 
    </div>
    </div>
  )
}

export default GuestsBillingPayment