import React, { useEffect, useState } from "react";
import Header from "./header";
import Sidenav from "./sidenav";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { useDispatch, useSelector } from "react-redux";
import { InviteGuestListAction } from "../redux/actions/guestAction";
import moment from "moment/moment";
import { Link, useParams } from "react-router-dom";

const GuestsInvited = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { invitedGuestList } = useSelector((state) => state.guestReducer);

  useEffect(() => {
    dispatch(InviteGuestListAction({ hotelId: id }));
  }, []);




  const [filterData, setFilterData] = useState({
    search: "",
    startDate: "",
    endDate: "",
  });


  const handleFilter = (e) => {
    e.preventDefault();
    dispatch(
      InviteGuestListAction({
        startDate: filterData.startDate,
        endDate: filterData.endDate,
        search: filterData.search,
        hotelId:id,
      })
    );
  };

  const handleRefresh = () => {
    dispatch(InviteGuestListAction({hotelId:id}));
    setFilterData({
      timeFrame: "",
      startDate: "",
      endDate: "",
    });
  };

  return (
    <div>
      <Header />
      <Sidenav />

      <div class="WrapperArea">
        <div class="WrapperBox">
          <div className="CommonLinks">
            <Link to={`/guests-management/${id}`}>
              <i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back
            </Link>
          </div>

          <div class="Small-Wrapper">
          <div class="NewTitleBox">
              <div className="arrow-left">
                <Link to="/guests-management">
                  <i class="fa fa-arrow-left arrow-back" aria-hidden="true"></i>
                </Link>

                <h4>Invited Users</h4>
              </div>
            </div>
         
            <div class="Filter">
              <div class="form-group">
                <label>Search</label>
                <input
                  type="text"
                  value={filterData.search}
                  class="form-control"
                  onChange={(e) =>
                    setFilterData({ ...filterData, search: e.target.value })
                  }
                  placeholder="Search.."
                />
              </div>
              <div class="form-group">
                <label>From Date</label>
                <DatePicker
                  className="form-control"
                  selected={filterData.startDate}
                  placeholderText="DD-MM-YYYY"
                  maxDate={new Date()}
                  dateFormat="dd-MM-yyyy"
                  onChange={(date) =>
                    setFilterData({ ...filterData, startDate: date })
                  }
                  // minDate={moment().toDate()}
                />
              </div>
              <div class="form-group">
                <label>To Date</label>
                <DatePicker
                  className="form-control"
                  selected={filterData.endDate}
                  dateFormat="dd-MM-yyyy"
                  placeholderText="DD-MM-YYYY"
                  disabled={filterData.startDate ? false : true}
                  onChange={(date) =>
                    setFilterData({ ...filterData, endDate: date })
                  }
                  minDate={filterData.startDate}
                  maxDate={new Date()}
                />
              </div>
              <div class="form-group">
                <label>&nbsp;</label>
                <button class="Button" onClick={handleFilter}>
                  Apply
                </button>
                <button class="Button Cancel" onClick={handleRefresh}>
                  <i class="fa fa-refresh"></i>
                </button>
              </div>{" "}
            </div>
            <div class="WifiArea">
              {invitedGuestList?.map((item) => (
                <div class="WifiBox">
                  <article>
                    <p>
                      <label>Name</label>
                      <span>
                        {item?.name} <b>(Deluxe)</b>
                      </span>
                    </p>
                    <p>
                      <label>Gender</label>
                      <span>{item?.gender}</span>
                    </p>
                    <p>
                      <label>Email</label>
                      <span>{item?.email}</span>
                    </p>
                    <p>
                      <label>Phone</label>
                      <span>{item?.phone}</span>
                    </p>
                    <p>
                      <label>Date</label>
                      <span>{moment(item?.createdAt).format("ll")}</span>
                    </p>
                    <p>
                      <label>Booking ID</label>
                      <span>{item?.bookingId}</span>
                    </p>
                    <p>
                      <label> Stay Durations</label>
                      <span>{item?.stay_duration}</span>
                    </p>
                    <p>
                      <label>Meal</label>
                      <span>{item?.meal}</span>
                    </p>
                    <p>
                      <label>Check-In on</label>
                      <span>{moment(item?.checkInDate).format("ll")}</span>
                    </p>
                    <p class="Green">
                      <strong>Number of Rooms: {item?.numberOfRoom}</strong>
                    </p>
                    <p class={item?.isAppInstalled? "Green":"Red"}>
                      <strong>{item?.isAppInstalled? "App Installed":"App not Installed"}</strong>
                    </p>
                  </article>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuestsInvited;
