import React, { useEffect, useState } from "react";
import Header from "./header";
import Sidenav from "./sidenav";
import Modal from "react-bootstrap/Modal";
import { Link, useParams } from "react-router-dom";
import {
  AddRoomAction,
  EditRoomAction,
  RemoveRoomAction,
  RoomCategoryListAction,
  AddRoomCategoryAction,
  RoomListAction,
} from "../redux/actions/roomAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import NoDataFound from "./noDataFound";
import Pagination from "react-js-pagination";
import Loader from "./loader";



const initialState = {
  roomNumber: "",
  category: "",
  errorMsg: {},
};

const HotelRoom = () => {
  const hotelDetails =  JSON.parse(window.localStorage.getItem("hotelDetails"))
  const [stopper, setStopper] = useState(false)

  const {id} = useParams()
  const [roomId, setroomId] = useState("");
  const [activePage, setActivePage] = useState(1);

  const [Type, setType] = useState("add");
  const [iState, updateState] = useState(initialState);
  const [Assigned, setAssigned] = useState("");
  const { category, roomNumber, start, end, title, fullNumber, errorMsg } =
    iState;
  const { roomList, categoryList, loader } = useSelector((state) => state.roomReducer);
  const [search, setSearch] = useState("");
  const [roomType, setRoomType] = useState("single");
  const [roomCategory, setRoomCategory] = useState("");
  const [tempCategory, setTempCategory] = useState("");


  const dispatch = useDispatch();

  const [DeleteModal, setDelete] = useState(false);
  const handleDeleteModalClose = () => setDelete(false);
  const handleDeleteModalShow = (id, status,type) => {
    setAssigned(status);
    setroomId(id);
    setDelete(true);
    setType(type)
  };

  const [AddModal, setAddModel] = useState(false);
  const handleAddModalClose = () => {
    updateState({
      roomNumber: "",
      category: "",
      title: "",
      errorMsg: {},
    });
    setAddModel(false);
  };
  const handleAddModalShow = (type, ele) => {
    console.log(type,'TTTTTTT')
    if (type == "edit") {
      setType(type);

      setroomId(ele?._id);

      updateState({
        ...iState,
        roomNumber: ele?.roomNumber,
        category: ele?.category,
        title: ele?.title,
        fullNumber: ele?.fullNumber,
      });
      setAddModel(true);
    } else {
      setAddModel(true);
      setType(type);
    }
  };

  const [EditModal, setEditModel] = useState(false);
  const handleEditModalClose = () => setEditModel(false);
  const handleEditModalShow = () => {
    setEditModel(true);
  };

  let handleValidation = () => {
    let errorMsg = {};
    let formIsValid = true;

    if (end < start) {
      errorMsg.roomValid = "* Please enter valid number";
      formIsValid = false;
    }

    if (Type !== "edit") {
      if (roomType == "single") {
        if (!roomNumber) {
          errorMsg.roomNumber = "* Please enter room number";
          formIsValid = false;
        }
      } else {
        if (!start) {
          errorMsg.start = "* Please enter start range";
          formIsValid = false;
        }
        if (!end) {
          errorMsg.end = "* Please enter end range";
          formIsValid = false;
        }
      }
    } else {
      if (!roomNumber) {
        errorMsg.roomNumber = "* Please enter room number";
        formIsValid = false;
      }
    }

    if (category=="Add Category") {

      if (!roomCategory) {
        errorMsg.category = "* Please select category";
        formIsValid = false;
      }
   
    }

    updateState({ ...iState, errorMsg: errorMsg });
    return formIsValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name)
    if(name=="roomNumber" ||name=="start" ||name=="end"){
      if (!/^[0-9]{0,10}$/.test(value) || value<0) return;
      {
  
        updateState((prev) => ({ ...prev, [name]: value }));
      }
    
    }else{
      updateState({ ...iState, [name]: value });
  
  
    }
    };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formIsValid = handleValidation();
    setStopper(formIsValid)

    if (formIsValid) {
      let data = {};
      if (roomType == "single")
        data = {
          roomNumber ,
          category :roomCategory?roomCategory:category,
          title: title.toUpperCase(),
          type: "single",
          hotelId:id
        };
      else {
        data = {
          start: parseInt(start),
          end: parseInt(end),
          roomNumber ,
          title: title.toUpperCase(),
          type: "multiple",
          category:roomCategory?roomCategory:category,
          hotelId:id
        };
      }
      
      if(roomCategory){

        dispatch(AddRoomCategoryAction({ category: roomCategory,hotelId:id })).then((res) => {
          if (res.status === 200) {
            dispatch(AddRoomAction(data)).then((res) => {
              if (res.status === 201) {
                setStopper(false)
                setAddModel(false)
                dispatch(RoomListAction({ hotelId: id, limit:"12" }));
                setRoomType("single");
                setRoomCategory("")
      
                updateState({
                  roomNumber: "",
                  category: "",
                  title: "",
                  errorMsg: {},
                });
                toast.success(res.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              } else {
                setStopper(false)
                toast.error(res.response.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            });
            
            dispatch(RoomCategoryListAction({hotelId:id}));
            updateState({ ...iState, category: roomCategory });
    
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            setStopper(false)
            toast.error(res.response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });
      }
      else{
        dispatch(AddRoomAction(data)).then((res) => {
          if (res.status === 201) {
            setStopper(false)
            setAddModel(false)

            dispatch(RoomListAction({ hotelId: id ,limit:"12"}));
            setRoomType("single");
  
            setAddModel(false);
            updateState({
              roomNumber: "",
              category: "",
              title: "",
              errorMsg: {},
            });
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            setStopper(false)
            toast.error(res.response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });
      }
    }
  };



  const handleEdit = (e) => { 
    e.preventDefault();

    let formIsValid = handleValidation();
    setStopper(formIsValid)

    if (formIsValid) {
      var data = {
        roomNumber,
        _id: roomId,
        title,
        fullNumber,
        hotelId:id,
        category,
      };
      dispatch(EditRoomAction(data)).then((res) => {
        if (res.status === 202) {
          setStopper(false)
          setAddModel(false);
          updateState({
            roomNumber: "",
            category: "",
            title: "",
            errorMsg: {},
          });
          setRoomCategory("single");
          dispatch(RoomListAction({ hotelId: id,limit:"12"}));

          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(res.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };

  useEffect(() => {
    dispatch(RoomListAction({ hotelId: id}));
    dispatch(RoomCategoryListAction({hotelId :id}));
  }, []);

  const handleDelete = () => {
    dispatch(RemoveRoomAction({ _id: roomId, hotelId:id })).then((res) => {
      if (res.status >= 200 && res.status <= 300) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });

        updateState({ ...iState, roomType: "single" });

        setDelete(false);
        dispatch(RoomCategoryListAction({ hotelId: id }));

        dispatch(RoomListAction({ hotelId: id ,limit:"12"}));
      } else {
        toast.error(res.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleSearch = (e) => {
    dispatch(
      RoomListAction({
        hotelId: id,
        search: e.target.value,
        limit:"12"
      })
    );
    setSearch(e.target.value);
  };

  const handleCheck = (e, type) => {

    if (e.target.checked) {
      setRoomType(type);
    }
  };

  const handleFilter = (e) => {
    setTempCategory(e.target.value);
    dispatch(
      RoomListAction({
        hotelId: id,
        category: e.target.value,
        limit:"12"
      })
    );
  };

  const handleRefresh = () => {
    setSearch("");
    setTempCategory("");

    dispatch(RoomListAction({ hotelId: id,limit:"12"}));
  };


  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    dispatch(
      RoomListAction({
        hotelId: id,
        page: pageNumber,
        limit:"12"
      })
    );



  };

  
  return (
    <div>
      <Header />
      <Sidenav />

      <div class="WrapperArea">
        <div class="WrapperBox">
          <div class="HotelLocation HotelLocation2">
            <div class="back-btn">
              <Link to={`/hotel-details/${id}`}>
                <i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back{" "}
              </Link>
            </div>

            <aside>
              <h3>{hotelDetails?.name}</h3>
              <p>
                <span>
                  <i class="fa fa-map-marker"></i>
                </span>{" "}
                {hotelDetails?.address}
              </p>
            </aside>
          </div>

          <div class="Small-Wrapper">
            <div class="NewTitleBox">
              <h4>Rooms Management</h4>
              <div>
                <Link to={`/room-min-bar/${id}`}>Mini Bar Management</Link>
                <a onClick={() => handleAddModalShow("add")}>Add Room</a>
              </div>
            </div>

            <div class="Filter">
              <div class="form-group">
                <label>Search</label>
                <input
                  type="text"
                  value={search}
                  onChange={handleSearch}
                  class="form-control"
                  placeholder="Search by room no."
                />
              </div>
              <div class="form-group">
                <label> Room Category</label>
                <select
                  name="category"
                  onChange={handleFilter}
                  value={tempCategory}
                  class="form-control"
                >
                  <option>Select </option>
                  {categoryList?.roomCategory?.map((item) => (
                    <option value={item}>{item}</option>
                  ))}
                </select>
              </div>
              <div class="form-group">
                <label>&nbsp;</label>

                <button onClick={handleRefresh} class="Button Cancel">
                  <i class="fa fa-refresh"></i>
                </button>
              </div>
            </div>
            {loader? <Loader />:

            <div class="WifiArea">
              {roomList?.[0]?.data?.map((item, i) => (
                <div class="WifiBox">
                  <aside>
                    <h4>S.No :{((activePage-1)*12)+(i + 1)}</h4>
                    <div>
                      <a
                        class="Green"
                        onClick={() => {(item?.occupied)?handleDeleteModalShow(item?._id, item?.occupied,"edit"):handleAddModalShow("edit", item)}}
                      >
                        <i class="fa fa-pencil"></i>
                      </a>
                      <a
                        class="Red"
                        onClick={() =>
                          handleDeleteModalShow(item?._id, item?.occupied,'delete')
                        }
                      >
                        <i class="fa fa-trash"></i>
                      </a>
                    </div>
                  </aside>
                  <article>
                    <p>
                      <label>Room Number</label>
                      <span>
                        {item?.title}
                        {item?.roomNumber}
                      </span>
                    </p>
                    {/* <p>
                      <label>Status</label>
                      <span>Assigned to Brijesh Kumar</span>
                    </p> */}
                                          <p>
                        <label>Category</label>
                        <strong >{item?.category}</strong>
                      </p>


                    {item?.occupied ? (
                      <p>
                        <label>Status</label>
                        <strong className="text-danger">Assigned</strong>
                      </p>
                    ) : (
                      <>
                        <p>
                          <label>Status</label>
                          <strong className="text-danger">Unassigned</strong>
                        </p>
                        <p class="text-guest-unassigned">
                          <strong>View Guest Details</strong>
                        </p>

                        <button class="ButtonFade ButtonOtp">Send OTP</button>
                      </>
                    )}
                    {item?.occupied && (
                      <>
                      <Link to={`/guests-details/${id}`}state={item?.guestId?.[0]}>
                        <p class="text-guest">
                          <strong>View Guest Details</strong>
                        </p>
                        </Link>
                        <button class="Button ButtonOtp">Send OTP</button>
                      </>
                    )}
                  </article>
                </div>
              ))}
            </div>}
          </div>
          {loader ? (
            ""
          ) : roomList?.[0]?.data ? (
            ""
          ) : (
            <NoDataFound />
          )}


          {roomList?.[0]?.metadata?.[0]?.total > 0 ? (
            <div className="Pagination">
              <Pagination
                activePage={activePage}
                itemsCountPerPage={12}
                totalItemsCount={roomList?.[0]?.metadata?.[0]?.total}
                pageRangeDisplayed={5}
                onChange={(e) => handlePageChange(e)}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <Modal
        show={DeleteModal}
        onHide={handleDeleteModalClose}
        className="ModalBox "
      >
        <Modal.Body>
          <div className="Decline">
            <button className="CloseModal" onClick={handleDeleteModalClose}>
              ×
            </button>
            {!Assigned && <h3>Delete</h3>}
            {Assigned ? (
              <p>You can't {Type=="edit"?"update" :"delete"} assigned room.</p>
            ) : (
              <p>Are you sure you want to delete this room ?</p>
            )}
            <h4>
              <a onClick={handleDeleteModalClose} className="Cancel">
                Cancel
              </a>
              {!Assigned && <a onClick={handleDelete}>Delete</a>}
            </h4>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={AddModal} onHide={handleAddModalClose} className="ModalBox ">
        <Modal.Body>
          <div class="Category">
            <a className="CloseModal" onClick={handleAddModalClose}>
              ×
            </a>
            {Type == "edit" ? <h3>Update Room</h3> : <h3>Add Room</h3>}
            {Type !== "edit" && (
              <div class="form-group">
                <label class="CheckBox">
                  Single
                  <input
                    type="radio"
                    value="Restaurant"
                    checked={roomType == "single" ? true : false}
                    onChange={(e) => handleCheck(e, "single")}
                    name="Restaurant"
                  />
                  <span class="checkmark"></span>
                </label>
                <label class="CheckBox">
                  Multiple
                  <input
                    type="radio"
                    value="Restaurant"
                    onChange={(e) => handleCheck(e, "multiple")}
                    name="Restaurant"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
            )}
            {roomType == "single" && (
              <div class="form-group">
                <label>Add Room Number</label>
                <div className="RoomBox">
                  <input
                    type="text"
                    class="form-control"
                    name="title"
                    value={title}
                    onChange={handleChange}
                    placeholder="Enter room Prefix"
                  />
                  <input
                    type="text"
                    class="form-control"
                    value={roomNumber}
                    name="roomNumber"
                    placeholder="Enter room number"
                    onChange={handleChange}
                  />
                </div>

                {errorMsg.roomNumber && !roomNumber && (
                  <p style={{ color: "red" }}>{errorMsg.roomNumber}</p>
                )}
              </div>
            )}

            {roomType == "multiple" && (
              <div class="form-group">
                <div className="RoomBox">
                  <label>Prefix</label>

                  <input
                    type="text"
                    class="form-control"
                    value={title}
                    name="title"
                    onChange={handleChange}
                  />
                </div>
              </div>
            )}

            {roomType == "multiple" && (
              <div class="form-group">
                <div className="RoomBox">
                  <label>From</label>

                  <input
                    type="text"
                    class="form-control"
                    value={start}
                    name="start"
                    onChange={handleChange}
                  />
                  {errorMsg.start && !start && (
                    <p style={{ color: "red" }}>{errorMsg.start}</p>
                  )}

                  <label>To</label>

                  <input
                    type="text"
                    class="form-control"
                    value={end}
                    name="end"
                    onChange={handleChange}
                  />
                  {errorMsg.end && !end && (
                    <p style={{ color: "red" }}>{errorMsg.end}</p>
                  )}
                </div>
                {errorMsg.roomValid && (
                  <p style={{ color: "red" }}>{errorMsg.roomValid}</p>
                )}
              </div>
            )}
            <div class="form-group">
              <label>Select or Add category</label>
              <select
                value={category}
                name="category"
                onChange={handleChange}
                class="form-control"
              >
                <option>Select </option>
                {categoryList?.roomCategory?.map((item) => (
                  <option value={item}>{item}</option>
                ))}
                {Type=="add" &&<option value="Add Category">Add Category</option>}
              </select>
              {errorMsg.category && !category && (
                <p style={{ color: "red" }}>{errorMsg.category}</p>
              )}
            </div>

            {category == "Add Category" && (
              <div class="form-group">
                <div className="CategoryAdd">
                  <input
                    type="text"
                    class="form-control"
                    value={roomCategory}
                    placeholder="Enter category name"
                    onChange={(e) => setRoomCategory(e.target.value)}
                  />
                 
                </div>
                {errorMsg.category && !roomCategory && (
                <p style={{ color: "red" }}>{errorMsg.category}</p>
              )}
              </div>
            )}
            {(Type == "edit" ? (
              <button onClick={handleEdit} disabled={stopper} class="Button" data-dismiss="modal">
                Update Room
              </button>
            ) : (
              <button
                onClick={handleSubmit}
                disabled={stopper}
                class="Button"
                data-dismiss="modal"
              >
                Add Room
              </button>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </div>

  );
};

export default HotelRoom;
