import { dashboardContant } from "../actionTypes";
const initialState = {
    dashboardData: {},
    notificationList:[],
    loader: false,
    error: {},
  };
  
  export default function dashboardReducer(
    state = initialState,
    { type, payload }
    
  ) {
    switch (type) {

      case dashboardContant.DASHBOARD_INITIATE:
        return { ...state, loader: true, dashboardData: {} };
  
      case dashboardContant.DASHBOARD_SUCCESS:
        return { ...state, loader: false, dashboardData: payload.data };
  
      case dashboardContant.DASHBOARD_FAILURE:
        return { ...state, loader: false, error: payload };


        case dashboardContant.NOTIFICATION_LIST_INITIATE:
          return { ...state, loader: true, notificationList: {} };
    
        case dashboardContant.NOTIFICATION_LIST_SUCCESS:
          return { ...state, loader: false, notificationList: payload.data };
    
        case dashboardContant.NOTIFICATION_LIST_FAILURE:
          return { ...state, loader: false, error: payload };
  

  
          case dashboardContant.GET_SPLITPERCENTAGE_INITIATE:
            return { ...state, loader: true };
          case dashboardContant.GET_SPLITPERCENTAGE_SUCCESS:
            return { ...state, loader: false };
          case dashboardContant.GET_SPLITPERCENTAGE_FAILURE:
            return { ...state, loader: false, error: payload };
    
          case dashboardContant.EDIT_SPLITPERCENTAGE_INITIATE:
            return { ...state, loader: true };
          case dashboardContant.EDIT_SPLITPERCENTAGE_SUCCESS:
            return { ...state, loader: false };
          case dashboardContant.EDIT_SPLITPERCENTAGE_FAILURE:
            return { ...state, loader: false, error: payload };
      
  
      default:
        return state;
    }
  }
  