import React from 'react'
import Header from './header'
import Sidenav from './sidenav'
import { Link, useLocation, useParams } from 'react-router-dom'
import ImageFetcher from './ImageFetcher'

const RoleDetails = () => {
    const {state} = useLocation()
    const {id} = useParams()

    console.log(state)

  return (
    <>
    <Header />
    <Sidenav />
    <div class="WrapperArea">
        <div class="WrapperBox">

        <div className='CommonLinks'>
            <Link to={`/roles-management/${id}`}><i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back</Link>
            </div>
            
            <div class="Small-Wrapper">
                <div class="NewTitleBox">
                    <h4>Staff Details</h4>
                </div>
                
                <div class="GuestUserBox">
                    <figure>
                    <ImageFetcher imageUrl={state?.profileImage}/>
                    </figure>
                    <figcaption>
                        <div class="GuestUserHead">
                            <aside>
                                <p>
                                    <label>Name </label>
                                    <span>{state?.name}</span>
                                </p>
                                <p>
                                    <label>Gender</label>
                                    <span>                            {state?.gender?.charAt(0)?.toUpperCase() +
                              state?.gender?.slice(1)}
</span>
                                </p>
                                <p>
                                    <label>Duty Hours</label>
                                    <span>{state?.duty_hours?.start} to {state?.duty_hours?.end}</span>
                                </p>
                                <p>
                                    <label>Reporting To</label>
                                    <span>Anjesh Kumar</span>
                                </p>
                                
                            </aside>
                            <aside>
                                <p>
                                    <label>Email</label>
                                    <span>{state?.email}</span>
                                </p>
                                <p>
                                    <label>Phone Number</label>
                                    <span>{state?.phone}</span>
                                </p>
                                <p>
                                    <label>Nationality</label>
                                    <span>{state?.nationality}</span>
                                </p>
                            </aside>
                        </div>
                    </figcaption>
                </div>

            </div>
            
        </div> 
    </div>

    </>
  )
}

export default RoleDetails