import React, { useEffect, useState } from "react";
import Header from "./header";
import Sidenav from "./sidenav";
import { useDispatch, useSelector } from "react-redux";
import {
  HotelDetailsAction,
  ReviewListAction,
  ReviewReplyAction,
} from "../redux/actions/hotelAction";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import ImageFetcher from './ImageFetcher'

const initialState = {
  message: "",
  errorMsg: "",
  id: "",
};

const HotelReviews = () => {
  const dispatch = useDispatch();
  const { hotelId } = useParams();
  const { hotelData } = useSelector((state) => state.hotelReducer);

  const [iState, updateState] = useState(initialState);
  const { errorMsg, message, id } = iState;

  const [ReplyModal, setReplyModal] = useState(false);
  const handleReplyModalClose = () => setReplyModal(false);
  const handleReplyModalShow = (id) => {
    setReplyModal(true);
    updateState({ ...iState, id: id });
  };

  const { reviewList } = useSelector((state) => state.hotelReducer);

  useEffect(() => {
    dispatch(ReviewListAction({ hotelId: hotelId }));
    dispatch(HotelDetailsAction({ hotelId: hotelId }));
  }, []);

  const handleValidate = () => {
    let errorMsg = "";
    let formIsValid = true;
    if (!message) {
      formIsValid = false;
      errorMsg = "* Please enter message";
    }

    updateState({ ...iState, errorMsg: errorMsg });

    return formIsValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formIsValid = handleValidate();

    if (formIsValid) {
      dispatch(ReviewReplyAction({ id: id, message: message, hotelId }))
        .then((res) => {
          if (res.data.success === true) {
            updateState({  message: "",
            errorMsg: "",
            id: "",})
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setReplyModal(false);
            dispatch(ReviewListAction({ hotelId: hotelId }));
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          alert(err);
        });
    }
  };


  return (
    <>
      <Header />
      <Sidenav />

      <div class="WrapperArea">
        <div class="WrapperBox">
          <div class="Small-Wrapper">
            <div class="NewTitleBox">
              <div className="arrow-left">
                <Link to={`/hotel-details/${hotelId}`}>
                  <i class="fa fa-arrow-left arrow-back" aria-hidden="true"></i>
                </Link>
                <h4>Rating &amp; Reviews</h4>
              </div>
            </div>

            <div class="partner-details">
              <div class="row">
                <div class="col-12">
                  <div class="partner-details-body">
                    <div class="UserBox">
                      <div class="UserBody">
                        <figure>
                        <ImageFetcher imageUrl={hotelData?.image}/>
                        </figure>
                        <aside>
                          <p>
                            <label>Total Rooms </label>{" "}
                            <span>{hotelData?.totalRooms}</span>
                          </p>
                          <p>
                            <label>Phone Number </label>{" "}
                            <span> {hotelData?.phone}</span>
                          </p>
                          <p>
                            <label>Email</label>{" "}
                            <span> {hotelData?.email}</span>
                          </p>
                        </aside>
                        <article>
                          <p>
                            <span>
                              <img src="images/star.svg" alt="" />
                            </span>
                            <a>{hotelData?.totalReview} Reviews</a>
                          </p>
                        </article>
                      </div>
                    </div>
                  </div>
                </div>
                </div>


                {reviewList?.map((item) => (
                  <div class="total-order">
                    <div class="comanlinks-contant">
                      <div class="subscription">
                        <p class="order-Date">
                          {" "}
                          {moment(item?.createdAt).format("ll")}
                        </p>
                        <div class="comn-item">
                          {
                            (item?.reviewer?.profileImage)?
                            <ImageFetcher imageUrl={item?.reviewer?.profileImage}/>
                            :
                            <img
                            src={ require("../assets/image/avatar.png") }
                            alt=" "
                          />
                          }
                          
                          
                          <h2>{item?.reviewer?.name}</h2>
                          <span>
                            <i class="fa fa-star" aria-hidden="true"></i>{" "}&nbsp;
                            {item?.rating} &nbsp;Rating
                          </span>
                        </div>
                        <p>{item?.reviews}</p>
                        <a  onClick={() => handleReplyModalShow(item?._id)}>
                          Reply
                        </a> 
                        {item?.replies?.map((ele) => (
                          <aside  className="reply-right">
                            <div className="reply-body">
                              <div class="comn-item">
                                <img
                                  src={require("../assets/image/Avatar-3.png")}
                                  alt=" "
                                />
                                <p className="reply-msg">{ele?.message}</p>
                                
                              </div>
                            </div>
                          </aside>
                        ))}
                       <a  onClick={() => handleReplyModalShow(item?._id)}>
                          Reply
                        </a> 
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={ReplyModal}
        onHide={handleReplyModalClose}
        className="ModalBox "
      >
        <Modal.Body>
          <div class="Category">
            <a onClick={handleReplyModalClose} class="CloseModal">
              ×
            </a>
            <h3>Send Review</h3>
            <div class="form-group">
              <label>Review</label>
              <textarea
                value={message}
                onChange={(e) =>
                  updateState({ ...iState, message: e.target.value })
                }
                type="text"
                class="form-control"
              />
              {errorMsg && <p style={{ color: "red" }}>{errorMsg}</p>}
            </div>

            <button class="Button" data-dismiss="modal" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default HotelReviews;
