import React, { useEffect, useState, useRef } from "react";
import Sidenav from "./sidenav";
import Header from "./header";
import Modal from "react-bootstrap/Modal";
import { Link ,useParams} from "react-router-dom";
import { useLocation } from "react-router";
import moment from "moment";
import Multiselect from "multiselect-react-dropdown";
import { toast } from "react-toastify";
import { RoomCategoryListAction } from "../redux/actions/roomAction";
import ImageFetcher from './ImageFetcher'
import Loader from "./loader";

import { useDispatch, useSelector } from "react-redux";
import {
  ManualCheckinAction,
  GetAvailbleRoomAction,
} from "../redux/actions/guestAction";
import { useContext } from "react";

const initialState = {
  numberOfRoom: "",
  room: [],
  errorMsg: {},
  meal: "",
  stay_duration: "",
  roomCategory: "",
};
const GuestsManualVerification = () => {
  const { state } = useLocation();
  const [guestData, setGuestData] = useState([]);


const {id} = useParams()

  const { categoryList } = useSelector((state) => state.roomReducer);

  const [loader, setLoader] = useState(false);
  const [iState, updateState] = useState(initialState);
  const { availableRooms } = useSelector((state) => state.guestReducer);
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");

  const [totalRoom, setTotalRoom] = useState([]);

  const { numberOfRoom, room, meal, errorMsg, stay_duration, roomCategory } =
    iState;

  const dropDownRefCategory = useRef();

  const [SuccessGuestModal, setSuccessGuestModal] = useState(false);
  const handleSuccessModalClose = () => setSuccessGuestModal(false);
  const handleSuccessModalShow = (id) => {
    setSuccessGuestModal(true);
  };

  function onSelectRoom(selectedList, selectedItem) {
    updateState({
      ...iState,
      room: [...room, selectedItem?._id],
    });
  }

  function onRemoveRoom(selectedList, removedItem) {
    let tempList = room;
    const removeIndex = tempList.findIndex((item) => item === removedItem._id);
    tempList.splice(removeIndex, 1);

    updateState({
      ...iState,
      room: tempList,
    });
  }

  const handleRefresh = () => {
    window.location.reload();
  };

  let handleValidation = () => {
    let errorMsg = {};
    let formIsValid = true;

    if (room.length == 0) {
      errorMsg.room = "* Please select room";
      formIsValid = false;
    }
    if (!stay_duration) {
      errorMsg.stay_duration = "* Please enter number of days";
      formIsValid = false;
    }

    if (!meal) {
      errorMsg.meal = "* Please select meal";
      formIsValid = false;
    }

    updateState({ ...iState, errorMsg: errorMsg });
    return formIsValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name == "stay_duration") {
      if (!/^[0-9]{0,10}$/.test(value)) return;
      {
        updateState((prev) => ({ ...prev, [name]: value }));
      }
    } else {
      updateState({ ...iState, [name]: value });
    }
  };

  useEffect(() => {
    const hotelData = JSON.parse(localStorage.getItem("hotel"));

    dispatch(GetAvailbleRoomAction({ hotelId: id }));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    let formIsValid = handleValidation();
    setLoader(formIsValid);

    if (formIsValid) {
      const hotelData = JSON.parse(localStorage.getItem("hotel"));

      let data;

      // let temp =guestData.forEach(object => {
      //   delete object.kyc_details;
      // });
      let temp =
        guestData?.length > 0 &&
        guestData?.map((ele) => {
          let { kyc_details, ...localTemp } = ele;
          return localTemp;
        });
      if (state) {
        data = state;
        data["co_occupants"] = temp;
        data["numberOfRoom"] = room?.length;
        data["stay_duration"] = stay_duration;
        data.rooms = room;
        data["hotelId"] = hotelData?.hotelId?.[0]?._id;
        data["meal"] = meal;
        data["hotelId"] = id;

        data["checkInDate"] = moment().format();
      }

      dispatch(ManualCheckinAction(data))
        .then((res) => {
          if (res.status === 200) {
            setLoader(false);
            setOtp(res?.data?.data?.checkInOtp);

            setSuccessGuestModal(true);
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(res.response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const protect_Doc = (user_email) => {
    var avg, splitted, part1, part2;
    splitted = user_email.slice(0, 4);
    part1 = user_email.slice(0, 4);
    part2 = user_email.slice(user_email.length - 4, user_email.length);
    return "*********" + part2;
  };

  useEffect(() => {
    let temp = state.co_occupants.map((item) => {
      item.kyc_details.docData.docNumber =
        item.kyc_details.docData.docNumber == ""
          ? item.kyc_details.docData.docNumber
          : protect_Doc(item.kyc_details.docData.docNumber);

      return item;
    });

    setGuestData(temp);
  }, []);

  useEffect(() => {
    dropDownRefCategory.current.resetSelectedValues();

    if (roomCategory == "") {
      setTotalRoom(availableRooms);
    } else {
      let temp = availableRooms?.filter((ele) => ele.category == roomCategory);

      setTotalRoom(temp);
    }

  }, [availableRooms, roomCategory]);

  return (
    <div>
      <Header />
      <Sidenav />
      <div class="WrapperArea">
        <div class="WrapperBox">
          <div class="back-btn">
            <Link to={`/guests-management/${id}`}>
              <i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back
            </Link>
          </div>

          {loader ? (
            <Loader />
          ) : (
            <div class="Small-Wrapper">
              <div class="NewTitleBox">
                <h4>Guests Verification</h4>
              </div>
              {guestData?.map((item) => (
                <div class="GuestUserBox">
                  <figure>
                     <ImageFetcher imageUrl={item?.kyc_details?.docData?.profileImage}/>
                  </figure>
                  <figcaption>
                    <div class="GuestUserHead">
                      <aside>
                        <p>
                          <label>Name </label>
                          <span>{item?.name}</span>
                        </p>
                        {/* <p>
                                  <label>Guest ID</label>
                                  <span>GUEST1234</span>
                              </p> */}
                        <p>
                          <label>Gender</label>
                          <span>
                            {" "}
                            {item?.gender
                              ?.replace(/_/g, " ")
                              ?.charAt(0)
                              ?.toUpperCase() +
                              item?.gender?.replace(/_/g, " ")?.slice(1)}
                          </span>
                        </p>
                        <p>
                          <label>Date of Birth</label>
                          <span>
                            {moment(item?.kyc_details?.docData?.dob).format(
                              "ll"
                            )}
                          </span>
                        </p>
                        <p>
                          <label>Age</label>
                          <span>{item?.age} Years</span>
                        </p>
                        {item?.kyc_details?.docData?.frontDoc && (
                          <p>
                            <label> Documents</label>
                            <span class="passport">
                            <ImageFetcher imageUrl={item?.kyc_details?.docData?.frontDoc}/>
                            </span>
                            <span class="passport">
                            <ImageFetcher imageUrl={item?.kyc_details?.docData?.backDoc}/>
                            </span>
                          </p>
                        )}
                      </aside>
                      <aside>
                        {item?.type !== "kid" && (
                          <p>
                            <label>Email</label>
                            <span>
                              {" "}
                              {item?.email == "" || item?.email == "false"
                                ? "NA"
                                : item?.email}
                            </span>
                          </p>
                        )}
                        {item?.type !== "kid" && (
                          <p>
                            <label>Phone Number</label>
                            <span> {item?.phone}</span>
                          </p>
                        )}
                        <p>
                          <label>Address</label>
                          <span>{item?.kyc_details?.docData?.address}</span>
                        </p>
                        {/* {item?.type !=="kid" &&
   <p>
                        <label>Verified with Document</label>
                        <span> {item?.kyc_details?.docType}</span>
                      </p>}
                      {item?.type !=="kid" &&
<p>
                        <label>Document Number</label>
                        <span> {item?.kyc_details?.docData?.docNumber}</span>

                        
                      </p>} */}
                      </aside>
                    </div>
                  </figcaption>
                </div>
              ))}

              <div class="CommonForm">
                <div class="row">
                  <div class="col-sm-3">
                    <div class="form-group">
                      <label>
                        Please select number of rooms needs to be allocated to
                        guests
                      </label>
                      <select
                        value={meal}
                        name="meal"
                        onChange={handleChange}
                        class="form-control"
                      >
                        <option value="">Select </option>
                        <option value="CP">
                          CP (Continental Plan) With Breakfast{" "}
                        </option>
                        <option value="MAP">
                          MAP Buffer (Breakfast + Buffet Lunch/Dinner){" "}
                        </option>
                        <option value="AP">
                          AP (Buffet Breakfast + Buffet Lunch + Buffet Dinner){" "}
                        </option>
                        <option value="EP">
                          EP (all meals are extra chargeable){" "}
                        </option>
                      </select>
                      {errorMsg.meal && (
                        <p style={{ color: "red" }}>{errorMsg.meal}</p>
                      )}
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group">
                      <label>Stay Duration</label>
                      <input
                        value={stay_duration}
                        name="stay_duration"
                        type="text"
                        class="form-control"
                        placeholder="Enter Stays days"
                        onChange={handleChange}
                      />
                      {errorMsg.stay_duration && (
                        <p style={{ color: "red" }}>{errorMsg.stay_duration}</p>
                      )}
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group">
                      <label>Room category</label>

                      <select
                        name="roomCategory"
                        onChange={handleChange}
                        value={roomCategory}
                        class="form-control"
                      >
                <option value={''}>All </option>
                        {categoryList?.roomCategory?.map((item) => (
                          <option value={item}>{item}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group">
                      <label>Select Room</label>
                      <Multiselect
                        options={totalRoom}
                        selectedValues={totalRoom.selected}
                        displayValue="fullNumber"
                        onSelect={onSelectRoom}
                        ref={dropDownRefCategory}
                        onRemove={onRemoveRoom}
                        resetSelectedValues={handleRefresh}
                      />

                      {errorMsg.room && (
                        <p style={{ color: "red" }}>{errorMsg.room}</p>
                      )}
                    </div>
                  </div>
                </div>
                <button class="Button" type="button" onClick={handleSubmit}>
                  Confirm &amp; Allocate Rooms
                </button>
                &nbsp;&nbsp;&nbsp;
                <Link
                  class="Button"
                  to={`/guests-management/${id}`}
                  style={{ backgroundColor: "#c70000" }}
                  type="button"
                >
                  Cancel
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>

      <Modal
        show={SuccessGuestModal}
        // onHide={handleSuccessModalClose}
        className="ModalBox "
      >
        <Modal.Body>
          <div class="Decline">
            <Link className="CloseModal" to={`/guests-management/${id}`}>
              ×
            </Link>
            <h3>Please enter OTP</h3>
            {/* <figure>
              <img src={require("../assets/images/QR.png")} alt="" />
            </figure> */}

            <div class="qr-code-otp">
              <form>
                <div class="form-group">
                  <p style={{ textAlign: "center", fontSize: "20px" }}>{otp}</p>

                  {/* <div class="OTPBox">
                                            <input type="text" class="form-control" value={123123} />
                                        </div> */}
                </div>
              </form>
            </div>

            <Link className="Button" to={`/guests-management/${id}`}>
              Back to Guests Stay Management
            </Link>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default GuestsManualVerification;
