import { wifiConstant } from "../actionTypes";
const initialState = {
    wifiList: {},
    loader: false,
    error: {},
  };
  
  export default function wifiReducer(
    state = initialState,
    { type, payload }
    
  ) {
    switch (type) {

      case wifiConstant.WIFI_LIST_INITIATE:
        return { ...state, loader: true, wifiList: {} };
  
      case wifiConstant.WIFI_LIST_SUCCESS:
        return { ...state, loader: false, wifiList: payload.data };
  
      case wifiConstant.WIFI_LIST_FAILURE:
        return { ...state, loader: false, error: payload };



  
  
      default:
        return state;
    }
  }
  