import React from 'react'
import Header from './header'
import Sidenav from './sidenav'
import { Link, useLocation, useParams } from 'react-router-dom'

import Congragulation from '../assets/image/congragulation.png';

const SpaOrderStatus = () => {
    const {state} = useLocation()
    const {id} = useParams()



  return (
    <div>
        <Header />
        <Sidenav />
        <div class="WrapperArea">
        <div class="WrapperBox">
        

            <div className='OrderSatus'>
                <figure>
                    <img src={Congragulation} alt="" />
                </figure>
                <figcaption>
                    <h3>Congratulations!</h3>
                    <h4>Your order is confirmed for {state?.room?.fullNumber}</h4>
                    <h5>Order Status: <span>{state?.status} </span> </h5>
                </figcaption>
                <table>
                    <thead>
                        <tr>
                            <th>Item</th>
                            <th>Qnty</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {state?.items?.map((ele)=>
                            <tr>
                                <td>{ele?.item?.name}</td>
                                <td>{ele?.qty}</td>
                                <td>{ele?.item?.price} INR</td>
                            </tr>
                        )}
                                                <tr>
                            <td>GST{state?.amount?.tax?.taxPercent} %</td>
                            <td>-</td>
                            <td>{state?.amount?.tax?.taxAmount}</td>
                        </tr>

                        <tr>
                            <td>Total Amount</td>
                            <td>-</td>
                            <td>{state?.amount?.totalAmount} INR</td>
                        </tr>
                    </tbody> 
                </table>

                {/* <div class="OTPBox">
                    <input disabled={true} type="text" value={state?.otp[0]}  class="form-control" maxlength="1" />
                    <input disabled={true}type="text"  value={state?.otp[1]} class="form-control" maxlength="1" />
                    <input disabled={true}type="text"  value={state?.otp[2]} class="form-control" maxlength="1" />
                    <input disabled={true}type="text"  value={state?.otp[3]} class="form-control" maxlength="1" />
                </div> */}

                {/* <p>Note: you can show this QR code or OTP to customer to <br /> add bill in his dues.</p> */}
                <Link class="TitleLink" to={`/spa-order-request/${id}`}>Back to Home</Link>
                
            </div>



        </div>
    </div>
        

    </div>
  )
}

export default SpaOrderStatus