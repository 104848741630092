import React, { useEffect, useState } from "react";
import Header from "./header";
import Sidenav from "./sidenav";
import { useDispatch, useSelector } from "react-redux";
import { HotelCouponUsageAction } from "../redux/actions/financeAction";
import moment from "moment/moment";
import NoDataFound from "./noDataFound";
import Pagination from "react-js-pagination";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Loader from "./loader";

const ServicePackage = () => {
  const [filterData, setFilterData] = useState({
    search: "",
    fromDate: "",
    toDate: "",
    timeFrame: "",
    page: 1,
    status: "",
  });

  const [activePage, setActivePage] = useState(1);

  const { couponUsage, loader } = useSelector((state) => state.financeReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(HotelCouponUsageAction());
  }, []);

  const handleFilter = (e) => {
    dispatch(
      HotelCouponUsageAction({
        search: filterData?.search,
        fromDate: filterData?.fromDate,
        toDate: filterData?.toDate,
        timeFrame: filterData?.timeFrame,
        status: filterData?.status,
      })
    );
  };

  const handleRefresh = (e) => {
    setFilterData({
      ...filterData,
      search: "",
      fromDate: "",
      toDate: "",
      timeFrame: "",
      status: "",
    });

    dispatch(HotelCouponUsageAction());
  };

  const handleSearch = (e) => {
    dispatch(HotelCouponUsageAction({ search: e.target.value }));
    setFilterData((prev) => ({ ...prev, search: e.target.value }));
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    filterData.page = pageNumber;
    HotelCouponUsageAction({
      search: filterData?.search,
      fromDate: filterData?.fromDate,
      toDate: filterData?.toDate,
      timeFrame: filterData?.timeFrame,
      status: filterData?.status,
      page:pageNumber
    })  };

  const downloadRowData = (rowData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    let result = rowData?.map((item, i) => {
      return {
        "Sr. No": i + 1,
        "Hotel Name": item?.hotelId?.[0]?.name,
        "Total Bill":item?.totalBill?.toFixed(2),
        "Coupon type":                         item?.couponId?.category?.charAt(0)?.toUpperCase() +
            item?.couponId?.category?.slice(1),
        "Totel Discount": item?.totalDiscount?.toFixed(2),
        "Room Number": item?.room?.fullNumber,
      };
    });

    const ws = XLSX.utils.json_to_sheet(result);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "finance-record.xlsx");
  };

  return (
    <>
      <Header />
      <Sidenav />

      <div class="WrapperArea">
        <div class="WrapperBox">
          <div class="Small-Wrapper">
            <div class="TitleBox">
              <h4 class="Title">Coupon Purchases &amp; Usage</h4>
              <a class="add" onClick={()=>downloadRowData(couponUsage?.[0]?.data)}>
                Export to Excel
              </a>
            </div>
            <div class="Filter">
              <div class="form-group">
                <label>Search</label>
                <input
                  type="text"
                  onChange={handleSearch}
                  value={filterData?.search}
                  class="form-control"
                  placeholder="Search by hotel name "
                />
              </div>

              <div class="form-group">
                        <label>From Date</label>
                        <input type="date" value={filterData?.fromDate}  onChange={(e)=>setFilterData({...filterData, fromDate:e.target.value, timeFrame:""})} class="form-control" />
                    </div>
                    <div class="form-group">
                        <label>To Date</label>
                        <input type="date" disabled={!filterData?.fromDate} value={filterData?.toDate} min={filterData?.fromDate} onChange={(e)=> setFilterData({...filterData, toDate:e.target.value ,timeFrame:""})} class="form-control" />
                    </div> 
                    <div class="form-group">
                        <label>Timeframe</label>
                        <select value={filterData?.timeFrame} onChange={(e)=> setFilterData({...filterData,fromDate:"",toDate:"", timeFrame:e.target.value})} class="form-control">
                            <option value="">--Select--</option>
                            <option value="Today">Today</option>
                            <option value="Week">This Week</option>
                            <option value="Month">This Month</option>
                        </select>
                    </div>
              <div class="form-group">
                <label>&nbsp;</label>
                <button onClick={handleFilter} class="Button">
                  Apply
                </button>
                <button onClick={handleRefresh} class="Button Cancel">
                  <i class="fa fa-refresh"></i>
                </button>
              </div>
            </div>
            {loader? <Loader />:

            <div class="TableList">
              <table style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th>Hotel Name</th>
                    <th>Guest Name</th>
                    <th>Total Bill</th>
                    <th>Coupon type</th>
                    <th>total discount</th>
                    <th>Room Number</th>
                  </tr>
                </thead>
                <tbody>
                  {couponUsage?.[0]?.data?.map((item, i) => (
                    <tr>
                      <td>{item?.hotelId?.name}</td>
                      <td>{item?.userId?.name}</td>
                      <td>{item?.totalBill?.toFixed(2)}</td>
                      <td>
                        {item?.couponId?.category?.charAt(0)?.toUpperCase() +
                          item?.couponId?.category?.slice(1)}
                      </td>
                      <td>{item?.totalDiscount?.toFixed(2)}</td>
                      <td>{item?.room?.fullNumber}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>}

            {loader ? "" : couponUsage?.[0]?.data ? "" : <NoDataFound />}

            {couponUsage?.[0]?.metadata?.[0]?.total > 0 ? (
              <div className="Pagination">
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={couponUsage?.[0]?.metadata?.[0]?.total}
                  pageRangeDisplayed={5}
                  onChange={(e) => handlePageChange(e)}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        </div>
    </>
  );
};

export default ServicePackage;
