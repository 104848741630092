import { spaConstant } from "../actionTypes";

import axios from "../../axiosInstance"
import { baseUrl } from "../../config/config";
import { isLoggedIn } from "../../utils";




// ADD SPA

export const addSpaInitiate=() =>({
    type:spaConstant.ADD_SPA_INITIATE

})

export const addSpaSuccess=(data) =>({
    type:spaConstant.ADD_SPA_SUCCESS,
    payload:data

})

export const addSpaFailure=(data) =>({
    type:spaConstant.ADD_SPA_FAILURE,
    payload : data

})


export const  AddSpaAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(addSpaInitiate());
        const response = await axios.post(`${baseUrl}/admin/spa/create`, payload,{
            headers: {authorization:`${token}`}})

        if (response.data.success === true) {
          dispatch(addSpaSuccess(response.data));
        } else {
          dispatch(addSpaFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(addSpaFailure(err));
        return err;
      }


}


// EDIT SPA

export const editSpaInitiate=() =>({
    type:spaConstant.UPDATE_SPA_INITIATE

})

export const editSpaSuccess=(data) =>({
    type:spaConstant.UPDATE_SPA_SUCCESS,
    payload:data

})

export const editSpaFailure=(data) =>({
    type:spaConstant.UPDATE_SPA_FAILURE,
    payload : data

})


export const  EditSpaAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(editSpaInitiate());
        const response = await axios.put(`${baseUrl}/admin/spa/update`, payload,{
            headers: {authorization:`${token}`}})

        if (response.data.sucess === true) {
          dispatch(editSpaSuccess(response.data));
        } else {
          dispatch(editSpaFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(editSpaFailure(err));
        return err;
      }


}



// REMOVE SPA

export const removeSpaInitiate=() =>({
    type:spaConstant.REMOVE_SPA_INITIATE

})

export const removeSpaSuccess=(data) =>({
    type:spaConstant.REMOVE_SPA_SUCCESS,
    payload:data

})

export const removeSpaFailure=(data) =>({
    type:spaConstant.REMOVE_SPA_FAILURE,
    payload : data

})


export const  RemoveSpaAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(removeSpaInitiate());
        const response = await axios.delete(`${baseUrl}/admin/spa/remove`, {
          headers: {Authorization:`${token}`},params: payload})
          console.log(response)

        if (response.data.sucess === true) {
          dispatch(removeSpaSuccess(response.data));
        } else {
          dispatch(removeSpaFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(removeSpaFailure(err));
        return err;
      }


}



//  SPA LIST

export const spaListInitiate=() =>({
    type:spaConstant.SPA_LIST_INITIATE

})

export const spaListSuccess=(data) =>({
    type:spaConstant.SPA_LIST_SUCCESS,
    payload:data

})

export const spaListFailure=(data) =>({
    type:spaConstant.SPA_LIST_FAILURE,
    payload : data

})


export const  SpaListAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(spaListInitiate());
        const response = await axios.get(`${baseUrl}/admin/spa/get`, {
          headers: {Authorization:`${token}`},params: payload})

          if (response.data.success === true) {
            dispatch(spaListSuccess(response.data));
        } else {
          dispatch(spaListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(spaListFailure(err));
        return err;
      }


}



// SET TAX

export const setTaxInitiate=() =>({
  type:spaConstant.SPA_TAX_INITIATE

})

export const setTaxSuccess=(data) =>({
  type:spaConstant.SPA_TAX_SUCCESS,
  payload:data

})

export const setTaxFailure=(data) =>({
  type:spaConstant.SPA_TAX_FAILURE,
  payload : data

})


export const  SetSpaTaxAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(setTaxInitiate());
      const response = await axios.put(`${baseUrl}/admin/spa/update/tax`, payload,{
        headers: {authorization:`${token}`}})



      if (response.status == 200) {
        dispatch(setTaxSuccess(response.data));
      } else {
        dispatch(setTaxFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(setTaxFailure(err));
      return err;
    }


}



// UPDATE SPA TIME

export const updateSpaTimeInitiate=() =>({
  type:spaConstant.UPDATE_SPA_INITIATE

})

export const updateSpaTimeSuccess=(data) =>({
  type:spaConstant.UPDATE_SPA_SUCCESS,
  payload:data

})

export const updateSpaTimeFailure=(data) =>({
  type:spaConstant.UPDATE_SPA_FAILURE,
  payload : data

})


export const  UpdateSpaTimeSpaAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(updateSpaTimeInitiate());
      const response = await axios.put(`${baseUrl}/admin/spa/update/timing`, payload,{
          headers: {authorization:`${token}`}})

      if (response.data.sucess === true) {
        dispatch(updateSpaTimeSuccess(response.data));
      } else {
        dispatch(updateSpaTimeFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(updateSpaTimeFailure(err));
      return err;
    }


}
