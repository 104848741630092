import { subscriptionManagementConstant } from "../actionTypes";
const initialState = {
    subscriptions: [],
    hotelsubscrptionList: [],
    couponList: [],
    subscriptionType: [],
    currentSubscription: [],
    oldSubscriptions: [],
    futureSubscriptions: [],
    hotelId: null,
    loader: false,
    error: {},
  };
  
  export default function subscriptionReducer(
    state = initialState,
    { type, payload }
    
  ) {
    switch (type) {
      case subscriptionManagementConstant.GET_SUBSCRIPTION_INITIATE:
        return { ...state, loader: true, subscriptions: []}
      case subscriptionManagementConstant.GET_SUBSCRIPTION_SUCCESS:
        return { ...state, loader: false, subscriptions: payload.data}
      case subscriptionManagementConstant.GET_SUBSCRIPTION_FAILURE:
        return { ...state, loader: false, error: payload };
  
      case subscriptionManagementConstant.HOTEL_SUBSCRIPTION_LIST_INITIATE:
        return { ...state, loader: true, hotelsubscrptionList: []}
      case subscriptionManagementConstant.HOTEL_SUBSCRIPTION_LIST_SUCCESS:
        console.log("mai payload", payload);
        return { ...state, loader: false, hotelsubscrptionList: payload.data}
      case subscriptionManagementConstant.HOTEL_SUBSCRIPTION_LIST_FAILURE:
        return { ...state, loader: false, error: payload };

      case subscriptionManagementConstant.GET_SUBSCRIPTION_COUPON_INITIATE:
        return { ...state, loader: true, couponList: []}
      case subscriptionManagementConstant.GET_SUBSCRIPTION_COUPON_SUCCESS:
        return { ...state, loader: false, couponList: payload.data}
      case subscriptionManagementConstant.GET_SUBSCRIPTION_COUPON_FAILURE:
        return { ...state, loader: false, error: payload };

      case subscriptionManagementConstant.COUPON_STATUS_INITIATE:
        return { ...state, loader: true}
      case subscriptionManagementConstant.COUPON_STATUS_SUCCESS:
          return { ...state, loader: false}
      case subscriptionManagementConstant.COUPON_STATUS_FAILURE:
          return { ...state, loader: false}


      case subscriptionManagementConstant.CREATE_COUPON_INITIATE:
        return { ...state, loader: true}
      case subscriptionManagementConstant.CREATE_COUPON_SUCCESS:
        return { ...state, loader: false}
      case subscriptionManagementConstant.CREATE_COUPON_FAILURE:
        return { ...state, loader: false}

      case subscriptionManagementConstant.UPDATE_COUPON_INITIATE:
        return { ...state, loader: true}
      case subscriptionManagementConstant.UPDATE_COUPON_SUCCESS:
        return { ...state, loader: false}
      case subscriptionManagementConstant.UPDATE_COUPON_FAILURE:
        return { ...state, loader: false}

      case subscriptionManagementConstant.GET_SUBSCRIPTION_TYPE_INITIATE:
        return { ...state, loader: true, subscriptionType: []}
      case subscriptionManagementConstant.GET_SUBSCRIPTION_TYPE_SUCCESS:
        return { ...state, loader: false, subscriptionType: payload.data}
      case subscriptionManagementConstant.GET_SUBSCRIPTION_TYPE_FAILURE:
        return { ...state, loader: false, error: payload };

      case subscriptionManagementConstant.GET_SUBSCRIPTION_DETAILS_INITIATE:
        return { ...state, loader: true, currentSubscription: [], oldSubscriptions: [], futureSubscriptions: [], hotelId: null };
      case subscriptionManagementConstant.GET_SUBSCRIPTION_DETAILS_SUCCESS:
        return { ...state, loader: false, currentSubscription: payload.data.currentSubscription, oldSubscriptions: payload.data.oldSubscriptions, futureSubscriptions: payload.data.futureSubscriptions, hotelId: payload.data.hotelId };
      case subscriptionManagementConstant.GET_SUBSCRIPTION_DETAILS_FAILURE:
        return { ...state, loader: false, error: payload };

      case subscriptionManagementConstant.UPDATE_SUBSCRIPTION_GST_INITIATE:
        return { ...state, loader: true};
      case subscriptionManagementConstant.UPDATE_SUBSCRIPTION_GST_SUCCESS:
        return { ...state, loader: false};
      case subscriptionManagementConstant.UPDATE_SUBSCRIPTION_GST_FAILURE:
        return { ...state, loader: false, error: payload };


      
      default:
        return state;
    }
  }
  