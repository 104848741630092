import React, { useEffect, useState } from 'react'
import Header from './header'
import Sidenav from './sidenav';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import commonCrypto from '../utils';
import NoDataFound from "./noDataFound";
import Pagination from "react-js-pagination";
import ImageFetcher from './ImageFetcher';


import {CheckOutApproveListAction} from '../redux/actions/guestAction';
const hotelDetails =  JSON.parse(window.localStorage.getItem("hotelDetails"))


const GuestsCheckoutApprove = () => {
  const {id} = useParams()
    const[search,setSearch] = useState("")
    const [activePage, setActivePage] = useState(1);


    const dispatch = useDispatch()
    const {approveCheckoutList,loader} = useSelector((state)=>state.guestReducer)

    useEffect(()=>{

        dispatch(CheckOutApproveListAction({hotelId:id}))
  
    },[])




    const handleSearch = (e) => {
      dispatch(CheckOutApproveListAction({hotelId:id,
        search: e.target.value
  
      }));
  
      setSearch(e.target.value);
    };
  

    const handlePageChange = (pageNumber) => {
      setActivePage(pageNumber);
      dispatch(CheckOutApproveListAction({ page: pageNumber ,hotelId:id}));
    };
  
  
  return (
    <div>
        <Header />
        <Sidenav />
        <div class="WrapperArea">
        <div class="WrapperBox">
        <div class="HotelLocation HotelLocation2">
            <div class="back-btn">
            <Link to={`/guests-management/${id}`}>
                <i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back{" "}
              </Link>
            </div>
            
            <aside>
              <h3>{hotelDetails?.name}</h3>
              <p>
                <span>
                  <i class="fa fa-map-marker"></i>
                </span>{" "}
                {hotelDetails?.address}
              </p>
            </aside>
</div>

            <div class="NewTitleBox">
            <div className="arrow-left">
            {/* <Link to={`/guests-management/${id}`}>
                  <i class="fa fa-arrow-left arrow-back" aria-hidden="true"></i>
                </Link> */}

                <h4>Approved Checkout</h4> 
            </div>

            <div>
            </div>
          </div>
            <div class="Small-Wrapper">


            <div class="Filter">
              <div class="form-group">
                <label>Search</label>
                <input
                  type="text"
                  value={search}
                  onChange={handleSearch}

                  class="form-control"
                  placeholder="Search.."
                />
              </div>
             
            </div>
            <div class="GuestAccordian">
              <div id="accordion">
                {approveCheckoutList?.[0]?.data?.map((item,index) => (
                  <div class="card">
                    <div
                      class="card-header collapsed"
                      data-toggle="collapse"
                      href={`#collapse${index}`}
                      aria-expanded="false"
                    >
                      <h5>
                        Rooms :{" "}
                        {item?.room?.map((ele) => (
                          <strong>{ele?.fullNumber} </strong>
                        ))}{" "}
                      </h5>
                      <h6>
                        {moment(item?.updatedAt).format("ll")}{" "}
                        <span>(Checkout-April 22,2023)</span>{" "}
                      </h6>
                    </div>

                    <div
                      id={`collapse${index}`}
                      class="collapse"
                      data-parent="#accordion"
                    >

                      <div class="card-body" style={{ borderTop: "none" }}>
                      {item?.guestId?.[0]?.co_occupants?.map((ele,i)=>
                        {
                          let cryptData= commonCrypto.decrypt(ele.userId?.[0]?.kyc_details?.docData)


                          console.log(cryptData?.phone ,'CHECKING')

                          return (
                            <div class="GuestUserBox">
                              <figure>
                              <ImageFetcher imageUrl={cryptData !== null ? cryptData?.kycSelfie : ""}/>
                              </figure>
                              <figcaption>
                                <div class="GuestUserHead">
                                  <aside>
                                    <p>
                                      <label>Name </label>
                                      <span>
                                        {cryptData !== null
                                          ? cryptData?.name
                                          : ele?.name}
                                      </span>
                                    </p>
                                    <p>
                                      <label>Guest ID</label>
                                      <span>
                                        {item?.guestId?.[0]?.guestId}
                                      </span>
                                    </p>
                                    {ele?.type !== "kid" && (
                                      <p>
                                        <label>Gender</label>
                                        <span>
                                          {cryptData !== null
                                            ? cryptData?.gender
                                                ?.charAt(0)
                                                ?.toUpperCase() +
                                              cryptData?.gender?.slice(1)
                                            : ele?.gender
                                                ?.charAt(0)
                                                ?.toUpperCase() +
                                              ele?.gender?.slice(1)}
                                        </span>
                                      </p>
                                    )}
                                    <p>
                                      <label>Date of Birth</label>
                                      <span>
                                        {cryptData !== null
                                          ? cryptData?.dob
                                            ? moment(cryptData?.dob).format(
                                                "ll"
                                              )
                                            : "NA"
                                          : moment(ele?.dob).format("ll")}
                                      </span>
                                    </p>
                                    <p>
                                      <label>Age</label>
                                      <span>
                                        {cryptData !== null
                                          ? cryptData?.age
                                            ? cryptData?.age
                                            : "NA"
                                          : ele?.age}
                                      </span>
                                    </p>
                                    {ele?.type == "adult" &&
                                      cryptData?.docFrontImg && (
                                        <div class="card-guest">
                                          <span>
                                          <ImageFetcher imageUrl={cryptData?.docFrontImg}/>
                                          </span>
                                          <span>
                                          <ImageFetcher imageUrl={cryptData?.docFrontImg}/>
                                          </span>
                                        </div>
                                      )}{" "}
                                  </aside>
                                  <aside>
                                    {ele?.type !== "kid" && (
                                      <p>
                                        <label>Email</label>
                                        {cryptData !== null
                                          ? cryptData?.email == "" ||
                                            cryptData?.email == "false"
                                            ? "NA"
                                            : cryptData?.email
                                          : ele?.email == "" ||
                                            ele?.email == "false"
                                          ? "NA"
                                          : ele?.email}{" "}
                                      </p>
                                    )}
                                    {ele?.type !== "kid" && (
                                      <p>
                                        <label>Phone Number</label>
                                        <span>
                                          {ele?.type == "kid"
                                            ? "NA"
                                            : cryptData !== null
                                            ? cryptData?.phone
                                            : ele?.phone}
                                        </span>
                                      </p>
                                    )}
                                    {ele?.type !== "kid" && (
                                      <p>
                                        <label>Address</label>
                                        <span>
                                          {cryptData !== null
                                            ? cryptData?.address
                                              ? cryptData?.address
                                              : "NA"
                                            : ele?.address}
                                        </span>
                                      </p>
                                    )}
                                    {ele?.type !== "kid" && (
                                      <p>
                                        <label>Verified with Document</label>
                                        <span>
                                          {ele?.type == "kid"
                                            ? "NA"
                                            : cryptData !== null
                                            ? ele?.kyc_details
                                              ? ele?.kyc_details?.docType
                                              : ele.userId?.[0]?.kyc_details
                                                  ?.docType
                                            : "NA"}
                                        </span>
                                      </p>
                                    )}

                                    <p>
                                      {" "}
                                      <label>Document Number</label>
                                      <span>
                                        {" "}
                                        {ele?.type == "kid"
                                          ? "NA"
                                          : cryptData !== null
                                          ? cryptData?.docNumber
                                          : "NA"}
                                      </span>
                                    </p>

                                    <p>
                                      <label>Billing info</label>
                                      <span>
                                        <span>
                                          <Link
                                            class="Green"
                                            state={"guests-checkout-approve"}
                                            to={`/guests-billing-payment-view/${item?.guestId?.[0]?._id}`}
                                          >
                                            View
                                          </Link>
                                        </span>
                                      </span>
                                    </p>
                                  </aside>
                                </div>
                              </figcaption>
                            </div>
                          );}
                        ) }
                      </div>
                    </div>
                  </div>
                ))}

{loader ? (
                ""
              ) : approveCheckoutList?.[0]?.metadata?.[0]?.total > 0 ? (
                ""
              ) : (
                <NoDataFound />
              )}

              </div>
               
            {approveCheckoutList?.[0]?.metadata?.[0]?.total > 0 ? (
              <div className="Pagination">
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={approveCheckoutList?.[0]?.metadata?.[0]?.total}
                  pageRangeDisplayed={5}
                  onChange={(e) => handlePageChange(e)}
                />
              </div>
            ) : (
              ""
            )}
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default GuestsCheckoutApprove