import { couponConstant } from "../actionTypes";
const initialState = {
    couponList: [],
    couponUsageList:[],
    loader: false,
    error: {},
  };
  
  export default function couponReducer(
    state = initialState,
    { type, payload }
    
  ) {
    switch (type) {

      case couponConstant.COUPON_LIST_INITIATE:
        return { ...state, loader: true, couponList: [] };
  
      case couponConstant.COUPON_LIST_SUCCESS:
        return { ...state, loader: false, couponList: payload.data };
  
      case couponConstant.COUPON_LIST_FAILURE:
        return { ...state, loader: false, error: payload };



        case couponConstant.COUPON_USEAGE_INITIATE:
          return { ...state, loader: true, couponUsageList: [] };
    
        case couponConstant.COUPON_USEAGE_SUCCESS:
          return { ...state, loader: false, couponUsageList: payload.data };
    
        case couponConstant.COUPON_USEAGE_FAILURE:
          return { ...state, loader: false, error: payload };
  




  
  
      default:
        return state;
    }
  }
  