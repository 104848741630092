

import { financeConstant } from "../actionTypes";

import axios from "../../axiosInstance"
import { baseUrl } from "../../config/config";
import { isLoggedIn } from "../../utils";



// FINANCE LIST

export const financeListInitiate=() =>({
    type:financeConstant.FINANCE_LIST_INITIATE
  
  })
  
  export const financeListSuccess=(data) =>({
    type:financeConstant.FINANCE_LIST_SUCCESS,
    payload:data
  
  })
  
  export const financeListFailure=(data) =>({
    type:financeConstant.FINANCE_LIST_FAILURE,
    payload : data
  
  })
  
  
  export const  FinanceListAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(financeListInitiate());
        const response = await axios.get(`${baseUrl}/admin/finaceList`, {
          headers: {Authorization:`${token}`},params: payload})
  
  
        if (response.data.success === true) {
          dispatch(financeListSuccess(response.data));
        } else {
          dispatch(financeListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(financeListFailure(err));
        return err;
      }
  
  
  }
  
  
  

  // ANALYTICS LIST

export const analyticListInitiate=() =>({
  type:financeConstant.ANALYTIC_LIST_INITIATE

})

export const analyticListSuccess=(data) =>({
  type:financeConstant.ANALYTIC_LIST_SUCCESS,
  payload:data

})

export const analyticListFailure=(data) =>({
  type:financeConstant.ANALYTIC_LIST_FAILURE,
  payload : data

})


export const  AnalyticListAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(analyticListInitiate());
      const response = await axios.get(`${baseUrl}/admin/analyticList`, {
        headers: {Authorization:`${token}`},params: payload})


      if (response.data.success === true) {
        dispatch(analyticListSuccess(response.data));
      } else {
        dispatch(analyticListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(analyticListFailure(err));
      return err;
    }


}



  // HOTEL  COUPON USAGE 

  export const couponUsageInitiate=() =>({
    type:financeConstant.HOTEL_ALL_COUPON_USAGE_INITIATE
  
  })
  
  export const couponUsageSuccess=(data) =>({
    type:financeConstant.HOTEL_ALL_COUPON_USAGE_SUCCESS,
    payload:data
  
  })
  
  export const couponUsageFailure=(data) =>({
    type:financeConstant.HOTEL_ALL_COUPON_USAGE_FAILURE,
    payload : data
  
  })
  
  
  export const  HotelCouponUsageAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(couponUsageInitiate());
        const response = await axios.get(`${baseUrl}/admin/finance/all/coupon/usage`, {
          headers: {Authorization:`${token}`},params: payload})
  
  
        if (response.data.success === true) {
          dispatch(couponUsageSuccess(response.data));
        } else {
          dispatch(couponUsageFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(couponUsageFailure(err));
        return err;
      }
  
  
  }
  
  

