import React, { useEffect } from 'react'
import Header from './header'
import Sidenav from './sidenav'
import { Link, useLocation, useParams } from 'react-router-dom'
import moment from 'moment'
import ImageFetcher from './ImageFetcher'
import { useDispatch, useSelector } from 'react-redux';
import { TransactionDetailsAction } from '../redux/actions/hotelAction';

const HotelTransactionDetails = () => {
    const {state} = useLocation()
    const {id} =useParams()

    const {transactionDetails} = useSelector((state)=>state.hotelReducer)


    const dispatch = useDispatch()


    useEffect(()=>{
        dispatch(TransactionDetailsAction({_id:state?._id}));

    },[])





  return (
    <div>
        <Header />
        <Sidenav />
        <div class="WrapperArea">
        <div class="WrapperBox">
          <div class="NewTitleBox">
            <div className="arrow-left">
              <Link to={`/hotel-transaction/${id}`}>
                <i class="fa fa-arrow-left arrow-back" aria-hidden="true"></i>
              </Link>

              <h4> Transaction Details</h4>
            </div>
          </div>
          {/* <div class="GuestUserBox">
            <figure>
                    <img src="images/Avatar-2.png" />
                </figure>
            <figcaption>
              <div class="GuestUserHead">
                <aside>
                  <p>
                    <label>Room Number </label>
                    <span class="Green">
                      {billingDetails?.[0]?.rooms
                        ?.concat(billingDetails?.[0]?.pastRooms)
                        ?.map(
                          (ele) =>
                            `${
                              ele?.fullNumber !== undefined
                                ? ele?.fullNumber
                                : ""
                            } `
                        )}
                    </span>
                  </p>
                  <p>
                                    <label>Total Spend on stay bar</label>
                                    <span>3000 INR</span>
                                </p>
                  <p>
                    <label>Total Paid</label>
                    <span>
                      {billingDetails?.[0]?.paidAmount?.toFixed(2)} INR
                    </span>
                  </p>
                </aside>
                <aside>
                  <p>
                    <label>Pending Amount</label>
                    <span class="Red">
                      <strong>
                        {billingDetails?.[0]?.pendingAmount?.toFixed(2)} INR
                      </strong>
                    </span>
                  </p>
                  <p>
                    <label>Check In</label>
                    <span>
                      {moment(billingDetails?.[0]?.checkInDate).format("ll")}
                    </span>
                  </p>
                  <p>
                    <label>Checkout</label>
                    <span>
                      {billingDetails?.[0]?.extendCheckOut?.rooms?.length > 0
                        ? moment(state?.extendCheckOut?.newCheckOutDate).format(
                            "ll"
                          )
                        : moment(billingDetails?.[0]?.checkOutDate).format(
                            "ll"
                          )}
                    </span>
                  </p>
                </aside>
              </div>
            </figcaption>
          </div> */}
        
          <div class="Small-Wrapper">
            <div class="NewTitleBox">
              <h4> Order Details</h4>
            </div>
            {/* 
                <div class="Filter">  
                    
                    <div class="form-group">
                        <label>Filter category</label>
                        <select class="form-control">
                            <option value="select">--Select--</option>
                            <option value="Month">All</option>
                        </select>
                    </div>

                    <div class="form-group">
                        <label>Room Number</label>
                        <select class="form-control">
                            <option value="select">--Select--</option>
                            <option value="Month">RM103</option>
                        </select>
                    </div>

                    <div class="form-group">
                        <label>Status</label>
                        <select class="form-control">
                            <option value="select">--Select--</option>
                            <option value="Month">Paid</option>
                        </select>
                    </div>

                    <div class="form-group">
                        <label>&nbsp;</label>
                        <button class="Button">Apply</button>
                        <button class="Button Cancel"><i class="fa fa-refresh"></i></button>
                    </div>
                </div>  */}

            <div class="guest-menuList">
              <div class="MenuList">
                <ul>
                  {transactionDetails?.map((item) => (
                    <li>
                      <div class="MenuLeft">
                        {/* <figure>
                                     <img src="images/facial.png" />
                                 </figure> */}
                        <figcaption>
                        <p>
                            {item?.billId}
                          </p>
                          <h3>
                            {item?.itemType == "foodandbeverage"
                              ? "Food & Beverage"
                              : item?.itemType == "spa"
                              ? "Spa"
                              : item?.itemType == "minibar"
                              ? "Minibar"
                              : "Coupon"}
                          </h3>
                          <p>
                            {/* Ordered on App for Room {item?.room?.fullNumber} */}
                          </p>
                          <h4>
                            {" "}
                            {item?.items?.map((ele) => {
                              return `${
                                ele?.qty ? ele?.qty : ele?.quantity
                              } * ${
                                ele?.item?.name ? ele?.item?.name : ele?.name
                              }`;
                            })}
                          </h4>
                          <h4>
                            <span>{moment(item?.createdAt).format("lll")}</span>
                          </h4>
                        </figcaption>
                      </div>
                      <div class="MenuRight">
                        <h5>{item?.totalAmount?.toFixed(2)} INR</h5>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      
    </div>
        

    </div>
  )
}

export default HotelTransactionDetails