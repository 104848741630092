import React, { useEffect, useState } from "react";
import Header from "./header";
import Sidenav from "./sidenav";
import axiosInstance from '../axiosInstance';
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import {
  AddActivitiesAction,
  ActivitiesListAction,
  EditActivitiesAction,
  RemoveActivitiesAction,
} from "../redux/actions/activitiesAction";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loader from "./loader";
import ImageFetcher from './ImageFetcher'

const inititalState = {
  image: "",
  name: "",
  errorMsg: {},
};

const HotelActivities = () => {
  const [iState, updateState] = useState(inititalState);
  const [activitiesId, setActivitiesId] = useState("");
  const [search, setSearch]= useState('')
  const hotelDetails =  JSON.parse(window.localStorage.getItem("hotelDetails"))


  const [Type, setType] = useState("add");
  const {id} = useParams()

  const { name, image, errorMsg } = iState;
  const dispatch = useDispatch();

  const { activitiesList,loader } = useSelector((state) => state.activitiesReducer);

  const [AddActivitiesModal, setAddActivities] = useState(false);
  const handleAddActivitiesModalClose = () => {setAddActivities(false)
    updateState({  image: "",
    name: "",
    errorMsg: {},
  })
  }
  const handleAddActivitiesModalShow = (type, item) => {
    if (type == "edit") {
      setActivitiesId(item?._id);
      setType(type);

      updateState({ ...iState, name: item?.name, image: item?.image });
      setAddActivities(true);
    } else {
      setAddActivities(true);
      setType(type);

    }
  };

  const [DeleteModal, setDelete] = useState(false);
  const handleDeleteModalClose = () => setDelete(false);
  const handleDeleteModalShow = (id) => {
    setActivitiesId(id);
    setDelete(true);
  };

  window.Buffer = window.Buffer || require("buffer").Buffer;

  const handleUpload = (e) => {
    const file = e.target.files[0];

    if (file.type == "application/pdf") {
      toast.error("Only jpeg, jpg and png are allowed ", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    if (file) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        //Initiate the JavaScript Image object.
        var image = new Image();

        //Set the Base64 string return from FileReader as source.
        image.src = e.target.result;

        //Validate the File Height and Width.

        image.onload = function () {
          var height = this.height;
          var width = this.width;
          if (
            file.type === "image/jpeg" ||
            file.type === "image/jpg" ||
            file.type === "image/png"
          ) {
            const formData = new FormData();
            formData.append('file', file);
  
            let accessToken = JSON.parse(localStorage.getItem("hotelDetails")).jwtToken;
            axiosInstance.post('/upload?type=hotel', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Authorization'.concat(" ",accessToken),
              }
            })
            .then(response => {
              console.log(response);
            
              if (response.status !== 200) {
                throw new Error(`Server error: ${response.statusText}`);
              }
            
              return response;
            })
            .then(data => {
              if (data.error) {
                toast.error(data.error, { position: toast.POSITION.TOP_RIGHT });
              } else {
                const tempData = data.data.url;
                updateState({ ...iState, image: tempData });
              toast.success("Upload successfully", { position: toast.POSITION.TOP_RIGHT });
              }
            })
            .catch(error => {
              console.error(error);
              toast.error("Upload failed: " + error.message, { position: toast.POSITION.TOP_RIGHT });
            });
            } else {
            toast.error("Only jpeg, jpg and png are allowed ", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        };
      };
    }
  };

  const handleValidate = () => {
    let formIsValid = true;
    let errorMsg = {};
    if (!name) {
      formIsValid = false;
      errorMsg.name = "* Please enter activities name.";
    }
    if (!image) {
      formIsValid = false;
      errorMsg.image = "* Please upload activities image.";
    }

    updateState({ ...iState, errorMsg: errorMsg });

    return formIsValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    updateState({ ...iState, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formIsValid = handleValidate();

    if (formIsValid) {
      let data = {
        image,
        name,
        hotelId :id
      };

      dispatch(AddActivitiesAction(data))
        .then((res) => {
          if (res.status==200) {
            setAddActivities(false);
            dispatch(ActivitiesListAction({hotelId :id}));
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            updateState({ name: "", image: "", errorMsg: {} });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          alert(err);
        });
    }
  };

  const handlEdit = (e) => {
    e.preventDefault();

    let formIsValid = handleValidate();

    if (formIsValid) {
      let data = {
        _id: activitiesId,
        image,
        name,
        hotelId :id
      };

      dispatch(EditActivitiesAction(data))
        .then((res) => {
          if (res.data.success === true) {
            setAddActivities(false);
            dispatch(ActivitiesListAction({hotelId : id}));

            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            updateState({ name: "", image: "", errorMsg: {} });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          alert(err);
        });
    }
  };

  const handleDelete = () => {
    dispatch(RemoveActivitiesAction({ id: activitiesId , hotelId:id})).then((res) => {
      if (res.status === 200) {
        dispatch(ActivitiesListAction({hotelId :id}));
        setDelete(false);
      }
    });
  };

  useEffect(() => {
    dispatch(ActivitiesListAction({hotelId :id}));
  }, []);


  const handleStatusChange=(e)=>{
    dispatch(EditActivitiesAction({_id: e.target.value, visibility:e.target.checked, hotelId:id}))
    .then((res) => {
        if (res.data.success === true) {
          dispatch(ActivitiesListAction({hotelId :id}));

          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        alert(err);
      });
  }


    
  
  const handleSearch=(e)=>{
    dispatch(ActivitiesListAction({search: e.target.value , hotelId:id}));
    setSearch(e.target.value)


  }

  window.scrollTo(0, 0)

  return (
    <>
      <Header />
      <Sidenav />

      <div class="WrapperArea">
        <div class="WrapperBox">
        <div class="HotelLocation HotelLocation2">
            <div class="back-btn">
              <Link to={`/hotel-details/${id}`}>
                <i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back{" "}
              </Link>
            </div>

            <aside>
              <h3>{hotelDetails?.name}</h3>
              <p>
                <span>
                  <i class="fa fa-map-marker"></i>
                </span>{" "}
                {hotelDetails?.address}
              </p>
            </aside>
          </div>{" "}
          <div class="Small-Wrapper">
            <div class="NewTitleBox">
              <h4>Activities</h4>
              <a onClick={()=>handleAddActivitiesModalShow("add")}>Add Activities</a>
            </div>

            <div class="Filter">
              <div class="form-group">
                <label>Search</label>
                <input type="text" value={search} onChange={handleSearch} class="form-control" placeholder="Search by activity name" />
              </div>
            </div>

            {loader? <Loader />:

            <div class="AmenitiesArea">
              <ul>
                {activitiesList?.map((item) => (
                  <li>
                    <div class="AmenitiesBox">
                      <aside>
                        <label class="switch">
                          <input type="checkbox" onChange={handleStatusChange} value={item?._id} checked={item?.visibility} />
                          <span class="slider"></span>
                        </label>
                        <a
                          class="Green"
                          onClick={() =>
                            handleAddActivitiesModalShow("edit",item)
                          }
                        >
                          <i class="fa fa-pencil"></i>
                        </a>
                        <a
                          class="Red"
                          onClick={() => handleDeleteModalShow(item?._id)}
                        >
                          <i class="fa fa-trash"></i>
                        </a>
                      </aside>
                      <figure>
                      <ImageFetcher imageUrl={item?.image}/>
                      </figure>
                      <h6>{item?.name}</h6>
                    </div>
                  </li>
                ))}
              </ul>
            </div>}
          </div>
        </div>
      </div>
      <Modal
        show={AddActivitiesModal}
        onHide={handleAddActivitiesModalClose}
        className="ModalBox "
      >
        <Modal.Body>
          <div class="Category">
            <a onClick={handleAddActivitiesModalClose} class="CloseModal">
              ×
            </a>
            {Type == "add" ? (
                          <h3>Add Activities</h3>): <h3>Update Activities</h3>}


            <div class="form-group">
              <label>Activities Image</label>
              <div class="UploadBox">
                <div class="Upload">
                  <i class="fa fa-upload"></i> <span>Upload Icon</span>
                  <input
                    type="file"
                    onClick={(e) => (e.target.value = null)}
                    onChange={(e) => handleUpload(e)}
                    class="form-control"
                  />
                </div>
                {image &&  <div className="UploadIcon">
                  <ImageFetcher imageUrl={image}/>
                </div>
 }
              </div>

              {errorMsg.image && !image && (
                <p style={{ color: "red" }}>{errorMsg.image}</p>
              )}
            </div>
            <div class="form-group">
              <label>Activity Name</label>
              <input
                type="text"
                class="form-control"
                placeholder="Activity Name "
                value={name}
                name="name"
                onChange={handleChange}
              />
              {errorMsg.name && !name && <p style={{ color: "red" }}>{errorMsg.name}</p>}
            </div>
            {Type == "add" ? (
              <button class="Button" onClick={handleSubmit}>
                Add
              </button>
            ) : (
              <button class="Button" onClick={handlEdit}>
                Update
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={DeleteModal}
        onHide={handleDeleteModalClose}
        className="ModalBox "
      >
        <Modal.Body>
          <div className="Decline">
            <button className="CloseModal" onClick={handleDeleteModalClose}>
              ×
            </button>
            <h3>Delete</h3>
            <p>Are you sure you want to delete this Activities ?</p>
            <h4>
              <a onClick={handleDeleteModalClose} className="Cancel">
                Cancel
              </a>
              <a onClick={handleDelete}>Delete</a>
            </h4>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default HotelActivities;
