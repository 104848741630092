import React, { useEffect } from 'react';
import Sidenav from "./sidenav";
import Header from './header'
import { GetAboutUsAction,UpdateAboutUsAction,GetPrivacyPolicyAction,UpdatePrivacyPolicyAction,GetTermsConditionAction,UpdateTermsConditionAction } from '../redux/actions/contentAction';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const ContentManagement = () => {
    const dispatch = useDispatch()
    const {aboutusContent,privacyContent,termsContent} = useSelector((state)=>state.contentReducer)


useEffect(()=>{
    dispatch(GetAboutUsAction())
    dispatch(GetPrivacyPolicyAction())
    dispatch(GetTermsConditionAction())

},[])


console.log(termsContent)
  return (
    <div>
        <Header />
        <Sidenav />
    <div class="WrapperArea">
        <div class="WrapperBox">
            <div class="TitleBox">
                <h4 class="Title">Static Content Management</h4>
            </div>

            <div class="Small-Wrapper">
                <div class="StaticBoxArea">
                    <div class="panel-group" id="accordion">
                        <div class="panel">
                            <div class="panel-heading" data-toggle="collapse" data-parent="#accordion" href="#collapse2">
                                <h4>About Us</h4>
                            </div>
                            <div id="collapse2" class="panel-collapse collapse">
                                <div class="panel-body">
                                    <div class="TitleBox">
                                        <h3>About Us</h3>
                                        <Link to="/edit-aboutus" state={aboutusContent} class="Blue">
                                            <i class="fa fa-pencil"></i>
                                        </Link>
                                    </div>

                                    <div
                                  dangerouslySetInnerHTML={{
                                    __html: aboutusContent?.data,
                                  }}
                                  className="editor"
                                ></div>
                                </div>
                            </div>
                        </div>

                        <div class="panel">
                            <div class="panel-heading" data-toggle="collapse" data-parent="#accordion" href="#collapse3">
                                <h4>Terms &amp; Conditions</h4>
                            </div>
                            <div id="collapse3" class="panel-collapse collapse">
                                <div class="panel-body">
                                    <div class="TitleBox">
                                        <h3>Terms and conditions</h3>
                                        <Link to="/edit-termscondition" state={termsContent} class="Blue">
                                            <i class="fa fa-pencil"></i>
                                        </Link>
                                    </div>

                                    <div
                                  dangerouslySetInnerHTML={{
                                    __html: termsContent?.data,
                                  }}
                                  className="editor"
                                ></div>

                                </div>
                            </div>
                        </div>
                        <div class="panel">
                            <div class="panel-heading" data-toggle="collapse" data-parent="#accordion" href="#collapse4">
                                <h4>Privacy Policies</h4>
                            </div>
                            <div id="collapse4" class="panel-collapse collapse">
                                <div class="panel-body">
                                    <div class="TitleBox">
                                        <h3>Privacy Policy</h3>
                                        <Link to="/edit-policy" state={privacyContent} class="Blue">
                                            <i class="fa fa-pencil"></i>
                                        </Link>
                                    </div>

                                    <div
                                  dangerouslySetInnerHTML={{
                                    __html: privacyContent?.data,
                                  }}
                                  className="editor"
                                ></div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div></div>
  )
}

export default ContentManagement