import React, { useEffect } from 'react'
import Header from './header'
import Sidenav from './sidenav';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {ExtendCheckApproveRequestAction} from '../redux/actions/guestAction';
import commonCrypto from '../utils';
import ImageFetcher from './ImageFetcher'
const GuestsApproveExtendedCheckout = () => {
  const hotelDetails =  JSON.parse(window.localStorage.getItem("hotelDetails"))
  

    const {id} = useParams()
    const dispatch = useDispatch()
    const {extendApproveCheckinList} = useSelector((state)=>state.guestReducer)

    useEffect(()=>{

        dispatch(ExtendCheckApproveRequestAction({hotelId:id}))

    },[])



  return (
    <div>
        <Header />
        <Sidenav />
        <div class="WrapperArea">
        <div class="WrapperBox">
        <div class="HotelLocation HotelLocation2">
            <div class="back-btn">
            <Link to={`/guests-management/${id}`}>
                <i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back{" "}
              </Link>
            </div>
            
            <aside>
              <h3>{hotelDetails?.name}</h3>
              <p>
                <span>
                  <i class="fa fa-map-marker"></i>
                </span>{" "}
                {hotelDetails?.address}
              </p>
            </aside>
</div>

            
            <div class="Small-Wrapper">
            <div class="NewTitleBox">
              <div className="arrow-left">
                {/* <Link to={`/guests-management/${id}`}>
                  <i class="fa fa-arrow-left arrow-back" aria-hidden="true"></i>
                </Link>
 */}
                <h4>Extended Approve Checkout Requests</h4> 
              </div>
            </div>


                <div class="Filter">  
                    <div class="form-group">
                        <label>Search</label>
                        <input type="text" class="form-control" placeholder="Search.." />
                    </div>
                    {/* <div class="form-group">
                        <label>Room Number</label>
                        <select class="form-control">
                            <option>Select Room Number</option>
                            <option>RM103</option>
                        </select>
                    </div>    */}
                </div>
                <div class="GuestAccordian">
              <div id="accordion">
              {extendApproveCheckinList?.[0]?.data?.map((item, index) => {
                    const extendCheckOutDate = moment(
                      item?.newCheckOutDate
                    ).format("ll");
                    const CheckOutDate = moment(item?.oldCheckOutDate).format(
                      "ll"
                    );

                    return (
                      <div class="card">
                        <div
                          class="card-header collapsed"
                          data-toggle="collapse"
                          href={`#collapse${index}`}
                          aria-expanded="false"
                        >
                          <h5>
                            Rooms :{" "}
                            {item?.room?.map((ele) => (
                              <strong>{ele?.fullNumber} </strong>
                            ))}{" "}
                          </h5>
                          <h6>
                            {moment(item?.checkInDate).format("ll")}{" "}
                            <span>
                              (Checkout-{" "}
                              {moment(item?.newCheckOutDate).format("ll")})
                            </span>{" "}
                          </h6>
                        </div>

                        <div
                          id={`collapse${index}`}
                          class="collapse"
                          data-parent="#accordion"
                        >
                          <div class="card-body" style={{ borderTop: "none" }}>
                            {item?.guestId?.[0]?.co_occupants?.map((ele, i) => {
                              let GuestId = item?.guestId?.[0]?.guestId;

                              let cryptData = commonCrypto.decrypt(
                                ele.userId?.[0]?.kyc_details?.docData
                              );

                              return (
                                <div class="GuestUserBox">
                                  <figure>
                                  <ImageFetcher imageUrl={cryptData !== null ? cryptData?.kycSelfie : ele?.kycSelfie}/>
                                  {" "}
                                  </figure>
                                  <figcaption>
                                    <div class="GuestUserHead">
                                      <aside>
                                        <p>
                                          <label>Name </label>
                                          <span>
                                            <span>
                                              {cryptData !== null
                                                ? cryptData?.name
                                                : ele?.name}
                                            </span>
                                          </span>
                                        </p>
                                        <p>
                                          <label>Guest ID</label>
                                          <span>{GuestId}</span>
                                        </p>
                                        {ele?.userId &&
                                          ele?.userId?.length > 0 && (
                                            <p>
                                              <label>Gender</label>
                                              <span>
                                                {cryptData !== null
                                                  ? cryptData?.gender
                                                      ?.charAt(0)
                                                      ?.toUpperCase() +
                                                    cryptData?.gender?.slice(1)
                                                  : ele?.gender
                                                      ?.charAt(0)
                                                      ?.toUpperCase() +
                                                    ele?.gender?.slice(1)}
                                              </span>
                                            </p>
                                          )}
                                        <p>
                                          <label>Date of Birth</label>
                                          <span>
                                            {cryptData !== null
                                              ? cryptData?.dob
                                                ? moment(cryptData?.dob).format(
                                                    "ll"
                                                  )
                                                : "NA"
                                              : moment(ele?.dob).format("ll")}
                                          </span>
                                        </p>
                                        <p>
                                          <label>Age</label>
                                          <span>
                                            {cryptData !== null
                                              ? cryptData?.age
                                                ? cryptData?.age
                                                : "NA"
                                              : ele?.age
                                              ? ele?.age
                                              : "NA"}
                                          </span>
                                        </p>
                                        {ele?.type == "adult" &&
                                      cryptData?.docFrontImg && (
                                        <div class="card-guest">
                                          <span>
                                          <ImageFetcher imageUrl={cryptData?.docFrontImg}/>
                                          </span>
                                          <span>
                                          <ImageFetcher imageUrl={cryptData?.docFrontImg}/>
                                          </span>
                                        </div>
                                      )}
                                      </aside>
                                      <aside>
                                        {ele?.type !== "kid" && (
                                          <p>
                                            <label>Email</label>
                                            {cryptData !== null
                                              ? cryptData?.email == "" ||
                                                cryptData?.email == "false"
                                                ? "NA"
                                                : cryptData?.email
                                              : ele?.email == "" ||
                                                ele?.email == "false"
                                              ? "NA"
                                              : ele?.email}{" "}
                                          </p>
                                        )}
                                        <p>
                                          <label>Phone Number</label>
                                          <span>
                                            {ele?.type == "kid"
                                              ? "NA"
                                              : cryptData !== null
                                              ? cryptData?.phone
                                              : ele?.phone}
                                          </span>
                                        </p>
                                        {ele?.type !== "kid" && (
                                          <p>
                                            <label>Address</label>
                                            <span>
                                              {cryptData !== null
                                                ? cryptData?.address
                                                  ? cryptData?.address
                                                  : "NA"
                                                : ele?.address
                                                ? ele?.address
                                                : "NA"}
                                            </span>
                                          </p>
                                        )}
                                        <p>
                                          <label>Verified with Document</label>
                                          <span>
                                            {ele?.type == "kid"
                                              ? "NA"
                                              : cryptData !== null
                                              ? item?.kyc_details
                                                ? ele?.kyc_details?.docType
                                                : ele.userId?.[0]?.kyc_details
                                                    ?.docType
                                              : "NA"}
                                          </span>
                                        </p>
                                        <p>
                                          <label>Document Number</label>
                                          <span>
                                            {" "}
                                            {ele?.type == "kid"
                                              ? "NA"
                                              : cryptData !== null
                                              ? cryptData?.docNumber
                                              : "NA"}
                                          </span>
                                        </p>
                                      </aside>
                                    </div>
                                  </figcaption>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            </div>
        </div> 
    </div><div class="ModalBox">
        <div id="ApproveModal" class="modal fade" aria-hidden="true" style={{display: "none"}}>
            <div class="modal-dialog">   
                <div class="modal-content"> 
                    <div class="modal-body"> 
                        <div class="Category">
                            <a href="javascript:void(0);" class="CloseModal" data-dismiss="modal">×</a>
                            <h3>Extended Stay</h3>
                            <div class="form-group">  
                                <label>Specify the price for extended Stay</label>
                                <input type="text" class="form-control" />
                            </div> 
                            <button class="Button" data-toggle="modal" data-target="#SuccessGuestModal" data-dismiss="modal">Approve Request</button> 
                        </div> 
                    </div> 
                </div>    
            </div>
        </div>
        <div id="SuccessGuestModal" class="modal fade" role="dialog">
            <div class="modal-dialog">   
                <div class="modal-content"> 
                    <div class="modal-body"> 
                        <div class="SuccessGuestBox"> 
                            <span><img src="images/partner4.png" /></span>
                            <h4>Check-In has been completed successfully
                            and a confirmation link sent on customer's
                            Mobile and email Address.</h4>
                            <a href="Guests-extended-checkout.html">Back to Guests Stay Management</a>
                        </div>
                    </div> 
                </div>    
            </div>
        </div>
    </div>
    </div>
  )
}

export default GuestsApproveExtendedCheckout