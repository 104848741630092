import { restaurantConstant } from "../actionTypes";
const initialState = {
    serviceList: {},
    orderRequests:[],
    availableStaffList:[],
    loader: false,
    error: {},
  };
  
  export default function restaurantReducer(
    state = initialState,
    { type, payload }
    
  ) {
    switch (type) {

      case restaurantConstant.SERVICE_CATEGORY_LIST_INITIATE:
        return { ...state, loader: true, serviceList: {} };
  
      case restaurantConstant.SERVICE_CATEGORY_LIST_SUCCESS:
        return { ...state, loader: false, serviceList: payload.data };
  
      case restaurantConstant.SERVICE_CATEGORY_LIST_FAILURE:
        return { ...state, loader: false, error: payload };



        case restaurantConstant.FOOD_ORDER_REQUEST_LIST_INITIATE:
        return { ...state, loader: true, orderRequests: [] };
  
      case restaurantConstant.FOOD_ORDER_REQUEST_LIST_SUCCESS:
        return { ...state, loader: false, orderRequests: payload.data };
  
      case restaurantConstant.FOOD_ORDER_REQUEST_LIST_FAILURE:
        return { ...state, loader: false, error: payload };


        case restaurantConstant.AVAILABLE_STAFF_INITIATE:
          return { ...state, loader: true, availableStaffList: [] };
    
        case restaurantConstant.AVAILABLE_STAFF_SUCCESS:
          return { ...state, loader: false, availableStaffList: payload.data };
    
        case restaurantConstant.AVAILABLE_STAFF_FAILURE:
          return { ...state, loader: false, error: payload };
  
  


  
      default:
        return state;
    }
  }
  