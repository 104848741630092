import React, { useEffect, useState } from "react";
import Sidenav from "./sidenav";
import Header from "./header";
import { useLocation, useNavigate,Link } from "react-router-dom";
import { toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { UpdateTermsConditionAction } from "../redux/actions/contentAction";
import { useDispatch } from "react-redux";

const initialState = {
  aboutusData: "",
  errorMsg: "",
};

const EditTermsCondition = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [iState, updateState] = useState(initialState);
  const { errorMsg, aboutusData } = iState;

  useEffect(() => {
    updateState({ ...iState, aboutusData: state?.data });
  }, []);

  const handleValidation = () => {
    let formIsValid = true;

    let errorMsg = "";
    if (!aboutusData) {
      errorMsg = "* Please enter text";
      formIsValid = false;
    }
    updateState({ ...iState, errorMsg: errorMsg });

    return formIsValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formIsValid = handleValidation();

    if (formIsValid) {
      var data = { title:"P&P", _id: state?._id, data: aboutusData };

      dispatch(UpdateTermsConditionAction(data))
        .then((res) => {
          if (res.status === 200) {
            navigate("/content-management");
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            updateState({
              ...iState,
              errorMsg: res.message,
              emailError: "",
              passwordError: "",
            });
          }
        })
        .catch((err) => {
          alert(err);
        });
    }
  };

  return (
    <div>
      <Header />

      <Sidenav />
      <div class="WrapperArea">
        <div class="WrapperBox">
          <div class="back-btn">
          <Link to="/content-management">
              <i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back
            </Link>
          </div>

          <div class="Small-Wrapper">
            <CKEditor
              id="editor1"
              className="form-control"
              editor={ClassicEditor}
              //data={overview} // add in state and use state key
              data={aboutusData} // add in state and use state key
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                console.log("Editor is ready to use!", editor);
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                // console.log(data)
                updateState({ ...iState, aboutusData: data });
              }}
            />

            {errorMsg && <p style={{ color: "red" }}>{errorMsg}</p>}

            <div class="small-btn saveBtn">
              <a class="TitleLink" onClick={handleSubmit}>
                Save
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditTermsCondition;
