import React from 'react'
import Logo  from "../assets/image/logo.png"

import { Link, useNavigate } from 'react-router-dom'
import {toast} from 'react-toastify'

import {LogoutUserAction} from '../redux/actions/authAction'
import { useDispatch } from 'react-redux'

const Sidenav = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const AdminData = JSON.parse(localStorage.getItem("admin"));


    let path = window.location.href.split("/");
    let pathLength = parseInt(path.length) - 1;
    let pathMatch = path[pathLength];
    let pathMatchFor2Id = path[pathLength - 1];
  

    const handleLogout=()=>{

        dispatch(LogoutUserAction({_id: AdminData?._id}))
        .then((res) => {
          if (res.status === 200) {
            localStorage.removeItem("admin");

            navigate('/')

          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });

          }
        })
        .catch((err) => {
          alert(err);
        });
    
    }
  return (
    <div>
        <div class="SidenavBar">
        <div class="Logo">
            <img src={Logo} />
        </div>
        <ul>
            <li className={pathMatch == "dashboard" ? "active" : ""}>
                <Link to="/dashboard">
                    <span><i class="fa fa-tachometer"></i></span> Dashboard
                </Link>
            </li>
         
            <li className={ pathMatch == "dashboard" || pathMatch == "guests-deletion-request" || pathMatch === "guests" || pathMatch === "guest-wallet-management" ||pathMatch == "service-package" || pathMatch == "finance-management" || pathMatch == "notification-management" || pathMatch == "Content-management"|| pathMatch == "settings" || pathMatch == "analytics" || pathMatch == "subscriptions" || pathMatch === "subscription-hotel" || pathMatch === "subscription-coupon" ? "" : "active"  }>
                <Link to="/hotel-management">
                    <span><i class="fa fa-user"></i></span> Partner Hotels Management
                </Link>
            </li>

            <li className={pathMatch == "guests" || pathMatch == "guests-deletion-request"  ? "active":"" }>
                <Link to="/guests">
                    <span><i class="fa fa-cubes"></i></span> Guests Management
                </Link>
            </li> 
            

            {/* <li className={pathMatch == "guest-wallet-management"  ? "active":"" }>
                <Link to="/guest-wallet-management">
                    <span><i class="fa fa-cubes"></i></span> Guests Wallet Management
                </Link>
            </li> */}


            <li className={pathMatch == "finance-management"||pathMatch == "analytics" ||pathMatch == "service-package" ? "active":"" }>
                <Link to="/finance-management">
                    <span><i class="fa fa-money"></i></span> Finance & Analytics
                </Link>
            </li>            

            <li className={pathMatch == "notification-management"  ? "active":"" }>
                <Link to="/notification-management">
                    <span><i class="fa fa-credit-card"></i></span> Push Notification Management
                </Link>
            </li>             
            
            <li className={pathMatch == "Content-management" ? "active" : ""}>
                <Link to="/Content-management">
                    <span><i class="fa fa-credit-card"></i></span> App Content Management
                </Link>
            </li>
            
            <li className={pathMatch == "subscriptions" || pathMatch === "subscription-hotel" || pathMatch === "subscription-coupon" ? "active" : ""}>
                <Link to="/subscriptions">
                    <span><i class="fa fa-rupee"></i></span> Subscription
                </Link>
            </li>

            <li className={pathMatch == "settings" ? "active" : ""}>
            <Link to="/settings">
                    <span><i class="fa fa-cog"></i></span> Settings
                </Link>
            </li>

            <li>
                <a onClick={handleLogout}>
                    <span><i class="fa fa-sign-out"></i></span> Logout
                </a>
            </li>

        </ul>
    </div>
    </div>
  )
}

export default Sidenav