import CryptoJS from "crypto-js";
import { CRYPTO_KEY } from "./config/config";
export function isLoggedIn(adminType) {
    let session = getObject(adminType) || {};
    session = Object?.keys(session).length && JSON.parse(session);
    let accessToken = session && session && session['jwtToken'] || "";
    return accessToken;
}

export function getObject(key) {
  //  console.log('keye', key,window.localStorage.getItem(key))
    if (window && window.localStorage) {
      return window.localStorage?.getItem(key);
    }
  }

  export function multiPartData(data){
    let multiPart = new FormData();
    for (let key in data){
      multiPart?.append(key,data[key])
    }
  
    return multiPart
  }



  export const minutesList=()=>{
    const items =['01','02', '03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36','37','38','39','40','41','42','43','45','46','47','48','49','50','51','53','54','55','56','57','58','58','59']

    return items
  }

  export const hoursList=()=>{
    const items =['01','02', '03','04','05','06','07','08','09','10','11','12']
    return items
  }







  const pbKey =CRYPTO_KEY;
  class Crypto {
    static instance;
    constructor() {
      this.pvKey = pbKey;
    }
  
    setPVKey(pvKey) {
      this.pvKey = pvKey;
    }
  
    encrypt(data) {
      if (typeof data !== "string") {
        data = JSON.stringify(data);
      }
  
      let eData = CryptoJS.AES.encrypt(
        CryptoJS.enc.Utf8.parse(data),
        // this.getPBKey(),
        // this.getDecrPrivateKey(this.getPBKey()),
        this.getPVKey(),
        {
          keySize: 128 / 8,
          iv: CryptoJS.enc.Utf8.parse(""),
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      );
  
      return eData.toString();
    }

  
    getPVKey() {
      return this.pvKey;
    }
  

  
    decrypt(encryptedData) {
      try {
        const bytes = CryptoJS.AES.decrypt(
          encryptedData,
          // this.getPBKey(),
          // this.getDecrPrivateKey(this.getPBKey()),
          this.getPVKey(),
  
          {
            keySize: 128 / 8,
            iv: CryptoJS.enc.Utf8.parse(""),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
          }
        );
  
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        return JSON.parse(decryptedData);
      } catch (error) {
        console.error("Decryption error:", error);
        return null;
      }
    }
  
    static getInstance() {
      if (!Crypto.instance) {
        Crypto.instance = new Crypto();
      }
  
      return Crypto.instance;
    }
  }
  
  const commonCrypto = Crypto.getInstance();
  
  export default commonCrypto;
  
  