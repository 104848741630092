import { foodBeverageConstant } from "../actionTypes";

import axios from "../../axiosInstance"
import { baseUrl } from "../../config/config";
import { isLoggedIn } from "../../utils";




// ADD FOOD_BEVERAGE

export const addFoodBeverageInitiate=() =>({
    type:foodBeverageConstant.ADD_FOOD_BEVERAGE_INITIATE

})

export const addFoodBeverageSuccess=(data) =>({
    type:foodBeverageConstant.ADD_FOOD_BEVERAGE_SUCCESS,
    payload:data

})

export const addFoodBeverageFailure=(data) =>({
    type:foodBeverageConstant.ADD_FOOD_BEVERAGE_FAILURE,
    payload : data

})


export const  AddFoodBeverageAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(addFoodBeverageInitiate());
        const response = await axios.post(`${baseUrl}/admin/foodandbeverage/create`, payload,{
            headers: {authorization:`${token}`}})

        if (response.data.success === true) {
          dispatch(addFoodBeverageSuccess(response.data));
        } else {
          dispatch(addFoodBeverageFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(addFoodBeverageFailure(err));
        return err;
      }


}


// EDIT FOOD_BEVERAGE

export const editFoodBeverageInitiate=() =>({
    type:foodBeverageConstant.UPDATE_FOOD_BEVERAGE_INITIATE

})

export const editFoodBeverageSuccess=(data) =>({
    type:foodBeverageConstant.UPDATE_FOOD_BEVERAGE_SUCCESS,
    payload:data

})

export const editFoodBeverageFailure=(data) =>({
    type:foodBeverageConstant.UPDATE_FOOD_BEVERAGE_FAILURE,
    payload : data

})


export const  EditFoodBeverageAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(editFoodBeverageInitiate());
        const response = await axios.put(`${baseUrl}/admin/foodandbeverage/update`, payload,{
            headers: {authorization:`${token}`}})

        if (response.data.sucess === true) {
          dispatch(editFoodBeverageSuccess(response.data));
        } else {
          dispatch(editFoodBeverageFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(editFoodBeverageFailure(err));
        return err;
      }


}



// REMOVE FOOD_BEVERAGE

export const removeFoodBeverageInitiate=() =>({
    type:foodBeverageConstant.REMOVE_FOOD_BEVERAGE_INITIATE

})

export const removeFoodBeverageSuccess=(data) =>({
    type:foodBeverageConstant.REMOVE_FOOD_BEVERAGE_SUCCESS,
    payload:data

})

export const removeFoodBeverageFailure=(data) =>({
    type:foodBeverageConstant.REMOVE_FOOD_BEVERAGE_FAILURE,
    payload : data

})


export const  RemoveFoodBeverageAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(removeFoodBeverageInitiate());
        const response = await axios.delete(`${baseUrl}/admin/foodandbeverage/remove`, {
          headers: {Authorization:`${token}`},params: payload})

        if (response.data.sucess === true) {
          dispatch(removeFoodBeverageSuccess(response.data));
        } else {
          dispatch(removeFoodBeverageFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(removeFoodBeverageFailure(err));
        return err;
      }


}



//  FOOD_BEVERAGE LIST

export const foodbeverageListInitiate=() =>({
    type:foodBeverageConstant.FOOD_BEVERAGE_LIST_INITIATE

})

export const foodbeverageListSuccess=(data) =>({
    type:foodBeverageConstant.FOOD_BEVERAGE_LIST_SUCCESS,
    payload:data

})

export const foodbeverageListFailure=(data) =>({
    type:foodBeverageConstant.FOOD_BEVERAGE_LIST_FAILURE,
    payload : data

})


export const  FoodBeverageListAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(foodbeverageListInitiate());
        const response = await axios.get(`${baseUrl}/admin/foodandbeverage/get`, {
          headers: {Authorization:`${token}`},params: payload})

          if (response.data.success === true) {
            dispatch(foodbeverageListSuccess(response.data));
        } else {
          dispatch(foodbeverageListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(foodbeverageListFailure(err));
        return err;
      }


}



//  FOOD_BEVERAGE CATEGORY LIST

export const foodbeverageCategoryListInitiate=() =>({
  type:foodBeverageConstant.FOOD_BEVERAGE_CATEGORY_LIST_INITIATE

})

export const foodbeverageCategoryListSuccess=(data) =>({
  type:foodBeverageConstant.FOOD_BEVERAGE_CATEGORY_LIST_SUCCESS,
  payload:data

})

export const foodbeverageCategoryListFailure=(data) =>({
  type:foodBeverageConstant.FOOD_BEVERAGE_CATEGORY_LIST_FAILURE,
  payload : data

})


export const  FoodBeverageCategoryListAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(foodbeverageCategoryListInitiate());
      const response = await axios.get(`${baseUrl}/admin/foodandbeverage/category/list`, {
        headers: {Authorization:`${token}`},params: payload})

        if (response.data.success === true) {
          dispatch(foodbeverageCategoryListSuccess(response.data));
      } else {
        dispatch(foodbeverageCategoryListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(foodbeverageCategoryListFailure(err));
      return err;
    }


}




