import React, { useState } from 'react'
import Logo  from "../assets/image/logo.png"
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {toast} from 'react-toastify'

import { TwoStepAction } from '../redux/actions/authAction';
import { useEffect } from 'react';


const Otp = () => {
    const [sendOtp, setSendOtp] =useState('')

    let timer

    // const [otpTimer, setOtpTimer] = useState(true);
    // const [otpExpired, setOtpExpired] = useState(false);


    const [otpExpireEmail, setOtpExpireEmail] = useState(false);
    const [otpExpireResend, setExpiredResend] = useState(false);

    
    const [otpState, setOtpState] = useState({
        dig1: "",
        dig2: "",
        dig3: "",
        dig4: "",
        dig5: "",
        dig6: "",
        optError:""
      });
      const dispatch = useDispatch()
      const {state} = useLocation()

      
    
    const navigate = useNavigate()


    let handleValidation = () => {
      let optError = "";

      let formIsValid = true;

      if (!otpState.dig6) {
          optError = "* Please enter otp.";
          formIsValid = false;
        } 


      
       
      

              setOtpState({
          ...otpState,optError

          });
          return formIsValid;
      };


    const handleSubmit=()=>{
        let formIsValid = handleValidation();


        var data={email:state?.email, otp:otpState.dig1 +
            otpState.dig2 +
            otpState.dig3 +
            otpState.dig4 +
            otpState.dig5 +
            otpState.dig6

            
    }

    if(formIsValid){

      if (sendOtp !== data.otp) {
        toast.error("Otp not match!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        if (!otpExpireEmail) {
          navigate('/dashboard')
          toast.success("Otp match successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          
        } else {
          toast.error("Otp expired !", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    } 





    }

    const inputfocus = (elmnt) => {
        if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
          const next = elmnt.target.tabIndex - 2;
    
          if (next > -1) {
    
            elmnt.target.form.elements[next].focus()
          }
        }
        else {
          // // console.log("next");
         
            const next = elmnt.target.tabIndex;
            if (next < 6) {
              elmnt.target.form.elements[next].focus()
            }
        }
    
      }

      const otpHandleChange = (e) => {
        const { name, value } = e.target;
        setOtpState({
          ...otpState,
          [name]: value,
        });
      };
    
    

      const handleSendOtp =(e)=>{
        e.preventDefault()


            dispatch(TwoStepAction({email: state?.email}))
            .then((res) => {
                console.log(res)
                if (res.status === 200) {

                  clearTimeout(timer);
                  setOtpExpireEmail(false);
                  setExpiredResend(false);
                  setSendOtp(res.data.data.otp);
                  runTimerResend();
                  runTimerEmail();
        
                    toast.success("OTP send successfully", {
                      position: toast.POSITION.TOP_RIGHT,
                    });
                    setOtpState({
                      dig1: "",
                      dig2: "",
                      dig3: "",
                      dig4: "",
                      dig5: "",
                      dig6: "",
                      optError: "",
                  
                    })
                  } else {
                    toast.error(res.message, {
                      position: toast.POSITION.TOP_RIGHT,
                    });

                  }
                 })
                 .catch((err) => {
                   alert(err);
                 });



       
    }

    useEffect(()=>{

        setSendOtp(state?.otp)
        runTimerEmail()

    },[])


  //   const protect_email= (user_email)=> {
  //     var avg, splitted, part1, part2;
  //     splitted = user_email?.split("@");
  //     part1 = splitted[0];
  //     avg = part1.length / 2;
  //     part1 = part1.substring(0, (part1.length - avg));
  //     part2 = splitted[1];
  //     return part1 + "XXXXX@" + part2;
  // };
  
  


  const runTimerEmail = () => {
    timer = window.setTimeout(() => {
      setOtpExpireEmail(true);
    }, 1000 * 15*60);
  };


  const runTimerResend = () => {
    timer = window.setTimeout(() => {
      setExpiredResend(true);
    }, 1000 * 1*45);
  };



  return (
    <div>
        
    <div class="LoginArea">
        <div class="LoginBox">
            <figure>
                <img src={Logo} alt="" />
            </figure>
            <form>
                <h3>Please Enter 6-digit OTP</h3>
                <p>Please enter 6 digit OTP Verification code recieved on your email address.</p>
                {/* <p>{protect_email(state.email)}</p> */}



                <div class="form-group">
                    <div class="OTPBox">

                    <input
                    value={otpState.dig1}
                    name="dig1"
                    onChange={otpHandleChange}
                    type="text"
                    autoFocus
                    class="form-control"
                    tabIndex="1" maxLength="1" onKeyUp={e =>inputfocus(e)}


                  />
                  <input
                    value={otpState.dig2}
                    name="dig2"
                    onChange={otpHandleChange}
                    type="text"
                    tabIndex="2" maxLength="1" onKeyUp={e =>inputfocus(e)}

                    class="form-control"
                  />
                  <input
                    value={otpState.dig3}
                    name="dig3"
                    onChange={otpHandleChange}
                    type="text"
                    class="form-control"
                    tabIndex="3" maxLength="1" onKeyUp={e =>inputfocus(e)}

                  />
                  <input
                    value={otpState.dig4}
                    name="dig4"
                    tabIndex="4" maxLength="1" onKeyUp={e =>inputfocus(e)}

                    onChange={otpHandleChange}
                    type="text"
                    class="form-control"
                  />
                  <input
                    value={otpState.dig5}
                    name="dig5"
                    onChange={otpHandleChange}
                    type="text"

                    tabIndex="5" maxLength="1" onKeyUp={e =>inputfocus(e)}

                    class="form-control"
                  />
                  <input
                    value={otpState.dig6}
                    name="dig6"

                    onChange={otpHandleChange}
                    type="text"
                    class="form-control"
                    tabIndex="6" maxLength="1" onKeyUp={e =>inputfocus(e)}

                  />

                     </div>
                     {otpState.optError && <p className="emptyField" style={{color:"red"}}>{otpState.optError}</p>}

                    <p class="text-right"><button disabled={otpExpireResend?false:true}  onClick={handleSendOtp}>Resend OTP</button></p>
                </div>

                <a onClick={handleSubmit} class="Button"> VERIFY OTP</a>
            </form>
        </div>
    </div>
    </div>
  )
}

export default Otp