import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Sidenav from "./sidenav";
import Header from "./header";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { BillingDetailsAction } from "../redux/actions/guestAction";
import moment from "moment";
import commonCrypto from "../utils";
import ImageFetcher from './ImageFetcher'

const GuestsBillingPaymentView = () => {
  const hotelDetails = JSON.parse(window.localStorage.getItem("hotelDetails"));

  const { state } = useLocation();
  const { id, hotelId } = useParams();
  const { billingDetails } = useSelector((state) => state.guestReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(BillingDetailsAction({ _id: id, hotelId: hotelId }));
  }, []);

  return (
    <div>
      <Header />
      <Sidenav />

      <div class="WrapperArea">
        <div class="WrapperBox">
          <div class="HotelLocation HotelLocation2">
            <div class="back-btn">
              <Link to={`/${state}/${hotelId}`}>
                <i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back{" "}
              </Link>
            </div>

            <aside>
              <h3>{hotelDetails?.name}</h3>
              <p>
                <span>
                  <i class="fa fa-map-marker"></i>
                </span>{" "}
                {hotelDetails?.address}
              </p>
            </aside>
          </div>
          <div class="NewTitleBox">
            <div className="arrow-left">
              {/* <Link to={`/${state}/${hotelId}`}>
                <i class="fa fa-arrow-left arrow-back" aria-hidden="true"></i>
              </Link> */}

              <h4>Billing &amp; Payments Details</h4>
            </div>
          </div>{" "}
          <div class="GuestUserBox">
            <figcaption>
              <div class="GuestUserHead">
                <aside>
                  <p>
                    <label>Room Number </label>
                    <span class="Green">
                      {billingDetails?.[0]?.rooms
                        ?.concat(billingDetails?.[0]?.pastRooms)
                        ?.map(
                          (ele) =>
                            `${
                              ele?.fullNumber !== undefined
                                ? ele?.fullNumber
                                : ""
                            } `
                        )}
                    </span>
                  </p>
                  {/* <p>
                                    <label>Total Spend on stay bar</label>
                                    <span>3000 INR</span>
                                </p> */}
                  <p>
                    <label>Total Paid</label>
                    <span>
                      {billingDetails?.[0]?.paidAmount?.toFixed(2)} INR
                    </span>
                  </p>
                </aside>
                <aside>
                  <p>
                    <label>Pending Amount</label>
                    <span class="Red">
                      <strong>
                        {billingDetails?.[0]?.pendingAmount?.toFixed(2)} INR
                      </strong>
                    </span>
                  </p>
                  <p>
                    <label>Check In</label>
                    <span>
                      {moment(billingDetails?.[0]?.checkInDate).format("ll")}
                    </span>
                  </p>
                  <p>
                    <label>Checkout</label>
                    <span>
                      {billingDetails?.[0]?.extendCheckOut?.rooms?.length > 0
                        ? moment(state?.extendCheckOut?.newCheckOutDate).format(
                            "ll"
                          )
                        : moment(billingDetails?.[0]?.checkOutDate).format(
                            "ll"
                          )}
                    </span>
                  </p>
                </aside>
              </div>
            </figcaption>
          </div>
          <div class="Small-Wrapper">
            <div class="NewTitleBox">
              <h4>Checked In Customers</h4>
            </div>

            {billingDetails?.[0]?.co_occupants?.map((item) => {
              let cryptData = commonCrypto.decrypt(
                item.userId?.[0]?.kyc_details?.docData
              );

              return (
                <div class="GuestUserBox">
                  <figure>
                  <ImageFetcher imageUrl={cryptData !== null ? cryptData?.kycSelfie : ""}/>
                  {" "}
                  </figure>
                  <figcaption>
                    <div class="GuestUserHead">
                      <aside>
                        <p>
                          <label>Name </label>
                          <span>
                            <span>
                              {cryptData !== null
                                ? cryptData?.name
                                : item?.name}
                            </span>
                          </span>
                        </p>
                        {/* <p>
                                                      <label>Guest ID</label>
                                                      <span>GUEST1234</span>
                                                  </p> */}
                        {item?.type !== "kid" && (
                          <p>
                            <label>Gender</label>
                            <span>
                              {cryptData !== null
                                ? cryptData?.gender?.charAt(0)?.toUpperCase() +
                                  cryptData?.gender?.slice(1)
                                : item?.gender?.charAt(0)?.toUpperCase() +
                                  item?.gender?.slice(1)}
                            </span>
                          </p>
                        )}
                        <p>
                          <label>Date of Birth</label>
                          <span>
                            {cryptData !== null
                              ? moment(cryptData?.dob).format("ll")
                              : "NA"}
                          </span>
                        </p>
                        <p>
                          <label>Age</label>
                          <span>
                            {cryptData !== null
                              ? `${cryptData?.age} Years`
                              : "NA"}
                          </span>
                        </p>
                        {cryptData?.docFrontImg && (
                          <p>
                            <label> Documents</label>
                            <span class="passport">
                            <ImageFetcher imageUrl={cryptData?.docFrontImg}/>
                            </span>
                            <span class="passport">
                            <ImageFetcher imageUrl={cryptData?.docFrontImg}/>
                            </span>
                          </p>
                        )}
                      </aside>
                      <aside>
                        {item?.type !== "kid" && (
                          <p>
                            <label>Email</label>
                            {cryptData !== null
                              ? cryptData?.email == "" ||
                                cryptData?.email == "false"
                                ? "NA"
                                : cryptData?.email
                              : item?.email == "" || item?.email == "false"
                              ? "NA"
                              : item?.email}{" "}
                          </p>
                        )}
                        {item?.type !== "kid" && (
                          <p>
                            <label>Phone Number</label>
                            <span>
                              {item?.type == "kid"
                                ? "NA"
                                : cryptData !== null
                                ? cryptData?.phone
                                : item?.phone}
                            </span>
                          </p>
                        )}
                        {item?.type !== "kid" && (
                          <p>
                            <label>Address</label>
                            <span>
                              {cryptData !== null
                                ? cryptData?.address
                                  ? cryptData?.address
                                  : "NA"
                                : item?.address}
                            </span>
                          </p>
                        )}
                        {item?.type !== "kid" && (
                          <p>
                            <label>Verified with Document</label>
                            <span>
                              {cryptData !== null
                                ? item.userId?.[0]?.kyc_details?.docType
                                : item?.kyc_details?.docType}
                            </span>
                          </p>
                        )}

                        {/* <p>
                                        <label>Document Number</label>
                                        <span>                      {item?.userId?.kyc_details?.docData?.docNumber}
                                        </span>
                                      </p> */}
                      </aside>
                    </div>
                  </figcaption>
                </div>
              );
            })}
          </div>
          <div class="Small-Wrapper">
            <div class="NewTitleBox">
              <h4>Billing &amp; Payment Details</h4>
            </div>
            {/* 
                <div class="Filter">  
                    
                    <div class="form-group">
                        <label>Filter category</label>
                        <select class="form-control">
                            <option value="select">--Select--</option>
                            <option value="Month">All</option>
                        </select>
                    </div>

                    <div class="form-group">
                        <label>Room Number</label>
                        <select class="form-control">
                            <option value="select">--Select--</option>
                            <option value="Month">RM103</option>
                        </select>
                    </div>

                    <div class="form-group">
                        <label>Status</label>
                        <select class="form-control">
                            <option value="select">--Select--</option>
                            <option value="Month">Paid</option>
                        </select>
                    </div>

                    <div class="form-group">
                        <label>&nbsp;</label>
                        <button class="Button">Apply</button>
                        <button class="Button Cancel"><i class="fa fa-refresh"></i></button>
                    </div>
                </div>  */}

            <div class="guest-menuList">
              <div class="MenuList">
              <ul>
                  {billingDetails?.[0]?.bills?.map((item) =>
                    item?.itemType == "extend_checkout" ? (
                      <li>
                        <div class="MenuLeft">
                          {/* <figure>
                                     <img src="images/facial.png" />
                                 </figure> */}
                          <figcaption>
                            <p>{item?.billId}</p>
                            <h3>Extend Checkout</h3>

                            <h4>
                              {" "}
                              {item?.extendRooms?.map((ele, i) => {
                                return `${ele?.fullNumber} , `;
                              })}
                            </h4>
                            <h4>
                              <span>
                                {moment(item?.createdAt).format("lll")}
                              </span>
                            </h4>
                          </figcaption>
                        </div>
                        <div class="MenuRight">
                          <h5>{item?.totalAmount?.toFixed(2)} INR</h5>
                        </div>
                      </li>
                    ) : (
                      <li>
                        <div class="MenuLeft">
                          <figcaption>
                            <p>{item?.billId}</p>
                            <h3>
                              {item?.itemType == "foodandbeverage"
                                ? "Food & Beverage"
                                : item?.itemType == "spa"
                                ? "Spa"
                                : item?.itemType == "minibar"
                                ? "Minibar"
                                : "Coupon"}
                            </h3>
                            <p>
                              Ordered on App for Room {item?.room?.fullNumber}
                            </p>
                            <h4>
                              {" "}
                              {item?.items?.map((ele) => {
                                return `${
                                  ele?.qty ? ele?.qty : ele?.quantity
                                } * ${
                                  ele?.item?.name ? ele?.item?.name : ele?.name
                                }`;
                              })}
                            </h4>
                            <h4>
                              <span>
                                {moment(item?.createdAt).format("lll")}
                              </span>
                            </h4>
                          </figcaption>
                        </div>
                        <div class="MenuRight">
                          <h5>{item?.totalAmount?.toFixed(2)} INR</h5>
                        </div>
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuestsBillingPaymentView;
