import React, { useState, useEffect } from "react";

import Header from "./header";
import Sidenav from "./sidenav";
import { toast } from "react-toastify";
import { SpaListAction } from "../redux/actions/spaAction";
import ImageFetcher from './ImageFetcher'
import { useDispatch, useSelector } from "react-redux";
import { CreateOrderAction } from "../redux/actions/restaurantAction";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import { OccupiedRoomAction } from "../redux/actions/dropdownAction";
import moment from "moment";
const initialState = {
  hotelId: "",
  serviceType: "spa",
  status: "scheduled",
  paymentStatus: "paylater",
  person: 1,
  itemCount: "",
  totalPrice: "",
  createdBy: "staff",
  errorMsg: {},
};
const SpaOrderAdd = () => {

  const {id} = useParams()

  const hotelDetails =  JSON.parse(window.localStorage.getItem("hotelDetails"))
  const dispatch = useDispatch();
  const[stopper, setStopper] =useState(false)
  const navigate = useNavigate();
  const { spaList } = useSelector((state) => state.spaReducer);
  const [iState, updateState] = useState(initialState);
  const [search, setSearch] = useState("");

  const { errorMsg, person } = iState;

  const [person_details, person_detailsSet] = useState(
    Array(+person).fill({
      date: "",
      time: "",
    })
  );

  const [room, setRoom] = useState("");
  const [options, setOptions] = useState([]);

  const {occupiedRoom} = useSelector((state)=>state.dropdownReducer)

  const [items, setItems] = useState([]);
  const [allFoodItems, setAllFoodItems] = useState([]);
  const [AddModal, setAddModel] = useState(false);
  const handleAddModalClose = () => {
    setAddModel(false);
  };

  const handleAddModalShow = () => {
    setAddModel(true);
  };

  let handleValidation = () => {
    let errorMsg = {};
    let formIsValid = true;

    if (person_details.some((item) => item.time) == "") {
      errorMsg.time = "* Please enter time";
    }
    if (person_details.some((item) => item.date) == "") {
      errorMsg.date = "* Please enter date";
    }

    if (!room) {
      errorMsg.room = "* Please select room";
      formIsValid = false;
    }
    updateState({
      ...iState,
      errorMsg: errorMsg,
    });
    return formIsValid;
  };

  const handleAddToCart = (ele, index) => {
    let tempArray = [...items];
    let tempAllItems = [...allFoodItems];
    var addIndex = 0;
    let AlreadyInCart = items?.some((product, i) => {
      if (product?.item?.name == ele?.name) {
        addIndex = i;
        return true;
      }
    });
    if (!AlreadyInCart) {
      // tempArray.push({ item: ele, qty: 1, totalPrice: ele?.price });
      setItems([{ item: ele, qty: 1, totalPrice: ele?.price }]);

      tempAllItems[index] = { ...tempAllItems[index], qty: 1 };

      setAllFoodItems(tempAllItems);

    } else {
      tempArray[addIndex] = {
        ...tempArray[addIndex],
        qty: parseInt(tempArray[addIndex].qty) + 1,
        totalPrice:
          parseInt(tempArray[addIndex].totalPrice) +
          parseInt(tempArray[addIndex].item.price),
      };
      setItems(tempArray);

      tempAllItems[index] = {
        ...tempAllItems[index],
        qty: parseInt(tempAllItems[index].qty) + 1,
      };

      setAllFoodItems(tempAllItems);
    }
  };

  const handleRemoveFromCart = (ele, index) => {
    let tempArray = [...items];
    let tempAllItems = [...allFoodItems];
    var removeIndex = 0;

    let AlreadyInCart = items?.some((product, i) => {
      if (product?.item?.name == ele?.name) {
        removeIndex = i;
        return true;
      }
    });
    if (!AlreadyInCart) {
      //     tempArray.splice(index, 1);

      //   setItems(tempArray)

      tempAllItems[index] = { ...tempAllItems[index], qty: 0 };

      setAllFoodItems(tempAllItems);
    } else {
      let removeItem = tempArray[removeIndex]?.qty == 1;

      //     tempArray.splice(index, 1);

      if (removeItem) {
        tempArray.splice(removeIndex, 1);
        setItems(tempArray);
      } else {
        tempArray[removeIndex] = {
          ...tempArray[removeIndex],
          qty: parseInt(tempArray[removeIndex].qty) - 1,
          totalPrice:
            parseInt(tempArray[removeIndex].totalPrice) -
            parseInt(tempArray[removeIndex].item.price),
        };
        setItems(tempArray);
      }

      tempAllItems[index] = {
        ...tempAllItems[index],
        qty: parseInt(tempAllItems[index].qty) - 1,
      };

      setAllFoodItems(tempAllItems);
    }
  };

  useEffect(() => {
    const hotelData = JSON.parse(localStorage.getItem("hotel"));

    dispatch(SpaListAction({hotelId:id}));
    dispatch(OccupiedRoomAction({hotelId:id}));

  }, []);

  useEffect(() => {
    if (spaList?.[0]?.data?.[0]?.items) {
      let tempArray = spaList?.[0]?.data?.[0]?.items;
      tempArray.forEach((object) => {
        object.qty = 0;
      });

      setAllFoodItems(tempArray);
    }
  }, [spaList]);

  useEffect(() => {
    if (occupiedRoom) {

      let tempArray = occupiedRoom;
      tempArray.map((object) => {
        object["label"] = object.fullNumber;
        object["value"] = object.fullNumber;
      });

      setOptions(tempArray);
    }
  }, [occupiedRoom]);
  const grandTotal = items?.reduce((accum, ele) => accum + ele?.totalPrice, 0);

  const itemCount = items?.reduce((accum, ele) => accum + ele?.qty, 0);

  const handleChange = (e) => {
    const { name, value } = e.target;

    updateState({
      ...iState,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formIsValid = handleValidation();
    setStopper(formIsValid)
    const hotelData = JSON.parse(localStorage.getItem("hotel"));

    if (formIsValid) {
      const tempItem = items.map((itemm,index)=>{
        return {
            item:itemm?.item,
            qty:person_details.length,
            person_details:person_details,
            totalPrice:  itemm.totalPrice * person
        }
    });

    

      let data = {
        hotelId: hotelData?.hotelId?.[0]?._id,
        serviceType: iState.serviceType,
        room: room?._id,
        paymentStatus: "paylater",
        itemCount: itemCount,
        amount : {
          "itemTotalAmount" :grandTotal,
          totalAmount:(
           parseInt( grandTotal) +
            (parseInt( spaList?.[0]?.data?.[0]?.tax?.percent) / 100) * parseInt(grandTotal)
          ).toFixed(2),
         "tax" : {
          "taxType" : spaList?.[0]?.data?.[0]?.tax?.taxType,
          "taxPercent" :spaList?.[0]?.data?.[0]?.tax?.percent,
        "taxAmount" : ((parseInt(spaList?.[0]?.data?.[0]?.tax?.percent) / 100) * parseInt(grandTotal)).toFixed(2),
      }},


        totalPrice: (
          grandTotal +
          (spaList?.[0]?.data?.[0]?.tax?.percent / 100) * grandTotal
        ).toFixed(2),
        
        createdBy: "staff",
        items: tempItem,
      };
      dispatch(CreateOrderAction(data))
        .then((res) => {
          
          if (res.status >= 200 && res.status <= 300) {
            setStopper(false)
            navigate(`/spa-order-status/${id}`, { state: res.data.data });
            toast.success(res.data.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            setStopper(false)
            toast.error(res.response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          alert(err);
        });
    }
  };

  const handlePersonDetails = (e) => {
    updateState({ ...iState, person: e.target.value });

    let newArray;
    let localValue = person;
    let tempTotalPerson = [...person_details];
    let currentValue = Math.abs(localValue - e.target.value);

    if (person > e.target.value) {
      newArray = person_details.slice(0, e.target.value);
    } else {
      let tempArray = new Array(currentValue).fill({
        date: "",
        time: "",
      });

      newArray = [...tempTotalPerson, ...tempArray];
    }
    person_detailsSet(newArray);
  };

  const handleChangeDateTime = (e, i) => {
    let { name, value } = e.target;

    const temp = [...person_details];
    if(name =="date"){
      temp[i] = {
        ...temp[i],
        [name]: moment(value).format("MM/DD/YYYY"),
      };
  

    }
    else{
      console.log("value",        moment(value, "HH:mm").format("hh:mm a")
      );
      temp[i] = {
        ...temp[i],
        [name]:moment(value, "HH:mm").format("hh:mm a"),
      };


    }
    console.log(temp ,'==============> temp')
    person_detailsSet(temp);
  };

  const handleSearch = (e) => {
    dispatch(SpaListAction({ search: e.target.value, hotelId:id }));
    setSearch(e.target.value);

  };



  return (
    <div>
      <Header />
      <Sidenav />

      <div class="WrapperArea">
        <div class="WrapperBox">
        <div class="HotelLocation HotelLocation2">
            <div class="back-btn">
            <Link to={`/spa-order-request/${id}`}>
                <i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back{" "}
              </Link>
            </div>

            <aside>
              <h3>{hotelDetails?.name}</h3>
              <p>
                <span>
                  <i class="fa fa-map-marker"></i>
                </span>{" "}
                {hotelDetails?.address}
              </p>
            </aside>
          </div>

          <div class="NewTitleBox">
          <div className='arrow-left'>
              <h4>Create New Order</h4>
            </div>

            <div class="CommonLinks CommonLinksNew">
              <ul>
                {/* <li class="active"><a href="restaurant-add-order.html">Food</a></li>
                        <li><a href="restaurant-add-order-beverages.html">Beverages</a></li> */}
              </ul>
            </div>
          </div>

          <div class="Small-Wrapper">
            <div class="CommonTabs">
              <div class="TitleBox">
                <ul class="nav nav-tabs">
                  {/* <li class="nav-item">
                                <a class="nav-link active" data-toggle="tab" href="#Italian">Italian</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#Mexican">Mexican</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#Chinese">Chinese</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#American">American</a>
                            </li> */}
                </ul>
                <div class="Filter">
                  <div class="form-group">
                    <label>Search</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search"
                      value={search}
                      onChange={handleSearch}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="tab-content">
              <div class="tab-pane active" id="Italian">
                <div class="TitleBox"></div>
                <div class="MenuList">
                  <ul>
                    {allFoodItems?.map((item, i) => (
                      <li>
                        <div class="MenuLeft">
                          <figure>
                          <ImageFetcher imageUrl={item?.image}/>
                          </figure>
                          <figcaption>
                            <h3>{item?.name}</h3>
                            {/* <p>
                                                <span>
                                                  <img src="images/star.svg" alt="" />
                                                </span> 4.5
                                                <a href="#">34 Reviews</a>
                                            </p> */}
                            <h4>
                              <span>{item?.servingSize}</span>
                            </h4>
                            <h4>
                              <span>
                                Expected Delivery Time /{" "}
                                {item?.duration?.split(":")?.[0] == "00"
                                  ? ""
                                  : `${
                                      item?.duration?.split(":")?.[0]
                                    } Hrs`}{" "}
                                {item?.duration?.split(":")?.[1]} Mins
                              </span>
                            </h4>
                          </figcaption>
                        </div>
                        <div class="MenuRight">
                          <h5>
                            {item?.price} INR <span>+Taxes</span>
                          </h5>
                          { (
                            <div class="form-group">
                            <label class="Radio CheckBox">
                                <input type="radio" onChange={() => handleAddToCart(item, i)} name="ac" />
                                <span class="checkmark checkmark-new"></span>
                            </label>
                        </div>
                          )}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            {items?.length>0 &&

            <div class="total-bill">
              <div class="TableList">
                <table>
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Qnty</th>
                      <th>Amount</th>
                    </tr>
                  </thead>

                  <tbody>
                    {items?.map((ele) => (
                      <tr>
                        <td>{ele?.item?.name}</td>
                        <td>{ele?.qty}</td>
                        <td>{ele?.totalPrice} INR</td>
                      </tr>
                    ))}

                    <tr>
                      <td>GST (@ {spaList?.[0]?.data?.[0]?.tax?.percent}%) </td>
                      <td>-</td>
                      <td>
                        {(
                          (grandTotal / 100) *
                          spaList?.[0]?.data?.[0]?.tax?.percent
                        )?.toFixed(2)}{" "}
                        INR
                      </td>
                    </tr>
                  </tbody>

                  <tfoot>
                    <tr>
                      <td>Total Amount</td>
                      <td>-</td>
                      <td>
                        {(
                          grandTotal +
                          (spaList?.[0]?.data?.[0]?.tax?.percent / 100) * grandTotal
                        ).toFixed(2)}{" "}
                        INR
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>}

            {items?.length>0 &&

            <div class="CommonForm">
              <form>
                <div class="row">
                  <div class="col-sm-3">
                    <div class="form-group">
                      <label>Number of persons</label>
                      <select
                        onChange={handlePersonDetails}
                        value={person}
                        disabled={true}
                        className="form-control"
                      >
                        <option value="">Select</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                      </select>
                      {errorMsg.person && !iState.person && (
                        <p style={{ color: "red" }}>{errorMsg.person}</p>
                      )}
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group">
                      <label>Select Room </label>
                      <Select
                        defaultValue={room}
                        onChange={setRoom}
                        options={options}
                      />

                      {errorMsg.room && !room && (
                        <p style={{ color: "red" }}>{errorMsg.room}</p>
                      )}
                    </div>
                  </div>
                </div>
                {person_details?.map((item, i) => (
                  <div className="row">
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label>Date</label>

                        <input
                          type="date"
                          class="form-control"
                          min={moment(new Date()).format("YYYY-MM-DD")}

                          // value={item?.date}
                          name="date"
                          onChange={(e) => handleChangeDateTime(e, i)}
                        />
                        {errorMsg.date && !item.date && (
                          <p style={{ color: "red" }}>{errorMsg.date}</p>
                        )}
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label>Time</label>

                        <input
                          type="time"
                          class="form-control"
                          // value={item?.time}
                          name="time"
                          onChange={(e) => handleChangeDateTime(e, i)}
                        />
                        {errorMsg.time && !item?.time && (
                          <p style={{ color: "red" }}>{errorMsg.time}</p>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
                <div class="small-btn saveBtn">
                  <button disabled={stopper}  class="TitleLink" onClick={handleSubmit}>
                    Create Order
                  </button>
                </div>{" "}
              </form>
            </div>}
          </div>
        </div>
      </div>

      <Modal show={AddModal} onHide={handleAddModalClose} className="ModalBox ">
        <Modal.Body>
          <div class="Category">
            <a className="CloseModal" onClick={handleAddModalClose}>
              ×
            </a>
            <h3>Schedule</h3>
            <div class="form-group">
              {person_details?.map((item) => (
                <div className="RoomBox">
                  <label>Date</label>

                  <input
                    type="date"
                    class="form-control"
                    // value={start}
                    name="start"
                    onChange={handleChange}
                  />
                  {/* {errorMsg.start && !start && (
                    <p style={{ color: "red" }}>{errorMsg.start}</p>
                  )}
 */}
                  <label>Time</label>

                  <input
                    type="time"
                    class="form-control"
                    // value={end}
                    name="end"
                    onChange={handleChange}
                  />
                  {/* {errorMsg.end && !end && (
                    <p style={{ color: "red" }}>{errorMsg.end}</p>
                  )} */}
                </div>
              ))}
            </div>

            <button onClick={handleSubmit} class="Button" >
              Add Room
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SpaOrderAdd;
