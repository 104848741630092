import React, { useState } from "react";
import Header from "./header";
import Sidenav from "./sidenav";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from '../axiosInstance';
import TimePicker from "@semcore/time-picker";
import ImageFetcher from './ImageFetcher'

import {
  AddFoodBeverageAction,
  EditFoodBeverageAction,
  FoodBeverageListAction,
  RemoveFoodBeverageAction,
} from "../redux/actions/foodBeverageAction";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";

const inititalState = {
  openingTime: "",
  category: "",
  name: "",
  image: "",
  servingSize: "",
  deliveryTime: "",
  price: "",
  description: "",
  errorMsg: {},
};
const RestaurantBeverage = () => {
  const hotelDetails =  JSON.parse(window.localStorage.getItem("hotelDetails"))


  const {id}= useParams()
  const [iState, updateState] = useState(inititalState);
  const [foodbeverageId, setFoodBeverageId] = useState("");
  const [search, setSearch]= useState('')
  const [Type, setType] = useState("add");
  const dispatch = useDispatch();

  const [DeleteModal, setDelete] = useState(false);
  const handleDeleteModalClose = () => setDelete(false);
  const handleDeleteModalShow = (id) => {
    setFoodBeverageId(id);
    setDelete(true);
  };

  const { foodBeverageList } = useSelector(
    (state) => state.foodBeverageReducer
  );

  const {
    category,
    cuisine,
    name,
    image,
    servingSize,
    deliveryTime,
    price,
    description,
    errorMsg,
  } = iState;

  const [AddActivitiesModal, setAddActivities] = useState(false);
  const handleAddActivitiesModalClose = () => {
    updateState({...iState ,
      cuisine: "",
      name: "",
      image: "",
      servingSize: "",
      deliveryTime: "",
      price: "",
      description: "",
      errorMsg: {},
    })
    setAddActivities(false)};
  const handleAddActivitiesModalShow = (type, ele) => {
    if (type == "edit") {
      setType(type);
      setFoodBeverageId(ele?._id);

      updateState({
        ...iState,
        cuisine:cuisine,
        name: ele?.name,
        image: ele?.image,
        servingSize: ele?.servingSize,
        deliveryTime: ele?.deliveryTime,
        price: ele?.price,
        description: ele?.description,
      });
      setAddActivities(true);
    } else {
      setAddActivities(true);
      updateState({
        ...iState,
        cuisine:"",
      });

    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    updateState({ ...iState, [name]: value });
  };

  window.Buffer = window.Buffer || require("buffer").Buffer;

  const handleUpload = (e) => {
    const file = e.target.files[0];

    if (file.type == "application/pdf") {
      toast.error("Only jpeg, jpg and png are allowed ", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    if (file) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        //Initiate the JavaScript Image object.
        var image = new Image();

        //Set the Base64 string return from FileReader as source.
        image.src = e.target.result;

        //Validate the File Height and Width.
        image.onload = function () {
          var height = this.height;
          var width = this.width;
          if (
            file.type === "image/jpeg" ||
            file.type === "image/jpg" ||
            file.type === "image/png"
          ) {
            const formData = new FormData();
            formData.append('file', file);
  
            let accessToken = JSON.parse(localStorage.getItem("hotelDetails")).jwtToken;
            axiosInstance.post('/upload?type=hotel', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Authorization'.concat(" ",accessToken),
              }
            })
            .then(response => {
              console.log(response);
            
              if (response.status !== 200) {
                throw new Error(`Server error: ${response.statusText}`);
              }
            
              return response;
            })
            .then(data => {
              if (data.error) {
                toast.error(data.error, { position: toast.POSITION.TOP_RIGHT });
              } else {
                const tempData = data.data.url;
                updateState({ ...iState, image: tempData });
              toast.success("Upload successfully", { position: toast.POSITION.TOP_RIGHT });
              }
            })
            .catch(error => {
              console.error(error);
              toast.error("Upload failed: " + error.message, { position: toast.POSITION.TOP_RIGHT });
            });
            } else {
            toast.error("Only jpeg, jpg and png are allowed ", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        };
      };
    }
  };

  const handleValidate = () => {
    let formIsValid = true;
    let errorMsg = {};
    if (!name) {
      formIsValid = false;
      errorMsg.name = "* Please enter name.";
    }
    if (!image) {
      formIsValid = false;
      errorMsg.image = "* Please upload  image.";
    }

    if (!deliveryTime) {
      formIsValid = false;
      errorMsg.deliveryTime = "* Please enter serving time.";
    }

    if (!price) {
      formIsValid = false;
      errorMsg.price = "* Please enter price.";
    }

    if (!description) {
      formIsValid = false;
      errorMsg.description = "* Please  enter description.";
    }

    if (!cuisine) {
      formIsValid = false;
      errorMsg.cuisine = "* Please select cusine .";
    }
    if (!servingSize) {
      formIsValid = false;
      errorMsg.servingSize = "* Please enter serving size .";
    }

    updateState({ ...iState, errorMsg: errorMsg });

    return formIsValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formIsValid = handleValidate();
    const hotelData = JSON.parse(localStorage.getItem("hotel"));

    if (formIsValid) {
      let data = {
        name,
        price,
        cuisine,
        image,
        servingSize,
        description,
        hotelId: hotelData?.hotelId?.[0]?._id,
        category,
        deliveryTime,
      };

      dispatch(AddFoodBeverageAction(data))
        .then((res) => {
          if (res.data.success === true) {
            setAddActivities(false);
            dispatch(FoodBeverageListAction({ category, cuisine: cuisine }));
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            updateState({ name: "", image: "", errorMsg: {} });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          alert(err);
        });
    }
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    let formIsValid = handleValidate();
    const hotelData = JSON.parse(localStorage.getItem("hotel"));

    if (formIsValid) {
      let data = {
        _id: foodbeverageId,
        name,
        price,
        cuisine,
        image,
        servingSize,
        description,
        hotelId: hotelData?.hotelId?.[0]?._id,
        category,
        deliveryTime,
      };

      dispatch(EditFoodBeverageAction(data))
        .then((res) => {
          if (res.data.success === true) {
            setAddActivities(false);
            dispatch(FoodBeverageListAction({ category, cuisine: cuisine }));
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            updateState({ name: "", image: "", errorMsg: {} });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          alert(err);
        });
    }
  };

  useEffect(() => {
    dispatch(FoodBeverageListAction({ category: "beverage", cuisine: "italian" }));
  }, []);

  const handleCusine = (cuisine) => {
    updateState({...iState, cuisine})
    dispatch(FoodBeverageListAction({ category, cuisine: cuisine }));
  };

  const handleDelete = () => {
    dispatch(RemoveFoodBeverageAction({ id: foodbeverageId })).then((res) => {
      if (res.data.success === true) {
        setDelete(false);
        dispatch(FoodBeverageListAction({ category, cuisine: cuisine }));
      }
    });
  };



  const handleStatusChange=(item)=>{
    if(item?.visibility ==true){
      item.visibility = false
    }
    else{
      item.visibility =true
      
    }
    dispatch(EditFoodBeverageAction(item))
    .then((res) => {
        if (res.data.success === true) {
          dispatch(FoodBeverageListAction({ category, cuisine: cuisine }));

          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        alert(err);
      });
  }

  const handleSearch=(e)=>{
    dispatch(FoodBeverageListAction({ category, cuisine: cuisine, search }));

  }

  const handleRefresh=(e)=>{
    dispatch(FoodBeverageListAction({ category, cuisine: cuisine, search:"" }));
    setSearch("")

  }


  return (
    <div>
      <Header />
      <Sidenav />
      <div class="WrapperArea">
        <div class="WrapperBox">
        <div class="HotelLocation HotelLocation2">
            <div class="back-btn">
              <Link to={`/hotel-details/${id}`}>
                <i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back{" "}
              </Link>
            </div>

            <aside>
              <h3>{hotelDetails?.name}</h3>
              <p>
                <span>
                  <i class="fa fa-map-marker"></i>
                </span>{" "}
                {hotelDetails?.address}
              </p>
            </aside>
          </div>
          

          <div class="Small-Wrapper">
          <div class="CommonLinks CommonLinksNew">
            <ul>
              <li>
              <Link to={`/restaurant-food/${id}`}>Food</Link>
              </li>
              <li class="active">
              <Link to={`/restaurant-beverage/${id}`}>Beverages</Link>
              </li>
              </ul>
              <ul>

              <li class="active">
                <a href="restaurant-msg.html">Message</a>
              </li>
              <li class="active">
                <a href="restaurant-order-request.html">Order Requests</a>
              </li>
              </ul>
              {/* <li class="active">
                <a href="#">Guest Notifications</a>
              </li> */}
          </div>
            <div class="CommonTabs">
              <div class="TitleBox">
                <ul class="nav nav-tabs">
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      onClick={() => handleCusine("italian")}
                      data-toggle="tab"
                      href="#Italian"
                    >
                      Italian
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      onClick={() => handleCusine("mexican")}
                      data-toggle="tab"
                      href="#Mexican"
                    >
                      Mexican
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      onClick={() => handleCusine("chinese")}
                      data-toggle="tab"
                      href="#Chinese"
                    >
                      Chinese
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      onClick={() => handleCusine("american")}
                      data-toggle="tab"
                      href="#American"
                    >
                      American
                    </a>
                  </li>
                </ul>
                <div class="SearchBox">
                  <form action="">
                  <input
                      type="text"
                      class="form-control"
                      value={search}
                      onChange={(e)=> setSearch(e.target.value)}
                      placeholder="Search.."
                    />
                    <button class="Button" onClick={handleSearch}>Apply</button>
                    <button onClick={handleRefresh} class="Button Cancel">
                      <i class="fa fa-refresh"></i>
                    </button>                  </form>
                  <a
                    class="Button"
                    onClick={(e) => handleAddActivitiesModalShow("add")}
                  >
                    Add Item
                  </a>
                </div>
              </div>
            </div>

            <div class="TitleBox">
              <h6>
                Opening time:{" "}
                <b>
                  10:00 AM to 9:00 PM
                  <span>
                    <a class="Green" data-toggle="modal">
                      <i class="fa fa-pencil"></i>
                    </a>
                  </span>
                </b>
              </h6>
            </div>

            <div class="tab-content">
              <div class="tab-pane active" id="Italian">
                <div class="TitleBox">
                  <h4 class="Title">Italian</h4>
                </div>
                <div class="MenuList">
                  <ul>
                    {foodBeverageList?.[0]?.items?.map((item) => (
                      <li>
                        <div class="MenuLeft">
                          <figure>
                          <ImageFetcher imageUrl={item?.image}/>
                          </figure>
                          <figcaption>
                            <h3>{item?.name}</h3>
                            <p>
                              <span>
                                <img src="images/star.svg" alt="" />
                              </span>
                              4.5
                              <a href="#">34 Reviews</a>
                            </p>
                            <h4>
                              <span>4 Pieces / per serving</span>
                            </h4>
                            <h4>
                              <span>
                                Expected Delivery Time / {item?.deliveryTime}
                              </span>
                            </h4>
                          </figcaption>
                          <figcaption>
                            <h4>Platinum Service Packages (Say 20%) 200</h4>
                            <h4>Gold Service Packages (Say 15%) 212</h4>
                            <h4>Silver Service Packages (Say 10%) 225</h4>
                          </figcaption>
                        </div>
                        <div class="MenuRight">
                          <h5>
                            {item?.price} INR <span>+Taxes</span>
                          </h5>
                          <label class="switch">
                          <input type="checkbox" checked={item?.visibility} value={item} onChange={(e)=>handleStatusChange(item)} />
                            <span class="slider"></span>
                          </label>
                          <a
                            class="Green"
                            onClick={(e) =>
                              handleAddActivitiesModalShow("edit", item)
                            }
                          >
                            <i class="fa fa-pencil"></i>
                          </a>
                          <a
                            class="Red"
                            onClick={() => handleDeleteModalShow(item?._id)}
                          >
                            <i class="fa fa-trash"></i>
                          </a>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div class="tab-pane fade" id="Mexican">
                <div class="TitleBox">
                  <h4 class="Title">Mexican</h4>
                </div>
                <div class="MenuList">
                  <ul>
                    {foodBeverageList?.[0]?.items?.map((item) => (
                      <li>
                        <div class="MenuLeft">
                          <figure>
                          <ImageFetcher imageUrl={item?.image}/>
                          </figure>
                          <figcaption>
                            <h3>{item?.name}</h3>
                            <p>
                              <span>
                                <img src="images/star.svg" alt="" />
                              </span>
                              4.5
                              <a href="#">34 Reviews</a>
                            </p>
                            <h4>
                              <span>4 Pieces / per serving</span>
                            </h4>
                            <h4>
                              <span>
                                Expected Delivery Time / {item?.deliveryTime}
                              </span>
                            </h4>
                          </figcaption>
                          <figcaption>
                            <h4>Platinum Service Packages (Say 20%) 200</h4>
                            <h4>Gold Service Packages (Say 15%) 212</h4>
                            <h4>Silver Service Packages (Say 10%) 225</h4>
                          </figcaption>
                        </div>
                        <div class="MenuRight">
                          <h5>
                            {item?.price} INR <span>+Taxes</span>
                          </h5>
                          <label class="switch">
                          <input type="checkbox" checked={item?.visibility} value={item} onChange={(e)=>handleStatusChange(item)} />
                            <span class="slider"></span>
                          </label>
                          <a
                            class="Green"
                            onClick={(e) =>
                              handleAddActivitiesModalShow("edit", item)
                            }
                          >
                            <i class="fa fa-pencil"></i>
                          </a>
                          <a
                            class="Red"
                            data-toggle="modal"
                            data-target="#DeleteModal"
                          >
                            <i class="fa fa-trash"></i>
                          </a>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div class="tab-pane fade" id="Chinese">
                <div class="TitleBox">
                  <h4 class="Title">Chinese</h4>
                </div>
                <div class="MenuList">
                  <ul>
                    {foodBeverageList?.[0]?.items?.map((item) => (
                      <li>
                        <div class="MenuLeft">
                          <figure>
                          <ImageFetcher imageUrl={item?.image}/>
                          </figure>
                          <figcaption>
                            <h3>{item?.name}</h3>
                            <p>
                              <span>
                                <img src="images/star.svg" alt="" />
                              </span>
                              4.5
                              <a href="#">34 Reviews</a>
                            </p>
                            <h4>
                              <span>4 Pieces / per serving</span>
                            </h4>
                            <h4>
                              <span>
                                Expected Delivery Time / {item?.deliveryTime}
                              </span>
                            </h4>
                          </figcaption>
                          <figcaption>
                            <h4>Platinum Service Packages (Say 20%) 200</h4>
                            <h4>Gold Service Packages (Say 15%) 212</h4>
                            <h4>Silver Service Packages (Say 10%) 225</h4>
                          </figcaption>
                        </div>
                        <div class="MenuRight">
                          <h5>
                            {item?.price} INR <span>+Taxes</span>
                          </h5>
                          <label class="switch">
                          <input type="checkbox" checked={item?.visibility} value={item} onChange={(e)=>handleStatusChange(item)} />
                            <span class="slider"></span>
                          </label>
                          <a
                            class="Green"
                            onClick={(e) =>
                              handleAddActivitiesModalShow("edit", item)
                            }
                          >
                            <i class="fa fa-pencil"></i>
                          </a>                          <a
                            class="Red"
                            data-toggle="modal"
                            data-target="#DeleteModal"
                          >
                            <i class="fa fa-trash"></i>
                          </a>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div class="tab-pane fade" id="American">
                <div class="TitleBox">
                  <h4 class="Title">American</h4>
                </div>
                <div class="MenuList">
                  <ul>
                    {foodBeverageList?.[0]?.items?.map((item) => (
                      <li>
                        <div class="MenuLeft">
                          <figure>
                          <ImageFetcher imageUrl={item?.image}/>
                          </figure>
                          <figcaption>
                            <h3>{item?.name}</h3>
                            <p>
                              <span>
                                <img src="images/star.svg" alt="" />
                              </span>
                              4.5
                              <a href="#">34 Reviews</a>
                            </p>
                            <h4>
                              <span>4 Pieces / per serving</span>
                            </h4>
                            <h4>
                              <span>
                                Expected Delivery Time / {item?.deliveryTime}
                              </span>
                            </h4>
                          </figcaption>
                          <figcaption>
                            <h4>Platinum Service Packages (Say 20%) 200</h4>
                            <h4>Gold Service Packages (Say 15%) 212</h4>
                            <h4>Silver Service Packages (Say 10%) 225</h4>
                          </figcaption>
                        </div>
                        <div class="MenuRight">
                          <h5>
                            {item?.price} INR <span>+Taxes</span>
                          </h5>
                          <label class="switch">
                          <input type="checkbox" checked={item?.visibility} value={item} onChange={(e)=>handleStatusChange(item)} />
                            <span class="slider"></span>
                          </label>
                          <a
                            class="Green"
                            onClick={(e) =>
                              handleAddActivitiesModalShow("edit", item)
                            }
                          >
                            <i class="fa fa-pencil"></i>
                          </a>                          <a
                            class="Red"
                            data-toggle="modal"
                            data-target="#DeleteModal"
                          >
                            <i class="fa fa-trash"></i>
                          </a>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={AddActivitiesModal}
        onHide={handleAddActivitiesModalClose}
        className="ModalBox "
      >
        <Modal.Body>
          <div class="Category">
            <a onClick={handleAddActivitiesModalClose} class="CloseModal">
              ×
            </a>
            {Type =="edit" ?            <h3>Edit Beverage Item</h3>
 :             <h3>Add Beverage Item</h3>
}
            <div class="form-group">
              <label></label>
              <div class="UploadBox">
                <div class="Upload">
                  <i class="fa fa-upload"></i> <span>Upload Icon</span>
                  <input
                    type="file"
                    onClick={(e) => (e.target.value = null)}
                    onChange={(e) => handleUpload(e)}
                    class="form-control"
                  />
                </div>
                {image &&  <div className="UploadIcon">
                  <ImageFetcher imageUrl={image}/>
                </div>
 }
              </div>
              {errorMsg.image && (
                <p style={{ color: "red" }}>{errorMsg.image}</p>
              )}
            </div>
            <div class="form-group">
              <label>Name</label>
              <input
                value={name}
                name="name"
                onChange={handleChange}
                class="form-control"
              />
              {errorMsg.name && <p style={{ color: "red" }}>{errorMsg.name}</p>}
            </div>

            <div class="form-group">
              <label>Select Cuisine</label>
              <select
                value={cuisine}
                name="cuisine"
                onChange={handleChange}
                class="form-control"
              >
                <option value="">Select</option>

                <option value="italian">Italian</option>
                <option value="mexican">Mexican</option>
                <option value="chinese">Chinese</option>
                <option value="american">American</option>
              </select>
              {errorMsg.cuisine && (
                <p style={{ color: "red" }}>{errorMsg.cuisine}</p>
              )}
            </div>
            <div class="form-group">
              <label>Serving Size</label>
              <input
                value={servingSize}
                name="servingSize"
                onChange={handleChange}
                type="text"
                class="form-control"
              />
              {errorMsg.servingSize && (
                <p style={{ color: "red" }}>{errorMsg.servingSize}</p>
              )}
            </div>
            <div class="form-group">
              <label>Estimated Serving Time</label>
              <TimePicker
                size="m"
                state="normal"
                value={deliveryTime}
                onChange={(e) => updateState({ ...iState, deliveryTime: e })}
              />

              {errorMsg.deliveryTime && (
                <p style={{ color: "red" }}>{errorMsg.deliveryTime}</p>
              )}
            </div>
            <div class="form-group">
              <label>Item Price</label>
              <input
                value={price}
                name="price"
                onChange={handleChange}
                type="text"
                class="form-control"
              />
              {errorMsg.price && (
                <p style={{ color: "red" }}>{errorMsg.price}</p>
              )}
            </div>
            <div class="form-group">
              <label>Description</label>
              <textarea
                rows="3"
                value={description}
                name="description"
                onChange={handleChange}
                class="form-control"
              ></textarea>
              {errorMsg.description && (
                <p style={{ color: "red" }}>{errorMsg.description}</p>
              )}
            </div>
            {Type == "edit" ? (
              <button class="Button" onClick={handleUpdate}>
                Edit Food
              </button>
            ) : (
              <button class="Button" onClick={handleSubmit}>
                Add Food
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={DeleteModal}
        onHide={handleDeleteModalClose}
        className="ModalBox "
      >
        <Modal.Body>
          <div className="Decline">
            <button className="CloseModal" onClick={handleDeleteModalClose}>
              ×
            </button>
            <h3>Delete</h3>
            <p>Are you sure you want to delete this beverage ?</p>
            <h4>
              <a onClick={handleDeleteModalClose} className="Cancel">
                Cancel
              </a>
              <a onClick={handleDelete}>Delete</a>
            </h4>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RestaurantBeverage;
