import { authConstant } from "../actionTypes";

const initialState = {
  adminData: {},
  loader: false,
  loginStatus: false,
  reviewList:[],
  error: {},
  bonusData:[],
  notification:[],
};

export default function authReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    // LOGIN
    case authConstant.LOGIN_INITIATE:
      return { ...state, loader: true, loginStatus: false, adminData: {} };

    case authConstant.LOGIN_SUCCESS:
      return { ...state, loader: false, loginStatus: true, adminData: payload };

    case authConstant.LOGIN_FAILURE:
      return { ...state, loader: false, error: payload };


    // GET DETAILS

    case authConstant.ADMIN_DETAILS_INITIATE:
      return { ...state, loader: true, adminData: {} };

    case authConstant.ADMIN_DETAILS_SUCCESS:
      return { ...state, loader: false, adminData: payload };

    case authConstant.ADMIN_DETAILS_FAILURE:
      return { ...state, loader: false };


      
          // REVIEW LIST

    case authConstant.REVIEW_LIST_INITIATE:
      return { ...state, loader: true, reviewList: [] };

    case authConstant.REVIEW_LIST_SUCCESS:
      return { ...state, loader: false, reviewList: payload.data };

    case authConstant.REVIEW_LIST_FAILURE:
      return { ...state, loader: false };




      case authConstant.SIGNUP_BONUS_INITIATE:
        return { ...state, loader: true, bonusData: [] };
  
      case authConstant.SIGNUP_BONUS_SUCCESS:
        return { ...state, loader: false, bonusData: payload.data };
  
      case authConstant.SIGNUP_BONUS_FAILURE:
        return { ...state, loader: false };



        
      case authConstant.NOTIFICATION_INITIATE:
        return { ...state, loader: true, notification: [] };
  
      case authConstant.NOTIFICATION_SUCCESS:
        return { ...state, loader: false, notification: payload.data };
  
      case authConstant.NOTIFICATION_FAILURE:
        return { ...state, loader: false };
  
  
    default:
      return state;
  }
}
