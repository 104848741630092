import React, { useEffect, useState } from "react";
import Header from "./header";
import Sidenav from "./sidenav";

import Modal from "react-bootstrap/Modal";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment/moment";
import NoDataFound from "./noDataFound";
import Pagination from "react-js-pagination";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { TransactionListAction } from "../redux/actions/hotelAction";

const HotelTransaction = () => {
  const dispatch = useDispatch();
  const [activePage, setActivePage] = useState(1);
  const {id} = useParams()

  const { transactionList, loader } = useSelector(
    (state) => state.hotelReducer
  );

  const [userId, setUserId] = useState("");
  const [filterData, setFilterData] = useState({
    search: "",
    fromDate: "",
    toDate: "",
    timeFrame: "",
    page: 1,
    status: "",
  });

  const [ApproveModal, setApproveShow] = useState(false);
  const handleApproveClose = () => setApproveShow(false);
  const handleApproveShow = (id, status) => {
    setUserId(id);
    setApproveShow(true);
  };

  const [AddHotelModal, setAddHotel] = useState(false);
  const handleAddHotelModalClose = () => setAddHotel(false);
  const handleAddHotelModalShow = (id) => {
    setAddHotel(true);
  };

  useEffect(() => {
    dispatch(TransactionListAction({hotelId:id}));
  }, []);

  const handleFilter = (e) => {
    dispatch(
      TransactionListAction({
        search: filterData?.search,
        fromDate: filterData?.fromDate,
        toDate: filterData?.toDate,
        hotelId:id
      })
    );
  };

  const handleRefresh = (e) => {
    setFilterData({
      ...filterData,
      search: "",
      fromDate: "",
      toDate: "",
      timeFrame: "",
      status: "",
    });

    dispatch(TransactionListAction({hotelId:id}));
  };

  const handleSearch = (e) => {
    dispatch(TransactionListAction({ search: e.target.value, hotelId:id }));
    setFilterData((prev) => ({ ...prev, search: e.target.value }));
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    filterData.page = pageNumber;
    dispatch(TransactionListAction({ page: pageNumber, hotelId:id }));
  };

  const grandTotal = transactionList?.[0]?.data?.reduce(
    (accum, ele) => accum + ele?.totalAmount,
    0
  );

  const downloadRowData = (rowData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    let result = rowData?.map((item, i) => {
      return {
        "Sr. No": i + 1,
        "Trans Id": item?.transId,
        "Transaction Date": moment(item?.createdAt).format("ll"),
        "Transaction Amount": item?.totalAmount?.toFixed(2),
        "Transaction Type": item?.paymentType,
        "Mode of Payment": item?.paymentMethod,
      };
    });

    // Combine the header row and row data into a single string
    const myJsonString = JSON.stringify(result);

    const ws = XLSX.utils.json_to_sheet(result);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "finance-record.xlsx");
  };

  return (
    <>
      <Header />
      <Sidenav />
      <div class="WrapperArea">
        <div class="WrapperBox">
        <div class="NewTitleBox">
            <div className="arrow-left">
              <Link to={`/hotel-details/${id}`}>
                <i class="fa fa-arrow-left arrow-back" aria-hidden="true"></i>
              </Link>

              <h4> Transaction</h4>
            </div>
          </div>
          <div class="Small-Wrapper">

          <div class="NewTitleBox">
</div>


            <div class="TitleBox">

              <ul>
                <li>
               
                </li>
              </ul>
            </div>

            <div class="Filter">
              <div class="form-group">
                <label>Search</label>
                <input
                  type="text"
                  onChange={handleSearch}
                  value={filterData?.search}
                  class="form-control"
                  placeholder="Search by Transaction Id"
                />
              </div>
        

              <div class="form-group">
                <label>From Date</label>
                <input
                  type="date"
                  value={filterData?.fromDate}
                  onChange={(e) =>
                    setFilterData({ ...filterData, fromDate: e.target.value })
                  }
                  class="form-control"
                />
              </div>
              <div class="form-group">
                        <label>To Date</label>
                        <input type="date" disabled={!filterData?.fromDate} value={filterData?.toDate} min={filterData?.fromDate} onChange={(e)=> setFilterData({...filterData, toDate:e.target.value})} class="form-control" />
                    </div> 
       
              {/* <div class="form-group">
                        <label>Timeframe</label>
                        <select value={filterData?.timeFrame} onChange={(e)=> setFilterData({...filterData, timeFrame:e.target.value})} class="form-control">
                            <option value="">--Select--</option>
                            <option value="Today">Today</option>
                            <option value="Week">This Week</option>
                            <option value="Month">This Month</option>
                        </select>
                    </div>   */}
              <div class="form-group">
                <label>&nbsp;</label>
                <button onClick={handleFilter} class="Button">
                  Apply
                </button>
                <button onClick={handleRefresh} class="Button Cancel">
                  <i class="fa fa-refresh"></i>
                </button>
                &nbsp;&nbsp;

                <button
                    class="Button"
                    onClick={() =>
                      downloadRowData(transactionList?.hotelTrans?.[0]?.data)
                    }
                  >
                    Export to Excel
                  </button>
              </div>
            </div>

            <div class="Subscription-cancel">
              <div class="earning">
                <p>
                  Total Earnings : <span>{transactionList?.totalRevenueGenrated?.toFixed(2)} INR</span>
                </p>
              </div>
            </div>

            <div class="TableList">
              <table style={{ width: "120%" }}>
                <thead>
                  <tr>
                    <th>Trans Id</th>
                    <th>Transaction date</th>
                    <th>Transaction Amount</th>
                    <th>Transaction Type</th>
                    <th>Mode of Payment</th>
                    <th>Order</th>

                  </tr>
                </thead>
                <tbody>
                  {transactionList?.hotelTrans?.[0]?.data?.map((item, i) => (
                    <tr>
                      <td>{item?.transId}</td>
                      <td>{moment(item?.createdAt).format("ll")}</td>
                      <td>{item?.totalAmount?.toFixed(2)}</td>
                      <td>{item?.paymentType}</td>
                      <td>{item?.paymentMethod}</td>
                      <td><Link to={`/hotel-transaction-details/${id}`} state={item}>View Order</Link></td>

                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {loader ? (
              ""
            ) : transactionList?.hotelTrans?.[0]?.data?.length>0 ? (
              ""
            ) : (
              <NoDataFound />
            )}

            {transactionList?.hotelTrans?.[0]?.metadata?.[0]?.total > 0 ? (
              <div className="Pagination">
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={transactionList?.hotelTrans?.[0]?.metadata?.[0]?.total}
                  pageRangeDisplayed={5}
                  onChange={(e) => handlePageChange(e)}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      <Modal
        show={AddHotelModal}
        onHide={handleAddHotelModalClose}
        className="ModalBox "
      >
        <Modal.Body>
          <div class="Category">
            <a onClick={handleAddHotelModalClose} class="CloseModal">
              ×
            </a>
            <h3>Invite Partner Hotel</h3>
            <div class="form-group">
              <label>Hotel Name</label>
              <input type="text" class="form-control" />
            </div>
            <div class="form-group">
              <label>Phone Number</label>
              <input type="text" class="form-control" />
            </div>
            <div class="form-group">
              <label>Email Address</label>
              <input type="text" class="form-control" />
            </div>
            <button class="Button" data-dismiss="modal">
              Send Invite{" "}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default HotelTransaction;
