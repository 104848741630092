import React, { useState } from "react";
import Header from "./header";
import Sidenav from "./sidenav";
import { baseUrl } from "../config/config";
import axios from "axios";
import { CouponListAction } from "../redux/actions/couponAction";
import ImageFetcher from './ImageFetcher'
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from '../axiosInstance';
import TimePicker from "@semcore/time-picker";
import { Box } from "@semcore/ui/flex-box";
import NoDataFound from "./noDataFound";
import Pagination from "react-js-pagination";
import Star from "../assets/image/star.svg";

import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  AddSpaAction,
  SpaListAction,
  RemoveSpaAction,
  EditSpaAction,
  UpdateSpaTimeSpaAction,
  SetSpaTaxAction,
} from "../redux/actions/spaAction";
import moment, { min } from "moment";
import { hoursList, isLoggedIn, minutesList } from "../utils";
import Loader from "./loader";

const inititalState = {
  name: "",
  duration: "",
  price: "",
  description: "",
  hrs: "00",
  mins: "00",

  errorMsg: {},
};

const inititalDiscountState = {
  diamond: {
    price: "",
    discount: "",
  },
  gold: {
    price: "",
    discount: "",
  },
  silver: {
    price: "",
    discount: "",
  },
  discountError: {},
};

const initialSpanTimeState = {
  start: "",
  end: "",
  restaurantError: {},
};

const SpaMananagement = () => {
  const hotelDetails =  JSON.parse(window.localStorage.getItem("hotelDetails"))
  const [image, setImage] = useState("");
  const [stopper, setStopper] = useState(false);

  const { couponList } = useSelector((state) => state.couponReducer);

  const [iState, updateState] = useState(inititalState);
  const [iDiscountState, updateDiscountState] = useState(inititalDiscountState);
  const [iSpanTimeState, updateSpanTimeState] = useState(initialSpanTimeState);
  const { start, end, restaurantError } = iSpanTimeState;

  const [percent, setPercent] = useState("");
  const [tempPrice, setTempPrice] = useState("");

  const [EditTaxModal, setTax] = useState(false);
  const handleEditTaxModalClose = () => setTax(false);
  const handleEditTaxModalShow = (tax) => {
    setPercent(tax);
    setTax(true);
  };

  const [spaId, setSpaId] = useState("");
  const [Type, setType] = useState("add");
  const [search, setSearch] = useState("");
  const [activePage, setActivePage] = useState(1);

  const { diamond, gold, silver, discountError } = iDiscountState;

  const dispatch = useDispatch();
  const { id } = useParams();

  const [DeleteModal, setDelete] = useState(false);
  const handleDeleteModalClose = () => setDelete(false);
  const handleDeleteModalShow = (id) => {
    setSpaId(id);
    setDelete(true);
  };

  const { spaList, loader } = useSelector((state) => state.spaReducer);

  const {
    category,
    cuisine,
    name,
    price,
    description,
    hrs,
    mins,
    duration,
    errorMsg,
  } = iState;

  const [AddSpaModal, setAddSpa] = useState(false);
  const handleAddSpaModalClose = () => {
    setImage("");
    updateState({
      ...iState,
      name: "",
      hrs: "00",
      mins: "00",
      price: "",
      description: "",
      errorMsg: {},
    });
    setAddSpa(false);
  };
  const handleAddSpaModalShow = (type, ele) => {
    if (couponList?.length < 3) {
      toast.error("* Please add all coupon first", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      if (type == "edit") {
        setType(type);
        setSpaId(ele?._id);
        setImage(ele?.image);

        updateState({
          ...iState,
          name: ele?.name,
          hrs: ele?.duration?.split(":")?.[0],
          mins: ele?.duration?.split(":")?.[1],

          price: ele?.price,
          description: ele?.description,
        });
        setAddSpa(true);
      } else {
        setAddSpa(true);
        setType(type);
      }
    }
  };

  const [AddTimeModal, setAddTime] = useState(false);

  const handeAddTimeModalClose = () => {
    setAddTime(false);
    updateSpanTimeState({
      start: "",
      end: "",
      restaurantError: {},
    });
  };
  const handleAddTimeModalShow = (type, ele) => {
    if (type == "edit") {
      setType(type);

      updateSpanTimeState({
        ...iSpanTimeState,
        start: ele?.start,
        end: ele?.end,
      });
      setAddTime(true);
    } else {
      setAddTime(true);
      setType(type);
    }
  };

  let handleSpanTimeValidation = () => {
    let restaurantError = {};
    let formIsValid = true;

    if (!start) {
      restaurantError.start = "* Please enter restaurant opening time";
      formIsValid = false;
    }

    if (!end) {
      restaurantError.end = "* Please enter restaurant closing time";
      formIsValid = false;
    }

    updateSpanTimeState({
      ...iSpanTimeState,
      restaurantError: restaurantError,
    });
    return formIsValid;
  };

  const handleSpanTimeChange = (e) => {
    const { name, value } = e.target;
    if (name == "end") {
      if (value >= start) {
        updateSpanTimeState({ ...iSpanTimeState, [name]: value });
      }
    } else {
      updateSpanTimeState({ ...iSpanTimeState, [name]: value });
    }
  };

  const [updateDiscountModal, setUpdateDiscount] = useState(false);
  const handleUpdateDiscountModalClose = () => {
    setImage("");

    updateState({
      ...iState,
      name: "",
      hrs: "00",
      mins: "00",
      price: "",
      description: "",
    });

    setUpdateDiscount(false);
  };

  const handleUpdateDiscountModalShow = (item) => {
    if (
      (item?.price / 100) * gold.discount <
        couponList
          ?.filter((item) => item?.category == "silver")
          ?.map((ele) => {
            return ele?.maxDiscountAmt;
          })?.[0] &&
      (item?.price / 100) * gold.discount <
        couponList
          ?.filter((item) => item?.category == "gold")
          ?.map((ele) => {
            return ele?.maxDiscountAmt;
          })?.[0] &&
      (item?.price / 100) * gold.discount <
        couponList
          ?.filter((item) => item?.category == "diamond")
          ?.map((ele) => {
            return ele?.maxDiscountAmt;
          })?.[0]
    ) {
      setSpaId(item?._id);
      setTempPrice(item?.price);

      updateState({
        ...iState,
        name: item?.name,
        image: item?.image,
        hrs: item?.duration?.split(":")?.[0],
        mins: item?.duration?.split(":")?.[1],

        price: item?.price,
        description: item?.description,
      });

      updateDiscountState({
        ...iDiscountState,
        diamond: {
          discount: item?.diamond?.discount,
          price: item?.diamond?.price,
        },
        gold: {
          discount: item?.gold?.discount,
          price: item?.gold?.price,
        },
        silver: {
          discount: item?.silver?.discount,
          price: item?.silver?.price,
        },
      });
      if (couponList?.length == 3) {
        setUpdateDiscount(true);
      } else {
        toast.error("Please add all coupons first ", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name == "price") {
      if (!/^[0-9]{0,10}$/.test(value) || e.target.value == "e") return;
      {
        updateState({ ...iState, [name]: value });
      }
    } else {
      updateState({ ...iState, [name]: value });
    }
  };

  window.Buffer = window.Buffer || require("buffer").Buffer;

  const handleUpload = (e) => {
    const file = e.target.files[0];

    if (file.type == "application/pdf") {
      toast.error("Only jpeg, jpg and png are allowed ", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    if (file) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        //Initiate the JavaScript Image object.
        var image = new Image();

        //Set the Base64 string return from FileReader as source.
        image.src = e.target.result;

        //Validate the File Height and Width.
        image.onload = function () {
          var height = this.height;
          var width = this.width;
          if (
            file.type === "image/jpeg" ||
            file.type === "image/jpg" ||
            file.type === "image/png"
          ) {
            const formData = new FormData();
            formData.append('file', file);
  
            let accessToken = JSON.parse(localStorage.getItem("hotelDetails")).jwtToken;
            axiosInstance.post('/upload?type=hotel', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Authorization'.concat(" ",accessToken),
              }
            })
            .then(response => {
              console.log(response);
            
              if (response.status !== 200) {
                throw new Error(`Server error: ${response.statusText}`);
              }
            
              return response;
            })
            .then(data => {
              if (data.error) {
                toast.error(data.error, { position: toast.POSITION.TOP_RIGHT });
              } else {
                const tempData = data.data.url;
                setImage(tempData);
              toast.success("Upload successfully", { position: toast.POSITION.TOP_RIGHT });
              }
            })
            .catch(error => {
              console.error(error);
              toast.error("Upload failed: " + error.message, { position: toast.POSITION.TOP_RIGHT });
            });
            } else {
            toast.error("Only jpeg, jpg and png are allowed ", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        };
      };
    }
  };

  const handleValidate = () => {
    let formIsValid = true;
    let errorMsg = {};
    if (!name) {
      formIsValid = false;
      errorMsg.name = "* Please enter name.";
    }
    if (!image) {
      formIsValid = false;
      errorMsg.image = "* Please upload  image.";
    }

    if (hrs == "00" && mins == "00") {
      formIsValid = false;
      errorMsg.duration = "* Please enter duration.";
    }

    if (!price) {
      formIsValid = false;
      errorMsg.price = "* Please enter price.";
    }

    if (!description) {
      formIsValid = false;
      errorMsg.description = "* Please  enter description.";
    }

    updateState({ ...iState, errorMsg: errorMsg });

    return formIsValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let couponSilver = couponList?.filter?.(
      (item) => item?.category == "silver"
    );

    let formIsValid = handleValidate();
    setStopper(formIsValid);

    const hotelData = JSON.parse(localStorage.getItem("hotel"));

    if (formIsValid) {
      let data = {
        name,
        price,
        image,
        description,
        hotelId: id,
        duration: hrs + ":" + mins,
      };


      setImage("");

      if (couponSilver?.[0]?.minPurchaseAmt < price) {
        setStopper(false);
        toast.error(
          `* Maximum allowed discount is ${couponSilver?.[0]?.minPurchaseAmt} as per Silver coupon. Please update the item amount or coupon discount`,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      } else {
        dispatch(AddSpaAction(data))
          .then((res) => {
            if (res.data.success === true) {
              setStopper(false);
              setAddSpa(false);
              dispatch(SpaListAction({ category, cuisine: cuisine, hotelId:id }));
              toast.success(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              updateState({
                name: "",
                image: "",
                hrs: "00",
                mins: "00",
                price: "",
                description: "",
                errorMsg: {},
              });
            } else {
              setStopper(false);
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          })
          .catch((err) => {
            alert(err);
          });
      }
    }
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    let couponSilver = couponList?.filter?.(
      (item) => item?.category == "silver"
    );

    let formIsValid = handleValidate();
    setStopper(formIsValid);

    const hotelData = JSON.parse(localStorage.getItem("hotel"));

    if (formIsValid) {
      let timeArray = duration?.split(":");

      let data = {
        _id: spaId,
        name,
        price,
        image,
        description,
        hotelId: id,
        duration: hrs + ":" + mins,
      };

      if (couponSilver?.[0]?.minPurchaseAmt < price) {
        setStopper(false);
        toast.error(
          `* Maximum allowed discount is ${couponSilver?.[0]?.minPurchaseAmt} as per Silver coupon. Please update the item amount or coupon discount`,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      } else {
        dispatch(EditSpaAction(data))
          .then((res) => {
            if (res.data.success === true) {
              setStopper(false);
              setAddSpa(false);
              dispatch(SpaListAction({ category, cuisine: cuisine, hotelId:id }));
              toast.success(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });

              setImage("");

              updateState({
                name: "",
                hrs: "00",
                mins: "00",
                price: "",
                description: "",
                errorMsg: {},
              });
            } else {
              setStopper(false);
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          })
          .catch((err) => {
            alert(err);
          });
      }
    }
  };

  useEffect(() => {
    dispatch(SpaListAction({hotelId:id}));
    dispatch(CouponListAction({hotelId:id}));
  }, []);

  const handleDelete = () => {
    dispatch(RemoveSpaAction({ id: spaId ,hotelId:id})).then((res) => {
      if (res.data.success === true) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });

        setDelete(false);
        dispatch(SpaListAction({hotelId:id}));
      }
    });
  };

  const handleStatusChange = (item) => {
    let tempItem = item;

    tempItem["hotelId"] = id;

    if (item?.visibility == true) {
      tempItem.visibility = false;
    } else {
      tempItem.visibility = true;
    }

    dispatch(EditSpaAction(tempItem))
      .then((res) => {
        if (res.data.success === true) {
          dispatch(SpaListAction({hotelId:id}));

          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const handleSearch = (e) => {
    dispatch(SpaListAction({ search: e.target.value, hotelId:id }));
    setSearch(e.target.value);
  };

  const handleDiscountValidate = () => {
    let formIsValid = true;
    let discountError = {};
    if (!diamond.discount) {
      formIsValid = false;
      discountError.diamondDiscount = "* Please enter dicount.";
    } else if (diamond.discount < 0) {
      formIsValid = false;
      discountError.diamondValidDiscount = "* Discount not be negative.";
    } else if (diamond.discount > 100) {
      formIsValid = false;
      discountError.diamondValidDiscount = "* Discount not be valid.";
    } else if (
      (tempPrice / 100) * diamond.discount >
      couponList
        ?.filter((item) => item?.category == "diamond")
        ?.map((ele) => {
          return ele?.maxDiscountAmt;
        })?.[0]
    ) {
      formIsValid = false;
      discountError.diamondValidDiscount =
        "* Discount percentage entered is higher than the total discount amount on this package.!";
    }

    if (!gold.discount) {
      formIsValid = false;
      discountError.goldDiscount = "* Please enter dicount.";
    } else if (gold.discount < 0) {
      formIsValid = false;
      discountError.goldValidDiscount = "* Discount not be negative.";
    } else if (gold.discount > 100) {
      formIsValid = false;
      discountError.goldValidDiscount = "* Discount not be valid.";
    } else if (
      (tempPrice / 100) * gold.discount >
      couponList
        ?.filter((item) => item?.category == "gold")
        ?.map((ele) => {
          return ele?.maxDiscountAmt;
        })?.[0]
    ) {
      formIsValid = false;
      discountError.goldValidDiscount =
        "* Discount percentage entered is higher than the total discount amount on this package.!";
    }

    if (!silver.discount) {
      formIsValid = false;
      discountError.silverDiscount = "* Please enter dicount.";
    } else if (silver.discount < 0) {
      formIsValid = false;
      discountError.silverValidDiscount = "* Discount not be negative.";
    } else if (silver.discount > 100) {
      formIsValid = false;
      discountError.silverValidDiscount = "* Discount not be valid.";
    } else if (
      (tempPrice / 100) * silver.discount >
      couponList
        ?.filter((item) => item?.category == "silver")
        ?.map((ele) => {
          return ele?.maxDiscountAmt;
        })?.[0]
    ) {
      formIsValid = false;
      discountError.silverValidDiscount =
        "* Discount percentage entered is higher than the total discount amount on this package.!";
    }

    updateDiscountState({ ...iDiscountState, discountError: discountError });

    return formIsValid;
  };

  const handleChangeDiscount = (e) => {
    const { name, value } = e.target;
    const limit = 2;
    if (!/^[0-9]{0,10}$/.test(value) && value < 0) return;
    {
      setPercent(value.replace(/[^\d]/g, "").slice(0, limit));
    }

    updateDiscountState((prev) => ({
      ...prev,
      [name]: { ...name, discount: e.target.value },
    }));
  };

  const handleUpdateDiscount = (e) => {
    e.preventDefault();

    let formIsValid = handleDiscountValidate();
    setStopper(formIsValid);

    const hotelData = JSON.parse(localStorage.getItem("hotel"));
    let timeArray = duration?.split(":");

    if (formIsValid) {
      let data = {
        name,
        price,
        image,
        description,
        duration: hrs + ":" + mins,

        _id: spaId,
        diamond: {
          discount: diamond?.discount,
          price: tempPrice - (tempPrice / 100) * diamond.discount,
        },
        gold: {
          discount: gold?.discount,
          price: tempPrice - (tempPrice / 100) * gold.discount,
        },
        silver: {
          discount: silver?.discount,
          price: tempPrice - (tempPrice / 100) * silver.discount,
        },
        hotelId: hotelData?.hotelId?.[0]?._id,
      };

      dispatch(EditSpaAction(data))
        .then((res) => {
          if (res.data.success === true) {
            setStopper(false);
            setUpdateDiscount(false);
            dispatch(SpaListAction({hotelId:id}));

            toast.success("Discount updated successfully", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            setStopper(true);
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          alert(err);
        });
    }
  };

  const handleSpanTimeUpdate = (e) => {
    e.preventDefault();

    let formIsValid = handleSpanTimeValidation();
    setStopper(formIsValid);

    if (formIsValid) {
      var data = {
        openingTime: {
          start,
          end,
          hotelId:id
        },
      };
      dispatch(UpdateSpaTimeSpaAction(data)).then((res) => {
        if (res.status === 200) {
          setStopper(false);
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setAddTime(false);
          dispatch(SpaListAction({hotelId:id}));

          updateSpanTimeState({
            start: "",
            end: "",
            restaurantError: {},
          });
        }
      });
    }
  };

  const handleUpdateTax = () => {
    dispatch(SetSpaTaxAction({ taxType: "GST", percent: percent, hotelId:id })).then(
      (res) => {
        if (res.data.success === true) {
          dispatch(SpaListAction({hotelId:id}));

          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });

          setTax(false);
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    );
  };

  const handleChangePercentage = (e) => {
    const { value } = e.target;
    const limit = 2;
    if (!/^[0-9]{0,10}$/.test(value) && value < 0) return;
    {
      setPercent(value.replace(/[^\d]/g, "").slice(0, limit));
    }
  };

  useEffect(() => {
    console.log(spaList);
    if (spaList?.[0]?.data?.length == 0) {
      let openingTime = { start: "00", end: "00" };

      UpdateSpaTimeSpaAction({ openingTime: openingTime , hotelId:id});

      dispatch(SetSpaTaxAction({ taxType: "GST", percent: 0 , hotelId:id}));
    }
  }, [spaList]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    dispatch(SpaListAction({ page: pageNumber , hotelId:id}));
  };

  window.scrollTo(0, 0)
  return (
    <div>
      <Header />
      <Sidenav />

      <div class="WrapperArea">
        <div class="WrapperBox">
          <div class="HotelLocation HotelLocation2">
            <div class="back-btn">
              <Link to={`/hotel-details/${id}`}>
                <i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back{" "}
              </Link>
            </div>

            <aside>
              <h3>{hotelDetails?.name}</h3>
              <p>
                <span>
                  <i class="fa fa-map-marker"></i>
                </span>{" "}
                {hotelDetails?.address}
              </p>
            </aside>
          </div>
          <div class="NewTitleBox">
            <h4>Spa Management</h4>
            <a onClick={() => handleAddSpaModalShow("add")}>Add Item</a>
          </div>

          <div class="CommonLinks CommonLinksNew">
            <ul>
              <li class="active">
                <Link to={`/spa-msg/${id}`}>Message</Link>
              </li>
              <li class="active">
                <Link to={`/spa-order-request/${id}`}>Booking Requests</Link>
              </li>
            </ul>
            <div class="Filter">
              <div class="form-group">
                <label>Search</label>
                <input
                  type="text"
                  value={search}
                  onChange={handleSearch}
                  class="form-control"
                  placeholder="Search by spa"
                />
              </div>
            </div>
          </div>
          

          <div class="NewTitleBox"></div>

          {loader? <Loader />:

          <div class="Small-Wrapper">
            {spaList?.[0]?.data?.[0]?.items?.length > 0 && (
              <div class="TitleBox">
                <h6>
                  Opening time:{" "}
                  {spaList?.[0]?.data?.[0]?.openingTime?.start ? (
                    <b>
                      {moment(
                        spaList?.[0]?.data?.[0]?.openingTime?.start,
                        "HH:mm"
                      ).format("h:mm A")}{" "}
                      to{" "}
                      {moment(
                        spaList?.[0]?.data?.[0]?.openingTime?.end,
                        "HH:mm"
                      ).format("h:mm A")}
                      <span>
                        <a
                          onClick={() => {
                            handleAddTimeModalShow(
                              "edit",
                              spaList?.[0]?.data?.[0]?.openingTime
                            );
                          }}
                          class="Green"
                          data-toggle="modal"
                        >
                          <i class="fa fa-pencil"></i>
                        </a>
                      </span>
                    </b>
                  ) : (
                    <span>
                      <a
                        onClick={() =>
                          handleAddTimeModalShow(
                            "edit",
                            spaList?.[0]?.data?.[0]?.openingTime
                          )
                        }
                        class="Green"
                        data-toggle="modal"
                      >
                        <i class="fa fa-pencil"></i>
                      </a>
                    </span>
                  )}
                </h6>
                <h6>
                  <b>
                    <h6>
                      GST:{" "}
                      <b>
                        {spaList?.[0]?.data?.[0]?.tax?.percent}%
                        <span>
                          <a
                            class="Green"
                            onClick={() =>
                              handleEditTaxModalShow(
                                spaList?.[0]?.data?.[0]?.tax?.percent
                              )
                            }
                          >
                            <i class="fa fa-pencil"></i>
                          </a>
                        </span>
                      </b>
                    </h6>{" "}
                  </b>
                </h6>
              </div>
            )}
            <div class="MenuList">
              <ul>
                {spaList?.[0]?.data?.[0]?.items?.map((item) => (
                  <li>
                    <div class="MenuLeft">
                      <figure>
                         <ImageFetcher imageUrl={item?.image}/>
                      </figure>
                      <figcaption>
                        <h3>{item?.name}</h3>
                        <p>{item?.description}</p>
                        <p>
                          <span>
                            <img src={Star} alt="" />
                          </span>
                          {item?.rating}{" "}
                          <Link state={item} to="/spa-review-list">
                            {item?.totalReview} Reviews
                          </Link>
                        </p>
                        <h4>
                          <span>
                            Duration Time:{" "}
                            {item?.duration?.split(":")?.[0] == "00"
                              ? ""
                              : `${item?.duration?.split(":")?.[0]} Hrs`}{" "}
                            {item?.duration?.split(":")?.[1]} Mins
                          </span>
                        </h4>
                      </figcaption>
                      {couponList?.length != 0 && (
                        <figcaption className="food-bav-content">
                          {item?.diamond?.discount && (
                            <h4>
                              Diamond Service Packages (
                              {item?.diamond?.discount}%) {item?.diamond?.price}
                            </h4>
                          )}
                          {item?.gold?.discount && (
                            <h4>
                              Gold Service Packages ({item?.gold?.discount}%){" "}
                              {item?.gold?.price}
                            </h4>
                          )}
                          {item?.silver?.discount && (
                            <h4>
                              Silver Service Packages ({item?.silver?.discount}
                              %) {item?.silver?.price}
                            </h4>
                          )}

                          <a
                            class="Green food-bav2"
                            onClick={() => handleUpdateDiscountModalShow(item)}
                          >
                            <i class="fa fa-pencil"></i>
                          </a>
                        </figcaption>
                      )}
                    </div>
                    <div class="MenuRight">
                      <h5>
                        {item?.price} INR <span>+Taxes</span>
                      </h5>
                      <label class="switch">
                        <input
                          type="checkbox"
                          checked={item?.visibility}
                          value={item}
                          onChange={(e) => handleStatusChange(item)}
                        />
                        <span class="slider"></span>
                      </label>
                      <a
                        class="Green"
                        onClick={() => handleAddSpaModalShow("edit", item)}
                      >
                        <i class="fa fa-pencil"></i>
                      </a>
                      <a
                        class="Red"
                        onClick={() => handleDeleteModalShow(item?._id)}
                      >
                        <i class="fa fa-trash"></i>
                      </a>
                    </div>
                  </li>
                ))}
              </ul>
              {loader ? (
                ""
              ) : spaList?.[0]?.data?.[0]?.items?.length > 0 ? (
                ""
              ) : (
                <NoDataFound />
              )}
            </div>
            {spaList?.[0]?.data?.[0]?.items?.length > 0 ? (
              <div className="Pagination">
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={spaList?.[0]?.metadata?.[0]?.total}
                  pageRangeDisplayed={5}
                  onChange={(e) => handlePageChange(e)}
                />
              </div>
            ) : (
              ""
            )}
          </div>}
        </div>
        </div>

      <Modal
        show={AddSpaModal}
        onHide={handleAddSpaModalClose}
        className="ModalBox "
      >
        <Modal.Body>
          <div class="Category">
            <a onClick={handleAddSpaModalClose} class="CloseModal">
              ×
            </a>
            {Type == "edit" ? <h3>Update Spa</h3> : <h3>Add Item</h3>}
            <div class="form-group">
              <label></label>
              <div class="UploadBox">
                <div class="Upload">
                  <i class="fa fa-upload"></i> <span>Upload Icon</span>
                  <input
                    type="file"
                    onClick={(e) => (e.target.value = null)}
                    onChange={(e) => handleUpload(e)}
                    class="form-control"
                  />
                </div>
                {image && (
                  <div className="UploadIcon">
                    <img src={image} alt="image" />
                  </div>
                )}
              </div>
              {errorMsg.image && (
                <p style={{ color: "red" }}>{errorMsg.image}</p>
              )}
            </div>
            <div class="form-group">
              <label>Name</label>
              <input
                value={name}
                name="name"
                onChange={handleChange}
                class="form-control"
              />
              {errorMsg.name && !name && (
                <p style={{ color: "red" }}>{errorMsg.name}</p>
              )}
            </div>

            <div class="form-group">
              <label>Duration</label>
              {/* <Box mt={2} aria-labelledby="timePickerLabel">
                <TimePicker
                  is12Hour={false}
                  value={duration}
                  onChange={(e) => updateState({ ...iState, duration: e })}
                >
                  <TimePicker.Hours />
                  <TimePicker.Separator />
                  <TimePicker.Minutes />
                </TimePicker>
              </Box>
              <div className="TimePicker">
                <span> HH :</span>
                <span> MM</span>
              </div> */}
                     <div className="Duration">
                <select value={hrs}  name="hrs" onChange={handleChange}>
                  <option value={"00"}>00</option>
                  {hoursList()?.map((item) => (
                    <option value={item}>{item}</option>
                  ))}
                </select>
                <span>:</span>
                <select value={mins}  name="mins" onChange={handleChange}>
                  <option value={"00"}>00</option>
                  {minutesList()?.map((item) => (
                    <option value={item}>{item}</option>
                  ))}
                </select>
              </div> 
              <div className="TimePicker">
                <span>HH</span>
                <span className="padding_none">:</span>
                <span>MM</span>
              </div>

              {errorMsg.duration && !duration && (
                <p style={{ color: "red" }}>{errorMsg.duration}</p>
              )}
            </div>
            <div class="form-group">
              <label> Price</label>
              <input
                value={price}
                name="price"
                onChange={handleChange}
                type="text"
                class="form-control"
              />
              {errorMsg.price && !price && (
                <p style={{ color: "red" }}>{errorMsg.price}</p>
              )}
            </div>
            <div class="form-group">
              <label>Description</label>
              <textarea
                rows="3"
                value={description}
                name="description"
                onChange={handleChange}
                class="form-control"
              ></textarea>
              {errorMsg.description && !description && (
                <p style={{ color: "red" }}>{errorMsg.description}</p>
              )}
            </div>
            {Type == "edit" ? (
              <button class="Button" onClick={handleUpdate}>
                Update
              </button>
            ) : (
              <button class="Button" onClick={handleSubmit}>
                Add Item
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={DeleteModal}
        onHide={handleDeleteModalClose}
        className="ModalBox "
      >
        <Modal.Body>
          <div className="Decline">
            <button className="CloseModal" onClick={handleDeleteModalClose}>
              ×
            </button>
            <h3>Delete</h3>
            <p>Are you sure you want to delete this Spa ?</p>
            <h4>
              <a onClick={handleDeleteModalClose} className="Cancel">
                Cancel
              </a>
              <a onClick={handleDelete}>Delete</a>
            </h4>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={updateDiscountModal}
        onHide={handleUpdateDiscountModalClose}
        className="ModalBox "
      >
        <Modal.Body>
          <div class="Category">
            <a onClick={handleUpdateDiscountModalClose} class="CloseModal">
              ×
            </a>
            <h3>Update Discount</h3>

            <div class="discount-package">
              <h2>Diamond Service Package</h2>
              <div class="row">
                <div className="col-12">
                  <div className="form-group">
                    <label>Discount %</label>

                    <input
                      value={diamond?.discount}
                      onChange={handleChangeDiscount}
                      type="text"
                      name="diamond"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>

              {discountError?.diamondDiscount && !diamond?.discount && (
                <p style={{ color: "red" }}>{discountError?.diamondDiscount}</p>
              )}

              {discountError?.diamondValidDiscount && (
                <p style={{ color: "red" }}>
                  {discountError?.diamondValidDiscount}
                </p>
              )}
            </div>

            <div class="discount-package">
              <h2>Gold Service Package</h2>
              <div class="row">
                <div className="col-12">
                  <div className="form-group">
                    <label>Discount %</label>

                    <input
                      value={gold?.discount}
                      onChange={handleChangeDiscount}
                      name="gold"
                      type="number"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>

              {discountError?.goldDiscount && !gold?.discount && (
                <p style={{ color: "red" }}>{discountError?.goldDiscount}</p>
              )}
              {discountError?.goldValidDiscount && (
                <p style={{ color: "red" }}>
                  {discountError?.goldValidDiscount}
                </p>
              )}
            </div>

            <div class="discount-package">
              <h2>Silver Service Package</h2>
              <div class="row">
                <div className="col-12">
                  <div className="form-group">
                    <label>Discount %</label>

                    <input
                      value={silver?.discount}
                      onChange={handleChangeDiscount}
                      type="number"
                      name="silver"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>

              {discountError?.silverDiscount && !silver?.discount && (
                <p style={{ color: "red" }}>{discountError?.silverDiscount}</p>
              )}
              {discountError?.silverValidDiscount && (
                <p style={{ color: "red" }}>
                  {discountError?.silverValidDiscount}
                </p>
              )}
            </div>

            <button class="Button" onClick={handleUpdateDiscount}>
              Update
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={AddTimeModal}
        onHide={handeAddTimeModalClose}
        className="ModalBox "
      >
        <Modal.Body>
          <div class="Category">
            <a className="CloseModal" onClick={handeAddTimeModalClose}>
              ×
            </a>
            {Type == "edit" ? (
              <h3>Update Spa Timing</h3>
            ) : (
              <h3>Add Spa Timing</h3>
            )}

            <div class="form-group">
              <div className="RoomBox">
                <label>From</label>

                <input
                  type="time"
                  class="form-control"
                  value={start}
                  name="start"
                  onChange={handleSpanTimeChange}
                />
                {restaurantError.start && !start && (
                  <p style={{ color: "red" }}>{restaurantError.start}</p>
                )}

                <label>To</label>

                <input
                  type="time"
                  min={start}
                  class="form-control"
                  value={end}
                  name="end"
                  onChange={handleSpanTimeChange}
                />
                {restaurantError.end && !end && (
                  <p style={{ color: "red" }}>{restaurantError.end}</p>
                )}
              </div>
            </div>

            {Type == "edit" ? (
              <button class="Button" onClick={handleSpanTimeUpdate}>
                Update
              </button>
            ) : (
              <button
                class="Button"
                data-dismiss="modal"
                onClick={handleSpanTimeUpdate}
              >
                Add
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={EditTaxModal}
        onHide={handleEditTaxModalClose}
        className="ModalBox "
      >
        <Modal.Body>
          <div class="Category">
            <a className="CloseModal" onClick={handleEditTaxModalClose}>
              ×
            </a>
            <h3>Update Tax</h3>

            <div class="form-group">
              <div className="RoomBox">
                <label>Percentage</label>

                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter GST %"
                  value={percent}
                  name="start"
                  onChange={handleChangePercentage}
                />
              </div>
            </div>

            <button
              disabled={percent == "" ? true : false}
              class="Button"
              onClick={handleUpdateTax}
            >
              Update
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SpaMananagement;
