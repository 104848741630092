import React, { useState } from "react";
import Header from "./header";
import Sidenav from "./sidenav";

import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from '../axiosInstance';
import TimePicker from "@semcore/time-picker";
import { Box } from "@semcore/ui/flex-box";
import Star from '../assets/image/star.svg'
import NoDataFound from "./noDataFound";
import Pagination from "react-js-pagination";
import ImageFetcher from './ImageFetcher'
import { minutesList,hoursList } from "../utils";
import {AXIOS_APIKEY } from "../config/config";


import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  AddHousekeepingAction,
  HousekeepingListAction,
  EditHousekeepingAction,
  RemoveHousekeepingAction,
} from "../redux/actions/housekeepingAction";
import Loader from "./loader";

const inititalState = {
  name: "",
  image: "",
  deliveryTime: "",
  price: "",
  description: "",
  hrs:"00",
  mins:"00",


  errorMsg: {},
};


const HousekeepingMananagement = () => {
  const {id} = useParams();
  const hotelDetails =  JSON.parse(window.localStorage.getItem("hotelDetails"))

  const[index, setIndex]=useState('')
  const [stoper, setStopper] = useState(false);
  const [uploadImage, setuploadImage] = useState(null);
  const [ShowAddHousekeepingButton, setShowAddHousekeepingButton] = useState(true);
  const [HouseKeepingItems, setHouseKeepingItems] = useState([
    {
      name: "MakeMyRoom",
      image: "https://bms-assets-storage-bucket.s3.ap-south-1.amazonaws.com/makeMyRoom.png",
    },
    {
      name: "MakeMyBed",
      image: "https://bms-assets-storage-bucket.s3.ap-south-1.amazonaws.com/makeMyBed.png",
    },
    {
      name: "MakeMyBathroom",
      image: "https://bms-assets-storage-bucket.s3.ap-south-1.amazonaws.com/makeMyBathroom.png",
    },
    {
      name: "FloorClean",
      image: "https://bms-assets-storage-bucket.s3.ap-south-1.amazonaws.com/floorClean.png",
    },
    {
      name: "RO-Water",
      image: "https://bms-assets-storage-bucket.s3.ap-south-1.amazonaws.com/RO-Water.png",
    },
    {
      name: "Mineral Water",
      image: "https://bms-assets-storage-bucket.s3.ap-south-1.amazonaws.com/mineralWater.png",
    },
    {
      name: "Towels",
      image: "https://bms-assets-storage-bucket.s3.ap-south-1.amazonaws.com/towels.png",
    },
    {
      name: "Shampoo",
      image: "https://bms-assets-storage-bucket.s3.ap-south-1.amazonaws.com/shampoo.png",
    },
    {
      name: "Shower Cap",
      image: "https://bms-assets-storage-bucket.s3.ap-south-1.amazonaws.com/showerCap.png",
    },
    {
      name: "Shower Gel",
      image: "https://bms-assets-storage-bucket.s3.ap-south-1.amazonaws.com/showerGel.png",
    },
    { name: "Soap", 
      image: "https://bms-assets-storage-bucket.s3.ap-south-1.amazonaws.com/soap.png" 
    },
    {
      name: "Dental kit",
      image: "https://bms-assets-storage-bucket.s3.ap-south-1.amazonaws.com/dentalKit.png",
    },
    {
      name: "Shaving kit",
      image: "https://bms-assets-storage-bucket.s3.ap-south-1.amazonaws.com/shavingKit.png",
    },
    { name: "Tea bags",
      image: "https://bms-assets-storage-bucket.s3.ap-south-1.amazonaws.com/tea.png" 
    },
    {
      name: "Coffee bags",
      image: "https://bms-assets-storage-bucket.s3.ap-south-1.amazonaws.com/coffee.png",
    },
    {
      name: "Sugar sachets",
      image: "https://bms-assets-storage-bucket.s3.ap-south-1.amazonaws.com/showerGel.png",
    },
    {
      name: "Appliance Fix",
      image: "https://bms-assets-storage-bucket.s3.ap-south-1.amazonaws.com/applianceFix.png",
    },
    {
      name: "Plumbing Fix",
      image: "https://bms-assets-storage-bucket.s3.ap-south-1.amazonaws.com/plumbingFix.png",
    },
  ]);

  const initialHousekeepingList = [
 
    {
      name: "MakeMyRoom",
      image: "https://bms-assets-storage-bucket.s3.ap-south-1.amazonaws.com/makeMyRoom.png",
    },
    {
      name: "MakeMyBed",
      image: "https://bms-assets-storage-bucket.s3.ap-south-1.amazonaws.com/makeMyBed.png",
    },
    {
      name: "MakeMyBathroom",
      image: "https://bms-assets-storage-bucket.s3.ap-south-1.amazonaws.com/makeMyBathroom.png",
    },
    {
      name: "FloorClean",
      image: "https://bms-assets-storage-bucket.s3.ap-south-1.amazonaws.com/floorClean.png",
    },
    {
      name: "RO-Water",
      image: "https://bms-assets-storage-bucket.s3.ap-south-1.amazonaws.com/RO-Water.png",
    },
    {
      name: "Mineral Water",
      image: "https://bms-assets-storage-bucket.s3.ap-south-1.amazonaws.com/mineralWater.png",
    },
    {
      name: "Towels",
      image: "https://bms-assets-storage-bucket.s3.ap-south-1.amazonaws.com/towels.png",
    },
    {
      name: "Shampoo",
      image: "https://bms-assets-storage-bucket.s3.ap-south-1.amazonaws.com/shampoo.png",
    },
    {
      name: "Shower Cap",
      image: "https://bms-assets-storage-bucket.s3.ap-south-1.amazonaws.com/showerCap.png",
    },
    {
      name: "Shower Gel",
      image: "https://bms-assets-storage-bucket.s3.ap-south-1.amazonaws.com/showerGel.png",
    },
    { 
      name: "Soap", 
      image: "https://bms-assets-storage-bucket.s3.ap-south-1.amazonaws.com/soap.png" 
    },
    {
      name: "Dental kit",
      image: "https://bms-assets-storage-bucket.s3.ap-south-1.amazonaws.com/dentalKit.png",
    },
    {
      name: "Shaving kit",
      image: "https://bms-assets-storage-bucket.s3.ap-south-1.amazonaws.com/shavingKit.png",
    },
    { 
      name: "Tea bags", 
      image: "https://bms-assets-storage-bucket.s3.ap-south-1.amazonaws.com/tea.png"
    },
    {
      name: "Coffee bags",
      image: "https://bms-assets-storage-bucket.s3.ap-south-1.amazonaws.com/coffee.png",
    },
    {
      name: "Sugar sachets",
      image: "https://bms-assets-storage-bucket.s3.ap-south-1.amazonaws.com/sugarSachet.png",
    },
    {
      name: "Appliance Fix",
      image: "https://bms-assets-storage-bucket.s3.ap-south-1.amazonaws.com/applianceFix.png",
    },
    {
      name: "Plumbing Fix",
      image: "https://bms-assets-storage-bucket.s3.ap-south-1.amazonaws.com/plumbingFix.png",
    },
  
  ];

  const [iState, updateState] = useState(inititalState);
  const [housekeepingId, setHouseKeepingId] = useState("");
  const [activePage, setActivePage] = useState(1);

  const [Type, setType] = useState("add");
  const [search, setSearch] = useState("");

  const dispatch = useDispatch();

  const [DeleteModal, setDelete] = useState(false);
  const handleDeleteModalClose = () => setDelete(false);
  const handleDeleteModalShow = (id) => {
    setHouseKeepingId(id);
    setDelete(true);
  };

  const { housekeepingList,loader } = useSelector(
    (state) => state.housekeepingReducer
  );

  const { name, image, deliveryTime, errorMsg , hrs,mins} = iState;

  const [AddHouseKeepingModal, setAddHouseKeeping] = useState(false);
  const handleAddHouseKeepingModalClose = () => {
    updateState({
      ...iState,
      name: "",
      image: "",
      deliveryTime: "",
      hrs:"00",
      mins:"00",
      errorMsg: {},
    });
    setAddHouseKeeping(false);
  };
  const handleAddHouseKeepingModalShow = (type, ele) => {
    if (type == "edit") {
      setType(type);
      setHouseKeepingId(ele?._id);

      updateState({
        ...iState,
        name: ele?.name,
        image: ele?.image,
        hrs: ele?.deliveryTime?.split(":")?.[0],
        mins: ele?.deliveryTime?.split(":")?.[1],

      });
      setAddHouseKeeping(true);
    } else {
      setAddHouseKeeping(true);
      setType(type);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if(name =="hrs" || name=="mins"){
      updateState(prev=>({...prev, [name]:value}))



    }
    else{
      HouseKeepingItems?.map((item,i)=>{
        if(item?.name ==value){
          setIndex(i)
  
          updateState(prev=>({...prev ,image:item?.image, name:value}))
        }
        
      })
    }

    

  };

  window.Buffer = window.Buffer || require("buffer").Buffer;

  const handleUpload = (e) => {
    const file = e.target.files[0];

    if (file.type == "application/pdf") {
      toast.error("Only jpeg, jpg and png are allowed ", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    if (file) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        //Initiate the JavaScript Image object.
        var image = new Image();

        //Set the Base64 string return from FileReader as source.
        image.src = e.target.result;

        //Validate the File Height and Width.
        image.onload = function () {
          var height = this.height;
          var width = this.width;
          if (
            file.type === "image/jpeg" ||
            file.type === "image/jpg" ||
            file.type === "image/png"
          ) {
            const formData = new FormData();
            formData.append('file', file);
            let accessToken = JSON.parse(localStorage.getItem("hotelDetails")).jwtToken;
            axiosInstance.post('/upload?type=hotel', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Authorization'.concat(" ",accessToken),
              }
            })
            .then(response => {
              console.log(response);
            
              if (response.status !== 200) {
                throw new Error(`Server error: ${response.statusText}`);
              }
            
              return response;
            })
            .then(data => {
              if (data.error) {
                toast.error(data.error, { position: toast.POSITION.TOP_RIGHT });
              } else {
                const tempData = data.data.url;
                updateState({ ...iState, image: tempData });
                setuploadImage(tempData)
              toast.success("Upload successfully", { position: toast.POSITION.TOP_RIGHT });
              }
            })
            .catch(error => {
              console.error(error);
              toast.error("Upload failed ", { position: toast.POSITION.TOP_RIGHT });
            });
            } else {
            toast.error("Only jpeg, jpg and png are allowed ", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        };
      };
    }
  };

  const handleValidate = () => {
    let formIsValid = true;
    let errorMsg = {};
    if (!name) {
      formIsValid = false;
      errorMsg.name = "* Please enter name.";
    }
    if (!image) {
      formIsValid = false;
      errorMsg.image = "* Please upload  image.";
    }

    if (hrs=="00" && mins =="00") {
      formIsValid = false;
      errorMsg.deliveryTime = "* Please enter delivery time.";
    }

    updateState({ ...iState, errorMsg: errorMsg });

    return formIsValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formIsValid = handleValidate();
    setStopper(formIsValid);
    if (formIsValid) {

      let data = {
        name,
        image,
        hotelId: id,
        deliveryTime:
          hrs +
          ":" +
          mins,
      };

      dispatch(AddHousekeepingAction(data))
        .then((res) => {
          if (res.status >= 200 && res.status <= 300) {
            setStopper(false);
            setAddHouseKeeping(false);
            dispatch(HousekeepingListAction({hotelId:id}));
            setHouseKeepingItems((prevItems) =>
              prevItems.filter((item) => item.name !== name)
            );
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            updateState({ name: "", image: "",hrs:"00",mins:"00", errorMsg: {} });
          } else {
            setStopper(false);
            toast.error(res.response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          alert(err);
        });
    }
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    let formIsValid = handleValidate();
    const hotelData = JSON.parse(localStorage.getItem("hotelDetails"));
    setStopper(formIsValid);

    if (formIsValid) {

      let data = {
        _id: housekeepingId,
        name,
        image,
        deliveryTime:
          hrs+
          ":" +
          mins,
        hotelId: id,
      };

      dispatch(EditHousekeepingAction(data))
        .then((res) => {
          if (res.status >= 200 && res.status <= 300) {
            setStopper(false);
            setAddHouseKeeping(false);
            dispatch(HousekeepingListAction({hotelId:id}));
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            updateState({ name: "", image: "",hrs:"00",mins:"00", errorMsg: {} });
          } else {
            setStopper(false);
            toast.error(res.response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          alert(err);
        });
    }
  };

  useEffect(() => {
    dispatch(HousekeepingListAction({hotelId:id}));
  }, []);

  useEffect(() => {
    const hasItems = HouseKeepingItems?.length > 0;
    setShowAddHousekeepingButton(hasItems);

  }, [HouseKeepingItems]);

  useEffect(() => {
    const fetchData = async () => {
      let filtered;
      try {
        const token = JSON.parse(localStorage.getItem("hotelDetails")).jwtToken;
        const response = await axiosInstance.get('hotel/housekeep/get', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
            'x-api-key': AXIOS_APIKEY,
          },
        });
        const res= response.data.data[0];
        filtered = initialHousekeepingList.filter(item =>
          !res.data[0]?.items.some(responseItem => responseItem.name === item.name)
        );

        console.log(filtered)

      } catch (error) {
        console.error('Error fetching data', error);
        return initialHousekeepingList;
      }
      setHouseKeepingItems(filtered);
    };
    fetchData();
  }, []);

  const handleDelete = async () => {
    let housekeepname;
    try {
      const accessToken = JSON.parse(localStorage.getItem("hotel")).jwtToken;
      const response = await axiosInstance.get('hotel/housekeep/single/get?id=' + housekeepingId, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': accessToken,
          'x-api-key': AXIOS_APIKEY,
        },
      });

      if (response.status === 200) {
        housekeepname = response.data.data.itemName;
        console.log(housekeepname);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    dispatch(RemoveHousekeepingAction({ id: housekeepingId , hotelId:id})).then((res) => {
      if (res.status >= 200 && res.status <= 300) {
        const deletedItem = initialHousekeepingList.find(item => item.name === housekeepname);
      if (deletedItem) {
        setHouseKeepingItems(prevItems => [...prevItems, deletedItem]);
      }
        setDelete(false);
        dispatch(HousekeepingListAction({hotelId:id}));
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,  
        });
      }
    });
  };


  const handleStatusChange = (item) => {
    if (item?.visibility == true) {
      item.visibility = false;
      item["hotelId"]= id
    } else {
      item.visibility = true;
      item["hotelId"]= id


    }
    dispatch(EditHousekeepingAction(item))
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          dispatch(HousekeepingListAction({hotelId:id}));

          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const handleSearch = (e) => {
    dispatch(HousekeepingListAction({ search: e.target.value, hotelId:id }));
    setSearch(e.target.value);
  };


  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    dispatch(HousekeepingListAction({ page: pageNumber, hotelId:id }));


  };



  window.scrollTo(0, 0)




  return (
    <div>
      <Header />
      <Sidenav />

      <div class="WrapperArea">
        <div class="WrapperBox">
        <div class="NewTitleBox">
            <h4>Housekeeping Management</h4>
           
           
           
            {ShowAddHousekeepingButton && (
               <a onClick={() => handleAddHouseKeepingModalShow("add")}>
              Add HouseKeeping
            </a>
            )}

          </div>
          <div class="HotelLocation HotelLocation2">
            <div class="back-btn">
              <Link to={`/hotel-details/${id}`}>
                <i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back{" "}
              </Link>
            </div>

            <aside>
              <h3>{hotelDetails?.name}</h3>
              <p>
                <span>
                  <i class="fa fa-map-marker"></i>
                </span>{" "}
                {hotelDetails?.address}
              </p>
            </aside>
          </div>
          

          <div class="Small-Wrapper">
            <div class="CommonLinks CommonLinksNew">
              <ul>
                <li class="active">
                  <Link to={`/housekeeping-msg/${id}`}>Message</Link>
                </li>
                <li class="active">
                  <Link to={`/housekeeping-order-request/${id}`}>Order Requests</Link>
                </li>
              </ul>
              <div class="Filter">
                <div class="form-group">
                  <label>Search</label>
                  <input
                    type="text"
                    value={search}
                    onChange={handleSearch}
                    class="form-control"
                    placeholder="Search by housekeeping"
                  />
                </div>
              </div>
            </div>

            {loader? <Loader />:


            <div class="AmenitiesArea">
              <ul>
                {housekeepingList?.[0]?.data?.[0]?.items?.map((item) => (
                  <li>
                    <div class="AmenitiesBox">
                      <aside>
                        <label class="switch">
                          <input
                            type="checkbox"
                            checked={item?.visibility}
                            value={item}
                            onChange={(e) => handleStatusChange(item)}
                          />
                          <span class="slider"></span>
                        </label>
                        <a
                          class="Green"
                          onClick={() =>
                            handleAddHouseKeepingModalShow("edit", item)
                          }
                        >
                          <i class="fa fa-pencil"></i>
                        </a>
                        <a
                          class="Red"
                          onClick={() =>handleDeleteModalShow(item?._id)}
                        >
                          <i class="fa fa-trash"></i>
                        </a>
                      </aside>
                      <figure>
                      <ImageFetcher imageUrl={item?.image}/>
                      </figure>
                      <figcaption>
                        <h6>{item?.name}</h6>
                        <p>
                          <span>
                            <img src={Star} alt="" />
                          </span>
                          {item?.rating}
                          {" "}
                          <Link state={item} to="/housekeeping-review-list">{item?.totalReview} Reviews</Link>
                        </p>
                        <p>
                        <span>Response time : {(item?.deliveryTime?.split(':')?.[0] =="00")?"": `${item?.deliveryTime?.split(':')?.[0]} Hrs` }   {item?.deliveryTime?.split(':')?.[1]} Mins</span>

                        </p>
                      </figcaption>
                    </div>
                  </li>
                ))}
              </ul>
              {loader ? (
            ""
          ) : housekeepingList?.[0]?.metadata?.[0]?.total > 0 ? (
            ""
          ) : (
            <NoDataFound />
          )}

            </div>}
            {housekeepingList?.[0]?.metadata?.[0]?.total > 0 ? (
            <div className="Pagination">
              <Pagination
                activePage={activePage}
                itemsCountPerPage={10}
                totalItemsCount={housekeepingList?.[0]?.metadata?.[0]?.total}
                pageRangeDisplayed={5}
                onChange={(e) => handlePageChange(e)}
              />
            </div>
          ) : (
            ""
          )}

          </div>
        </div>
      </div>

      <Modal
        show={AddHouseKeepingModal}
        onHide={handleAddHouseKeepingModalClose}
        className="ModalBox "
      >
        <Modal.Body>
          <div class="Category">
            <a onClick={handleAddHouseKeepingModalClose} class="CloseModal">
              ×
            </a>
            {Type == "edit" ? <h3>Update HouseKeeping</h3> : <h3>Add Item</h3>}



            {Type == "edit" ? <div class="form-group">
              <label>Name</label>

              <select
                className="form-control"
                name="name"
                onChange={handleChange}
                value={name}
                disabled
              >
                <option disabled>Select </option>
                {initialHousekeepingList?.map((item) => (
                  <option value={item?.name} disabled>{item?.name}</option>
                ))}
              </select>
              {errorMsg.name && !name && (
                <p style={{ color: "red" }}>{errorMsg.name}</p>
              )}
            </div> : 
            <div class="form-group">
              <label>Name</label>

              <select
                className="form-control"
                name="name"
                onChange={handleChange}
                value={name}
              >
                <option>Select</option>
                {HouseKeepingItems?.map((item) => (
                  <option value={item?.name}>{item?.name}</option>
                ))}
              </select>
              {errorMsg.name && !name && (
                <p style={{ color: "red" }}>{errorMsg.name}</p>
              )}
            </div>  }

            <div class="form-group">
              <label></label>
              <div class="UploadBox">
                {Type =="edit" &&
                <div class="Upload">
                  <i class="fa fa-upload"></i> <span>Upload Icon</span>
                  <input
                    type="file"
                    onClick={(e) => (e.target.value = null)}
                    onChange={(e) => handleUpload(e)}
                    class="form-control"
                  />
                </div>}
                {image && (
                  <div className="UploadIcon">
                    <ImageFetcher imageUrl={image}/>
                  </div>
                )}
              </div>
              {errorMsg.image &&  !image &&(
                <p style={{ color: "red" }}>{errorMsg.image}</p>
              )}
            </div>

            <div class="form-group">
              <label>Estimated Time of arriving</label>
              <div className="Duration">
                <select value={hrs}  name="hrs" onChange={handleChange}>
                  <option value={"00"}>00</option>
                  {hoursList()?.map((item) => (
                    <option value={item}>{item}</option>
                  ))}
                </select>
                <span>:</span>
                <select value={mins}  name="mins" onChange={handleChange}>
                  <option value={"00"}>00</option>
                  {minutesList()?.map((item) => (
                    <option value={item}>{item}</option>
                  ))}
                </select>
              </div> 
              <div className="TimePicker">
                <span>HH</span>
                <span className="padding_none">:</span>
                <span>MM</span>
              </div>

              {errorMsg.deliveryTime &&  !deliveryTime && (
                <p style={{ color: "red" }}>{errorMsg.deliveryTime}</p>
              )}
            </div>

            {Type == "edit" ? (
              <button   disabled={stoper}  class="Button" onClick={handleUpdate}>
                Update
              </button>
            ) : (
              <button  disabled={stoper}  class="Button" onClick={handleSubmit}>
                Add Item
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={DeleteModal}
        onHide={handleDeleteModalClose}
        className="ModalBox "
      >
        <Modal.Body>
          <div className="Decline">
            <button className="CloseModal" onClick={handleDeleteModalClose}>
              ×
            </button>
            <h3>Delete</h3>
            <p>Are you sure you want to delete this HouseKeeping ?</p>
            <h4>
              <a onClick={handleDeleteModalClose} className="Cancel">
                Cancel
              </a>
              <a onClick={handleDelete}>Delete</a>
            </h4>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default HousekeepingMananagement;
