
import { Route, HashRouter,  BrowserRouter as Router, Routes ,Navigate,Outlet} from 'react-router-dom'
import { isLoggedIn } from './utils';

import React, { useEffect , useRef} from "react";
import "./App.css";
import { ToastContainer, toast, Slide } from "react-toastify";
import { socket } from './config/sckt';

// import Settings from './component/settings';
import Login from './component/login';
import Forgot from './component/forgot'
import Otp from './component/otp';
import ChangePassword from './component/change-password';
import Congratulation from './component/congratulation';
import Dashboard from './component/dashboard';
import ContentManagement from './component/Content-management';
import EditAbouts from './component/editAboutus';
import EditPolicy from './component/editPolicy';
import EditTermsCondition from './component/editTermsCondition';
import Settings from './component/settings';
import SubscriptionManagement from './component/subscription-management';
import SubscriptionHistory from './component/subscription-history';

import CancelSubscription from './component/cancel-subscription';
import ActiveSubscription from './component/active-subscription';
import NotificationManagement from './component/notification-management';
import FinanceManagement from './component/finance-management';
import ServicePackage from './component/service-package';
import Analytics from './component/analytics';
import HotelManagement from './component/hotel-management';
import HotelDetails from './component/hotel-details';
import Notification from './component/notification';



import GuestDetails from './component/guests-details';




import RestaurantFood from './component/restaurant-food';
import RestaurantOrder from './component/restaurant-order';
import RestaurantChatHistory from './component/restaurant-chat-history';
import ResaurantBeverage from './component/resturant-beverage';
import RestaurantMsg from './component/restaurant-msg';
import RestaurantChat from './component/restaurant-chat';
import RestaurantChatPer from './component/restaurant-chat-per';


import SpaMananagement from './component/spa-mananagement';
import SpaOrderRequest from './component/spa-order-request';
import SpaMsg from './component/spa-msg';
import HousekeepingManagement from './component/housekeeping-management';
import HousekeepingOrderRequest from './component/housekeeping-order-request';
import HousekeepingMsg from './component/housekeeping-msg';
import HousekeepingChat from './component/housekeeping-chat';
import HousekeepingChatPer from './component/housekeeping-chat-per';
import HousekeepingChatHistory from './component/housekeeping-chat-history';
import HousekeepingOrderDetails from './component/housekeeping-order-details'

import HotelRoom from './component/hotel-room';
import RoomMinBar from './component/room-min-bar';
import HotelWifi from './component/hotel-wifi';
import HotelActivities from './component/hotel-activities';
import HotelCoupons from './component/hotel-coupons';
import HotelCouponsUse from "./component/hotel-coupon-use"
import HotelTrending from './component/hotel-trending';
import TrendingAdd from './component/trending-add';
import RoleDetails from './component/role-details';
import RolesManagement from './component/roles-management';
import RoleAdd  from './component/role-add';
import RoleEdit from './component/role-edit';
import GetNotifications from './component/get-notifications';
import GuestsManagement from './component/guests-management';
import GuestsNotifications from './component/guests-notifications';
import GuestsManual from './component/guests-manual';
import GuestsManualVerification from './component/guests-manual-verification';
import GuestsVerification from './component/guests-verification';
import GuestsInvited from './component/guests-invited';
import GuestsCheckedIn from './component/guests-checked-in';
import Guests from './component/guests';

import GuestsExtendedCheckout from './component/guests-extended-checkout';
import GuestsApproveExtendedCheckout from './component/guests-approve-extendCheckout';

import GuestsCheckoutApprovedMinibar from './component/guests-checkout-approved-minibar';
import GuestsCheckoutRequest from './component/guests-checkout-request';
import GuestsCheckoutApprove from './component/guests-checkout-approve';
import GuestsCheckoutCompleted from './component/guest-checkout-completed';
import GuestsBillingPayment from './component/guests-billing-payment';
import GuestsBillingPaymentView from './component/guests-billing-payment-view';



import RestaurantProfile from './component/restaurant-profile';

import RestaurantOrderAdd from './component/restaurant-order-add';
import RestaurantOrderStatus from './component/restaurant-order-status';
import RestaurantOrderDetails from './component/restaurant-order-details';
import SpaOrderAdd from './component/spa-order-add';
import SpaOrderStatus from './component/spa-order-status';
import SpaOrderDetails from './component/spa-order-details';
import SpaChat from './component/spa-chat';
import SpaChatPer from './component/spa-chat-per';
import SpaChatHistory from './component/spa-chat-history';



import FoodReviewList from './component/food-review-list';
import HotelReviews from './component/hotel-review';
import RestaurantBeverage from './component/resturant-beverage';
import HotelTransaction from "./component/hotel-transaction";
import HotelTransactionDetails from "./component/hotel-transaction-details";
import GuestWalletManagement from './component/guest-wallet-management';
import HotelUpdate from './component/hotelUpdate';
import GuestDeletionRequest from './component/GuestDeletionRequest';
import CancelRequest from './component/CancelRequest';
import HotelDeletionRequest from './component/HotelDeletionRequest';
import HotelCancelRequest from './component/HotelCancelRequest';

import Subscription from './component/subscription';
import HotelSubscription from './component/subscription-hotel';
import HotelSubscriptionDetails from './component/subscription-hotel-details';
import SubscriptionCoupon from './component/subscription-coupon';







function App() {

  const ProtectedRoute = ({
    adminType,
    redirectPath = '/',
    children,
  }) => {
    

    
    if (!isLoggedIn(adminType)) {
      return <Navigate to={redirectPath} replace />;
    }
  
    return children ? children : <Outlet />;
  };

  

  useEffect(()=>{
    socket.on('connect',()=>{
      console.log("=================>", socket.connected)
    })
    // socket.emit("checkin_room_join",{roomId:"qwertyuiop"})
    // socket.emit("dinesh","Hello")

    // socket.on('aman',(data)=>{
    //   console.log("amNS event=============>", data)
    // })




    socket.on('event',(data)=>{
      console.log("listening event=============>", data)
    })


  },[])
  







 


  return (
    <div >




<React.Fragment>


      <ToastContainer />
      <HashRouter>
        <Routes>  
         <Route path='/' element={<Login/>}/>
         <Route path='/forgot' element={<Forgot/>}/>
         <Route path='/otp' element={<Otp/>}/>
         <Route path='/change-password' element={<ChangePassword/>}/>
         <Route path='/congratulation' element={<Congratulation/>}/>
         <Route path='/dashboard' element={<ProtectedRoute adminType={'admin'}><Dashboard/> </ProtectedRoute>}/>
         <Route path='/content-management' element={<ProtectedRoute adminType={'admin'}><ContentManagement/> </ProtectedRoute>}/>
         <Route path='/edit-aboutus' element={<ProtectedRoute adminType={'admin'}><EditAbouts/> </ProtectedRoute>}/>
         <Route path='/edit-policy' element={<ProtectedRoute adminType={'admin'}><EditPolicy/> </ProtectedRoute>}/>
         <Route path='/edit-termscondition' element={<ProtectedRoute adminType={'admin'}><EditTermsCondition/> </ProtectedRoute>}/>
         <Route path='/settings' element={<ProtectedRoute adminType={'admin'}><Settings/> </ProtectedRoute>}/>
         <Route path='/subscription-management' element={<ProtectedRoute adminType={'admin'}><SubscriptionManagement/> </ProtectedRoute>}/>
         <Route path='/cancel-subscription' element={<ProtectedRoute adminType={'admin'}><CancelSubscription/> </ProtectedRoute>}/>
         <Route path='/active-subscription' element={<ProtectedRoute adminType={'admin'}><ActiveSubscription/> </ProtectedRoute>}/>
         <Route path='/subscription-history' element={<ProtectedRoute adminType={'admin'}><SubscriptionHistory/> </ProtectedRoute>}/>
         <Route path='/notification-management' element={<ProtectedRoute adminType={'admin'}><NotificationManagement/> </ProtectedRoute>}/>
         <Route path='/finance-management' element={<ProtectedRoute adminType={'admin'}><FinanceManagement/> </ProtectedRoute>}/>
         <Route path='/analytics' element={<ProtectedRoute adminType={'admin'}><Analytics/> </ProtectedRoute>}/>
         <Route path='/service-package' element={<ProtectedRoute adminType={'admin'}><ServicePackage/> </ProtectedRoute>}/>
         <Route path='/hotel-management' element={<ProtectedRoute adminType={'admin'}><HotelManagement/> </ProtectedRoute>}/>
         <Route path='/hotel-details/:id' element={<ProtectedRoute adminType={'admin'}><HotelDetails/> </ProtectedRoute>}/>
         <Route path='/notification' element={<ProtectedRoute adminType={'admin'}><Notification/> </ProtectedRoute>}/>


         <Route path='/restaurant-profile/:id' element={<ProtectedRoute adminType={'admin'}><RestaurantProfile/> </ProtectedRoute>}/>
         <Route path='/restaurant-food/:id' element={<ProtectedRoute adminType={'admin'}><RestaurantFood/> </ProtectedRoute>}/>
         <Route path='/restaurant-msg/:id' element={<ProtectedRoute adminType={'admin'}><RestaurantMsg/> </ProtectedRoute>}/>
         <Route path='/restaurant-chat-history/:id' element={<ProtectedRoute adminType={'admin'}><RestaurantChatHistory/> </ProtectedRoute>}/>
         <Route path='/restaurant-order-request/:id' element={<ProtectedRoute adminType={'admin'}><RestaurantOrder/> </ProtectedRoute>}/>
         <Route path='/restaurant-beverage/:id' element={<ProtectedRoute adminType={'admin'}><RestaurantBeverage/> </ProtectedRoute>}/>
         <Route path='/restaurant-order-add/:id' element={<ProtectedRoute adminType={'admin'}><RestaurantOrderAdd/> </ProtectedRoute>}/>
         <Route path='/restaurant-order-status/:id' element={<ProtectedRoute adminType={'admin'}><RestaurantOrderStatus/> </ProtectedRoute>}/>
         <Route path='/restaurant-order-details/:id' element={<ProtectedRoute adminType={'admin'}><RestaurantOrderDetails/> </ProtectedRoute>}/>
         <Route path='/restaurant-msg/:id' element={<ProtectedRoute adminType={'admin'}><RestaurantMsg/> </ProtectedRoute>}/>
         <Route path='/restaurant-chat/:id' element={<ProtectedRoute adminType={'admin'}><RestaurantChat/> </ProtectedRoute>}/>
         <Route path='/restaurant-chat-per/:id' element={<ProtectedRoute adminType={'admin'}><RestaurantChatPer/> </ProtectedRoute>}/>


         <Route path='/spa-management/:id' element={<ProtectedRoute adminType={'admin'}><SpaMananagement/> </ProtectedRoute>}/>
         <Route path='/spa-order-request/:id' element={<ProtectedRoute adminType={'admin'}><SpaOrderRequest/> </ProtectedRoute>}/>
         <Route path='/spa-order-add/:id' element={<ProtectedRoute adminType={'admin'}><SpaOrderAdd/> </ProtectedRoute>}/>
         <Route path='/spa-order-status/:id' element={<ProtectedRoute adminType={'admin'}><SpaOrderStatus/> </ProtectedRoute>}/>
         <Route path='/spa-order-details/:id' element={<ProtectedRoute adminType={'admin'}><SpaOrderDetails/> </ProtectedRoute>}/>

         <Route path='/spa-chat-history/:id' element={<ProtectedRoute adminType={'admin'}><SpaChatHistory/> </ProtectedRoute>}/>
         <Route path='/spa-msg/:id' element={<ProtectedRoute adminType={'admin'}><SpaMsg/> </ProtectedRoute>}/>
         <Route path='/spa-chat/:id' element={<ProtectedRoute adminType={'admin'}><SpaChat/> </ProtectedRoute>}/>
         <Route path='/spa-chat-per/:id' element={<ProtectedRoute adminType={'admin'}><SpaChatPer/> </ProtectedRoute>}/>

         <Route path='/housekeeping-management/:id' element={<ProtectedRoute adminType={'admin'}><HousekeepingManagement/> </ProtectedRoute>}/>
         <Route path='/housekeeping-order-request/:id' element={<ProtectedRoute adminType={'admin'}><HousekeepingOrderRequest/> </ProtectedRoute>}/>
         <Route path='/housekeeping-msg/:id' element={<ProtectedRoute adminType={'admin'}><HousekeepingMsg/> </ProtectedRoute>}/>
         <Route path='/housekeeping-chat/:id' element={<ProtectedRoute adminType={'admin'}><HousekeepingChat/> </ProtectedRoute>}/>
         <Route path='/housekeeping-chat-per/:id' element={<ProtectedRoute adminType={'admin'}><HousekeepingChatPer/> </ProtectedRoute>}/>
         <Route path='/housekeeping-chat-history/:id' element={<ProtectedRoute adminType={'admin'}><HousekeepingChatHistory/> </ProtectedRoute>}/>
         <Route path='/housekeeping-order-details/:id' element={<ProtectedRoute adminType={'admin'}><HousekeepingOrderDetails/> </ProtectedRoute>}/>


         <Route path='/hotel-room/:id' element={<ProtectedRoute adminType={'admin'}><HotelRoom/> </ProtectedRoute>}/>
         <Route path='/room-min-bar/:id' element={<ProtectedRoute adminType={'admin'}><RoomMinBar/> </ProtectedRoute>}/>
         <Route path='/hotel-wifi/:id' element={<ProtectedRoute adminType={'admin'}><HotelWifi/> </ProtectedRoute>}/>
         <Route path='/hotel-activities/:id' element={<ProtectedRoute adminType={'admin'}><HotelActivities/> </ProtectedRoute>}/>
         <Route path='/hotel-coupons/:id' element={<ProtectedRoute adminType={'admin'}><HotelCoupons/> </ProtectedRoute>}/>
         <Route path='/hotel-coupons-use/:id' element={<ProtectedRoute adminType={'admin'}><HotelCouponsUse/> </ProtectedRoute>}/>
         <Route path='/hotel-trending/:id' element={<ProtectedRoute adminType={'admin'}><HotelTrending/> </ProtectedRoute>}/>
         <Route path='/trending-add/:id' element={<ProtectedRoute adminType={'admin'}><TrendingAdd/> </ProtectedRoute>}/>
         <Route path='/hotel-review/:hotelId' element={<ProtectedRoute adminType={'admin'}><HotelReviews/> </ProtectedRoute>}/>



         <Route path='/roles-management/:id' element={<ProtectedRoute adminType={'admin'}><RolesManagement/> </ProtectedRoute>}/>
         <Route path='/role-add/:id' element={<ProtectedRoute adminType={'admin'}><RoleAdd/> </ProtectedRoute>}/>
         <Route path='/role-details/:id' element={<ProtectedRoute adminType={'admin'}><RoleDetails/> </ProtectedRoute>}/>
         <Route path='/role-edit/:id' element={<ProtectedRoute adminType={'admin'}><RoleEdit/> </ProtectedRoute>}/>



         <Route path='/guests-management/:id' element={<ProtectedRoute adminType={'admin'}><GuestsManagement/> </ProtectedRoute>}/>
         <Route path='/guests-notifications' element={<ProtectedRoute adminType={'admin'}><GuestsNotifications/> </ProtectedRoute>}/>
         <Route path='/guests-manual/:id' element={<ProtectedRoute adminType={'admin'}><GuestsManual/> </ProtectedRoute>}/>
         <Route path='/guests-manual-verification/:id' element={<ProtectedRoute adminType={'admin'}><GuestsManualVerification/> </ProtectedRoute>}/>

         <Route path='/guests-verification/:id' element={<ProtectedRoute adminType={'admin'}><GuestsVerification/> </ProtectedRoute>}/>
         <Route path='/guests-invited/:id' element={<ProtectedRoute adminType={'admin'}><GuestsInvited/> </ProtectedRoute>}/>
         <Route path='/guests-checked-in/:id' element={<ProtectedRoute adminType={'admin'}><GuestsCheckedIn/> </ProtectedRoute>}/>
         <Route path='/guests-notification' element={<ProtectedRoute adminType={'admin'}><GuestsNotifications/> </ProtectedRoute>}/>
         <Route path='/guests' element={<ProtectedRoute adminType={'admin'}><Guests/> </ProtectedRoute>}/>
         <Route path='/guests-details/:id' element={<ProtectedRoute adminType={'admin'}><GuestDetails/> </ProtectedRoute>}/>



         <Route path='/guests-checkout-approved-minbar/:id' element={<ProtectedRoute adminType={'admin'}><GuestsCheckoutApprovedMinibar/> </ProtectedRoute>}/>
         <Route path='/guests-extended-checkout/:hotelId' element={<ProtectedRoute adminType={'admin'}><GuestsExtendedCheckout/> </ProtectedRoute>}/>
         <Route path='/guests-approve-extended-checkout/:id' element={<ProtectedRoute adminType={'admin'}><GuestsApproveExtendedCheckout/> </ProtectedRoute>}/>
         <Route path='/guests-checkout-request/:id' element={<ProtectedRoute adminType={'admin'}><GuestsCheckoutRequest/> </ProtectedRoute>}/>
         <Route path='/guests-checkout-approve/:id' element={<ProtectedRoute adminType={'admin'}><GuestsCheckoutApprove/> </ProtectedRoute>}/>
         <Route path='/guests-checkout-completed/:id' element={<ProtectedRoute adminType={'admin'}><GuestsCheckoutCompleted/> </ProtectedRoute>}/>



         <Route path='/guests-billing-payment/:id' element={<ProtectedRoute adminType={'admin'}><GuestsBillingPayment/> </ProtectedRoute>}/>
         <Route path='/guests-billing-payment-view/:id/:hotelId' element={<ProtectedRoute adminType={'admin'}><GuestsBillingPaymentView/> </ProtectedRoute>}/>


         <Route path='/food-review-list/:id' element={<ProtectedRoute adminType={'admin'}><FoodReviewList/> </ProtectedRoute>}/>


         <Route path='/hotel-transaction/:id' element={<ProtectedRoute adminType={'admin'}><HotelTransaction/> </ProtectedRoute>}/>
         <Route path='/hotel-transaction-details/:id' element={<ProtectedRoute adminType={'admin'}><HotelTransactionDetails/> </ProtectedRoute>}/>
         {/* <Route path='/guest-wallet-management' element={<ProtectedRoute adminType={'admin'}><GuestWalletManagement/> </ProtectedRoute>}/> */}


         <Route path='/hotel-update' element={<ProtectedRoute adminType={'admin'}><HotelUpdate/> </ProtectedRoute>}/>
         <Route path="/guests-deletion-request" element={<ProtectedRoute adminType={'admin'}><GuestDeletionRequest/></ProtectedRoute>} />
         <Route path="/cancel-request" element={<ProtectedRoute adminType={'admin'}><CancelRequest/></ProtectedRoute>} />
         <Route path="/hotel-deletion-request" element={<ProtectedRoute adminType={'admin'}><HotelDeletionRequest/></ProtectedRoute>} />
         <Route path="/hotel-cancel-request" element={<ProtectedRoute adminType={'admin'}><HotelCancelRequest/></ProtectedRoute>} />
         
         <Route path="/subscriptions" element={<ProtectedRoute adminType={'admin'}><Subscription/></ProtectedRoute>} />
         <Route path="/subscription-hotel" element={<ProtectedRoute adminType={'admin'}><HotelSubscription/></ProtectedRoute>} />
         <Route path="/subscription-hotel-details" element={<ProtectedRoute adminType={'admin'}><HotelSubscriptionDetails/></ProtectedRoute>} />
         <Route path="/subscription-coupon" element={<ProtectedRoute adminType={'admin'}><SubscriptionCoupon/></ProtectedRoute>} />

















        </Routes>
      </HashRouter>
    </React.Fragment>
       <HashRouter>
        <Routes>
        
        </Routes>
      </HashRouter>
    </div>
   
  );
}

export default App;
