import React, { useEffect } from "react";
import Header from "./header";
import Sidenav from "./sidenav";
import { useDispatch, useSelector } from "react-redux";
import { NotificationAction } from "../redux/actions/authAction";
import moment from "moment";

const Notification = () => {
  const dispatch = useDispatch();

  const { notification } = useSelector((state) => state.authReducer);

  useEffect(() => {
    dispatch(NotificationAction());
  }, []);

  return (
    <>
      <Header />
      <Sidenav />
      <div class="WrapperArea">
        <div class="WrapperBox">
          <div class="Small-Wrapper">
            <div class="TitleBox">
              <h4 class="Title">Notifications</h4>
            </div>
            <div class="NotificationsBox">
              <ul>
                {notification?.data?.map((item) => (
                  <li>
                    <aside>
                    <h4 style={{    fontWeight: 700
}}>{item?.title}</h4>

                      <p>{item?.content}</p>
                      <h6>{moment(item?.createdAt)?.format("lll")}</h6>
                    </aside>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notification;
