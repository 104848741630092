import { subscriptionManagementConstant } from "../actionTypes";

import axios from "../../axiosInstance"
import { baseUrl } from "../../config/config";
import { isLoggedIn } from "../../utils";

// GET SUBSCRIPTIONS
export const getSubscriptionsInitiate=() =>({
  type:subscriptionManagementConstant.GET_SUBSCRIPTION_INITIATE

})

export const getSubscriptionssuccess=(data) =>({
  type:subscriptionManagementConstant.GET_SUBSCRIPTION_SUCCESS,
  payload:data

})

export const getSubscriptionsFailure=(data) =>({
  type:subscriptionManagementConstant.GET_SUBSCRIPTION_FAILURE,
  payload : data

})


export const  GetSubscriptionsAction=()=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(getSubscriptionsInitiate());
      const response = await axios.get(`${baseUrl}/admin/listsubscriptions`,
                                      {
                                        headers: {authorization:`${token}`}
                                      });

      if (response.status == 200) {
        dispatch(getSubscriptionssuccess(response.data));
      } else {
        dispatch(getSubscriptionsFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getSubscriptionsFailure(err));
      return err;
    }
}


// ADD SUBSCRIPTION

export const addSubcriptionToHotelInitiate=() =>({
    type:subscriptionManagementConstant.ADD_SUBSCRIPTION_INITIATE

})

export const addSubcriptionToHotelSuccess=(data) =>({
    type:subscriptionManagementConstant.ADD_SUBSCRIPTION_SUCCESS,
    payload:data

})

export const addSubcriptionToHotelFailure=(data) =>({
    type:subscriptionManagementConstant.ADD_SUBSCRIPTION_FAILURE,
    payload : data

})


export const  AddSubcriptionToHotelAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(addSubcriptionToHotelInitiate());
        const response = await axios.put(`${baseUrl}/admin/addsubscription`, payload,{
            headers: {authorization:`${token}`}})

        if (response.status == 200) {
          dispatch(addSubcriptionToHotelSuccess(response.data));
        } else {
          dispatch(addSubcriptionToHotelFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(addSubcriptionToHotelFailure(err));
        return err;
      }
}


// EDIT SUBSCRIPTION

export const editSubcriptionInitiate=() =>({
    type:subscriptionManagementConstant.EDIT_SUBSCRIPTION_INITIATE

})

export const editSubcriptionSuccess=(data) =>({
    type:subscriptionManagementConstant.EDIT_SUBSCRIPTION_SUCCESS,
    payload:data

})

export const editSubcriptionFailure=(data) =>({
    type:subscriptionManagementConstant.EDIT_SUBSCRIPTION_FAILURE,
    payload : data

})


export const  EditSubcriptionAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(editSubcriptionInitiate());
        const response = await axios.put(`${baseUrl}/admin/updateSubscription`, payload,
          {
            headers: {authorization:`${token}`}
          });

        if (response.status == 200) {
          dispatch(editSubcriptionSuccess(response.data));
        } else {
          dispatch(editSubcriptionFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(editSubcriptionFailure(err));
        return err;
      }


}



// REMOVE SUBSCRIPTION

export const removeSubcriptionInitiate=() =>({
    type:subscriptionManagementConstant.REMOVE_SUBSCRIPTION_INITIATE

})

export const removeSubcriptionSuccess=(data) =>({
    type:subscriptionManagementConstant.REMOVE_SUBSCRIPTION_SUCCESS,
    payload:data

})

export const removeSubcriptionFailure=(data) =>({
    type:subscriptionManagementConstant.REMOVE_SUBSCRIPTION_FAILURE,
    payload : data

})


export const  RemoveSubcriptionAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(removeSubcriptionInitiate());
        const response = await axios.post(`${baseUrl}/admin/removeSubscription`, payload,{
            headers: {authorization:`${token}`}})

        if (response.status == 200) {
          dispatch(removeSubcriptionSuccess(response.data));
        } else {
          dispatch(removeSubcriptionFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(removeSubcriptionFailure(err));
        return err;
      }


}


// GET LIST OF HOTELS WITH SUBSCRIPTION STATUS

export const hotelSubcriptionListInitiate=() =>({
    type:subscriptionManagementConstant.HOTEL_SUBSCRIPTION_LIST_INITIATE
})

export const hotelSubcriptionListSuccess=(data) =>({
    type:subscriptionManagementConstant.HOTEL_SUBSCRIPTION_LIST_SUCCESS,
    payload:data
})

export const hotelSubcriptionListFailure=(data) =>({
    type:subscriptionManagementConstant.HOTEL_SUBSCRIPTION_LIST_FAILURE,
    payload : data
})


export const  HotelSubcriptionListAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(hotelSubcriptionListInitiate());
        const response = await axios.get(`${baseUrl}/admin/getsubscriptionDetails`,
          {
            headers: {authorization:`${token}`}
          });

        if (response.status == 200) {
          dispatch(hotelSubcriptionListSuccess(response.data));
        } else {
          dispatch(hotelSubcriptionListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(hotelSubcriptionListFailure(err));
        return err;
      }
}


// SET CUSTOM SUBSERIPTION FOR HOTELS
export const setCustomSubscriptionForHotelInitiate=() =>({
  type:subscriptionManagementConstant.SET_CUSTOM_SUBSCRIPTION_FOR_HOTEL_INITIATE

})

export const setCustomSubscriptionForHotelSuccess=(data) =>({
  type:subscriptionManagementConstant.SET_CUSTOM_SUBSCRIPTION_FOR_HOTEL_SUCCESS,
  payload:data

})

export const setCustomSubscriptionForHotelFailure=(data) =>({
  type:subscriptionManagementConstant.SET_CUSTOM_SUBSCRIPTION_FOR_HOTEL_FAILURE,
  payload : data

})


export const  SetCustomSubscriptionForHotelAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(setCustomSubscriptionForHotelInitiate());
      const response = await axios.put(`${baseUrl}/admin/updateHotelSubscription`, payload,
        {
          headers: {authorization:`${token}`}
        })

      if (response.status == 200) {
        dispatch(setCustomSubscriptionForHotelSuccess(response.data));
      } else {
        dispatch(setCustomSubscriptionForHotelFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(setCustomSubscriptionForHotelFailure(err));
      return err;
    }
}


// GET SUBSCRIPTION COUPONS

export const getSubscriptionCouponInitiate=() =>({
  type:subscriptionManagementConstant.GET_SUBSCRIPTION_COUPON_INITIATE

})

export const getSubscriptionCouponSuccess=(data) =>({
  type:subscriptionManagementConstant.GET_SUBSCRIPTION_COUPON_SUCCESS,
  payload:data

})

export const getSubscriptionCouponFailure=(data) =>({
  type:subscriptionManagementConstant.GET_SUBSCRIPTION_COUPON_FAILURE,
  payload : data

})


export const  GetSubscriptionCouponAction=()=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(getSubscriptionCouponInitiate());
      const response = await axios.get(`${baseUrl}/admin/getsubscriptioncoupon`,
                                      {
                                        headers: {authorization:`${token}`}
                                      });

      if (response.status == 200) {
        dispatch(getSubscriptionCouponSuccess(response.data));
      } else {
        dispatch(getSubscriptionCouponFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getSubscriptionCouponFailure(err));
      return err;
    }
}


// CHANGE COUPON STATUS

export const couponStatusInitiate=() =>({
  type:subscriptionManagementConstant.COUPON_STATUS_INITIATE

})

export const couponStatusSuccess=(data) =>({
  type:subscriptionManagementConstant.COUPON_STATUS_SUCCESS,
  payload:data

})

export const couponStatusFailure=(data) =>({
  type:subscriptionManagementConstant.COUPON_STATUS_FAILURE,
  payload : data

})


export const  CouponStatusAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(couponStatusInitiate());
      const response = await axios.put(`${baseUrl}/admin/changesubscriptioncouponstatus`, payload,
        {
          headers: {authorization:`${token}`}
        })

      if (response.status == 200) {
        dispatch(couponStatusSuccess(response.data));
      } else {
        dispatch(couponStatusFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(couponStatusFailure(err));
      return err;
    }
}


// CREATE COUPON

export const createCouponInitiate=() =>({
  type:subscriptionManagementConstant.CREATE_COUPON_INITIATE

})

export const createCouponSuccess=(data) =>({
  type:subscriptionManagementConstant.CREATE_COUPON_SUCCESS,
  payload:data

})

export const createCouponFailure=(data) =>({
  type:subscriptionManagementConstant.CREATE_COUPON_FAILURE,
  payload : data

})


export const  CreateCouponAction=(payload)=> async(dispatch) => {
  const token =isLoggedIn('admin');
  try {
      dispatch(createCouponInitiate());
      const response = await axios.post(`${baseUrl}/admin/createsubscriptioncoupon`, payload,
        {
          headers: {authorization:`${token}`}
        })

      if (response.status == 200) {
        dispatch(createCouponSuccess(response.data));
      } else {
        dispatch(createCouponFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(createCouponFailure(err));
      return err;
    }
}


// UPDATE COUPON
export const updateCouponInitiate=() =>({
  type:subscriptionManagementConstant.UPDATE_COUPON_INITIATE

})

export const updateCouponSuccess=(data) =>({
  type:subscriptionManagementConstant.UPDATE_COUPON_SUCCESS,
  payload:data

})

export const updateCouponFailure=(data) =>({
  type:subscriptionManagementConstant.UPDATE_COUPON_FAILURE,
  payload : data

})


export const  UpdateCouponAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(updateCouponInitiate());
      const response = await axios.put(`${baseUrl}/admin/updatesubscriptioncoupon`, payload,
        {
          headers: {authorization:`${token}`}
        })

      if (response.status == 200) {
        dispatch(updateCouponSuccess(response.data));
      } else {
        dispatch(updateCouponFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(updateCouponFailure(err));
      return err;
    }
}


// GET SUBSCRIPTION TYPE

export const getSubscriptionTypeInitiate=() =>({
  type:subscriptionManagementConstant.GET_SUBSCRIPTION_TYPE_INITIATE

})

export const getSubscriptionTypeSuccess=(data) =>({
  type:subscriptionManagementConstant.GET_SUBSCRIPTION_TYPE_SUCCESS,
  payload:data

})

export const getSubscriptionTypeFailure=(data) =>({
  type:subscriptionManagementConstant.GET_SUBSCRIPTION_TYPE_FAILURE,
  payload : data

})


export const  GetSubscriptionTypeAction=()=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(getSubscriptionTypeInitiate());
      const response = await axios.get(`${baseUrl}/admin/getsubscriptiontype`,
                                      {
                                        headers: {authorization:`${token}`}
                                      });

      if (response.status == 200) {
        dispatch(getSubscriptionTypeSuccess(response.data));
      } else {
        dispatch(getSubscriptionTypeFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getSubscriptionTypeFailure(err));
      return err;
    }
}


// GET SUBSCRTIPION DETAILS OF HOTEL

export const getSubscriptionsDetailsInitiate=() =>({
  type:subscriptionManagementConstant.GET_SUBSCRIPTION_DETAILS_INITIATE
})
export const getSubscriptionsDetailsSuccess=(data) =>({
  type:subscriptionManagementConstant.GET_SUBSCRIPTION_DETAILS_SUCCESS,
  payload:data
})
export const getSubscriptionsDetailsFailure=(data) =>({
  type:subscriptionManagementConstant.GET_SUBSCRIPTION_DETAILS_FAILURE,
  payload : data 
})  

export const  GetSubscriptionsDetailsAction = (payload)=> async(dispatch)=>{
  const token = isLoggedIn('admin');
  try {
      dispatch(getSubscriptionsDetailsInitiate());
      const response = await axios.get(`${baseUrl}/admin/getHotelSubscriptionDetails`, 
                                              {
                                                  headers: {Authorization:`${token}`}, 
                                                  params: payload
                                              });                                       
      if (response.status === 200) {

          dispatch(getSubscriptionsDetailsSuccess(response.data));
      } else {
          dispatch(getSubscriptionsDetailsFailure(response.data));
      }
      return response;
  } catch (err) {
      dispatch(getSubscriptionsDetailsFailure(err));
      return err;
  }
}


// UPDATE GST OF SUBSCRIPTION

export const updateSubscriptionGSTInitiate=() =>({
  type:subscriptionManagementConstant.UPDATE_SUBSCRIPTION_GST_INITIATE
})
export const updateSubscriptionGSTSuccess=(data) =>({
  type:subscriptionManagementConstant.UPDATE_SUBSCRIPTION_GST_SUCCESS,
  payload:data
})
export const updateSubscriptionGSTFailure=(data) =>({
  type:subscriptionManagementConstant.UPDATE_SUBSCRIPTION_GST_FAILURE,
  payload : data 
})  

export const  UpdateSubscriptionGSTAction = (payload)=> async(dispatch)=>{
  const token = isLoggedIn('admin');
  try {
      dispatch(updateSubscriptionGSTInitiate());
      const response = await axios.put(`${baseUrl}/admin/updateSubscriptionTaxData`, payload,
                                              {
                                                  headers: {Authorization:`${token}`}
                                              });                                       
      if (response.status>=200 && response.status<300) {
          dispatch(updateSubscriptionGSTSuccess(response.data));
      } else {
          dispatch(updateSubscriptionGSTFailure(response.data));
      }
      return response;
  } catch (err) {
      dispatch(updateSubscriptionGSTFailure(err));
      return err;
  }
}