import React from "react";
import Header from "./header";
import Sidenav from "./sidenav";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { SocketContext } from "../config/sckt";
import { useContext } from "react";
import { useState } from "react";
import ImageFetcher from './ImageFetcher'
import SendIcon from "../assets/image/send.svg";
import { ChatHitoryAction } from "../redux/actions/chatAction";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";

const SpaChatPer = () => {
  const { state } = useLocation();
  const {id} = useParams()
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const [chatData, setChatData] = useState([]);
  const { chatHistory } = useSelector((state) => state.chatReducer);

  const [msg, setMsg] = useState("");

  useEffect(() => {
    if (state) {
      socket.emit("checkin_room_join", { roomId: state?.chatRoomId });
    }
  }, []);


  useEffect(() => {
    dispatch(ChatHitoryAction({ chatRoomId: state?.chatRoomId }));
  }, []);

  useEffect(() => {
    setChatData(chatHistory);
  }, [chatHistory]);

  useEffect(() => {
    socket.on("get-new-message", (data) => {
      //   console.log("listening  NEW MESSAGE=============>", data)
      setChatData((prev) => [...prev, data]);
    });
  }, []);

  useEffect(() => {
    socket.on("create-new-message", (data) => {
      //   console.log("listening  NEW MESSAGE=============>", data)
      setChatData((prev) => [...prev, data]);
    });
  }, []);


  return (
    <div>
      <Header />
      <Sidenav />
      <div class="WrapperArea">
        <div class="WrapperBox">
          <div class="NewTitleBox">
            <div className="arrow-left">
              <Link to={`/spa-msg/${id}`}>
                <i class="fa fa-arrow-left arrow-back" aria-hidden="true"></i>
              </Link>

              <h4>Chat</h4>
            </div>
          </div>

          <div class="SupportDetails">
            <ul>
              {chatData?.map((item) => (
                <li
                  class={
                    item?.sender == id
                      ? "OtherMessage"
                      : ""
                  }
                >
                  <figure>
                    <img
                      src={
                        item?.sender == id
                          ? require("../assets/image/Avatar-3.png")
                          : require("../assets/image/Avatar-2.png")
                      }
                    />
                  </figure>
                  <figcaption>
                    <h3>
                      {item?.sender == id
                        ? "Admin"
                        : state?.sender?.[0]?.name}
                    </h3>
                    <p>{item?.message?.message}</p>
                    <span class="time">
                      {moment(item?.updatedAt).format("lll")}
                    </span>
                  </figcaption>
                </li>
              ))}
            </ul>

          </div>
        </div>
      </div>
    </div>
  );
};

export default SpaChatPer;
