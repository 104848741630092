import { couponConstant } from "../actionTypes";

import axios from "../../axiosInstance"
import { baseUrl } from "../../config/config";
import { isLoggedIn } from "../../utils";




// ADD COUPON

export const addCouponInitiate=() =>({
    type:couponConstant.ADD_COUPON_INITIATE

})

export const addCouponSuccess=(data) =>({
    type:couponConstant.ADD_COUPON_SUCCESS,
    payload:data

})

export const addCouponFailure=(data) =>({
    type:couponConstant.ADD_COUPON_FAILURE,
    payload : data

})


export const  AddCouponAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(addCouponInitiate());
        const response = await axios.post(`${baseUrl}/admin/coupon/add`, payload,{
            headers: {authorization:`${token}`}})

        if (response.data.success === true) {
          dispatch(addCouponSuccess(response.data));
        } else {
          dispatch(addCouponFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(addCouponFailure(err));
        return err;
      }


}


// EDIT COUPON

export const editCouponInitiate=() =>({
    type:couponConstant.UPDATE_COUPON_INITIATE

})

export const editCouponSuccess=(data) =>({
    type:couponConstant.UPDATE_COUPON_SUCCESS,
    payload:data

})

export const editCouponFailure=(data) =>({
    type:couponConstant.UPDATE_COUPON_FAILURE,
    payload : data

})


export const  EditCouponAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(editCouponInitiate());
        const response = await axios.put(`${baseUrl}/admin/coupon/update`, payload,{
            headers: {authorization:`${token}`}})

        if (response.data.sucess === true) {
          dispatch(editCouponSuccess(response.data));
        } else {
          dispatch(editCouponFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(editCouponFailure(err));
        return err;
      }


}



// REMOVE COUPON

export const removeCouponInitiate=() =>({
    type:couponConstant.REMOVE_COUPON_INITIATE

})

export const removeCouponSuccess=(data) =>({
    type:couponConstant.REMOVE_COUPON_SUCCESS,
    payload:data

})

export const removeCouponFailure=(data) =>({
    type:couponConstant.REMOVE_COUPON_FAILURE,
    payload : data

})


export const  RemoveCouponAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(removeCouponInitiate());
        const response = await axios.delete(`${baseUrl}/admin/coupon/delete`, {
          headers: {Authorization:`${token}`},params: payload})
          console.log(response)

        if (response.data.sucess === true) {
          dispatch(removeCouponSuccess(response.data));
        } else {
          dispatch(removeCouponFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(removeCouponFailure(err));
        return err;
      }


}



//  COUPON LIST

export const trendingListInitiate=() =>({
    type:couponConstant.COUPON_LIST_INITIATE

})

export const trendingListSuccess=(data) =>({
    type:couponConstant.COUPON_LIST_SUCCESS,
    payload:data

})

export const trendingListFailure=(data) =>({
    type:couponConstant.COUPON_LIST_FAILURE,
    payload : data

})


export const  CouponListAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(trendingListInitiate());
        const response = await axios.get(`${baseUrl}/admin/coupon/get`, {
          headers: {Authorization:`${token}`},params: payload})

          if (response.data.success === true) {
            dispatch(trendingListSuccess(response.data));
        } else {
          dispatch(trendingListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(trendingListFailure(err));
        return err;
      }


}




// ADDED COUPON LIST

export const addtrendingListInitiate=() =>({
    type:couponConstant.ADD_COUPON_LIST_INITIATE

})

export const addtrendingListSuccess=(data) =>({
    type:couponConstant.ADD_COUPON_LIST_SUCCESS,
    payload:data

})

export const addtrendingListFailure=(data) =>({
    type:couponConstant.ADD_COUPON_LIST_FAILURE,
    payload : data

})


export const  AddedCouponListAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(addtrendingListInitiate());
        const response = await axios.get(`${baseUrl}/admin/trending/get`, {
          headers: {Authorization:`${token}`},params: payload})

          if (response.data.success === true) {
            dispatch(addtrendingListSuccess(response.data));
        } else {
          dispatch(addtrendingListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(addtrendingListFailure(err));
        return err;
      }


}





//  COUPON USAGE

export const couponUsageInitiate=() =>({
  type:couponConstant.COUPON_USEAGE_INITIATE

})

export const couponUsageSuccess=(data) =>({
  type:couponConstant.COUPON_USEAGE_SUCCESS,
  payload:data

})

export const couponUsageFailure=(data) =>({
  type:couponConstant.COUPON_USEAGE_FAILURE,
  payload : data

})


export const  CouponUsageAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(couponUsageInitiate());
      const response = await axios.get(`${baseUrl}/admin/coupon/couponusage/list`, {
        headers: {Authorization:`${token}`},params: payload})

        if (response.data.success === true) {
          dispatch(couponUsageSuccess(response.data));
      } else {
        dispatch(couponUsageFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(couponUsageFailure(err));
      return err;
    }


}




