// SubscriptionPlans.js
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import check_select_subscription from "../assets/image/check_select_subscription.png";

import { GetSubscriptionsAction, 
        EditSubcriptionAction, 
        UpdateSubscriptionGSTAction } from "../redux/actions/subscriptionAction.js";

import Header from "./header";
import Sidenav from "./sidenav";
import { Link } from "react-router-dom";

const SubscriptionPlans = () => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPlanDetails, setSelectedPlanDetails] = useState(null);
  const [plans, setPlans] = useState([]);
  var [payload, setPayload] = useState({});

  const dispatch = useDispatch();

  const {subscriptions} = useSelector(
    (state) => state.subscriptionReducer
  );

  useEffect (() => {
    dispatch(
        GetSubscriptionsAction()
    );
  }, []);

  useEffect (() => {
    setPlans(subscriptions);
  }, [subscriptions]);

  const handleDiscountedPrice = (pricePerRoom, discount) => {
    var calculate = pricePerRoom - (pricePerRoom * (discount / 100 ));
    var integerPricing = Math.floor(calculate);

    if ( integerPricing <= 49) {
      integerPricing = 49;
    }
    else if (integerPricing <= 99) {
      integerPricing = 99;
    }
    else {
      var rem = integerPricing % 100;
      var div = Math.floor(integerPricing / 100);

      if (rem <= 49) {
        integerPricing = (div * 100) + 49;
      }
      else {
        integerPricing = (div * 100) + 99;
      }
    }
    return integerPricing;
  }

  const handleChooseSubscription = (e, plan) => {
    e.preventDefault();
    if (selectedPlan){
        setSelectedPlan(null);
        setSelectedPlanDetails(null);
        setIsModalOpen(false);
      }else{
        setSelectedPlanDetails(plan);
        setSelectedPlan(plan.subscriptionId);
        setIsModalOpen(true);
      }
  }

  const handleCloseModal = () => {
    setSelectedPlan(null);
    setSelectedPlanDetails(null);
    setIsModalOpen(false);
    setPayload({});
  };

  const handleCancelButton = (e) => {
    e.preventDefault();
    handleCloseModal();
  }
  
  const handleUpdateButton = (e) => {
    e.preventDefault();
    setIsModalOpen(true);
  }

  const handleUpdateSubscription = (e) => {
    e.preventDefault();
    payload = {...payload, _id: selectedPlanDetails._id};
    console.log("GST val", payload.GST, typeof payload.GST)

    dispatch(
      EditSubcriptionAction(payload)
    ).then((res) => {
        if (res.status===202){
          toast.success(`Subscription Updated`,
            {position: toast.POSITION.TOP_RIGHT},
          );
          dispatch(GetSubscriptionsAction());
        }
        else {
          console.log(res);
          toast.error(`${res.response.data.message}`,
            {position: toast.POSITION.TOP_RIGHT},
          );
        }
      }).catch(error => {
        toast.error(`Something Went Wrong`,
          {position: toast.POSITION.TOP_RIGHT,}
        );
      }
    );
    handleCloseModal();
  }

  const [taxUpdateModal, setTaxUpdateModal] = useState(false);
  const [taxtype, setTaxType] = useState(null);
  const [percent, setPercent] = useState(null);

  const handleTaxUpdate = () => {
    var payload = {};

    if(taxtype != null) {
      payload.taxType = taxtype;
    }
    if(percent != null) {
      payload.percent = percent;
    }
    
    dispatch(
      UpdateSubscriptionGSTAction(payload)
    ).then((res) => {
      if (res.status >= 200 && res.status < 300) {
        toast.success(`Tax Data Updated`,
          {position: toast.POSITION.TOP_RIGHT}
        );
        dispatch(GetSubscriptionsAction());
      }
      else{
        toast.error(`${res.response.data.message}`,
          {position: toast.POSITION.TOP_RIGHT}
        );
      }

    }).catch((err) => {
      alert(err);
    });

    handleTaxUpdateClose();

  }
  const handleTaxUpdateOpen = () => {
    setTaxUpdateModal(true);
  }
  const handleTaxUpdateClose = () => {
    setTaxType(null);
    setPercent(null);
    setTaxUpdateModal(false);
  }
  
  return (
    <>
        <Header />
        <Sidenav />
        <div class="WrapperArea">
            <div class="WrapperBox">
            <div class="Small-Wrapper">
                <div class="subsTitleBox">
                  <h4 className="Title"> Subscription Plans </h4>
                  <div className="subsTitleButtonContainer">
                    <Link to="/subscription-hotel" className="Button">Hotel Subscription</Link>
                    <Link to="/subscription-coupon" className="Button">Subscription Coupons</Link>
                  </div>
                </div>
                
                <div class="SubscriptionGSTBox">
                    <h4>
                    {plans?.[0]?.tax.taxType}:{" "}
                    <b>
                    {plans?.[0]?.tax.percent}%
                      <span>
                        <a
                          class="Green"
                        >
                          <i class="fa fa-pencil" onClick={() => setTaxUpdateModal(true)}></i>
                        </a>
                      </span>
                    </b>
                  </h4>
                </div>

                <div className="subscriptionPlans">
                    {plans.map((plan) => (
                    <div
                        key={plan.subscriptionId}
                        className = { selectedPlan === plan.subscriptionId ? 
                          "subscriptionplanBox subscriptionPlanSelected" :
                          "subscriptionplanBox"}
                    >
                        {selectedPlan === plan.subscriptionId && (
                        <img
                            src={check_select_subscription}
                            style={{
                            height: "50px",
                            position: "absolute",
                            top: "-15px", // Adjust as needed
                            right: "110px", // Adjust as needed
                            }}
                            alt="Selected Plan"
                        />
                        )}

                        <h5 className="subscriptionPlanBoxheading">{plan.name}</h5>
                        <p className="subscriptionPlanBoxSubheading">{plan.subType}</p>
                        {plan.description && 
                        <div className="subscriptionPlanBoxDescription">{plan.description}</div>}
                        { plan.discount ? 
                        <p className="strikedtxt">₹ {plan.pricePerRoom} / room</p> : <p></p>}
                        <p className="subscriptionPricetxt">₹ {handleDiscountedPrice(plan.pricePerRoom, plan.discount)} / room</p>
                        <div>
                          <p className="subscriptionPlanAbout">
                            {plan.discount ? 
                            <span>&#8226; Save <strong>{plan.discount}%</strong> </span>  : 
                            <span>&#8226; No Discount </span>}
                            <br></br>
                            {/* {plan.subType === "Yearly" && 
                            <span>&#8226; Get <strong>7 days</strong> free </span>} 
                            {plan.subType === "6 Months" && 
                            <span>&#8226; Get <strong>3 days</strong> free </span>} 
                            {plan.subType === "3 Months" && 
                            <span>&#8226; Get <strong>1 days</strong> free </span>}  */}
                          </p>
                          
                        </div>
                        <div>
                        </div>
                        <div className="subscriptionUpdateButtonContainer">
                        <button
                            className={selectedPlan === plan.subscriptionId ? 
                              "subscriptionPlanUpdateButton" :
                              "subscriptionPlanUpdateButton subscriptionPlanUpdateButtonFadeOut"
                            }
                            onClick={(e) => handleChooseSubscription(e, plan)}
                        >
                            Update Plan
                        </button>
                        </div>
                    </div>
                    ))}
                </div>
              </div>

                { isModalOpen && 
                    <Modal show={handleUpdateButton} 
                           onHide={handleCloseModal} 
                           className="subscriptionBillingModalOverlay">
                        <Modal.Body className="subscriptionUpdateModalBody">
                          <div className="subscriptionUpdateModalContent">
                            <h3 className="subscriptionUpdateModalheading">
                              Update Subscription
                            </h3>
                            
                              <div className="form-group">
                                <label>Subscription ID</label>
                                  <input
                                      type={"text"}
                                      className="form-control"
                                      disabled
                                      value = {selectedPlanDetails.subscriptionId}                           
                                  /> 
                              </div>
                              <div className="form-group">
                                <label>Subscription Type</label>
                                  <input
                                      type={"text"}
                                      className="form-control"
                                      disabled
                                      value = {selectedPlanDetails.subType}                           
                                  /> 
                              </div>
                              <div className="form-group">
                                <label>Subscription Name</label>
                                  <input
                                      type={"text"}
                                      className="form-control"  
                                      onChange={(e) => (e.target.value) ? setPayload((payload) => ({...payload, name: e.target.value})) : delete payload.name}    
                                      placeholder={selectedPlanDetails.name}                   
                                  /> 
                              </div>
                              <div className="form-group">
                                <label>Subscription Price / Room</label>
                                  <input
                                      type={"number"}
                                      className="form-control"
                                      placeholder = {selectedPlanDetails.pricePerRoom}
                                      onChange={(e) => (e.target.value) ? setPayload((payload) => ({...payload, pricePerRoom: e.target.valueAsNumber})) : delete payload.pricePerRoom}                         
                                  /> 
                              </div>
                              <div className="form-group">
                                <label>Subscription Duration</label>
                                  <input
                                      type={"number"}
                                      className="form-control"
                                      placeholder = {selectedPlanDetails.durationInDays}
                                      onChange={(e) => (e.target.value) ? setPayload((payload) => ({...payload, durationInDays: e.target.valueAsNumber})) : delete payload.durationInDays}                           
                                  /> 
                              </div>
                              <div className="form-group">
                                <label>Subscription Discount</label>
                                  <input
                                      type={"number"}
                                      className="form-control"
                                      placeholder = {selectedPlanDetails.discount}    
                                      onChange={(e) => (e.target.value) ? setPayload((payload) => ({...payload, discount: e.target.valueAsNumber})) : delete payload.discount}                       
                                  /> 
                              </div>
                              <div className="form-group">
                                <label>Subscription Description</label>
                                  <input
                                      type={"text"}
                                      className="form-control"
                                      placeholder = {selectedPlanDetails.description}
                                      onChange={(e) => (e.target.value) ? setPayload((payload) => ({...payload, description: e.target.value})): delete payload.description}                           
                                  /> 
                              </div>
                              <div className="updateSubscriptionButtonContainer">
                                <button className="Button" onClick={(e) => handleUpdateSubscription(e)}>
                                  Update
                                </button>
                                <button className="Button Cancel" onClick={(e) => handleCancelButton(e)}> 
                                  Cancel
                                </button>
                              </div>
                            </div>
                        </Modal.Body>
                    </Modal> }

                    { taxUpdateModal && 
                    <Modal show={handleTaxUpdateOpen} 
                           onHide={handleTaxUpdateOpen} 
                           style={{padding:"50px"}}
                    >
                      <Modal.Body >
                           {/* <div className="subscriptionUpdateModalContent"> */}
                              <h3 className="subscriptionUpdateModalheading">
                                Update Tax 
                              </h3>
                              
                                <div className="form-group">
                                  <label>Tax Type</label>
                                    <input
                                        type={"text"}
                                        className="form-control"
                                        placeholder = {plans?.[0]?.tax.taxType}
                                        onChange={(e) => setTaxType(e.target.value)}                           
                                    /> 
                                </div>
                                <div className="form-group">
                                  <label>Subscription Type</label>
                                    <input
                                        type={"number"}
                                        className="form-control"
                                        placeholder = {plans?.[0]?.tax.percent}  
                                        onChange={(e) => setPercent(e.target.valueAsNumber)}                         
                                    /> 
                                </div>

                                <div className="updateSubscriptionButtonContainer">
                                <button className="Button" onClick={(e) => handleTaxUpdate(e)}>
                                  Update
                                </button>
                                <button className="Button Cancel" onClick={(e) => handleTaxUpdateClose(e)}> 
                                  Cancel
                                </button>
                              </div>

                      </Modal.Body>
                    </Modal> }
            </div>
          </div>
    </>
  );
};

export default SubscriptionPlans;
