import React, { useState } from 'react'
import Sidenav from './sidenav'
import Header from './header'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CouponUsageAction } from '../redux/actions/couponAction';
import { Link } from 'react-router-dom';

import NoDataFound from "./noDataFound";
import Pagination from "react-js-pagination";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx"
import { useParams } from 'react-router-dom';




const HotelCouponUse = () => {
    const dispatch = useDispatch();
    const {id} = useParams()

    const [filterData, setFilterData] = useState({
        timeFrame: "",
        startDate: "",
        endDate: "",
        search: "",
        page:1
      });
    

    const {couponUsageList, loader} = useSelector((state)=>state.couponReducer)

    
    useEffect(()=>{

        dispatch(CouponUsageAction())


    },[])







    const handlePageChange = (pageNumber) => {
        setFilterData({...filterData, page:pageNumber});
        dispatch(CouponUsageAction({ page: pageNumber}));
      };
    



      const downloadRowData = (rowData, fileName) => {
        const fileType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        let result = rowData?.map((item, i) => {
          return {
            "Sr. No": i + 1,
            "Coupon Name":  item?.couponId?.category?.charAt(0)?.toUpperCase() +
              item?.couponId?.category?.slice(1),
            "User Name":item?.userId?.name,
            "Total Bill": item?.totalBill?.toFixed(2),
            "Coupon Type":  `Upto to ${item?.couponId?.maxDiscountAmt} INR on Minimum Purchase of ${item?.couponId?.minPurchaseAmt}`,
            "Total Discount": item?.totalDiscount?.toFixed(2),
          "Room Number":item?.room?.fullNumber,
          };
        });
    
        // Combine the header row and row data into a single string
        const myJsonString = JSON.stringify(result);
    
        const ws = XLSX.utils.json_to_sheet(result);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, "coupon-usage-record.xlsx");
      };


  return (
    <div><Sidenav />
    <Header />

    <div class="WrapperArea">
        <div class="WrapperBox">
            <div class="back-btn">
            <Link to={`/hotel-coupons/${id}`}><i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back</Link>
            </div>
            <div class="Small-Wrapper">
                <div class="NewTitleBox">
                    <h4>Coupon Usage Details</h4> 
                    <a class="ExcelDownload" onClick={()=>downloadRowData(couponUsageList?.[0]?.data)}><i class="fa fa-file-excel-o"></i> Export to Excel</a>
                </div>
                
                <div class="Filter">  
                    <div class="form-group">
                        <label>Search</label>
                        <input type="text" class="form-control" placeholder="Search.." />
                    </div> 
                    <div class="form-group">
                        <label>Coupon Type</label>
                        <select class="form-control">
                            <option>Select Coupon Type</option>
                        </select> 
                    </div>   
                    <div class="form-group">
                        <label>From date</label>
                        <input type="date" class="form-control" /> 
                    </div>   
                    <div class="form-group">
                        <label>To date</label>
                        <input type="date" class="form-control" /> 
                    </div>   
                </div>

                <div class="TableList">
                    <table>
                        <thead>
                            <tr>
                                <th>Coupon Name</th>
                                <th>User name</th> 
                                <th>Total Bill</th>
                                <th>Coupon type</th>
                                <th>Total discount</th>
                                <th>Room Number</th>
                            </tr>
                        </thead>
                        <tbody>
                            {couponUsageList?.[0]?.data?.map((item)=>
                            <tr>
                            <td>  {item?.couponId?.category?.charAt(0)?.toUpperCase() +
                            item?.couponId?.category?.slice(1)}</td> 
                            <td>{item?.userId?.name}</td> 
                            <td>{item?.totalBill?.toFixed(2)} INR</td> 
                            <td>                            Upto to {item?.couponId?.maxDiscountAmt} INR on Minimum
                            Purchase of {item?.couponId?.minPurchaseAmt} INR
</td> 
                            <td>{item?.totalDiscount?.toFixed(2)} INR</td> 
                            <td>{item?.room?.fullNumber}</td> 
                        </tr>
                            )}
                            
                           
                        </tbody>
                    </table>
                    {loader ? (
                ""
              ) : couponUsageList?.[0]?.metadata?.[0]?.total > 0 ? (
                ""
              ) : (
                <NoDataFound />
              )}
                </div>
                {couponUsageList?.[0]?.metadata?.[0]?.total > 0 ? (
              <div className="Pagination">
                <Pagination
                  activePage={filterData?.page}
                  itemsCountPerPage={10}
                  totalItemsCount={couponUsageList?.[0]?.metadata?.[0]?.total}
                  pageRangeDisplayed={5}
                  onChange={(e) => handlePageChange(e)}
                />
              </div>
            ) : (
              ""
            )}

            </div>
        </div>
    </div>
    </div>
  )
}

export default HotelCouponUse