import React from "react";
import io from "socket.io-client";
import {socketLink} from "./config";

export const socket = io.connect(socketLink,{
    transports: ['websocket'],
    reconnection: true,
    reconnectionAttempts: Infinity,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    randomizationFactor: 0.5,
    secure: true
  });
export const SocketContext = React.createContext();