// SubscriptionPlans.js
import React, { useEffect, useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { GetSubscriptionCouponAction, CouponStatusAction, CreateCouponAction, UpdateCouponAction, GetSubscriptionTypeAction } from "../redux/actions/subscriptionAction.js";
import Header from "./header";
import Sidenav from "./sidenav";
import { Link } from "react-router-dom";
import Loader from "./loader";
import Multiselect from "multiselect-react-dropdown";
import NoDataFound from "./noDataFound";


const SubscriptionCoupon = () => {
  const [AddCouponModal, setAddCouponModal] = useState(false);
  const [UpdateCouponModal, setUpdateCouponModal] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  var [createCouponData, setCreateCouponData] = useState({});
  var [updateCouponData, setUpdateCouponData] = useState({});

  const dispatch = useDispatch();
  const dropDownRefCategory = useRef();

  const {couponList, subscriptionType, loader} = useSelector(
    (state) => state.subscriptionReducer
  );

  useEffect(() => {
    dispatch(GetSubscriptionCouponAction());
  }, [])

  useEffect(() => {
    dispatch(GetSubscriptionTypeAction());
  },[])

  console.log("Sub type", subscriptionType)

  const getDate = (date) => {
    date = new Date(date);
    const day = date.getDate();
    const month = date.getMonth() + 1; // +1 because getMonth() returns 0-11
    const year = date.getFullYear();

    const formattedDate = `${day.toString().padStart(2, '0')}-${month.toString().padStart(2, '0')}-${year}`;
    return formattedDate;
  }


  function onSelectCreateType(selectedItem) {
    if (createCouponData.validForType === undefined) {
      createCouponData.validForType = []
    }
    var selectedValues = []
    for (let i=0; i < selectedItem.length; i++){
      selectedValues.push(selectedItem[i].subType)
    }
    setCreateCouponData({...createCouponData, validForType: selectedValues});
  }

  function onRemoveCreatedType(removedItem) {
    let tempList = createCouponData.validForType;
    const removeIndex = tempList.findIndex((item) => item === removedItem);
    tempList.splice(removeIndex, 1);

    setCreateCouponData({...createCouponData, validForType: tempList})

    if (createCouponData.validForType.length === 0){
      delete createCouponData.validForType;
    }

  }

  // function onSelectUpdateType(selectedItem) {
  //   if (updateCouponData.validForType === undefined) {
  //     updateCouponData.validForType = []
  //   }
  //   var selectedValues = []
  //   for (let i=0; i < selectedItem.length; i++){
  //     selectedValues.push(selectedItem[i].subType)
  //   }
  //   setUpdateCouponData({...updateCouponData, validForType: selectedValues});
  // }

  // function onRemoveUpdateType(removedItem) {
  //   let tempList = updateCouponData.validForType;
  //   const removeIndex = tempList.findIndex((item) => item === removedItem);
  //   tempList.splice(removeIndex, 1);

  //   setCreateCouponData({...updateCouponData, validForType: tempList})

  //   if (updateCouponData.validForType.length === 0){
  //     delete updateCouponData.validForType;
  //   }
  // }

  const handleRefresh = () => {
    window.location.reload();
  };


  const handleAddCouponModalShow = (e) => {
    e.preventDefault();
    setAddCouponModal(true);
  }

  const handleAddCouponModalClose = () => {
    setAddCouponModal(false);
    setCreateCouponData({});
  }

  const handleUpdateCouponModalShow = (e, coupon) => {
    e.preventDefault();
    setUpdateCouponModal(true);
    setSelectedCoupon(coupon);
  }

  const handleUpdateCouponModalClose = () => {
    setUpdateCouponModal(false);
    setUpdateCouponData({});
    setSelectedCoupon(null);
  }

  const handleCouponStatus = (e, status, couponId) => {
    e.preventDefault();

    const payload = {couponId: couponId, status: !status}
    dispatch(
      CouponStatusAction(payload)
    ).then((res) => {
      if (res.status===200){
        // to fetch the updated results
        dispatch(GetSubscriptionCouponAction());
        toast.success(`Coupon Status Updated`,
          {position: toast.POSITION.TOP_RIGHT},
        );
      }
      else {
        console.log(res);
        toast.error(`${res.response.data.message}`,
          {position: toast.POSITION.TOP_RIGHT},
        );
      }
    }).catch(error => {
      toast.error(`Something Went Wrong`,
        {position: toast.POSITION.TOP_RIGHT,}
      );
    });

  }


  const handleAddCoupon = (e) => {
    e.preventDefault();

    if(!createCouponData.couponCode){
      toast.error(`Please Enter Coupon Code`,
        {position: toast.POSITION.TOP_RIGHT}
      )
    }
    else if(!createCouponData.couponDescription){
      toast.error(`Please Enter Coupon Description`,
        {position: toast.POSITION.TOP_RIGHT}
      )
    }
    else if(!createCouponData.discount){
      toast.error(`Please Enter Discount`,
        {position: toast.POSITION.TOP_RIGHT}
      )
    }
    else if(!createCouponData.expiryDate){
      toast.error(`Please Enter Expiry Date`,
        {position: toast.POSITION.TOP_RIGHT}
      )
    }
    else{
      createCouponData.expiryDate = new Date(createCouponData.expiryDate);

      const today = new Date();

      if(createCouponData.expiryDate.getTime() < today.getTime()){
        toast.error(`Please select a future date`,
          {position: toast.POSITION.TOP_RIGHT}
        )
      }
      else{
        dispatch(
          CreateCouponAction(createCouponData)
        ).then((res) => {
          if (res.status===200){
            dispatch(GetSubscriptionCouponAction());
            toast.success(`Coupon Created Successfully`,
              {position: toast.POSITION.TOP_RIGHT},
            );
          }
          else {
            console.log(res);
            toast.error(`${res.response.data.message}`,
              {position: toast.POSITION.TOP_RIGHT},
            );
          }
        }).catch(error => {
          toast.error(`Something Went Wrong`,
            {position: toast.POSITION.TOP_RIGHT,}
          );
        });

        handleAddCouponModalClose();
      
      }
    }
  }

  const handleUpdateCoupon = (e) => {
    e.preventDefault();

    updateCouponData = {...updateCouponData, couponId: selectedCoupon._id}

    if(updateCouponData.expiryDate){
      updateCouponData.expiryDate = new Date(updateCouponData.expiryDate);
      const today = new Date();
      if(updateCouponData.expiryDate.getTime() < today.getTime()){
        toast.error(`Please select a future date`,
          {position: toast.POSITION.TOP_RIGHT}
        )
        return;
      }
    }

    dispatch(
      UpdateCouponAction ( updateCouponData )
    ).then((res) => {
      if (res.status===202){
        dispatch(GetSubscriptionCouponAction());
        toast.success(`Coupon Updated Successfully`,
          {position: toast.POSITION.TOP_RIGHT},
        );
      }
      else {
        console.log(res);
        toast.error(`${res.response.data.message}`,
          {position: toast.POSITION.TOP_RIGHT},
        );
      }
    }).catch(error => {
      toast.error(`Something Went Wrong`,
        {position: toast.POSITION.TOP_RIGHT,}
      );
    });
    
    handleUpdateCouponModalClose();

  }


  return (
    <div>
      <Header />
      <Sidenav />

      <div class="WrapperArea">
        <div class="WrapperBox">
          <div class="Small-Wrapper">
            <div class="NewTitleBox">
              <div className="arrow-left">
                  <Link to="#" onClick={() => window.history.back()}>
                      <i class="fa fa-arrow-left arrow-back" aria-hidden="true"></i>
                  </Link>
                  <h4>Subscription Coupons</h4>
              </div>
            </div>

            
              <div class="Filter">
                <div class="form-group">
                  <a
                    class="Button"
                    onClick={(e) => handleAddCouponModalShow(e)}
                  >
                    Add Coupon
                  </a>
                </div>
              </div>

              {loader ? (<Loader/>) :
              (<div class="TableList">
                <table style={{ width: "130%" }}>
                  <thead>
                    <tr>
                      <th>Coupon Id</th>
                      <th>Coupon Code</th>
                      <th>Discount</th>
                      <th>Coupon Description</th>
                      <th>Expiry Date</th>
                      <th>Valid For Subscription Type</th>
                      <th>Coupon Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {couponList && couponList.map((item, i) => (
                      <tr>
                        <td>{item?.couponId}</td>
                        <td>{item?.couponCode}</td>
                        <td>{item?.discount}%</td>
                        <td>{item?.couponDescription}</td>
                        <td>{getDate(item?.expiryDate)}</td>
                        <td>{item?.validForType.length === 0 ? "All" : 
                            <div>
                              <p>
                                <span>{item?.validForType?.[0]}</span>
                                {item?.validForType?.[1] && <span>, {item?.validForType?.[1]}</span>}
                                {item?.validForType?.[2] && <span>, {item?.validForType?.[2]}</span>}
                              </p>
                            </div>
                          }
                        </td>
                        <td>
                            <button
                              disabled
                              className={
                                item?.is_active ? "subscriptionActive" : "subscriptionInactive"
                              }
                            >
                              {item?.is_active ? "Active" : "Inactive"} 
                            </button>
                        </td>
                        <td>
                          <div class="Actions">
                            <label class="switch">
                              <input
                                type="checkbox"
                                checked={item?.is_active}
                                onChange={(e) => handleCouponStatus(e, item?.is_active, item?._id)}
                              />
                              <span class="slider"></span>
                            </label>

                            <Link class="Green" onClick={(e) => handleUpdateCouponModalShow(e, item)}>
                              <i class="fa fa-pencil"></i>
                            </Link>
                          </div>
                        </td>
                      </tr>
                      ))}
                  </tbody>
                </table>
                {loader ? ("") : (couponList && couponList.length > 0) ? ("") : (<NoDataFound />)}
              </div>
              )} 


          </div>
        </div>
      </div>

      <Modal
        show={AddCouponModal}
        onHide={handleAddCouponModalClose}
        className="ModalBox "
      >
        <Modal.Body>  
          <h3 className="subscriptionUpdateModalheading">Create Coupon</h3>
      
          <div className="form-group">
              <label>Coupon Code</label>
                  <input
                      type={"text"}
                      className="form-control"
                      placeholder = "Enter Coupon Code"
                      required
                      onChange={(e) => (e.target.value) ? setCreateCouponData((createCouponData) => ({...createCouponData, couponCode: e.target.value})) : delete createCouponData.couponCode}                           
                  /> 
          </div>
          <div className="form-group">
              <label>Coupon Description</label>
                  <input
                    type={"text"}
                    className="form-control"
                    placeholder = "Enter Description"
                    required
                    onChange={(e) => (e.target.value) ? setCreateCouponData((createCouponData) => ({...createCouponData, couponDescription: e.target.value})) : delete createCouponData.couponDescription}                           
                  /> 
          </div>
          <div className="form-group">
              <label>Coupon Discount</label>
                  <input
                    type={"number"}
                    className="form-control"
                    placeholder = "Enter Discount"
                    required
                    onChange={(e) => (e.target.value) ? setCreateCouponData((createCouponData) => ({...createCouponData, discount: e.target.valueAsNumber})) : delete createCouponData.discount}                           
                  /> 
          </div>
          <div className="form-group">
              <label>Coupon Expiry Date</label>
                  <input
                    type={"date"}
                    className="form-control"
                    placeholder = "Enter Date"
                    required
                    onChange={(e) => (e.target.value) ? setCreateCouponData((createCouponData) => ({...createCouponData, expiryDate: e.target.valueAsNumber})) : delete createCouponData.expiryDate}                           
                  /> 
          </div>
          <div className="form-group">
              <label>Coupon Valid For</label>
              <Multiselect
                  options={subscriptionType}
                  selectedValues={subscriptionType.subType}
                  displayValue="subType"
                  onSelect={onSelectCreateType}
                  ref={dropDownRefCategory}
                  onRemove={onRemoveCreatedType}
                  resetSelectedValues={handleRefresh}
              />
          </div>
          
                             

          <div className="updateSubscriptionButtonContainer">
                <button className="Button" onClick={(e) => handleAddCoupon(e)}>
                    Add
                </button>
                <button className="Button Cancel" onClick={handleAddCouponModalClose}> 
                    Cancel
                </button>
          </div>

        </Modal.Body>
      </Modal>


      <Modal
        show={UpdateCouponModal}
        onHide={handleUpdateCouponModalClose}
        className="ModalBox "
      >
        <Modal.Body>  
          <h3 className="subscriptionUpdateModalheading">Update Coupon</h3>
      
          <div className="form-group">
              <label>Coupon Code</label>
                  <input
                      type={"text"}
                      className="form-control"
                      placeholder = {selectedCoupon?.couponCode}
                      onChange={(e) => (e.target.value) ? setUpdateCouponData((updateCouponData) => ({...updateCouponData, couponCode: e.target.value})) : delete updateCouponData.couponCode}                           
                  /> 
          </div>
          <div className="form-group">
              <label>Coupon Description</label>
                  <input
                    type={"text"}
                    className="form-control"
                    placeholder = {selectedCoupon?.couponDescription}
                    required
                    onChange={(e) => (e.target.value) ? setUpdateCouponData((updateCouponData) => ({...updateCouponData, couponDescription: e.target.value})) : delete updateCouponData.couponDescription}                           
                  /> 
          </div>
          <div className="form-group">
              <label>Coupon Discount</label>
                  <input
                    type={"number"}
                    className="form-control"
                    placeholder = {selectedCoupon?.discount}
                    required
                    onChange={(e) => (e.target.value) ? setUpdateCouponData((updateCouponData) => ({...updateCouponData, discount: e.target.valueAsNumber})) : delete updateCouponData.discount}                           
                  /> 
          </div>
          <div className="form-group">
              <label>Coupon Expiry Date</label>
                  <input
                    type={"date"}
                    className="form-control"
                    placeholder = {selectedCoupon?.expiryDate}
                    required
                    onChange={(e) => (e.target.value) ? setUpdateCouponData((updateCouponData) => ({...updateCouponData, expiryDate: e.target.valueAsNumber})) : delete updateCouponData.expiryDate}                           
                  /> 
          </div>
          {/* <div className="form-group">
              <label>Coupon Valid For</label>
                <Multiselect
                    options={subscriptionType}
                    selectedValues={subscriptionType.subType}
                    displayValue="subType"
                    onSelect={onSelectUpdateType}
                    ref={dropDownRefCategory}
                    onRemove={onRemoveUpdateType}
                    resetSelectedValues={handleRefresh}
                />
          </div> */}
          
                             

          <div className="updateSubscriptionButtonContainer">
                <button className="Button" onClick={(e) => handleUpdateCoupon(e)}>
                    Update
                </button>
                <button className="Button Cancel" onClick={handleUpdateCouponModalClose}> 
                    Cancel
                </button>
          </div>

        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SubscriptionCoupon;
