import { hotelConstant } from "../actionTypes";
const initialState = {
    reviewList:[],
    ratings:{},
    hotelList:[],
    hotelData:{},
    transactionDetails:[],
    transactionList:[],

    loader: false,
    error: {},
  };
  
  export default function hotelReducer(
    state = initialState,
    { type, payload }
    
  ) {
    switch (type) {
      case hotelConstant.HOTEL_LIST_INITIATE:
        return { ...state, loader: true, hotelList: [] };
  
      case hotelConstant.HOTEL_LIST_SUCCESS:
        return { ...state, loader: false, hotelList: payload.data };
  
      case hotelConstant.HOTEL_LIST_FAILURE:
        return { ...state, loader: false, error: payload };


      case hotelConstant.HOTEL_REVIEW_LIST_INITIATE:
        return { ...state, loader: true, reviewList: [] };
  
      case hotelConstant.HOTEL_REVIEW_LIST_SUCCESS:
        return { ...state, loader: false, reviewList: payload.data };
  
      case hotelConstant.HOTEL_REVIEW_LIST_FAILURE:
        return { ...state, loader: false, error: payload };


        case hotelConstant.HOTEL_RATING_INITIATE:
            return { ...state, loader: true, ratings: {} };
      
          case hotelConstant.HOTEL_RATING_SUCCESS:
            return { ...state, loader: false, ratings:payload.data };
      
          case hotelConstant.HOTEL_RATING_FAILURE:
            return { ...state, loader: false, error: payload };



            case hotelConstant.HOTEL_DETAILS_INITIATE:
              return { ...state, loader: true, hotelData: {} };
        
            case hotelConstant.HOTEL_DETAILS_SUCCESS:
              return { ...state, loader: false, hotelData:payload.data };
        
            case hotelConstant.HOTEL_DETAILS_FAILURE:
              return { ...state, loader: false, error: payload };
      
              case hotelConstant.HOTEL_TRANSACTION_INITIATE:
              return { ...state, loader: true, transactionList: [] };
        
            case hotelConstant.HOTEL_TRANSACTION_SUCCESS:
              return { ...state, loader: false, transactionList:payload.data };
        
            case hotelConstant.HOTEL_TRANSACTION_FAILURE:
              return { ...state, loader: false, error: payload };
  
    
  
              case hotelConstant.HOTEL_TRANSACTION_DETAILS_INITIATE:
                return { ...state, loader: true, transactionDetails: [] };
          
              case hotelConstant.HOTEL_TRANSACTION_DETAILS_SUCCESS:
                return { ...state, loader: false, transactionDetails:payload.data };
          
              case hotelConstant.HOTEL_TRANSACTION_DETAILS_FAILURE:
                return { ...state, loader: false, error: payload };

              case hotelConstant.EDIT_SPLITPERCENTAGE_INITIATE:
                return { ...state, loader: true};

              case hotelConstant.EDIT_SPLITPERCENTAGE_SUCCESS:
                return { ...state, loader: false};
                
              case hotelConstant.EDIT_SPLITPERCENTAGE_FAILURE:
                 return { ...state, loader: false, error: payload };
  
  
      default:
        return state;
    }
  }
  