import React, { useEffect, useState } from 'react'
import Header from './header'
import Sidenav from './sidenav'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux'
import { AssignOrderAction, AvailableStaffAction, OrderStatusAction } from '../redux/actions/restaurantAction'
import {toast} from 'react-toastify'
import { Link } from 'react-router-dom'
import ImageFetcher from './ImageFetcher'

const inititalState = {
    staffId: "",
    deliveryTime: "",
  
    errorMsg: {},
  };

  
  
const SpaOrderDetails = () => {
  const hotelDetails =  JSON.parse(window.localStorage.getItem("hotelDetails"))

    const {state} = useLocation()
    const {id} =useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [iState , updateState] = useState(inititalState)
    const [status, setStaus] = useState('')
    const [stopper, setStopper]=useState(false)



    const {staffId,deliveryTime, errorMsg } = iState
    const {availableStaffList} = useSelector((state)=>state.restaurantReducer)

    const [DeleteModal, setDelete] = useState(false);
    const handleDeleteModalClose = () => setDelete(false);
    const handleDeleteModalShow = (id) => {
      setDelete(true);
    };
  
    const grandTotal = state?.items?.reduce((accum, ele) => accum + ele?.totalPrice, 0);


    const handleChange = (e) => {
        const { name, value } = e.target;
    
        updateState({ ...iState, [name]: value });
      };
    
    const handleValidate = () => {
        let formIsValid = true;
        let errorMsg = {};
        if (!staffId) {
          formIsValid = false;
          errorMsg.staffId = "* Please select delivery boy.";
        }
    
   
        updateState({ ...iState, errorMsg: errorMsg });
    
        return formIsValid;
      };
    
    

      const handleAssign = (e) => {
        e.preventDefault();
    
        let formIsValid = handleValidate();
        setStopper(formIsValid)
        const hotelData = JSON.parse(localStorage.getItem("hotel"));
    
        if (formIsValid) {
          let data = {
            staffId,
            orderId: state?._id,
            hotelId:id,
    
            deliveryTime,
          };
    
          dispatch(AssignOrderAction(data))
            .then((res) => {
              if (res.data.success === true) {
                setStopper(false)
                toast.success(res.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
                navigate(`/spa-order-request/${id}`);
                updateState({ name: "", image: "", errorMsg: {} });
              } else {
                setStaus(false)
                toast.error(res.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            })
            .catch((err) => {
              alert(err);
            });
        }
      };
        const handleChangeStatus=(e)=>{
      e.preventDefault()
      setStaus(e.target.value)



    let data = {
      _id:state?._id,
      status:e.target.value
      
           };

    dispatch(OrderStatusAction(data))
      .then((res) => {
        if (res.data.success === true) {
          
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        alert(err);
      });


  }


    useEffect(()=>{
        dispatch(AvailableStaffAction({role:"spa", hotelId:id}))
        setStaus(state?.status)


    },[])


     

    return (
    <div>
        <Header />
        <Sidenav />
        <div class="WrapperArea"> 
        <div class="WrapperBox">

        <div class="HotelLocation HotelLocation2">
            <div class="back-btn">
            <Link to={`/spa-order-request/${id}`}>
                <i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back{" "}
              </Link>
            </div>

            <aside>
              <h3>{hotelDetails?.name}</h3>
              <p>
                <span>
                  <i class="fa fa-map-marker"></i>
                </span>{" "}
                {hotelDetails?.address}
              </p>
            </aside>
          </div>
            <div class="back-btn">
            </div>
            
            <div class="Small-Wrapper">
                <div class="orderReq">
                    <div class="order-head">
                        <figcaption>
                            <p>Order for Room Number: <b>{state?.room?.fullNumber}</b></p>
                            <p>Customer: <b>{state?.orderBy?.name}</b></p>
                            <p>Contact Number: <b>{state?.orderBy?.phone}</b></p>
                            <h4>Ordered on {moment(state?.orderby?.updateAt).format('ll')}</h4>
                        </figcaption>

                         <aside>
                         {((state?.status == "scheduled"||state?.status == "re-scheduled" ||state?.status == "inProgress"||state?.status == "pending")&&((status == "scheduled"||status == "re-scheduled" ||status == "inProgress"||status == "pending"))) && 

                            <div class="form-group">
                                <label>Order Status</label>
                                <select onChange={handleChangeStatus} value={status} class="form-control">
                                <option value="">--Select--</option>
                                {((state?.status=="pending"&& status=="pending")||(state?.status=="inProgress"&& status=="inProgress") ) &&<option value="scheduled">Scheduled</option>}
                        {((state?.status=="inProgress"&& status=="inProgress") ||(state?.status=="pending"&& status=="pending")) &&<option value="re-scheduled">Re-Scheduled</option>}


                                    <option value="cancelled">Cancel Order</option>

                                    
                                </select>
                            </div>}
                            <div className='form-group'>


                            </div>
                            <div className="form-group"></div>
                  {status == "scheduled" && (
                      <div class="earning">
                        <a
                          onClick={handleDeleteModalShow}
                          class="Red"
                          data-toggle="modal"
                          data-target="#assignModal"
                        >
                          {state?.deliverBy
                            ? "Re-assign for Delivery"
                            : "Assign for Delivery"}
                        </a>
                      </div>
                    )}                        </aside>
                    </div>
                    
                    <div class="MenuList">
                        <ul>
                            {state?.items?.map((ele)=>
                            <li>
                            <div class="MenuLeft">
                                <figure>
                                <ImageFetcher imageUrl={ele?.item?.image}/>
                                </figure>
                                <figcaption>
                                    <h3>{ele?.item?.name}</h3>
                                    {/* <p>
                                        <span>
                                            <img src="images/star.svg" alt="" />
                                        </span>
                                        4.5
                                        <a href="#">34 Reviews</a>
                                    </p> */}
                                    <h4><span>{ele?.item?.servingSize}</span></h4>
                                    {/* <h4><span>Expected Delivery Time By 20 Mins</span></h4> */}
                                </figcaption>
                            </div>
                            <div class="MenuRight">
                                <h5>{ele?.totalPrice} INR <span>+Taxes</span></h5>
                                <p>Qnty: {ele?.qty}</p>
                            </div>
                        </li>
                            )}
                            
                            
                        </ul>
                    </div>
                </div>
            <div class="total-bill">
                {/* <h3>Instructions:</h3>
                <p>{state?.instruction}</p> */}
                <h4>Total Bill:</h4>
                <div class="TableList">
                    
                    <table>
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th>Qnty</th>
                                <th>Amount</th>
                            </tr>
                        </thead>

                        <tbody>
                            {state?.items?.map((ele)=>
                                                        <tr>
                                                        <td>{ele?.item?.name}</td>
                                                        <td>{ele?.qty}</td>
                                                        <td>{ele?.totalPrice} INR</td>
                                                                                        
                                                    </tr>
                        
                            )}
                             
                        </tbody>

                        <tfoot>
                        <tr>
                            <td>GST {state?.amount?.tax?.taxPercent} %</td>
                            <td>-</td>
                            <td>{state?.amount?.tax?.taxAmount} INR</td>
                        </tr>
                        {state?.amount?.coupon &&<tr>
                            <td> Dicount Coupon ({state?.amount?.coupon?.couponType?.charAt(0)?.toUpperCase() +
                            state?.amount?.coupon?.couponType?.slice(1)} )  </td>
                            <td>-</td>
                            <td>{state?.amount?.coupon?.discountAmount?.toFixed(2)} INR</td>
                            
                        </tr>}


                        <tr>
                            <td>Total Amount</td>
                            <td>-</td>
                            <td>{state?.amount?.totalAmount} INR</td>
                        </tr>                            
                        </tfoot>
                    </table>
                </div>
            </div>
            </div>

        </div> 
    </div>
    <Modal
        show={DeleteModal}
        onHide={handleDeleteModalClose}
        className="ModalBox "
      >
        <Modal.Body>
          <div className="Category">
            <button className="CloseModal" onClick={handleDeleteModalClose}>
              ×
            </button>
            <h3>Assign order for Delivery?</h3>
            <div className="form-group">
              <label>Select Delivery Boy for delivery</label>
              <select
                onChange={handleChange}
                name="staffId"
                value={staffId}
                className="form-control"
              >
                <option value="">select</option>
                {availableStaffList?.map((item) => (
                  <option value={item?._id}>{item?.name}</option>
                ))}
              </select>
              {errorMsg.staffId && !staffId && (
                <p style={{ color: "red" }}>{errorMsg.staffId}</p>
              )}

              {/* <label>Delivery Time</label>
              <input
                className="form-control"
                type="time"
                value={deliveryTime}
                name="deliveryTime"
                onChange={handleChange}
              />
              {errorMsg.deliveryTime && !deliveryTime && (
                <p style={{ color: "red" }}>{errorMsg.deliveryTime}</p>
              )} */}
            </div>
            <button disabled={stopper} onClick={handleAssign} className="Button">
              Assign
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default SpaOrderDetails