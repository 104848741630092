import React, { useState, useEffect } from 'react'
import Logo  from "../assets/image/logo.png"

import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { AdminLoginAction, TwoStepAction } from '../redux/actions/authAction';
import { useNavigate ,Link} from 'react-router-dom';

const initialState = {
    email:"",
    password:"",
    rememberMe: false,

    errorMsg: {},
  };
  

const Login = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    
  
    const [iState, updateState] = useState(initialState);
    const { email, password, errorMsg, rememberMe } = iState;
    const [password1Shown, setPassword1Shown] = useState(false);

    const toggle1PasswordVisiblity = () => {
      setPassword1Shown(password1Shown ? false : true);
    };
  
  
    let handleValidation = () => {
      let errorMsg = {};
      let formIsValid = true;
  
      if (!email) {
        errorMsg.email = "* Please enter email";
        formIsValid = false;
    } else if (!/^.+?@.+?\..+$/.test(email)) {
        errorMsg.email = "* Email format is not valid";
        formIsValid = false;
      }


      if (!password) {
        errorMsg.password = "* Please enter password";
        formIsValid = false;
    } 

  
      updateState({ ...iState, errorMsg: errorMsg });
      return formIsValid;
    };
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      updateState({ ...iState, [name]: value });
    };
  

    const handleCheck =(e)=>{
      let input = e.target.checked;
      updateState({...iState, rememberMe:input})

      
  }


useEffect(()=>{
if (localStorage.rememberMe && localStorage.email !== "") {
  updateState({...iState,
    rememberMe: true,
    email: localStorage.email,
    password: localStorage.password
  });
}


},[])


  
    
    const handleSubmit = (e) => {
      e.preventDefault();

      if (rememberMe && email !== "") {
        localStorage.email = email;
        localStorage.password = password;
        localStorage.rememberMe = rememberMe;
      }

  
      let formIsValid = handleValidation();
  
      if (formIsValid) {
        var data = {
          email : email?.toLocaleLowerCase()?.trim(),
          password :password?.trim(),
        };
        dispatch(AdminLoginAction(data))
          .then((res) => {
            if (res.status === 200) {

              window.localStorage.setItem("admin", JSON.stringify(res.data.data));

              dispatch(TwoStepAction({email : email?.toLocaleLowerCase()?.trim()})).then((res) => {
                if (res.status === 200) {

                  navigate("/otp", {state:{otp:res.data.data.otp}})
                  


                }})


            }
            else {
              toast.error(res.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          })

        }}
   
    
  return (
    <div> <div class="LoginArea">
    <div class="LoginBox">
        <figure>
            <img src={Logo} alt="" />
        </figure>
        <form>
            <h3>Admin Login</h3>
            <h5>Enter your email Id and password to access <br /> admin panel.</h5>
            <div class="form-group">
                <label>Enter your email ID</label>
                <input
                  name="email"
                  value={email}
                  onChange={handleChange}
                  placeholder="Email"
                  type="text"
                  className="form-control"
                />
                {errorMsg.email && <p style={{ color: "red" }}>{errorMsg.email}</p>}      
                      </div>
            <div class="form-group">
                <label>Enter your password</label>
                <input
                  name="password"
                  type={password1Shown ? "text" : "password"}
                  value={password}
                  onChange={handleChange}
                  className="form-control"
                  placeholder="Password"
                />
                <span
                  onClick={toggle1PasswordVisiblity}
                  className={password1Shown ? "Icon cross" : "Icon"}
                >
                {password1Shown ? (
                  <i className="fa fa-eye"></i>
                ) : (
                  <i class="fa fa-eye-slash" aria-hidden="true"></i>
                )}
                </span>

                {errorMsg.password && (
                  <p style={{ color: "red" }}>{errorMsg.password}</p>
                )}
            </div>
            <div class="form-group form-group-checkBox">
                <label class="CheckBox">
                    Remember Me
                    <input type="checkbox" checked={rememberMe}   onChange={handleCheck} />
                    <span class="checkmark"></span>
                </label>
                <div class="Checkboxs">
                    <Link to="/forgot">Forgot password?</Link>
                </div>
            </div>

            <a onClick={handleSubmit} class="Button">Login</a>
        </form>
    </div>
</div></div>
  )
}

export default Login