import React from 'react'
import Header from './header'
import Sidenav from './sidenav'

import DashboardImg  from '../assets/image/dashboard-img.png';
import LocationImg from '../assets/image/location.png'

import { Link } from 'react-router-dom';

const CancelSubscription = () => {
  return (
    <div>
        <Header />
        <Sidenav />
        <div class="WrapperArea">
        <div class="WrapperBox">

            <div class="Small-Wrapper">
                <div class="TitleBox">
                    <h4 class="Title">Cancellation & Refund Subscriptions</h4>
                </div>

                <div class="Filter">
                    <div class="form-group">
                        <label></label>
                        <input type="text" class="form-control" placeholder="Search" />
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="order-address">
                            <div class="order-address-body">
                                <div class="UserHead">
                                    <figure>
                                    <img src={DashboardImg} alt="" />
                                    </figure>
                                    <figcaption>
                                        <h3>The Park Inn</h3>
                                        <h6>
                                        <span><img src={LocationImg} alt="" /></span> Nehru Palace, New Delhi
                                        </h6>
                                        <p>Subscription Purchased</p>
                                        <h4>6 Months | <span>4000 INR</span></h4>
                                    </figcaption>
                                </div>

                                <div class="order-address-footer">
                                    <p>Purchased on: <b>22 Jan 2022</b></p>
                                    <p>Valid Till: <b>22 Jan 2022</b></p>
                                    <p>Cancel Requested on</p>
                                    <h3>24 October 2023</h3>
                                    <a href="#" class="Cancellation">Approve Cancellation</a>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div class="order-address">
                            <div class="order-address-body">
                                <div class="UserHead">
                                    <figure>
                                    <img src={DashboardImg} alt="" />
                                    </figure>
                                    <figcaption>
                                        <h3>The Park Inn</h3>
                                        <h6>
                                        <span><img src={LocationImg} alt="" /></span> Nehru Palace, New Delhi
                                        </h6>
                                        <p>Subscription Purchased</p>
                                        <h4>6 Months | <span>4000 INR</span></h4>
                                    </figcaption>
                                </div>

                                <div class="order-address-footer">
                                    <p>Purchased on: <b>22 Jan 2022</b></p>
                                    <p>Valid Till: <b>22 Jan 2022</b></p>
                                    <p>Cancel Requested on</p>
                                    <h3>24 October 2023</h3>
                                    <a href="#" class="Cancellation">
                                        <span><img src="image/check.svg" alt="" /></span>
                                        Request Approved
                                    </a>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div class="order-address">
                            <div class="order-address-body">
                                <div class="UserHead">
                                    <figure>
                                        <img src="image/dashboard-img.png" alt="" />
                                    </figure>
                                    <figcaption>
                                        <h3>The Park Inn</h3>
                                        <h6>
                                            <span><img src="image/location.png" alt="" /></span> Nehru Palace, New Delhi
                                        </h6>
                                        <p>Subscription Purchased</p>
                                        <h4>6 Months | <span>4000 INR</span></h4>
                                    </figcaption>
                                </div>

                                <div class="order-address-footer">
                                    <p>Purchased on: <b>22 Jan 2022</b></p>
                                    <p>Valid Till: <b>22 Jan 2022</b></p>
                                    <p>Cancel Requested on</p>
                                    <h3>24 October 2023</h3>
                                    <a href="#" class="Cancellation">Approve Cancellation</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default CancelSubscription