import React, { useEffect, useState } from "react";
import Header from "./header";
import Sidenav from "./sidenav";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { AvailableStaffAction } from "../redux/actions/restaurantAction";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import TimePicker from "@semcore/time-picker";
import { Box } from "@semcore/ui/flex-box";
import ImageFetcher from './ImageFetcher'
import {
  RequestAssignAction,
  RequestStatusAction,
} from "../redux/actions/housekeepingAction";

const inititalState = {
  staffId: "",

  errorMsg: {},
};

const statusInitalState = {
  status: "",

  deliveryTime: "",

  errorStatusMsg: {},
};
const HousekeepingOrderDetails = () => {
  const { state } = useLocation();
  const {id} = useParams()
  const [detailsData, setDetailsData] = useState(state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [iState, updateState] = useState(inititalState);
  const [iStatus, updateStatus] = useState(statusInitalState);

  const [tempDelivery, setTempDelivery] = useState(false);

  const { staffId, errorMsg } = iState;
  const { status, deliveryTime, errorStatusMsg } = iStatus;

  const { availableStaffList } = useSelector(
    (state) => state.restaurantReducer
  );

  const [DeleteModal, setDelete] = useState(false);
  const handleDeleteModalClose = () => setDelete(false);
  const handleDeleteModalShow = (id) => {
    setDelete(true);
  };

  const [StatusModal, setStatusModal] = useState(false);
  const handleStatusModalClose = () => setStatusModal(false);
  const handleStatusModalShow = (id) => {
    setStatusModal(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    updateState({ ...iState, [name]: value });
  };

  const handleValidate = () => {
    let formIsValid = true;
    let errorMsg = {};
    if (!staffId) {
      formIsValid = false;
      errorMsg.staffId = "* Please select staff.";
    }

    updateState({ ...iState, errorMsg: errorMsg });

    return formIsValid;
  };

  const handleValidateStatus = () => {
    let formIsValid = true;
    let errorStatusMsg = {};
    if (!status) {
      formIsValid = false;
      errorStatusMsg.status = "* Please select select status.";
    }
    if (deliveryTime == "null") {
      if (!deliveryTime) {
        formIsValid = false;
        errorStatusMsg.deliveryTime = "* Please enter delivery time.";
      }
    }

    updateStatus({ ...iStatus, errorStatusMsg: errorStatusMsg });

    return formIsValid;
  };

  const handleAssign = (e) => {
    e.preventDefault();

    let formIsValid = handleValidate();

    if (formIsValid) {
      let data = {
        staffId,
        orderId: state?._id,
        hotelId:id
      };

      dispatch(RequestAssignAction(data))
        .then((res) => {
          if (res.data.success === true) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            navigate(`/housekeeping-order-request/${id}`);
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          alert(err);
        });
    }
  };

  const handleChangeStatus = (e) => {
    e.preventDefault();

    let formIsValid = handleValidateStatus();
    const today = new Date(
      `${moment().format("YYYY-MM-DD")}T${deliveryTime}:00`
    );

    if (formIsValid) {
      let data;
      if (status == "onProcess") {
        data = {
          _id: state?._id,
          status: status,
          hotelId:id,
          deliveryTime: today.toISOString(),
        };
      } else {
        data = {
          _id: state?._id,
          status: status,
          hotelId:id
        };
      }

      dispatch(RequestStatusAction(data))
        .then((res) => {
          if (res.data.success === true) {
            setStatusModal(false);
            setDetailsData({
              ...detailsData,
              status: status,
              deliveryTime: deliveryTime,
            });
            updateStatus({
              iStatus,
              status: "",

              errorStatusMsg: {},
            });
            setTempDelivery(deliveryTime);

            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          alert(err);
        });
    }
  };

  useEffect(() => {
    dispatch(AvailableStaffAction({ role: "delivery_person",hotelId:id }));
    setTempDelivery(state?.deliveryTime);

    updateStatus({ ...iStatus, status: state?.status });
  }, []);

  return (
    <div>
      <Header />
      <Sidenav />
      <div class="WrapperArea">
        <div class="WrapperBox">
          <div class="back-btn">
            <Link to={`/housekeeping-order-request/${id}`}>
              <i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back
            </Link>
          </div>

          <div class="Small-Wrapper">
            <div class="orderReq">
              <div class="order-head">
                <figcaption>
                  <p>
                    Order for Room Number: <b>{state?.room?.fullNumber}</b>
                  </p>
                  <p>
                    Customer: <b>{state?.requestBy?.name}</b>
                  </p>
                  <p>
                    Contact Number: <b>{state?.requestBy?.phone}</b>
                  </p>
                  <p>
                    Status:{" "}
                    <b>
                      {" "}
                      {detailsData?.status?.charAt(0)?.toUpperCase() +
                        detailsData?.status?.slice(1)}
                    </b>
                  </p>

                  <h4>
                    Ordered on {moment(state?.requestBy?.updateAt).format("ll")}
                  </h4>
                </figcaption>
                {(detailsData?.status == "onProcess" ||detailsData?.status == "requested") && (
                  <aside>
                    <div class="form-group">
                      <button
                        onClick={handleStatusModalShow}
                        className="Button"
                      >
                        Change status
                      </button>
                    </div>
                    <div className="form-group"></div>
                      <div class="earning">
                        <a
                          onClick={handleDeleteModalShow}
                          class="Red"
                          data-toggle="modal"
                          data-target="#assignModal"
                        >
                        {detailsData?.hasOwnProperty('serviceBy')
                         
                          ? "Re-assign for Housekeeping"
                          :"Assign for Housekeeping"
                        }
                        </a>
                      </div>
                  </aside>
                )}
               
              </div>

              <div class="MenuList">
                <ul>
                  <li>
                    <div class="MenuLeft">
                      <figure>
                      <ImageFetcher imageUrl={state?.image}/>
                      </figure>
                      <figcaption>
                        <h3>{state?.name}</h3>
                        {/* <p>
                                        <span>
                                            <img src="images/star.svg" alt="" />
                                        </span>
                                        4.5
                                        <a href="#">34 Reviews</a>
                                    </p> */}
                        {/* <h4><span>{ele?.item?.servingSize}</span></h4> */}
                        {/* <h4><span>Expected Delivery Time By 20 Mins</span></h4> */}
                      </figcaption>
                    </div>
                    {/* <div class="MenuRight">
                                <h5>{ele?.totalPrice} INR <span>+Taxes</span></h5>
                                <p>Qnty: {ele?.qty}</p>
                            </div> */}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={DeleteModal}
        onHide={handleDeleteModalClose}
        className="ModalBox "
      >
        <Modal.Body>
          <div className="Category">
            <button className="CloseModal" onClick={handleDeleteModalClose}>
              ×
            </button>
            <h3>Assign staff for housekeeping?</h3>
            <div className="form-group">
              <label>Select staff</label>
              <select
                onChange={handleChange}
                name="staffId"
                value={staffId}
                className="form-control"
              >
                <option value="">select</option>
                {availableStaffList?.map((item) => (
                  <option value={item?._id}>{item?.name}</option>
                ))}
              </select>
              {errorMsg.staffId && !staffId && (
                <p style={{ color: "red" }}>{errorMsg.staffId}</p>
              )}
            </div>
            <button onClick={handleAssign} className="Button">
              Assign
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={StatusModal}
        onHide={handleStatusModalClose}
        className="ModalBox "
      >
        <Modal.Body>
          <div className="Category">
            <button className="CloseModal" onClick={handleStatusModalClose}>
              ×
            </button>
            <h3>Change Status</h3>
            <div className="form-group">
              <label>Select status</label>
              <select
                value={status}
                onChange={(e) =>
                  updateStatus({ ...iStatus, status: e.target.value })
                }
                class="form-control"
              >
                <option value="">--Select--</option>
                <option value="onProcess">On Process</option>
                <option value="cancelled">Cancelled</option>
              </select>
              {errorStatusMsg.status && (
                <p style={{ color: "red" }}>{errorStatusMsg.status}</p>
              )}

              {(tempDelivery == null || tempDelivery == "") && (
                <>
                  <label>Delivery Time</label>
                  <input
                    type={"time"}
                    onChange={(e) =>
                      updateStatus({ ...iStatus, deliveryTime: e.target.value })
                    }
                    className="form-control"
                  />

                  {errorStatusMsg.deliveryTime && (
                    <p style={{ color: "red" }}>
                      {errorStatusMsg.deliveryTime}
                    </p>
                  )}
                </>
              )}
            </div>
            <button onClick={handleChangeStatus} className="Button">
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default HousekeepingOrderDetails;
