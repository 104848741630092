import { authConstant } from "../actionTypes";

import axios from "../../axiosInstance";
import { baseUrl } from "../../config/config";
import { isLoggedIn } from "../../utils";

//  REGISTER

export const signUpInitiate = () => ({
  type: authConstant.SIGNUP_INITIATE,
});

export const signUpSuccess = (data) => ({
  type: authConstant.SIGNUP_SUCCESS,
  payload: data,
});

export const signUpFailure = (data) => ({
  type: authConstant.SIGNUP_FAILURE,
  payload: data,
});

export const SignUpAction = (payload) => {
  return (dispatch) => {
    dispatch(signUpInitiate());
    return new Promise((resolve, reject) =>
      axios
        .post(`${baseUrl}/projectAdmin/projectSignUp`, payload)
        .then((response) => {
          const data = response.data;
          if (data.code && data.status === 200) {
            dispatch(signUpSuccess(data));
          } else {
            dispatch(signUpFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(signUpFailure(err));
          reject(err);
        })
    );
  };
};

//  VERIFY ADMIN

export const verifyAdminInitiate = () => ({
  type: authConstant.VERIFY_ADMIN_INITIATE,
});

export const verifyAdminSuccess = (data) => ({
  type: authConstant.VERIFY_ADMIN_SUCCESS,
  payload: data,
});

export const verifyAdminFailure = (data) => ({
  type: authConstant.VERIFY_ADMIN_SUCCESS,
  payload: data,
});

export const VerifyAdminAction = (payload) => {
  return (dispatch) => {
    dispatch(verifyAdminInitiate());
    return new Promise((resolve, reject) =>
      axios
        .post(`${baseUrl}/projectAdmin/verifyProjectAdmin`, payload)
        .then((response) => {
          const data = response.data;
          if (data.code && data.status === 200) {
            dispatch(verifyAdminSuccess(data));
          } else {
            dispatch(verifyAdminFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(verifyAdminFailure(err));
          reject(err);
        })
    );
  };
};

//  VERIFY USER

export const verifyUserInitiate = () => ({
  type: authConstant.VERIFY_ADMIN_INITIATE,
});

export const verifyUserSuccess = (data) => ({
  type: authConstant.VERIFY_ADMIN_SUCCESS,
  payload: data,
});

export const verifyUserFailure = (data) => ({
  type: authConstant.VERIFY_ADMIN_SUCCESS,
  payload: data,
});

export const VerifyUserAction = (payload) => {
  const token = isLoggedIn("projectAdminData");

  return (dispatch) => {
    dispatch(verifyUserInitiate());
    return new Promise((resolve, reject) =>
      axios
        .post(`${baseUrl}/projectAdmin/verifyUser`, payload, {
          headers: { authorization: `${token}` },
        })
        .then((response) => {
          const data = response.data;
          if (data.code && data.status === 200) {
            dispatch(verifyUserSuccess(data));
          } else {
            dispatch(verifyUserFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(verifyUserFailure(err));
          reject(err);
        })
    );
  };
};

//  VERIFY OTP

export const verifyOtpInitiate = () => ({
  type: authConstant.VERIFY_OTP_INITIATE,
});

export const verifyOtpSuccess = (data) => ({
  type: authConstant.VERIFY_OTP_SUCCESS,
  payload: data,
});

export const verifyOtpFailure = (data) => ({
  type: authConstant.VERIFY_OTP_FAILURE,
  payload: data,
});

export const VerifyOtpAction = (payload) => async (dispatch) => {
  try {
    dispatch(adminLoginInitiate());
    const response = await axios.post(`${baseUrl}/admin/verifyOtp`, payload);
    if (response.status == 200) {
      dispatch(adminLoginSuccess(response.data));
    } else {
      dispatch(adminLoginFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(adminLoginFailure(err));
    return err;
  }
};

//  CREATE PIN

export const createPinInitiate = () => ({
  type: authConstant.CREATE_PIN_INITIATE,
});

export const createPinSuccess = (data) => ({
  type: authConstant.CREATE_PIN_SUCCESS,
  payload: data,
});

export const createPinFailure = (data) => ({
  type: authConstant.CREATE_PIN_FAILURE,
  payload: data,
});

export const CreatePinAction = (payload) => async (dispatch) => {
  try {
    dispatch(createPinInitiate());
    const response = await axios.post(`${baseUrl}/admin/createPin`, payload);
    if (response.status == 200) {
      dispatch(createPinSuccess(response.data));
    } else {
      dispatch(createPinFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(createPinFailure(err));
    return err;
  }
};




export const verifyPinInitiate = () => ({
  type: authConstant.CREATE_PIN_INITIATE,
});

export const verifyPinSuccess = (data) => ({
  type: authConstant.CREATE_PIN_SUCCESS,
  payload: data,
});

export const verifyPinFailure = (data) => ({
  type: authConstant.CREATE_PIN_FAILURE,
  payload: data,
});

export const verifyPinAction = (payload) => async (dispatch) => {
  try {
    dispatch(verifyPinInitiate());
    const response = await axios.post(`${baseUrl}/admin/verifyPinPassword`, payload);
    if (response.status == 200) {
      dispatch(verifyPinSuccess(response.data));
    } else {
      dispatch(verifyPinFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(verifyPinFailure(err));
    return err;
  }
};


//  LOGIN

export const adminLoginInitiate = () => ({
  type: authConstant.LOGIN_INITIATE,
});

export const adminLoginSuccess = (data) => ({
  type: authConstant.LOGIN_SUCCESS,
  payload: data,
});

export const adminLoginFailure = (data) => ({
  type: authConstant.LOGIN_FAILURE,
  payload: data,
});

export const AdminLoginAction = (payload) => async (dispatch) => {
  try {
    dispatch(adminLoginInitiate());
    const response = await axios.post(`${baseUrl}/admin/login`, payload);
    if (response.status == 200) {
      dispatch(adminLoginSuccess(response.data));
    } else {
      dispatch(adminLoginFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(adminLoginFailure(err));
    return err;
  }
};

//   LOG OUT

export const logoutUserInitiate = () => ({
  type: authConstant.LOGOUT_INITIATE,
});

export const logoutUserSuccess = (data) => ({
  type: authConstant.LOGOUT_SUCCESS,
  payload: data,
});

export const logoutUserFailure = (data) => ({
  type: authConstant.LOGOUT_FAILURE,
  payload: data,
});

export const LogoutUserAction = (payload) => async (dispatch) => {
  const token = isLoggedIn("admin");

  try {
    dispatch(logoutUserInitiate());
    const response = await axios.post(`${baseUrl}/admin/adminLogout`, payload, {
      headers: { authorization: `${token}` },
    });

    if (response.status == 200) {
      dispatch(logoutUserSuccess(response.data));
    } else {
      dispatch(logoutUserFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(logoutUserFailure(err));
    return err;
  }
};

// SEND OTP

export const loginOtpInitiate = () => ({
  type: authConstant.LOGIN_OTP_INITIATE,
});

export const loginOtpSuccess = (data) => ({
  type: authConstant.LOGIN_OTP_SUCCESS,
  payload: data,
});

export const loginOtpFailure = (data) => ({
  type: authConstant.LOGIN_OTP_FAILURE,
  payload: data,
});

export const LoginOtpAction = (payload) => {
  return (dispatch) => {
    dispatch(loginOtpInitiate());
    return new Promise((resolve, reject) =>
      axios
        .post(`${baseUrl}/projectAdmin/sendPhoneOtpForProjectAdmin`, payload)
        .then((response) => {
          const data = response.data;
          if (data.code && data.status === 200) {
            dispatch(loginOtpSuccess(data));
          } else {
            dispatch(loginOtpFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(loginOtpFailure(err));
          reject(err);
        })
    );
  };
};

// SEND OTP

export const emailOtpInitiate = () => ({
  type: authConstant.EMAIL_OTP_INITIATE,
});

export const emailOtpSuccess = (data) => ({
  type: authConstant.EMAIL_OTP_SUCCESS,
  payload: data,
});

export const emailOtpFailure = (data) => ({
  type: authConstant.EMAIL_OTP_FAILURE,
  payload: data,
});

export const EmailOtpAction = (payload) => async (dispatch) => {
  try {
    dispatch(adminLoginInitiate());
    const response = await axios.post(
      `${baseUrl}/admin/forgetpassword/link`,
      payload
    );
    if (response.status == 200) {
      dispatch(adminLoginSuccess(response.data));
    } else {
      dispatch(adminLoginFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(adminLoginFailure(err));
    return err;
  }
};

// UPDATE PASSWORD

export const updatePasswordInitiate = () => ({
  type: authConstant.UPDATE_PASSWORD_INITIATE,
});

export const updatePasswordSuccess = (data) => ({
  type: authConstant.UPDATE_PASSWORD_SUCCESS,
  payload: data,
});

export const updatePasswordFailure = (data) => ({
  type: authConstant.UPDATE_PASSWORD_FAILURE,
  payload: data,
});

export const UpdatePasswordAction = (payload) => async (dispatch) => {
  const token = isLoggedIn("admin");

  try {
    dispatch(updatePasswordInitiate());
    const response = await axios.post(
      `${baseUrl}/admin/ChangePassword`,
      payload,
      {
        headers: { Authorization: `${token}` },
      }
    );

    if (response.status == 200) {
      dispatch(updatePasswordSuccess(response.data));
    } else {
      dispatch(updatePasswordFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(updatePasswordFailure(err.response));
    return err;
  }

  // // return dispatch => {
  // //     dispatch(updatePasswordInitiate())

  // //     return new Promise((resolve, reject) =>
  // //         axios.post(`${baseUrl}/admin/changePassword`,payload,{
  // //             headers: { Authorization: `${token}`},})
  // //             .then(response => {
  // //                 const data = response.data
  // //                 if (data && data.status === 200) {
  // //                     dispatch(updatePasswordSuccess(data))

  // //                 }
  // //                 else {
  // //                     dispatch(updatePasswordFailure(data))
  // //                 }
  // //                 resolve(data)
  // //             }).catch(err => {
  // //                 dispatch(updatePasswordFailure(err))
  // //                 // reject(err);
  // //             })

  // //     );
  // }
};

//  FORGET PASSWORD

export const forgetPasswordInitiate = () => ({
  type: authConstant.FORGET_PASSWORD_INITIATE,
});

export const forgetPasswordSuccess = (data) => ({
  type: authConstant.FORGET_PASSWORD_SUCCESS,
  payload: data,
});

export const forgetPasswordFailure = (data) => ({
  type: authConstant.FORGET_PASSWORD_FAILURE,
  payload: data,
});

export const ForgetPasswordAction = (payload) => async (dispatch) => {
  try {
    dispatch(forgetPasswordInitiate());
    const response = await axios.post(
      `${baseUrl}/admin/forgetPassword`,
      payload
    );
    if (response.status == 200) {
      dispatch(forgetPasswordSuccess(response.data));
    } else {
      dispatch(forgetPasswordFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(adminLoginFailure(err));
    return err;
  }
};

// GET DETAILS

export const adminDetailsInitiate = () => ({
  type: authConstant.ADMIN_DETAILS_INITIATE,
});

export const adminDetailstSuccess = (data) => ({
  type: authConstant.ADMIN_DETAILS_SUCCESS,
  payload: data,
});

export const adminDetailstFailure = (data) => ({
  type: authConstant.ADMIN_DETAILS_FAILURE,
  payload: data,
});

export const AdminDetailsAction = (payload) => {
  const token = isLoggedIn("adminData");

  return (dispatch) => {
    dispatch(adminDetailsInitiate());
    return new Promise((resolve, reject) =>
      axios
        .post(baseUrl + "/admin/adminDetails", payload, {
          headers: { authorization: `${token}` },
        })
        .then((response) => {
          const data = response.data;
          if (data && data.status === 200) {
            dispatch(adminDetailstSuccess(data));
          } else {
            dispatch(adminDetailstFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(adminDetailstFailure(err));
          reject(err);
        })
    );
  };
};

export const adminUpdateDetailsInitiate = () => ({
  type: authConstant.ADMIN_UPDATE_DETAILS_INITIATE,
});

export const adminUpdateDetailsSuccess = (data) => ({
  type: authConstant.ADMIN_UPDATE_DETAILS_SUCCESS,
  payload: data,
});

export const adminUpdateDetailsFailure = (data) => ({
  type: authConstant.ADMIN_UPDATE_DETAILS_FAILURE,
  payload: data,
});

// UPDATE DETAILS

export const AdminUpdateDetailsAction = (payload) => async (dispatch) => {
  const token = isLoggedIn("admin");

  try {
    dispatch(adminUpdateDetailsInitiate());
    const response = await axios.put(
      `${baseUrl}/admin/hotel/updateHotel`,
      payload,
      {
        headers: { authorization: `${token}` },
      }
    );
    if (response.status == 200) {
      dispatch(adminUpdateDetailsSuccess(response.data));
    } else {
      dispatch(adminUpdateDetailsFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(adminUpdateDetailsFailure(err));
    return err;
  }
};

//  VERIFY LOGIN OTP

export const verifyLoginOtpInitiate = () => ({
  type: authConstant.VERIFY_LOGIN_OTP_INITIATE,
});

export const verifyLoginOtpSuccess = (data) => ({
  type: authConstant.VERIFY_LOGIN_OTP_SUCCESS,
  payload: data,
});

export const verifyLoginOtpFailure = (data) => ({
  type: authConstant.VERIFY_LOGIN_OTP_FAILURE,
  payload: data,
});

export const VerifyLoginOtpAction = (payload) => {
  return (dispatch) => {
    dispatch(verifyLoginOtpInitiate());
    return new Promise((resolve, reject) =>
      axios
        .post(`${baseUrl}/projectAdmin/otpLogin`, payload)
        .then((response) => {
          const data = response.data;
          if (data.code && data.status === 200) {
            dispatch(verifyLoginOtpSuccess(data));
          } else {
            dispatch(verifyLoginOtpFailure(data));
          }
          resolve(data);
        })
        .catch((err) => {
          dispatch(verifyLoginOtpFailure(err));
          reject(err);
        })
    );
  };
};

export const reviewListInitiate = (data) => ({
  type: authConstant.REVIEW_LIST_INITIATE,
  payload: data,
});

export const reviewListSuccess = (data) => ({
  type: authConstant.REVIEW_LIST_SUCCESS,
  payload: data,
});

export const reviewListFailure = (data) => ({
  type: authConstant.REVIEW_LIST_FAILURE,
  payload: data,
});

export const ReviewListAction = (payload) => async (dispatch) => {
  const token = isLoggedIn("admin");

  try {
    dispatch(reviewListInitiate());
    const response = await axios.get(`${baseUrl}/admin/service/review/list`, {
      headers: { Authorization: `${token}` },
      params: payload,
    });

    if (response.status >= 200 && response.status <= 300) {
      dispatch(reviewListSuccess(response.data));
    } else {
      dispatch(reviewListFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(reviewListFailure(err));
    return err;
  }
};

// SIGNUP BONUS
export const SignupBonusInitiate = (data) => ({
  type: authConstant.SIGNUP_BONUS_INITIATE,
  payload: data,
});

export const SignupBonusSuccess = (data) => ({
  type: authConstant.SIGNUP_BONUS_SUCCESS,
  payload: data,
});

export const SignupBonusFailure = (data) => ({
  type: authConstant.SIGNUP_BONUS_FAILURE,
  payload: data,
});

export const SignupBonusAction = (payload) => async (dispatch) => {
  const token = isLoggedIn("admin");

  try {
    dispatch(SignupBonusInitiate());
    const response = await axios.get(`${baseUrl}/admin/bouns/get`, {
      headers: { Authorization: `${token}` },
      params: payload,
    });

    if (response.status >= 200 && response.status <= 300) {
      dispatch(SignupBonusSuccess(response.data));
    } else {
      dispatch(SignupBonusFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(SignupBonusFailure(err));
    return err;
  }
};

// UPDATE BONUS

export const updateBonusInitiate = () => ({
  type: authConstant.UPDATE_SIGNUP_BONUS_INITIATE,
});

export const updateBonusSuccess = (data) => ({
  type: authConstant.UPDATE_SIGNUP_BONUS_SUCCESS,
  payload: data,
});

export const updateBonusFailure = (data) => ({
  type: authConstant.UPDATE_SIGNUP_BONUS_FAILURE,
  payload: data,
});

export const UpdateBonusAction = (payload) => async (dispatch) => {
  const token = isLoggedIn("admin");

  try {
    dispatch(updateBonusInitiate());
    const response = await axios.put(`${baseUrl}/admin/bouns/update`, payload, {
      headers: { authorization: `${token}` },
    });
    if (response.status == 200) {
      dispatch(updateBonusSuccess(response.data));
    } else {
      dispatch(updateBonusFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(updateBonusFailure(err));
    return err;
  }
};

// VERIFY TOKEN

export const verifyTokenInitiate = () => ({
  type: authConstant.VERIFY_TOKEN_INITIATE,
});

export const verifyTokenSuccess = (data) => ({
  type: authConstant.VERIFY_TOKEN_SUCCESS,
  payload: data,
});

export const verifyTokenFailure = (data) => ({
  type: authConstant.VERIFY_ADMIN_FAILURE,
  payload: data,
});

export const VerifyTokenAction = (payload) => async (dispatch) => {
  const token = isLoggedIn("admin");

  try {
    dispatch(verifyTokenInitiate());
    const response = await axios.post(
      `${baseUrl}/staff/forget/token/verify`,
      payload,
      {
        headers: { authorization: `${token}` },
      }
    );
    if (response.status == 200) {
      dispatch(verifyTokenSuccess(response.data));
    } else {
      dispatch(verifyTokenFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(verifyTokenFailure(err));
    return err;
  }
};

// SEND OTP

export const twoStepInitiate = () => ({
  type: authConstant.TWO_STEP_INITIATE,
});

export const twoStepSuccess = (data) => ({
  type: authConstant.TWO_STEP_SUCCESS,
  payload: data,
});

export const twoStepFailure = (data) => ({
  type: authConstant.TWO_STEP_FAILURE,
  payload: data,
});

export const TwoStepAction = (payload) => async (dispatch) => {
  try {
    dispatch(twoStepInitiate());
    const response = await axios.get(`${baseUrl}/user/sendotp`, {
      params: payload,
    });
    if (response.status == 200) {
      dispatch(twoStepSuccess(response.data));
    } else {
      dispatch(twoStepFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(twoStepFailure(err));
    return err;
  }
};




// NOTIFICATION

export const  notificationInitiate = () => ({
    type: authConstant.NOTIFICATION_INITIATE,
  });
  
  export const notificationSuccess = (data) => ({
    type: authConstant.NOTIFICATION_SUCCESS,
    payload: data,
  });
  
  export const notificationFailure = (data) => ({
    type: authConstant.NOTIFICATION_FAILURE,
    payload: data,
  });
  
  export const NotificationAction = (payload) => async (dispatch) => {
    const token =isLoggedIn('admin');

    try {
      dispatch(notificationInitiate());
      const response = await axios.get(`${baseUrl}/admin/bellNotification`, {
        headers: {authorization:`${token}`}, params: payload,
      });
      if (response.status == 200) {
        dispatch(notificationSuccess(response.data));
      } else {
        dispatch(notificationFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(notificationFailure(err));
      return err;
    }
  };
  


  
export const phoneOtpInitiate=() =>({
  type:authConstant.EMAIL_OTP_INITIATE

})

export const phoneOtpSuccess=(data) =>({
  type:authConstant.EMAIL_OTP_SUCCESS,
  payload:data

})

export const phoneOtpFailure=(data) =>({
  type:authConstant.EMAIL_OTP_FAILURE,
  payload : data

})



export const  PhoneOtpAction=(payload)=> async (dispatch) =>{
  
      
  try {
      dispatch(phoneOtpInitiate());
      const response = await axios.get(`${baseUrl}/hotel/verifyphone`, {
  params: payload})
  if (response.status >= 200 && response.status <=300) {
      dispatch(phoneOtpSuccess(response.data));

      } else {
        dispatch(phoneOtpFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(phoneOtpFailure(err));
      return err;
    }





}




