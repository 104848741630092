import { financeConstant } from "../actionTypes";
const initialState = {
    financeList: [],
    analyticList:[],
    couponUsage:[],
    loader: false,
    error: {},
  };
  
  export default function financeReducer(
    state = initialState,
    { type, payload }
    
  ) {
    switch (type) {

      case financeConstant.FINANCE_LIST_INITIATE:
        return { ...state, loader: true, financeList: [] };
  
      case financeConstant.FINANCE_LIST_SUCCESS:
        return { ...state, loader: false, financeList: payload.data };
  
      case financeConstant.FINANCE_LIST_FAILURE:
        return { ...state, loader: false, error: payload };

        case financeConstant.ANALYTIC_LIST_INITIATE:
          return { ...state, loader: true, analyticList: [] };
    
        case financeConstant.ANALYTIC_LIST_SUCCESS:
          return { ...state, loader: false, analyticList: payload.data };
    
        case financeConstant.ANALYTIC_LIST_FAILURE:
          return { ...state, loader: false, error: payload };
  
          case financeConstant.HOTEL_ALL_COUPON_USAGE_INITIATE:
            return { ...state, loader: true, couponUsage: [] };
      
          case financeConstant.HOTEL_ALL_COUPON_USAGE_SUCCESS:
            return { ...state, loader: false, couponUsage: payload.data };
      
          case financeConstant.HOTEL_ALL_COUPON_USAGE_FAILURE:
            return { ...state, loader: false, error: payload };
  
  
  
      default:
        return state;
    }
  }
  