import React, { lazy, useEffect, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import {mapAPI_KEY } from "../config/config";

const AutoPlace = ({ updateState, iState, address, verified }) => {
  const [tempState, setTempState] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [defaultCoords, setDefaultCoords] = useState({ lat: 28.535517, lng: 77.391029}); 

  const handleChange = (address) => {
    updateState((prev) => ({ ...prev, address }));
    setTempState(address);
  };

  const handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        updateState({ ...iState, lat: latLng.lat, long: latLng.lng, address });
      })
      .catch((error) => console.error("Error", error));
  };
  
  

  useEffect(() => {
    const getUserLocation = async () => {
      if (navigator.geolocation) {
        setIsLoading(true); 
        try {
          const position = await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
          });
          const { latitude, longitude } = position.coords;
          updateState((prev) => ({ ...prev, lat: latitude, lng: longitude }));
        } catch (error) {
          console.error("Error getting user location:", error);
          updateState({ ...iState, ...defaultCoords });
        } finally {
          setIsLoading(false);
        }
      } else {
        console.warn("Geolocation is not supported by this browser.");
        updateState({ ...iState, ...defaultCoords });
      }
    };

    getUserLocation();

    fetchAddress(iState.lat, iState.long)
    setTempState(address);
  }, [address]);


  
  const fetchAddress = (lat, lng) => {
    if (lat && lng) {
      fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${mapAPI_KEY}`)
        .then((response) => response.json())
        .then((data) => {
          let tempFormattedAddress = data?.results?.[0]?.place_id ?? "";
          updateState((prev) => ({ ...prev, pinCode: tempFormattedAddress }));
        })
        .catch((error) => console.error("Error fetching address:", error));
    } else {
      console.warn("Latitude or Longitude is missing. Address cannot be fetched.");
    }
  };




  return (
    <PlacesAutocomplete
      value={tempState}
      onChange={handleChange}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <label>Address</label>
          <input

            {...getInputProps({
              placeholder: "Enter Address ",
              className: "location-search-input form-control",
              disabled:!verified
            })}
          />
          <div className="autocomplete-dropdown-container">
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion) => {
              const className = suggestion.active
                ? "suggestion-item--active"
                : "suggestion-item";
              // inline style for demonstration purpose
              const style = suggestion.active
                ? { backgroundColor: "#fafafa", cursor: "pointer" }
                : { backgroundColor: "#ffffff", cursor: "pointer" };
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default AutoPlace;
