import React from 'react'
import Header from './header'
import Sidenav from './sidenav'
import { Link, useParams } from 'react-router-dom'
import {toast} from 'react-toastify'
import ImageFetcher from './ImageFetcher'
import Email from "../assets/image/email.svg"
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { ChatRoomHitoryAction, CloseChatAction } from '../redux/actions/chatAction'

const HousekeepingChatHistory = () => {
  const {id} =useParams()

    const dispatch = useDispatch();
    const{chatRoomHistory} = useSelector((state)=>state.chatReducer)

    useEffect(()=>{
        dispatch(ChatRoomHitoryAction({serviceType:"housekeeping", hotelId:id}))

    },[])




    const handleCloseChat=(id)=>{
        dispatch(CloseChatAction({ getRoomId: id })).then((res) => {
            if (res.status >= 200 && res.status <= 300) {
              toast.success(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
      
      
              dispatch(ChatRoomHitoryAction({serviceType:"housekeeping" , hotelId:id}))
            } else {
              toast.error(res.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          });
      

    }




  return (
    <div>
        <Header />
        <Sidenav />
        <div class="WrapperArea">
        <div class="WrapperBox">
            <div class="NewTitleBox">
            <div className='arrow-left'>
              <Link to={`/housekeeping-msg/${id}`}><i class="fa fa-arrow-left arrow-back" aria-hidden="true"></i>
              </Link>

                <h4>Chat History</h4>
                </div>
            </div>
            
            {/* <div class="Filter">  
                <div class="form-group">
                    <label>Search</label>
                </div>
                                     
                <div class="form-group">
                    <label>&nbsp;</label>
                    <button class="Button">Apply</button>
                    <button class="Button Cancel"><i class="fa fa-refresh"></i></button>
                </div>
            </div>
                      */}


                      {chatRoomHistory?.map((item)=>
                      <div class="Small-Wrapper"> 
                
                      <div class="message">
                          <aside>
                              <figure>
                              <ImageFetcher imageUrl={item?.sender?.[0]?.profileImage}/>
                              </figure>
                              <figcaption>
                <p>{item?.sender?.[0]?.name}  </p>

                                  {/* <p>Marissa Mattos  <b>(RM103)</b></p> */}
                                  {/* <a onClick={()=>handleCloseChat(item?.chatRoomId)}><b>Close Chat</b></a> */}
                              </figcaption>
                          </aside>
                          <article>
                              <figure>
                                  <img src={Email} alt="" />
                              </figure>
                              <figcaption>
                                  <Link to={`/housekeeping-chat-per/${id}`} state={item}>View Chat</Link>
                              </figcaption>
                          </article>
                      </div>                
                  </div>
                      )}

            


        </div> 
    </div>
    </div>
  )
}

export default HousekeepingChatHistory