import { houseKeepingConstant } from "../actionTypes";
const initialState = {
    housekeepingList: [],
    loader: false,
    housekeepingRequest:[],
    error: {},
  };
  
  export default function houseKeepingReducer(
    state = initialState,
    { type, payload }
    
  ) {
    switch (type) {

      case houseKeepingConstant.HOUSE_KEEPING_LIST_INITIATE:
        return { ...state, loader: true, housekeepingList: [] };
  
      case houseKeepingConstant.HOUSE_KEEPING_LIST_SUCCESS:
        return { ...state, loader: false, housekeepingList: payload.data };
  
      case houseKeepingConstant.HOUSE_KEEPING_LIST_FAILURE:
        return { ...state, loader: false, error: payload };

        case houseKeepingConstant.HOUSE_KEEPING_REQUEST_INITIATE:
          return { ...state, loader: true, housekeepingRequest: [] };
    
        case houseKeepingConstant.HOUSE_KEEPING_REQUEST_SUCCESS:
          return { ...state, loader: false, housekeepingRequest: payload.data };
    
        case houseKeepingConstant.HOUSE_KEEPING_REQUEST_SUCCESS:
          return { ...state, loader: false, error: payload };
  


  
  
      default:
        return state;
    }
  }
  