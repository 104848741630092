import React, { useEffect } from "react";
import Header from "./header";
import Sidenav from "./sidenav";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { HousekeepingRequestAction } from "../redux/actions/housekeepingAction";
import moment from "moment/moment";
import NoDataFound from "./noDataFound";
import Pagination from "react-js-pagination";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Loader from "./loader";

const HousekeepingOrderRequest = () => {
  const navigate = useNavigate()
  const [activePage, setActivePage] = useState(1);
  const [otp, setOtp] = useState("");
  const {id} = useParams()

  const [filterData, setFilterData] = useState({
    status: "",
    fromDate: "",
    toDate: "",
    search: "",
    page: 1,
  });

  const [QrModal, setQr] = useState(false);
  const handleQrModalClose = () => setQr(false);
  const handleQrModalShow = (data) => {
    setOtp(data?.otp);
    setQr(true);
  };

  const { housekeepingRequest, loader } = useSelector(
    (state) => state.housekeepingReducer
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(HousekeepingRequestAction({hotelId:id}));
  }, []);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    filterData.page = pageNumber;
    dispatch(HousekeepingRequestAction({ page: filterData?.page , hotelId:id}));
  };

  const handleSearch = (e) => {
    dispatch(HousekeepingRequestAction({ search: e.target.value, hotelId:id }));
    setFilterData((prev) => ({ ...prev, search: e.target.value }));
  };

  const handleRefresh = (e) => {
    setFilterData({ search: "", fromDate: "", toDate: "", status: "" });

    dispatch(HousekeepingRequestAction({hotelId:id}));
  };

  const handleFilter = (e) => {
    dispatch(
      HousekeepingRequestAction({
        filter: filterData?.status,
        fromDate: filterData?.fromDate,
        toDate: filterData?.toDate,
        hotelId:id
      })
    );
  };


  const handleDetails=(item)=>{
      navigate(`/housekeeping-order-details/${id}`, {state:item})


  }

  return (
    <div>
      <Header />
      <Sidenav />
      {loader? <Loader />:


      <div class="WrapperArea">
        <div class="WrapperBox">
          {/* <div class="back-btn">
                <a href="restaurant-order-request.html"><i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back</a>
            </div> */}

          <div class="Small-Wrapper">
            {/* <div class="NewTitleBox">
                    <h4>Order Listing</h4>
                    <a class="add" href="restaurant-add-order.html">+ Add Order</a>
                </div> */}

            <div class="NewTitleBox">
              <div className="arrow-left">
                <Link to={`/housekeeping-management/${id}`}>
                  <i class="fa fa-arrow-left arrow-back" aria-hidden="true"></i>
                </Link>
                <h4>Order Listing</h4>
              </div>
              {/* <Link class="add" to="/spa-order-add">+ Add Order</Link> */}
            </div>

            <div class="Filter">
              <div class="form-group">
                <label>Search</label>
                <input
                  type="text"
                  class="form-control"
                  value={filterData?.search}
                  onChange={handleSearch}
                  placeholder="Search"
                />
              </div>
              <div class="form-group">
                <label>Status</label>
                <select
                  value={filterData?.status}
                  onChange={(e) =>
                    setFilterData({ ...filterData, status: e.target.value })
                  }
                  class="form-control"
                >
                  <option value="">--Select--</option>
                  <option value="onProcess">On-Process</option>
                  <option value="completed">Completed</option>
                  <option value="requested">Requested</option>
                  <option value="cancelled">Cancelled</option>
                </select>
              </div>
              <div class="form-group">
                <label>From Date</label>
                <input
                                  value={filterData?.fromDate}

                  name="fromDate"
                  // min={moment(todayData).format("YYYY-MM-DD")}
                  onChange={(e) =>
                    setFilterData({ ...filterData, fromDate: e.target.value })
                  }
                  type="date"
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <label>To Date</label>
                <input
                  min={filterData?.toDate}
                  value={filterData?.toDate}
                  name="toDate"
                  disabled={!filterData?.fromDate}
                  onChange={(e) =>
                    setFilterData({ ...filterData, toDate: e.target.value })
                  }
                  type="date"
                  class="form-control"
                />
              </div>

              <div class="form-group">
                <label>&nbsp;</label>
                <button class="Button" onClick={handleFilter}>
                  Apply
                </button>
                <button onClick={handleRefresh} class="Button Cancel">
                  <i class="fa fa-refresh"></i>
                </button>
              </div>
            </div>

            <div class="TableList">
              <table style={{ width: "110%" }}>
                <thead>
                  <tr>
                    <th>Order Id</th>
                    <th>Date &amp; Time of Order</th>
                    <th>Room Number</th>
                    <th>Customer Name</th>
                    <th>Order Item</th>
                    <th>Scheduled Time</th>
                    <th>Status</th>
                    <th>Action</th>

                  </tr>
                </thead>
                <tbody>
                  {housekeepingRequest?.[0]?.data?.map((item) => (
                    <tr>
                      <td>{item?.requestId}</td>
                      <td>{moment(item?.updatedAt).format("lll")}</td>
                      <td>{item?.room?.fullNumber}</td>
                      <td>
                         {item?.requestBy?.name
                         }
                      </td>

                      <td> {item?.name}</td>
                      
                      
                <td>
                {(item?.deliveryTime!=""&& item?.deliveryTime!==null )&& ((item?.deliveryTime?.split(':')?.[0] =="00")?"": `${item?.deliveryTime?.split(':')?.[0]} Hrs` )}   {(item?.deliveryTime!=""&& item?.deliveryTime!==null )&&(`${item?.deliveryTime?.split(':')?.[1]} Mins`)}                    </td>
                      <td>
                        {" "}
                        {item?.status?.charAt(0)?.toUpperCase() +
                          item?.status?.slice(1)}
                      </td>
                      <td>
                                    <div class="Actions"> 
                                        <a class="Blue" onClick={()=>(handleDetails(item))}>
                                            <i class="fa fa-eye"></i>
                                        </a>
                                    </div>
                                </td>

                      
                    </tr>
                  ))}
                </tbody>
              </table>
              {loader ? (
                ""
              ) : housekeepingRequest?.[0]?.metadata?.[0]?.total > 0 ? (
                ""
              ) : (
                <NoDataFound />
              )}
            </div>
            {housekeepingRequest?.[0]?.metadata?.[0]?.total > 0 ? (
              <div className="Pagination">
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={
                    housekeepingRequest?.[0]?.metadata?.[0]?.total
                  }
                  pageRangeDisplayed={5}
                  onChange={(e) => handlePageChange(e)}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>}

      <Modal show={QrModal} onHide={handleQrModalClose} className="ModalBox ">
        <Modal.Body>
          <div class="Category">
            <a className="CloseModal" onClick={handleQrModalClose}>
              ×
            </a>
            <div class="QRScanGuest">
              <h4>
                Please scan customer QR or enter OTP to proceed with check-In
              </h4>
              <figure>
                <img src={require("../assets/image/QRCode.png")} />
              </figure>
              <p>
                <span class="qr-pera">Or</span>
              </p>

              <div class="form-group">
                {/* <label>Enter 4-Digit generated OTP Code</label> */}
                <input
                  value={otp}
                  disabled={true}
                  type="number"
                  class="form-control"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default HousekeepingOrderRequest;
