import React, { useEffect, useState } from 'react';

const ImageFetcher = ({ imageUrl }) => {
  const [base64ImageData, setBase64ImageData] = useState(null);
  const [error, setError] = useState(null);
  let accessToken = JSON.parse(localStorage.getItem("admin")).jwtToken;
  const getImage = async () => {
    try {

          if(imageUrl)
            {
              if(imageUrl.includes('/api/v1/download'))
                {
                  const response = await fetch(imageUrl, {
                    headers: {
                      'Authorization': `Authorization ${accessToken}`,
                    },
                    method: 'GET'
                  });
                
                  if (!response.ok) {
                    throw new Error(`Failed to fetch image: ${response.statusText}`);
                  }


                  const data = await response.json();
                  console.log("Image Data",data.image)
                  setBase64ImageData(data.image);
                }
                else if(imageUrl.includes('data:image')){
                  setBase64ImageData(imageUrl);
                }
                else if(imageUrl.includes('storage.googleapis.com')){
                  setBase64ImageData(imageUrl);
                }
                else{
                  let data="data:image/png;base64," +imageUrl;
                  setBase64ImageData(data);
                }
            }
            else
            {
              setBase64ImageData('');
            }
  
    } catch (error) {
      console.error('Error:', error);
      setError(error);
    }
  };

  useEffect(() => {
    getImage();
  }, [imageUrl]); 



  if (error) {
    return (
    <img src={""}/> 
  );
}

  return (
    <img src={base64ImageData}/>
  );
};

export default ImageFetcher;