import React,{useState} from 'react'
import Header from './header'
import Sidenav from './sidenav'


import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from '../axiosInstance';
import TimePicker from "@semcore/time-picker";
import ImageFetcher from './ImageFetcher'
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import { AddCouponAction , CouponListAction, EditCouponAction, RemoveCouponAction} from '../redux/actions/couponAction';
import Loader from './loader';

const inititalState = {
    "image" : "",
    "discount" : "",
    "minPurchaseAmt" : "",
    "category" : "",
    "descripiton" : "",

  errorMsg: {},
};


const HotelCoupons = () => {
    const [iState, updateState] = useState(inititalState);
    const [couponId, setCouponId] = useState("");
    const [Type, setType] = useState("add");
    const [stopper,setStopper] = useState(false)
    const dispatch = useDispatch();
    const [blockCount, setBlockCount] = useState(0)

    const {id} = useParams()

    const hotelDetails =  JSON.parse(window.localStorage.getItem("hotelDetails"))

  
    const [index, setIndex] = useState(0);
    const coponImages = [
      {
        name: "diamond",
        image: "https://bms-assets-storage-bucket.s3.ap-south-1.amazonaws.com/diamond.png",
      },
      { name: "gold", 
        image: "https://bms-assets-storage-bucket.s3.ap-south-1.amazonaws.com/gold.png" 
      },
      {
        name: "silver",
        image: "https://bms-assets-storage-bucket.s3.ap-south-1.amazonaws.com/silver.png",
      },
    ];
  
    const optionsDefault = ["diamond", "gold", "silver"];
    const [options, setOptions] = useState(["diamond", "gold", "silver"]);
  
    const [DeleteModal, setDelete] = useState(false);
    const handleDeleteModalClose = () => setDelete(false);
    const handleDeleteModalShow = (id) => {
      setCouponId(id);
      setDelete(true);
    };
  
    const { couponList,loader } = useSelector((state) => state.couponReducer);
  
    const { category, minPurchaseAmt, discount, image, description, errorMsg } =
      iState;
  
    const [AddCouponModal, setAddCoupon] = useState(false);
    const handleAddCouponModalClose = () => {
      updateState({
        ...iState,
        image: "",
        duration: "",
        discount: "",
        minPurchaseAmt: "",
        description: "",
        errorMsg: {},
      });
      setAddCoupon(false);
    };
    const handleAddCouponModalShow = (type, ele, i) => {
      setIndex(i);
      if (type == "edit") {
        setType(type);
  
        if(couponList?.length !==3)
        {
          toast.error(
            "* Please add all coupons first then you able to update or delete this coupon.",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
  
        }
        else{
        setCouponId(ele?._id);
  
        updateState({
          ...iState,
          category: ele?.category,
          image: ele?.image,
          discount: ele?.discount,
          minPurchaseAmt: ele?.minPurchaseAmt,
          description: ele?.description,
        });
        setAddCoupon(true);}
      } else {
        updateState({
          ...iState,
          image: "",
          category: "",
          duration: "",
          discount: "",
          minPurchaseAmt: "",
          description: "",
          errorMsg: {},
        });
  
        setAddCoupon(true);
        setType(type);
      }
    };
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      if (name == "minPurchaseAmt") {
        if (!/^[0-9]{0,10}$/.test(value)) return;
        {
          updateState({ ...iState, [name]: value });
        }
      } else if (name == "discount") {
        if (!/^[0-9]{0,10}$/.test(value)) return;
        {
          updateState({
            ...iState,
            [name]: value.replace(/[^\d]/g, "").slice(0, 2),
          });
        }
      } else if (name == "category") {
        coponImages?.map((item, i) => {
          if (item?.name == value) {
            setIndex(i);
  
            updateState((prev) => ({
              ...prev,
              image: item?.image,
              category: value,
            }));
          }
        });
      } else {
        updateState({ ...iState, [name]: value });
      }
    };
  
    window.Buffer = window.Buffer || require("buffer").Buffer;
  
    const handleUpload = (e) => {
      const file = e.target.files[0];
  
      if (file.type == "application/pdf") {
        toast.error("Only jpeg, jpg and png are allowed ", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
  
      if (file) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (e) {
          //Initiate the JavaScript Image object.
          var image = new Image();
  
          //Set the Base64 string return from FileReader as source.
          image.src = e.target.result;
  
          //Validate the File Height and Width.
          image.onload = function () {
            var height = this.height;
            var width = this.width;
            if (
              file.type === "image/jpeg" ||
              file.type === "image/jpg" ||
              file.type === "image/png"
            ) {
              const formData = new FormData();
              formData.append('file', file);
    
              let accessToken = JSON.parse(localStorage.getItem("hotelDetails")).jwtToken;
              axiosInstance.post('/upload?type=hotel', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  'Authorization': 'Authorization'.concat(" ",accessToken),
                }
              })
              .then(response => {
                console.log(response);
              
                if (response.status !== 200) {
                  throw new Error(`Server error: ${response.statusText}`);
                }
              
                return response;
              })
              .then(data => {
                if (data.error) {
                  toast.error(data.error, { position: toast.POSITION.TOP_RIGHT });
                } else {
                  const tempData = data.data.url;
                  updateState({ ...iState, image: tempData });
                toast.success("Upload successfully", { position: toast.POSITION.TOP_RIGHT });
                }
              })
              .catch(error => {
                console.error(error);
                toast.error("Upload failed: " + error.message, { position: toast.POSITION.TOP_RIGHT });
              });
              } else {
              toast.error("Only jpeg, jpg and png are allowed ", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          };
        };
      }
    };
  
    const handleValidate = () => {
      let formIsValid = true;
      let errorMsg = {};
      if (!image) {
        formIsValid = false;
        errorMsg.image = "* Please upload  image.";
      }
  
      if (!discount) {
        formIsValid = false;
        errorMsg.discount = "* Please enter discount.";
      } else if (discount < 0) {
        formIsValid = false;
        errorMsg.discountValid = "* Discount not be negative.";
      }
      if (!category) {
        formIsValid = false;
        errorMsg.category = "* Please select category .";
      }
      if (!minPurchaseAmt) {
        formIsValid = false;
        errorMsg.minPurchaseAmt = "* Please enter min purchase.";
      } else if (minPurchaseAmt < 0) {
        formIsValid = false;
        errorMsg.minPurchaseAmtValid = "* Amount not be negative.";
      }
  
      if (!description) {
        formIsValid = false;
        errorMsg.description = "* Please  enter description.";
      }
  
      updateState({ ...iState, errorMsg: errorMsg });
  
      return formIsValid;
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
  
      let formIsValid = handleValidate();
      const hotelData = JSON.parse(localStorage.getItem("hotel"));
      setStopper(formIsValid)
  
      if (formIsValid) {
        let data = {
          image,
          description,
          hotelId: id,
          discount,
          minPurchaseAmt,
          category,
        };
  
        let couponValidation = false;
        if (couponList?.length == 2) {
          if (
            couponList?.[0]?.category == "diamond" &&
            couponList?.[1]?.category == "gold"
          ) {
            if (
              couponList?.[0]?.discount > discount &&
              couponList?.[0]?.minPurchaseAmt > minPurchaseAmt &&
              couponList?.[1]?.discount > discount &&
              couponList?.[1]?.minPurchaseAmt > minPurchaseAmt
            ) {
              couponValidation = true;
            }
          }
          if (
            couponList?.[0]?.category == "diamond" &&
            couponList?.[1]?.category == "silver"
          ) {
            if (
              couponList?.[0]?.discount > discount &&
              couponList?.[1]?.discount < discount &&
              couponList?.[0]?.minPurchaseAmt > minPurchaseAmt &&
              couponList?.[1]?.minPurchaseAmt < minPurchaseAmt
            ) {
              couponValidation = true;
            }
          }
  
          if (
            couponList?.[0]?.category == "gold" &&
            couponList?.[1]?.category == "diamond"
          ) {
            if (
              couponList?.[0]?.discount > discount &&
              couponList?.[1]?.discount > discount &&
              couponList?.[0]?.minPurchaseAmt > minPurchaseAmt &&
              couponList?.[1]?.minPurchaseAmt > minPurchaseAmt
            ) {
              couponValidation = true;
            }
          }
          if (
            couponList?.[0]?.category == "gold" &&
            couponList?.[1]?.category == "silver"
          ) {
  
            if (
              couponList?.[0]?.discount > discount &&
              couponList?.[1]?.discount > discount &&
              couponList?.[0]?.minPurchaseAmt > minPurchaseAmt &&
              couponList?.[1]?.minPurchaseAmt > minPurchaseAmt
            ) {
              couponValidation = true;
            }
          }
          if (
            couponList?.[0]?.category == "silver" &&
            couponList?.[1]?.category == "gold"
          ) {
            if (
              couponList?.[0]?.discount < discount &&
              couponList?.[1]?.discount < discount &&
              couponList?.[0]?.minPurchaseAmt < minPurchaseAmt &&
              couponList?.[1]?.minPurchaseAmt < minPurchaseAmt
            ) {
              couponValidation = true;
            }
          }
  
          if (
            couponList?.[0]?.category == "silver" &&
            couponList?.[1]?.category == "diamond"
          ) {
  
            if (
              couponList?.[0]?.discount < discount &&
              couponList?.[1]?.discount > discount &&
              couponList?.[0]?.minPurchaseAmt < minPurchaseAmt &&
              couponList?.[1]?.minPurchaseAmt > minPurchaseAmt
            ) {
              couponValidation = true;
            }
          }
        } else if (couponList?.length == 1) {
          if (couponList?.[0]?.category == "diamond") {
            if (
              category == "silver" &&
              discount < couponList?.[0]?.discount &&
              minPurchaseAmt < couponList?.[0]?.minPurchaseAmt
            ) {
              couponValidation = true;
            }
            if (
              category == "gold" &&
              discount < couponList?.[0]?.discount &&
              minPurchaseAmt < couponList?.[0]?.minPurchaseAmt
            ) {
              couponValidation = true;
            }
          }
          if (couponList?.[0]?.category == "gold") {
            if (
              category == "diamond" &&
              discount > couponList?.[0]?.discount &&
              minPurchaseAmt > couponList?.[0]?.minPurchaseAmt
            ) {
              couponValidation = true;
            }
            if (
              category == "silver" &&
              discount < couponList?.[0]?.discount &&
              minPurchaseAmt < couponList?.[0]?.minPurchaseAmt
            ) {
              couponValidation = true;
            }
          }
  
          if (couponList?.[0]?.category == "silver") {
            if (
              category == "diamond" &&
              discount > couponList?.[0]?.discount &&
              minPurchaseAmt > couponList?.[0]?.minPurchaseAmt
            ) {
              couponValidation = true;
            }
            if (
              category == "gold" &&
              discount > couponList?.[0]?.discount &&
              minPurchaseAmt > couponList?.[0]?.minPurchaseAmt
            ) {
              couponValidation = true;
            }
          }
        } else {
          couponValidation = true;
        }
  
        if (couponValidation) {
          dispatch(AddCouponAction(data))
            .then((res) => {
              if (res.data.success === true) {
                setStopper(false)
                setAddCoupon(false);
                updateState({
                  ...iState,
                  image: "",
                  discount: "",
                  minPurchaseAmt: "",
                  category: "",
                  descripiton: "",
                });
                dispatch(CouponListAction({hotelId:id}));
                toast.success(res.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              } else {
                toast.error(res.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            })
            .catch((err) => {
              alert(err);
            });
        } else {
          setStopper(false)
          toast.error(
            "Coupon discount & minimum purchase should be according coupon type.",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        }
      }
    };
  
    const handleUpdate = (e) => {
      e.preventDefault();
  
      let formIsValid = handleValidate();
      const hotelData = JSON.parse(localStorage.getItem("hotel"));
      setStopper(formIsValid)
  
      if (formIsValid) {
        let data = {
          _id: couponId,
          image,
          description,
          hotelId: id,
          discount,
          category,
          minPurchaseAmt,
        };
  
        let couponValidation = false;
  
        if (index == 2) {
          if (
            couponList?.[0]?.category == "diamond" &&
            couponList?.[1]?.category == "gold"
          ) {
            if (
              couponList?.[0]?.discount > discount &&
              couponList?.[0]?.minPurchaseAmt > minPurchaseAmt &&
              couponList?.[1]?.discount > discount &&
              couponList?.[1]?.minPurchaseAmt > minPurchaseAmt
            ) {
              couponValidation = true;
            }
          }
          if (
            couponList?.[0]?.category == "diamond" &&
            couponList?.[1]?.category == "silver"
          ) {
            if (
              couponList?.[0]?.discount > discount &&
              couponList?.[1]?.discount < discount &&
              couponList?.[0]?.minPurchaseAmt > minPurchaseAmt &&
              couponList?.[1]?.minPurchaseAmt < minPurchaseAmt
            ) {
              couponValidation = true;
            }
          }
  
          if (
            couponList?.[0]?.category == "gold" &&
            couponList?.[1]?.category == "diamond"
          ) {
            if (
              couponList?.[0]?.discount > discount &&
              couponList?.[1]?.discount > discount &&
              couponList?.[0]?.minPurchaseAmt > minPurchaseAmt &&
              couponList?.[1]?.minPurchaseAmt > minPurchaseAmt
            ) {
              couponValidation = true;
            }
          }
          if (
            couponList?.[0]?.category == "gold" &&
            couponList?.[1]?.category == "silver"
          ) {
  
            if (
              couponList?.[0]?.discount < discount &&
              couponList?.[1]?.discount < discount &&
              couponList?.[0]?.minPurchaseAmt < minPurchaseAmt &&
              couponList?.[1]?.minPurchaseAmt <minPurchaseAmt
            ) {
  
              couponValidation = true;
            }
          }
          if (
            couponList?.[0]?.category == "silver" &&
            couponList?.[1]?.category == "gold"
          ) {
            if (
              couponList?.[0]?.discount < discount &&
              couponList?.[1]?.discount < discount &&
              couponList?.[0]?.minPurchaseAmt < minPurchaseAmt &&
              couponList?.[1]?.minPurchaseAmt < minPurchaseAmt
            ) {
              couponValidation = true;
            }
          }
  
          if (
            couponList?.[0]?.category == "silver" &&
            couponList?.[1]?.category == "diamond"
          ) {
            if (
              couponList?.[0]?.discount < discount &&
              couponList?.[1]?.discount > discount &&
              couponList?.[0]?.minPurchaseAmt < minPurchaseAmt &&
              couponList?.[1]?.minPurchaseAmt > minPurchaseAmt
            ) {
              couponValidation = true;
            }
          }
        } else if (index == 1) {
          if (couponList?.[0]?.category == "diamond") {
            if (
              couponList?.[1]?.category == "gold" &&
              discount > couponList?.[2]?.discount
               &&
              minPurchaseAmt > couponList?.[2]?.minPurchaseAmt &&
              discount < couponList?.[0]?.discount &&
              minPurchaseAmt < couponList?.[0]?.minPurchaseAmt
            ) {
  
              couponValidation = true;
            }
            if (
              couponList?.[1]?.category == "silver" &&
              discount < couponList?.[2]?.discount &&
              minPurchaseAmt < couponList?.[2]?.minPurchaseAmt &&
              discount < couponList?.[0]?.discount &&
              minPurchaseAmt < couponList?.[0]?.minPurchaseAmt
            ) {
              couponValidation = true;
            }
          }
          if (couponList?.[0]?.category == "gold") {
            if (
              couponList?.[1]?.category == "silver" &&
              discount < couponList?.[2]?.discount &&
              minPurchaseAmt < couponList?.[2]?.minPurchaseAmt &&
              discount < couponList?.[0]?.discount &&
              minPurchaseAmt < couponList?.[0]?.minPurchaseAmt
            ) {
              couponValidation = true;
            }
            if (
              couponList?.[1]?.category == "diamond" &&
              discount > couponList?.[2]?.discount &&
              minPurchaseAmt > couponList?.[2]?.minPurchaseAmt &&
              discount > couponList?.[0]?.discount &&
              minPurchaseAmt > couponList?.[0]?.minPurchaseAmt
            ) {
              couponValidation = true;
            }
          }
  
          if (couponList?.[0]?.category == "silver") {
            if (
              couponList?.[1]?.category == "diamond" &&
              discount > couponList?.[2]?.discount &&
              minPurchaseAmt > couponList?.[2]?.minPurchaseAmt &&
              discount > couponList?.[0]?.discount &&
              minPurchaseAmt > couponList?.[0]?.minPurchaseAmt
            ) {
              couponValidation = true;
            }
            if (
              couponList?.[1]?.discount == "gold" &&
              discount < couponList?.[2]?.discount &&
              minPurchaseAmt < couponList?.[2]?.minPurchaseAmt &&
              discount > couponList?.[0]?.discount &&
              minPurchaseAmt > couponList?.[0]?.minPurchaseAmt
            ) {
              couponValidation = true;
            }
          }
        } else {
          if (couponList?.[0]?.category == "diamond") {
            if (
              discount > couponList?.[1]?.discount &&
              minPurchaseAmt > couponList?.[1]?.minPurchaseAmt &&
              discount > couponList?.[2]?.discount &&
              minPurchaseAmt > couponList?.[2]?.minPurchaseAmt
            ) {
              couponValidation = true;
            }
          }
          if (couponList?.[0]?.category == "gold") {
            if (
              couponList?.[1]?.discount == "diamond" &&
              discount > couponList?.[2]?.discount &&
              minPurchaseAmt > couponList?.[2]?.minPurchaseAmt &&
              discount < couponList?.[1]?.discount &&
              minPurchaseAmt < couponList?.[1]?.minPurchaseAmt
            ) {
              couponValidation = true;
            }
            if (
              couponList?.[1]?.category == "silver" &&
              discount < couponList?.[2]?.discount &&
              minPurchaseAmt < couponList?.[2]?.minPurchaseAmt &&
              discount > couponList?.[1]?.discount &&
              minPurchaseAmt > couponList?.[1]?.minPurchaseAmt
  
            ) {
              couponValidation = true;
            }
          }
  
          if (couponList?.[0]?.category == "silver") {
            if (
              couponList?.[1]?.category == "diamond" &&
              discount < couponList?.[2]?.discount &&
              minPurchaseAmt < couponList?.[2]?.minPurchaseAmt &&
              discount < couponList?.[1]?.discount &&
              minPurchaseAmt < couponList?.[1]?.minPurchaseAmt
            ) {
              couponValidation = true;
            }
            if (
              couponList?.[1]?.category == "gold" &&
              discount < couponList?.[2]?.discount &&
              minPurchaseAmt < couponList?.[2]?.minPurchaseAmt &&
              discount < couponList?.[1]?.discount &&
              minPurchaseAmt < couponList?.[1]?.minPurchaseAmt
  
            ) {
              couponValidation = true;
            }
          }
        }
  
        if (couponValidation) {
          dispatch(EditCouponAction(data))
            .then((res) => {
              if (res.data.success === true) {
                setStopper(false)
                setAddCoupon(false);
                dispatch(CouponListAction({hotelId:id}));
                toast.success(res.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
                updateState({
                  ...iState,
                  image: "",
                  discount: "",
                  minPurchaseAmt: "",
                  category: "",
                  descripiton: "",
                });
              } else {
                toast.error(res.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            })
            .catch((err) => {
              alert(err);
            });
        } else {
          setStopper(false)
          toast.error(
            "Coupon discount & minimum purchase should be according coupon type.",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        }
      }
    };
  
    useEffect(() => {
      dispatch(CouponListAction({hotelId:id}));
    }, []);
  
    const handleDelete = () => {    if(couponList?.length> 1)
      {
  
      dispatch(RemoveCouponAction({ id: couponId })).then((res) => {
        if (res.data.success === true) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
  
          setDelete(false);
          dispatch(CouponListAction({hotelId:id}));
        }
      });
    }
    else{
      toast.error("You can't delete all coupon's", {
        position: toast.POSITION.TOP_RIGHT,
      });

    }
    };

    const statusFunc=(item)=>{
      dispatch(EditCouponAction(item))
      .then((res) => {
        if (res.data.success === true) {
          dispatch(CouponListAction({hotelId:id}));

          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        alert(err);
      });

    }
  
    const handleStatusChange = (item) => {
      if (item?.visibility == true) {
      


        item.visibility = false;
  
        if(blockCount >1){
          statusFunc(item)
  
        }
  
        else{
          toast.error("You can't inactive all coupons.", {
            position: toast.POSITION.TOP_RIGHT,
          });
    
    
        }
    
      } else {
        
        item.visibility = true;
        statusFunc(item)
      }    };
  
    useEffect(() => {
      let tempList = [...optionsDefault];
  
      couponList?.map((item) => {
        const removeIndex = tempList.findIndex((ele) => ele == item?.category);
  
        tempList.splice(removeIndex, 1);
      });
      setOptions(tempList);
    }, [couponList]);
    return (
    <div>
      <Header />
      <Sidenav />

      {loader? <Loader />:

      <div class="WrapperArea">
        <div class="WrapperBox">
        <div class="HotelLocation HotelLocation2">
            <div class="back-btn">
              <Link to={`/hotel-details/${id}`}>
                <i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back{" "}
              </Link>
            </div>

            <aside>
              <h3>{hotelDetails?.name}</h3>
              <p>
                <span>
                  <i class="fa fa-map-marker"></i>
                </span>{" "}
                {hotelDetails?.address}
              </p>
            </aside>
          </div>{" "}
            <div class="Small-Wrapper">
                <div class="NewTitleBox">
                    <h4>Coupons Management</h4>
                    <Link to={`/hotel-coupons-use/${id}`}>Coupon Usage Details</Link>
                </div>
                
            {couponList?.length < 3 && (
              <div class="Filter">
                <div class="form-group">
                  {/* <label>.</label> */}
                  <a
                    class="Button"
                    onClick={() => handleAddCouponModalShow("add")}
                  >
                    Add Coupon
                  </a>
                </div>
              </div>
            )}

            <div class="CouponsList">
              <div class="row">
                {couponList?.map((item, i) => (
                  <div class="col-sm-4">
                    <div class="CouponsBox">
                      <figure>
                      <ImageFetcher imageUrl={item?.image}/>
                      </figure>
                      <figcaption>
                        <h3>
                          Coupon Type{" "}
                          <span>
                            {item?.category?.charAt(0)?.toUpperCase() +
                              item?.category?.slice(1)}
                          </span>
                        </h3>

                        <h3>
                          {item?.discount}% Discount{" "}
                          <span>
                            upto to {item?.maxDiscountAmt} INR on Minimum
                            Purchase of {item?.minPurchaseAmt} INR
                          </span>
                        </h3>
                        <h4>{item?.price} INR</h4>
                        {/* <h6>+Taxes</h6> */}
                      </figcaption>
                      <aside>
                        <label class="switch">
                          <input
                            type="checkbox"
                            checked={item?.visibility}
                            value={item}
                            onChange={(e) => handleStatusChange(item)}
                          />
                          <span class="slider"></span>
                        </label>
                        <a
                          class="Green"
                          onClick={() =>
                            handleAddCouponModalShow("edit", item, i)
                          }
                        >
                          <i class="fa fa-pencil"></i>
                        </a>
                        <a
                          class="Red"
                          onClick={() => handleDeleteModalShow(item?._id)}
                        >
                          <i class="fa fa-trash"></i>
                        </a>
                      </aside>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            </div>
        </div>
    </div>}

    
    <Modal
        show={AddCouponModal}
        onHide={handleAddCouponModalClose}
        className="ModalBox "
      >
        <Modal.Body>
        <div class="Category">
            <a onClick={handleAddCouponModalClose} class="CloseModal">
              ×
            </a>
            {Type == "edit" ? <h3>Edit Item</h3> : <h3>Add Item</h3>}
            <div class="form-group">
              <label></label>
              <div class="UploadBox">
                {/* <div class="Upload">
                  <i class="fa fa-upload"></i> <span>Upload Icon</span>
                  <input
                    type="file"
                    onClick={(e) => (e.target.value = null)}
                    onChange={(e) => handleUpload(e)}
                    class="form-control"
                  />
                </div> */}
                {image && (
                  <div className="UploadIcon">
                     <ImageFetcher imageUrl={image}/>
                  </div>
                )}
              </div>
            </div>
            <div class="form-group">
              <label>Name</label>
              <select
                value={category}
                name="category"
                disabled={Type == "edit" ? true : false}
                onChange={handleChange}
                class="form-control"
              >
                <option value="">Select</option>
                {Type == "add" &&
                  options?.map((item) => (
                    <option value={item}>
                      {" "}
                      {item?.charAt(0)?.toUpperCase() + item.slice(1)}
                    </option>
                  ))}

                {Type == "edit" && <option value="diamond">Diamond</option>}
                {Type == "edit" && <option value="gold">Gold</option>}
                {Type == "edit" && <option value="silver">Silver</option>}
              </select>
              {errorMsg.category && !category&& (
                <p style={{ color: "red" }}>{errorMsg.category}</p>
              )}
            </div>

            <div class="form-group">
              <label>Discount %</label>
              <input
                value={discount}
                name="discount"
                type='text'
                onChange={handleChange}
                placeholder="Enter discount %"
                class="form-control"
              />

              {errorMsg.discount && !discount && (
                <p style={{ color: "red" }}>{errorMsg.discount}</p>
              )}
            </div>

            <div class="form-group">
              <label>Min Purchase</label>
              <input
              type='text'
                value={minPurchaseAmt}
                name="minPurchaseAmt"
                onChange={handleChange}
                placeholder="Enter min purchase"
                class="form-control"
              />

              {errorMsg.minPurchaseAmt && !minPurchaseAmt && (
                <p style={{ color: "red" }}>{errorMsg.minPurchaseAmt}</p>
              )}
            </div>

            <div class="form-group">
              <label>Description</label>
              <textarea
                rows="3"
                value={description}
                name="description"
                onChange={handleChange}
                class="form-control"
              ></textarea>
              {errorMsg.description && ! description && (
                <p style={{ color: "red" }}>{errorMsg.description}</p>
              )}
            </div>
            {Type == "edit" ? (
              <button class="Button" disabled={stopper} onClick={handleUpdate}>
                Edit Item
              </button>
            ) : (
              <button class="Button" disabled={stopper} onClick={handleSubmit}>
                Add Item
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={DeleteModal}
        onHide={handleDeleteModalClose}
        className="ModalBox "
      >
        <Modal.Body>
          <div className="Decline">
            <button className="CloseModal" onClick={handleDeleteModalClose}>
              ×
            </button>
            <h3>Delete</h3>
            <p>Are you sure you want to delete this Coupon ?</p>
            <h4>
              <a onClick={handleDeleteModalClose} className="Cancel">
                Cancel
              </a>
              <a onClick={handleDelete}>Delete</a>
            </h4>
          </div>
        </Modal.Body>
      </Modal>
       
    </div>
  )
}

export default HotelCoupons