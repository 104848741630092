import React, { useEffect, useState } from "react";
import Header from "./header";
import Sidenav from "./sidenav";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { CheckOutCompleteListAction } from "../redux/actions/guestAction";
import ImageFetcher from './ImageFetcher'
import NoDataFound from "./noDataFound";
import Pagination from "react-js-pagination";
import commonCrypto from "../utils";

const GuestsCheckoutCompleted = () => {
  const hotelDetails = JSON.parse(window.localStorage.getItem("hotelDetails"));

  const { id } = useParams();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [activePage, setActivePage] = useState(1);

  const { checkoutcompleteList, loader } = useSelector(
    (state) => state.guestReducer
  );

  useEffect(() => {
    dispatch(CheckOutCompleteListAction({ hotelId: id }));
  }, []);

  const handleSearch = (e) => {
    dispatch(
      CheckOutCompleteListAction({
        search: e.target.value,
        hotelId: id,
      })
    );

    setSearch(e.target.value);
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    dispatch(CheckOutCompleteListAction({ page: pageNumber, hotelId: id }));
  };

  return (
    <div>
      <Header />
      <Sidenav />
      <div class="WrapperArea">
        <div class="WrapperBox">
          <div class="HotelLocation HotelLocation2">
            <div class="back-btn">
              <Link to={`/guests-management/${id}`}>
                <i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back{" "}
              </Link>
            </div>

            <aside>
              <h3>{hotelDetails?.name}</h3>
              <p>
                <span>
                  <i class="fa fa-map-marker"></i>
                </span>{" "}
                {hotelDetails?.address}
              </p>
            </aside>
          </div>
          <div class="Small-Wrapper">
            <div class="NewTitleBox">
              <div className="arrow-left">
                {/* <Link to={`/guests-management/${id}`}>
                  <i class="fa fa-arrow-left arrow-back" aria-hidden="true"></i>
                </Link> */}

                <h4>Competed Checkout</h4>
              </div>
            </div>

            <div class="Filter">
              <div class="form-group">
                <label>Search</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search.."
                  value={search}
                  onChange={handleSearch}
                />
              </div>
            </div>
            <div class="GuestAccordian">
              <div id="accordion">
                {checkoutcompleteList?.[0]?.data?.map((item, index) => (
                  <div class="card">
                    <div
                      class="card-header collapsed"
                      data-toggle="collapse"
                      href={`#collapse${index}`}
                      aria-expanded="false"
                    >
                      <h5>
                        Rooms :{" "}
                        {item?.room?.map((ele) => (
                          <strong>{ele?.fullNumber} </strong>
                        ))}{" "}
                      </h5>
                      <h6>
                        {moment(item?.updatedAt).format("ll")}{" "}
                        <span>
                          (Checkout-{" "}
                          {item?.extendCheckOut?.rooms?.length > 0
                            ? moment(
                                item?.extendCheckOut?.newCheckOutDate
                              ).format("ll")
                            : moment(item?.checkOutDate).format("ll")}
                          )
                        </span>{" "}
                      </h6>
                    </div>

                    <div
                      id={`collapse${index}`}
                      class="collapse"
                      data-parent="#accordion"
                    >
                      <div class="card-body" style={{ borderTop: "none" }}>
                        {item?.guestId?.[0]?.co_occupants?.map((ele, i) => {
                                                      let GuestId = item?.guestId?.[0]?.guestId;

                          let cryptData = commonCrypto.decrypt(
                            ele.userId?.[0]?.kyc_details?.docData
                          );

                          return (
                            <div class="GuestUserBox">
                              <figure>
                              <ImageFetcher imageUrl={ cryptData !== null ? cryptData?.kycSelfie : ele?.kycSelfie}/>
                              </figure>
                              <figcaption>
                                <div class="GuestUserHead">
                                  <aside>
                                    <p>
                                      <label>Name </label>
                                      <span>
                                        <span>
                                          {cryptData !== null
                                            ? cryptData?.name
                                            : ele?.name}
                                        </span>
                                      </span>
                                    </p>
                                    <p>
                                      <label>Guest ID</label>
                                      <span>{GuestId}</span>
                                    </p>
                                    {ele?.userId &&
                                      ele?.userId?.length > 0 && (
                                        <p>
                                          <label>Gender</label>
                                          <span>
                                            {cryptData !== null
                                              ? cryptData?.gender
                                                  ?.charAt(0)
                                                  ?.toUpperCase() +
                                                cryptData?.gender?.slice(1)
                                              : ele?.gender
                                                  ?.charAt(0)
                                                  ?.toUpperCase() +
                                                ele?.gender?.slice(1)}
                                          </span>
                                        </p>
                                      )}
                                    <p>
                                      <label>Date of Birth</label>
                                      <span>
                                        {cryptData !== null
                                          ? cryptData?.dob
                                            ? moment(cryptData?.dob).format(
                                                "ll"
                                              )
                                            : "NA"
                                          : moment(ele?.dob).format("ll")}
                                      </span>
                                    </p>
                                    <p>
                                      <label>Age</label>
                                      <span>
                                        {cryptData !== null
                                          ? cryptData?.age
                                            ? cryptData?.age
                                            : "NA"
                                          : ele?.age
                                          ? ele?.age
                                          : "NA"}
                                      </span>
                                    </p>
                                    {ele?.type == "adult" &&
                                    cryptData?.docFrontImg && (
                                      <div class="card-guest">
                                        <span>
                                        <ImageFetcher imageUrl={cryptData?.docFrontImg}/>
                                        </span>
                                        <span>
                                        <ImageFetcher imageUrl={cryptData?.docFrontImg}/>
                                        </span>
                                      </div>
                                    )}
                                  </aside>
                                  <aside>
                                    {ele?.type !== "kid" && (
                                      <p>
                                        <label>Email</label>
                                        {cryptData !== null
                                          ? cryptData?.email == "" ||
                                            cryptData?.email == "false"
                                            ? "NA"
                                            : cryptData?.email
                                          : ele?.email == "" ||
                                            ele?.email == "false"
                                          ? "NA"
                                          : ele?.email}{" "}
                                      </p>
                                    )}
                                    <p>
                                      <label>Phone Number</label>
                                      <span>
                                        {ele?.type == "kid"
                                          ? "NA"
                                          : cryptData !== null
                                          ? cryptData?.phone
                                          : ele?.phone}
                                      </span>
                                    </p>
                                    {item?.type !== "kid" && (
                                      <p>
                                        <label>Address</label>
                                        <span>
                                          {cryptData !== null
                                            ? cryptData?.address
                                              ? cryptData?.address
                                              : "NA"
                                            : ele?.address
                                            ? ele?.address
                                            : "NA"}
                                        </span>
                                      </p>
                                    )}
                                    <p>
                                      <label>Verified with Document</label>
                                      <span>
                                        {ele?.type == "kid"
                                          ? "NA"
                                          : cryptData !== null
                                          ? item?.kyc_details
                                            ? ele?.kyc_details?.docType
                                            : ele.userId?.[0]?.kyc_details
                                                ?.docType
                                          : "NA"}
                                      </span>
                                    </p>
                                    <p>
                                      <label>Document Number</label>
                                      <span>
                                        {" "}
                                        {ele?.type == "kid"
                                          ? "NA"
                                          : cryptData !== null
                                          ? cryptData?.docNumber
                                          : "NA"}
                                      </span>
                                    </p>
                                  </aside>
                                </div>
                              </figcaption>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                ))}

                {loader ? (
                  ""
                ) : checkoutcompleteList?.[0]?.metadata?.[0]?.total > 0 ? (
                  ""
                ) : (
                  <NoDataFound />
                )}
              </div>

              {checkoutcompleteList?.[0]?.metadata?.[0]?.total > 0 ? (
                <div className="Pagination">
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={10}
                    totalItemsCount={
                      checkoutcompleteList?.[0]?.metadata?.[0]?.total
                    }
                    pageRangeDisplayed={5}
                    onChange={(e) => handlePageChange(e)}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuestsCheckoutCompleted;
