import React, { useEffect, useState } from "react";
import Header from "./header";
import Sidenav from "./sidenav";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
 
import { Link, useLocation } from "react-router-dom";
import { GetSubscriptionsDetailsAction } from "../redux/actions/subscriptionAction"

const SubscriptionDetails = () => {
  const [visibleoldsubsEntries, setVisibleoldsubsEntries] = useState(3);
  const [visibleFutureSubscriptionEntries, setVisibleFutureSubscriptionEntries] = useState(3);

  const [oldsubs, setoldsubs] = useState([]);
  const dispatch = useDispatch();

  const {state} = useLocation();
  const {hotelId} = useSelector(
    (state) => state.subscriptionReducer
  );

  useEffect(() => {
    var hid;
    if (state){
        hid = state.hotelId
    }
    else {
        hid = hotelId
    }
    dispatch(
      GetSubscriptionsDetailsAction(
        { hotelId: hid }
      )
    ).then((res) => {
      if (res.status === 200){
        if(res.data.data.oldSubscriptions.length > 0){
          setoldsubs(res.data.data.oldSubscriptions);
        }
      }
    });
  }, [])

  const handleOldSubViewMore = () => {
    setVisibleoldsubsEntries(visibleoldsubsEntries + 3);
  };

  const handleFutureSubViewMore = () => {
    setVisibleFutureSubscriptionEntries(visibleFutureSubscriptionEntries + 3);
  };


  const getDate = (date) => {
    // console.log("this is date", date, typeof date);
    date = new Date(date);
    const day = date.getDate();
    const month = date.getMonth() + 1; // +1 because getMonth() returns 0-11
    const year = date.getFullYear();

    const formattedDate = `${day.toString().padStart(2, '0')}-${month.toString().padStart(2, '0')}-${year}`;
    return formattedDate;
  }

  const downloadSubscriptionData = (fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    var i = 0;

    let result = oldsubs?.map((oldSub) => {
      i = i + 1;
      return {
        "Sr. No": i,
        "Plan": oldSub?.subscriptionDetails?.name,
        "Start Date": getDate(oldSub?.startDate),
        "Duration" : oldSub?.subscriptionDetails?.durationInDays,
        "End Date" : getDate(oldSub?.endDate),
        "Amount" : oldSub?.totalPrice
      };
    });

    // Combine the header row and row data into a single string
    const myJsonString = JSON.stringify(result);

    const ws = XLSX.utils.json_to_sheet(result);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + ".xlsx");
  };

  return (
    <>
      <Header />
      <Sidenav />
        <div class="WrapperArea">
          <div class="WrapperBox">
          <div class="Small-Wrapper">
            <div class="NewTitleBox">
              <div className="arrow-left">
                <Link to="#" onClick={() => window.history.back()}>
                  <i class="fa fa-arrow-left arrow-back" aria-hidden="true"></i>
                </Link>
                <h4 className="Title">Subscription History</h4>
              </div>
                <ul>
                  <li>
                    <a
                      class="add"
                      onClick={() =>
                        downloadSubscriptionData("subscription-histoy")
                      }
                    >
                      Export to Excel
                    </a>
                  </li>
                </ul>
            </div> 
            
                  { (oldsubs.length > 0) && 
                    <div class="TableList">
                        <table>
                          <thead>
                            <tr>
                              <th>Plan</th>
                              <th>Start Date</th>
                              <th>Duration</th>
                              <th>End Date</th>
                              <th>Amount</th>
                              {/* <th>Invoice</th> */}
                            </tr>
                          </thead>

                          <tbody>
                            { oldsubs.map((oldSub) => (
                              <tr>
                                <td>{oldSub?.subscriptionDetails?.name}</td>
                                <td>{getDate(oldSub.startDate)}</td>
                                <td>{oldSub?.subscriptionDetails?.durationInDays} days</td>
                                <td>{getDate(oldSub.endDate)}</td>
                                <td>{oldSub.totalPrice}</td>
                                {/* <td>
                                    <Link 
                                          to="/subscription-transaction"
                                          state = {{search: oldSub?.paymentOrderId}}
                                        >
                                          Invoice
                                    </Link>
                                </td> */}
                              </tr>
                            )) }
                          </tbody>
                        </table>
                      </div> }
                </div>
              </div>
            </div>
    </>
  );
};

export default SubscriptionDetails;
