import { wifiConstant } from "../actionTypes";

import axios from "../../axiosInstance"
import { baseUrl } from "../../config/config";
import { isLoggedIn } from "../../utils";




// ADD WIFI

export const addWifiInitiate=() =>({
    type:wifiConstant.ADD_WIFI_INITIATE

})

export const addWifiSuccess=(data) =>({
    type:wifiConstant.ADD_WIFI_SUCCESS,
    payload:data

})

export const addWifiFailure=(data) =>({
    type:wifiConstant.ADD_WIFI_FAILURE,
    payload : data

})


export const  AddWifiAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(addWifiInitiate());
        const response = await axios.post(`${baseUrl}/admin/wifi/add`, payload,{
            headers: {authorization:`${token}`}})

        if (response.status == 201) {
          dispatch(addWifiSuccess(response.data));
        } else {
          dispatch(addWifiFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(addWifiFailure(err));
        return err;
      }


}


// EDIT WIFI

export const editWifiInitiate=() =>({
    type:wifiConstant.EDIT_WIFI_INITIATE

})

export const editWifiSuccess=(data) =>({
    type:wifiConstant.EDIT_WIFI_SUCCESS,
    payload:data

})

export const editWifiFailure=(data) =>({
    type:wifiConstant.EDIT_WIFI_FAILURE,
    payload : data

})


export const  EditWifiAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(editWifiInitiate());
        const response = await axios.put(`${baseUrl}/admin/wifi/update`, payload,{
            headers: {authorization:`${token}`}})

        if (response.data.sucess === true) {
          dispatch(editWifiSuccess(response.data));
        } else {
          dispatch(editWifiFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(editWifiFailure(err));
        return err;
      }


}



// REMOVE WIFI

export const removeWifiInitiate=() =>({
    type:wifiConstant.REMOVE_WIFI_INITIATE

})

export const removeWifiSuccess=(data) =>({
    type:wifiConstant.REMOVE_WIFI_SUCCESS,
    payload:data

})

export const removeWifiFailure=(data) =>({
    type:wifiConstant.REMOVE_WIFI_FAILURE,
    payload : data

})


export const  RemoveWifiAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(removeWifiInitiate());
        const response = await axios.delete(`${baseUrl}/admin/wifi/remove`, {
          headers: {Authorization:`${token}`},params: payload})

        if (response.data.sucess === true) {
          dispatch(removeWifiSuccess(response.data));
        } else {
          dispatch(removeWifiFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(removeWifiFailure(err));
        return err;
      }


}



//  WIFI LIST

export const wifiListInitiate=() =>({
    type:wifiConstant.WIFI_LIST_INITIATE

})

export const wifiListSuccess=(data) =>({
    type:wifiConstant.WIFI_LIST_SUCCESS,
    payload:data

})

export const wifiListFailure=(data) =>({
    type:wifiConstant.WIFI_LIST_FAILURE,
    payload : data

})


export const  WifiListAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(wifiListInitiate());
        const response = await axios.get(`${baseUrl}/admin/wifi/list`, {
          headers: {Authorization:`${token}`},params: payload})

        if (response.status == 200) {
          dispatch(wifiListSuccess(response.data));
        } else {
          dispatch(wifiListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(wifiListFailure(err));
        return err;
      }


}



