import { houseKeepingConstant } from "../actionTypes";

import axios from "../../axiosInstance"
import { baseUrl } from "../../config/config";
import { isLoggedIn } from "../../utils";




// ADD HOUSE_KEEPING

export const addHousekeepingInitiate=() =>({
    type:houseKeepingConstant.ADD_HOUSE_KEEPING_INITIATE

})

export const addHousekeepingSuccess=(data) =>({
    type:houseKeepingConstant.ADD_HOUSE_KEEPING_SUCCESS,
    payload:data

})

export const addHousekeepingFailure=(data) =>({
    type:houseKeepingConstant.ADD_HOUSE_KEEPING_FAILURE,
    payload : data

})


export const  AddHousekeepingAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(addHousekeepingInitiate());
        const response = await axios.post(`${baseUrl}/admin/housekeep/create`, payload,{
            headers: {authorization:`${token}`}})

        if (response.data.success === true) {
          dispatch(addHousekeepingSuccess(response.data));
        } else {
          dispatch(addHousekeepingFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(addHousekeepingFailure(err));
        return err;
      }


}


// EDIT HOUSE_KEEPING

export const editHousekeepingInitiate=() =>({
    type:houseKeepingConstant.UPDATE_HOUSE_KEEPING_INITIATE

})

export const editHousekeepingSuccess=(data) =>({
    type:houseKeepingConstant.UPDATE_HOUSE_KEEPING_SUCCESS,
    payload:data

})

export const editHousekeepingFailure=(data) =>({
    type:houseKeepingConstant.UPDATE_HOUSE_KEEPING_FAILURE,
    payload : data

})


export const  EditHousekeepingAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(editHousekeepingInitiate());
        const response = await axios.put(`${baseUrl}/admin/housekeep/update`, payload,{
            headers: {authorization:`${token}`}})

        if (response.data.sucess === true) {
          dispatch(editHousekeepingSuccess(response.data));
        } else {
          dispatch(editHousekeepingFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(editHousekeepingFailure(err));
        return err;
      }


}



// REMOVE HOUSE_KEEPING

export const removeHousekeepingInitiate=() =>({
    type:houseKeepingConstant.REMOVE_HOUSE_KEEPING_INITIATE

})

export const removeHousekeepingSuccess=(data) =>({
    type:houseKeepingConstant.REMOVE_HOUSE_KEEPING_SUCCESS,
    payload:data

})

export const removeHousekeepingFailure=(data) =>({
    type:houseKeepingConstant.REMOVE_HOUSE_KEEPING_FAILURE,
    payload : data

})


export const  RemoveHousekeepingAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(removeHousekeepingInitiate());
        const response = await axios.delete(`${baseUrl}/admin/housekeep/remove`, {
          headers: {Authorization:`${token}`},params: payload})

        if (response.data.sucess === true) {
          dispatch(removeHousekeepingSuccess(response.data));
        } else {
          dispatch(removeHousekeepingFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(removeHousekeepingFailure(err));
        return err;
      }


}



//  HOUSE_KEEPING LIST

export const houseKeepingListInitiate=() =>({
    type:houseKeepingConstant.HOUSE_KEEPING_LIST_INITIATE

})

export const houseKeepingListSuccess=(data) =>({
    type:houseKeepingConstant.HOUSE_KEEPING_LIST_SUCCESS,
    payload:data

})

export const houseKeepingListFailure=(data) =>({
    type:houseKeepingConstant.HOUSE_KEEPING_LIST_FAILURE,
    payload : data

})


export const  HousekeepingListAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(houseKeepingListInitiate());
        const response = await axios.get(`${baseUrl}/admin/housekeep/get`, {
          headers: {Authorization:`${token}`},params: payload})

          if (response.data.success === true) {
            dispatch(houseKeepingListSuccess(response.data));
        } else {
          dispatch(houseKeepingListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(houseKeepingListFailure(err));
        return err;
      }


}



//  HOUSE_KEEPING Request

export const houseKeepingRequestInitiate=() =>({
  type:houseKeepingConstant.HOUSE_KEEPING_REQUEST_INITIATE

})

export const houseKeepingRequestSuccess=(data) =>({
  type:houseKeepingConstant.HOUSE_KEEPING_REQUEST_SUCCESS,
  payload:data

})

export const houseKeepingRequestFailure=(data) =>({
  type:houseKeepingConstant.HOUSE_KEEPING_REQUEST_FAILURE,
  payload : data

})


export const  HousekeepingRequestAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(houseKeepingRequestInitiate());
      const response = await axios.get(`${baseUrl}/admin/service/request/list`, {
        headers: {Authorization:`${token}`},params: payload})

        if (response.data.success === true) {
          dispatch(houseKeepingRequestSuccess(response.data));
      } else {
        dispatch(houseKeepingRequestFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(houseKeepingRequestFailure(err));
      return err;
    }


}


// REQUEST STATUS

export const requestStatusInitiate=() =>({
  type:houseKeepingConstant.REQUEST_ASSIGN_INITIATE

})

export const requestStatusSuccess=(data) =>({
  type:houseKeepingConstant.REQUEST_STATUS_SUCCESS,
  payload:data

})

export const requestStatusFailure=(data) =>({
  type:houseKeepingConstant.REQUEST_STATUS_FAILURE,
  payload : data

})


export const  RequestStatusAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(requestStatusInitiate());
      const response = await axios.put(`${baseUrl}/admin/service/request/update/status
      `, payload,{
          headers: {authorization:`${token}`}})

          if (response.status >= 200 && response.status <= 300) {
            dispatch(requestStatusSuccess(response.data));
      } else {
        dispatch(requestStatusFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(requestStatusFailure(err));
      return err;
    }


}



// REQUEST ASSIGN

export const requestAssignInitiate=() =>({
  type:houseKeepingConstant.REQUEST_ASSIGN_INITIATE

})

export const requestAssignSuccess=(data) =>({
  type:houseKeepingConstant.REQUEST_ASSIGN_SUCCESS,
  payload:data

})

export const requestAssignFailure=(data) =>({
  type:houseKeepingConstant.REQUEST_ASSIGN_FAILURE,
  payload : data

})


export const  RequestAssignAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(requestAssignInitiate());
      const response = await axios.post(`${baseUrl}/admin/service/request/assign
      `, payload,{
          headers: {authorization:`${token}`}})

          if (response.status >= 200 && response.status <= 300) {
            dispatch(requestAssignSuccess(response.data));
      } else {
        dispatch(requestAssignFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(requestAssignFailure(err));
      return err;
    }


}

