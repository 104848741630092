import axios from "axios";
import { baseLink, baseUrl } from "./config/config";
import { toast } from "react-toastify";
import { AXIOS_APIKEY } from "./config/config";

const axiosInstance = axios.create({
  baseURL: baseUrl, // Replace with your API base URL
});
let timer;




// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {

    config.headers['x-api-key'] = AXIOS_APIKEY; 
    return config;
  },
  (error) => {
    // Handle request errors here
    return Promise.reject(error);
  }
);




// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {


    clearTimeout(timer)
    runTimer()
  
      

    // Modify the response data here (e.g., parse, transform)







    return response;
  },
  (error) => {
    // Handle response errors here

    return Promise.reject(error);
  }
);


  
const runTimer = () => {
  const hotelData = JSON.parse(localStorage.getItem("admin"));

  timer = window.setTimeout(
    () => {
      localStorage.removeItem("admin");
    
      toast.error("Session expired please login again!");
     window.location.href = baseLink;
  }, 1000 * 60 *15);
}
runTimer();





export default axiosInstance;
