import { spaConstant } from "../actionTypes";
const initialState = {
    spaList: [],
    loader: false,
    error: {},
  };
  
  export default function spaReducer(
    state = initialState,
    { type, payload }
    
  ) {
    switch (type) {

      case spaConstant.SPA_LIST_INITIATE:
        return { ...state, loader: true, spaList: [] };
  
      case spaConstant.SPA_LIST_SUCCESS:
        return { ...state, loader: false, spaList: payload.data };
  
      case spaConstant.SPA_LIST_FAILURE:
        return { ...state, loader: false, error: payload };



  
  
      default:
        return state;
    }
  }
  