import React, { useEffect, useState, useRef } from "react";
import Sidenav from "./sidenav";
import Header from "./header";
import Modal from "react-bootstrap/Modal";
import { Link,useParams } from "react-router-dom";
import { useLocation } from "react-router";
import moment from "moment";
import commonCrypto from "../utils";
import ImageFetcher from './ImageFetcher'

const GuestsDetails = () => {
  const { state } = useLocation();
  

  const {id} =useParams()


  return (
    <div>
      <Header />
      <Sidenav />
      <div class="WrapperArea">
        <div class="WrapperBox">
          <div class="Small-Wrapper">
            <div class="NewTitleBox">
              <div className="arrow-left">
                <Link to={`/hotel-room/${id}`}>
                  <i class="fa fa-arrow-left arrow-back" aria-hidden="true"></i>
                </Link>
                <h4>Guests Details</h4>
              </div>
            </div>

            {state?.co_occupants?.map((item) => {
              let cryptData = commonCrypto.decrypt(
                item?.kyc_details
                  ? item?.kyc_details?.docData
                  : item.userId?.[0]?.kyc_details?.docData
              );

              return (
                <div class="GuestUserBox">
                  <figure>
                     <ImageFetcher imageUrl={cryptData !== null ? cryptData?.kycSelfie : ""}/>{" "}
                  </figure>
                  <figcaption>
                    <div class="GuestUserHead">
                      <aside>
                        <p>
                          <label>Name </label>
                          <span>
                            <span>
                              {cryptData !== null
                                ? cryptData?.name
                                : item?.name}
                            </span>
                          </span>
                        </p>

                        {item?.type !== "kid" && (
                          <p>
                            <label>Gender</label>
                            <span>
                              {cryptData !== null
                                ? cryptData?.gender?.charAt(0)?.toUpperCase() +
                                  cryptData?.gender?.slice(1)
                                : item?.gender?.charAt(0)?.toUpperCase() +
                                  item?.gender?.slice(1)}
                            </span>
                          </p>
                        )}
                        <p>
                          <label>Date of Birth</label>
                          <span>
                            {cryptData !== null
                              ? cryptData?.dob
                                ? moment(cryptData?.dob).format("ll")
                                : "NA"
                              : moment(item?.dob).format("ll")}
                          </span>
                        </p>
                        <p>
                          <label>Age</label>
                          <span>
                            {cryptData !== null
                              ? cryptData?.age
                                ? cryptData?.age
                                : "NA"
                              : item?.age}
                          </span>
                        </p>
                        {item?.type == "adult" && cryptData?.docFrontImg && (
                          <p>
                            <label> Documents</label>
                            <span class="passport">
                            <ImageFetcher imageUrl={cryptData?.docFrontImg}/>
                            </span>
                            <span class="passport">
                            <ImageFetcher imageUrl={cryptData?.docFrontImg}/>
                            </span>
                          </p>
                        )}

           
                      </aside>
                      <aside>


                      <p>
                            <label>Verification Method</label>
                            {item?.hasOwnProperty("mannualCheckin")? (item?.mannualCheckin ?"Manual":"Via App"):"Manual"}
                      </p>

                        {item?.type !== "kid" && (
                          <p>
                            <label>Email</label>
                            {cryptData !== null
                              ? cryptData?.email == "" ||
                                cryptData?.email == "false"
                                ? "NA"
                                : cryptData?.email
                              : item?.email == "" || item?.email == "false"
                              ? "NA"
                              : item?.email}{" "}
                          </p>
                        )}
                        <p>
                          <label>Phone Number</label>
                          <span>
                            {item?.type == "kid"
                              ? "NA"
                              : cryptData !== null
                              ? cryptData?.phone
                              : item?.phone}
                          </span>
                        </p>
                        {item?.type !== "kid" && (
                          <p>
                            <label>Address</label>
                            <span style={{lineBreak:"anywhere"}}>
                              {cryptData !== null
                                ? cryptData?.address
                                  ? cryptData?.address
                                  : "NA"
                                : item?.address}
                            </span>
                          </p>
                        )}
                        <p>
                          <label>Verified with Document</label>
                          <span>
                            {item?.type == "kid"
                              ? "NA"
                              : cryptData !== null
                              ? item?.kyc_details
                                ? item?.kyc_details?.docType
                                : item.userId?.[0]?.kyc_details?.docType
                              : "NA"}
                          </span>
                        </p>
                        <p>
                          <label>Document Number</label>
                          <span>
                            {" "}
                            {item?.type == "kid"
                              ? "NA"
                              : cryptData !== null
                              ? cryptData?.docNumber
                              : "NA"}
                          </span>
                        </p>
                      </aside>
                    </div>
                  </figcaption>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuestsDetails;
