import React, { useEffect, useState } from "react";
import Header from "./header";
import Sidenav from "./sidenav";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import {
  AddWifiAction,
  EditWifiAction,
  RemoveWifiAction,
  WifiListAction,
} from "../redux/actions/wifiAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link, useParams } from "react-router-dom";
import NoDataFound from "./noDataFound";
import Loader from "./loader";

const initialState = {
  userName: "",
  password: "",

  errorMsg: {},
};

const HotelWifi = () => {
  const { id } = useParams();
  const hotelDetails =  JSON.parse(window.localStorage.getItem("hotelDetails"))

  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const[wifiData,setWifiData] = useState([])

  const navigate = useNavigate();

  const { wifiList, loader } = useSelector((state) => state.wifiReducer);
  const [wifiId, setWifiId] = useState("");
  const [Type, setType] = useState("add");


  const [password5Shown, setPassword5Shown] = useState(false);
  const toggle5PasswordVisiblity = () => {
    setPassword5Shown(password5Shown ? false : true);
  };


  const [password1Shown, setPassword1Shown] = useState(false);
  const toggle1PasswordVisiblity = (status,i) => {
      const temp = [...wifiData];
      temp[i] = {
          ...temp[i],
          toggle:(status== true)?false:true,
      };
      setWifiData(temp);




    


    setPassword1Shown(password1Shown ? false : true);
  };

  const [iState, updateState] = useState(initialState);
  const { userName, password, errorMsg } = iState;

  const [DeleteModal, setDelete] = useState(false);
  const handleDeleteModalClose = () => setDelete(false);
  const handleDeleteModalShow = (id) => {
    setWifiId(id);
    setDelete(true);
  };

  const [AddModal, setAddModel] = useState(false);
  const handleAddModalClose = () => 
  {
    updateState({
      userName: "",
      password: "",
    
      errorMsg: {},

    })
    setAddModel(false);}

  const handleAddModalShow = (type,item) => {
    if (type == "edit") {
      setWifiId(item?._id);
      setType(type);

      updateState({
        ...iState,
        userName: item?.userName,
        password: item?.password,
      });
      setAddModel(true);
    } else {
      setAddModel(true);
      setType(type);
    }
  };

  const [EditModal, setEditModel] = useState(false);
  const handleEditModalClose = () => setEditModel(false);
  const handleEditModalShow = () => {
    setEditModel(true);
  };

  let handleValidation = () => {
    let errorMsg = {};
    let formIsValid = true;

    if (!userName) {
      errorMsg.userName = "* Please enter user name";
      formIsValid = false;
    }

    if (password?.length <8 && password?.length >0) {
      errorMsg.password = "* Please enter password";
      formIsValid = false;
    }

    updateState({ ...iState, errorMsg: errorMsg });
    return formIsValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if(name=="password"){
      if (value.length >15) return

      updateState({ ...iState, [name]: value });


    }
    else{
      updateState({ ...iState, [name]: value });


    }

  };

  const handleEdit = (e) => {
    e.preventDefault();

    let formIsValid = handleValidation();

    if (formIsValid) {
      var data = {
        userName,
        password,
        _id: wifiId,
        hotelId:id
      };
      dispatch(EditWifiAction(data)).then((res) => {
        if (res.status === 202) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          })
        }
       else {
          toast.error(res.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        
        }



          dispatch(WifiListAction({hotelId:id}));
          setAddModel(false);
          updateState({
            name: "",
            quantity: "",
            price: "",
            category: "",
            errorMsg: {},
          });
        
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formIsValid = handleValidation();


    if (formIsValid) {
      var data = {
        userName,
        password,
        hotelId:  id,
      };
      dispatch(AddWifiAction(data)).then((res) => {
        if (res.status === 200) {
          setAddModel(false);
          dispatch(WifiListAction({ hotelId:id }));
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });

          updateState({
            userName: "",
            password: "",
          
            errorMsg: {},
          
          })
        } else {
          toast.error(res.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        
        }
      }
      );
    }
  };

  const handleDelete = () => {
    dispatch(RemoveWifiAction({ id: wifiId,hotelId:id })).then((res) => {
      if (res.data.success === true) {
        dispatch(WifiListAction({hotelId:id}));
        setDelete(false);
      }
    });
  };

  useEffect(() => {
    dispatch(WifiListAction({hotelId:id}));
  }, []);

  const handleSearch = (e) => {
    dispatch(WifiListAction({ search: e.target.value,hotelId:id }));
    setSearch(e.target.value);
  };


  useEffect(() => {

    if(wifiList){

    let tempList = wifiList?.[0]?.wifiConnections;



    
    let i = 0;
    for (i = 0; i < tempList?.length; i++) {

      tempList[i] = {
        ...tempList[i],
        ["toggle"]: false,
      };
    }
    setWifiData(tempList);}
  }, [wifiList]);

  window.scrollTo(0, 0)
  return (
    <div>
      <Header />
      <Sidenav />

      <div class="WrapperArea">
        <div class="WrapperBox">
          <div class="HotelLocation HotelLocation2">
            <div class="back-btn">
              <Link to={`/hotel-details/${id}`}>
                <i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back{" "}
              </Link>
            </div>

            <aside>
              <h3>{hotelDetails?.name}</h3>
              <p>
                <span>
                  <i class="fa fa-map-marker"></i>
                </span>{" "}
                {hotelDetails?.address}
              </p>
            </aside>
          </div>{" "}
          <div class="Small-Wrapper">
            <div class="NewTitleBox">
              <h4>Wi-fi Management</h4>
              <a onClick={(e) => handleAddModalShow("add")}>Add Network</a>
            </div>

            <div class="Filter">
              <div class="form-group">
                <label>Search</label>
                <input
                  type="text"
                  value={search}
                  onChange={handleSearch}
                  class="form-control"
                  placeholder="Search by user name"
                />
              </div>
            </div>
            {loader? <Loader />:

            <div class="WifiArea">
              {wifiData?.map((item, i) => (
                <div class="WifiBox">
                  <aside>
                    <h4>Connection {i + 1}</h4>
                    <div>
                      <a
                        class="Green"
                        onClick={(e) => handleAddModalShow("edit",item)}
                      >
                        <i class="fa fa-pencil"></i>
                      </a>
                      <a
                        class="Red"
                        onClick={() => handleDeleteModalShow(item?._id)}
                      >
                        <i class="fa fa-trash"></i>
                      </a>
                    </div>
                  </aside>

                  <article>
                    <p>
                      <label>User name</label>
                      <span>{item?.userName}</span>
                    </p>
                    <p>
                      <label>Password</label>
                      <span>
                        <input
                          value={item?.password}
                          disabled={true}
                          type={item?.toggle ?( "text") : "password"}
                        />
                      </span>
                      <span
                        onClick={()=>toggle1PasswordVisiblity(item?.toggle,i)}
                        className={item?.toggle ? "Icon cross" : "Icon"}
                      >
                        {(item?.toggle ? (
                          <i className="fa fa-eye"></i>
                        ) : (
                          <i class="fa fa-eye-slash" aria-hidden="true"></i>
                        ))                  
                      }
                      </span>
                    </p>
                  </article>
                </div>
              ))}


            </div>}
            {loader ? (
            ""
          ) : wifiData?.length > 0 ? (
            ""
          ) : (
            <NoDataFound />
          )}
          </div>
        </div>
      </div>
      <Modal
        show={DeleteModal}
        onHide={handleDeleteModalClose}
        className="ModalBox "
      >
        <Modal.Body>
          <div className="Decline">
            <button className="CloseModal" onClick={handleDeleteModalClose}>
              ×
            </button>
            <h3>Delete</h3>
            <p>Are you sure you want to delete this network ?</p>
            <h4>
              <a onClick={handleDeleteModalClose} className="Cancel">
                Cancel
              </a>
              <a onClick={handleDelete}>Delete</a>
            </h4>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={AddModal} onHide={handleAddModalClose} className="ModalBox ">
        <Modal.Body>
          <div class="Category">
            <a class="CloseModal" onClick={handleAddModalClose}>
              ×
            </a>
            {Type == "edit" ? <h3>Update Network</h3> : <h3>Add Network</h3>}
            <div class="form-group">
              <label>Network User name</label>
              <input
                type="text"
                value={userName}
                name="userName"
                onChange={handleChange}
                class="form-control"
              />
              {errorMsg.userName && !userName && (
                <p style={{ color: "red" }}>{errorMsg.userName}</p>
              )}
            </div>
            <div class="form-group">
              <label>Network Password</label>
              <input
                type={password5Shown ? "text" : "password"}
                value={password}
                name="password"
                onChange={handleChange}
                class="form-control"
              />
              
              <span
                onClick={toggle5PasswordVisiblity}
                className={password5Shown ? "Icon cross" : "Icon"}
              >
                {password5Shown ? (
                  <i className="fa fa-eye"></i>
                ) : (
                  <i class="fa fa-eye-slash" aria-hidden="true"></i>
                )}
              </span>
              {errorMsg.password && !password && (
                <p style={{ color: "red" }}>{errorMsg.password}</p>
              )}
            </div>
            {Type == "edit" ? (
              <button class="Button" onClick={handleEdit}>
                Update
              </button>
            ) : (
              <button class="Button" onClick={handleSubmit}>
                Add
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default HotelWifi;
