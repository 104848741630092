import React from "react";
import Header from "./header";
import Sidenav from "./sidenav";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { SocketContext } from "../config/sckt";
import { useContext } from "react";
import { useState } from "react";
import SendIcon from "../assets/image/send.svg";
import { ChatHitoryAction } from "../redux/actions/chatAction";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import ImageFetcher from './ImageFetcher'
import { Link } from "react-router-dom";

const RestaurantChat = () => {
  const {id} = useParams()
  const { state } = useLocation();
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const [chatData, setChatData] = useState([]);
  const { chatHistory } = useSelector((state) => state.chatReducer);

  const [msg, setMsg] = useState("");
  const hotelData = JSON.parse(localStorage.getItem("hotel"));

  useEffect(() => {
    if (state) {
      socket.emit("checkin_room_join", { roomId: state?.chatRoomId });
    }
  }, []);

  const handleSend = () => {
    if(msg?.trim()){
    socket.emit("create-new-message", {
      chatRoomId: state?.chatRoomId,
      message: {
        mtype: "text",
        message: msg,
      },
      hotelId: hotelData?.hotelId?.[0]?._id,
      serviceType: "foodandbeverage",
      sender: hotelData?.hotelId?.[0]?._id,
      receiver: state?.sender?.[0]?._id,
    });}
    setMsg("");
  };

  useEffect(() => {
    dispatch(ChatHitoryAction({ chatRoomId: state?.chatRoomId }));
  }, []);

  useEffect(() => {
    setChatData(chatHistory);
  }, [chatHistory]);

  useEffect(() => {
    socket.on("get-new-message", (data) => {
      //   console.log("listening  NEW MESSAGE=============>", data)
      setChatData((prev) => [...prev, data]);
    });
  }, []);

  useEffect(() => {
    socket.on("create-new-message", (data) => {
      //   console.log("listening  NEW MESSAGE=============>", data)
      setChatData((prev) => [...prev, data]);
    });
  }, []);

  const handleKeyDown = (e) => {
    if (e.keyCode == 13 && !e.shiftKey) {
      // prevent default behavior
      e.preventDefault();
      if (msg.trim()) {
        handleSend();
      }

      return false;
    }
  };

  return (
    <div>
      <Header />
      <Sidenav />
      <div class="WrapperArea">
        <div class="WrapperBox">
          <div class="NewTitleBox">
            <div className="arrow-left">
              <Link to={`/restaurant-msg/${id}`}>
                <i class="fa fa-arrow-left arrow-back" aria-hidden="true"></i>
              </Link>

              <h4>Chat</h4>
            </div>
          </div>

          <div class="SupportDetails">
            <ul>
              {chatData?.map((item) => (
                <li
                  class={
                    item?.sender == hotelData?.hotelId?.[0]?._id
                      ? "OtherMessage"
                      : ""
                  }
                >
                  <figure>
                    <img
                      src={
                        item?.sender == hotelData?.hotelId?.[0]?._id
                          ? require("../assets/image/Avatar-3.png")
                          : require("../assets/image/Avatar-2.png")
                      }
                    />
                  </figure>
                  <figcaption>
                    <h3>
                      {item?.sender == hotelData?.hotelId?.[0]?._id
                        ? "Admin"
                        : state?.sender?.[0]?.name}
                    </h3>
                    <p>{item?.message?.message}</p>
                    <span class="time">
                      {moment(item?.updatedAt).format("lll")}
                    </span>
                  </figcaption>
                </li>
              ))}
            </ul>

            <div class="MessageReply">
              <textarea
                onKeyDown={handleKeyDown}
                onChange={(e) => setMsg(e.target.value)}
                value={msg}
                class="form-control"
                rows="5"
              ></textarea>
              <div class="msg-send">
                <button>
                  <img src="images/cemra-icon.svg" alt="" />
                  <input type="file" />
                </button>
                <button disabled={msg==""?true:false} onClick={handleSend}>
                  <img src={SendIcon} alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RestaurantChat;
