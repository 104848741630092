import React from 'react'

import user from '../assets/image/user.png'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import {toast} from 'react-toastify'

import {LogoutUserAction} from '../redux/actions/authAction'
const Header = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const AdminData = JSON.parse(localStorage.getItem("admin"));

    const handleLogout=()=>{
      navigate('/')

        dispatch(LogoutUserAction({_id: AdminData?._id}))
        .then((res) => {
          if (res.status === 200) {
            localStorage.removeItem("admin");


          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });

          }
        })
        .catch((err) => {
          alert(err);
        });
    



    }
  return (
    <div>
        <div class="Header">
        <div class="Navigation">            
            
            <div class="Bell">
                <Link to="/notification">
                    <i class="fa fa-bell"></i>
                </Link>
            </div>

            <div class="Avater">
                <a href="javascript:void(0);">
                    <figure>
                        <img src={user} />
                    </figure>                    
                </a>
                <ul>
                    <li>
                        <figure><img src={user} /></figure>
                        <h4> <span>Administrator</span></h4>
                    </li>
                    <li>
                        <a onClick={handleLogout}>
                            <span><i class="fa fa-sign-out"></i></span> Logout
                        </a>
                    </li>
                </ul>
            </div>

            <div class="clear"></div>
        </div>
    </div>
    </div>
  )
}

export default Header