import { restaurantConstant } from "../actionTypes";

import axios from "../../axiosInstance"
import { baseUrl } from "../../config/config";
import { isLoggedIn } from "../../utils";




// ADD SERVICECATEGORY

export const addServiceCategoryInitiate=() =>({
    type:restaurantConstant.ADD_SERVICE_CATEGORY_INITIATE

})

export const addServiceCategorySuccess=(data) =>({
    type:restaurantConstant.ADD_SERVICE_CATEGORY_SUCCESS,
    payload:data

})

export const addServiceCategoryFailure=(data) =>({
    type:restaurantConstant.ADD_SERVICE_CATEGORY_FAILURE,
    payload : data

})


export const  AddServiceCategoryAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(addServiceCategoryInitiate());
        const response = await axios.post(`${baseUrl}/admin/foodandbeverage/add/servingcategory`, payload,{
            headers: {authorization:`${token}`}})

        if (response.status == 201) {
          dispatch(addServiceCategorySuccess(response.data));
        } else {
          dispatch(addServiceCategoryFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(addServiceCategoryFailure(err));
        return err;
      }


}


// EDIT SERVICECATEGORY

export const editServiceCategoryInitiate=() =>({
    type:restaurantConstant.EDIT_SERVICE_CATEGORY_INITIATE

})

export const editServiceCategorySuccess=(data) =>({
    type:restaurantConstant.EDIT_SERVICE_CATEGORY_SUCCESS,
    payload:data

})

export const editServiceCategoryFailure=(data) =>({
    type:restaurantConstant.EDIT_SERVICE_CATEGORY_FAILURE,
    payload : data

})


export const  EditServiceCategoryAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(editServiceCategoryInitiate());
        const response = await axios.put(`${baseUrl}/admin/foodandbeverage/update/servingcategory`, payload,{
            headers: {authorization:`${token}`}})

        if (response.data.sucess === true) {
          dispatch(editServiceCategorySuccess(response.data));
        } else {
          dispatch(editServiceCategoryFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(editServiceCategoryFailure(err));
        return err;
      }


}



// REMOVE SERVICECATEGORY

export const removeServiceCategoryInitiate=() =>({
    type:restaurantConstant.REMOVE_SERVICE_CATEGORY_INITIATE

})

export const removeServiceCategorySuccess=(data) =>({
    type:restaurantConstant.REMOVE_SERVICE_CATEGORY_SUCCESS,
    payload:data

})

export const removeServiceCategoryFailure=(data) =>({
    type:restaurantConstant.REMOVE_SERVICE_CATEGORY_FAILURE,
    payload : data

})


export const  RemoveServiceCategoryAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(removeServiceCategoryInitiate());
        const response = await axios.delete(`${baseUrl}/admin/foodandbeverage/remove/servingcategory`, {
          headers: {Authorization:`${token}`},params: payload})

        if (response.data.sucess === true) {
          dispatch(removeServiceCategorySuccess(response.data));
        } else {
          dispatch(removeServiceCategoryFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(removeServiceCategoryFailure(err));
        return err;
      }


}



//  SERVICECATEGORY LIST

export const servicecategoryListInitiate=() =>({
    type:restaurantConstant.SERVICE_CATEGORY_LIST_INITIATE

})

export const servicecategoryListSuccess=(data) =>({
    type:restaurantConstant.SERVICE_CATEGORY_LIST_SUCCESS,
    payload:data

})

export const servicecategoryListFailure=(data) =>({
    type:restaurantConstant.SERVICE_CATEGORY_LIST_FAILURE,
    payload : data

})


export const  ServiceCategoryListAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(servicecategoryListInitiate());
        const response = await axios.get(`${baseUrl}/admin/foodandbeverage/profile`, {
          headers: {Authorization:`${token}`},params: payload})
          console.log(response ,'AAAAAAAAAAAAAAAaaa')


        if (response.status == 200) {
          dispatch(servicecategoryListSuccess(response.data));
        } else {
          dispatch(servicecategoryListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(servicecategoryListFailure(err));
        return err;
      }


}




// ADD FOOD CATEGORY

export const addFoodCategoryInitiate=() =>({
    type:restaurantConstant.ADD_FOOD_CATEGORY_INITIATE

})

export const addFoodCategorySuccess=(data) =>({
    type:restaurantConstant.ADD_FOOD_CATEGORY_SUCCESS,
    payload:data

})

export const addFoodCategoryFailure=(data) =>({
    type:restaurantConstant.ADD_FOOD_CATEGORY_FAILURE,
    payload : data

})


export const  AddFoodCategoryAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(addFoodCategoryInitiate());
        const response = await axios.post(`${baseUrl}/admin/foodandbeverage/add/category`, payload,{
            headers: {authorization:`${token}`}})

        if (response.status == 201) {
          dispatch(addFoodCategorySuccess(response.data));
        } else {
          dispatch(addFoodCategoryFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(addFoodCategoryFailure(err));
        return err;
      }


}


// EDIT FOOD CATEGORY

export const editFoodCategoryInitiate=() =>({
    type:restaurantConstant.EDIT_FOOD_CATEGORY_INITIATE

})

export const editFoodCategorySuccess=(data) =>({
    type:restaurantConstant.EDIT_FOOD_CATEGORY_SUCCESS,
    payload:data

})

export const editFoodCategoryFailure=(data) =>({
    type:restaurantConstant.EDIT_FOOD_CATEGORY_FAILURE,
    payload : data

})


export const  EditFoodCategoryAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(editFoodCategoryInitiate());
        const response = await axios.put(`${baseUrl}/admin/foodandbeverage/update/category`, payload,{
            headers: {authorization:`${token}`}})

        if (response.data.sucess === true) {
          dispatch(editFoodCategorySuccess(response.data));
        } else {
          dispatch(editFoodCategoryFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(editFoodCategoryFailure(err));
        return err;
      }


}



// REMOVE FOOD CATEGORY

export const removeFoodCategoryInitiate=() =>({
    type:restaurantConstant.REMOVE_FOOD_CATEGORY_INITIATE

})

export const removeFoodCategorySuccess=(data) =>({
    type:restaurantConstant.REMOVE_FOOD_CATEGORY_SUCCESS,
    payload:data

})

export const removeFoodCategoryFailure=(data) =>({
    type:restaurantConstant.REMOVE_FOOD_CATEGORY_FAILURE,
    payload : data

})


export const  RemoveFoodCategoryAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(removeServiceCategoryInitiate());
        const response = await axios.delete(`${baseUrl}/admin/foodandbeverage/remove/category`, {
          headers: {Authorization:`${token}`},params: payload})

        if (response.data.sucess === true) {
          dispatch(removeFoodCategorySuccess(response.data));
        } else {
          dispatch(removeFoodCategoryFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(removeFoodCategoryFailure(err));
        return err;
      }


}



//  FOOD CATEGORY LIST

export const foodCategoryListInitiate=() =>({
    type:restaurantConstant.FOOD_CATEGORY_LIST_INITIATE

})

export const foodCategoryListSuccess=(data) =>({
    type:restaurantConstant.FOOD_CATEGORY_LIST_SUCCESS,
    payload:data

})

export const foodCategoryListFailure=(data) =>({
    type:restaurantConstant.FOOD_CATEGORY_LIST_FAILURE,
    payload : data

})


export const  FoodCategoryListAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(foodCategoryListInitiate());
        const response = await axios.get(`${baseUrl}/admin/servicecategory/list`, {
          headers: {Authorization:`${token}`},params: payload})

        if (response.status == 200) {
          dispatch(foodCategoryListSuccess(response.data));
        } else {
          dispatch(foodCategoryListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(foodCategoryListFailure(err));
        return err;
      }


}



// ADD RESTAURANT TIME

export const addRestTimeInitiate=() =>({
  type:restaurantConstant.ADD_RESTAURANT_TIME_INITIATE

})

export const addRestTimeSuccess=(data) =>({
  type:restaurantConstant.ADD_RESTAURANT_TIME_SUCCESS,
  payload:data

})

export const addRestTimeFailure=(data) =>({
  type:restaurantConstant.ADD_RESTAURANT_TIME_FAILURE,
  payload : data

})


export const  AddRestaurantTimeAction=(payload)=> async(dispatch)=>{
  console.log("disptach-----");
  const token = isLoggedIn('admin');
  try {
      dispatch(addRestTimeInitiate());
      const response = await axios.post(`${baseUrl}/admin/foodandbeverage/settiming`, payload,{
          headers: {authorization:`${token}`}})

      if (response.status == 201) {
        dispatch(addRestTimeSuccess(response.data));
      } else {
        dispatch(addRestTimeFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(addRestTimeFailure(err));
      return err;
    }


}




//  FOOD ORDER REQUEST LIST

export const foodOrderResquestsInitiate=() =>({
  type:restaurantConstant.FOOD_ORDER_REQUEST_LIST_INITIATE

})

export const foodOrderResquestsSuccess=(data) =>({
  type:restaurantConstant.FOOD_ORDER_REQUEST_LIST_SUCCESS,
  payload:data

})

export const foodOrderResquestsFailure=(data) =>({
  type:restaurantConstant.FOOD_ORDER_REQUEST_LIST_FAILURE,
  payload : data

})


export const  FoodOrderResquestsAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(foodOrderResquestsInitiate());
      const response = await axios.get(`${baseUrl}/admin/order/request/get`, {
        headers: {Authorization:`${token}`},params: payload})

      if (response.status == 200) {
        dispatch(foodOrderResquestsSuccess(response.data));
      } else {
        dispatch(foodOrderResquestsFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(foodOrderResquestsFailure(err));
      return err;
    }


}





//  CREATE ORDER

export const createOrderInitiate=() =>({
  type:restaurantConstant.CREATE_FOOD_ORDER_INITIATE

})

export const createOrderSuccess=(data) =>({
  type:restaurantConstant.CREATE_FOOD_ORDER_SUCCESS,
  payload:data

})

export const createOrderFailure=(data) =>({
  type:restaurantConstant.CREATE_FOOD_ORDER_FAILURE,
  payload : data

})


export const CreateOrderAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(createOrderInitiate());
      const response = await axios.post(`${baseUrl}/admin/order/request/create`, payload,{
        headers: {authorization:`${token}`}})

      if (response.status == 200) {
        dispatch(createOrderSuccess(response.data));
      } else {
        dispatch(createOrderFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(createOrderFailure(err));
      return err;
    }


}



//  ASSIGN ORDER

export const assignOrderInitiate=() =>({
  type:restaurantConstant.ASSIGN_FOOD_ORDER_INITIATE

})

export const assignOrderSuccess=(data) =>({
  type:restaurantConstant.ASSIGN_FOOD_ORDER_SUCCESS,
  payload:data

})

export const assignOrderFailure=(data) =>({
  type:restaurantConstant.ASSIGN_FOOD_ORDER_FAILURE,
  payload : data

})


export const AssignOrderAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(assignOrderInitiate());
      const response = await axios.post(`${baseUrl}/admin/order/assign`, payload,{
        headers: {authorization:`${token}`}})

      if (response.status == 200) {
        dispatch(assignOrderSuccess(response.data));
      } else {
        dispatch(assignOrderFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(assignOrderFailure(err));
      return err;
    }


}








//  AVAILABLE STAFF LIST

export const availableStaffInitiate=() =>({
  type:restaurantConstant.AVAILABLE_STAFF_INITIATE

})

export const availableStaffSuccess=(data) =>({
  type:restaurantConstant.AVAILABLE_STAFF_SUCCESS,
  payload:data

})

export const availableStaffFailure=(data) =>({
  type:restaurantConstant.AVAILABLE_STAFF_FAILURE,
  payload : data

})


export const  AvailableStaffAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(availableStaffInitiate());
      const response = await axios.get(`${baseUrl}/admin/staff/available/list`, {
        headers: {Authorization:`${token}`},params: payload})

      if (response.status == 200) {
        dispatch(availableStaffSuccess(response.data));
      } else {
        dispatch(availableStaffFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(availableStaffFailure(err));
      return err;
    }


}






//  ORDER STATUS CHANGE

export const orderStatusInitiate=() =>({
  type:restaurantConstant.ORDER_STATUS_INITIATE

})

export const orderStatusSuccess=(data) =>({
  type:restaurantConstant.ORDER_STATUS_SUCCESS,
  payload:data

})

export const orderStatusFailure=(data) =>({
  type:restaurantConstant.ORDER_STATUS_FAILURE,
  payload : data

})


export const  OrderStatusAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(orderStatusInitiate());
      const response = await axios.put(`${baseUrl}/admin/order/update/status`, payload,{
        headers: {authorization:`${token}`}})



      if (response.status == 200) {
        dispatch(orderStatusSuccess(response.data));
      } else {
        dispatch(orderStatusFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(orderStatusFailure(err));
      return err;
    }

}






// SET TAX

export const setTaxInitiate=() =>({
  type:restaurantConstant.RESTAURANT_TAX_INITIATE

})

export const setTaxSuccess=(data) =>({
  type:restaurantConstant.RESTAURANT_TAX_SUCCESS,
  payload:data

})

export const setTaxFailure=(data) =>({
  type:restaurantConstant.RESTAURANT_TAX_FAILURE,
  payload : data

})


export const  SetTaxAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(setTaxInitiate());
      const response = await axios.post(`${baseUrl}/admin/foodandbeverage/settax`, payload,{
        headers: {authorization:`${token}`}})



      if (response.status == 200) {
        dispatch(setTaxSuccess(response.data));
      } else {
        dispatch(setTaxFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(setTaxFailure(err));
      return err;
    }


}




