import React, {useState,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './header';
import Sidenav from './sidenav';
import GuestDeletionCard from './GuestDeletionCard';


const CancelRequest = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch('/users.json');
        const data = await response.json();
        const pendingUsers = data.filter(user => user.status === 'Cancelled');
        setUsers(pendingUsers);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUsers();
  }, []);

  const ArrowLeftIcon = ({ onClick, className }) => (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 448 512" 
      onClick={onClick}
      className={className}
      style={{ cursor: 'pointer', width: '24px', height: '24px', fill: '#165885' }}
    >
      <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/>
    </svg>
  );

  return (
  <>
  <Header/>
      <Sidenav />
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="Small-Wrapper">   
          <div className="top">
          <ArrowLeftIcon onClick={() => navigate(-1)}/>
              <h4 className="Title"> Deleted Requests</h4>
              </div>
            </div>
      <div className="user-list">
        {users.map((user, index) => (
          <GuestDeletionCard key={index} user={user} showActions />
        ))}
      </div>
    </div>
    </div>
   
    </>
  );
};

export default CancelRequest;