import React, { useEffect } from "react";
import Header from "./header";
import Sidenav from "./sidenav";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  RemoveRoleAction,
  RoleListAction,
  StatusRoleAction,
} from "../redux/actions/roleAction";
import { useState } from "react";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";

import NoDataFound from "./noDataFound";
import Pagination from "react-js-pagination";
import Loader from "./loader";

const RolesManagement = () => {
  const dispatch = useDispatch();
  const [activePage, setActivePage] = useState(1);

  const {id} = useParams()

  const hotelDetails =  JSON.parse(window.localStorage.getItem("hotelDetails"))


  const hotelData = JSON.parse(localStorage.getItem("hotel"));

  const [filterData, setFilterData] = useState({
    timeFrame: "",
    startDate: "",
    endDate: "",
    search: "",
  });

  const { roleList, loader } = useSelector((state) => state.roleReducer);
  const [search, setSearch] = useState("");
  const [role, setRole] = useState("");
  const [type, setType] = useState("");

  const [roleId, setRoleId] = useState("");

  const [DeleteModal, setDelete] = useState(false);
  const handleDeleteModalClose = () => setDelete(false);
  const handleDeleteModalShow = (id) => {
    setRoleId(id);
    setDelete(true);
  };

  useEffect(() => {
      dispatch(RoleListAction({hotelId:id}));
  }, []);

  const handleStatusChange = (item) => {
    let Temp = "";
    if (item?.status == "active") {
      Temp = "inactive";
    } else {
      Temp = "active";
    }
    dispatch(StatusRoleAction({ _id: item?._id, status: Temp ,hotelId:id}))
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
            dispatch(RoleListAction({hotelId:id}));
        
          setRole("");
          setSearch("");
          setType("");

          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(res.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const handleDelete = () => {
    dispatch(RemoveRoleAction({ id: roleId ,hotelId:id })).then((res) => {
      if (res.status >= 200 && res.status <= 300) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setRole("");
        setSearch("");
        setType("");

        setDelete(false);
          dispatch(RoleListAction({hotelId:id}));
      }
    });
  };

  const handleSearch = (e) => {
      dispatch(RoleListAction({ search: e.target.value,hotelId:id }));

    setSearch(e.target.value);
  };

  const handleRole = (e) => {
      dispatch(RoleListAction({ role: e.target.value ,type,hotelId:id}));

    setRole(e.target.value);
  };

  const handleRefresh = () => {
    dispatch(RoleListAction({hotelId:id}));
    setRole("");
    setSearch("");
    setType("");
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    filterData.page = pageNumber;
      dispatch(RoleListAction({ page: activePage ,hotelId:id}));

  };

  const handleEmployee = (e) => {
    dispatch(RoleListAction({ type: e.target.value, role,hotelId:id }));

    setType(e.target.value);
  };


  window.scrollTo(0, 0)
  return (
    <div>
      <Header />
      <Sidenav />

      <div class="WrapperArea">
        <div class="WrapperBox">
        <div class="HotelLocation HotelLocation2">
            <div class="back-btn">
              <Link to={`/hotel-details/${id}`}>
                <i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back{" "}
              </Link>
            </div>

            <aside>
              <h3>{hotelDetails?.name}</h3>
              <p>
                <span>
                  <i class="fa fa-map-marker"></i>
                </span>{" "}
                {hotelDetails?.address}
              </p>
            </aside>
          </div>

          <div class="Small-Wrapper">
            <div class="NewTitleBox">
              <h4>Roles Management</h4>
              <Link to={`/role-add/${id}`}>+ Add Employee</Link>
            </div>

            <div class="Filter">
              <div class="form-group">
                <label>Search</label>
                <input
                  type="text"
                  value={search}
                  onChange={handleSearch}
                  class="form-control"
                  placeholder="Search.."
                />
              </div>

                <div class="form-group">
                  <label>Employee Type</label>
                  <select
                    value={type}
                    onChange={handleEmployee}
                    class="form-control"
                  >
                    <option value="">Select Employee Type</option>
                    <option value="manager">Manager </option>
                    <option value="staff">Staff </option>
                  </select>
                </div>
                <div class="form-group">
                  <label>Role Type</label>
                  <select
                    value={role}
                    onChange={handleRole}
                    class="form-control"
                  >
                    <option value="">Select Role Type</option>
                    <option value="security">Security </option>
                    <option value="frontdesk">Front Desk </option>
                    <option value="spa">Spa </option>
                    <option value="foodandbeverage">FNB </option>
                    <option value="housekeeping">Housekeeping </option>
                  </select>
                </div>

              <div class="form-group">
                <label>&nbsp;</label>

                <button onClick={handleRefresh} class="Button Cancel">
                  <i class="fa fa-refresh"></i>
                </button>
              </div>
            </div>
            {loader? <Loader />:

            <div class="TableList">
              <table style={{ width: "115%" }}>
                <thead>
                  <tr>
                    <th>Member ID</th>
                    <th>Staff Member Name</th>
                    <th>Phone Number</th>
                    <th>Email</th>
                    <th>Duty Hours</th>
                    <th>Role</th>
                    <th>Status</th>
                    {/* <th>Access</th> */}
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {roleList?.[0]?.data?.map((item) => {
                    let role =
                      item?.role?.charAt(0)?.toUpperCase() +
                      item?.role?.slice(1);
                    return (
                      <tr>
                        <td>{item.staffId}</td>
                        <td>
                          <Link to={`/role-details/${id}`} state={item}>
                            {item?.name}
                          </Link>
                        </td>
                        <td>{item?.phone}</td>
                        <td>{item?.email}</td>

                        <td>
                          {item?.duty_hours?.start} to {item?.duty_hours?.end}{" "}
                        </td>
                        <td>
                          {item?.isManager
                            ? `${role?.split("_")?.[0]} Manager`
                            : `${role} Staff`}
                        </td>
                        {/* <td>{item?.reportingManager?.name}</td> */}
                        <td>
                          <span
                            class={item?.status == "active" ? "Green" : "Red"}
                          >
                            {item?.status?.charAt(0)?.toUpperCase() +
                              item?.status?.slice(1)}
                          </span>
                        </td>
                        {/* <td>{(item?.role =="delivery_person" ||item?.role =="security_staff" )?"App Access":"Web Access"}</td> */}
                        <td>
                          <div class="Actions">
                            <label class="switch">
                              <input
                                type="checkbox"
                                onChange={() => handleStatusChange(item)}
                                checked={
                                  item?.status == "active" ? true : false
                                }
                              />
                              <span class="slider"></span>
                            </label>
                            <Link class="Green" to={`/role-edit/${id}`} state={item}>
                              <i class="fa fa-pencil"></i>
                            </Link>
                            <a
                              class="Red"
                              data-toggle="modal"
                              onClick={() => handleDeleteModalShow(item?._id)}
                            >
                              <i class="fa fa-trash"></i>
                            </a>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {loader ? (
                ""
              ) : roleList?.[0]?.metadata?.[0]?.total > 0 ? (
                ""
              ) : (
                <NoDataFound />
              )}
            </div>}

            {roleList?.[0]?.metadata?.[0]?.total > 0 ? (
              <div className="Pagination">
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={roleList?.[0]?.metadata?.[0]?.total}
                  pageRangeDisplayed={5}
                  onChange={(e) => handlePageChange(e)}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <Modal
        show={DeleteModal}
        onHide={handleDeleteModalClose}
        className="ModalBox "
      >
        <Modal.Body>
          <div className="Decline">
            <button className="CloseModal" onClick={handleDeleteModalClose}>
              ×
            </button>
            <h3>Delete</h3>
            <p>Are you sure you want to delete this Role ?</p>
            <h4>
              <a onClick={handleDeleteModalClose} className="Cancel">
                Cancel
              </a>
              <a onClick={handleDelete}>Delete</a>
            </h4>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RolesManagement;
