import { chatConstant } from "../actionTypes";

import axios from "../../axiosInstance"
import { baseUrl } from "../../config/config";
import { isLoggedIn } from "../../utils";




// CHAT HISTORY

export const chatHitoryInitiate=() =>({
    type:chatConstant.CHAT_HISTORY_INITIATE

})

export const chatHitorySuccess=(data) =>({
    type:chatConstant.CHAT_HISTORY_SUCCESS,
    payload:data

})

export const chatHitoryFailure=(data) =>({
    type:chatConstant.CHAT_HISTORY_FAILURE,
    payload : data

})


export const  ChatHitoryAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(chatHitoryInitiate());
        const response = await axios.get(`${baseUrl}/admin/chat/history/get`, {
          headers: {Authorization:`${token}`},params: payload})

          if (response.data.success === true) {
            dispatch(chatHitorySuccess(response.data));
        } else {
          dispatch(chatHitoryFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(chatHitoryFailure(err));
        return err;
      }


}




// CHAT LIST

export const chatListInitiate=() =>({
    type:chatConstant.CHAT_LIST_INITIATE

})

export const chatListSuccess=(data) =>({
    type:chatConstant.CHAT_LIST_SUCCESS,
    payload:data

})

export const chatListFailure=(data) =>({
    type:chatConstant.CHAT_HISTORY_FAILURE,
    payload : data

})


export const ChatListAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(chatListInitiate());
        const response = await axios.get(`${baseUrl}/admin/chatroom/chatlist/get`, {
          headers: {Authorization:`${token}`},params: payload})

          if (response.data.success === true) {
            dispatch(chatListSuccess(response.data));
        } else {
          dispatch(chatListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(chatListFailure(err));
        return err;
      }


}





// CHAT ROOM HISTORY

export const chatRoomHitoryInitiate=() =>({
  type:chatConstant.CHAT_ROOM_HISTORY_INITIATE

})

export const chatRoomHitorySuccess=(data) =>({
  type:chatConstant.CHAT_ROOM_HISTORY_SUCCESS,
  payload:data

})

export const chatRoomHitoryFailure=(data) =>({
  type:chatConstant.CHAT_ROOM_HISTORY_FAILURE,
  payload : data

})


export const  ChatRoomHitoryAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(chatRoomHitoryInitiate());
      const response = await axios.get(`${baseUrl}/admin/chatroom/close/chat/history`, {
        headers: {Authorization:`${token}`},params: payload})

        if (response.data.success === true) {
          dispatch(chatRoomHitorySuccess(response.data));
      } else {
        dispatch(chatRoomHitoryFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(chatRoomHitoryFailure(err));
      return err;
    }


}


// CLOSE CHAT

export const closeChatInitiate=() =>({
  type:chatConstant.CHAT_CLOSE_INITIATE

})

export const closeChatSuccess=(data) =>({
  type:chatConstant.CHAT_CLOSE_SUCCESS,
  payload:data

})

export const closeChatFailure=(data) =>({
  type:chatConstant.CHAT_CLOSE_FAILURE,
  payload : data

})


export const  CloseChatAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(closeChatInitiate());
      const response = await axios.put(`${baseUrl}/admin/chatroom/chat/close`, payload,{
          headers: {authorization:`${token}`}})

      if (response.data.success === true) {
        dispatch(closeChatSuccess(response.data));
      } else {
        dispatch(closeChatFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(closeChatFailure(err));
      return err;
    }


}


