import { contentManagementConstant } from "../actionTypes";
const initialState = {
    aboutusContent: {},
    privacyContent:{},
    termsContent:{},

    loader: false,
    error: {},
  };
  
  export default function contentReducer(
    state = initialState,
    { type, payload }
    
  ) {
    switch (type) {

      case contentManagementConstant.GET_CONTENT_ABOUT_US_INITIATE:
        return { ...state, loader: true, aboutusContent: {} };
  
      case contentManagementConstant.GET_CONTENT_ABOUT_US_SUCCESS:
        return { ...state, loader: false, aboutusContent: payload.data };
  
      case contentManagementConstant.GET_CONTENT_ABOUT_US_FAILURE:
        return { ...state, loader: false, error: payload };


        case contentManagementConstant.GET_CONTENT_PRIVACY_POLICY_INITIATE:
          return { ...state, loader: true, privacyContent: {} };
    
        case contentManagementConstant.GET_CONTENT_PRIVACY_POLICY_SUCCESS:
          return { ...state, loader: false, privacyContent: payload.data };
    
        case contentManagementConstant.GET_CONTENT_PRIVACY_POLICY_FAILURE:
          return { ...state, loader: false, error: payload };
  
          case contentManagementConstant.GET_CONTENT_TERM_CONDITION_INITIATE:
            return { ...state, loader: true, termsContent: {} };
      
          case contentManagementConstant.GET_CONTENT_TERM_CONDITION_SUCCESS:
            return { ...state, loader: false, termsContent: payload.data };
      
          case contentManagementConstant.GET_CONTENT_TERM_CONDITION_FAILURE:
            return { ...state, loader: false, error: payload };
    
  
  
  
  
      default:
        return state;
    }
  }
  