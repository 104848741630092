import React, { useEffect, useState } from "react";
import Sidenav from "./sidenav";
import Header from "./header";
import Modal from "react-bootstrap/Modal";
import axiosInstance from '../axiosInstance';
import { useDispatch, useSelector } from "react-redux";
import {
  AddFoodCategoryAction,
  AddRestaurantTimeAction,
  AddServiceCategoryAction,
  EditFoodCategoryAction,
  EditServiceCategoryAction,
  RemoveFoodCategoryAction,
  RemoveServiceCategoryAction,
  ServiceCategoryListAction,
  SetTaxAction,
} from "../redux/actions/restaurantAction";
import { toast } from "react-toastify";
import moment from "moment";
import { isLoggedIn } from "../utils";
import axios from "axios";
import { baseUrl } from "../config/config";
import Loader from "./loader";
import { Link, useParams } from "react-router-dom";




const initialState = {
  serviceName: "",
  serviceOpenTime: "",
  serviceCloseTime: "",
  serviceError: {},
};

const initialCategoryState = {
  name: "",
  categoryError: {},
};

const initialRestaurantState = {
  start: "",
  end: "",
  restaurantError: {},
};

const RestaurantProfile = () => {

  const {id} = useParams()

  const hotelDetails = JSON.parse(window.localStorage.getItem("hotelDetails"));

  const [showAddCategoryButton, setShowAddCategoryButton] = useState(true);
  const [stopper, setStopper] = useState(false)

  const [Type, setType] = useState("add");
  const [itemId, setItemId] = useState("");
  const token = isLoggedIn("hotel");
  const mealTypeOptions = ["Veg", "Non-Veg", "Drinks"];
  const [mealType, setMealType] = useState([]);
  const [options,setOptions] =useState([])

  const dayInterval=['Breakfast','Lunch','Dinner','All Day']

  const dispatch = useDispatch();
  const { serviceList,loader } = useSelector((state) => state.restaurantReducer);
  const [iState, updateState] = useState(initialState);
  const [iCategoryState, updateCategoryState] = useState(initialCategoryState);
  const [iRestaurantState, updateRestaurantState] = useState(
    initialRestaurantState
  );
  const allDayCheck =serviceList?.servingCategories?.filter((item)=>item?.name=="All Day")
  const [percent, setPercent] = useState("");

  const { serviceName, serviceOpenTime, serviceCloseTime, serviceError } =
    iState;
  const { name, categoryError } = iCategoryState;
  const { start, end, restaurantError } = iRestaurantState;

  const [DeleteModal, setDelete] = useState(false);
  const handleDeleteModalClose = () => setDelete(false);
  const handleDeleteModalShow = (id) => {
    setItemId(id);
    setDelete(true);
  };

  const [DeleteCategoryModal, setDeleteCategory] = useState(false);
  const handleDeleteCategoryModalClose = () => setDeleteCategory(false);
  const handleDeleteCategoryModalShow = (id) => {
    setItemId(id);
    setDeleteCategory(true);
  };

  const [EditTaxModal, setTax] = useState(false);
  const handleEditTaxModalClose = () => setTax(false);
  const handleEditTaxModalShow = (id) => {
    setTax(true);
  };

  const [AddCategoryModal, setAddCategory] = useState(false);

  const handeAddCategoryModalClose = () => {
    setAddCategory(false);
    updateCategoryState({
      name: "",
      categoryError: {},
    });
    setMealType([]);
  };
  const handleAddCategoryModalShow = (type, ele) => {
    if (type == "edit") {
      setItemId(ele?._id);
      setType(type);
      setMealType(ele?.mealType);

      updateCategoryState({
        ...iCategoryState,
        name: ele?.name,
      });
      setAddCategory(true);
    } else {
      setAddCategory(true);
      setType(type);
    }
  };

  const [AddServiceModal, setAddService] = useState(false);

  const handeAddServiceModalClose = () => {
    setAddService(false);
    updateState({
      serviceName: "",
      serviceCloseTime: "",
      serviceOpenTime: "",
      serviceError: {},
    });
  };

  const handleAddServiceModalShow = (type, ele) => {
    if (type == "edit") {
      setItemId(ele?._id);
      setType(type);

      updateState({
        ...iState,
        serviceOpenTime: ele?.openTime,
        serviceCloseTime: ele?.closeTime,
        serviceName: ele?.name,
      });
      setAddService(true);
    } else {
      setAddService(true);
      setType(type);
    }
  };

  const [AddTimeModal, setAddTime] = useState(false);

  const handeAddTimeModalClose = () => {
    setAddTime(false);
    updateRestaurantState({
      start: "",
      end: "",
      restaurantError: {},
    });
  };
  const handleAddTimeModalShow = (type, ele) => {
    if (type == "edit") {
      setItemId(ele?._id);
      setType(type);

      updateRestaurantState({
        ...iRestaurantState,
        start: ele?.start,
        end: ele?.end,
      });
      setAddTime(true);
    } else {
      setAddTime(true);
      setType(type);
    }
  };

  let handleServiceValidation = () => {
    let serviceError = {};
    let formIsValid = true;

    if (!serviceName) {
      serviceError.serviceName = "* Please select service name";
      formIsValid = false;
    }

    if (serviceName !== "All Day") {
      if (!serviceOpenTime) {
        serviceError.serviceOpenTime = "* Please enter starting time";
        formIsValid = false;
      }
      if (!serviceCloseTime) {
        serviceError.serviceCloseTime = "* Please enter closing time";
        formIsValid = false;
      }
    }
    updateState({ ...iState, serviceError: serviceError });
    return formIsValid;
  };

  let handleRestaurantValidation = () => {
    let restaurantError = {};
    let formIsValid = true;

    if (!start) {
      restaurantError.start = "* Please enter restaurant opening time";
      formIsValid = false;
    }

    if (!end) {
      restaurantError.end = "* Please enter restaurant closing time";
      formIsValid = false;
    }

    updateRestaurantState({
      ...iRestaurantState,
      restaurantError: restaurantError,
    });
    return formIsValid;
  };

  let handleCategoryValidation = () => {
    let categoryError = {};
    let formIsValid = true;

    if (!name) {
      categoryError.name = "* Please enter category name";
      formIsValid = false;
    }
    if (mealType?.length == 0) {
      categoryError.mealType = "* Please select meal type";
      formIsValid = false;
    }

    updateCategoryState({ ...iCategoryState, categoryError: categoryError });
    return formIsValid;
  };


  const handleServiceChange = (e) => {
    const { name, value } = e.target;
  
    if (name === "serviceOpenTime") {
      if (serviceName === "All Day") {
        updateState({ ...iState, [name]: '' });
      } else if (value > serviceList?.openingTime?.start) {
        updateState({ ...iState, [name]: value });
      } else {
        toast.error(
          "Serving time should be between restaurant opening & closing times ",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }
    } else if (name === "serviceCloseTime") {
      if (serviceName === "All Day") {
        updateState({ ...iState, [name]: '' });
      } else if (value >= serviceOpenTime && value <= serviceList?.openingTime?.end) {
        updateState({ ...iState, [name]: value });
      } else {
        toast.error(
          "Serving time should be between restaurant opening & closing times ",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }
    } else {
      updateState({ ...iState, [name]: value });
    }
  };


  const handleCategoryChange = (e) => {
    const { name, value } = e.target;
    updateCategoryState({ ...iCategoryState, [name]: value });
  };

  const handleRestaurantChange = (e) => {
    const { name, value } = e.target;
    let max = serviceList?.servingCategories?.some(
      (item) => item?.closeTime > value
    );
    let min = serviceList?.servingCategories?.some(
      (item) => item?.openTime < value
    );

    if (name == "end") {
      if (max) {
        toast.error("Please update service time first ", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else if (value > start) {
        {
          updateRestaurantState({ ...iRestaurantState, [name]: value });
        }
      }
    } else {
      if (min) {
        toast.error("Please update service time first! ", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        updateRestaurantState({ ...iRestaurantState, [name]: value });
      }
    }
  };

  const handleServiceSubmit = (e) => {
    e.preventDefault();

    let formIsValid = handleServiceValidation();

    setStopper(formIsValid)
    if (formIsValid) {




      let data = {};
      if (serviceName === "All Day") {
        if (serviceList?.openingTime) {
          data = {
            name: serviceName,
            openTime: serviceList.openingTime.start,
            closeTime: serviceList.openingTime.end,
            hotelId:id,
          };
        } else {
          console.error("Missing serviceList.openingTime for All Day category");
          return; 
        }
      } else {
        data = {
          name: serviceName,
          openTime: serviceOpenTime,
          closeTime: serviceCloseTime,
          hotelId:id,
      };
    }
      dispatch(AddServiceCategoryAction(data)).then((res) => {

        setStopper(false)
        if (res.status === 200) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setAddService(false);
          dispatch(ServiceCategoryListAction({hotelId:id}));

          updateState({
            serviceName: "",
            serviceCloseTime: "",
            serviceOpenTime: "",
            serviceError: {},
          });
        } else {
          toast.error(res.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {

        console.error("Error adding service category:", error);
        toast.error("An error occurred. Please try again later.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setStopper(false); 
      });
    }
  };

  const handleServiceUpdate = (e) => {
    e.preventDefault();

    let formIsValid = handleServiceValidation();
    setStopper(formIsValid)
    if (formIsValid) {
      var data = {
        _id: itemId,
        name: serviceName,
        openTime: serviceOpenTime,
        closeTime: serviceCloseTime,
        hotelId:id
      };
      dispatch(EditServiceCategoryAction(data)).then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          setStopper(false)
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setAddService(false);
          dispatch(ServiceCategoryListAction({hotelId:id}));

          updateState({
            serviceName: "",
            serviceCloseTime: "",
            serviceOpenTime: "",
            serviceError: {},
          });
        } else {

          setStopper(false)
          toast.error(res.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };

  const handleCategorySubmit = (e) => {
    e.preventDefault();

    let formIsValid = handleCategoryValidation();
    setStopper(formIsValid)
    if (formIsValid) {
      var data = {
        name: name.charAt(0).toUpperCase() + name.slice(1),
        mealType: mealType,
        hotelId:id,
      };
      dispatch(AddFoodCategoryAction(data)).then((res) => {
        if (res.status === 200) {
          setStopper(false)
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setAddCategory(false);
          dispatch(ServiceCategoryListAction({hotelId:id}));

          updateCategoryState({
            name: "",
            categoryError: {},
          });
          setMealType([]);
        } else {
          setStopper(false)
          toast.error(res.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };
  const handleCategoryUpdate = (e) => {
    e.preventDefault();

    let formIsValid = handleCategoryValidation();
    setStopper(formIsValid)
    if (formIsValid) {
      var data = {
        _id: itemId,
        name: name.charAt(0).toUpperCase() + name.slice(1),
        mealType: mealType,
        hotelId:id
      };
      dispatch(EditFoodCategoryAction(data)).then((res) => {
        if (res.status === 200) {
          setStopper(false)
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setAddCategory(false);
          dispatch(ServiceCategoryListAction({hotelId:id}));

          updateCategoryState({
            name: "",
            categoryError: {},
          });
          setMealType([]);
        } else {
          setStopper(false)
          toast.error(res.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };

  useEffect(() => {
    dispatch(ServiceCategoryListAction({hotelId:id}));
  }, []);

  useEffect(() => {
    
  const hasCategories = options?.length > 0;
    setShowAddCategoryButton(hasCategories);
  }, [options]);


  const handleDeletService = () => {
    dispatch(RemoveServiceCategoryAction({ _id: itemId, hotelId:id })).then((res) => {
      if (res.data.success === true) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });

        dispatch(ServiceCategoryListAction({hotelId:id}));
        setDelete(false);
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };



  const handleRestaurantUpdate = (e) => {
    e.preventDefault();

    let formIsValid = handleRestaurantValidation();
    if (formIsValid) {
      var data = {
        start,
        end,
        hotelId:id,
      };
      if(serviceList?.openingTime?.start == "00"){
        dispatch(AddServiceCategoryAction({ name: "All Day",
        openTime: start,
        closeTime: end,
        hotelId:id,
      }))

      
      }
      dispatch(AddRestaurantTimeAction(data)).then((res) => {
        if (res.status === 200) {

          
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });


          

          setAddTime(false);
          dispatch(ServiceCategoryListAction({hotelId:id}));

          updateRestaurantState({
            start: "",
            end: "",
            restaurantError: {},
          });
        }
      }
      );
    }
  };

  const handleDeleteCategory = () => {
    dispatch(RemoveFoodCategoryAction({ _id: itemId,hotelId:id })).then((res) => {
      if (res.data.success === true) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });

        dispatch(ServiceCategoryListAction({hotelId:id}));
        setDeleteCategory(false);
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };



  useEffect(() => {
    if (serviceList === null) {
      dispatch(AddRestaurantTimeAction({start:"00:00",end:"23:59", hotelId:id}))
      window.location.reload();
      // let payload = { start: "00", end: "00", hotelId:id };

      // const response = axios.post(
      //   `${baseUrl}/hotel/foodandbeverage/settiming`,
      //   payload,
      //   {
      //     headers: { authorization: `${token}` },
      //   }
      // );

    }
    setPercent(serviceList?.tax?.percent);
  }, [serviceList]);

  const handleUpdateTax = () => {
    setStopper(true)
    if(percent){
    dispatch(SetTaxAction({ taxType: "GST", percent: percent })).then((res) => {
      if (res.data.success === true) {
        setStopper(false)
        dispatch(ServiceCategoryListAction({hotelId:id}));

        toast.success("GST Updated successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });

        setTax(false);
      } else {
        setStopper(false)

        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  }
  else{
    setStopper(false)
  }
  };

  const handleChangePercentage = (e) => {
    const { value } = e.target;
    const limit = 2;
    if (!/^[0-9]{0,10}$/.test(value) && value < 0) return;
    {
      setPercent(value.replace(/[^\d]/g, "").slice(0, limit));
    }
  };

  const handleCheck = (e) => {
    const { value } = e.target;

    e.target.checked
      ? setMealType((prev) => [...prev, value])
      : setMealType((prev) => prev.filter((item) => item !== value));
  };


  const handleAllday=()=>{
    if(serviceList?.openingTime?.start == "00"){
      var data = {
        name: "All Day",
        openTime: "00:00",
        closeTime: "23:59",
        hotelId:id,
      };
      dispatch(AddServiceCategoryAction(data))

    
    }


    dispatch(AddRestaurantTimeAction({start:"00:00",end:"23:59", hotelId:id})).then((res) => {
      if (res.status === 200) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setAddTime(false);
        dispatch(ServiceCategoryListAction({hotelId:id}));

        updateRestaurantState({
          start: "",
          end: "",
          restaurantError: {},
        });
      }
    })

  }



  

  useEffect(()=>{

    let tempList = [...dayInterval];

    serviceList?.servingCategories?.map((item) => {
      const removeIndex = tempList.findIndex((ele) => ele == item?.name);

      tempList.splice(removeIndex, 1);
    });
    setOptions(tempList);


  },[serviceList])




  return (
    <>
      <Sidenav />
      <Header />
      {loader ? <Loader />:

      <div class="WrapperArea">
        <div class="WrapperBox">
          <div class="HotelLocation HotelLocation2">
            <div class="back-btn">
              <Link to={`/hotel-details/${id}`}>
                <i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back{" "}
              </Link>
            </div>

            <aside>
              <h3>{hotelDetails?.name}</h3>
              <p>
                <span>
                  <i class="fa fa-map-marker"></i>
                </span>{" "}
                {hotelDetails?.address}
              </p>
            </aside>
          </div>
          <div class="NewTitleBox">
            <h4>Restaurant Profile</h4>
          </div>

          <div class="TitleBox">
            <h6>
              Opening time:{" "}
              <b>
                {moment(serviceList?.openingTime?.start, "HH:mm").format(
                  "h:mm A"
                )}{" "}
                to{" "}
                {moment(serviceList?.openingTime?.end, "HH:mm").format(
                  "h:mm A"
                )}
                <span>
                  {serviceList?.openingTime?.start == "00" ? (
                    <a
                      class="Green"
                      onClick={() => {
                          handleAddTimeModalShow("add");
                        
                      }}
                    >
                      Add
                    </a>
                  ) : (
                    <a
                      class="Green"
                      onClick={() => {
                          handleAddTimeModalShow(
                            "edit",
                            serviceList?.openingTime
                          );
                        
                      }}
                    >
                      <i class="fa fa-pencil"></i>
                    </a>
                  )}
                </span>
              </b>
            </h6>

            <h6>
              GST:{" "}
              <b>
                {serviceList?.tax?.percent}%
                <span>
                  <a
                    class="Green"
                    onClick={() => {
                        handleEditTaxModalShow();
                      
                    }}
                  >
                    <i class="fa fa-pencil"></i>
                  </a>
                </span>
              </b>
            </h6>
          </div>

          <div class="Small-Wrapper">
            <div class="AmenitiesArea">
              <div class="TitleBox">
                <h4 class="Title">Serving Time:</h4>
                
                
                
        {showAddCategoryButton && (
          <a
          className="TitleLink"
          onClick={() => {
              handleAddServiceModalShow("add");
            
          }}
        >
          Add Serving Category
        </a>
 
          )}
                
            
              </div>

              <ul>
                {serviceList?.servingCategories?.map((item) => (
                  <li>
                    <div class="AmenitiesBox">
                      <aside>
                        <a
                          class="Green"
                          onClick={() => {
                              handleAddServiceModalShow("edit", item);
                            
                          }}
                        >
                          <i class="fa fa-pencil"></i>
                        </a>

                        <a
                          class="Red"
                          onClick={() => {
                              handleDeleteModalShow(item?._id);
                          }}
                        >
                          <i class="fa fa-trash"></i>
                        </a>
                      </aside>
                      <figcaption>
                        <h6>{item?.name}</h6>
                        <p>
                          <span>
                            {moment(item?.openTime, "HH:mm").format("h:mm A")} -{" "}
                            {moment(item?.closeTime, "HH:mm").format("h:mm A")}
                          </span>
                        </p>
                      </figcaption>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div class="MainHeading">
            <h2>Food Category</h2>
            <a
              onClick={() => {
                  handleAddCategoryModalShow();
                
              }}
              data-target="#FacilityAdd"
              class="Button"
            >
              Add Category{" "}
            </a>
          </div>

          <div class="FacilityBox">
            <ul>
              {serviceList?.categories?.map((item) => (
                <li>
                  <h4>
                    Category Name : <span>{item?.name}</span>{" "}
                  </h4>
                  <aside>
                    <a
                      className="Green"
                      onClick={() => {
                          handleAddCategoryModalShow("edit", item);
                        
                      }}
                    >
                      {" "}
                      <i class="fa fa-pencil"></i>
                    </a>
                    <a
                      className="Red"
                      onClick={() => {
                          handleDeleteCategoryModalShow(item?._id);
                        
                      }}
                    >
                      {" "}
                      <i class="fa fa-trash"></i>
                    </a>
                  </aside>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>}
      <Modal
        show={AddCategoryModal}
        onHide={handeAddCategoryModalClose}
        className="ModalBox "
      >
        <Modal.Body>
          <div class="Category">
            <a onClick={handeAddCategoryModalClose} class="CloseModal">
              ×
            </a>
            {Type == "edit" ? (
              <h3>Update Food Category</h3>
            ) : (
              <h3>Add Food Category</h3>
            )}

            <div class="form-group">
              <label>Name</label>
              <input
                name="name"
                value={name}
                onChange={handleCategoryChange}
                class="form-control"
              />
              {categoryError.name && !name && (
                <p style={{ color: "red" }}>{categoryError.name}</p>
              )}
            </div>
            <div class="form-group">
              <label>MealType</label>
              {mealTypeOptions?.map((item) => (
                <label class="CheckBox">
                  {item}
                  <input
                    type="checkbox"
                    value={item}
                    checked={mealType?.includes(item)}
                    name={item}
                    onChange={handleCheck}
                  />
                  <span class="checkmark"></span>
                </label>
              ))}
              {categoryError.mealType && (
                <p style={{ color: "red" }}>{categoryError.mealType}</p>
              )}
            </div>

            {Type == "edit" ? (
              <button onClick={handleCategoryUpdate} disabled={stopper} class="Button">
                Update
              </button>
            ) : (
              <button class="Button" disabled={stopper} onClick={handleCategorySubmit}>
                Add
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={AddServiceModal}
        onHide={handeAddServiceModalClose}
        className="ModalBox "
      >
        <Modal.Body>
          <div class="Category">
            <a className="CloseModal" onClick={handeAddServiceModalClose}>
              ×
            </a>
            {Type == "edit" ? (
              <h3>Update Serving Timing</h3>
            ) : (
              <h3>Add Serving Timing</h3>
            )}
            <div class="form-group">
              <label>Select Serving category</label>
              <select
                value={serviceName}
                disabled={Type=="edit"?true:false}
                name="serviceName"
                onChange={handleServiceChange}
                class="form-control"
              >
                <option>Select </option>
                {Type=="edit"?
                                dayInterval?.map((item) => (
                                  <option value={item}>{item}</option>
                                ))
                
                :
                options?.map((item) => (
                  <option value={item}>{item}</option>
                ))

                }

              </select>
              {serviceError.serviceName && !serviceName && (
                <p style={{ color: "red" }}>{serviceError.serviceName}</p>
              )}
            </div>

            <div class="form-group">
              <div className="RoomBox">
                <label>From</label>

                <input
                  type="time"
                  class="form-control"
                  value={serviceName === "All Day" ? serviceList?.openingTime?.start : serviceOpenTime}
                  name="serviceOpenTime"
                  onChange={handleServiceChange}
                />
                {serviceError.serviceOpenTime && !serviceOpenTime && (
                  <p style={{ color: "red" }}>{serviceError.serviceOpenTime}</p>
                )}

                <label>To</label>

                <input
                  type="time"
                  min={serviceOpenTime}
                  class="form-control"
                  value={serviceName === "All Day" ? serviceList?.openingTime?.end : serviceCloseTime}
                  name="serviceCloseTime"
                  onChange={handleServiceChange}
                />
                {serviceError.serviceCloseTime && !serviceCloseTime && (
                  <p style={{ color: "red" }}>
                    {serviceError.serviceCloseTime}
                  </p>
                )}
              </div>
            </div>

            {Type == "edit" ? (
              <button
                class="Button"
                disabled={stopper}
                data-dismiss="modal"
                onClick={handleServiceUpdate}
              >
                Update
              </button>
            ) : (
              <button
              disabled={stopper}
                class="Button"
                data-dismiss="modal"
                onClick={handleServiceSubmit}
              >
                Add
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={DeleteModal}
        onHide={handleDeleteModalClose}
        className="ModalBox "
      >
        <Modal.Body>
          <div className="Decline">
            <button className="CloseModal" onClick={handleDeleteModalClose}>
              ×
            </button>
            <h3>Delete</h3>
            <p>Are you sure you want to delete this Serving Time ?</p>
            <h4>
              <a onClick={handleDeleteModalClose} className="Cancel">
                Cancel
              </a>
              <a onClick={handleDeletService}>Delete</a>
            </h4>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={AddTimeModal}
        onHide={handeAddTimeModalClose}
        className="ModalBox "
      >
        <Modal.Body>
          <div class="Category">
            <a className="CloseModal" onClick={handeAddTimeModalClose}>
              ×
            </a>
            {Type == "edit" ? (
              <h3>Update Restaurant Timing</h3>
            ) : (
              <h3>Add Restaurant Timing</h3>
            )}
            {allDayCheck?.length == 0 && (
                              <label class="CheckBox">
                              All Day
                              <input
                                type="checkbox"
                                name="intercom"
                                onChange={handleAllday}
                              />
                              <span class="checkmark"></span>
                            </label>

                )}
            <div class="form-group">
              <div className="RoomBox">
                <label>From</label>

                <input
                  type="time"
                  class="form-control"
                  value={start}
                  name="start"
                  onChange={handleRestaurantChange}
                />
                {restaurantError.start && !start && (
                  <p style={{ color: "red" }}>{restaurantError.start}</p>
                )}

                <label>To</label>

                <input
                  type="time"
                  min={start}
                  class="form-control"
                  value={end}
                  name="end"
                  onChange={handleRestaurantChange}
                />
                {restaurantError.end && !end && (
                  <p style={{ color: "red" }}>{restaurantError.end}</p>
                )}
              </div>
            </div>

            {Type == "edit" ? (
              <button class="Button" disabled={stopper} onClick={handleRestaurantUpdate}>
                Update
              </button>
            ) : (
              <button
              disabled={stopper}
                class="Button"
                data-dismiss="modal"
                onClick={handleRestaurantUpdate}
              >
                Add
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={DeleteCategoryModal}
        onHide={handleDeleteCategoryModalClose}
        className="ModalBox "
      >
        <Modal.Body>
          <div className="Decline">
            <button
              className="CloseModal"
              onClick={handleDeleteCategoryModalClose}
            >
              ×
            </button>
            <h3>Delete</h3>
            <p>Are you sure you want to delete this Category ?</p>
            <h4>
              <a onClick={handleDeleteCategoryModalClose} className="Cancel">
                Cancel
              </a>
              <a onClick={handleDeleteCategory}>Delete</a>
            </h4>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={EditTaxModal}
        onHide={handleEditTaxModalClose}
        className="ModalBox "
      >
        <Modal.Body>
          <div class="Category">
            <a className="CloseModal" onClick={handleEditTaxModalClose}>
              ×
            </a>
            <h3>Update Tax</h3>

            <div class="form-group">
              <div className="RoomBox">
                <label>Percentage</label>

                <input
                  type="text"
                  class="form-control"
                  value={percent}
                  placeholder="Enter GST %"
                  name="start"
                  onChange={handleChangePercentage}
                />
              </div>
            </div>

            <button
              disabled={stopper}
              class="Button"
              onClick={handleUpdateTax}
            >
              Update
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RestaurantProfile;
