import React, { useEffect, useState } from "react";
import Header from "./header";
import Sidenav from "./sidenav";
import { useDispatch, useSelector } from "react-redux";
import { AllGuestListAction, GuestDeleteAction, GuestStatusAction } from "../redux/actions/guestAction";
import NoDataFound from "./noDataFound";
import Pagination from "react-js-pagination";
import moment from "moment";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { RotatingLines } from "react-loader-spinner";
import avatar from "../assets/image/avatar.png";
import {toast} from "react-toastify"
import ImageFetcher from './ImageFetcher'
import Modal from "react-bootstrap/Modal";
import commonCrypto from "../utils";
import { Link } from 'react-router-dom';

const Guests = () => {
  const dispatch = useDispatch();
  const [filterData, setFilterData] = useState({
    search: "",
    page: 1,
  });

  const [roleId, setRoleId] = useState("");


  const { guestList, loader } = useSelector((state) => state.guestReducer);

  useEffect(() => {
    dispatch(AllGuestListAction());
  }, []);

  const handleSearch = (e) => {
    dispatch(AllGuestListAction({ search: e.target.value }));
    setFilterData({ ...filterData, search: e.target.value });
  };

  const handlePageChange = (pageNumber) => {
    setFilterData({ ...filterData, page: pageNumber });

    dispatch(AllGuestListAction({ page: pageNumber ,search:filterData?.search}));
  };


  const [DeleteModal, setDelete] = useState(false);
  const handleDeleteModalClose = () => setDelete(false);
  const handleDeleteModalShow = (id) => {
    setRoleId(id);
    setDelete(true);
  };
  const downloadRowData = (rowData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    let result = rowData?.map((item, i) => {
      return {
        "Sr. No": i + 1,
        Name: item?.co_occupants?.name,
        Gender: item?.co_occupants?.gender,
        "Contact No.": item?.co_occupants?.phone,
        Email: item?.co_occupants?.email,
        "Hotel Name": item?.hotel?.[0]?.name,
        "Hotel Address": item?.hotel?.[0]?.address,
        "Check-in": item?.checkInDate,
        "Check-out":
          item?.extendCheckOut?.rooms?.length > 0
            ? moment(item?.checkOutDate).format("ll")
            : moment(item?.extendCheckOut?.newCheckOutDate).format("ll"),
      };
    });

    // Combine the header row and row data into a single string
    const myJsonString = JSON.stringify(result);

    const ws = XLSX.utils.json_to_sheet(result);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "guests-record.xlsx");
  };

  window.scrollTo(0, 0);




  const handleStatusChange = (item) => {
    let Temp = "";
    if (item?.active) {
      Temp = false;
    } else {
      Temp = true;
    }
    dispatch(GuestStatusAction({ userId: item?._id, active: Temp }))
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
            dispatch(AllGuestListAction());
        
         

          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(res.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };


  
  const handleDelete = () => {
    dispatch(GuestDeleteAction({ userId: roleId })).then((res) => {
      if (res.status >= 200 && res.status <= 300) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });


        setDelete(false);
        dispatch(AllGuestListAction());
      }
    });
  };




  return (
    <>
      <Header />
      <Sidenav />

      <div class="WrapperArea">
        <div class="WrapperBox">
          <div class="Small-Wrapper">
            <div class="TitleBox">
              <h4 class="Title"> Guests Management</h4>
              <div style={{ display: 'flex', alignItems: 'center', gap: '7px'}}>
              <Link to="/guests-deletion-request" className="add2">
      User Deletion Request
    </Link>
              <a
                class="add"
                onClick={() => downloadRowData(guestList?.[0]?.data)}
              >
                Export to Excel
              </a>             
              </div>
            </div>
            <div class="Filter">
              <div class="form-group">
                <label>Search</label>
                <input
                  value={filterData?.search}
                  onChange={handleSearch}
                  type="text"
                  class="form-control"
                  placeholder="Search Guest,Hotel"
                />
              </div>
            </div>
            <br />{" "}
            {loader ? (
              <div style={{ marginTop: "90px" }} className="LoaderBox">
                <RotatingLines
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  color="#4ecb71"
                />
              </div>
            ) : (
              guestList?.[0]?.data?.map((item) => {
                let cryptData = commonCrypto.decrypt(
                  item?.co_occupants?.kyc_details
                    ? item?.co_occupants?.kyc_details?.docData
                    : null
                );

                return (
                  <div class="UserBox">
                    <div class="UserHead">
                      <figure>
                      <ImageFetcher imageUrl={item?.profileImage
                              ? item?.profileImage
                              : avatar}/>
                      </figure>
                      <figcaption>
                        <h3>{item?.name}</h3>
                      </figcaption>
                    </div>

                    <div class="UserBody">
                      <aside>
                        {item?.co_occupants?.type !== "kid" && (
                          <p>
                            <label>Gender{item?.type} </label>{" "}
                            <span>
                              {" "}
                              {item?.gender
                                ?.replace(/_/g, " ")
                                ?.charAt(0)
                                ?.toUpperCase() +
                                item?.gender
                                  ?.replace(/_/g, " ")
                                  ?.slice(1)}
                            </span>
                          </p>
                        )}
                        <p>
                          <label>Date of Birth </label>{" "}
                          <span>
                            {cryptData !== null
                              ? cryptData?.dob
                                ? moment(cryptData?.dob).format("ll")
                                : "NA"
                              : moment(item?.dob).format("ll")}
                          </span>
                        </p>
                        <p>
                          <label>Age </label>{" "}
                          <span>
                            {cryptData !== null
                              ? `${cryptData?.age} Years`
                              : item?.age
                              ? item?.age
                              : "NA"}
                          </span>
                        </p>
                        <p>
                          <label>Email</label>{" "}
                          <span>
                            {" "}
                            {item?.email
                              ? item?.email
                              : "NA"}
                          </span>
                        </p>
                      </aside>

                      <aside>
                        <p>
                          <label>Phone</label>{" "}
                          <span>
                            {item?.phone
                              ? item?.phone == "+91"
                                ? "NA"
                                : item?.phone
                              : "NA"}
                          </span>
                        </p>

                        <p>
                          {item?.type !== "kid" && (
                            <p>
                              <label>Address</label>
                              <span>
                                {cryptData !== null
                                  ? cryptData?.address
                                    ? cryptData?.address
                                    : "NA"
                                  : item?.co_occupants?.address
                                  ? item?.co_occupants?.address
                                  : "NA"}
                              </span>
                            </p>
                          )}
                        </p>
                        {/* <p>
                        <label>Verified with Document</label>{" "}
                        <span> Aadhar</span>
                      </p>
                      <p>
                        <label>Document Number</label>{" "}
                        <span> ************2244</span>
                      </p> */}
                      </aside>
                    </div>


               <div class="UserFooter">
               {item?.guestData?.hotel &&
                      <article>
                        <figure>
                        <ImageFetcher imageUrl={item?.guestData?.hotel?.image}/>
                        </figure>
                        <figcaption>
                          <div class="HotelLocation HotelLocation2">
                            <aside>
                              <h3>{item?.guestData?.hotel?.name}</h3>
                              <p>
                                <span>
                                  <i class="fa fa-map-marker"></i>
                                </span>{" "}
                                {item?.guestData?.hotel?.address}
                              </p>
                            </aside>
                          </div>
                          <p>
                            Check-In to :{" "}
                            {moment(item?.guestData?.checkInDate).format("ll")}
                          </p>
                          <p>
                            Check-Out:{" "}
                            {item?.guestData?.extendCheckOut?.rooms?.length > 0
                              ? moment(item?.checkOutDate).format("ll")
                              : moment(
                                  item?.guestData?.extendCheckOut?.[
                                    item?.guestData?.extendCheckOut?.length - 1
                                  ]?.newCheckOutDate
                                )?.format("ll")}
                          </p>
                        </figcaption>
                      </article>}
                      <div class="Actions">
                        <label class="switch">
                          <input      type="checkbox"
                                onChange={() => handleStatusChange(item)}
                                checked={item?.active
                                } />
                          <span class="slider"></span>
                        </label>
                        <a
                              class="Red"
                              data-toggle="modal"
                              onClick={() => handleDeleteModalShow(item?._id)}
                            >
                              <i class="fa fa-trash"></i>
                            </a>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
            <br />
          </div>

          {loader ? (
            ""
          ) : guestList?.[0]?.data?.length > 0 ? (
            ""
          ) : (
            <NoDataFound />
          )}

          {guestList?.[0]?.metadata?.[0]?.total > 0 ? (
            <div className="Pagination">
              <Pagination
                activePage={filterData?.page}
                itemsCountPerPage={10}
                totalItemsCount={guestList?.[0]?.metadata?.[0]?.total}
                pageRangeDisplayed={5}
                onChange={(e) => handlePageChange(e)}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      <Modal
        show={DeleteModal}
        onHide={handleDeleteModalClose}
        className="ModalBox "
      >
        <Modal.Body>
          <div className="Decline">
            <button className="CloseModal" onClick={handleDeleteModalClose}>
              ×
            </button>
            <h3>Delete</h3>
            <p>Are you sure you want to delete this Guest ?</p>
            <h4>
              <a onClick={handleDeleteModalClose} className="Cancel">
                Cancel
              </a>
              <a onClick={handleDelete}>Delete</a>
            </h4>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};




export default Guests;
