import React from 'react'
import { RotatingLines  } from "react-loader-spinner";


const Loader = () => {
  return (
        <div className="LoaderBox">
                <RotatingLines 
                visible={true}
                height="80"
                width="80"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                color='#4ecb71'

              />  
            </div>
  )
}

export default Loader