import { activitiesConstant } from "../actionTypes";

import axios from "../../axiosInstance"
import { baseUrl } from "../../config/config";
import { isLoggedIn } from "../../utils";




// ADD ACTIVITIES

export const addActivitiesInitiate=() =>({
    type:activitiesConstant.ADD_ACTIVITIES_INITIATE

})

export const addActivitiesSuccess=(data) =>({
    type:activitiesConstant.ADD_ACTIVITIES_SUCCESS,
    payload:data

})

export const addActivitiesFailure=(data) =>({
    type:activitiesConstant.ADD_ACTIVITIES_FAILURE,
    payload : data

})


export const  AddActivitiesAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(addActivitiesInitiate());
        const response = await axios.post(`${baseUrl}/admin/activites/create`, payload,{
            headers: {authorization:`${token}`}})

        if (response.data.success === true) {
          dispatch(addActivitiesSuccess(response.data));
        } else {
          dispatch(addActivitiesFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(addActivitiesFailure(err));
        return err;
      }


}


// EDIT ACTIVITIES

export const editActivitiesInitiate=() =>({
    type:activitiesConstant.UPDATE_ACTIVITIES_INITIATE

})

export const editActivitiesSuccess=(data) =>({
    type:activitiesConstant.UPDATE_ACTIVITIES_SUCCESS,
    payload:data

})

export const editActivitiesFailure=(data) =>({
    type:activitiesConstant.UPDATE_ACTIVITIES_FAILURE,
    payload : data

})


export const  EditActivitiesAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(editActivitiesInitiate());
        const response = await axios.put(`${baseUrl}/admin/activites/update`, payload,{
            headers: {authorization:`${token}`}})

        if (response.data.sucess === true) {
          dispatch(editActivitiesSuccess(response.data));
        } else {
          dispatch(editActivitiesFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(editActivitiesFailure(err));
        return err;
      }


}



// REMOVE ACTIVITIES

export const removeActivitiesInitiate=() =>({
    type:activitiesConstant.REMOVE_ACTIVITIES_INITIATE

})

export const removeActivitiesSuccess=(data) =>({
    type:activitiesConstant.REMOVE_ACTIVITIES_SUCCESS,
    payload:data

})

export const removeActivitiesFailure=(data) =>({
    type:activitiesConstant.REMOVE_ACTIVITIES_FAILURE,
    payload : data

})


export const  RemoveActivitiesAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(removeActivitiesInitiate());
        const response = await axios.delete(`${baseUrl}/admin/activites/delete`, {
          headers: {Authorization:`${token}`},params: payload})

        if (response.data.sucess === true) {
          dispatch(removeActivitiesSuccess(response.data));
        } else {
          dispatch(removeActivitiesFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(removeActivitiesFailure(err));
        return err;
      }


}



//  ACTIVITIES LIST

export const activitiesListInitiate=() =>({
    type:activitiesConstant.ACTIVITIES_LIST_INITIATE

})

export const activitiesListSuccess=(data) =>({
    type:activitiesConstant.ACTIVITIES_LIST_SUCCESS,
    payload:data

})

export const activitiesListFailure=(data) =>({
    type:activitiesConstant.ACTIVITIES_LIST_FAILURE,
    payload : data

})


export const  ActivitiesListAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(activitiesListInitiate());
        const response = await axios.get(`${baseUrl}/admin/activites/get/`, {
          headers: {Authorization:`${token}`},params: payload})

          if (response.data.success === true) {
            dispatch(activitiesListSuccess(response.data));
        } else {
          dispatch(activitiesListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(activitiesListFailure(err));
        return err;
      }


}



