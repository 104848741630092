import React from 'react'
import Header from './header'
import Sidenav from './sidenav'

import DashboardImg  from '../assets/image/dashboard-img.png';
import LocationImg from '../assets/image/location.png'

import { Link } from 'react-router-dom';
 
const ActiveSubscription = () => {
  return (
    <div>
        <Header />
        <Sidenav />
        <div class="WrapperArea">
        <div class="WrapperBox">

            <div class="Small-Wrapper">
                <div class="TitleBox">
                    <h4 class="Title">Active Subscriptions</h4>
                </div>

                <div class="Filter">
                    <div class="form-group">
                        <label></label>
                        <input type="text" class="form-control" placeholder="Search" />
                    </div>
                </div>

                <div class="order-address">
                    <div class="order-address-body">
                        <div class="UserHead">
                            <figure>
                                <img src={DashboardImg} alt="" />
                            </figure>
                            <figcaption>
                                <h3>The Park Inn</h3>
                                <h6>
                                    <span><img src={LocationImg} alt="" /></span> Nehru Palace, New Delhi
                                </h6>
                                <p>Subscription Purchased</p>
                                <h4>6 Months | <span>4000 INR</span></h4>
                            </figcaption>
                        </div>

                        <div class="order-address-footer">
                            <p>Purchased on: <b>22 Jan 2022</b></p>
                            <p>Valid Till: <b>22 Jan 2022</b></p>
                            <Link to="/subscription-history">View Purchase History</Link>
                        </div>
                    </div>
                </div>

                <div class="order-address">
                    <div class="order-address-body">
                        <div class="UserHead">
                            <figure>
                                <img src={DashboardImg} alt="" />
                            </figure>
                            <figcaption>
                                <h3>The Park Inn</h3>
                                <h6>
                                <span><img src={LocationImg} alt="" /></span> Nehru Palace, New Delhi
                                </h6>
                                <p>Subscription Purchased</p>
                                <h4>6 Months | <span>4000 INR</span></h4>
                            </figcaption>
                        </div>

                        <div class="order-address-footer">
                            <p>Purchased on: <b>22 Jan 2022</b></p>
                            <p>Valid Till: <b>22 Jan 2022</b></p>
                            <Link to="/subscription-history">View Purchase History</Link>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
    </div>
  )
}

export default ActiveSubscription