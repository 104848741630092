import { roomConstant } from "../actionTypes";
const initialState = {
    roomList: [],
    minBarList:[],
    categoryList:[],

    loader: false,
    error: {},
  };
  
  export default function roomReducer(
    state = initialState,
    { type, payload }
    
  ) {
    switch (type) {

      case roomConstant.ROOM_LIST_INITIATE:
        return { ...state, loader: true, roomList: [] };
  
      case roomConstant.ROOM_LIST_SUCCESS:
        return { ...state, loader: false, roomList: payload.data };
  
      case roomConstant.ROOM_LIST_FAILURE:
        return { ...state, loader: false, error: payload };

        case roomConstant.ROOM_MIN_BAR_LIST_INITIATE:
          return { ...state, loader: true, minBarList: [] };
    
        case roomConstant.ROOM_MIN_BAR_LIST_SUCCESS:
          return { ...state, loader: false, minBarList: payload.data };
    
          case roomConstant.ROOM_MIN_BAR_LIST_FAILURE:
            return { ...state, loader: false, error: payload };
    
            case roomConstant.ROOM_CATEGORY_LIST_INITIATE:
          return { ...state, loader: true, categoryList: [] };
    
        case roomConstant.ROOM_CATEGORY_LIST_SUCCESS:
          return { ...state, loader: false, categoryList: payload.data };
    
          case roomConstant.ROOM_CATEGORY_LIST_FAILURE:
            return { ...state, loader: false, error: payload };
    

  



  
  
      default:
        return state;
    }
  }
  