import { contentManagementConstant } from "../actionTypes";

import axios from "../../axiosInstance"
import { baseUrl } from "../../config/config";
import { isLoggedIn } from "../../utils";






export const getTermInitiate=() =>({
    type: contentManagementConstant.GET_CONTENT_TERM_CONDITION_INITIATE

})

export const getTermSuccess=(data) =>({
    type: contentManagementConstant.GET_CONTENT_TERM_CONDITION_SUCCESS,
    payload:data

})

export const getTermFailure=(data) =>({
    type : contentManagementConstant.GET_CONTENT_TERM_CONDITION_FAILURE,

})



export const  GetTermsConditionAction=(payload)=>async(dispatch)=>{
    const token = isLoggedIn('admin')

        
    try {
        dispatch(getTermInitiate());
        const response = await axios.post(`${baseUrl}/admin/getTermsAndCond`, payload,{
          headers: {authorization:`${token}`}})
        if (response.status == 200) {
          dispatch(getTermSuccess(response.data));
        } else {
          dispatch(getTermFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(getTermFailure(err));
        return err;
      }
  


}




export const updateTermsConditionInitiate=() =>({
    type: contentManagementConstant.UPDATE_CONTENT_TERM_CONDITION_INITIATE

})

export const updateTermsConditionSuccess=(data) =>({
    type: contentManagementConstant.UPDATE_CONTENT_TERM_CONDITION_SUCCESS,
    payload:data

})

export const updateTermsConditionFailure=(data) =>({
    type : contentManagementConstant.UPDATE_CONTENT_TERM_CONDITION_FAILURE,

})

export const  UpdateTermsConditionAction=(payload)=> async(dispatch)=>{
    
    const token = isLoggedIn('admin')

    try {
        dispatch(updateTermsConditionInitiate());
        const response = await axios.post(`${baseUrl}/admin/updateTermsAndCond`, payload,{
          headers: {authorization:`${token}`}})
        if (response.status == 200) {
          dispatch(updateTermsConditionSuccess(response.data));
        } else {
          dispatch(updateTermsConditionFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(updateTermsConditionFailure(err));
        return err;
      }


}



export const getPrivacyInitiate=() =>({
    type: contentManagementConstant.GET_CONTENT_PRIVACY_POLICY_INITIATE

})

export const getPrivacySuccess=(data) =>({
    type:  contentManagementConstant.GET_CONTENT_PRIVACY_POLICY_SUCCESS,
    payload:data

})

export const getPrivacyFailure=(data) =>({
    type: contentManagementConstant.GET_CONTENT_PRIVACY_POLICY_FAILURE,

})

//*************************************** */





export const  GetPrivacyPolicyAction=(payload)=>async(dispatch)=>{
    

    const token = isLoggedIn('admin')

    try {
        dispatch(getPrivacyInitiate());
        const response = await axios.post(`${baseUrl}/admin/getPrivacyAndPolicy`, payload,{
          headers: {authorization:`${token}`}})
        if (response.status == 200) {
          dispatch(getPrivacySuccess(response.data));
        } else {
          dispatch(getPrivacyFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(getPrivacyFailure(err));
        return err;
      }



}



export const updatePrivacyInitiate=() =>({
    type: contentManagementConstant.UPDATE_CONTENT_EDUCATION_LOAN_INITIATE

})

export const updatePrivacySuccess=(data) =>({
    type:  contentManagementConstant.UPDATE_CONTENT_PRIVACY_POLICY_SUCCESS,
    payload:data

})

export const updatePrivacyFailure=(data) =>({
    type: contentManagementConstant.UPDATE_CONTENT_PRIVACY_POLICY_FAILURE,

})

//*************************************** */


export const  UpdatePrivacyPolicyAction=(payload)=> async(dispatch)=>{
    
    const token = isLoggedIn('admin')


    try {
        dispatch(updatePrivacyInitiate());
        const response = await axios.post(`${baseUrl}/admin/updatePrivacyAndPolicy`, payload,{
          headers: {authorization:`${token}`}})
        if (response.status == 200) {
          dispatch(updatePrivacySuccess(response.data));
        } else {
          dispatch(updatePrivacyFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(updatePrivacyFailure(err));
        return err;
      }



}




export const getAboutUsInitiate=() =>({
    type: contentManagementConstant.GET_CONTENT_ABOUT_US_INITIATE

})

export const getAboutUsSuccess=(data) =>({
    type: contentManagementConstant.GET_CONTENT_ABOUT_US_SUCCESS,
    payload:data

})

export const getAboutUsFailure=(data) =>({
    type: contentManagementConstant.GET_CONTENT_ABOUT_US_FAILURE,

})


export const  GetAboutUsAction=(payload)=>async(dispatch)=>{
    
    const token = isLoggedIn('admin')


    try {
        dispatch(getAboutUsInitiate());
        const response = await axios.post(`${baseUrl}/admin/getAboutUs`, payload,{
          headers: {authorization:`${token}`}})
        if (response.status == 200) {
          dispatch(getAboutUsSuccess(response.data));
        } else {
          dispatch(getAboutUsFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(getAboutUsFailure(err));
        return err;
      }

}


export const updateAboutUsInitiate=() =>({
    type: contentManagementConstant.UPDATE_CONTENT_ABOUT_US_INITIATE

})

export const updateAboutUsSuccess=(data) =>({
    type: contentManagementConstant.UPDATE_CONTENT_ABOUT_US_SUCCESS,
    payload:data

})

export const updateAboutUsFailure=(data) =>({
    type: contentManagementConstant.UPDATE_CONTENT_ABOUT_US_FAILURE,

})


export const  UpdateAboutUsAction=(payload)=>async(dispatch)=>{
    
    const token = isLoggedIn('admin')

    try {
        dispatch(updateAboutUsInitiate());
        const response = await axios.post(`${baseUrl}/admin/updateAboutUs`, payload,{
          headers: {authorization:`${token}`}})
        if (response.status == 200) {
          dispatch(updateAboutUsSuccess(response.data));
        } else {
          dispatch(updateAboutUsFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(updateAboutUsFailure(err));
        return err;
      }

}












