import React, { useEffect } from "react";
import Header from "./header";
import Sidenav from "./sidenav";
import { useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RoleListAction } from "../redux/actions/roleAction";
import { AddRoleAction } from "../redux/actions/roleAction";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import moment from "moment";
import axiosInstance from '../axiosInstance';
import ImageFetcher from './ImageFetcher'

const initialState = {
  name: "",
  email: "",
  phone: "",
  role: "",
  nationality: "Indian",
  gender: "",
  start: "",
  end: "",
  selectedManager: "",
  profileImage: "",
  access: "read",
  password: "",
  isManager: false,
  dob: "",
  errorMsg: {},
};

const RoleAdd = () => {
  const { id } = useParams();
  const [iState, updateState] = useState(initialState);

  const [stopper, setStopper] = useState(false);
  const { roleList } = useSelector((state) => state.roleReducer);

  const [reportingManager, setReportingManager] = useState();

  const [password1Shown, setPassword1Shown] = useState(false);
  const toggle1PasswordVisiblity = () => {
    setPassword1Shown(password1Shown ? false : true);
  };

  const hotelData = JSON.parse(localStorage.getItem("hotel"));

  const {
    name,
    email,
    phone,
    role,
    nationality,
    gender,
    profileImage,
    start,
    end,
    access,
    password,
    isManager,
    errorMsg,
    dob,
  } = iState;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const country = [
    "Afghan",
    "Albanian",
    "Algerian",
    "American",
    "Andorran",
    "Angolan",
    "Antiguans",
    "Argentinean",
    "Armenian",
    "Australian",
    "Austrian",
    "Azerbaijani",
    "Bahamian",
    "Bahraini",
    "Bangladeshi",
    "Barbadian",
    "Barbudans",
    "Batswana",
    "Belarusian",
    "Belgian",
    "Belizean",
    "Beninese",
    "Bhutanese",
    "Bolivian",
    "Bosnian",
    "Brazilian",
    "British",
    "Bruneian",
    "Bulgarian",
    "Burkinabe",
    "Burmese",
    "Burundian",
    "Cambodian",
    "Cameroonian",
    "Canadian",
    "Cape Verdean",
    "Central African",
    "Chadian",
    "Chilean",
    "Chinese",
    "Colombian",
    "Comoran",
    "Congolese",
    "Costa Rican",
    "Croatian",
    "Cuban",
    "Cypriot",
    "Czech",
    "Danish",
    "Djibouti",
    "Dominican",
    "Dutch",
    "East Timorese",
    "Ecuadorean",
    "Egyptian",
    "Emirian",
    "Equatorial Guinean",
    "Eritrean",
    "Estonian",
    "Ethiopian",
    "Fijian",
    "Filipino",
    "Finnish",
    "French",
    "Gabonese",
    "Gambian",
    "Georgian",
    "German",
    "Ghanaian",
    "Greek",
    "Grenadian",
    "Guatemalan",
    "Guinea-Bissauan",
    "Guinean",
    "Guyanese",
    "Haitian",
    "Herzegovinian",
    "Honduran",
    "Hungarian",
    "I-Kiribati",
    "Icelander",
    "Indian",
    "Indonesian",
    "Iranian",
    "Iraqi",
    "Irish",
    "Israeli",
    "Italian",
    "Ivorian",
    "Jamaican",
    "Japanese",
    "Jordanian",
    "Kazakhstani",
    "Kenyan",
    "Kittian and Nevisian",
    "Kuwaiti",
    "Kyrgyz",
    "Laotian",
    "Latvian",
    "Lebanese",
    "Liberian",
    "Libyan",
    "Liechtensteiner",
    "Lithuanian",
    "Luxembourger",
    "Macedonian",
    "Malagasy",
    "Malawian",
    "Malaysian",
    "Maldivian",
    "Malian",
    "Maltese",
    "Marshallese",
    "Mauritanian",
    "Mauritian",
    "Mexican",
    "Micronesian",
    "Moldovan",
    "Monacan",
    "Mongolian",
    "Moroccan",
    "Mosotho",
    "Motswana",
    "Mozambican",
    "Namibian",
    "Nauruan",
    "Nepalese",
    "New Zealander",
    "Ni-Vanuatu",
    "Nicaraguan",
    "Nigerian",
    "Nigerien",
    "North Korean",
    "Northern Irish",
    "Norwegian",
    "Omani",
    "Pakistani",
    "Palauan",
    "Panamanian",
    "Papua New Guinean",
    "Paraguayan",
    "Peruvian",
    "Polish",
    "Portuguese",
    "Qatari",
    "Romanian",
    "Russian",
    "Rwandan",
    "Saint Lucian",
    "Salvadoran",
    "Samoan",
    "San Marinese",
    "Sao Tomean",
    "Saudi",
    "Scottish",
    "Senegalese",
    "Serbian",
    "Seychellois",
    "Sierra Leonean",
    "Singaporean",
    "Slovakian",
    "Slovenian",
    "Solomon Islander",
    "Somali",
    "South African",
    "South Korean",
    "Spanish",
    "Sri Lankan",
    "Sudanese",
    "Surinamer",
    "Swazi",
    "Swedish",
    "Swiss",
    "Syrian",
    "Taiwanese",
    "Tajik",
    "Tanzanian",
    "Thai",
    "Togolese",
    "Tongan",
    "Trinidadian or Tobagonian",
    "Tunisian",
    "Turkish",
    "Tuvaluan",
    "Ugandan",
    "Ukrainian",
    "Uruguayan",
    "Uzbekistani",
    "Venezuelan",
    "Vietnamese",
    "Welsh",
    "Yemenite",
    "Zambian",
    "Zimbabwean",
  ];
  let handleValidation = () => {
    let errorMsg = {};
    let formIsValid = true;
    if (!email) {
      errorMsg.email = "* Please enter email.";
      formIsValid = false;
    } else if (!/^.+?@.+?\..+$/.test(email)) {
      errorMsg.emailValid = "* Email format is not valid";
      formIsValid = false;
    }

    if (!name) {
      errorMsg.name = "* Please enter name";
      formIsValid = false;
    }
    if (!role) {
      errorMsg.role = "* Please select name";
      formIsValid = false;
    }
    if (!nationality) {
      errorMsg.nationality = "* Please enter nationality";
      formIsValid = false;
    }
    if (!gender) {
      errorMsg.gender = "* Please select gender";
      formIsValid = false;
    }

    if (!start) {
      errorMsg.start = "* Please enter start time";
      formIsValid = false;
    }

    if (!end) {
      errorMsg.end = "* Please enter end time";
      formIsValid = false;
    }
    if (!profileImage) {
      errorMsg.profileImage = "* Please upload profile image";
      formIsValid = false;
    }

    if (!access) {
      errorMsg.access = "* Please select access";
      formIsValid = false;
    }

    if (isManager) {
      {
        if (!password) {
          errorMsg.password = "* Please enter password";
          formIsValid = false;
        } else if (
          !/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/.test(
            password
          )
        ) {
          formIsValid = false;
          errorMsg.passwordValid =
            "* Ensure that password contains both upper , lowercase includes special characters @ # * a. and numbers";
        }
      }
    }

    if (!dob) {
      errorMsg.dob = "* Please select DOB";
      formIsValid = false;
    }

    if (!phone) {
      errorMsg.phone = "* Please enter phone number.";
      formIsValid = false;
    } else if (phone.length < 12) {
      errorMsg.phoneValid = "* Please enter valid phone number.";
      formIsValid = false;
    }

    updateState({
      ...iState,
      errorMsg: errorMsg,
    });
    return formIsValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name == "reportingManager") {
      setReportingManager(value);
    }
    updateState({
      ...iState,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formIsValid = handleValidation();
    setStopper(formIsValid);

    if (formIsValid) {
      let data = {
        hotelId: id,
        name,
        email,
        phone: `+${phone}`,
        role,
        nationality,
        password,
        gender,
        profileImage,
        duty_hours: { start: start, end: end },
        reportingManager,
        access,
        isManager,
        hotelId: id,
        dob,
      };
      dispatch(AddRoleAction(data))
        .then((res) => {
          if (res.status >= 200 && res.status <= 300) {
            setStopper(false);
            navigate(`/roles-management/${id}`);
            toast.success(res.data.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            setStopper(false);

            toast.error(res.response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          alert(err);
        });
    }
  };

  const handleCheck = (e) => {
    if (e.target.value == "manager") {
      if (role == "security") {
        updateState({ ...iState, access: "read", isManager: true });
      } else {
        updateState({ ...iState, access: "readAndWrite", isManager: true });
      }
    } else {
      updateState({ ...iState, access: "read", isManager: false });
    }
  };

  window.Buffer = window.Buffer || require("buffer").Buffer;

  const handleUpload = (e) => {
    const file = e.target.files[0];

    if (file.type == "application/pdf") {
      toast.error("Only jpeg, jpg and png are allowed ", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    if (file) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        //Initiate the JavaScript Image object.
        var image = new Image();

        //Set the Base64 string return from FileReader as source.
        image.src = e.target.result;

        //Validate the File Height and Width.
        image.onload = function () {
          var height = this.height;
          var width = this.width;
          if (
            file.type === "image/jpeg" ||
            file.type === "image/jpg" ||
            file.type === "image/png"
          ) {
            const formData = new FormData();
            formData.append('file', file);
  
            let accessToken = JSON.parse(localStorage.getItem("hotelDetails")).jwtToken;
              axiosInstance.post('/upload?type=hotel', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  'Authorization': 'Authorization'.concat(" ",accessToken),
                }
              })
            .then(response => {
              console.log(response);
            
              if (response.status !== 200) {
                throw new Error(`Server error: ${response.statusText}`);
              }
            
              return response;
            })
            .then(data => {
              if (data.error) {
                toast.error(data.error, { position: toast.POSITION.TOP_RIGHT });
              } else {
                const tempData = data.data.url;
                updateState((prev) => ({ ...prev, profileImage: tempData }));
              toast.success("Upload successfully", { position: toast.POSITION.TOP_RIGHT });
              }
            })
            .catch(error => {
              console.error(error);
              toast.error("Upload failed: " + error.message, { position: toast.POSITION.TOP_RIGHT });
            });
            } else {
            toast.error("Only jpeg, jpg and png are allowed ", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        };
      };
    }
  };

  return (
    <>
      <Header />
      <Sidenav />
      <div class="WrapperArea">
        <div class="WrapperBox">
          <div class="Small-Wrapper">
            <div class="NewTitleBox">
              <div className="arrow-left">
                <Link to={`/roles-management/${id}`}>
                  <i class="fa fa-arrow-left arrow-back" aria-hidden="true"></i>
                </Link>
                <h4>Add Employee</h4>
              </div>
            </div>

            <div class="CommonForm">
              <form>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Employee Name</label>
                      <input
                        value={name}
                        name="name"
                        onChange={handleChange}
                        type="text"
                        class="form-control"
                      />
                      {errorMsg.name && !name && (
                        <p style={{ color: "red" }}>{errorMsg.name}</p>
                      )}
                    </div>
                  </div>
                  <div class={profileImage ? "col-sm-4" : "col-sm-6"}>
                    <div class="form-group upload-file">
                      <label>Profile Image</label>
                      <input
                        type="file"
                        onClick={(e) => (e.target.value = null)}
                        onChange={(e) => handleUpload(e)}
                        class="form-control"
                      />
                      {errorMsg.profileImage && !profileImage && (
                        <p style={{ color: "red" }}>{errorMsg.profileImage}</p>
                      )}
                    </div>
                  </div>
                  {profileImage && (
                    <div className="col-sm-2">
                      <div className="avtar-img">
                      <ImageFetcher imageUrl={profileImage}/>
                      </div>
                    </div>
                  )}

                  <div class="col-sm-6">
                    <div class="employe-type">
                      <label>Employee Type</label>

                      <div class="emp-type-radio">
                        <div class="form-group">
                          <label class="Radio">
                            Manager
                            <input
                              type="radio"
                              name="ac"
                              checked={isManager}
                              onChange={handleCheck}
                              value="manager"
                            />
                            <span class="checkmark"></span>
                          </label>
                        </div>

                        <div class="form-group">
                          <label class="Radio">
                            Staff
                            <input
                              checked={!isManager}
                              type="radio"
                              name="ac"
                              onChange={handleCheck}
                              value="staff"
                            />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Role</label>
                      <select
                        disabled={hotelData?.role == "admin" ? false : true}
                        value={role}
                        name="role"
                        onChange={handleChange}
                        class="form-control"
                      >
                        <option>Select </option>
                        <option value="security">
                          Security {isManager ? "Manager" : "Staff"}{" "}
                        </option>
                        <option value="frontdesk">
                          Front Desk {isManager ? "Manager" : "Staff"}{" "}
                        </option>
                        <option value="spa">
                          Spa {isManager ? "Manager" : "Staff"}{" "}
                        </option>
                        <option value="foodandbeverage">
                          FNB {isManager ? "Manager" : "Staff"}{" "}
                        </option>
                        <option value="housekeeping">
                          Housekeeping {isManager ? "Manager" : "Staff"}{" "}
                        </option>
                      </select>
                      {errorMsg.role && !role && (
                        <p style={{ color: "red" }}>{errorMsg.role}</p>
                      )}
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Phone Number</label>
                      <PhoneInput
                        country={"in"}
                        value={phone}
                        enableSearch={true}
                        countryCodeEditable={false}
                        onChange={(phone) => updateState({ ...iState, phone })}
                      />

                      {errorMsg.phone && !phone && (
                        <p style={{ color: "red" }}>{errorMsg.phone}</p>
                      )}
                      {errorMsg.phoneValid && phone?.length < 12 && (
                        <p style={{ color: "red" }}>{errorMsg.phoneValid}</p>
                      )}
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Email Address</label>
                      <input
                        value={email}
                        name="email"
                        onChange={handleChange}
                        type="email"
                        class="form-control"
                      />
                      {errorMsg.email && !email && (
                        <p style={{ color: "red" }}>{errorMsg.email}</p>
                      )}

                      {errorMsg.emailValid && !/^.+?@.+?\..+$/.test(email) && (
                        <p style={{ color: "red" }}>{errorMsg.emailValid}</p>
                      )}
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>DOB</label>
                      <input
                        value={dob}
                        name="dob"
                        max={moment(new Date()).format("YYYY-MM-DD")}
                        onChange={handleChange}
                        type="date"
                        class="form-control"
                      />
                      {errorMsg.dob && !dob && (
                        <p style={{ color: "red" }}>{errorMsg.dob}</p>
                      )}
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Nationality</label>
                      <select
                        onChange={handleChange}
                        value={nationality}
                        name="nationality"
                        class="form-control"
                      >
                        <option value="">Select </option>
                        {country?.map((item) => (
                          <option value={item}>{item} </option>
                        ))}
                      </select>

                      {errorMsg.nationality && !nationality && (
                        <p style={{ color: "red" }}>{errorMsg.nationality}</p>
                      )}
                    </div>
                  </div>
                  {isManager && (
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>Password</label>
                        <input
                          type={password1Shown ? "text" : "password"}
                          value={password}
                          name="password"
                          onChange={handleChange}
                          className="form-control"
                          placeholder="Enter  Password"
                        />
                        <span
                          onClick={toggle1PasswordVisiblity}
                          className={password1Shown ? "Icon cross" : "Icon"}
                        >
                          {password1Shown ? (
                            <i className="fa fa-eye"></i>
                          ) : (
                            <i class="fa fa-eye-slash" aria-hidden="true"></i>
                          )}
                        </span>
                        {errorMsg.password && !password && (
                          <p style={{ color: "red" }}>{errorMsg.password}</p>
                        )}
                        {errorMsg.passwordValid &&
                          !/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/.test(
                            password
                          ) && (
                            <p style={{ color: "red" }}>
                              {errorMsg.passwordValid}
                            </p>
                          )}
                      </div>
                    </div>
                  )}
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Gender</label>
                      <select
                        name="gender"
                        onChange={handleChange}
                        class="form-control"
                      >
                        <option value="">Select Gender </option>

                        <option value="male">Male </option>
                        <option value="female">Female </option>
                        <option value="others">Others </option>
                      </select>

                      {errorMsg.gender && !gender && (
                        <p style={{ color: "red" }}>{errorMsg.gender}</p>
                      )}
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Duty Start Time</label>
                      <input
                        value={start}
                        name="start"
                        onChange={handleChange}
                        type="time"
                        class="form-control"
                      />
                      {errorMsg.start && !start && (
                        <p style={{ color: "red" }}>{errorMsg.start}</p>
                      )}
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Duty End Time</label>
                      <input
                        value={end}
                        name="end"
                        onChange={handleChange}
                        type="time"
                        class="form-control"
                      />
                      {errorMsg.end && !end && (
                        <p style={{ color: "red" }}>{errorMsg.end}</p>
                      )}
                    </div>
                  </div>
                  {/* 
                  {roleList?.[0]?.data?.length > 0 && !isManager && (
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>Reporting Manager</label>
                        <select
                          name="reportingManager"
                          onChange={handleChange}
                          class="form-control"
                        >
                          <option value="">Select Reporting Manager </option>
                          {roleList?.[0]?.data
                            ?.filter((ele) => ele?.role == role)?.filter((elmnt) => elmnt?.isManager === true)
                            ?.map((item) => (
                              <option value={item?._id}>{item?.name} </option>
                            ))}
                        </select>

                        {errorMsg.reportingManager && !reportingManager && (
                          <p style={{ color: "red" }}>
                            {errorMsg.reportingManager}
                          </p>
                        )}
                      </div>
                    </div>
                  )} */}
                  {/* <div class="col-sm-2">
                    <div class="form-group">
                      <label class="Radio">
                        Read Access
                        <input
                          type="radio"
                          name="ac"
                          onChange={handleCheck}
                          value="read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group">
                      <label class="Radio">
                        Read / Write Access
                        <input
                          type="radio"
                          name="ac"
                          onChange={handleCheck}
                          value="readAndWrite"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    {errorMsg.access &&  !access &&(
                      <p style={{ color: "red" }}>{errorMsg.access}</p>
                    )}
                  </div> */}
                  <div class="col-sm-12">
                    <div class="form-group">
                      <p>
                        Note: All managers will have read/write access by
                        default and Security | Delivery boys will have app
                        access only.
                      </p>
                    </div>
                  </div>
                </div>

                <button
                  disabled={stopper}
                  onClick={handleSubmit}
                  class="Button"
                >
                  Add
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RoleAdd;
