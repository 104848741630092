import React, { useState } from "react";
import Header from "./header";
import Sidenav from "./sidenav";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AddTrendingAction,
  TrendingListAction,
} from "../redux/actions/trendingAction";
import moment from "moment";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "./loader";
import ImageFetcher from "./ImageFetcher";

const TrendingAdd = () => {
  const dispatch = useDispatch();
  const {id} =useParams()
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState({});
  const [search, setSearch]= useState('')

  const [category, setCategory] = useState("food");
  const [trendingItems, setTrendingItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const { trendingList ,loader} = useSelector((state) => state.trendingReducer);

  useEffect(() => {
    dispatch(TrendingListAction({ category: category, hotelId :id }));
  }, []);

  const todayData = new Date();

  useEffect(() => {

    let tempList = trendingList?.[0]?.items;

    let i = 0;
    for (i = 0; i < tempList?.length; i++) {

      tempList[i] = {
        ...tempList[i],
        ["hotelId"]: id,
        ["startDate"]: "",
        ["endDate"]: "",
        ["selected"]: "",
      };
    }
    setTrendingItems(tempList);
  }, [trendingList]);

  const handleDateChange = (e, i) => {
    let tempList = [...trendingItems];

    const { name, value } = e.target;
    if(name=="endDate" &&tempList[i].startDate> value){
      
      toast.error(
        "End time should be greter than start time ",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );

    }
    else{
      tempList[i] = { ...tempList[i], [name]: value };

      setTrendingItems(tempList);
  

    }

  };

  const handleCheck = (e, item, i) => {
    const { name } = e.target;

    let tempList = [...trendingItems];
    if(tempList[i].endDate==""){
      toast.error(
        "Please enter time for trending ",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );

    }
    else{
      tempList[i] = { ...tempList[i], [name]: e.target.checked };


      setTrendingItems(tempList);
  
      e.target.checked
        ? setSelectedItems((prev) => [...prev, item])
        : setSelectedItems((prev) => prev.filter((ele) => ele._id !== item?._id));
  
    }
  };
  const handleValidate = () => {
    let formIsValid = true;

    let errorMsg = {};

    if (selectedItems.some((item) => item.startDate == "")) {
      formIsValid = false;
      errorMsg.startDate = "* Please select start Date";
    }
    if (selectedItems.some((item) => item.endDate == "")) {
      formIsValid = false;
      errorMsg.endDate = "* Please select end Date";
    }
    if(selectedItems.length <1){
        formIsValid =false
    }

    setErrorMsg(errorMsg);

    return formIsValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formIsValid = handleValidate();

    if (formIsValid) {
      let data = {
        trendingList: selectedItems,
      };

      dispatch(AddTrendingAction(data))
        .then((res) => {
          if (res.data.success === true) {
            navigate(`/hotel-trending/${id}`);
            setSelectedItems([])
            toast.success(res.data.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(res.data.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          alert(err);
        });
    }
  };

  const handleCategory = (category) => {
    dispatch(TrendingListAction({ category: category, hotelId :id }));
    setCategory(category);
    setSelectedItems([])
  };

  const handleSearch=(e)=>{
    dispatch(TrendingListAction({ category: category , search ,hotelId:id}));

  }

  const handleRefresh=(e)=>{
    dispatch(TrendingListAction({ category: category , search:"",hotelId:id}));
    setSearch("")

  }



  return (
    <>
      <Header />
      <Sidenav />


      <div class="WrapperArea">
        <div class="WrapperBox">
        <div className='CommonLinks'>
            <Link to={`/hotel-trending/${id}`}><i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back</Link>
            </div>

          <div class="Small-Wrapper">
          <div className="CommonLinks">
              <div className="NewTitleBox">
              <h4>Add Trending Items</h4>
              </div>
            </div>
            <div class="CommonTabs">
              <div class="TitleBox">
                <ul class="nav nav-tabs">
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      onClick={() => handleCategory("food")}
                      data-toggle="tab"
                      href="#Food"
                    >
                      Food
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      data-toggle="tab"
                      onClick={(e) => handleCategory("spa")}
                      href="#Spa"
                    >
                      Spa
                    </a>
                  </li>
                </ul>

                <div class="Filter">
                  <div class="form-group">
                    <label>&nbsp;</label>
                    <label>Search</label>


                    <input
                      type="text"
                      class="form-control"
                      value={search}
                      onChange={(e)=> setSearch(e.target.value)}
                      placeholder="Search by item"
                    />
                  </div>

                  <div class="form-group">
                    <label>&nbsp;</label>
                    <label>&nbsp;</label>
                    <button onClick={handleSearch} class="Button">Apply</button>
                    <button onClick={handleRefresh} class="Button Cancel">
                      <i class="fa fa-refresh"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {loader? <Loader />:

            <div class="tab-content">
              <div class="tab-pane active" id="Food">
                <div class="TitleBox">
                  <h4 class="Title"></h4>
                </div>
                <div class="MenuList">
                  <ul>
                    {trendingItems?.map((item, i) => (
                      <li>
                        <div class="MenuLeft">
                          <figure>
                          <ImageFetcher imageUrl={item?.image}/>
                          </figure>
                          <figcaption>
                            <h3>{item?.name}</h3>
                            {/* <p>
                              <span>
                                <img src="images/star.svg" alt="" />
                              </span>
                              4.5
                              <a href="#">34 Reviews</a>
                            </p> */}
                            <h4>
                            <span>{item?.servingSize} </span>
                            </h4>
                            <h4>
                              <span>
                                Expected Delivery Time / {item?.deliveryTime}
                              </span>
                            </h4>
                          </figcaption>
                          <div class="Filter">
                          <div class="form-group">
                                <label>Start Time</label>
                                <input
                                  name="startDate"
                                  onChange={(e) => handleDateChange(e, i)}
                                  type="time"
                                  
                                  class="form-control"
                                />

                                {item?.selected && errorMsg.startDate && (
                                  <p style={{ color: "red" }}>
                                    {errorMsg.startDate}
                                  </p>
                                )}
                              </div>
                              <div class="form-group">
                                <label>End Time</label>
                                <input
                                  min={item?.startDate}
                                  name="endDate"
                                  disabled={!item?.startDate}
                                  onChange={(e) => handleDateChange(e, i)}
                                  type="time"
                                  class="form-control"
                                />
                                {item?.selected && errorMsg.endDate && (
                                  <p style={{ color: "red" }}>
                                    {errorMsg.endDate}
                                  </p>
                                )}
                              </div>
                              </div>
                        </div>
                        <div class="MenuRight">
                          <h5>
                            {item?.price} INR <span>+Taxes</span>
                          </h5>
                          <label class="CheckBox">
                            <input
                              name="selected"
                              type="checkbox"
                              checked={item?.selected}
                              onChange={(e) => handleCheck(e, item, i)}
                            />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div class="tab-pane" id="Spa">
                <div class="TitleBox">
                  <h4 class="Title"></h4>
                </div>
                <div class="MenuList">
                  <ul>
                  {trendingItems?.map((item, i) => (
                      <li>
                        <div class="MenuLeft">
                          <figure>
                          <ImageFetcher imageUrl={item?.image}/>
                          </figure>
                          <figcaption>
                            <h3>{item?.name}</h3>
                            {/* <p>
                              <span>
                                <img src="images/star.svg" alt="" />
                              </span>
                              4.5
                              <a href="#">34 Reviews</a>
                            </p> */}
                            <h4>
                            <span>
                          Approx Time:{' '}
                          {item?.duration?.split(':')?.[0] == '00'
                            ? ''
                            : `${item?.duration?.split(':')?.[0]} Hrs`}{' '}
                          {item?.duration?.split(':')?.[1]} Mins
                        </span>
                            </h4>
                          </figcaption>
                          <div class="Filter">
                          <div class="form-group">
                                <label>Start Time</label>
                                <input
                                  name="startDate"
                                  onChange={(e) => handleDateChange(e, i)}
                                  type="time"
                                  
                                  class="form-control"
                                />

                                {item?.selected && errorMsg.startDate && (
                                  <p style={{ color: "red" }}>
                                    {errorMsg.startDate}
                                  </p>
                                )}
                              </div>
                              <div class="form-group">
                                <label>End Time</label>
                                <input
                                  min={item?.startDate}
                                  name="endDate"
                                  disabled={!item?.startDate}
                                  onChange={(e) => handleDateChange(e, i)}
                                  type="time"
                                  class="form-control"
                                />
                                {item?.selected && errorMsg.endDate && (
                                  <p style={{ color: "red" }}>
                                    {errorMsg.endDate}
                                  </p>
                                )}
                              </div>
                          </div>
                        </div>
                        <div class="MenuRight">
                          <h5>
                            {item?.price} INR <span>+Taxes</span>
                          </h5>
                          <label class="CheckBox">
                            <input
                              name="selected"

                              type="checkbox"
                              checked={item?.selected}
                              onChange={(e) => handleCheck(e, item, i)}
                            />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>}
          </div>
          <br></br>

         {trendingItems?.length >0 &&<button type="button" onClick={handleSubmit} class="Button">
            Save List
          </button>}
        </div>
      </div>
    </>
  );
};

export default TrendingAdd;
