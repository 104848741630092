import { guestConstant } from "../actionTypes";
const initialState = {
    invitedGuestList: [],
    availableRooms:[],
    checkinGuestList:[],
    extendCheckinList:[],
    extendApproveCheckinList:[],
    checkoutRequest:[],
    guestData:{},
    billingList:[],
    billingDetails:[],
    guestList:[],
    approveCheckoutList:[],
    checkoutcompleteList:[],
    guestWalletList:[],

    loader: false,
    error: {},
  };
  
  export default function guestReducer(
    state = initialState,
    { type, payload }
    
  ) {
    switch (type) {

      case guestConstant.INVITED_GUEST_INITIATE:
        return { ...state, loader: true, invitedGuestList: [] };
  
      case guestConstant.INVITED_GUEST_SUCCESS:
        return { ...state, loader: false, invitedGuestList: payload.data };
  
      case guestConstant.INVITED_GUEST_FAILURE:
        return { ...state, loader: false, error: payload };



        case guestConstant.AVAILABLE_ROOMS_INITIATE:
          return { ...state, loader: true, availableRooms: [] };
    
        case guestConstant.AVAILABLE_ROOMS_SUCCESS:
          return { ...state, loader: false, availableRooms: payload.data };
    
        case guestConstant.AVAILABLE_ROOMS_FAILURE:
          return { ...state, loader: false, error: payload };
  
  
          case guestConstant.CHECK_IN_USER_LIST_INITIATE:
            return { ...state, loader: true, checkinGuestList: [] };
      
          case guestConstant.CHECK_IN_USER_LIST_SUCCESS:
            return { ...state, loader: false, checkinGuestList: payload.data };
      
          case guestConstant.CHECK_IN_USER_LIST_FAILURE:
            return { ...state, loader: false, error: payload };
    
    

            case guestConstant.GUEST_DATA_INITIATE:
              return { ...state, loader: true, guestData: {} };
        
            case guestConstant.GUEST_DATA_SUCCESS:
              return { ...state, loader: false, guestData: payload.data };
        
            case guestConstant.GUEST_DATA_FAILURE:
              return { ...state, loader: false, error: payload };
  
              case guestConstant.GUEST_EXTEND_CHECKOUT_INITIATE:
              return { ...state, loader: true, extendCheckinList: [] };
        
            case guestConstant.GUEST_EXTEND_CHECKOUT_SUCCESS:
              return { ...state, loader: false, extendCheckinList: payload.data };
        
            case guestConstant.GUEST_EXTEND_CHECKOUT_FAILURE:
              return { ...state, loader: false, error: payload };


              case guestConstant.GUEST_APPROVE_EXTEND_CHECKOUT_INITIATE:
                return { ...state, loader: true, extendApproveCheckinList: [] };
          
              case guestConstant.GUEST_APPROVE_EXTEND_CHECKOUT_SUCCESS:
                return { ...state, loader: false, extendApproveCheckinList: payload.data };
          
              case guestConstant.GUEST_APPROVE_EXTEND_CHECKOUT_FAILURE:
                return { ...state, loader: false, error: payload };


                case guestConstant.GUEST_CHECKOUT_REQUEST_INITIATE:
                  return { ...state, loader: true, checkoutRequest: [] };
            
                case guestConstant.GUEST_CHECKOUT_REQUEST_SUCCESS:
                  return { ...state, loader: false, checkoutRequest: payload.data };
            
                case guestConstant.GUEST_APPROVE_EXTEND_CHECKOUT_FAILURE:
                  return { ...state, loader: false, error: payload };

                  case guestConstant.BILLING_LIST_INITIATE:
                    return { ...state, loader: true, billingList: [] };
              
                  case guestConstant.BILLING_LIST_SUCCESS:
                    return { ...state, loader: false, billingList: payload.data };
              
                  case guestConstant.BILLING_LIST_FAILURE:
                    return { ...state, loader: false, error: payload };

                    
    
                    case guestConstant.BILLING_DETAILS_INITIATE:
                    return { ...state, loader: true, billingDetails: [] };
              
                  case guestConstant.BILLING_DETAILS_SUCCESS:
                    return { ...state, loader: false, billingDetails: payload.data };
              
                  case guestConstant.BILLING_DETAILS_FAILURE:
                    return { ...state, loader: false, error: payload };
    


                    case guestConstant.GUEST_ALL_LIST_INITIATE:
                      return { ...state, loader: true, guestList: [] };
                
                    case guestConstant.GUEST_ALL_LIST_SUCCESS:
                      return { ...state, loader: false, guestList: payload.data };
                
                    case guestConstant.GUEST_ALL_LIST_FAILURE:
                      return { ...state, loader: false, error: payload };



                      case guestConstant.GUEST_CHECKOUT_APPROVED_LIST_INITIATE:
                        return { ...state, loader: true, approveCheckoutList: [] };
                  
                      case guestConstant.GUEST_CHECKOUT_APPROVED_LIST_SUCCESS:
                        return { ...state, loader: false, approveCheckoutList: payload.data };
                  
                      case guestConstant.GUEST_CHECKOUT_APPROVED_LIST_FAILURE:
                        return { ...state, loader: false, error: payload };
    
  
                        case guestConstant.GUEST_CHECKOUT_COMPLETED_LIST_INITIATE:
                        return { ...state, loader: true, checkoutcompleteList: [] };
                  
                      case guestConstant.GUEST_CHECKOUT_COMPLETED_LIST_SUCCESS:
                        return { ...state, loader: false, checkoutcompleteList: payload.data };
                  
                      case guestConstant.GUEST_CHECKOUT_COMPLETED_LIST_FAILURE:
                        return { ...state, loader: false, error: payload };



                        case guestConstant.GUEST_WALLET_LIST_INITIATE:
                          return { ...state, loader: true, guestWalletList: [] };
                    
                        case guestConstant.GUEST_WALLET_LIST_SUCCESS:
                          return { ...state, loader: false, guestWalletList: payload.data };
                    
                        case guestConstant.GUEST_WALLET_LIST_FAILURE:
                          return { ...state, loader: false, error: payload };
    
  
  

  
  

  
      default:
        return state;
    }
  }
  