import React from 'react'
import Header from './header'
import Sidenav from './sidenav'

const GuestsNotifications = () => {
  return (
    <div>
        <Header />
        <Sidenav />
        <div class="WrapperArea">
        <div class="WrapperBox">
            
            <div class="Small-Wrapper">
                <div class="NewTitleBox">
                    <h4>Guest Notifications:</h4> 
                    <a class="Button" data-toggle="modal" data-target="#FaqEdit">Add New Notifications</a>
                </div> 
                
                <div class="Filter">  
                    <div class="form-group">
                        <label>Search</label>
                        <input type="search" class="form-control"  />
                    </div>
                    <div class="form-group">
                        <label>From Date</label>
                        <input type="date" class="form-control" />
                    </div>
                    <div class="form-group">
                        <label>To Date</label>
                        <input type="date" class="form-control" />
                    </div> 
                    <div class="form-group">
                        <label>&nbsp;</label>
                        <button class="Button">Apply</button>
                        <button class="Button Cancel"><i class="fa fa-refresh"></i></button>
                    </div>
                </div>

                <div class="management">

                    <div class="TableList">

                        <table>

                            <thead>

                                <tr>

                                    <th>S.No.</th>

                                    <th>Title of Notifications</th>

                                    <th>Message</th>

                                    <th>Sent On</th>

                                    <th>No. of Users</th>

                                    <th>Action</th>

                                </tr>

                            </thead>

                            <tbody>

                                <tr>

                                    <td>1</td>

                                    <td>Simmi</td>

                                    <td>This is the message recieved</td>

                                    <td>22-Feb-2022</td>

                                    <td>01</td>

                                    <td>
                                        <a data-toggle="modal" data-target="#FaqEdit"><i class="fa fa-pencil" aria-hidden="true"></i></a>
                                        <button>Resend</button>
                                    </td>

                                </tr>
                                <tr>

                                    <td>1</td>

                                    <td>Simmi</td>

                                    <td>This is the message recieved</td>

                                    <td>22-Feb-2022</td>

                                    <td>01</td>

                                    <td><a data-toggle="modal" data-target="#FaqEdit"><i class="fa fa-pencil" aria-hidden="true"></i></a><button>Resend</button></td>

                                </tr>
                                <tr>

                                    <td>1</td>

                                    <td>Simmi</td>

                                    <td>This is the message recieved</td>

                                    <td>22-Feb-2022</td>

                                    <td>01</td>

                                    <td><a data-toggle="modal" data-target="#FaqEdit"><i class="fa fa-pencil" aria-hidden="true"></i></a><button>Resend</button></td>

                                </tr>
                                <tr>

                                    <td>1</td>

                                    <td>Simmi</td>

                                    <td>This is the message recieved</td>

                                    <td>22-Feb-2022</td>

                                    <td>01</td>

                                    <td><a data-toggle="modal" data-target="#FaqEdit"><i class="fa fa-pencil" aria-hidden="true"></i></a><button>Resend</button></td>

                                </tr>
                                <tr>

                                    <td>1</td>

                                    <td>Simmi</td>

                                    <td>This is the message recieved</td>

                                    <td>22-Feb-2022</td>

                                    <td>01</td>

                                    <td><a data-toggle="modal" data-target="#FaqEdit"><i class="fa fa-pencil" aria-hidden="true"></i></a><button>Resend</button></td>

                                </tr>
                                <tr>

                                    <td>1</td>

                                    <td>Simmi</td>

                                    <td>This is the message recieved</td>

                                    <td>22-Feb-2022</td>

                                    <td>01</td>

                                    <td><a data-toggle="modal" data-target="#FaqEdit"><i class="fa fa-pencil" aria-hidden="true"></i></a><button>Resend</button></td>

                                </tr>                                

                            </tbody>

                        </table>

                    </div>

                    <div class="pagination">
                        <a href="#">Next</a>
                        <a href="#">1</a>
                        <a href="#" class="active">2</a>
                        <a href="#">3</a>
                        <a href="#">Prev</a>
                    </div>

                </div>
                 
            </div>            
            
        </div> 
    </div>
    </div>
  )
}

export default GuestsNotifications