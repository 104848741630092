import React, { useEffect, useState } from "react";
import Sidenav from "./sidenav";
import Header from "./header";
import { useDispatch, useSelector } from "react-redux";
import {
  UpdatePasswordAction,
  Cre,
  CreatePinAction,
  SignupBonusAction,
  UpdateBonusAction,
} from "../redux/actions/authAction";
import { toast } from "react-toastify";

const initialState = {
  oldPassword: "",
  password: "",
  confirmPassword: "",
  errorMsg: {},
};

const initialPinState = {
  pin: "",
  confirmPin: "",
  pinError: {},
};

const initialBonusState = {
  bouns: "",
  bounsError: {},
};
const Settings = () => {
  const dispatch = useDispatch();
  const [iState, updateState] = useState(initialState);
  const [pinState, updatePinState] = useState(initialPinState);
  const [bonusState, updateBonusState] = useState(initialBonusState);

  const { bonusData } = useSelector((state) => state.authReducer);

  const { oldPassword, password, confirmPassword, errorMsg } = iState;
  const { pin, confirmPin, pinError } = pinState;
  const { bouns, bounsError } = bonusState;

  const [password0Shown, setPassword0Shown] = useState(false);

  const [password1Shown, setPassword1Shown] = useState(false);
  const [password2Shown, setPassword2Shown] = useState(false);
  const [password3Shown, setPassword3Shown] = useState(false);
  const [password4Shown, setPassword4Shown] = useState(false);

  const toggle0PasswordVisiblity = () => {
    setPassword0Shown(password0Shown ? false : true);
  };

  const toggle1PasswordVisiblity = () => {
    setPassword1Shown(password1Shown ? false : true);
  };

  const toggle2PasswordVisiblity = () => {
    setPassword2Shown(password2Shown ? false : true);
  };

  const toggle3PasswordVisiblity = () => {
    setPassword3Shown(password3Shown ? false : true);
  };
  const toggle4PasswordVisiblity = () => {
    setPassword4Shown(password4Shown ? false : true);
  };

  let handleValidation = () => {
    let errorMsg = {};
    let formIsValid = true;

    if (!oldPassword) {
      errorMsg.oldPassword = "* Please enter Old Password.";
      formIsValid = false;
    }

    if (!password) {
      errorMsg.password = "* Please enter Password.";
      formIsValid = false;
    }

    if (!confirmPassword) {
      errorMsg.confirmPassword = "* Please re- Enter Password.";
      formIsValid = false;
    } else if (password !== confirmPassword) {
      errorMsg.confirmPassword = "* Password Not Match !.";
      formIsValid = false;
    } else if (
      !/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/.test(password)
    ) {
      formIsValid = false;
      errorMsg.passwordValid =
        "* Ensure that password contains both upper , lowercase includes symbol @ # * a. and numbers";
    }

    updateState({
      ...iState,
      errorMsg,
    });
    return formIsValid;
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    updateState({
      ...iState,
      [name]: value,
    });
  };

  let handlePinValidation = () => {
    let errorMsg = {};
    let formIsValid = true;

    if (!pin) {
      errorMsg.pin = "* Please enter Pin.";
      formIsValid = false;
    }

    if (!confirmPin) {
      errorMsg.confirmPin = "* Please enter Confirm Pin.";
      formIsValid = false;
    }

    updatePinState({
      ...pinState,
      pinError: errorMsg,
    });
    return formIsValid;
  };

  const handlePinChange = (e) => {
    const { name, value } = e.target;
    if (name == "pin" || "confirmPin") {
      const limit = 4;

      updatePinState({
        ...pinState,
        [name]: value.replace(/[^\d]/g, "").slice(0, limit),
      });
    } else {
      updatePinState({
        ...pinState,
        [name]: value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let formIsValid = handleValidation();

    if (formIsValid) {
      let adminData = JSON.parse(localStorage.getItem("admin"));

      var data = {
        userId: adminData?._id,
        newPassword: password,
        oldPassword: oldPassword,
      };

      dispatch(UpdatePasswordAction(data))
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            updateState({
              oldPassword: "",
              password: "",
              confirmPassword: "",
              errorMsg: {},
            });
          } else {
            toast.error(res.response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          alert(err);
        });
    }
  };

  const handleSubmitPin = (e) => {
    e.preventDefault();
    let formIsValid = handlePinValidation();
    let adminEmail = JSON.parse(localStorage.getItem("admin"));
    console.log(adminEmail);

    if (formIsValid) {
      var data = {
        email: adminEmail?.email,
        pin,
      };

      dispatch(CreatePinAction(data))
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            updatePinState({ pin: "", confirmPin: "", pinError: "" });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          alert(err);
        });
    }
  };

  let handleBonusValidation = () => {
    let bounsError = {};
    let formIsValid = true;

    if (!bouns) {
      errorMsg.bouns = "* Please enter Bonus.";
      formIsValid = false;
    }

    updateBonusState({
      ...bonusState,
      bounsError: bounsError,
    });
    return formIsValid;
  };

  useEffect(() => {
    dispatch(SignupBonusAction());
  }, []);

  const handleSubmitBonus = (e) => {
    e.preventDefault();
    let formIsValid = handleBonusValidation();

    if (formIsValid) {
      var data = {};

      if (bonusData?.length > 0) {
        data = {
          bonus: bouns,
          _id: bonusData?.[0]?._id,
        };
      } else {
        data = {
          bonus: bouns,
        };
      }

      dispatch(UpdateBonusAction(data))
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            dispatch(SignupBonusAction());

            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          alert(err);
        });
    }
  };

  const handleStatusChange = (status) => {
    let data = {};
    if (status) {
      data = {
        _id: bonusData?.[0]?._id,
        is_active: false,
      };
    } else {
      data = {
        _id: bonusData?.[0]?._id,
        is_active: true,
      };
    }
    dispatch(UpdateBonusAction(data))
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          dispatch(SignupBonusAction());

          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  useEffect(() => {
    if (bonusData) {
      updateBonusState({ ...bonusState, bouns: bonusData?.[0]?.bonus });
    }
  }, [bonusData]);

  return (
    <div>
      <Header />
      <Sidenav />

      <div class="WrapperArea">
        <div class="WrapperBox">
          <div class="TitleBox">
            <h4 class="Title">Settings</h4>
          </div>

          <div className="settings-area">
            <div class="Small-Wrapper Small-Wrapper2">
              <div class="StaticBoxArea">
                <div class="panel-group" id="accordion">
                  <div class="panel">
                    <div
                      class="panel-heading"
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse3"
                    >
                      <h4>
                        {" "}
                        <span>
                          <i class="fa fa-cog"></i>
                        </span>{" "}
                        Change Password{" "}
                      </h4>
                    </div>
                    <div id="collapse3" class="panel-collapse collapse">
                      <div class="PannelOne">
                        <div class="CommonForm">
                          <div class="form-group">
                            <div class="row">
                              <div class="col-sm-3">
                                <label>Enter your old Password</label>
                              </div>
                              <div class="col-sm-9">
                                <input
                                  value={oldPassword}
                                  name="oldPassword"
                                  onChange={handleChange}
                                  type={password0Shown ? "text" : "password"}
                                  className="form-control"
                                  placeholder="Enter your old Password"
                                />
                                <span
                                  onClick={toggle0PasswordVisiblity}
                                  className={
                                    password0Shown ? "Icon cross" : "Icon"
                                  }
                                >
                                  {password0Shown ? (
                                    <i className="fa fa-eye"></i>
                                  ) : (
                                    <i
                                      class="fa fa-eye-slash"
                                      aria-hidden="true"
                                    ></i>
                                  )}
                                </span>

                                {errorMsg.oldPassword && (
                                  <p style={{ color: "red" }}>
                                    {errorMsg.oldPassword}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>

                          <div class="form-group">
                            <div class="row">
                              <div class="col-sm-3">
                                <label>Create New Password</label>
                              </div>
                              <div class="col-sm-9">
                                <input
                                  type={password1Shown ? "text" : "password"}
                                  value={password}
                                  name="password"
                                  onChange={handleChange}
                                  className="form-control"
                                  placeholder="Enter New Password"
                                />
                                <span
                                  onClick={toggle1PasswordVisiblity}
                                  className={
                                    password1Shown ? "Icon cross" : "Icon"
                                  }
                                >
                                  {password1Shown ? (
                                    <i className="fa fa-eye"></i>
                                  ) : (
                                    <i
                                      class="fa fa-eye-slash"
                                      aria-hidden="true"
                                    ></i>
                                  )}
                                </span>
                                {errorMsg.password && (
                                  <p style={{ color: "red" }}>
                                    {errorMsg.password}
                                  </p>
                                )}
                                {errorMsg.passwordValid && (
                                  <p style={{ color: "red" }}>
                                    {errorMsg.passwordValid}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>

                          <div class="form-group">
                            <div class="row">
                              <div class="col-sm-3">
                                <label>Confirm New Password</label>
                              </div>
                              <div class="col-sm-9">
                                <input
                                  value={confirmPassword}
                                  name="confirmPassword"
                                  onChange={handleChange}
                                  type={password2Shown ? "text" : "password"}
                                  className="form-control"
                                  placeholder="Confirm New Password"
                                />
                                <span
                                  onClick={toggle2PasswordVisiblity}
                                  className={
                                    password2Shown ? "Icon cross" : "Icon"
                                  }
                                >
                                  {password2Shown ? (
                                    <i className="fa fa-eye"></i>
                                  ) : (
                                    <i
                                      class="fa fa-eye-slash"
                                      aria-hidden="true"
                                    ></i>
                                  )}
                                </span>

                                {errorMsg.confirmPassword && (
                                  <p style={{ color: "red" }}>
                                    {errorMsg.confirmPassword}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>

                          <div class="form-group">
                            <div class="row">
                              <div class="col-sm-9 offset-sm-3">
                                <a onClick={handleSubmit}>
                                  <button class="Button">
                                    Update Password
                                  </button>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                <div class="panel">
                  <div
                    class="panel-heading"
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#collapse4"
                  >
                    <h4>
                      {" "}
                      <span>
                        <i class="fa fa-cog"></i>
                      </span>{" "}
                      Set-up PIN :{" "}
                    </h4>
                  </div>
                  <div id="collapse4" class="panel-collapse collapse">
                    <div class="PannelOne">
                      <div class="CommonForm">
                        <div class="form-group">
                          <div class="row">
                            <div class="col-sm-3">
                              <label>Enter PIN</label>
                            </div>
                            <div class="col-sm-9">
                            <input
                                value={pin}
                                name="pin"
                                onChange={handlePinChange}
                                type={password3Shown ? "text" : "password"}
                                className="form-control"
                                placeholder="Enter Pin"
                              />
                              <span
                                onClick={toggle3PasswordVisiblity}
                                className={
                                  password3Shown ? "Icon cross" : "Icon"
                                }
                              >
                                {password3Shown ? (
                                  <i className="fa fa-eye"></i>
                                ) : (
                                  <i
                                    class="fa fa-eye-slash"
                                    aria-hidden="true"
                                  ></i>
                                )}
                              </span>

                              {pinError.pin && (
                                <p style={{ color: "red" }}>
                                  {pinError.pin}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>

                        <div class="form-group">
                          <div class="row">
                            <div class="col-sm-3">
                              <label>Confirm PIN</label>
                            </div>
                            <div class="col-sm-9">
                            <input
                                value={confirmPin}
                                name="confirmPin"
                                onChange={handlePinChange}
                                type={password4Shown ? "text" : "password"}
                                className="form-control"
                                placeholder="Confirm Pin"
                              />
                              <span
                                onClick={toggle4PasswordVisiblity}
                                className={
                                  password4Shown ? "Icon cross" : "Icon"
                                }
                              >
                                {password4Shown ? (
                                  <i className="fa fa-eye"></i>
                                ) : (
                                  <i
                                    class="fa fa-eye-slash"
                                    aria-hidden="true"
                                  ></i>
                                )}
                              </span>

                              {pinError.confirmPassword && (
                                <p style={{ color: "red" }}>
                                  {pinError.confirmPassword}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>

                        <div class="form-group">
                          <div class="row">
                            <div class="col-sm-9 offset-sm-3">
                              <a onClick={handleSubmitPin}>
                                <button class="Button">Set PIN</button>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> 
                </div>
              </div>
            </div>
            <div class="WifiBox">
              <aside>
                <h4>Signup Bonus</h4>
                <div class="Actions">
                  <label class="switch">
                    <input
                      type="checkbox"
                      checked={bonusData?.[0]?.is_active}
                      onChange={(e) =>
                        handleStatusChange(bonusData?.[0]?.is_active)
                      }
                    />
                    <span class="slider"></span>
                  </label>
                </div>{" "}
              </aside>
              <article>
                <p>
                  <label>Amount</label>
                  <span>
                    {" "}
                    <input
                      value={bouns}
                      className="form-control"
                      name="bouns"
                      type="text"
                      onChange={(e) => {
                        if (!/^[0-9]{0,10}$/.test(e.target.value)) return;
                        {
                          updateBonusState({
                            ...bonusState,
                            bouns: e.target.value,
                          });
                        }
                      }}
                    />
                  </span>
                </p>

                <a onClick={handleSubmitBonus} class="Button ButtonOtp">
                  Sumit
                </a>
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
