import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DashboardAction, GetSplitpercentageAction, EditSplitpercentageAction } from '../redux/actions/dashboardAction'
import Header from './header'
import Sidenav from './sidenav'
import { AnalyticListAction } from '../redux/actions/financeAction'
import { Link } from 'react-router-dom';
import NoDataFound from "./noDataFound";
import Pagination from "react-js-pagination";
import Loader from "./loader"
import { toast } from "react-toastify";
import ImageFetcher from './ImageFetcher'
import Modal from "react-bootstrap/Modal";

const Dashboard = () => {

    const [filterData, setFilterData] = useState({
        search: "",
        fromDate: "",
        toDate: "",
        timeFrame:"Today",
        page:1,
        status:""
      });
    
      const [editSplitPercentageModal, setEditSplitPercentageModal] = useState(false);
      const [editSplitPercentageType, setEditSplitPercentageType] = useState("")
      const [editSplitPercentage, setEditSplitPercentage] = useState(null);
    
      
    const [activePage, setActivePage] = useState(1);
    const [packageSplitPercentage, setPackageSplitPercentage] = useState(null);
    const [couponSplitPercentage, setCouponSplitPercentage] = useState(null);
    const dispatch = useDispatch()
    const {dashboardData} = useSelector((state)=>state.dashboardReducer);
    const {analyticList,loader} =useSelector((state)=>state.financeReducer)


    useEffect(()=>{

        dispatch(DashboardAction())
        dispatch(AnalyticListAction())


    },[])

    useEffect(() => {
        dispatch(
          GetSplitpercentageAction()
        ).then((res) => {
          if(res.status >= 200 && res.status < 300) {
            setPackageSplitPercentage(res.data.data.packageSplitPercentage);
            setCouponSplitPercentage(res.data.data.couponSplitPercentage);
          }
        }).catch((err) => {
          alert(err);
        })
      },[])
      

    const handleSearch = (e) => {
        dispatch(AnalyticListAction({search:e.target.value}))
        setFilterData((prev) => ({ ...prev, search: e.target.value }));
      };
    

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        filterData.page = pageNumber;
        dispatch(AnalyticListAction({page:pageNumber}))


      };

      const handleEditPercentageModalOpen = (e, type) => {
        e.preventDefault();
        if(type==="package"){
          setEditSplitPercentageType("package");
        }
        else{
          setEditSplitPercentageType("coupon");
        }
        setEditSplitPercentageModal(true);
      }
      const handleEditPercentageModalClose = () => {
        setEditSplitPercentageModal(false);
        setEditSplitPercentage(null);
      }
      const handleEditSplitPercentage = (e) => {
        e.preventDefault();
        if(!editSplitPercentage && editSplitPercentage !== 0){
          toast.error(`Nothing to Update`,
            {position: toast.POSITION.TOP_RIGHT}
          )
        }
        else if(editSplitPercentage < 0 || editSplitPercentage > 100){
          toast.error(`Discount must be between 0 to 100`,
            {position: toast.POSITION.TOP_RIGHT}
          )
        }
        else {
          const payload = {splitPercentage: editSplitPercentage, type: editSplitPercentageType} 
          dispatch(
            EditSplitpercentageAction(payload)
          ).then((res) => {
            if(res.status >= 200 && res.status < 300){
              toast.success(`Split Percentage Updated Successfully`,
                {position: toast.POSITION.TOP_RIGHT}
              )
              dispatch(GetSplitpercentageAction())
              .then((response) => {
                if(response.status >= 200 && response.status < 300) {
                  setPackageSplitPercentage(response.data.data.packageSplitPercentage);
                  setCouponSplitPercentage(response.data.data.couponSplitPercentage);
                }
              })
              handleEditPercentageModalClose();
            }
          }).catch((err) => {
            alert(err);
          })
        }
      }


      const handleFilter = (e) => {
        if(!filterData.fromDate=="" && filterData.toDate=="" ){
            toast.error("To date not be empty", {
                position: toast.POSITION.TOP_RIGHT,
              });
            
        }
        else{
            dispatch(DashboardAction({ fromDate: filterData?.fromDate, toDate: filterData?.toDate, timeFrame:filterData?.timeFrame}))


        }

      };
      const handleRefresh = (e) => {
        setFilterData({...filterData, search: "", fromDate: "",toDate:"", timeFrame:'Today' });
    
        dispatch(DashboardAction())
    };

    
  return (
    <div>
        <Header />
        <Sidenav />
        
         <div class="WrapperArea">
        <div class="WrapperBox">
        <div class="NewTitleBox">
                    <h4> Dashboard</h4> 
                </div>

            <div class="Small-Wrapper">
                
                <div class="Filter">  
                <div class="form-group">
                        <label>From Date</label>
                        <input type="date" value={filterData?.fromDate}  onChange={(e)=>setFilterData({...filterData, fromDate:e.target.value, timeFrame:""})} class="form-control" />
                    </div>
                    <div class="form-group">
                        <label>To Date</label>
                        <input type="date" disabled={!filterData?.fromDate} value={filterData?.toDate} min={filterData?.fromDate } onChange={(e)=> setFilterData({...filterData, toDate:e.target.value ,timeFrame:""})} class="form-control" />
                    </div> 
                    <div class="form-group">
                        <label>Timeframe</label>
                        <select value={filterData?.timeFrame} onChange={(e)=> setFilterData({...filterData,fromDate:"",toDate:"", timeFrame:e.target.value})} class="form-control">
                            <option value="">--Select--</option>
                            <option value="Today">Today</option>
                            <option value="Week">This Week</option>
                            <option value="Month">This Month</option>
                        </select>
                    </div>  
                    <div class="form-group">
                        <label>&nbsp;</label>
                        <button onClick={handleFilter} class="Button">Apply</button>
                        <button onClick={handleRefresh} class="Button Cancel"><i class="fa fa-refresh"></i></button>
                    </div>

                    <div class="SplitPercentageDisplayBoxInDashboard">
                        <h5>
                          Package Split Percentage:{" "}
                          <b>
                          {packageSplitPercentage}%
                          <span>
                              <a
                              class="Green"
                              >
                              <i class="fa fa-pencil" onClick={(e) => handleEditPercentageModalOpen(e,"package")}></i>
                              </a>
                          </span>
                          </b>
                        </h5>
                        <h5>
                          Coupon Split Percentage:{" "}
                          <b>
                          {couponSplitPercentage}%
                          <span>
                              <a
                              class="Green"
                              >
                              <i class="fa fa-pencil" onClick={(e) => handleEditPercentageModalOpen(e, "coupon")}></i>
                              </a>
                          </span>
                          </b>
                        </h5>
                    </div>
                </div>
            </div>
            <br />
            <div class="NewTitleBox">
                <h4> Statics Overview</h4> 
            </div>

            <div class="row">
                <div class="col-sm-3">
                    <div class="Overview">
                        <h2>Total Partner Hotel</h2>
                        <aside>
                            <span><i class="fa fa-users"></i></span>
                            <h3>{dashboardData?.totalHotel}</h3>
                        </aside>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="Overview">
                        <h2>Total Guests Onboard</h2>
                        <aside>
                            <span><i class="fa fa-users"></i></span>
                            <h3>{dashboardData?.totalGuestsOnboard}</h3>
                        </aside>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="Overview">
                        <h2>Total Revenue Generated</h2>
                        <aside>
                            <span><i class="fa fa-users"></i></span>
                            <h3>{dashboardData?.totalRevenue}</h3>
                        </aside>
                    </div>
                </div>  
                <div class="col-sm-3">
                    <div class="Overview">
                        <h2>Active Guests</h2>
                        <aside>
                            <span><i class="fa fa-users"></i></span>
                            <h3>{dashboardData?.totalActiveGuests}</h3>
                        </aside>
                    </div>
                </div>  
                
            </div> 

            <div class="NewTitleBox">
                    <h4>Guests Hotel Wise</h4> 
                </div>

            <div class="Small-Wrapper">
                <div class="Filter">  
                    <div class="form-group">
                        <label></label>
                        <input type="text"onChange={handleSearch}  value={filterData?.search} class="form-control" placeholder="Search by hotel name or Id" />
                    </div> 
                </div>
                {loader? <Loader />:


<ul className='dashboard-guest'>
                {analyticList?.[0]?.data?.map((item, i)=>

                    <li>
                        <div class="GuestsArea">
                            <div class="GuestsLeft">
                                <figure>
                                <ImageFetcher imageUrl={item?.image}/>
                                </figure>
                            </div>
                            
                            <div class="GuestsRight">
                                <aside>
                                    <a href="#">{item?.name}</a>
                                    <button>{item?.hasOwnProperty('guestCount')? (item?.guestCount?.count) :"0"} Guests</button>
                                </aside>
                                <article>
                                    <span><img src="image/location.png" alt="" /></span>
                                    <p>{item?.address}</p>
                                </article>
                                <label><Link to={`/hotel-details/${item?._id}`}>View Details</Link></label>
                            </div>
                        </div>
                    </li>)}

                    
                </ul>}
                {loader ? (
            ""
          ) : analyticList?.[0]?.data ? (
            ""
          ) : (
            <NoDataFound />
          )}


          {analyticList?.[0]?.metadata?.[0]?.total > 0 ? (
            <div className="Pagination">
              <Pagination
                activePage={activePage}
                itemsCountPerPage={10}
                totalItemsCount={analyticList?.[0]?.metadata?.[0]?.total}
                pageRangeDisplayed={5}
                onChange={(e) => handlePageChange(e)}
              />
            </div>
          ) : (
            ""
          )}

                
            </div>
        
        </div>


        
                    <Modal show={editSplitPercentageModal} 
                           onHide={handleEditPercentageModalClose} 
                           style={{padding:"50px"}}
                           class="ModalBox"
                    >
                      <Modal.Body >
                              <h3 className="subscriptionUpdateModalheading">
                                {editSplitPercentageType==="package" ? 
                                "Edit Package Split Percentage" : 
                                "Edit Coupon Split Percentage"} 
                              </h3>
                              
                                <div className="form-group">
                                  <label>
                                    { editSplitPercentageType==="package" ? 
                                        "Package Split Percentage" :
                                        "Coupon Split Percentage"}
                                  </label>
                                    <input
                                        type={"number"}
                                        className="form-control"
                                        placeholder = {editSplitPercentageType==="package" ? packageSplitPercentage : couponSplitPercentage}  
                                        onChange={(e) => setEditSplitPercentage(e.target.valueAsNumber)}                         
                                    /> 
                                </div>
                                <div className="updateSubscriptionButtonContainer">
                                <button className="Button" onClick={(e) => handleEditSplitPercentage(e)}>
                                  Update
                                </button>
                                <button className="Button Cancel" onClick={(e) => handleEditPercentageModalClose(e)}> 
                                  Cancel
                                </button>
                              </div>
                      </Modal.Body>
                    </Modal> 
    </div>
    </div>
  )
}

export default Dashboard