import React from "react";
import PropTypes from "prop-types";

const Modal = ({ isVisible, onClose, onConfirm, type, reason }) => {
  if (!isVisible) {
    return null;
  }

  console.log("Rendering modal of type:", type, "isVisible:", isVisible);

  const modalContent = type === "reason" ? (
    <>
      <h2 style={{ fontSize: '1.25rem', fontWeight: 'bold', marginBottom: '0.5rem' }}>Reason</h2>
      <p>{reason || "No reason provided"}</p>
      <button 
        style={{ 
          marginTop: '1rem', 
          backgroundColor: '#3B82F6', 
          color: 'white', 
          padding: '0.5rem', 
          borderRadius: '0.25rem', 
          border: 'none',
          outline: 'none',
          

        }} 
        onClick={onClose}
      >
        Close
      </button>
    </>
  ) : (
    <>
      <h1 style={{ fontSize: '1.8rem', fontWeight: 'bold', marginBottom: '0.5rem' }}>Do you want to delete this?</h1>
      <div style={{ marginTop: '2rem' }}>
        <button 
          style={{ 
            backgroundColor: '#EF4444', 
            color: 'white', 
            padding: '0.5rem', 
            borderRadius: '0.25rem', 
            marginRight: '0.5rem', 
            border: 'none'
          }} 
          onClick={onConfirm}
        >
          Confirm
        </button>
        <button 
          style={{ 
            backgroundColor: '#D1D5DB', 
            padding: '0.5rem', 
            borderRadius: '0.25rem', 
            border: 'none'
          }} 
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
    </>
  );

  return (
    <div style={{
      position: 'fixed',
      inset: '0',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: '50'
    }}>
      <div style={{
        backgroundColor: 'white',
        padding: '1rem',
        borderRadius: '0.5rem',
        maxWidth: '28rem',
        width: '100%',
        margin: '1rem'
      }}>
        {modalContent}
      </div>
    </div>
  );
};

Modal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  type: PropTypes.oneOf(["delete", "reason"]).isRequired,
  reason: PropTypes.string,
};

export default Modal;