import React, { useState, useEffect } from 'react';
import Header from './header';
import Sidenav from './sidenav';
import HotelDeletionCard from './HotelDeletionCard'
import { useNavigate } from 'react-router-dom';

const HotelDeletionRequest = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [status, setStatus] = useState('');
    const [timeFrame, setTimeFrame] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [filteredhotels, setFilteredhotels] = useState([]);
    const [isSearchPerformed, setIsSearchPerformed] = useState(false);
    const [selectedDateRange, setSelectedDateRange] = useState(null);
    const [isHovered, setIsHovered] = useState(false);
    const [hotels, setHotels] = useState([]);
  
    const navigate = useNavigate();
  
    const handleCancelRequest = () => {
      navigate('/hotel-cancel-request');
    };
  
    const buttonStyle = {
      marginLeft: '0.5rem',
      padding: '1.2rem 1.5rem',
      borderRadius: '0.375rem',
      backgroundColor: isHovered ? '#e4ba4f' : '#D6B156',
      color: 'white',
      cursor: 'pointer',
      transition: 'background-color 0.3s',
      border: 'none',
      fontSize: '1.5rem'
    };
  
    const ArrowLeftIcon = ({ onClick, className }) => (
      <svg 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 448 512" 
        onClick={onClick}
        className={className}
        style={{ cursor: 'pointer', width: '24px', height: '24px', fill: '#165885' }}
      >
        <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/>
      </svg>
    );
  
  
    useEffect(() => {
      const fetchUsers = async () => {
        try {
          const response = await fetch('./hotels.json');
          const data = await response.json();
          setHotels(data);
        } catch (error) {
          console.error('Error fetching hotels:', error);
        }
      };
  
      fetchUsers();
    }, []);

  
    const handleSearch = () => {
      let filtered = hotels;
  
      if (searchTerm) {
        filtered = filtered.filter(hotel =>
          hotel.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          hotel.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
          hotel.phone.includes(searchTerm)
        );
      }
  
      if (status) {
        filtered = filtered.filter(hotel => hotel.status === status);
      }
  
      if (startDate && endDate) {
        filtered = filtered.filter(hotel => {
          const hotelDate = new Date(hotel.date);
          return hotelDate >= new Date(startDate) && hotelDate <= new Date(endDate);
        });
        setSelectedDateRange({ start: startDate, end: endDate });
      } else if (timeFrame) {
        const now = new Date();
        const timeFrameDate = new Date();
        switch (timeFrame) {
          case 'today':
            timeFrameDate.setDate(now.getDate() - 1);
            setSelectedDateRange({ start: timeFrameDate, end: now });
            break;
          case 'week':
            timeFrameDate.setDate(now.getDate() - 7);
            setSelectedDateRange({ start: timeFrameDate, end: now });
            break;
          case 'month':
            timeFrameDate.setMonth(now.getMonth() - 1);
            setSelectedDateRange({ start: timeFrameDate, end: now });
            break;
          default:
            setSelectedDateRange(null);
            break;
        }
        filtered = filtered.filter(hotel => new Date(hotel.date) >= timeFrameDate);
      } else {
        setSelectedDateRange(null);
      }
  
      setFilteredhotels(filtered);
      setIsSearchPerformed(true);
    };
  
    const handleReset = () => {
      setSearchTerm('');
      setStatus('');
      setTimeFrame('');
      setStartDate('');
      setEndDate('');
      setFilteredhotels([]);
      setIsSearchPerformed(false);
      setSelectedDateRange(null);
    };
  
    return (
      <>
        <Header/>
        <Sidenav />
        <div className="WrapperArea">
          <div className="WrapperBox">
            <div className="Small-Wrapper">
              <div className="TitleBox">
                <div className='top'>
              <ArrowLeftIcon onClick={() => navigate(-1)}/>
                <h4 className="Title"> Manage Hotel Deletion</h4>
              </div>
              <button 
          onClick={handleCancelRequest}
          style={buttonStyle}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          Deleted Hotel
        </button>
        </div>
              <div className="Filter">
                <div className="form-group">
                  <label>Search</label>
                  <input
                    type="text"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    value={searchTerm}
                    className="form-control"
                    placeholder="Search Hotel"
                  />
                </div>
                <div className="form-group">
                  <label>Status</label>
                  <select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    className="form-control"
                  >
                    <option value="">--Select--</option>
                    <option value="Pending">Pending</option>
                    <option value="Approved">Approved</option>
                    <option value="Cancelled">Cancelled</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>From Date</label>
                  <input 
                    type="date" 
                    value={startDate}  
                    onChange={(e) => setStartDate(e.target.value)} 
                    className="form-control" 
                  />
                </div>
                <div className="form-group">
                  <label>To Date</label>
                  <input 
                    type="date" 
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    disabled={!startDate}
                    min={startDate}
                    className="form-control"
                  />
                </div> 
                <div className="form-group">
                  <label>Timeframe</label>
                  <select 
                    value={timeFrame}
                    onChange={(e) => setTimeFrame(e.target.value)} 
                    className="form-control"
                  >
                    <option value="">--Select--</option>
                    <option value="today">Today</option>
                    <option value="week">This Week</option>
                    <option value="month">This Month</option>
                  </select>
                </div>
                <div className="form-group" >
                  <label>&nbsp;</label>
                  <button onClick={handleSearch} className="Button">
                    Apply
                  </button>
                  <button onClick={handleReset} className="Button Cancel">
                    <i className="fa fa-refresh"></i>
                  </button>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem',justifyContent: 'flex-end' }}>
      </div>
              </div>
              {hotels.map((hotel, index) => (
    <HotelDeletionCard key={hotel.id || index} hotel={hotel} />
  ))}
            </div>
          </div>
        </div>
      </>
    );
  }
  
  export default HotelDeletionRequest;