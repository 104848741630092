import React, { useState, useEffect, useRef } from "react";
import Header from "./header";
import Sidenav from "./sidenav";
import Multiselect from "multiselect-react-dropdown";

import Modal from "react-bootstrap/Modal";
import {
  GuestNotificationListAction,
  ResendNotificationAction,
  SendNotificationAction,
  UpdateNotificationAction,
} from "../redux/actions/notificationAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import NoDataFound from "./noDataFound";
import Pagination from "react-js-pagination";
import moment from "moment/moment";

import { GuestListAction } from "../redux/actions/dropdownAction";
import Loader from "./loader";

const initialState = {
  title: "",
  content: "",
  errorMsg: {},
};

const NotificationManagement = () => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState([]);

  const [iState, updateState] = useState(initialState);

  const [guestList, setGuestList] = useState([{ name: "All", _id: "All" }]);

  const { noificationList, loader } = useSelector(
    (state) => state.notificationReducer
  );

  const { title, content, errorMsg } = iState;
  const [Type, setType] = useState("add");
  const [tempId, setTempId] = useState("");
  const [filterData, setFilterData] = useState({
    search: "",
    fromDate: "",
    toDate: "",
    page: 1,
  });

  const [stopper, setStopper] = useState(false);
  const [guestId, setGuetId] = useState([]);

  const dropDownRefCategory = useRef();

  const [AddNotificationModal, setAddNotification] = useState(false);
  const handleAddNotificationModalClose = () => setAddNotification(false);
  const handleAddNotificationModalShow = (type, item) => {
    setAddNotification(true);
    setType(type);

    if (type == "edit") {
      setTempId(item?._id);

      setGuetId(item?.userId);

      setSelected(
        item?.userId?.map((ite) => {
          let temp = guestList?.find((ele) => ele?._id == ite);
          return temp;
        })
      );

      updateState({ ...iState, title: item?.title, content: item?.content });
    }
  setAddNotification(true);
  };

  let handleValidation = () => {
    let errorMsg = {};
    let formIsValid = true;

    if (!content) {
      errorMsg.content = "* Please enter content";
      formIsValid = false;
    }

    if (!title) {
      errorMsg.title = "* Please enter title";
      formIsValid = false;
    }

    updateState({ ...iState, errorMsg: errorMsg });
    return formIsValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateState({ ...iState, [name]: value });
  };

  const handleSubmit = (e, saveLater) => {
    e.preventDefault();

    let formIsValid = handleValidation();
    setStopper(formIsValid);

    if (formIsValid) {
      var data = {
        title,
        content,
        userId: guestId.some((item) => item == "All") ? [] : guestId,
        saveLater: saveLater,
      };
      dispatch(SendNotificationAction(data)).then((res) => {
        if (res.status === 200) {
          setStopper(false);
          setAddNotification(false);
          dispatch(GuestNotificationListAction());
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });

          updateState({
            title: "",
            content: "",

            errorMsg: {},
          });
        } else {
          setStopper(false);
          toast.error(res.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };

  const handlePageChange = (pageNumber) => {
    setFilterData({ ...filterData, page: pageNumber });
    dispatch(
      GuestNotificationListAction({
        fromDate: filterData?.fromDate,
        toDate: filterData?.toDate,
        page: pageNumber,
      })
    );
  };

  useEffect(() => {
    dispatch(GuestNotificationListAction({}));
    dispatch(GuestListAction()).then((res) => {
      if (res.status === 200) {
        let temp = [...guestList, ...res.data.data];

        setGuestList(temp);
      } else {
        toast.error(res.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  }, []);

  const handleSearch = (e) => {
    setFilterData({ ...filterData, search: e.target.value });
    dispatch(GuestNotificationListAction({ search: e.target.value }));
  };

  const handleRefresh = () => {
    dispatch(GuestNotificationListAction());
    setFilterData({ search: "", fromDate: "", toDate: "", page: 1 });
  };

  const handleFilter = (e) => {
    dispatch(
      GuestNotificationListAction({
        fromDate: filterData?.fromDate,
        toDate: filterData?.toDate,
      })
    );
  };

  const handleResend = (id) => {
    setStopper(true);
    dispatch(ResendNotificationAction({ _id: id })).then((res) => {
      if (res.status === 200) {
        setStopper(false);
        setAddNotification(false);
        dispatch(GuestNotificationListAction());
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });

        updateState({
          title: "",
          content: "",

          errorMsg: {},
        });
      } else {
        setStopper(false);
        toast.error(res.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    let formIsValid = handleValidation();
    setStopper(formIsValid);
    const hotelData = JSON.parse(localStorage.getItem("hotel"));

    if (formIsValid) {
      var data = {
        _id: tempId,
        title,
        content,
        saveLater: false,
        hotelId: hotelData?.hotelId?.[0]?._id,
      };
      dispatch(UpdateNotificationAction(data)).then((res) => {
        if (res.status === 200) {
          setStopper(false);
          setAddNotification(false);
          dispatch(GuestNotificationListAction());
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });

          updateState({
            title: "",
            content: "",

            errorMsg: {},
          });
        } else {
          setStopper(false);
          toast.error(res.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };

  function onSelectGatekeeper(selectedList, selectedItem) {
    setGuetId([...guestId, selectedItem?._id]);
  }

  function onRemoveGatekeeper(selectedList, removedItem) {
    let tempList = guestList;
    const removeIndex = tempList.findIndex((item) => item === removedItem._id);
    tempList.splice(removeIndex, 1);

    setGuetId(tempList);
  }

  window.scrollTo(0, 0);

  return (
    <>
      <Header />
      <Sidenav />

      <div class="WrapperArea">
        <div class="WrapperBox">
          <div class="Small-Wrapper wrapper-contant">
            <div class="TitleBox">
              <h4 class="Title">Push Notifications Management</h4>
              <a
                class="add"
                onClick={() => handleAddNotificationModalShow("add")}
              >
                Add New Notifications
              </a>
            </div>
            <div class="Filter">
              <div class="form-group">
                <label>Search</label>
                <input
                  type="search"
                  value={filterData?.search}
                  onChange={handleSearch}
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <label>From Date</label>
                <input
                  type="date"
                  value={filterData?.fromDate}
                  onChange={(e) =>
                    setFilterData({ ...filterData, fromDate: e.target.value })
                  }
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <label>To Date</label>
                <input
                  type="date"
                  disabled={!filterData?.fromDate}
                  value={filterData?.toDate}
                  min={filterData?.fromDate}
                  onChange={(e) =>
                    setFilterData({ ...filterData, toDate: e.target.value })
                  }
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <label>&nbsp;</label>
                <button class="Button" onClick={handleFilter}>
                  Apply
                </button>
                <button onClick={handleRefresh} class="Button Cancel">
                  <i class="fa fa-refresh"></i>
                </button>
              </div>
            </div>

            {loader ? (
              <Loader />
            ) : (
              <div class="management">
                <div class="TableList">
                  <table>
                    <thead>
                      <tr>
                        <th>S.No.</th>

                        <th>Title of Notifications</th>

                        <th>Message</th>

                        <th>Sent On</th>

                        <th>Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {noificationList?.[0]?.data?.map((item, i) => (
                        <tr>
                          <td>{i + 1}</td>

                          <td>{item?.title}</td>

                          <td>{item?.content}</td>

                          <td>{moment(item?.updatedAt).format("ll")}</td>

                          <td>
                            <a
                              data-toggle="modal"
                              onClick={() =>
                                handleAddNotificationModalShow("edit", item)
                              }
                            >
                              <i class="fa fa-pencil" aria-hidden="true"></i>
                            </a>
                            <button onClick={(e) => handleResend(item?._id)}>
                              Resend
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {loader ? (
                    ""
                  ) : noificationList?.[0]?.data?.length > 0 ? (
                    ""
                  ) : (
                    <NoDataFound />
                  )}
                </div>

                {noificationList?.[0]?.metadata?.[0]?.total > 0 ? (
                  <div className="Pagination">
                    <Pagination
                      activePage={filterData?.page}
                      itemsCountPerPage={10}
                      totalItemsCount={
                        noificationList?.[0]?.metadata?.[0]?.total
                      }
                      pageRangeDisplayed={5}
                      onChange={(e) => handlePageChange(e)}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        show={AddNotificationModal}
        // onHide={handleAddNotificationModalClose}
        className="ModalBox "
      >
        <Modal.Body>
          <div class="Category">
            <a onClick={handleAddNotificationModalClose} class="CloseModal">
              ×
            </a>
            {Type == "add" ? (
              <h3>Add New Notification</h3>
            ) : (
              <h3>Update Notification</h3>
            )}

            <div class="form-group">
              <label>Notification Title</label>
              <input
                type="text"
                value={title}
                name="title"
                onChange={handleChange}
                class="form-control"
                placeholder="Enter Title of Notification"
              />
              {errorMsg.title && !title && (
                <p style={{ color: "red" }}>{errorMsg.title}</p>
              )}
            </div>

            <div class="form-group">
              <label>Notification Content</label>
              <textarea
                rows="5"
                class="form-control"
                value={content}
                name="content"
                onChange={handleChange}
                placeholder="Enter Notification Content"
              ></textarea>
              {errorMsg.content && !content && (
                <p style={{ color: "red" }}>{errorMsg.content}</p>
              )}
            </div>

            <div class="form-group">
              <label>Select Guest</label>

              <Multiselect
                options={guestList}
                selectedValues={selected}
                displayValue="name"
                onSelect={onSelectGatekeeper}
                ref={dropDownRefCategory}
                onRemove={onRemoveGatekeeper}
                resetSelectedValues={handleRefresh}
              />
              {errorMsg.guest && guestId?.length < 0 && (
                <p style={{ color: "red" }}>{errorMsg.guest}</p>
              )}
            </div>

            <div class="notification-btn">
              {Type == "add" && (
                <button
                  onClick={(e) => handleSubmit(e, false)}
                  class="Button active"
                >
                  Send Notification
                </button>
              )}
              {Type == "add" && (
                <button
                  onClick={(e) => handleSubmit(e, true)}
                  href="pushNotification.html"
                  class="Button"
                >
                  Save for Later
                </button>
              )}
              {Type == "edit" && (
                <button onClick={handleUpdate} class="Button">
                  Save for Later
                </button>
              )}
            </div>
          </div>{" "}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NotificationManagement;
