import React, { useEffect } from "react";
import Header from "./header";
import Sidenav from "./sidenav";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FoodOrderResquestsAction } from "../redux/actions/restaurantAction";
import moment from "moment/moment";
import NoDataFound from "./noDataFound";
import Pagination from "react-js-pagination";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Loader from "./loader";

const SpaOrderRequest = () => {
  const hotelDetails =  JSON.parse(window.localStorage.getItem("hotelDetails"))

    const {id} = useParams()
  const [activePage, setActivePage] = useState(1);
  const [otp, setOtp] = useState("");
  const navigate = useNavigate()

  const [filterData, setFilterData] = useState({
    status: "",
    fromDate: "",
    toDate: "",
    search: "",
    page: 1,
  });

  const [QrModal, setQr] = useState(false);
  const handleQrModalClose = () => setQr(false);
  const handleQrModalShow = (data) => {
    setOtp(data?.otp);
    setQr(true);
  };

  const { orderRequests, loader } = useSelector(
    (state) => state.restaurantReducer
  );

  const dispatch = useDispatch();

  // const { activitiesList } = useSelector((state) => state.activitiesReducer);

  useEffect(() => {
    dispatch(FoodOrderResquestsAction({ serviceType: "spa" , hotelId:id}));
  }, []);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    filterData.page = pageNumber;
    dispatch(
      FoodOrderResquestsAction({ serviceType: "spa", page: filterData?.page , hotelId:id})
    );
  };

  const handleSearch = (e) => {
    dispatch(
      FoodOrderResquestsAction({ search: e.target.value, serviceType: "spa", hotelId:id })
    );
    setFilterData((prev) => ({ ...prev, search: e.target.value }));
  };

  const handleRefresh = (e) => {
    setFilterData({ search: "", fromDate: "", toDate: "", status: "" });

    dispatch(FoodOrderResquestsAction({ serviceType: "spa" , hotelId:id}));
  };

  const handleFilter = (e) => {
    dispatch(
      FoodOrderResquestsAction({
        serviceType: "spa",
        status: filterData?.status,
        search: filterData?.search,
        fromDate: filterData?.fromDate,
        toDate: filterData?.toDate,
        hotelId:id
      })
    );
  };

  const handleAddOrder=()=>{
      navigate(`/spa-order-add/${id}`)

  }

  const handleDetails=(item)=>{
      navigate(`/spa-order-details/${id}`, {state:item})


    
  }


  return (
    <div>
      <Header />
      <Sidenav />

      

      <div class="WrapperArea">
        <div class="WrapperBox">
        <div class="HotelLocation HotelLocation2">
            <div class="back-btn">
            <Link to={`/spa-management/${id}`}>
                <i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back{" "}
              </Link>
            </div>
            
            <aside>
              <h3>{hotelDetails?.name}</h3>
              <p>
                <span>
                  <i class="fa fa-map-marker"></i>
                </span>{" "}
                {hotelDetails?.address}
              </p>
            </aside>
</div>
          <div class="Small-Wrapper">
            {/* <div class="NewTitleBox">
                    <h4>Order Listing</h4>
                    <a class="add" href="restaurant-add-order.html">+ Add Order</a>
                </div> */}

            <div class="NewTitleBox">
              <div className="arrow-left">
                <h4>Order Listing</h4>
              </div>
              <a class="add" onClick={handleAddOrder}>
                + Add Order
              </a>
            </div>

            <div class="Filter">
              <div class="form-group">
                <label>Search</label>
                <input
                  type="text"
                  class="form-control"
                  value={filterData?.search}
                  onChange={handleSearch}
                  placeholder="Search"
                />
              </div>
              <div class="form-group">
                <label>Status</label>
                <select
                  value={filterData?.status}
                  onChange={(e) =>
                    setFilterData({ ...filterData, status: e.target.value })
                  }
                  class="form-control"
                >
                  <option value="">--Select--</option>
                  <option value="inProgress">In-Progress</option>
                  <option value="scheduled">Scheduled</option>
                  <option value="re-scheduled">Re-scheduled</option>
                  <option value="cancelled">Cancelled</option>
                </select>
              </div>
              <div class="form-group">
                <label>From Date</label>
                <input
                  name="fromDate"
                  // min={moment(todayData).format("YYYY-MM-DD")}
                  value={filterData?.fromDate}
                  onChange={(e) =>
                    setFilterData({ ...filterData, fromDate: e.target.value })
                  }
                  type="date"
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <label>To Date</label>
                <input
                  min={filterData?.fromDate}
                  name="toDate"
                  value={filterData?.toDate }

                  disabled={!filterData?.fromDate}
                  onChange={(e) =>
                    setFilterData({ ...filterData, toDate: e.target.value })
                  }
                  type="date"
                  class="form-control"
                />
              </div>

              <div class="form-group">
                <label>&nbsp;</label>
                <button class="Button" onClick={handleFilter}>
                  Apply
                </button>
                <button onClick={handleRefresh} class="Button Cancel">
                  <i class="fa fa-refresh"></i>
                </button>
              </div>
            </div>
            {loader? <Loader />:


<div class="TableList">
<table style={{ width: "130%" }}>
  <thead>
    <tr>
      <th>Order Id</th>
      <th>Date &amp; Time of Order</th>
      <th>Room Number</th>
      <th>Customer Name</th>
      <th>Order Value</th>
      <th>Order Qnty</th>
      <th>Order Created by</th>
      <th>Assigned to</th>

      <th>Scheduled Time</th>
      <th>Status</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    {orderRequests?.[0]?.data?.map((item) => (
      <tr>
        <td>{item?.orderId}</td>
        <td>{moment(item?.updatedAt).format("lll")}</td>
        <td>{item?.room?.fullNumber}</td>
        <td>
          {item?.orderBy ? (
            item?.orderBy?.name
          ) : (
            <a onClick={() => handleQrModalShow(item)}>View QR</a>
          )}
        </td>

        <td>INR {item?.amount?.totalAmount?.toFixed(2)}</td>
        <td>{item?.itemCount}</td>
        <td>
          {" "}
          {item?.createdBy?.charAt(0)?.toUpperCase() +
            item?.createdBy?.slice(1)}
        </td>
        <td>                          {item?.deliverBy ? (
              <>{item?.deliverBy?.name}</>
            ) : (
              "Not Assigned"
            )}{" "}
</td>
        <td>
          {item?.items?.[0]?.person_details?.map((ele) => (
            <>
              {ele?.date}
              <span>{ele?.time}</span>
            </>
          ))}{" "}
        </td>
        <td>
          {" "}
          {item?.status?.charAt(0)?.toUpperCase() +
            item?.status?.slice(1)}
        </td>
        <td>
          <div class="Actions">
            <a
              class="Blue"
              onClick={()=>handleDetails(item)}
            >
              <i class="fa fa-eye"></i>
            </a>
          </div>
        </td>
      </tr>
    ))}
  </tbody>
</table>
{loader ? (
  ""
) : orderRequests?.[0]?.metadata?.[0]?.total > 0 ? (
  ""
) : (
  <NoDataFound />
)}
</div>}
            {orderRequests?.[0]?.metadata?.[0]?.total > 0 ? (
              <div className="Pagination">
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={orderRequests?.[0]?.metadata?.[0]?.total}
                  pageRangeDisplayed={5}
                  onChange={(e) => handlePageChange(e)}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        </div>

      <Modal show={QrModal} onHide={handleQrModalClose} className="ModalBox ">
        <Modal.Body>
          <div class="Category">
            <a className="CloseModal" onClick={handleQrModalClose}>
              ×
            </a>
            <div class="QRScanGuest">
              <h4>
                Please scan customer QR or enter OTP to proceed with check-In
              </h4>
              <figure>
                <img src={require("../assets/image/QRCode.png")} />
              </figure>
              <p>
                <span class="qr-pera">Or</span>
              </p>

              <div class="form-group">
                {/* <label>Enter 4-Digit generated OTP Code</label> */}
                <input
                  value={otp}
                  disabled={true}
                  type="number"
                  class="form-control"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SpaOrderRequest;
