import React from 'react'
import Header from './header'
import Sidenav from './sidenav'
import { Link, useParams } from 'react-router-dom'
import ImageFetcher from './ImageFetcher'
import Email from "../assets/image/email.svg"
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { ChatListAction, CloseChatAction } from '../redux/actions/chatAction'

const SpaMsg = () => {

    const dispatch = useDispatch();
    const{chatList} = useSelector((state)=>state.chatReducer)
    const {id} = useParams()

    useEffect(()=>{
        dispatch(ChatListAction({serviceType:"spa", hotelId:id}))

    },[])




    const handleClose=(rid)=>{
        dispatch(CloseChatAction({chatRoomId:rid, hotelId:id}))
        .then((res) => {
            if (res.status >= 200 && res.status <= 300) {
      
      
                dispatch(ChatListAction({serviceType:"spa", hotelId:id}))
            } 
          });

}

  return (
    <div>
        <Header />
        <Sidenav />
        <div class="WrapperArea">
        <div class="WrapperBox">
            <div class="NewTitleBox">
            <div className='arrow-left'>
              <Link to={`/spa-management/${id}`}><i class="fa fa-arrow-left arrow-back" aria-hidden="true"></i>
              </Link>

                <h4>Messages</h4>
                </div>
                <div class="earning">
                    <Link to={`/spa-chat-history/${id}`} >Chat History</Link>
                </div> 
            </div>
            
            {/* <div class="Filter">  
                <div class="form-group">
                    <label>Search</label>
                </div>
                                     
                <div class="form-group">
                    <label>&nbsp;</label>
                    <button class="Button">Apply</button>
                    <button class="Button Cancel"><i class="fa fa-refresh"></i></button>
                </div>
            </div>
                      */}


                      {chatList?.map((item)=>
                      <div class="Small-Wrapper"> 
                
                      <div class="message">
                          <aside>
                          <figure>
                          <ImageFetcher imageUrl={item?.sender?.[0]?.profileImage}/>
                              </figure>
                              <figcaption>
                <p>{item?.sender?.[0]?.name}  <b>({item?.roomId?.[0]?.fullNumber})</b> </p>
                                  <a onClick={()=>handleClose(item?.chatRoomId)}><b>Close Chat</b></a>
                              </figcaption>
                          </aside>
                          {/* <article>
                              <figure>
                                  <img src={Email} alt="" />
                              </figure>
                              <figcaption>
                                  <Link to="/spa-chat" state={item}>View Chat</Link>
                              </figcaption>
                          </article> */}
                      </div>                
                  </div>
                      )}

            


        </div> 
    </div>
    </div>
  )
}

export default SpaMsg