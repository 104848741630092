import React, { useState } from "react";
import Header from "./header";
import Sidenav from "./sidenav";
import { Link } from "react-router-dom";
import axiosInstance from '../axiosInstance';
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import moment from "moment";
import { useParams} from "react-router-dom";
import ImageFetcher from './ImageFetcher'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const GuestsManual = () => {
  const {id} =useParams()
  const [veriry, setVerify] = useState(false);
  const [errorMsg, setErrorMsg] = useState({});

  const today = moment(new Date());

  function hasDuplicatePhone(data) {
    const seenValues = new Set();

    for (const entry of data) {
      if (seenValues.has(entry.kyc_details.docData.phone)) {
        return true; // Duplicate found
      }
      seenValues.add(entry.kyc_details.docData.phone);
    }

    return false; // No duplicates found
  }

  function hasDuplicateEmail(data) {
    const seenValues = new Set();

    for (const entry of data) {
      if (seenValues.has(entry.kyc_details.docData.email)) {
        return true; // Duplicate found
      }
      seenValues.add(entry.kyc_details.docData.email);
    }

    return false; // No duplicates found
  }

  let validDate = today.subtract(18, "years");
  let validMinDate = moment().subtract(12, "years");

  const [co_occupants, setCo_occupants] = useState([
    {
      guestType: "main",
      // kyc_status: true,
      name: "",
      email: "",
      phone: "",
      type: "adult",
      profileImage: "",
      age: "",
      dob: "",
      baseShow:"",
      address:"",
      gender: "",
      mannualCheckin:true,
      kyc_details: {
        docType: "",
        docData: {
          name: "",
          phone: "",
          age: "",
          dob: "",
          docNumber: "",
          email: "",
          gender: "",
          address: "",
          backDoc: "",
          frontDoc: "",
          profileImage: "",
          baseShow: "",
          guestCategory: "adult",
        },
      },
      gender: "",
    },
  ]);
  const navigate = useNavigate();

  let handleValidation = () => {
    let errorMsg = {};
    let formIsValid = true;
    console.log(formIsValid, "Pre");

    if (co_occupants?.some((item) => item?.kyc_details?.docData?.name == "")) {
      errorMsg.name = "* Please enter name";
      formIsValid = false;
    }

    // if (
    //   co_occupants?.some((item) => item?.kyc_details?.docData?.baseShow == "")
    // ) {
    //   errorMsg.baseShow = "* Please upload profile";
    //   formIsValid = false;
    // }

    if (
      co_occupants?.some((item) => item?.kyc_details?.docData?.gender == "")
    ) {
      errorMsg.gender = "* Please select gender";
      formIsValid = false;
    }

    let isemptyPhone = co_occupants?.some((item) => {
      if (
        item?.kyc_details?.docData?.phone == "" &&
        item?.kyc_details?.docData?.guestCategory == "adult"
      ) {
        return true;
      } else {
        return false;
      }
    });

    let isphoneValid = co_occupants?.some((item) => {
      if (
        item?.kyc_details?.docData?.phone?.length == 13 &&
        item?.kyc_details?.docData?.phone !== ""
      ) {
        return true;
      } else {
        return false;
      }
    });

    if (!isemptyPhone) {
      if (!isphoneValid) {
        errorMsg.phoneValid = "* Please enter valid phone number.";
        formIsValid = false;
      }
      if (hasDuplicatePhone(co_occupants)) {
        errorMsg.phoneUnique = "* Phone number should be uninque.";
        formIsValid = false;
      }
    } else {
      errorMsg.phone = "* Please enter phone.";
      formIsValid = false;
    }

    let emailValidation = !co_occupants?.some((item) => {
      return /^.+?@.+?\..+$/.test(item?.kyc_details?.docData?.email);
    });

    let isemptyEmail = co_occupants?.some((item) => {
      if (
        item?.kyc_details?.docData?.email == "" &&
        item?.kyc_details?.docData?.guestCategory == "adult"
      ) {
        return true;
      } else {
        return false;
      }
    });

    if (!isemptyEmail) {
      if (emailValidation) {
        errorMsg.emailValid = "* Email format is not valid";
        formIsValid = false;
      }
      if (hasDuplicateEmail(co_occupants)) {
        errorMsg.emailUnique = "* Email number should be uninque.";
        formIsValid = false;
      }
    } else {
      errorMsg.email = "* Please enter email.";
      formIsValid = false;
    }

    if (co_occupants?.some((item) => item?.kyc_details?.docData?.dob == "")) {
      errorMsg.dob = "* Please enter date of birth";
      formIsValid = false;
    }

    if (
      co_occupants?.some((item) => item?.kyc_details?.docData?.gender == "")
    ) {
      errorMsg.gender = "* Please select gender";
      formIsValid = false;
    }

    if (
      co_occupants?.some((item) => item?.kyc_details?.docData?.address == "")
    ) {
      errorMsg.address = "* Please enter addresss";
      formIsValid = false;
    }

    // let isemptyDocnumber = co_occupants?.some((item) => {
    //   if (
    //     item?.kyc_details?.docData?.docNumber == "" &&
    //     item?.kyc_details?.docData?.guestCategory !== "kid"
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // });

    // if (isemptyDocnumber) {
    //   if (
    //     co_occupants?.some(
    //       (item) => item?.kyc_details?.docData?.docNumber == ""
    //     )
    //   ) {
    //     errorMsg.docNumber = "* Please enter document number";
    //     formIsValid = false;
    //   } else if (
    //     co_occupants
    //       .filter((ele) => ele?.kyc_details?.docType == "Aadhaar")
    //       .some((item) => item?.kyc_details?.docData?.docNumber.length != 12)
    //   ) {
    //     errorMsg.docNumberValid = "* Please enter valid document number";
    //     formIsValid = false;
    //   }
    // }

    // let isemptyDocType = co_occupants?.some((item) => {
    //   if (
    //     item?.kyc_details?.docData?.docType == "" &&
    //     item?.kyc_details?.docData?.guestCategory !== "kid"
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // });

    let isDoctypeAadhar = co_occupants?.some((item) => {
      if (
        item?.kyc_details?.docType == "Aadhaar" &&
        item?.kyc_details?.docData?.guestCategory !== "kid"
      ) {
        return true;
      } else {
        return false;
      }
    });

    // if (isemptyDocType) {
    //   if (co_occupants?.some((item) => item?.kyc_details?.docType == "")) {
    //     errorMsg.docType = "* Please select document type";
    //     formIsValid = false;
    //   }
    // }

    // let isemptyDocBack = co_occupants?.some((item) => {
    //   if (
    //     item?.kyc_details?.docData?.backDoc == "" &&
    //     item?.kyc_details?.docData?.guestCategory !== "kid" &&
    //     item?.kyc_details?.docType !== "Aadhaar"
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // });

    // if (isemptyDocBack) {
    //   if (
    //     co_occupants?.some((item) => item?.kyc_details?.docData?.backDoc == "")
    //   ) {
    //     errorMsg.backDoc = "* Please upload back image  ";
    //     formIsValid = false;
    //   }
    // }

    // let isemptyDocFront = co_occupants?.some((item) => {
    //   if (
    //     item?.kyc_details?.docData?.frontDoc == "" &&
    //     item?.kyc_details?.docData?.guestCategory !== "kid" &&
    //     item?.kyc_details?.docType !== "Aadhaar"
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // });

    // if (isemptyDocFront) {
    //   if (
    //     co_occupants?.some((item) => item?.kyc_details?.docData?.frontDoc == "")
    //   ) {
    //     errorMsg.frontDoc = "* Please upload front image ";
    //     formIsValid = false;
    //   }
    // }

    // if (co_occupants?.some((item) => item?.relation == "")) {
    //   errorMsg.relation = "* Please select  relation ";
    //   formIsValid = false;
    // }

    setErrorMsg(errorMsg);

    // updateState({ ...iState, errorMsg: errorMsg });
    return formIsValid;
  };

  window.Buffer = window.Buffer || require("buffer").Buffer;

  const handleUpload = (e, index, name) => {
    const file = e.target.files[0];

    if (file.type == "application/pdf") {
      toast.error("Only jpeg, jpg and png are allowed ", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    if (file) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        //Initiate the JavaScript Image object.
        var image = new Image();

        //Set the Base64 string return from FileReader as source.
        image.src = e.target.result;

        //Validate the File Height and Width.
        image.onload = function () {
          var height = this.height;
          var width = this.width;
          if (
            file.type === "image/jpeg" ||
            file.type === "image/jpg" ||
            file.type === "image/png"
          ) {
            const formData = new FormData();
            formData.append('file', file);
            let accessToken = JSON.parse(localStorage.getItem("hotelDetails")).jwtToken;
            axiosInstance.post('/upload', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Authorization'.concat(" ",accessToken),
              }
            })
            .then(response => {
              console.log(response);
            
              if (response.status !== 200) {
                throw new Error(`Server error: ${response.statusText}`);
              }
            
              return response;
            })
            .then(data => {
              if (data.error) {
                toast.error(data.error, { position: toast.POSITION.TOP_RIGHT });
              } else {
                const tempData = data.data.url;
                if (name == "backDoc") {
                  co_occupants[index].kyc_details.docData.backDoc = tempData;
                } else if (name == "frontDoc") {
                  co_occupants[index].kyc_details.docData.frontDoc = tempData;
                } else {
                  co_occupants[index].kyc_details.docData.profileImage =
                    tempData;
                    co_occupants[index].profileImage =
                    tempData;
                    co_occupants[index].baseShow =
                    tempData;
                  co_occupants[index].kyc_details.docData.baseShow = tempData;
                }

                let value = [...co_occupants];
                setCo_occupants(value);
              toast.success("Upload successfully", { position: toast.POSITION.TOP_RIGHT });
              }
            })
            .catch(error => {
              console.error(error);
              toast.error("Upload failed: " + error.message, { position: toast.POSITION.TOP_RIGHT });
            });
            } else {
            toast.error("Only jpeg, jpg and png are allowed ", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        };
      };
    }
  };
  const handleSubmit = () => {
    let formIsValid = handleValidation();

    let data = {};
    if (formIsValid) {
      navigate(`/guests-manual-verification/${id}`, {
        state: { co_occupants: co_occupants },
      });
    }
  };

  const handleAddMore = () => {
    setCo_occupants((updateList) => [
      ...updateList,
      {
        name: "",
        email: "",
        phone: "",
        guestType: "cotraveller",
        gender: "",
        relation: "",
        type: "adult",
        age: "",
        dob: "",
        profileImage: "",
        baseShow:"",
        address:"",
        mannualCheckin:true,

        kyc_details: {
          docType: "",
          docData: {
            name: "",
            phone: "",
            age: "",
            dob: "",
            docNumber: "",
            email: "",
            gender: "",
            address: "",
            backDoc: "",
            frontDoc: "",
            profileImage: "",
            baseShow: "",
            guestCategory: "adult",
          },
        },
      },
    ]);
  };

  const handleRemove = (e, i) => {
    e.preventDefault();

    let removedData = [...co_occupants];
    removedData.splice(i, 1);
    setCo_occupants(removedData);
  };

  const handleCommonChange = (e, i) => {
    const { name, value } = e.target;

    let temp = [...co_occupants];

    temp[i] = { ...temp[i], [name]: value };
    temp[i].kyc_details.docData = {
      ...temp[i].kyc_details.docData,
      [name]: value,
    };

    setCo_occupants(temp);
  };

  const handlePhoneChange = (phone, i) => {
    let temp = [...co_occupants];

    temp[i] = { ...temp[i], phone: `+${phone}` };
    temp[i].kyc_details.docData = {
      ...temp[i].kyc_details.docData,
      phone: `+${phone}`,
    };

    setCo_occupants(temp);
  };

  const handleDOBChange = (e, i) => {
    const { value } = e.target;

    let temp = [...co_occupants];
    temp[i]={...temp[i], dob:moment(value).format("MM/DD/YYYY"),age:moment().diff(value, "years", false)}

    temp[i].kyc_details.docData = {
      ...temp[i].kyc_details.docData,
      dob: moment(value).format("MM/DD/YYYY"),
      age: moment().diff(value, "years", false),
    };

    setCo_occupants(temp);
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  // const handleFileUpload = async (e,index) => {
  //   const file = e.target.files[0];
  //   const base64 = await convertToBase64(file);
  //   console.log(base64.split(','),'BASE==============>')
  //   co_occupants[index].kyc_details.docData.profileImage = base64.split(',')?.[1];
  //   co_occupants[index].kyc_details.docData.baseShow = base64;

  // };

  return (
    <div>
      <Header />
      <Sidenav />
      <div class="WrapperArea">
        <div class="WrapperBox">
          <div class="Small-Wrapper">
            <div class="NewTitleBox">
              <div className="arrow-left">
                <Link to="/guests-management">
                  <i class="fa fa-arrow-left arrow-back" aria-hidden="true"></i>
                </Link>

                <h4>Guests Manual Check-In</h4>
              </div>
            </div>
            {co_occupants?.map((item, i) => (
              <div class="CommonForm manualCheckin">
                {i > 0 && (
                  <a
                    className="CloseModal CloseCard"
                    onClick={(e) => handleRemove(e, i)}
                  >
                    ×
                  </a>
                )}

                <form>
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label>
                          {" "}
                          Name <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          value={item?.kyc_details?.docData?.name}
                          name="name"
                          onChange={(e) => {
                            handleCommonChange(e, i);
                          }}
                          class="form-control"
                          placeholder=""
                        />
                        {errorMsg.name && !item?.kyc_details?.docData?.name && (
                          <p style={{ color: "red" }}>{errorMsg.name}</p>
                        )}
                      </div>
                    </div>

                    <div
                      class={
                        item?.relation === undefined ? "col-sm-4" : "col-sm-2"
                      }
                    >
                      <div class="form-group">
                        <label>
                          Gender<span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          value={item?.kyc_details?.gender}
                          name="gender"
                          class="form-control"
                          onChange={(e) => {
                            handleCommonChange(e, i);
                          }}
                        >
                          <option value=""> Select</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                        </select>
                        {errorMsg.gender &&
                          !item?.kyc_details?.docData?.gender && (
                            <p style={{ color: "red" }}>{errorMsg.gender}</p>
                          )}
                      </div>
                    </div>
                    {item?.relation != undefined && (
                      <div class="col-sm-2">
                        <div class="form-group">
                          <label>
                            Guest Type<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            value={item?.kyc_details?.docData?.guestCategory}
                            name="guestCategory"
                            class="form-control"
                            onChange={(e) => {
                              co_occupants[
                                i
                              ].kyc_details.docData.guestCategory =
                                e.target.value;
                              co_occupants[i].type = e.target.value;

                              co_occupants[i].phone = "+91";
                              co_occupants[i].email = "";

                              let value = [...co_occupants];
                              setCo_occupants(value);
                            }}
                          >
                            <option value=""> Select</option>
                            <option value="adult">Adult</option>
                            <option value="kid">Kid</option>
                          </select>
                        </div>
                      </div>
                    )}

                    <div class="col-sm-4">
                      <div class="form-group">
                        <label>
                          Email Address{" "}
                          {item?.kyc_details?.docData.guestCategory ==
                            "adult" && <span style={{ color: "red" }}>*</span>}
                        </label>
                        <input
                          type="email"
                          name="email"
                          disabled={
                            item?.kyc_details?.docData.guestCategory == "kid"
                          }
                          value={item?.email}
                          onChange={(e) => {
                            handleCommonChange(e, i);
                          }}
                          class="form-control"
                          placeholder=""
                        />
                        {errorMsg.email &&
                          !item?.kyc_details?.docData?.email &&
                          item?.kyc_details?.docData?.guestCategory ==
                            "adult" && (
                            <p style={{ color: "red" }}>{errorMsg.email}</p>
                          )}
                        {/* {errorMsg.emailValid &&
                          (
                            <p style={{ color: "red" }}>
                              {errorMsg.emailValid}
                            </p>
                          )} */}

                        {errorMsg.emailValid &&
                          item?.kyc_details?.docData?.email !== "" &&
                          !/^.+?@.+?\..+$/.test(
                            item?.kyc_details?.docData?.email
                          ) && (
                            <p style={{ color: "red" }}>
                              {"* Please enter valid email."}
                            </p>
                          )}
                        {errorMsg.emailUnique &&
                          item?.kyc_details?.docData?.email !== "" && (
                            <p style={{ color: "red" }}>
                              {"* Please enter unique email."}
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label>
                          Date of Birth<span style={{ color: "red" }}>*</span>{" "}
                        </label>
{/* 
                        <DatePicker
                  className="form-control"
                  value={moment(
                    item?.kyc_details?.docData?.dob
                  )?.format("YYYYY-MM-DD")}

                  selected={filterData.startDate}
                  placeholderText="DD-MM-YYYY"
                  onChange={(date) => {
                    handleDOBChange(e, i);
                  }}

                  maxDate={ item?.kyc_details?.docData?.guestCategory == "kid"
                  ? moment(new Date()).format("YYYY-MM-DD")
                  : validDate.format("YYYY-MM-DD")}
                  dateFormat="dd-MM-yyyy"
                  onChange={(date) =>
                    setFilterData({ ...filterData, startDate: date })
                  }
                  minDate={ item?.kyc_details?.docData?.guestCategory == "kid"
                  ? validMinDate.format("YYYY-MM-DD")
                  : "1920-01-01"}
                /> */}
                        <input
                          value={moment(
                            item?.kyc_details?.docData?.dob
                          )?.format("YYYYY-MM-DD")}
                          name="dob"
                          max={
                            item?.kyc_details?.docData?.guestCategory == "kid"
                              ? moment(new Date()).format("YYYY-MM-DD")
                              : validDate.format("YYYY-MM-DD")
                          }
                          min={
                            item?.kyc_details?.docData?.guestCategory == "kid"
                              ? validMinDate.format("YYYY-MM-DD")
                              : "1920-01-01"
                          }
                          onChange={(e) => {
                            handleDOBChange(e, i);
                          }}
                          type="date"

                          onKeyDown={(e) => e.preventDefault()}                          class="form-control"
                          placeholder=""
                        />
                        {errorMsg.dob && !item?.kyc_details?.docData?.dob && (
                          <p style={{ color: "red" }}>{errorMsg.dob}</p>
                        )}
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label>
                          Phone Number
                          {item?.kyc_details?.docData.guestCategory ==
                            "adult" && <span style={{ color: "red" }}>*</span>}
                        </label>
                        <PhoneInput
                          disabled={
                            item?.kyc_details?.docData.guestCategory == "kid"
                          }
                          country={"in"}
                          value={item?.phone}
                          countryCodeEditable={false}
                          enableSearch={true}
                          onChange={(phone) => {
                            handlePhoneChange(phone, i);
                          }}
                        />
                      </div>
                      {errorMsg.phone &&
                        item?.kyc_details?.docData?.guestCategory == "adult" &&
                        item?.kyc_details?.docData?.phone == "" && (
                          <p style={{ color: "red" }}>{errorMsg.phone}</p>
                        )}
                      {errorMsg.phoneValid &&
                        item?.kyc_details?.docData?.phone !== "" &&
                        item?.kyc_details?.docData?.phone?.length < 13 && (
                          <p style={{ color: "red" }}>
                            {"* Please enter valid number."}
                          </p>
                        )}
                      {errorMsg.phoneUnique &&
                        item?.kyc_details?.docData?.phone !== "" && (
                          <p style={{ color: "red" }}>
                            {"* Please enter unique phone number."}
                          </p>
                        )}
                    </div>
{/* 
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label>
                          Please select document type
                          {item?.kyc_details?.docData.guestCategory ==
                            "adult" && <span style={{ color: "red" }}>*</span>}
                        </label>
                        <select
                          name="documentType"
                          value={item?.kyc_details?.docType}
                          onChange={(e) => {
                            co_occupants[i].kyc_details.docType =
                              e.target.value;
                            co_occupants[i].kyc_details.docData.docNumber = "";

                            let value = [...co_occupants];
                            setCo_occupants(value);
                          }}
                          class="form-control"
                        >
                          <option value="">Select </option>
                          <option value="Aadhaar">Aadhaar</option>
                          <option value="Passport">Passport </option>
                          <option value="Driving Licence">
                            Driving Licence{" "}
                          </option>
                          <option value="Voter Id">Voter Id </option>
                        </select>
                        {errorMsg.docType && !item?.kyc_details?.docType && (
                          <p style={{ color: "red" }}>{errorMsg.docType}</p>
                        )}
                      </div>
                    </div> */}
                  </div>
                  <div class="row">
                    {/* <div class="col-sm-4">
                      <div class="form-group">
                        <label>
                          Document Number{" "}
                          {item?.kyc_details?.docData.guestCategory ==
                            "adult" && <span style={{ color: "red" }}>*</span>}
                        </label>
                        <input
                          type="text"
                          name="documentNumber"
                          disabled={
                            item?.kyc_details?.docType == "" ? true : false
                          }
                          value={item?.kyc_details?.docData?.docNumber}
                          onChange={(e) => {
                            if (
                              item?.kyc_details?.docType == "Aadhaar" &&
                              !/^[0-9]{0,12}$/.test(e.target.value)
                            )
                              return;
                            {
                              co_occupants[i].kyc_details.docData.docNumber =
                                e.target.value;

                              let value = [...co_occupants];
                              setCo_occupants(value);
                            }
                          }}
                          class="form-control"
                          placeholder=""
                        />
                        {errorMsg.docNumber &&
                          !item?.kyc_details?.docData?.docNumber &&
                          item?.kyc_details?.docData?.guestCategory !==
                            "kid" && (
                            <p style={{ color: "red" }}>{errorMsg.docNumber}</p>
                          )}

                        {errorMsg.docNumberValid &&
                          item?.kyc_details?.docData?.docNumber?.length !==
                            12 && (
                            <p style={{ color: "red" }}>
                              {errorMsg.docNumberValid}
                            </p>
                          )}
                      </div>
                    </div> */}

                    <div className="col-sm-6">
                      <div class="form-group">
                        <label>
                          Address <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          name="address"
                          value={item?.kyc_details?.docData?.address}
                          onChange={(e) => {
                            co_occupants[i].address =
                            e.target.value;
                            co_occupants[i].kyc_details.docData.address =
                              e.target.value;
                            let value = [...co_occupants];
                            setCo_occupants(value);
                          }}
                          class="form-control"
                          placeholder=""
                        />
                        {errorMsg.address &&
                          !item?.kyc_details?.docData?.address && (
                            <p style={{ color: "red" }}>{errorMsg.address}</p>
                          )}
                      </div>
                    </div>

                    {item?.relation != undefined && (
                      <div class="col-sm-2">
                        <div class="form-group">
                          <label>
                            Relation <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            value={item?.relation}
                            name="gender"
                            class="form-control"
                            onChange={(e) => {
                              co_occupants[i].relation = e.target.value;
                              let value = [...co_occupants];
                              setCo_occupants(value);
                            }}
                          >
                            <option value=""> Select</option>
                            <option value="Friend">Friend</option>
                            <option value="Family">Family</option>
                            <option value="Colleague">Colleague</option>
                          </select>
                          {errorMsg.relation && !item?.relation && (
                            <p style={{ color: "red" }}>{errorMsg.relation}</p>
                          )}
                        </div>
                      </div>
                    )}

                    {/* {item?.kyc_details?.docType !== "Aadhaar" && (
                      <div class="col-sm-4">
                        <div class="form-group">
                          <label>
                            Front Document Image
                            {item?.kyc_details?.docData.guestCategory ==
                              "adult" && (
                              <span style={{ color: "red" }}>*</span>
                            )}
                          </label>
                          <div class="UploadBox">
                            <div class="Upload">
                              <p>
                                <i aria-hidden="true" class="fa fa-upload"></i>{" "}
                                <span>Upload </span>
                              </p>
                              <input
                                type="file"
                                onClick={(e) => (e.target.value = null)}
                                onChange={(e) => handleUpload(e, i, "frontDoc")}
                                class="form-control"
                              />
                              {item?.kyc_details?.docData?.frontDoc && (
                                <figure>
                                  <img
                                    src={item?.kyc_details?.docData?.frontDoc}
                                  />
                                </figure>
                              )}

                              {errorMsg.frontDoc &&
                                !item?.kyc_details?.docData?.frontDoc &&
                                item?.kyc_details?.docData?.guestCategory !==
                                  "kid" && (
                                  <p style={{ color: "red" }}>
                                    {errorMsg.frontDoc}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {item?.kyc_details?.docType !== "Aadhaar" && (
                      <div class="col-sm-4">
                        <div class="form-group">
                          <label>
                            Back Document Image
                            {item?.kyc_details?.docData.guestCategory ==
                              "adult" && (
                              <span style={{ color: "red" }}>*</span>
                            )}
                          </label>
                          <div class="UploadBox">
                            <div class="Upload">
                              <p>
                                <i aria-hidden="true" class="fa fa-upload"></i>{" "}
                                <span>Upload Document</span>
                              </p>
                              <input
                                type="file"
                                onClick={(e) => (e.target.value = null)}
                                onChange={(e) => handleUpload(e, i, "backDoc")}
                                class="form-control"
                              />
                              {item?.kyc_details?.docData?.backDoc && (
                                <figure>
                                  <img
                                    src={item?.kyc_details?.docData?.backDoc}
                                  />
                                </figure>
                              )}

                              {errorMsg.backDoc &&
                                !item?.kyc_details?.docData?.backDoc &&
                                item?.kyc_details?.docData?.guestCategory !==
                                  "kid" && (
                                  <p style={{ color: "red" }}>
                                    {errorMsg.backDoc}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )} */}
                    {/* <div class="col-sm-4">
                      <div class="form-group">
                        <label>
                          Profile Image<span style={{ color: "red" }}>*</span>
                        </label>
                        <div class="UploadBox">
                          <div class="Upload">
                            <p>
                              <i aria-hidden="true" class="fa fa-upload"></i>{" "}
                              <span>Upload Profile</span>
                            </p>
                            <input
                              type="file"
                              onClick={(e) => (e.target.value = null)}
                              onChange={(e) =>
                                handleUpload(e, i, "profileImage")
                              }
                              class="form-control"
                            />
                            {item?.kyc_details?.docData?.baseShow && (
                              <figure>
                                <img
                                  src={item?.kyc_details?.docData?.baseShow}
                                />
                              </figure>
                            )}

                            {errorMsg.baseShow &&
                              !item?.kyc_details?.docData?.baseShow && (
                                <p style={{ color: "red" }}>
                                  {errorMsg.baseShow}
                                </p>
                              )}
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </form>
              </div>
            ))}
            <p className="addOccupant">
              <a onClick={handleAddMore}>+ Add Occupant</a>
            </p>

            <div className="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label class="CheckBox">
                    I confirm that document belongs to the presenting user and
                    customer photo is there on document ID.
                    <input
                      type="checkbox"
                      onChange={(e) => setVerify(e.target.checked)}
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>

            <button disabled={!veriry} class="Button" onClick={handleSubmit}>
              {" "}
              Confirm and Proceed
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuestsManual;
