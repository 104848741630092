import React, { useEffect, useState } from 'react'
import Logo  from "../assets/image/logo.png"
import { useLocation, useNavigate } from 'react-router-dom';
import { ForgetPasswordAction, VerifyAdminAction ,VerifyTokenAction} from '../redux/actions/authAction';
import {toast} from 'react-toastify'
import { useDispatch } from 'react-redux';


const initialState = {
    password: "",
    confirmPassword: "",
    passwordError: "",
    confirmPasswordError: "",
    passwordValidError:"",

    errorMsg: "",
  };
  
const ChangePassword = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    let location = useLocation();
    let query= location.search?.split('&')
    let token =query?.[1]?.split("=")?.[1]

    const [iState, updateState] = useState(initialState);
  
    const {
      password,
      confirmPassword,
      passwordError,
      confirmPasswordError,
      passwordValidError,

      errorMsg,
    } = iState;


    
  
    const [password1Shown, setPassword1Shown] = useState(false);
    const [password2Shown, setPassword2Shown] = useState(false);
    const toggle1PasswordVisiblity = () => {
      setPassword1Shown(password1Shown ? false : true);
    };
  
    const toggle2PasswordVisiblity = () => {
      setPassword2Shown(password2Shown ? false : true);
    };
  
    let handleValidation = () => {
      let passwordError = "";
      let confirmPasswordError = "";
      let formIsValid = true;
      if (!password) {
        passwordError = "* Please enter Password.";
        formIsValid = false;
      }
  
      if (!confirmPassword) {
        confirmPasswordError = "* Please re- Enter Password.";
        formIsValid = false;
      } else if (password !== confirmPassword) {
        confirmPasswordError = "* Password Not Match !.";
        formIsValid = false;
      }
      else if (
        !/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/.test(password)
      ) {
        formIsValid = false;
    passwordValidError =
          "* Ensure that password contains both upper , lowercase includes special characters @ # * a. and numbers";
      }

  
      updateState({
        ...iState,
        passwordError,
        confirmPasswordError,
        errorMsg,
      });
      return formIsValid;
    };
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      updateState({
        ...iState,
        [name]: value,
      });
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      let formIsValid = handleValidation();
  
      if (formIsValid) {
  
        var data = { adminId: query?.[0]?.split("=")?.[1], newPassword: password };
  
        dispatch(ForgetPasswordAction(data))
          .then((res) => {
            console.log(res);
            if (res.status === 200) {
              navigate("/congratulation");
              toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            } else {
              toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              updateState({
                ...iState,
                errorMsg: res.message,
                ChangePasswordError: "",
                passwordError: "",
              });
            }
          })
          .catch((err) => {
            alert(err);
          });
      }
    };
  


    useEffect(()=>{


      dispatch(VerifyTokenAction({token}))
       .then((res) => {
         console.log(res);
         if (res.status === 200) {
         } else {
           navigate("/");
           toast.error("Token expired", {
             position: toast.POSITION.TOP_RIGHT,
           })
         }
       })
       .catch((err) => {
         alert(err);
       });
      },[])


  
  return (
    <div>
        
    <div class="LoginArea">
        <div class="LoginBox">
            <figure>
                <img src={Logo} alt="" />
            </figure>
            <form>
                <h3>Change password</h3>
                <h5>No Problem! Enter your new password</h5>
                <div class="form-group">
                    <label>Create New Password</label>
                    <input
              type={password1Shown ? "text" : "password"}
              value={password}
              name="password"
              onChange={handleChange}
              class="form-control"
              placeholder="Password"
            />
                        <span
              onClick={toggle1PasswordVisiblity}
              className={password1Shown ? "Icon cross" : "Icon"}
            >
              {password1Shown ? (
                <i className="fa fa-eye"></i>
              ) : (
                <i class="fa fa-eye-slash" aria-hidden="true"></i>
              )}
            </span>

            {passwordValidError && (
              <p className="emptyField" style={{ color: "red" }}>
                {passwordValidError}
              </p>
            )}


            {passwordError && (
              <p className="emptyField" style={{ color: "red" }}>
                {passwordError}
              </p>
            )}

                </div>
                <div class="form-group">
                    <label>Confirm Password</label>
                    <input
              type={password2Shown ? "text" : "password"}
              value={confirmPassword}
              name="confirmPassword"
              onChange={handleChange}
              class="form-control"
              placeholder="Confirm Password"
            />
                        <span
              onClick={toggle2PasswordVisiblity}
              className={password2Shown ? "Icon cross" : "Icon"}
            >
              {password2Shown ? (
                <i className="fa fa-eye"></i>
              ) : (
                <i class="fa fa-eye-slash" aria-hidden="true"></i>
              )}
            </span>

            {confirmPasswordError && (
              <p className="emptyField" style={{ color: "red" }}>
                {confirmPasswordError}
              </p>
            )}
                </div>

                <a onClick={handleSubmit} class="Button">CREATE PASSWORD</a>
            </form>
        </div>
    </div>
    </div>
  )
}

export default ChangePassword