import React, { useEffect, useState, useRef } from "react";
import Sidenav from "./sidenav";
import Header from "./header";
import Modal from "react-bootstrap/Modal";
import Partner4 from "../assets/image/partner4.png";
import { Link, useParams } from "react-router-dom";
import { useLocation } from "react-router";
import moment from "moment";
import Multiselect from "multiselect-react-dropdown";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import commonCrypto from "../utils";
import { RoomCategoryListAction } from "../redux/actions/roomAction";
import ImageFetcher from './ImageFetcher'


import { useDispatch, useSelector } from "react-redux";
import {
  GetAvailbleRoomAction,
  AssingnGuestAction,
  GuestDataAction,
  UpdateKycAction,
} from "../redux/actions/guestAction";
import { useContext } from "react";
import { SocketContext } from "../config/sckt";
import axiosInstance from '../axiosInstance';

const initialState = {
  numberOfRoom: "",
  room: [],
  errorMsg: {},
  meal: "",
  stay_duration: "",
  roomCategory:"",

};

const initialManaulState = {
  name: "",
  phone: "",
  age: "",
  dob: "",
  docNumber: "",
  email: "",
  gender: "",
  address: "",
  docImage: "",
  respProfile: "",
  baseShow: "",
  frontDoc: "",
  backDoc: "",
  docType: "",
  manualError: {},
};
const GuestsVerification = () => {
  const {id} =useParams()
  const { state } = useLocation();
  const [userData, setUserData] = useState("");
  const [iState, updateState] = useState(initialState);
  const [iManulState, updateManualState] = useState(initialManaulState);
  const { availableRooms, guestData } = useSelector(
    (state) => state.guestReducer
  );




  const { categoryList } = useSelector((state) => state.roomReducer);

  const [totalRoom, setTotalRoom]  =useState([])


  const [newGuest, setNewGuest] = useState([]);

  const today = moment();

  let validDate = today.subtract(18, "years");

  const dispatch = useDispatch();
  const socket = useContext(SocketContext);

  const { numberOfRoom, room, meal, errorMsg, stay_duration,  roomCategory} = iState;
  const {
    name,
    phone,
    age,
    dob,
    docNumber,
    email,
    gender,
    address,
    docType,
    frontDoc,
    backDoc,
    respProfile,
    baseShow,
    docImage,
  } = iManulState;

  const dropDownRefCategory = useRef();

  const [ManualModal, setManaulModal] = useState(false);
  const handleManualModalClose = () => {
    setManaulModal(false);
    updateManualState({
      name: "",
      phone: "",
      age: "",
      dob: "",
      docNumber: "",
      email: "",
      gender: "",
      address: "",
      docImage: "",
      docType: "",
      manualError: {},
    });
  };
  const handleManulaModalShow = (data) => {
    setUserData(data);
    let date = data?.userId?.kyc_details?.docData?.dob?.split("/");
    updateManualState({
      ...iManulState,
      name: data?.name,
      gender: data?.userId?.gender?.toLowerCase(),
      phone: data?.userId ? data?.userId?.phone : data?.phone,
      email: data?.userId
        ? data?.userId?.email == null || data?.userId?.email == "false"
          ? ""
          : data?.userId?.email
        : data?.email,
      dob: date == undefined ? "" : `${date[2]}-${date[0]}-${date[1]}`,
      address: data?.userId?.kyc_details?.docData?.address,
      docType: data?.userId?.kyc_details?.docType,
    });

    setManaulModal(true);
  };

  const [SuccessGuestModal, setSuccessGuestModal] = useState(false);
  const handleSuccessModalClose = () => {
    setSuccessGuestModal(false);
  };
  const handleSuccessModalShow = (id) => {
    setSuccessGuestModal(true);
  };

  function onSelectRoom(selectedList, selectedItem) {
    updateState({
      ...iState,
      room: [...room, selectedItem?._id],
    });
  }

  function onRemoveRoom(selectedList, removedItem) {
    let tempList = room;
    const removeIndex = tempList.findIndex((item) => item === removedItem._id);
    tempList.splice(removeIndex, 1);

    updateState({
      ...iState,
      room: tempList,
    });
  }

  const handleRefresh = () => {
    window.location.reload();
  };

  let handleValidation = () => {
    let errorMsg = {};
    let formIsValid = true;

    if (
      guestData?.co_occupants?.some(
        (item) => item?.type == "adult" && item?.userId && item?.kyc_details
      )
    ) {
    } else {
      formIsValid = false;
      toast.error("* Please complete kyc.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    // if (
    //   guestData?.co_occupants?.some(
    //     (item) => item?.cotravellerId?.selfiVerify == "initial" && item?.userId
    //   )
    // ) {
    //   toast.error("* Please verify selfie.", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });


      

    //   formIsValid = false;
    // }


    if (
      newGuest?.some(
        (item) => item?.mannualCheckin===true && item?.docReceviedCheck ===false
      )
    ) {
      toast.error("* Please Recevie co-traveller document.", {
        position: toast.POSITION.TOP_RIGHT,
      });


      

      formIsValid = false;
    }

    if (room.length == 0) {
      errorMsg.room = "* Please select room";
      formIsValid = false;
    }
    if (!stay_duration) {
      errorMsg.stay_duration = "* Please enter number of days";
      formIsValid = false;
    }

    if (!meal) {
      errorMsg.meal = "* Please select meal";
      formIsValid = false;
    }

    updateState({ ...iState, errorMsg: errorMsg });
    return formIsValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;


    if (name == "stay_duration") {
      if (!/^[0-9]{0,10}$/.test(value)) return;
      {
        updateState((prev) => ({ ...prev, [name]: value }));
      }
    } else {
      updateState({ ...iState, [name]: value });
    }
  };
  useEffect(() => {
    const hotelData = JSON.parse(localStorage.getItem("hotel"));

    dispatch(GetAvailbleRoomAction({ hotelId: id }));
  }, []);

  useEffect(() => {
    if (state) {
      let temp = state?.co_occupants?.filter(
        (item) => item?.guestType == "main"
      );

      socket.emit("checkin_room_join", { roomId: state?._id });
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    let formIsValid = handleValidation();
    let tempName = state?.name
      ? state?.name
      : `${state?.firstName} ${state?.lastName}`;

    if (formIsValid) {
      let data;
      if (guestData) {
        data = guestData;
        data["numberOfRoom"] = room?.length;
        data["stay_duration"] = stay_duration;
        data.rooms = room;
        data["meal"] = meal;
        data["hotelId"] =id
      }



      dispatch(AssingnGuestAction(data))
        .then((res) => {
          if (res.status === 200) {
            socket.emit("checkin_data", res.data.data);

            setSuccessGuestModal(true);
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(res.response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const handleKycChange = (e) => {
    const { name, value } = e.target;
    if (name == "dob") {
      updateManualState({
        ...iManulState,
        [name]: moment(value).format("MM/DD/YYYY"),
        age: moment().diff(value, "years", false),
      });
    } else if (name == "docNumber") {
      if (docType == "Aadhaar" && !/^[0-9]{0,12}$/.test(e.target.value)) return;
      {
        updateManualState({ ...iManulState, [name]: value });
      }
    } else if (name == "docType") {
      updateManualState({ ...iManulState, docNumber: "", [name]: value });
    } else {
      updateManualState({ ...iManulState, [name]: value });
    }
  };

  let handleValidation2 = () => {
    let manualError = {};
    let formIsValid = true;

    if (!name) {
      manualError.name = "* Please enter name";
      formIsValid = false;
    }

    if (docType !== "Aadhaar") {
      if (!frontDoc) {
        manualError.frontDoc = "* Please upload front Document";
        formIsValid = false;
      }

      if (!backDoc) {
        manualError.backDoc = "* Please upload back Document";
        formIsValid = false;
      }
    }

    if (!respProfile) {
      manualError.ressProfile = "* Please upload profile";
      formIsValid = false;
    }

    if (!address) {
      manualError.address = "* Please enter addresss";
      formIsValid = false;
    }

    if (!docNumber) {
      manualError.docNumber = "* Please enter document number";
      formIsValid = false;
    }

    if (!docType) {
      manualError.docType = "* Please select document type";
      formIsValid = false;
    }

    // if (co_occupants?.some((item) => item?.relation == "")) {
    //   errorMsg.relation = "* Please select  relation ";
    //   formIsValid = false;
    // }

    updateManualState({ ...iManulState, manualError: manualError });
    return formIsValid;
  };

  window.Buffer = window.Buffer || require("buffer").Buffer;

  const handleUpload = (e, name) => {
    const file = e.target.files[0];

    if (file.type == "application/pdf") {
      toast.error("Only jpeg, jpg and png are allowed ", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    if (file) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        //Initiate the JavaScript Image object.
        var image = new Image();

        //Set the Base64 string return from FileReader as source.
        image.src = e.target.result;

        //Validate the File Height and Width.
        image.onload = function () {
          var height = this.height;
          var width = this.width;
          if (
            file.type === "image/jpeg" ||
            file.type === "image/jpg" ||
            file.type === "image/png"
          ) {
            const formData = new FormData();
            formData.append('file', file);
  
            let accessToken = JSON.parse(localStorage.getItem("hotelDetails")).jwtToken;
            axiosInstance.post('/upload?type=kyc', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Authorization'.concat(" ",accessToken),
              }
            })
            .then(response => {
              console.log(response);
            
              if (response.status !== 200) {
                throw new Error(`Server error: ${response.statusText}`);
              }
            
              return response;
            })
            .then(data => {
              if (data.error) {
                toast.error(data.error, { position: toast.POSITION.TOP_RIGHT });
              } else {
                const tempData = data.data.url;
                if (name == "backDoc") {
                  updateManualState({ ...iManulState, backDoc: tempData });
                } else if (name == "frontDoc") {
                  updateManualState({ ...iManulState, frontDoc: tempData });
                } else {
                  updateManualState({
                    ...iManulState,
                    respProfile: tempData,
                    baseShow: tempData,
                  });
                }
              toast.success("Upload successfully", { position: toast.POSITION.TOP_RIGHT });
              }
            })
            .catch(error => {
              console.error(error);
              toast.error("Upload failed: " + error.message, { position: toast.POSITION.TOP_RIGHT });
            });
            } else {
            toast.error("Only jpeg, jpg and png are allowed ", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        };
      };
    }
  };

  const protect_Doc = (user_email) => {
    var avg, splitted, part1, part2;
    splitted = user_email.slice(0, 4);
    part1 = user_email.slice(0, 4);
    part2 = user_email.slice(user_email.length - 4, user_email.length);
    return "*********" + part2;
  };

  const handleManual = (e) => {
    e.preventDefault();

    let formIsValid = handleValidation2();

    const { manualError, ...manualData } = iManulState;
    let data;
    let tempData = manualData;
    if (userData?.mannualCheckin) {
      data = userData;
      data["guestId"] = guestData._id;
      data["email"] = email;

      data["cotravellerId"] = userData.cotravellerId._id;
      data["kyc_details"] = {
        docData: tempData,
        docType: docType,
        kyc_status: true,
      };
    } else {
      data = userData;
      data["email"] = email;

      data["guestId"] = guestData?._id;
      data["cotravellerId"] = userData?.cotravellerId?._id;
      data["userId"] = userData?.userId?._id;

      data["kyc_details"] = {
        docData: tempData,
        docType: docType,
        kyc_status: true,
      };
    }

    data['co_occupants']=newGuest;
    data['hotelId'] =id

    if (formIsValid) {
      dispatch(UpdateKycAction(data)).then((res) => {
        if (res.status === 200) {
          setManaulModal(false);

          dispatch(GuestDataAction({ _id: state?._id, hotelId:id }));
          updateManualState({
            name: "",
            phone: "",
            age: "",
            dob: "",
            docNumber: "",
            email: "",
            gender: "",
            address: "",
            docImage: "",
            docType: "",
            manualError: {},
          });

          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    } else {
      toast.error("* Kyc detail & selfie must be verified. ", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    dispatch(GuestDataAction({ _id: state?._id, hotelId:id }));

    dispatch(RoomCategoryListAction({hotelId:id}))
  }, []);


  useEffect(()=>{

    setNewGuest(guestData?.co_occupants)

  },[guestData])

  const handleRefreshPage = () => {
    dispatch(GuestDataAction({ _id: state?._id, hotelId:id }));
  };



  const setVerify = (e, i) => {


    let temp = [...newGuest];

    temp[i] = { ...temp[i], docReceviedCheck:e.target.checked};

    setNewGuest(temp);
  };



  useEffect(()=>{

    dropDownRefCategory.current.resetSelectedValues();


    if(roomCategory ==""){
      setTotalRoom(availableRooms)
    }
    else{

      let temp =availableRooms?.filter((ele)=>ele.category ==roomCategory)

      setTotalRoom(temp)
    }
    

  },[availableRooms, roomCategory])
  return (
    <div>
      <Header />
      <Sidenav />
      <div class="WrapperArea">
        <div class="WrapperBox">
          <div class="back-btn">
            <Link to={`/guests-management/${id}`}>
              <i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back
            </Link>
          </div>

          <div class="Small-Wrapper">
            <div class="NewTitleBox">
              <h4>Guests Verification</h4>

              <button class="Button Cancel" onClick={handleRefreshPage}>
                <i class="fa fa-refresh"></i>
              </button>
            </div>
            {newGuest?.map((item,i) => {
              let decryptManual = commonCrypto.decrypt(
                item?.kyc_details?.docData
              );
              let decryptMain = commonCrypto.decrypt(
                item?.userId?.kyc_details?.docData
              );

              return (
                <div class="GuestUserBox">
                  <figure>
                  <ImageFetcher imageUrl={item?.type == "kid"
                          ? item?.cotravellerId?.profileImage
                          : item?.userId
                          ? decryptMain?.kycSelfie
                          : decryptMain?.respProfile 
                          }/>
               
                  </figure>
                  <figcaption>
                    <div class="GuestUserHead">
                      {/* {item?.type == "adult" &&
                        (item?.guestType != "main" && !item?.kyc_details ? (
                          <button
                            type="button"
                            onClick={() => handleManulaModalShow(item)}
                            className={"ManualTrue"}
                          >
                            Manual Check-In
                          </button>
                        ) : (
                          item?.userId?.kyc_details?.kyc_status == false && (
                            <button
                              type="button"
                              onClick={() => handleManulaModalShow(item)}
                              className={"ManualFalse"}
                            >
                              Manual Check-In
                            </button>
                          )
                        ))} */}

                      <aside>
                        <p>
                          <label>Name </label>
                          <span>
                            {item?.userId?.kyc_details
                              ? decryptMain?.name
                              : item?.name}
                          </span>
                        </p>
                        {item?.type == "adult" && item?.userId && (
                          <p>
                            <label>Gender</label>
                            <span>
                              {" "}
                              {decryptManual?.gender
                                ? decryptManual?.gender
                                    ?.charAt(0)
                                    ?.toUpperCase() +
                                  decryptManual?.gender?.slice(1)
                                : item?.userId
                                ? item?.userId?.gender
                                    ?.charAt(0)
                                    ?.toUpperCase() +
                                  item?.userId?.gender?.slice(1)
                                : item?.kyc_details?.docData?.gender
                                    ?.charAt(0)
                                    ?.toUpperCase() +
                                  item?.kyc_details?.docData?.gender?.slice(1)}
                            </span>
                          </p>
                        )}
                        {(item?.userId || item?.type == "kid") && (
                          <p>
                            <label>Date of Birth</label>
                            <span>
                              {item?.type == "kid"
                                ? moment(item?.cotravellerId?.dob).format("ll")
                                : item?.userId
                                ? moment(decryptMain?.dob).format("ll")
                                : decryptManual?.dob
                                ? moment(decryptManual?.dob)?.format("ll")
                                : "NA"}
                            </span>
                          </p>
                        )}
                        {(item?.userId || item?.type == "kid") && (
                          <p>
                            <label>Age</label>
                            <span>
                              {item?.type == "kid"
                                ? item?.cotravellerId?.age
                                : item?.userId
                                ? decryptMain?.age
                                : decryptManual?.age
                                ? decryptManual?.age
                                : "NA"}{" "}
                              Years
                            </span>
                          </p>
                        )}

                        {item?.type == "adult" &&
                          item?.userId &&
                          item?.userId?.kyc_status && (
                            <p>
                              <label>KYC Status</label>
                              <span>
                                {" "}
                                {item?.userId
                                  ? item?.userId?.kyc_status
                                    ? "Verified"
                                    : "Pending"
                                  : item?.kyc_details?.kyc_status
                                  ? "Verified"
                                  : "Pending"}
                              </span>
                            </p>
                          )}
                        {item?.type == "adult" && decryptMain?.docFrontImg && (
                          <p>
                            <label> Documents</label>
                            <span class="passport">
                            <ImageFetcher imageUrl={decryptMain?.docFrontImg}/>
                            </span>
                            <span class="passport">
                            <ImageFetcher imageUrl={decryptMain?.docFrontImg}/>
                            </span>
                          </p>
                        )}
                      </aside>
                      <aside>
                        {item?.mannualCheckin &&
                      <p>
                            <label>Document Receive</label>
                      <input
                      type="checkbox"
                      onChange={(e) => setVerify(e,i)}
                    />
                    </p>}
                        {decryptMain?.kycPlace && (
                          <p>
                            <label>Location</label>
                            <span>{decryptMain?.kycPlace}</span>
                          </p>
                        )}

                        {item?.type == "adult" && (
                          <p>
                            <label>Email</label>
                            <span>
                              {" "}
                              {item?.email==""?"NA":
                              item?.userId
                                ? item?.userId?.email == "" ||
                                  item?.userId?.kyc_status == false ||
                                  item?.userId?.email == "false"
                                  ? "NA"
                                  : item?.userId?.email
                                : item?.email == ""
                                ? "NA"
                                : item?.email}
                            </span>
                          </p>
                        )}
                        {item?.type == "adult" && (
                          <p>
                            <label>Phone Number</label>
                            <span>
                              {item?.userId?.phone
                                ? item?.userId?.phone
                                : item?.phone}
                            </span>
                          </p>
                        )}
                        {item?.type == "adult" && (
                          <p>
                            <label>Address</label>
                            <span style={{lineBreak:"anywhere"}}>
                              {item?.userId?.kyc_status
                                ? decryptMain?.address
                                : decryptManual?.address
                                ? decryptManual?.address
                                : "NA"}
                            </span>
                          </p>
                        )}
                        {item?.type == "adult" &&
                          item?.userId &&
                          item?.userId?.kyc_status && (
                            <p>
                              <label>Verified with Document</label>
                              <span>
                                {" "}
                                {item?.userId
                                  ? item?.userId?.kyc_details?.docType
                                  : decryptManual?.docType
                                  ? decryptManual?.docType
                                  : item?.kyc_details?.docData?.docType}
                              </span>
                            </p>
                          )}
                        {item?.type == "adult" &&
                          item?.userId &&
                          item?.userId?.kyc_status && (
                            <p>
                              <label>Selfie Status</label>
                              <span> { item?.guestType=="main"?"Verified":item?.cotravellerId?.selfiVerify}</span>
                            </p>
                          )}

                        {item?.type == "adult" &&
                          item?.userId &&
                          item?.userId?.kyc_status && (
                            <p>
                              <label>Document No.</label>
                              <span>
                                {" "}
                                {item?.userId
                                  ? decryptMain?.docNumber
                                  : decryptManual?.docNumber
                                  ? decryptManual?.docNumber
                                  : "NA"}
                              </span>
                            </p>
                          )}
                      </aside>
                    </div>
                  </figcaption>
                </div>
              );
            })}

            <div class="CommonForm">
              <div class="row">
                <div class="col-sm-3">
                  <div class="form-group">
                    <label>
                      Please select number of rooms needs to be allocated to
                      guests
                    </label>
                    <select
                      value={meal}
                      name="meal"
                      onChange={handleChange}
                      class="form-control"
                    >
                      <option value="">Select </option>
                      <option value="CP">
                        CP (Continental Plan) With Breakfast{" "}
                      </option>
                      <option value="MAP">
                        MAP Buffer (Breakfast + Buffet Lunch/Dinner){" "}
                      </option>
                      <option value="AP">
                        AP (Buffet Breakfast + Buffet Lunch + Buffet Dinner){" "}
                      </option>
                      <option value="EP">
                        EP (all meals are extra chargeable){" "}
                      </option>
                    </select>
                    {errorMsg.meal && !meal && (
                      <p style={{ color: "red" }}>{errorMsg.meal}</p>
                    )}
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="form-group">
                    <label>Stay Duration</label>
                    <input
                      value={stay_duration}
                      name="stay_duration"
                      type="text"
                      class="form-control"
                      placeholder="Enter Stays days"
                      onChange={handleChange}
                    />
                    {errorMsg.stay_duration && !stay_duration && (
                      <p style={{ color: "red" }}>{errorMsg.stay_duration}</p>
                    )}
                  </div>
                </div>
                <div class="col-sm-3">

                <div class="form-group">
              <label>Room category</label>

              <select
                name="roomCategory"
                onChange={handleChange}
                value={roomCategory}
                class="form-control"
              >
                <option value={''}>All </option>
                {categoryList?.roomCategory?.map((item) => (
                  <option value={item}>{item}</option>
                ))}
              </select>

            </div>
            </div>
                <div class="col-sm-3">
                  <div class="form-group">
                    <label>Select Room</label>
                    <Multiselect
                      options={totalRoom}
                      selectedValues={totalRoom.selected}
                      displayValue="fullNumber"
                      onSelect={onSelectRoom}
                      ref={dropDownRefCategory}
                      onRemove={onRemoveRoom}
                      resetSelectedValues={handleRefresh}
                    />

                    {errorMsg.room && room?.length == 0 && (
                      <p style={{ color: "red" }}>{errorMsg.room}</p>
                    )}
                  </div>
                </div>
              </div>
              <button class="Button" type="button" onClick={handleSubmit}>
                Confirm &amp; Allocate Rooms
              </button>
              &nbsp;&nbsp;&nbsp;
              <Link
                class="Button"
                to={`/guests-management/${id}`}
                style={{ backgroundColor: "#c70000" }}
                type="button"
              >
                Cancel
              </Link>
            </div>
          </div>
        </div>
      </div>

      <Modal show={SuccessGuestModal} className="ModalBox ">
        <Modal.Body>
          <div class="SuccessGuestBox">
            <span>
              <img src={Partner4} />
            </span>
            <h4>
              Check-In has been completed successfully and a confirmation link
              sent on customer's Mobile and email Address.
            </h4>
            <Link to={`/guests-management/${id}`}>Back to Guests Stay Management</Link>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={ManualModal}
        onHide={handleManualModalClose}
        className="ModalBox "
      >
        <Modal.Body>
          <div class="Category">
            <a onClick={handleManualModalClose} class="CloseModal">
              ×
            </a>
            <h3>KYC Details</h3>
            <div class="form-group">
              <label></label>
              <div class="UploadBox">
                <div class="Upload">
                  <i class="fa fa-upload"></i> <span>Upload Document </span>
                  <input
                    type="file"
                    onClick={(e) => (e.target.value = null)}
                    onChange={(e) => handleUpload(e)}
                    class="form-control"
                  />
                </div>
                {docImage && (
                  <div className="UploadIcon">
                    <img src={docImage} alt="image" />
                  </div>
                )}
              </div>
              {errorMsg.docImage && (
                <p style={{ color: "red" }}>{errorMsg.docImage}</p>
              )}
            </div>
            <div class="form-group">
              <label>Name</label>
              <input
                value={name}
                name="name"
                onChange={handleKycChange}
                class="form-control"
              />
              {errorMsg.name && !name && (
                <p style={{ color: "red" }}>{errorMsg.name}</p>
              )}
            </div>
            <div class="form-group">
              <label>Email</label>
              <input
                value={email}
                name="email"
                onChange={handleKycChange}
                class="form-control"
              />
              {errorMsg.email && !email && (
                <p style={{ color: "red" }}>{errorMsg.email}</p>
              )}
            </div>
            <div class="form-group">
              <label>Phone Number</label>
              <PhoneInput
                country={"in"}
                value={phone}
                countryCodeEditable={false}
                enableSearch={true}
                onChange={(phone) =>
                  updateManualState({ ...handleKycChange, phone: `+${phone}` })
                }
              />
            </div>

            <div className="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label> Document Number</label>
                  <input
                    value={docNumber}
                    name="docNumber"
                    onChange={handleKycChange}
                    type="number"
                    class="form-control"
                  />
                  {errorMsg.docNumber && !docNumber && (
                    <p style={{ color: "red" }}>{errorMsg.docNumber}</p>
                  )}
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label> DOB</label>
                  <input
                    value={dob}
                    max={validDate.format('YYYY-MM-DD')}

                    // value="2012-07-16"
                    name="dob"
                    onChange={handleKycChange}
                    type="date"
                    class="form-control"
                  />
                  {errorMsg.dob && !dob && (
                    <p style={{ color: "red" }}>{errorMsg.dob}</p>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label>Document Type</label>
                  <select
                    name="docType"
                    value={docType}
                    onChange={handleKycChange}
                    class="form-control"
                  >
                    <option value="">Select </option>
                    <option value="Aadhar">Aadhar </option>
                    <option value="Paasport">Paasport </option>
                  </select>
                  {errorMsg.docType && !docType && (
                    <p style={{ color: "red" }}>{errorMsg.docType}</p>
                  )}
                </div>
              </div>

              <div class="col-sm-6">
                <div class="form-group">
                  <label>Gender</label>
                  <select
                    name="gender"
                    value={gender}
                    onChange={handleKycChange}
                    class="form-control"
                  >
                    <option value="">Select </option>
                    <option value="male">Male </option>
                    <option value="female">Female </option>
                  </select>
                  {errorMsg.gender && !gender && (
                    <p style={{ color: "red" }}>{errorMsg.gender}</p>
                  )}
                </div>
              </div>
            </div>

            <div class="form-group">
              <label> Addresss</label>
              <input
                value={address}
                name="address"
                onChange={handleKycChange}
                type="text"
                class="form-control"
              />
              {errorMsg.address && !address && (
                <p style={{ color: "red" }}>{errorMsg.address}</p>
              )}
            </div>
            <button class="Button" onClick={handleManual}>
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default GuestsVerification;
