import React from "react";
import Header from "./header";
import Sidenav from "./sidenav";
import { Link, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axiosInstance from '../axiosInstance';
import Modal from "react-bootstrap/Modal";
import ImageFetcher from './ImageFetcher'
import DashbordImg from "../assets/image/dashboard-img.png";
import { useState } from "react";
import { useEffect } from "react";
import moment from "moment";
import Phone from "../assets/image/phone.svg";
import { useDispatch, useSelector } from "react-redux";
import { AdminUpdateDetailsAction } from "../redux/actions/authAction";
import { HotelDetailsAction } from "../redux/actions/hotelAction";

const initialState = {
  icon: "",
  name: "",
  number: "",
  intercom: false,
  errorMsg: {},
};
const initialDetailsState = {
  description: "",
  policy: "",
  detailError: {},
};

const initialAccountsState = {
  accountNumber: "",
  accountHolderName: "",
  ifsc: "",
  branch: "",
  bankName: "",
  accountError: {},
};

const HotelDetails = () => {
  const { id } = useParams();

  const { hotelData } = useSelector((state) => state.hotelReducer);

  const { state } = useLocation();
  if (state) {
    window.localStorage.setItem("hotelDetails", JSON.stringify(state));
  }

  const hotelDetails = JSON.parse(window.localStorage.getItem("hotelDetails"));

  const contactStaticImage = [
    {
      name: "Housekeeping",
      image: "https://bms-assets-storage-bucket.s3.ap-south-1.amazonaws.com/housekeeping.png",
    },
    {
      name: "Restaurant",
      image: "https://bms-assets-storage-bucket.s3.ap-south-1.amazonaws.com/restaurant.png",
    },
    {
      name: "Spa",
      image: "https://bms-assets-storage-bucket.s3.ap-south-1.amazonaws.com/spa.png",
    },
    {
      name: "Security",
      image: "https://bms-assets-storage-bucket.s3.ap-south-1.amazonaws.com/security.png",
    },
    {
      name: "Front_Desk",
      image: "https://bms-assets-storage-bucket.s3.ap-south-1.amazonaws.com/front_desk.png",
    },
  ];

  const [options, setOptions] = useState(contactStaticImage);

  const [contactBook, setContactbook] = useState();
  const [detailsState, setDetailsState] = useState(initialDetailsState);
  const [accountState, setAccountState] = useState(initialAccountsState);

  const [iState, updateState] = useState(initialState);
  const [index, setIndex] = useState("");
  const [Type, setType] = useState("add");
  const [Id, setId] = useState("");

  const { icon, name, number, intercom, errorMsg } = iState;
  const {
    accountHolderName,
    accountNumber,
    bankName,
    branch,
    ifsc,
    accountError,
  } = accountState;
  const { description, policy, detailError } = detailsState;

  const dispatch = useDispatch();

  const [AddModal, setAddModel] = useState(false);
  const handleAddModalClose = () => {
    updateState({ icon: "", name: "", number: "", errorMsg: {} });
    setAddModel(false);
  };

  const handleAddModalShow = (type, ele, i) => {
    if (type == "edit") {
      setType(type);
      setId(ele?._id);
      setIndex(i);

      updateState({
        ...iState,
        icon: ele?.icon,
        name: ele?.name,
        number: ele?.number,
        intercom: ele?.intercom,
      });
      setAddModel(true);
    } else {
      setAddModel(true);
      setType(type);
    }
  };

  const [AccountModal, setAccountModel] = useState(false);
  const handleAccountModalClose = () => {
    updateState({ icon: "", name: "", number: "", errorMsg: {} });
    setAccountModel(false);
  };

  const handleAccountModalShow = (type, ele) => {
    if (type == "edit") {
      setType(type);
      setId(ele?._id);

      setAccountState((prev) => ({
        ...prev,

        accountNumber: ele?.actNumber,
        accountHolderName: ele?.actName,
        ifsc: ele?.ifscCode,
        bankName: ele?.bankName,
        branch: ele?.branch,
        accountError: {},
      }));

      setAccountModel(true);
    } else {
      setAccountModel(true);
      setType(type);
    }
  };

  let handleDetailsValidation = () => {
    let detailError = {};
    let formIsValid = true;

    if (!description) {
      detailError.description = "* Please enter description";
      formIsValid = false;
    }

    if (!policy) {
      detailError.policy = "* Please enter policy";
      formIsValid = false;
    }

    setDetailsState({ ...detailsState, detailError: detailError });
    return formIsValid;
  };

  let handleValidation = () => {
    let errorMsg = {};
    let formIsValid = true;

    if (!icon) {
      errorMsg.icon = "* Please uploac icon";
      formIsValid = false;
    }

    if (!name) {
      errorMsg.name = "* Please enter name";
      formIsValid = false;
    }

    if (!number) {
      errorMsg.number = "* Please enter number";
      formIsValid = false;
    }

    updateState({ ...iState, errorMsg: errorMsg });
    return formIsValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name == "name") {
      contactStaticImage?.map((item, i) => {
        if (item?.name == value) {
          updateState((prev) => ({ ...prev, icon: item?.image, name: value }));
        }
      });
    } else if (name == "number") {
      if (!/^[0-9]{0,10}$/.test(value)) return;
      {
        updateState((prev) => ({ ...prev, [name]: value }));
      }
    } else {
      updateState((prev) => ({ ...prev, intercom: e.target.checked }));
    }
  };

  window.Buffer = window.Buffer || require("buffer").Buffer;

  const handleUpload = (e) => {
    const file = e.target.files[0];

    if (file.type == "application/pdf") {
      toast.error("Only jpeg, jpg and png are allowed ", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    if (file) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        //Initiate the JavaScript Image object.
        var image = new Image();

        //Set the Base64 string return from FileReader as source.
        image.src = e.target.result;

        //Validate the File Height and Width.
        image.onload = function () {
          var height = this.height;
          var width = this.width;
          if (
            file.type === "image/jpeg" ||
            file.type === "image/jpg" ||
            file.type === "image/png"
          ) {
            const formData = new FormData();
            formData.append('file', file);
  
            let accessToken = JSON.parse(localStorage.getItem("hotelDetails")).jwtToken;
            axiosInstance.post('/upload?type=hotel', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Authorization'.concat(" ",accessToken),
              }
            })
            .then(response => {
              console.log(response);
            
              if (response.status !== 200) {
                throw new Error(`Server error: ${response.statusText}`);
              }
            
              return response;
            })
            .then(data => {
              if (data.error) {
                toast.error(data.error, { position: toast.POSITION.TOP_RIGHT });
              } else {
                const tempData = data.data.url;
                updateState({ ...iState, image: tempData });
              toast.success("Upload successfully", { position: toast.POSITION.TOP_RIGHT });
              }
            })
            .catch(error => {
              console.error(error);
              toast.error("Upload failed: " + error.message, { position: toast.POSITION.TOP_RIGHT });
            });
            } else {
            toast.error("Only jpeg, jpg and png are allowed ", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        };
      };
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formIsValid = handleValidation();

    if (formIsValid) {
      var data = {
        name,
        number,
        icon,
        intercom,
      };

      let tempData = contactBook;

      tempData.push(data);
      let localData = hotelData;
      localData["contactBook"] = tempData;
      dispatch(
        AdminUpdateDetailsAction({ contactBook: tempData, hotelId: id })
      ).then((res) => {
        if (res.status == "202") {
          dispatch(HotelDetailsAction({ hotelId: id }));

          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({ icon: "", name: "", number: "", errorMsg: {} });
          setAddModel(false);
        }
      });
    }
  };

  const handleEdit = (e) => {
    e.preventDefault();

    let formIsValid = handleValidation();

    if (formIsValid) {
      var data = {
        name,
        number,
        icon,
        intercom,
        _id: Id,
      };

      let tempData = contactBook;
      tempData[index] = data;
      let localData = hotelData;
      localData["contactBook"] = tempData;

      dispatch(
        AdminUpdateDetailsAction({ hotelId: id, contactBook: tempData })
      ).then((res) => {
        if (res.status == 202) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          dispatch(HotelDetailsAction({ hotelId: id }));
          updateState({ icon: "", name: "", number: "", errorMsg: {} });
          setAddModel(false);
        }
      });
    }
  };

  const handleDetailsChange = (e) => {
    const { name, value } = e.target;
    setDetailsState({ ...detailsState, [name]: value });
  };

  const handleUpdateDetails = () => {
    let formIsValid = handleDetailsValidation();
    if (formIsValid) {
      dispatch(
        AdminUpdateDetailsAction({
          hotelId: hotelDetails?._id,
          description,
          policy,
        })
      ).then((res) => {
        if (res.status == 202) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({
            description: "",
            policy: "",
            errorMsg: {},
          });
          dispatch(HotelDetailsAction({ hotelId: id }));
        }
      });
    }
  };

  let handleAccountValidation = () => {
    let accountError = {};
    let formIsValid = true;

    if (!accountHolderName) {
      accountError.accountHolderName = "* Please enter account holder name";
      formIsValid = false;
    }

    if (!accountNumber) {
      accountError.accountNumber = "* Please enter account name";
      formIsValid = false;
    }

    if (!ifsc) {
      accountError.ifsc = "* Please enter ifsc";
      formIsValid = false;
    }

    if (!bankName) {
      accountError.bankName = "* Please enter bank name";
      formIsValid = false;
    }

    if (!branch) {
      accountError.branch = "* Please enter branch";
      formIsValid = false;
    }

    setAccountState({ ...accountState, accountError: accountError });
    return formIsValid;
  };

  const handleAccountChange = (e) => {
    const { name, value } = e.target;
    if (name == "accountNumber") {
      if (!/^[0-9]{0,20}$/.test(value)) return;
      {
        setAccountState((prev) => ({ ...prev, [name]: value }));
      }
    } else if (
      name == "accountHolderName" ||
      name == "bankName" ||
      name == "branch"
    ) {
      if (!/^[a-zA-Z ]*$/.test(value)) return;
      {
        setAccountState((prev) => ({ ...prev, [name]: value }));
      }
    } else {
      setAccountState({ ...accountState, [name]: value });
    }
  };

  const handleAccountSubmit = (e) => {
    e.preventDefault();

    let formIsValid = handleAccountValidation();

    if (formIsValid) {
      var data = {
        actName: accountHolderName,
        actNumber: accountNumber,
        ifscCode: ifsc,
        bankName,
        branch,
      };

      dispatch(
        AdminUpdateDetailsAction({ accountDetails: data, hotelId: id })
      ).then((res) => {
        if (res.status == "202") {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setAccountModel(false);
          setAccountState({
            accountNumber: "",
            accountHolderName: "",
            ifsc: "",
            branch: "",
            bankName: "",
            accountError: {},
          });
          dispatch(HotelDetailsAction({ hotelId: id }));
        }
      });
    }
  };

  useEffect(() => {
    dispatch(HotelDetailsAction({ hotelId: id }));
  }, []);

  useEffect(() => {
    setDetailsState({
      ...detailsState,
      description: hotelData?.description,
      policy: hotelData?.policy,
    });

    let tempList = contactStaticImage;

    hotelData?.contactBook?.map((item) => {
      const removeIndex = tempList.findIndex((ele) => ele?.name == item?.name);

      tempList.splice(removeIndex, 1);
    });

    setContactbook(hotelData?.contactBook);

    setOptions(tempList);
  }, [hotelData]);

  window.scrollTo(0, 0);

  return (
    <>
      <Header />
      <Sidenav />
      <div class="WrapperArea">
        <div class="WrapperBox">
          <div class="cust-details">
            <div class="CommonLinks">
              <Link to="#" onClick={() => window.history.back()}>
                <i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back
              </Link>
            </div>

            <div class="right-details">
              <p>
                Registration on {moment(hotelDetails?.createdAt).format("ll")}
              </p>
              {/* <div class="Actions">
                        
                        <label class="switch">
                            <span><strong>Active</strong></span>
                            <input type="checkbox" />
                            <span class="slider"></span> 
                        </label>
                    </div> */}
            </div>
          </div>

          <div class="Small-Wrapper">
            <div class="partner-details">
              <div class="row">
                <div class="col-12">
                  <div class="partner-details-body">
                    <div class="UserBox">
                      <div class="UserHead">
                        <figcaption>
                          <h3>{hotelData?.name}</h3>
                          <h6>
                            <span>
                              <img
                                src={require("../assets/image/location.png")}
                                alt=""
                              />
                            </span>{" "}
                            {hotelData?.address}
                          </h6>
                        </figcaption>
                      </div>

                      <div class="UserBody">
                        <figure>
                        <ImageFetcher imageUrl={hotelData?.image}/>
                        </figure>
                        <aside>
                          <p>
                            <label>Total Rooms </label>{" "}
                            <span> {hotelData?.totalRooms}</span>
                          </p>
                          <p>
                            <label>Phone Number </label>{" "}
                            <span> {hotelData?.phone}</span>
                          </p>
                          <p>
                            <label>Email</label>{" "}
                            <span> {hotelData?.email}</span>
                          </p>
                        </aside>
                        <article>
                          <p>
                            <a>{hotelData?.totalReview} Reviews</a>
                          </p>
                          <Link to={`/hotel-review/${id}`}>
                            View ratings &amp; reviews
                          </Link>
                        </article>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="partner-hotel-main">
                <ul>
                  <li>
                    <Link to={`/restaurant-profile/${id}`}>
                      <div class="partner-services">
                        <span class="icon">
                          <img
                            src={require("../assets/image/resume.png")}
                            alt=""
                          />
                        </span>
                        <p>Restaurant Profile</p>
                      </div>
                    </Link>
                  </li>

                  <li>
                    <Link to={`/restaurant-food/${id}`}>
                      <div class="partner-services">
                        <span class="icon">
                          <img
                            src={require("../assets/image/partner1.png")}
                            alt=""
                          />
                        </span>
                        <p>Food &amp; Beverages Management</p>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to={`/spa-management/${id}`}>
                      <div class="partner-services">
                        <span class="icon">
                          <img
                            src={require("../assets/image/partner2.png")}
                            alt=""
                          />
                        </span>
                        <p>Spa Services Management</p>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to={`/housekeeping-management/${id}`}>
                      <div class="partner-services">
                        <span class="icon">
                          <img
                            src={require("../assets/image/partner3.png")}
                            alt=""
                          />
                        </span>
                        <p>Housekeeping Management</p>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to={`/guests-management/${id}`}>
                      <div class="partner-services">
                        <span class="icon">
                          <img
                            src={require("../assets/image/partner4.png")}
                            alt=""
                          />
                        </span>
                        <p>Guests Stay Management</p>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to={`/hotel-room/${id}`}>
                      <div class="partner-services">
                        <span class="icon">
                          <img
                            src={require("../assets/image/partner5.png")}
                            alt=""
                          />
                        </span>
                        <p>Rooms Management</p>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to={`/hotel-wifi/${id}`}>
                      <div class="partner-services">
                        <span class="icon">
                          <img
                            src={require("../assets/image/partner6.png")}
                            alt=""
                          />
                        </span>
                        <p>Wifi Management</p>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to={`/hotel-trending/${id}`}>
                      <div class="partner-services">
                        <span class="icon">
                          <img
                            src={require("../assets/image/partner7.png")}
                            alt=""
                          />
                        </span>
                        <p>Trending Management</p>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to={`/hotel-activities/${id}`}>
                      <div class="partner-services">
                        <span class="icon">
                          <img
                            src={require("../assets/image/partner8.png")}
                            alt=""
                          />
                        </span>
                        <p>Activities</p>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to={`/hotel-coupons/${id}`}>
                      <div class="partner-services">
                        <span class="icon">
                          <img
                            src={require("../assets/image/partner9.png")}
                            alt=""
                          />
                        </span>
                        <p>Coupons Management</p>
                      </div>
                    </Link>
                  </li>

                  <li>
                    <Link to={`/roles-management/${id}`}>
                      <div class="partner-services">
                        <span class="icon">
                          <img
                            src={require("../assets/image/partner10.png")}
                            alt=""
                          />
                        </span>
                        <p>Roles Management</p>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="Small-Wrapper">
            <div class="StaticBoxArea">
              <div class="panel-group" id="accordion">
                <div class="panel">
                  <div
                    class="panel-heading"
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#collapse2"
                  >
                    <h4>Hotel Description:</h4>
                    <a class="" data-toggle="modal" data-target="#EditModal">
                      <i class="fa fa-pencil"></i>
                    </a>
                  </div>
                  <div id="collapse2" class="panel-collapse collapse">
                    <div class="panel-body">
                      <h2>Description</h2>
                      {/* <p>{hotelDetails?.description}</p> */}
                      <div className="form-group">
                        <textarea
                          className="form-control"
                          value={description}
                          name="description"
                          onChange={handleDetailsChange}
                          rows="5"
                        />
                        {detailError.description && !description && (
                          <p style={{ color: "red" }}>
                            {detailError.description}
                          </p>
                        )}

                        <br></br>
                        <a class="Button" onClick={handleUpdateDetails}>
                          Update
                        </a>
                      </div>
                      <h2>Policies</h2>
                      <div className="form-group">
                        <textarea
                          className="form-control"
                          rows="5"
                          value={policy}
                          name="policy"
                          onChange={handleDetailsChange}
                        />
                        {detailError.policy && !policy && (
                          <p style={{ color: "red" }}>{detailError.policy}</p>
                        )}

                        <br></br>
                        <a class="Button" onClick={handleUpdateDetails}>
                          Update
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel">
                  <div
                    class="panel-heading"
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#collapse3"
                  >
                    <h4>Hotel Contact Book:</h4>
                    {hotelData?.contactBook?.length < 5 && (
                      <a
                        class=""
                        data-toggle="modal"
                        onClick={() => handleAddModalShow("add")}
                      >
                        <i class="fa fa-plus"></i>{" "}
                      </a>
                    )}
                  </div>
                  <div id="collapse3" class="panel-collapse collapse">
                    <div class="panel-body">
                      <ul>
                        {hotelData?.contactBook?.map((item, i) => (
                          <li>
                            <div class="panel-item">
                              <figure>
                              <ImageFetcher imageUrl={item?.icon}/>
                              </figure>
                              <aside>
                                <h2>{item?.name}</h2>
                                <p>
                                  <span>
                                    <img src={Phone} alt="" />
                                  </span>{" "}
                                  {item?.number}
                                </p>
                              </aside>
                              <a
                                class=""
                                onClick={() =>
                                  handleAddModalShow("edit", item, i)
                                }
                              >
                                <i class="fa fa-pencil"></i>
                              </a>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="Small-Wrapper">
            <div class="TitleBox">
              <h4 class="Title">Payment Account:</h4>
              <a
                class="Green"
                data-toggle="modal"
                onClick={() =>
                  handleAccountModalShow("edit", hotelData?.accountDetails)
                }
              >
                <i class="fa fa-pencil"></i>
              </a>
            </div>

            <div class="UserBox">
              <div class="UserBody">
                <aside>
                  <p>
                    <label>Account Name</label>{" "}
                    <span> {hotelData?.accountDetails?.actName}</span>
                  </p>
                  <p>
                    <label>Account Number </label>{" "}
                    <span> {hotelData?.accountDetails?.actNumber}</span>
                  </p>
                  <p>
                    <label>IFSC code</label>{" "}
                    <span> {hotelData?.accountDetails?.ifscCode}</span>
                  </p>
                  <p>
                    <label>Bank Name</label>{" "}
                    <span> {hotelData?.accountDetails?.bankName}</span>
                  </p>
                  <p>
                    <label>Branch</label>{" "}
                    <span>{hotelData?.accountDetails?.branch}</span>
                  </p>
                </aside>
                <article>
                  <Link to={`/hotel-transaction/${id}`}>View History</Link>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={AddModal} onHide={handleAddModalClose} className="ModalBox ">
        <Modal.Body>
          <div class="Category">
            <a onClick={handleAddModalClose} class="CloseModal">
              ×
            </a>
            {Type == "edit" ? <h3>Update Contact</h3> : <h3>Add Contact</h3>}
            <div class="form-group">
              {icon && (
                <>
                  <label> Icon</label>
                  <div class="UploadBox">
                    {/* <div class="Upload">
                  <i class="fa fa-upload"></i> <span>Upload Icon</span>
                  <input
                    type="file"
                    onClick={(e) => (e.target.value = null)}
                    onChange={(e) => handleUpload(e)}
                    class="form-control"
                  />
                </div> */}
                    <div className="UploadIcon">
                    <ImageFetcher imageUrl={icon}/>
                    </div>
                  </div>
                </>
              )}

              {errorMsg.icon && !icon && (
                <p style={{ color: "red" }}>{errorMsg.icon}</p>
              )}
            </div>
            <div class="form-group">
              <label>Service Name</label>

              <select
                className="form-control"
                name="name"
                disabled={Type == "edit" ? true : false}
                onChange={handleChange}
                value={name}
              >
                <option>Select</option>
                {Type == "add"
                  ? options?.map((item) => (
                      <option value={item?.name}>{item?.name}</option>
                    ))
                  : contactStaticImage?.map((item) => (
                      <option value={item?.name}>{item?.name}</option>
                    ))}
              </select>
              {errorMsg.name && !name && (
                <p style={{ color: "red" }}>{errorMsg.name}</p>
              )}
            </div>

            <div class="form-group">
              <label class="CheckBox">
                Intercom
                <input
                  type="checkbox"
                  checked={intercom}
                  name="intercom"
                  onChange={handleChange}
                />
                <span class="checkmark"></span>
              </label>
            </div>

            <div class="form-group">
              <label>Contact Number</label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter Number "
                value={number}
                name="number"
                onChange={handleChange}
              />
              {errorMsg.number && !number && (
                <p style={{ color: "red" }}>{errorMsg.number}</p>
              )}
            </div>

            {Type == "add" ? (
              <button class="Button" onClick={handleSubmit}>
                Add
              </button>
            ) : (
              <button class="Button" onClick={handleEdit}>
                Update
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={AccountModal}
        onHide={handleAccountModalClose}
        className="ModalBox "
      >
        <Modal.Body>
          <div class="Category">
            <a onClick={handleAccountModalClose} class="CloseModal">
              ×
            </a>
            {Type == "edit" ? (
              <h3>Update Account Details</h3>
            ) : (
              <h3>Add Account Details</h3>
            )}
            <div class="form-group">
              <label> Account Holder Name</label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter account holder name "
                value={accountHolderName}
                name="accountHolderName"
                onChange={handleAccountChange}
              />

              {accountError.accountHolderName && !accountHolderName && (
                <p style={{ color: "red" }}>{accountError.accountHolderName}</p>
              )}
            </div>
            <div class="form-group">
              <label>Account Number</label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter account number "
                value={accountNumber}
                name="accountNumber"
                onChange={handleAccountChange}
              />
              {accountError.accountNumber && !accountNumber && (
                <p style={{ color: "red" }}>{accountError.accountNumber}</p>
              )}
            </div>
            <div class="form-group">
              <label>IFSC Code</label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter ifsc code "
                value={ifsc}
                name="ifsc"
                onChange={handleAccountChange}
              />
              {accountError.ifsc && (
                <p style={{ color: "red" }}>{accountError.ifsc}</p>
              )}
            </div>
            <div class="form-group">
              <label>Bank Name</label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter bank name "
                value={bankName}
                name="bankName"
                onChange={handleAccountChange}
              />
              {accountError.bankName && !bankName && (
                <p style={{ color: "red" }}>{accountError.bankName}</p>
              )}
            </div>
            <div class="form-group">
              <label>Branch Name</label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter branch name "
                value={branch}
                name="branch"
                onChange={handleAccountChange}
              />
              {accountError.branch && !branch && (
                <p style={{ color: "red" }}>{accountError.branch}</p>
              )}
            </div>

            {Type == "add" ? (
              <button class="Button" onClick={handleAccountSubmit}>
                Add
              </button>
            ) : (
              <button class="Button" onClick={handleAccountSubmit}>
                Update
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default HotelDetails;
