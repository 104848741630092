import { dashboardContant } from "../actionTypes";

import axios from "../../axiosInstance"
import { baseUrl } from "../../config/config";
import { isLoggedIn } from "../../utils";




// DASHBOARD

export const dashboardInitiate=() =>({
    type:dashboardContant.DASHBOARD_INITIATE

})

export const dashboardSuccess=(data) =>({
    type:dashboardContant.DASHBOARD_SUCCESS,
    payload:data

})

export const dashboardFailure=(data) =>({
    type:dashboardContant.DASHBOARD_FAILURE,
    payload : data

})


export const  DashboardAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(dashboardInitiate());
        const response = await axios.post(`${baseUrl}/admin/dashboard`, payload,{
            headers: {authorization:`${token}`}})

        if (response.status == 200) {
          dispatch(dashboardSuccess(response.data));
        } else {
          dispatch(dashboardFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(dashboardFailure(err));
        return err;
      }
  




}



//  NOTIFICATION LIST


export const notificationListInitiate=() =>({
    type:dashboardContant.NOTIFICATION_LIST_INITIATE

})

export const notificationListSuccess=(data) =>({
    type:dashboardContant.NOTIFICATION_LIST_SUCCESS,
    payload:data

})

export const notificationListFailure=(data) =>({
    type:dashboardContant.NOTIFICATION_LIST_FAILURE,
    payload : data

})


export const  NotificationListAction=(payload)=>{
    const token =isLoggedIn('projectAdminData');

    return dispatch => {
        dispatch(notificationListInitiate())
        return new Promise((resolve, reject) =>
            axios.get(`${baseUrl}/projectAdmin/userNotificationListForProject`,{
                headers: {Authorization:`${token}`}})
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {

                        dispatch(notificationListSuccess(data))

                    }
                    else {
                        dispatch(notificationListFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(notificationListFailure(err))
                    reject(err);
                })

        );

    }

}



//  NOTIFICATION LIST


export const notificationReadInitiate=() =>({
    type:dashboardContant.NOTIFICATION_READ_INITIATE

})

export const notificationReadSuccess=(data) =>({
    type:dashboardContant.NOTIFICATION_READ_SUCCESS,
    payload:data

})

export const notificationReadFailure=(data) =>({
    type:dashboardContant.NOTIFICATION_READ_FAILURE,
    payload : data

})


export const  NotificationReadAction=(payload)=>{
    const token =isLoggedIn('projectAdminData');

    return dispatch => {
        dispatch(notificationReadInitiate())
        return new Promise((resolve, reject) =>
            axios.get(`${baseUrl}/projectAdmin/allNotificationRead`,{
                headers: {Authorization:`${token}`}})
                .then(response => {
                    const data = response.data
                    if (data && data.status === 200) {

                        dispatch(notificationReadSuccess(data))

                    }
                    else {
                        dispatch(notificationReadFailure(data))
                    }
                    resolve(data)
                }).catch(err => {
                    dispatch(notificationReadFailure(err))
                    reject(err);
                })

        );

    }

}



//  GET SPLIT PERCENTAGE


export const getSplitpercentageInitiate=() => ({
    type:dashboardContant.GET_SPLITPERCENTAGE_INITIATE

})

export const getSplitpercentageSuccess=(data) =>({
    type:dashboardContant.GET_SPLITPERCENTAGE_SUCCESS,
    payload:data

})

export const getSplitpercentageFailure=(data) =>({
    type:dashboardContant.GET_SPLITPERCENTAGE_FAILURE,
    payload : data

})


export const  GetSplitpercentageAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(getSplitpercentageInitiate());
        const response = await axios.get(`${baseUrl}/admin/getsplitpercentage`,{
            headers: {authorization:`${token}`}})

        if (response.status == 200) {
          dispatch(getSplitpercentageSuccess(response.data));
        } else {
          dispatch(getSplitpercentageFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(getSplitpercentageFailure(err));
        return err;
      }

}


// EDIT SPLITPERCENTAGE ADMIN AND ALL

export const editSplitpercentageInitiate=() => ({
    type:dashboardContant.EDIT_SPLITPERCENTAGE_INITIATE

})

export const editSplitpercentageSuccess=(data) =>({
    type:dashboardContant.EDIT_SPLITPERCENTAGE_SUCCESS,
    payload:data

})

export const editSplitpercentageFailure=(data) =>({
    type:dashboardContant.EDIT_SPLITPERCENTAGE_FAILURE,
    payload : data

})


export const  EditSplitpercentageAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        console.log("SPLIT INPUT ===>>>", payload);
        dispatch(editSplitpercentageInitiate());
        const response = await axios.put(`${baseUrl}/admin/setsplitpercentageforall`, payload,{
            headers: {authorization:`${token}`}})

        if (response.status == 200) {
          dispatch(editSplitpercentageSuccess(response.data));
        } else {
          dispatch(editSplitpercentageFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(editSplitpercentageFailure(err));
        return err;
      }
}
