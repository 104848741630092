import React, { useEffect } from 'react'
import Header from './header'
import Sidenav from './sidenav'

import Modal from "react-bootstrap/Modal";
import { Link, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AddedTrendingListAction, RemoveTrendingAction } from '../redux/actions/trendingAction'
import moment from 'moment'
import { useState } from 'react'
import Loader from './loader';
import ImageFetcher from './ImageFetcher'

const HotelTrending = () => {
    const dispatch = useDispatch()
    const {id} = useParams()
    const [search, setSearch]= useState('')
    const hotelDetails =  JSON.parse(window.localStorage.getItem("hotelDetails"))


    const {addedTrendingList,loader} = useSelector((state)=>state.trendingReducer)
    const [trendingItemId, setTrendingItemId] = useState('')
    let hotelData = JSON.parse(localStorage.getItem("hotel"));



    const [DeleteModal, setDelete] = useState(false);
    const handleDeleteModalClose = () => setDelete(false);
    const handleDeleteModalShow = (id) => {
      setTrendingItemId(id);
      setDelete(true);
    };
  
    useEffect(()=>{
        dispatch(AddedTrendingListAction({hotelId:id}))

    },[])


    const handleDelete = () => {
        dispatch(RemoveTrendingAction({ id: trendingItemId })).then((res) => {
          if (res.data.success === true) {
            setDelete(false);
            dispatch(AddedTrendingListAction({hotelId:id}))
          }
        });
      };



      const handleSearch=(e)=>{
        dispatch(AddedTrendingListAction({search: e.target.value, hotelId: id}))
        setSearch(e.target.value)
    
    
      }
    

      window.scrollTo(0, 0)

  return (
    <div>
      <Header />
      <Sidenav />

      <div class="WrapperArea">
        <div class="WrapperBox">
        <div class="HotelLocation HotelLocation2">
            <div class="back-btn">
              <Link to={`/hotel-details/${id}`}>
                <i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back{" "}
              </Link>
            </div>

            <aside>
              <h3>{hotelDetails?.name}</h3>
              <p>
                <span>
                  <i class="fa fa-map-marker"></i>
                </span>{" "}
                {hotelDetails?.address}
              </p>
            </aside>
          </div>{" "}
           

            <div class="Small-Wrapper">
                <div class="NewTitleBox">
                    <h4>Trending Management</h4>
                    <Link to={`/trending-add/${id}`}>Add Trending</Link>
                </div>
                
                <div class="Filter">  
                    <div class="form-group">
                        <label>Search</label>
                        <input type="text" value={search} onChange={handleSearch} class="form-control" placeholder="Search by item name" />
                    </div>   
                </div>
                {loader? <Loader />:

                <div class="TrendingArea">
                    {addedTrendingList?.map((item)=>
                        <div class="TrendingBox">
                        <article>
                            <figure>
                            <ImageFetcher imageUrl={item?.image}/>
                            </figure>
                            <figcaption>
                                <h3>{item?.name}</h3>
                                {/* <h4>
                                    <span><i class="fa fa-star"></i> 4.5</span> 
                                    <a>( 34 Reviews )</a> 
                                </h4> */}
                                {item?.endDate ? (
                          <p>
                            {" "}
                            {moment(item?.startDate, "HH:mm").format("h:mm A")}
                            &nbsp; to{" "}
                            {moment(item?.endDate, "HH:mm").format("h:mm A")}
                          </p>
                        ) : (
                          <p>Duration {item?.duration}</p>
                        )}
                            </figcaption>
                        </article>
                        <aside>
                            <h5>{item?.price} INR</h5>
                            <h6>+Taxes</h6>
                            <button type='button' onClick={()=> handleDeleteModalShow(item?._id)}>Remove From Trending</button>
                        </aside>
                    </div>
                    )}
                
                    
                </div>}
               
            </div>

        </div> 
    </div>
    <Modal
        show={DeleteModal}
        onHide={handleDeleteModalClose}
        className="ModalBox "
      >
        <Modal.Body>
          <div className="Decline">
            <button className="CloseModal" onClick={handleDeleteModalClose}>
              ×
            </button>
            <h3>Delete</h3>
            <p>Are you sure you want to Remove this item of Trending?</p>
            <h4>
              <a onClick={handleDeleteModalClose} className="Cancel">
                Cancel
              </a>
              <a onClick={handleDelete}>Delete</a>
            </h4>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default HotelTrending