
import React, { useState, useEffect, useCallback } from 'react';
import { GoogleMap, useJsApiLoader, MarkerF } from '@react-google-maps/api';
import {mapAPI_KEY } from "../config/config";

const DefaultLocation = { lat: 28.535517, lng: 77.391029 };
const DefaultZoom = 15;
const containerStyle = {
    width: '100%',
    height: '350px'
};

const libraries = ['places'];

const Map = ({ iState, updateState }) => {
    const [location, setLocation] = useState(DefaultLocation);
    const [zoom, setZoom] = useState(DefaultZoom);
    const [isLoad, setIsLoad] = useState(false);
    const [map, setMap] = useState(null);

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: mapAPI_KEY, 
        libraries
    });

    const fetchAddress = useCallback((lat, lng) => {
        fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&result_type=street_address&key=${mapAPI_KEY}`) 
            .then(response => response.json())
            .then(data => {
                console.log('Geocode API response:', data);
                if (data.results && data.results.length > 0) {
                    updateState(prev => ({
                        ...prev,
                        address: data.results[0].formatted_address || "",
                        pinCode: data.results[0].address_components.find(comp => comp.types.includes("postal_code"))?.long_name || ""
                    }));
                } else {
                    console.log("No results found");
                    updateState(prev => ({
                        ...prev,
                        address: "",
                        pinCode: ""
                    }));
                }
            })
            .catch(error => {
                console.error('Error fetching address:', error);
                updateState(prev => ({
                    ...prev,
                    address: "",
                    pinCode: ""
                }));
            });
    }, [updateState]);

    useEffect(() => {
        if (iState?.lat) {
            setZoom(17);
            setLocation({ lat: iState.lat, lng: iState.long });
        }

        setTimeout(() => {
            setIsLoad(true);
        }, 1000);
    }, [iState]);

    const handleMapClick = (event) => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        setLocation({ lat, lng });
        fetchAddress(lat, lng);
        updateState(prev => ({ ...prev, lat, long: lng }));
    };

    const handleOnLoad = (mapInstance) => {
        setMap(mapInstance);
    };

    const handleZoomChanged = () => {
        if (map) {
            setZoom(map.getZoom());
        }
    };

    if (!isLoad || !isLoaded) {
        return <div>Loading...</div>;
    }

    return (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={location}
            zoom={zoom}
            onClick={handleMapClick}
            onZoomChanged={handleZoomChanged}
            onLoad={handleOnLoad}
        >
            <MarkerF
                position={location}
                draggable={true}
                onDragEnd={(event) => {
                    const lat = event.latLng.lat();
                    const lng = event.latLng.lng();
                    setLocation({ lat, lng });
                    fetchAddress(lat, lng);
                    updateState(prev => ({ ...prev, lat, long: lng }));
                }}
            />
        </GoogleMap>
    );
};

export default Map;