import React, { useEffect, useState } from 'react'
import Header from './header'
import Sidenav from './sidenav';

import Modal from "react-bootstrap/Modal";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { AnalyticListAction } from '../redux/actions/financeAction';
import moment from 'moment/moment';
import { toast } from 'react-toastify';
import NoDataFound from "./noDataFound";
import Pagination from "react-js-pagination";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx"
import Loader from './loader';


const Analytics = () => {
    const dispatch = useDispatch()
    const [activePage, setActivePage] = useState(1);

    const {analyticList,loader} =useSelector((state)=>state.financeReducer)

    const [userId, setUserId] =useState('')
    const [filterData, setFilterData] = useState({
        search: "",
        fromDate: "",
        toDate: "",
        timeFrame:"",
        page:1,
        status:""
      });
    






    useEffect(()=>{
        dispatch(AnalyticListAction())

    },[])




    
      const handleFilter = (e) => {
        dispatch(AnalyticListAction({search:filterData?.search, fromDate: filterData?.fromDate, toDate: filterData?.toDate, timeFrame:filterData?.timeFrame, status:filterData?.status}))

      };
    
      const handleRefresh = (e) => {
        setFilterData({...filterData, search: "", fromDate: "",toDate:"", timeFrame:'' , status:""});
    
        dispatch(AnalyticListAction())
    };

    const handleSearch = (e) => {
        dispatch(AnalyticListAction({search:e.target.value}))
        setFilterData((prev) => ({ ...prev, search: e.target.value }));
      };
    
    
      const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        filterData.page = pageNumber;
        dispatch(AnalyticListAction({search:filterData?.search, fromDate: filterData?.fromDate, toDate: filterData?.toDate, timeFrame:filterData?.timeFrame, status:filterData?.status, page:pageNumber}))


      };







      const downloadRowData = (rowData, fileName) => {
        const fileType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        let result = rowData?.map((item, i) => {
          return {
            "Sr. No": i + 1,
            "Hotel Id": item?.hotelId,
            "Hotel Name": item?.name,
            "Service Package Revenune": 0,
            "No. of Guests Checkin": item?.hasOwnProperty('guestCount')? (item?.guestCount?.count) :"0",
          "Hotel Revenue on app": 0,
            "No. of Employee": item?.hasOwnProperty('staffCount')? (item?.staffCount?.count) :"0",
          };
        });
    
        // Combine the header row and row data into a single string
        const myJsonString = JSON.stringify(result);
    
        const ws = XLSX.utils.json_to_sheet(result);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, "analytic-record.xlsx");
      };


  return (
    <>
    <Header />
    <Sidenav />

    <div class="WrapperArea">
        <div class="WrapperBox">
            <div class="Small-Wrapper">
                <div class="TitleBox">
                    <h4 class="Title">Analytics</h4>

                    <ul>
                        <li><a class="add" onClick={()=>downloadRowData(analyticList?.[0]?.data)}>Export to Excel</a></li>
                    </ul>
                </div>

                <div class="Filter">
                    <div class="form-group">
                        <label>Search</label>
                        <input type="text"onChange={handleSearch}  value={filterData?.search} class="form-control" placeholder="Search by hotel name or Id" />
                    </div>
                   
                    {/* <div class="form-group">
                        <label>From Date</label>
                        <input type="date" value={filterData?.fromDate}  onChange={(e)=>setFilterData({...filterData, fromDate:e.target.value})} class="form-control" />
                    </div>
                    <div class="form-group">
                        <label>To Date</label>
                        <input type="date" disabled={!filterData?.fromDate} value={filterData?.toDate} min={filterData?.fromDate} onChange={(e)=> setFilterData({...filterData, toDate:e.target.value})} class="form-control" />
                    </div> 
                    <div class="form-group">
                        <label>Timeframe</label>
                        <select value={filterData?.timeFrame} onChange={(e)=> setFilterData({...filterData, timeFrame:e.target.value})} class="form-control">
                            <option value="">--Select--</option>
                            <option value="Today">Today</option>
                            <option value="Week">This Week</option>
                            <option value="Month">This Month</option>
                        </select>
                    </div>  
                    <div class="form-group">
                        <label>&nbsp;</label>
                        <button onClick={handleFilter} class="Button">Apply</button>
                        <button onClick={handleRefresh} class="Button Cancel"><i class="fa fa-refresh"></i></button>
                    </div> */}
                </div>


                
                {loader? <Loader />:

                <div class="TableList">
                    <table style= {{width: "120%"}}>
                        <thead>
                        <tr>
                                <th>Hotel Id</th>
                                <th>Hotel Name</th>
                                <th>Service Package Revenue</th>
                                <th>Number of Guests Check In</th>
                                <th>Hotel Revenue on App</th>
                                <th>Number of Employee Users</th>
                            </tr>
                        </thead>
                        <tbody>
                            {analyticList?.[0]?.data?.map((item, i)=>
                                                        <tr>
                                                        <td>{item?.hotelId}</td>
                                                        <td>{item?.name}</td>
                                                        <td>{item?.hasOwnProperty('couponRevenue')? (item?.couponRevenue?.totalAmount?.toFixed()) :"0"}</td>

                                                        <td>{item?.hasOwnProperty('guestCount')? (item?.guestCount?.count) :"0"}</td>
                                                        <td>{item?.hasOwnProperty('hotelRevenue')? (item?.hotelRevenue?.hotelRevenue?.toFixed()) :"0"}</td>

                                                        <td>{item?.hasOwnProperty('staffCount')? (item?.staffCount?.count) :"0"}</td>



                                                        
                                                    </tr>
                            
                            )}
                            
                            
                            
                        </tbody>
                    </table>
                </div>}
                {loader ? (
            ""
          ) : analyticList?.[0]?.data ? (
            ""
          ) : (
            <NoDataFound />
          )}


          {analyticList?.[0]?.metadata?.[0]?.total > 0 ? (
            <div className="Pagination">
              <Pagination
                activePage={activePage}
                itemsCountPerPage={10}
                totalItemsCount={analyticList?.[0]?.metadata?.[0]?.total}
                pageRangeDisplayed={5}
                onChange={(e) => handlePageChange(e)}
              />
            </div>
          ) : (
            ""
          )}
            </div>
        </div>
    </div>

   
    


    </>
  )
}

export default Analytics