import React from 'react'
import Header from './header'
import Sidenav from './sidenav'
import { useLocation, useParams } from 'react-router-dom'
import moment from 'moment';
import { Link } from 'react-router-dom';

const GuestsCheckoutApprovedMinibar = () => {

    const {state} = useLocation()
    const{id} = useParams()


  return (
    <div>
        <Header />
        <Sidenav />

        <div class="WrapperArea">
        <div class="WrapperBox">
   
              
            <div class="Small-Wrapper">
            <div class="NewTitleBox">
                            <div className='arrow-left'>
                            <Link to={`/guests-checkout-request/${id}`}>
                            <i class="fa fa-arrow-left arrow-back" aria-hidden="true"></i>
            </Link>
            <h4>Mini Bar Details </h4> 

            </div>
          </div>                
                <div class="GuestUserBox">
                    {/* <figure>
                        <img src="images/Avatar-2.png" />
                    </figure> */}
                    <figcaption>
                        <div class="GuestUserHead"> 
                            <aside>
                                <p>
                                    <label>Room Number </label>
                                    {state?.room?.map((ele) => (
                          <strong>{ele?.fullNumber}{state?.room?.length >1?",":" "}</strong>
                          
                        ))}
                                </p>
                                <p>
                                {state?.miniBarTotalAmount &&<label>Total Spend on stay bar</label>}
                                {state?.miniBarTotalAmount &&<span>{state?.miniBarTotalAmount} INR</span>}
                                </p>
                                {/* <p>
                                    <label>Total Items</label>
                                    <span>20</span>
                                </p> */}
                            </aside>
                            <aside> 
                                <p>
                                    <label>Confirmed by</label>
                                    <span>{state?.luggagePickBy?.[0]?.name}</span>
                                </p>
                                <p>
                                    <label>Check In</label>
                                    <span>{moment(state?.guestId?.[0]?.checkInDate).format('ll')}</span>
                                </p>
                                <p>
                                    <label>Checkout</label>
                                    <span>{moment(state?.guestId?.[0]?.checkOutDate).format('ll')}</span>
                                </p>
                            </aside> 
                        </div> 
                    </figcaption>
                </div>
                {state?.miniBarItem?.filter((mini)=>mini?.items?.length >0)?.map((item)=>
                
                <div class="RoomMiniBar">
                    <article>
                        <h5>Room Number : {item?.room[0]?.fullNumber}</h5>
                        <ul>
                            {item?.items?.map((ele)=>
                                                        <li>

                            <aside>
                            {/* <label class="CheckBox">
                                <input type="checkbox" checked="" />
                                <span class="checkmark"></span>
                            </label> */}
                            <p>{ele?.name} </p>
                            <input disabled={true} type="text" value={ele?.quantity} />
                            <p>{ele?.price} INR / per item</p>
                        </aside>
                    </li>
                            )}
                              
                        </ul>
                    </article>



                </div>
                )}


            </div>
        
            

        </div> 
    </div>
    </div>
  )
}

export default GuestsCheckoutApprovedMinibar