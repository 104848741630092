import React, { useEffect } from "react";
import Header from "./header";
import Sidenav from "./sidenav";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  ApproveExtendCheckoutAction,
  ExtendCheckRequestAction,
} from "../redux/actions/guestAction";
import moment from "moment/moment";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import commonCrypto from "../utils";

import NoDataFound from "./noDataFound";
import ImageFetcher from './ImageFetcher'
import { toast } from "react-toastify";

const initialApprove = {
  id: "",
  price: "",
};

const GuestsExtendedCheckout = () => {
  const [search, setSearch] =useState('')


  const hotelDetails =  JSON.parse(window.localStorage.getItem("hotelDetails"))

  const dispatch = useDispatch();

  const {hotelId} = useParams()
  const { extendCheckinList, loader } = useSelector((state) => state.guestReducer);

  const [iState, updateState] = useState(initialApprove);
  const { id, price } = iState;

  const [extendDays,setExtendDays]=useState('')


  useEffect(() => {
    dispatch(ExtendCheckRequestAction({hotelId}));
  }, []);

  const [ApproveModal, setApprove] = useState(false);
  const handleApproveModalClose = () => setApprove(false);
  const handleApproveModalShow = (item) => {
    updateState({ ...iState, id: item?._id });
    const numofHours =(moment(item?.newCheckOutDate)?.diff(item?.oldCheckOutDate,'hours'))
    setExtendDays((parseInt(numofHours)/24).toFixed(0))

    setApprove(true);
  };

  const [SuccessModal, setSuccess] = useState(false);
  const handleSuccessModalClose = () => setSuccess(false);
  const handleSuccessModalShow = (id) => {
    setSuccess(true);
  };


  const [ConfirmModal, setConfirm] = useState(false);
  const handleConfirmModalClose = () => setConfirm(false);
  const handleConfirmModalShow = (id) => {
    if(price){
    setConfirm(true);
    }
  };


  const handleApprove = (type) => {
    let data;
    if (type == "approve") {
      data = { _id: id, price: price,hotelId };
    } else {
      data = { _id: id,hotelId };
    }
    dispatch(ApproveExtendCheckoutAction(data)).then((res) => {
      if (res.status >= "200" && res.status <= 300) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setSuccess(true);
        setConfirm(false)
        dispatch(ExtendCheckRequestAction(hotelId));

        updateState({ price: "", id: "" });
        setApprove(false);
      }
    });
  };

  const handleSearch = (e) => {
    dispatch(ExtendCheckRequestAction({search:e.target.value, hotelId}))
    setSearch(e.target.value) 
  };


  return (
    <div>
      <Header />
      <Sidenav />
      <div class="WrapperArea">
        <div class="WrapperBox">
        <div class="HotelLocation HotelLocation2">
            <div class="back-btn">
            <Link to={`/guests-management/${hotelId}`}>
                <i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back{" "}
              </Link>
            </div>
            
            <aside>
              <h3>{hotelDetails?.name}</h3>
              <p>
                <span>
                  <i class="fa fa-map-marker"></i>
                </span>{" "}
                {hotelDetails?.address}
              </p>
            </aside>
</div>
          
          <div class="Small-Wrapper">
            <div class="NewTitleBox">
              <div className="arrow-left">
                {/* <Link to={`/guests-management/${hotelId}`}>
                  <i class="fa fa-arrow-left arrow-back" aria-hidden="true"></i>
                </Link> */}

                <h4>Extended Checkout Requests</h4>
              </div>
            </div>

            <div class="Filter">
              <div class="form-group">
                <label>Search</label>
                <input
                  class="form-control"
                  placeholder="Search.."

                  type="text"onChange={handleSearch}  value={search}

                />
              </div>
              {/* <div class="form-group">
                <label>Room Number</label>
                <select class="form-control">
                  <option>Select Room Number</option>
                  <option>RM103</option>
                </select>
              </div> */}
            </div>
            <div class="GuestAccordian">
              <div id="accordion">
                {extendCheckinList?.map((item, i) => (
                  <div class="card">
                    <div
                      class="card-header collapsed"
                      data-toggle="collapse"
                      href={`#collapse${i}`}
                      aria-expanded="false"
                    >
                      <h5>
                        Rooms :{" "}
                        {item?.room?.map((ele) => (
                          <strong>{ele?.fullNumber} </strong>
                        ))}{" "}
                      </h5>
                      <h6>
                        <span>
                          {" "}
                          {(item?.extendCheckOut?.rooms?.length > 0)? moment(item?.checkOutDate).format('ll'):moment(item?.extendCheckOut?.newCheckOutDate).format('ll') }                        </span>{" "}
                      </h6>
                    </div>

                    <div
                      id={`collapse${i}`}
                      class="collapse"
                      data-parent="#accordion"
                    >
                            <div className="approveRequestBtn">
                        <button
                          style={{
                            position: "relative",
                            marginRight: "10px ",
                            marginTop: "15px",
                          }}
                          class="Approve "
                          onClick={() => handleApprove("skip",item)}
                        >
                          Complementary
                        </button>

                        <button
                          style={{
                            position: "relative",
                            marginRight: "10px ",
                            marginTop: "15px",
                            backgroundColor: "#146eab",
                          }}
                          class="Approve"
                          onClick={() => handleApproveModalShow(item)}
                        >
                          Approve Request
                        </button>
                      </div>
                      <div class="card-body" style={{ borderTop: "none" }}>
                        {item?.guestId?.[0]?.co_occupants?.map((ele, i) => 
                        
                        {
                          let cryptData= commonCrypto.decrypt(ele.userId?.[0]?.kyc_details?.docData)
                          let GuestId= item?.guestId?.[0]?.guestId


                          return (
                            <div class="GuestUserBox">
                              <figure>
                            <ImageFetcher imageUrl={cryptData !== null ? cryptData?.kycSelfie : ""}/>{" "}
                              </figure>
                              <figcaption>
                                <div class="GuestUserHead">
                                  <aside>
                                    <p>
                                      <label>Name </label>
                                      <span>
                                        <span>
                                          {cryptData !== null
                                            ? cryptData?.name
                                            : ele?.name}
                                        </span>
                                      </span>
                                    </p>
                                    <p>
                                      <label>Guest ID</label>
                                      <span>{GuestId}</span>
                                    </p>
                                    {ele?.userId &&
                                      ele?.userId?.length > 0 && (
                                        <p>
                                          <label>Gender</label>
                                          <span>
                                            {cryptData !== null
                                              ? cryptData?.gender
                                                  ?.charAt(0)
                                                  ?.toUpperCase() +
                                                cryptData?.gender?.slice(1)
                                              : ele?.gender
                                                  ?.charAt(0)
                                                  ?.toUpperCase() +
                                                ele?.gender?.slice(1)}
                                          </span>
                                        </p>
                                      )}
                                    <p>
                                      <label>Date of Birth</label>
                                      <span>
                                        {cryptData !== null
                                          ? cryptData?.dob
                                            ? moment(cryptData?.dob).format(
                                                "ll"
                                              )
                                            : "NA"
                                          : moment(ele?.dob).format("ll")}
                                      </span>
                                    </p>
                                    <p>
                                      <label>Age</label>
                                      <span>
                                        {cryptData !== null
                                          ? cryptData?.age
                                            ? cryptData?.age
                                            : "NA"
                                          : ele?.age ? ele?.age:"NA"}
                                      </span>
                                    </p>
                                    {ele?.type == "adult" &&
                                    cryptData?.docFrontImg && (
                                      <div class="card-guest">
                                        <span>
                                        <ImageFetcher imageUrl={cryptData?.docFrontImg}/>
                                        </span>
                                        <span>
                                        <ImageFetcher imageUrl={cryptData?.docFrontImg}/>
                                        </span>
                                      </div>
                                    )}
                                  </aside>
                                  <aside>
                                  {ele?.type !=="kid" &&

                                    <p>
                                      <label>Email</label>
                                      {cryptData !== null
                                        ? cryptData?.email == "" ||
                                          cryptData?.email == "false"
                                          ? "NA"
                                          : cryptData?.email
                                        : ele?.email == "" ||
                                          ele?.email == "false"
                                        ? "NA"
                                        : ele?.email}{" "}
                                    </p>}
                                    <p>
                                      <label>Phone Number</label>
                                      <span>
                                        {ele?.type == "kid"
                                          ? "NA"
                                          : cryptData !== null
                                          ? cryptData?.phone
                                          : ele?.phone}
                                      </span>
                                    </p>
                                    {ele?.type !=="kid" &&

                                    <p>
                                      <label>Address</label>
                                      <span>
                                        {cryptData !== null
                                          ? cryptData?.address
                                            ? cryptData?.address
                                            : "NA"
                                          : ele?.address?ele?.address:"NA"}
                                      </span>
                                    </p>}
                                    <p>
                                      <label>Verified with Document</label>
                                      <span>
                                        {ele?.type == "kid"
                                          ? "NA"
                                          : cryptData !== null
                                          ? item?.kyc_details
                                            ? ele?.kyc_details?.docType
                                            : ele.userId?.[0]?.kyc_details
                                                ?.docType
                                          : "NA"}
                                      </span>
                                    </p>
                                    <p>
                                      <label>Document Number</label>
                                      <span>
                                        {" "}
                                        {ele?.type == "kid"
                                          ? "NA"
                                          : cryptData !== null
                                          ? cryptData?.docNumber
                                          : "NA"}
                                      </span>
                                    </p>
                                  </aside>
                                </div>
                              </figcaption>
                            </div>
                          );
                        }
                          
                      )}
                      </div>
                      {/* <div class="card-body" style={{ borderTop: "none" }}>
                        <div class="GuestUserBox">
                          <figure>
                            <img src="images/Avatar-2.png" />
                          </figure>
                          <figcaption>
                            <div class="GuestUserHead">
                              <aside>
                                <p>
                                  <label>Name </label>
                                  <span>Riya Jain</span>
                                </p>
                                <p>
                                  <label>Guest ID</label>
                                  <span>GUEST1234</span>
                                </p>
                                <p>
                                  <label>Gender</label>
                                  <span>Female</span>
                                </p>
                                <p>
                                  <label>Date of Birth</label>
                                  <span>20 October 1994</span>
                                </p>
                                <p>
                                  <label>Age</label>
                                  <span>28 Years</span>
                                </p>
                              </aside>
                              <aside>
                                <p>
                                  <label>Email</label>
                                  <span>simmi@gmail.com</span>
                                </p>
                                <p>
                                  <label>Phone Number</label>
                                  <span>97679669696</span>
                                </p>
                                <p>
                                  <label>Address</label>
                                  <span>3383, Goreway Drive, Brampton, ON</span>
                                </p>
                                <p>
                                  <label>Verified with Document</label>
                                  <span>Passport</span>
                                </p>
                                <p>
                                  <label>Document Number</label>
                                  <span>************2244</span>
                                </p>
                              </aside>
                            </div>
                          </figcaption>
                        </div>
                        <div class="GuestUserBox">
                          <figure>
                            <img src="images/Avatar-3.png" />
                          </figure>
                          <figcaption>
                            <div class="GuestUserHead">
                              <aside>
                                <p>
                                  <label>Name </label>
                                  <span>Riya Jain</span>
                                </p>
                                <p>
                                  <label>Guest ID</label>
                                  <span>GUEST1234</span>
                                </p>
                                <p>
                                  <label>Gender</label>
                                  <span>Female</span>
                                </p>
                                <p>
                                  <label>Date of Birth</label>
                                  <span>20 October 1994</span>
                                </p>
                                <p>
                                  <label>Age</label>
                                  <span>28 Years</span>
                                </p>
                                <p>
                                  <label>Previous checkout by</label>
                                  <span class="Red">
                                    <strong>
                                      {moment(item?.oldCheckOutDate).format(
                                        "ll"
                                      )}
                                    </strong>
                                  </span>
                                </p>

                                <p>
                                  <label>Requested to extend checkout by</label>
                                  <span class="Green">
                                    <strong>
                                      {moment(item?.newCheckOutDate).format(
                                        "ll"
                                      )}
                                    </strong>
                                  </span>
                                </p>
                              </aside>
                              <aside>
                                <p>
                                  <label>Email</label>
                                  <span>simmi@gmail.com</span>
                                </p>
                                <p>
                                  <label>Phone Number</label>
                                  <span>97679669696</span>
                                </p>
                                <p>
                                  <label>Address</label>
                                  <span>3383, Goreway Drive, Brampton, ON</span>
                                </p>
                                <p>
                                  <label>Verified with Document</label>
                                  <span>Passport</span>
                                </p>
                                <p>
                                  <label>Document Number</label>
                                  <span>************2244</span>
                                </p>
                              </aside>
                            </div>
                          </figcaption>
                        </div>
                      </div> */}
                    </div>
                  </div>
                ))}
                                {loader ? "" : extendCheckinList?.length > 0 ? "" : <NoDataFound />}

              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={ApproveModal}
        onHide={handleApproveModalClose}
        className="ModalBox "
      >
        <Modal.Body>
          <div class="Category">
            <a className="CloseModal" onClick={handleApproveModalClose}>
              ×
            </a>
            <h3>Extended Stay</h3>
            <div class="form-group">
              <label>Specify the price for extended Stay</label>
              <input
                type="text"
                onChange={(e) => {
                  if (!/^[0-9]{0,10}$/.test(e.target.value)) return;
                  {
                    updateState({ ...iState, price: e.target.value });
                  }
                }}
                class="form-control"
              />
            </div>
            <button
              class="Button"
              onClick={handleConfirmModalShow}            >
              Approve Request
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={SuccessModal}
        onHide={handleSuccessModalClose}
        className="ModalBox "
      >
        <Modal.Body>
          <div class="SuccessGuestBox">
            <span>
              <img src={require("../assets/image/partner4.png")} />
            </span>
            <h4>
              Check-In has been completed successfully and a confirmation link
              sent on customer's Mobile and email Address.
            </h4>
            <a onClick={handleSuccessModalClose}>Back to extend checkout</a>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={ConfirmModal}
        onHide={handleConfirmModalClose}
        className="ModalBox "
      >
        <Modal.Body>
          <div class="Category">
            <a className="CloseModal" onClick={handleConfirmModalClose}>
              ×
            </a>
            <div class="SuccessGuestBox">
           
           <h4>
             {`Entered amount ${price} will not be updated after extend check-out approve.`}
           </h4>
         </div>
           
 
            <h3>{`Are you sure you want to extend this for ${ extendDays==0?1:extendDays} nights ?`}</h3>
       

            <a class="Button"onClick={() => handleApprove("approve")}>Confirm</a>
          </div>
    
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default GuestsExtendedCheckout;
