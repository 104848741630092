import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CheckInListAction } from "../redux/actions/guestAction";
import Header from "./header";
import Sidenav from "./sidenav";
import moment from "moment";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import NoDataFound from "./noDataFound";
import Pagination from "react-js-pagination";
import ImageFetcher from './ImageFetcher'
import Select from "react-select";
import { OccupiedRoomAction } from "../redux/actions/dropdownAction";
import commonCrypto from "../utils";

const GuestsCheckedIn = () => {
  const { id } = useParams();
  const hotelDetails = JSON.parse(window.localStorage.getItem("hotelDetails"));

  const dispatch = useDispatch();
  const [tempRoom, setTempRoom] = useState("");
  const [search, setSearch] = useState("");
  const [options, setOptions] = useState([]);
  const [activePage, setActivePage] = useState(1);

  const { occupiedRoom } = useSelector((state) => state.dropdownReducer);

  const { checkinGuestList, loader } = useSelector(
    (state) => state.guestReducer
  );

  useEffect(() => {
    dispatch(CheckInListAction({ hotelId: id }));
  }, []);

  const handleFilter = (e) => {
    console.log(tempRoom);

    dispatch(CheckInListAction({ search: tempRoom?.fullNumber, hotelId: id }));
  };

  const handleSearch = (e) => {
    dispatch(CheckInListAction({ hotelId: id, search: e.target.value }));

    setSearch(e.target.value);
  };

  useEffect(() => {
    dispatch(OccupiedRoomAction({ hotelId: id }));
  }, []);
  const handleRefresh = (e) => {
    setSearch("");
    setTempRoom("");

    dispatch(CheckInListAction({ hotelId: id }));
  };

  useEffect(() => {
    if (occupiedRoom) {
      let tempArray = occupiedRoom;
      tempArray.map((object) => {
        object["label"] = object.fullNumber;
        object["value"] = object.fullNumber;
      });

      setOptions(tempArray);
    }
  }, [occupiedRoom]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    dispatch(CheckInListAction({ page: pageNumber, hotelId: id }));
  };

  return (
    <>
      <Header />
      <Sidenav />
      <div class="WrapperArea">
        <div class="WrapperBox">
          <div class="HotelLocation HotelLocation2">
            <div class="back-btn">
              <Link to={`/guests-management/${id}`}>
                <i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back{" "}
              </Link>
            </div>

            <aside>
              <h3>{hotelDetails?.name}</h3>
              <p>
                <span>
                  <i class="fa fa-map-marker"></i>
                </span>{" "}
                {hotelDetails?.address}
              </p>
            </aside>
          </div>

          <div class="Small-Wrapper">
            <div class="NewTitleBox">
              <div className="arrow-left">
                <h4>Checked-In Users</h4>
              </div>
            </div>
            <div class="Filter">
              <div class="form-group">
                <label>Search</label>
                <input
                  type="text"
                  value={search}
                  onChange={handleSearch}
                  class="form-control"
                  placeholder="Search "
                />
              </div>
              <div class="form-group">
                <label>Room Number</label>
                <Select
                  value={tempRoom}
                  onChange={setTempRoom}
                  options={options}
                />
              </div>
              <div class="form-group">
                <label>&nbsp;</label>
                <button onClick={handleFilter} class="Button">
                  Apply
                </button>

                <button onClick={handleRefresh} class="Button Cancel">
                  <i class="fa fa-refresh"></i>
                </button>
              </div>
            </div>

            <div class="GuestAccordian">
              <div id="accordion">
                {checkinGuestList?.[0]?.data?.map((ele, i) => (
                  <div class="card">
                    <div
                      class="card-header collapsed"
                      data-toggle="collapse"
                      href={`#collapse${i}`}
                      aria-expanded="false"
                    >
                      <h5>
                        Room Number : <strong>{ele?.rooms?.fullNumber}</strong>{" "}
                      </h5>
                      <h5>
                        Guest Name :{" "}
                        <strong>
                          {ele?.co_occupants?.[0]?.name
                            ? ele?.co_occupants?.[0]?.name
                            : ele?.co_occupants?.[0]?.userId?.[0]?.name}
                        </strong>{" "}
                      </h5>

                      <h5>
                        Checkin :{" "}
                        <strong>
                          {moment(ele?.checkInDate)?.format("ll")}
                        </strong>{" "}
                      </h5>

                      <h6 className="text-left">
                        <span>
                          (Checkout-{" "}
                          {ele?.extendCheckOut?.rooms?.length > 0
                            ? moment(
                                ele?.extendCheckOut?.newCheckOutDate
                              ).format("ll")
                            : moment(ele?.checkOutDate).format("ll")}
                          )
                        </span>{" "}
                      </h6>
                    </div>
                    <div
                      id={`collapse${i}`}
                      class="collapse"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        {ele?.co_occupants?.map((item) => {
                          let cryptData = commonCrypto.decrypt(
                            item.userId?.[0]?.kyc_details?.docData
                          );

                          return (
                            <div class="GuestUserBox">
                              <figure> 
                              <ImageFetcher imageUrl={cryptData !== null ? cryptData?.kycSelfie : ""}/>{" "}
                              </figure>
                              <figcaption>
                                <div class="GuestUserHead">
                                  <aside>
                                    <p>
                                      <label>Name </label>
                                      <span>
                                        <span>
                                          {cryptData !== null
                                            ? cryptData?.name
                                            : item?.name}
                                        </span>
                                      </span>
                                    </p>
                                    <p>
                                      <label>Guest ID</label>
                                      <span>{ele?.guestId}</span>
                                    </p>
                                    {item?.userId &&
                                      item?.userId?.length > 0 && (
                                        <p>
                                          <label>Gender</label>
                                          <span>
                                            {cryptData !== null
                                              ? cryptData?.gender
                                                  ?.charAt(0)
                                                  ?.toUpperCase() +
                                                cryptData?.gender?.slice(1)
                                              : item?.gender
                                                  ?.charAt(0)
                                                  ?.toUpperCase() +
                                                item?.gender?.slice(1)}
                                          </span>
                                        </p>
                                      )}
                                    <p>
                                      <label>Date of Birth</label>
                                      <span>
                                        {cryptData !== null
                                          ? cryptData?.dob
                                            ? moment(cryptData?.dob).format(
                                                "ll"
                                              )
                                            : "NA"
                                          : moment(item?.dob).format("ll")}
                                      </span>
                                    </p>
                                    <p>
                                      <label>Age</label>
                                      <span>
                                        {cryptData !== null
                                          ? cryptData?.age
                                            ? cryptData?.age
                                            : "NA"
                                          : item?.age}
                                      </span>
                                    </p>

                                    {item?.type == "adult" &&
                                      cryptData?.docFrontImg && (
                                        <div class="card-guest">
                                          <span>
                                          <ImageFetcher imageUrl={cryptData?.docFrontImg}/>
                                          </span>
                                          <span>
                                          <ImageFetcher imageUrl={cryptData?.docFrontImg}/>
                                          </span>
                                        </div>
                                      )}
                                  </aside>
                                  <aside>
                                    {cryptData?.kycPlace && (
                                      <p>
                                        <label>Selfie Location</label>
                                        <span>{cryptData?.kycPlace}</span>
                                      </p>
                                    )}

                                    <p>
                                      <label>Stay Duration</label>
                                      <span>{ele?.stay_duration}</span>
                                    </p>

                                    <p>
                                      <label>Meal Type</label>
                                      <span>{ele?.meal}</span>
                                    </p>

                                    {item?.type !== "kid" && (
                                      <p>
                                        <label>Email</label>
                                        {cryptData !== null
                                          ? cryptData?.email == "" ||
                                            cryptData?.email == "false"
                                            ? "NA"
                                            : cryptData?.email
                                          : item?.email == "" ||
                                            item?.email == "false"
                                          ? "NA"
                                          : item?.email}{" "}
                                      </p>
                                    )}
                                    <p>
                                      <label>Phone Number</label>
                                      <span>
                                        {item?.type == "kid"
                                          ? "NA"
                                          : cryptData !== null
                                          ? cryptData?.phone
                                          : item?.phone}
                                      </span>
                                    </p>
                                    {item?.type !== "kid" && (
                                      <p>
                                        <label>Address</label>
                                        <span style={{ lineBreak: "anywhere" }}>
                                          {cryptData !== null
                                            ? cryptData?.address
                                              ? cryptData?.address
                                              : "NA"
                                            : item?.address}
                                        </span>
                                      </p>
                                    )}
                                    <p>
                                      <label>Verified with Document</label>
                                      <span>
                                        {item?.type == "kid"
                                          ? "NA"
                                          : cryptData !== null
                                          ? item?.kyc_details
                                            ? item?.kyc_details?.docType
                                            : item.userId?.[0]?.kyc_details
                                                ?.docType
                                          : "NA"}
                                      </span>
                                    </p>
                                    <p>
                                      <label>Document Number</label>
                                      <span>
                                        {" "}
                                        {item?.type == "kid"
                                          ? "NA"
                                          : cryptData !== null
                                          ? cryptData?.docNumber
                                          : "NA"}
                                      </span>
                                    </p>
                                  </aside>
                                </div>
                              </figcaption>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                ))}
                {loader ? (
                  ""
                ) : checkinGuestList?.[0]?.metadata?.[0]?.total > 0 ? (
                  ""
                ) : (
                  <NoDataFound />
                )}
              </div>

              {checkinGuestList?.[0]?.metadata?.[0]?.total > 0 ? (
                <div className="Pagination">
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={10}
                    totalItemsCount={
                      checkinGuestList?.[0]?.metadata?.[0]?.total
                    }
                    pageRangeDisplayed={5}
                    onChange={(e) => handlePageChange(e)}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GuestsCheckedIn;
