import React, { useEffect, useState } from "react";
import Header from "./header";
import Sidenav from "./sidenav";
import { toast } from "react-toastify";
import ImageFetcher from './ImageFetcher'
import {
  FoodBeverageListAction,
  FoodBeverageCategoryListAction,
} from "../redux/actions/foodBeverageAction";
import { useDispatch, useSelector } from "react-redux";
import { CreateOrderAction } from "../redux/actions/restaurantAction";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { OccupiedRoomAction } from "../redux/actions/dropdownAction";

const initialState = {
  hotelId: "",
  serviceType: "foodandbeverage",
  status: "inProgress",
  paymentStatus: "paylater",
  instruction: "",
  itemCount: "",
  totalPrice: "",
  mealType: "",
  createdBy: "staff",
  errorMsg: {},
};
const RestaurantOrderAdd = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();
  const hotelDetails = JSON.parse(window.localStorage.getItem("hotelDetails"));

  const [stopper, setStopper] = useState(false);

  const [filterData, setFilterData] = useState({
    search: "",
    mealType: "",
    category: "",
  });

  const { occupiedRoom } = useSelector((state) => state.dropdownReducer);

  const { foodBeverageList, loader, foodBeverageCategoryList } = useSelector(
    (state) => state.foodBeverageReducer
  );
  const [room, setRoom] = useState("");
  const [options, setOptions] = useState([]);

  const [iState, updateState] = useState(initialState);
  const { errorMsg } = iState;

  const [items, setItems] = useState([]);
  const [allFoodItems, setAllFoodItems] = useState([]);

  let handleValidation = () => {
    let errorMsg = {};
    let formIsValid = true;
    if (!iState.instruction) {
      errorMsg.instruction = "* Please  enter instruction";
      formIsValid = false;
    }
    if (!room) {
      errorMsg.room = "* Please select room";
      formIsValid = false;
    }
    updateState({
      ...iState,
      errorMsg: errorMsg,
    });
    return formIsValid;
  };

  const handleAddToCart = (ele, index) => {
    let tempArray = [...items];
    let tempAllItems = [...allFoodItems];
    var addIndex = 0;
    let AlreadyInCart = items?.some((product, i) => {
      if (product?.item?.name == ele?.name) {
        addIndex = i;
        return true;
      }
    });
    if (!AlreadyInCart) {
      ele["count"] = 1;
      tempArray.push({ item: ele, qty: 1, totalPrice: ele?.price });
      setItems(tempArray);

      tempAllItems[index] = { ...tempAllItems[index], count: 1 };

      setAllFoodItems(tempAllItems);
    } else {
      let obj = tempArray[addIndex];
      obj["count"] = parseInt(tempArray[addIndex].qty) + 1;

      obj["item"] = tempArray[addIndex] = {
        ...tempArray[addIndex],
        qty: parseInt(tempArray[addIndex].qty) + 1,

        totalPrice:
          parseInt(tempArray[addIndex].totalPrice) +
          parseInt(tempArray[addIndex].item.price),
      };
      setItems(tempArray);

      tempAllItems[index] = {
        ...tempAllItems[index],
        count: parseInt(tempAllItems[index].count) + 1,
      };

      setAllFoodItems(tempAllItems);
    }
  };

  const handleRemoveFromCart = (ele, index) => {
    let tempArray = [...items];
    let tempAllItems = [...allFoodItems];
    var removeIndex = 0;

    let AlreadyInCart = items?.some((product, i) => {
      if (product?.item?.name == ele?.name) {
        removeIndex = i;
        return true;
      }
    });
    if (!AlreadyInCart) {
      //     tempArray.splice(index, 1);

      //   setItems(tempArray)

      tempAllItems[index] = { ...tempAllItems[index], count: 1 };

      setAllFoodItems(tempAllItems);
    } else {
      let removeItem = tempArray[removeIndex]?.qty == 1;

      //     tempArray.splice(index, 1);

      if (removeItem) {
        tempArray.splice(removeIndex, 1);
        setItems(tempArray);
      } else {
        let obj = tempArray[removeIndex];
        obj["count"] = tempArray[removeIndex].qty - 1;

        tempArray[removeIndex] = {
          ...tempArray[removeIndex],
          qty: parseInt(tempArray[removeIndex].qty) - 1,
          item: obj,
          totalPrice:
            parseInt(tempArray[removeIndex].totalPrice) -
            parseInt(tempArray[removeIndex].item.price),
        };
        setItems(tempArray);
      }

      tempAllItems[index] = {
        ...tempAllItems[index],
        count: parseInt(tempAllItems[index].count) - 1,
      };

      setAllFoodItems(tempAllItems);
    }
  };

  useEffect(() => {
    const hotelData = JSON.parse(localStorage.getItem("hotel"));

    dispatch(FoodBeverageListAction({ hotelId: id }));
    dispatch(FoodBeverageCategoryListAction({ hotelId: id }));

    dispatch(OccupiedRoomAction({ hotelId: id }));
  }, []);

  useEffect(() => {
    console.log(foodBeverageList, "INSIDE");
    if (foodBeverageList?.[0]?.data) {
      if (foodBeverageList?.[0]?.data?.length == 0) {
        setAllFoodItems([]);
      } else {
        let tempArray = foodBeverageList?.[0]?.data?.[0]?.items;
        tempArray.forEach((object) => {
          object.count = 0;
        });

        setAllFoodItems(tempArray);
      }
    }
  }, [foodBeverageList]);

  useEffect(() => {
    if (occupiedRoom) {
      let tempArray = occupiedRoom;
      tempArray.map((object) => {
        object["label"] = object.fullNumber;
        object["value"] = object.fullNumber;
      });

      setOptions(tempArray);
    }
  }, [occupiedRoom]);

  const grandTotal = items?.reduce((accum, ele) => accum + ele?.totalPrice, 0);

  const itemCount = items?.reduce((accum, ele) => accum + ele?.qty, 0);

  const handleChange = (e) => {
    const { name, value } = e.target;

    updateState({
      ...iState,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formIsValid = handleValidation();
    setStopper(formIsValid);
    const hotelData = JSON.parse(localStorage.getItem("hotel"));

    if (formIsValid) {
      let data = {
        hotelId: hotelData?.hotelId?.[0]?._id,
        serviceType: iState.serviceType,
        room: room?._id,
        paymentStatus: "paylater",
        instruction: iState.instruction,
        itemCount: itemCount,
        amount: {
          itemTotalAmount: grandTotal,
          totalAmount: (
            grandTotal +
            (foodBeverageList?.[0]?.data?.[0]?.tax?.percent / 100) * grandTotal
          ).toFixed(2),
          tax: {
            taxType: foodBeverageList?.[0]?.data?.[0]?.tax?.taxType,
            taxPercent: foodBeverageList?.[0]?.data?.[0]?.tax?.percent,
            taxAmount: (
              (foodBeverageList?.[0]?.data?.[0]?.tax?.percent / 100) *
              grandTotal
            ).toFixed(2),
          },
        },

        totalPrice: (
          grandTotal +
          (foodBeverageList?.[0]?.data?.[0]?.tax?.percent / 100) * grandTotal
        ).toFixed(2),

        createdBy: "staff",
        items: items,
      };
      dispatch(CreateOrderAction(data))
        .then((res) => {
          if (res.status >= 200 && res.status <= 300) {
            setStopper(false);
            navigate(`/restaurant-order-status/${id}`, { state: res.data.data });
            toast.success(res.data.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            setStopper(false);
            toast.error(res.response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          alert(err);
        });
    }
  };

  const handleFilter = (e) => {
    setItems([]);

    dispatch(
      FoodBeverageListAction({
        filter: filterData?.category,
        search: filterData?.search,
        mealType: filterData?.mealType,
        hotelId: id,
      })
    );
  };

  const handleRefresh = (e) => {
    setFilterData({
      ...filterData,
      search: "",
      filter: "",
      category: "",
      mealType: "",
    });
    setItems([]);

    dispatch(FoodBeverageListAction({ hotelId: id }));
  };

  const handleMealType = (e) => {
    setFilterData((prev) => ({ ...prev, mealType: e.target.value }));
    dispatch(
      FoodBeverageListAction({
        mealType: e.target.value,
        search: filterData?.search,
        filter: filterData?.category,
        hotelId: id,
      })
    );
  };
  const handleSearch = (e) => {
    dispatch(FoodBeverageListAction({ search: e.target.value, hotelId: id }));
    setFilterData((prev) => ({ ...prev, search: e.target.value }));
  };

  return (
    <div>
      <Header />
      <Sidenav />

      <div class="WrapperArea">
        <div class="WrapperBox">
          <div class="HotelLocation HotelLocation2">
            <div class="back-btn">
              <Link to={`/restaurant-order-request/${id}`}>
                <i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back{" "}
              </Link>
            </div>

            <aside>
              <h3>{hotelDetails?.name}</h3>
              <p>
                <span>
                  <i class="fa fa-map-marker"></i>
                </span>{" "}
                {hotelDetails?.address}
              </p>
            </aside>
          </div>
          <div class="NewTitleBox">
            <div className="arrow-left">
              {/* <Link to={`/restaurant-order-request/${id}`}>
                <i class="fa fa-arrow-left arrow-back" aria-hidden="true"></i>
              </Link> */}
              <h4>Create New Order</h4>
            </div>

            <div class="CommonLinks CommonLinksNew">
              <ul>
                {/* <li class="active"><a href="restaurant-add-order.html">Food</a></li>
                        <li><a href="restaurant-add-order-beverages.html">Beverages</a></li> */}
              </ul>
            </div>
          </div>

          <div class="Small-Wrapper">
            <div class="CommonTabs">
              <div class="TitleBox">
                <div class="FilterArea">
                  <div class="FilterLeft">
                    <div class="form-group">
                      <label>Search</label>
                      <input
                        type="text"
                        value={filterData.search}
                        onChange={handleSearch}
                        placeholder="search ..."
                        class="form-control"
                      />
                    </div>
                    <div class="form-group">
                      <label>Category</label>
                      <select
                        class="form-control"
                        value={filterData.category}
                        onChange={(e) =>
                          setFilterData((prev) => ({
                            ...prev,
                            category: e.target.value,
                          }))
                        }
                      >
                        <option value="">Select </option>
                        {foodBeverageCategoryList?.[0]?.categories?.map(
                          (item) => (
                            <option value={item?._id}> {item?.name}</option>
                          )
                        )}
                      </select>
                    </div>
                    <div class="form-group">
                      <label>&nbsp;</label>
                      <button onClick={handleFilter} class="Button">
                        Apply
                      </button>
                      <button onClick={handleRefresh} class="Button Cancel">
                        <i class="fa fa-refresh"></i>
                      </button>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>&nbsp;</label>

                    <div class="form-group">
                      <label class="Radio">
                        Veg
                        <input
                          type="radio"
                          name="mealType"
                          checked={filterData?.mealType == "Veg" ? true : false}
                          onChange={handleMealType}
                          value="Veg"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <div class="form-group">
                      <label class="Radio">
                        Non-Veg
                        <input
                          type="radio"
                          name="mealType"
                          checked={
                            filterData?.mealType == "Non-Veg" ? true : false
                          }
                          onChange={handleMealType}
                          value="Non-Veg"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>

                    <div class="form-group">
                      <label class="Radio">
                        Beverages
                        <input
                          type="radio"
                          name="meal"
                          checked={
                            filterData?.mealType == "Drinks" ? true : false
                          }
                          onChange={handleMealType}
                          value="Drinks"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="tab-content">
              <div class="tab-pane active" id="Italian">
                <div class="TitleBox"></div>
                <div class="MenuList">
                  <ul>
                    {allFoodItems?.map((item, i) => (
                      <li>
                        <div class="MenuLeft">
                          <figure>
                          <ImageFetcher imageUrl={item?.image}/>
                          </figure>
                          <figcaption>
                            <h3>{item?.name}</h3>
                            {/* <p>
                                                <span>
                                                  <img src="images/star.svg" alt="" />
                                                </span> 4.5
                                                <a href="#">34 Reviews</a>
                                            </p> */}
                            <h4>
                              <span>{item?.servingSize}</span>
                            </h4>
                            <h4>
                              <span>
                                Expected Delivery Time /{" "}
                                {item?.deliveryTime?.split(":")?.[0] == "00"
                                  ? ""
                                  : `${
                                      item?.deliveryTime?.split(":")?.[0]
                                    } Hrs`}{" "}
                                {item?.deliveryTime?.split(":")?.[1]} Mins
                              </span>
                            </h4>
                          </figcaption>
                        </div>
                        <div class="MenuRight">
                          <h5>
                            {item?.price} INR <span>+Taxes</span>
                          </h5>
                          {item?.count == 0 ? (
                            <button
                              className="AddOrder"
                              onClick={() => handleAddToCart(item, i)}
                            >
                              Add{" "}
                            </button>
                          ) : (
                            <div class="silder-right">
                              <button onClick={() => handleAddToCart(item, i)}>
                                +
                              </button>
                              <input
                                type="number"
                                disabled={true}
                                value={item?.count}
                              />
                              <button
                                disabled={item?.count == 0 ? true : false}
                                onClick={() => handleRemoveFromCart(item, i)}
                              >
                                -
                              </button>
                            </div>
                          )}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            {items?.length > 0 && (
              <div class="total-bill">
                <div class="TableList">
                  <table>
                    <thead>
                      <tr>
                        <th>Item</th>
                        <th>Qnty</th>
                        <th>Amount</th>
                      </tr>
                    </thead>

                    <tbody>
                      {items?.map((ele) => (
                        <tr>
                          <td>{ele?.item?.name}</td>
                          <td>{ele?.qty}</td>
                          <td>{ele?.totalPrice} INR</td>
                        </tr>
                      ))}

                      <tr>
                        <td>
                          GST (@{" "}
                          {foodBeverageList?.[0]?.data?.[0]?.tax?.percent}%){" "}
                        </td>
                        <td>-</td>
                        <td>
                          {(
                            (foodBeverageList?.[0]?.data?.[0]?.tax?.percent /
                              100) *
                            grandTotal
                          ).toFixed(2)}{" "}
                          INR
                        </td>
                      </tr>
                    </tbody>

                    <tfoot>
                      <tr>
                        <td>Total Amount</td>
                        <td>-</td>
                        <td>
                          {(
                            grandTotal +
                            (foodBeverageList?.[0]?.data?.[0]?.tax?.percent /
                              100) *
                              grandTotal
                          ).toFixed(2)}{" "}
                          INR
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            )}
            {items?.length > 0 && (
              <div class="CommonForm">
                <form>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>Instruction</label>
                        <textarea
                          value={iState.instruction}
                          name="instruction"
                          onChange={handleChange}
                          class="form-control"
                        />
                        {errorMsg.instruction && !iState.instruction && (
                          <p style={{ color: "red" }}>{errorMsg.instruction}</p>
                        )}
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label>Select Room </label>
                        <Select
                          defaultValue={room}
                          onChange={setRoom}
                          options={options}
                        />

                        {errorMsg.room && !room && (
                          <p style={{ color: "red" }}>{errorMsg.room}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div class="small-btn saveBtn">
                    <button
                      disabled={items?.length == "0" ? true : false}
                      class="TitleLink"
                      onClick={handleSubmit}
                    >
                      Create Order
                    </button>
                  </div>{" "}
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RestaurantOrderAdd;
