import { pushNotificationConstant } from "../actionTypes";

import axios from "../../axiosInstance"
import { baseUrl } from "../../config/config";
import { isLoggedIn } from "../../utils";





//  CREATE NOTIFICATION

export const sendNotificationInitiate=() =>({
  type:pushNotificationConstant.SEND_GUEST_NOTIFICATION_INITIATE

})

export const sendNotificationSuccess=(data) =>({
  type:pushNotificationConstant.SEND_GUEST_NOTIFICATION_SUCCESS,
  payload:data

})

export const sendNotificationFailure=(data) =>({
  type:pushNotificationConstant.SEND_GUEST_NOTIFICATION_FAILURE,
  payload : data

})


export const  SendNotificationAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(sendNotificationInitiate());
      const response = await axios.post(`${baseUrl}/admin/addNotifications`, payload,{
          headers: {authorization:`${token}`}})

      if (response.data.success === true) {
        dispatch(sendNotificationSuccess(response.data));
      } else {
        dispatch(sendNotificationFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(sendNotificationFailure(err));
      return err;
    }



}




//  CREATE NOTIFICATION

export const reSendNotificationInitiate=() =>({
  type:pushNotificationConstant.SEND_GUEST_NOTIFICATION_INITIATE

})

export const reSendNotificationSuccess=(data) =>({
  type:pushNotificationConstant.SEND_GUEST_NOTIFICATION_SUCCESS,
  payload:data

})

export const reSendNotificationFailure=(data) =>({
  type:pushNotificationConstant.SEND_GUEST_NOTIFICATION_FAILURE,
  payload : data

})


export const  ResendNotificationAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(reSendNotificationInitiate());
      const response = await axios.post(`${baseUrl}/admin/resendNotifications`, payload,{
          headers: {authorization:`${token}`}})

      if (response.data.success === true) {
        dispatch(reSendNotificationSuccess(response.data));
      } else {
        dispatch(reSendNotificationFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(reSendNotificationFailure(err));
      return err;
    }



}





//  UPDATE NOTIFICATION

export const updateNotificationInitiate=() =>({
  type:pushNotificationConstant.GUEST_NOTIFICATION_UPDATE_INITIATE

})

export const updateNotificationSuccess=(data) =>({
  type:pushNotificationConstant.GUEST_NOTIFICATION_UPDATE_SUCCESS,
  payload:data

})

export const updateNotificationFailure=(data) =>({
  type:pushNotificationConstant.GUEST_NOTIFICATION_UPDATE_FAILURE,
  payload : data

})


export const  UpdateNotificationAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(updateNotificationInitiate());
      const response = await axios.post(`${baseUrl}/admin/updateNotification`, payload,{
          headers: {authorization:`${token}`}})

      if (response.data.success === true) {
        dispatch(updateNotificationSuccess(response.data));
      } else {
        dispatch(updateNotificationFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(updateNotificationFailure(err));
      return err;
    }



}


//  NOTIFICATION LIST

export const guestNotificationListInitiate=() =>({
    type:pushNotificationConstant.GUEST_NOTIFICATION_LIST_INITIATE
  
  })
  
  export const guestNotificationListSuccess=(data) =>({
    type:pushNotificationConstant.GUEST_NOTIFICATION_LIST_SUCCESS,
    payload:data
  
  })
  
  export const guestNotificationListFailure=(data) =>({
    type:pushNotificationConstant.GUEST_NOTIFICATION_LIST_FAILURE,
    payload : data
  
  })
  
  
  export const GuestNotificationListAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(guestNotificationListInitiate());
        const response = await axios.post(`${baseUrl}/admin/notificationsList`,payload,{
          headers: {authorization:`${token}`}})
  
        if (response.data.success === true) {
          dispatch(guestNotificationListSuccess(response.data));
        } else {
          dispatch(guestNotificationListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(guestNotificationListFailure(err));
        return err;
      }
  
  
  }
  