import React, { useEffect, useState } from "react";
import Header from "./header";
import Sidenav from "./sidenav";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import commonCrypto from "../utils";
import NoDataFound from "./noDataFound";
import { useDispatch, useSelector } from "react-redux";
import {
  ApproveCheckoutAction,
  AssignLuggageAction,
  GuestCheckRequestAction,
} from "../redux/actions/guestAction";
import { AvailableStaffAction } from "../redux/actions/restaurantAction";
import ImageFetcher from './ImageFetcher'
const hotelDetails =  JSON.parse(window.localStorage.getItem("hotelDetails"))


const GuestsCheckoutRequest = () => {
  const [search, setSearch] =useState('')


  const {id} = useParams()
  const dispatch = useDispatch();
  const { checkoutRequest, loader } = useSelector((state) => state.guestReducer);
  const { availableStaffList } = useSelector(
    (state) => state.restaurantReducer
  );

  const [iState, updateState] = useState({ deliveryTime: "", deliveryBoy: "" });
  const { deliveryBoy, deliveryTime } = iState;

  useEffect(() => {
    dispatch(GuestCheckRequestAction({hotelId:id}));
    dispatch(AvailableStaffAction({ role: "frontdesk" ,hotelId:id}));
  }, []);

  const handleAssign = (e, id, item) => {
    let room = item?.room?.map((item) => {
      return item?._id;
    });
    const today = new Date(
      `${moment().format("YYYY-MM-DD")}T${deliveryTime}:00`
    );
    let guest = item?.guestId?.[0]?.co_occupants?.filter((ele) => {
      return ele?.guestType == "main";
    });

    e.preventDefault();

    dispatch(
      AssignLuggageAction({
        hotelId:id,
        _id: id,
        deliveryBoy,
        deliveryTime: today.toISOString(),
        room: room,
        requestBy: guest?.[0]?.userId?.[0]?._id,
        guestId: item?.guestId?.[0]?._id,
      })
    )
      .then((res) => {
        if (res.data.success === true) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({ deliveryBoy: "", deliveryTime: "" });
          dispatch(GuestCheckRequestAction({hotelId:id}));
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const handleApprove = (id, item) => {
    let room = item?.room?.map((item) => {
      return item?._id;
    });


    if(item?.pendingAmount==0){

    dispatch(
      ApproveCheckoutAction({
        _id: id,
        room: room,
        hotelId:id,
        guestId: item?.guestId?.[0]?._id,
      })
    ).then((res) => {
      if (res.status >= "200" && res.status <= 300) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        dispatch(GuestCheckRequestAction({hotelId:id}));
      }
    });}
  // }else if(item?.luggageStatus=="pending"){
  //   toast.error("* Staff verification still pending!", {
  //     position: toast.POSITION.TOP_RIGHT,
  //   });

  // }
  // else{
  //   toast.error("* Please pay unpaid bill", {
  //     position: toast.POSITION.TOP_RIGHT,
  //   });

  // }


  };


  const handleSearch = (e) => {
    dispatch(GuestCheckRequestAction({search:e.target.value, hotelId :id}))
    setSearch(e.target.value) 
  };


  return (
    <div>
      <Header />
      <Sidenav />
      <div class="WrapperArea">
        <div class="WrapperBox">
          
        <div class="HotelLocation HotelLocation2">
            <div class="back-btn">
            <Link to={`/guests-management/${id}`}>
                <i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back{" "}
              </Link>
            </div>
            
            <aside>
              <h3>{hotelDetails?.name}</h3>
              <p>
                <span>
                  <i class="fa fa-map-marker"></i>
                </span>{" "}
                {hotelDetails?.address}
              </p>
            </aside>
</div>
          <div class="Small-Wrapper">
            <div class="NewTitleBox">
              <div className="arrow-left">
                {/* <Link to={`/guests-management/${id}`}>
                  <i class="fa fa-arrow-left arrow-back" aria-hidden="true"></i>
                </Link> */}

                <h4>Checkout Requests</h4>
              </div>
            </div>

            <div class="Filter">
              <div class="form-group">
                <label>Search</label>
                <input
                  type="text"
                  value={search}
                  onChange={handleSearch}
                  class="form-control"
                  placeholder="Search.."
                />
              </div>
              <div class="form-group">
                <label>Room Number</label>
                <select class="form-control">
                  <option>Select Room Number</option>
                  <option>RM103</option>
                </select>
              </div>
            </div>
            <div class="GuestAccordian">
              <div id="accordion">
                {checkoutRequest?.map((item, i) => {
                  return(
                  <div class="card">
                      <div
                        class="card-header collapsed"
                        data-toggle="collapse"
                        href={`#collapse${i}`}
                        aria-expanded="false"
                      >
                        <h5>
                          Rooms :{" "}
                          {item?.room?.map((ele) => (
                            <strong>{ele?.fullNumber} </strong>
                          ))}{" "}
                        </h5>
                        <h6>
                          {moment(item?.updatedAt).format("ll")}{" "}
                          <span>
                            (Checkout{" "}
                            {item?.guestId?.[0]?.extendCheckOut?.rooms?.length > 0
                              ? moment(item?.guestId?.[0]?.checkOutDate).format("ll")
                              : moment(
                                  item?.extendCheckOut?.guestId?.[0]?.newCheckOutDate
                                ).format("ll")}
                            )
                          </span>{" "}
                        </h6>
                      </div>
  
                      <div
                        id={`collapse${i}`}
                        class="collapse"
                        data-parent="#accordion"
                      >
                        {item?.luggagePickBy?.length > 0 && (
                          <button
                            style={{
                              position: "relative",
                              marginLeft: "auto",
                              marginRight: "10px ",
                              marginTop: "15px",
                            }}
                            class="Approve Approve"
                            onClick={() => handleApprove(item?._id, item)}
                          >
                            Approve Request
                          </button>
                        )}
                        <div class="card-body" style={{ borderTop: "none" }}>
                          {item?.guestId?.[0]?.co_occupants?.map((ele,i)=>
                        {
                          let cryptData= commonCrypto.decrypt(ele.userId?.[0]?.kyc_details?.docData)
                          
                       
                          return (
                            <div class="GuestUserBox">
                              <figure>
                              <ImageFetcher imageUrl={cryptData !== null ? cryptData?.kycSelfie : ele?.kycSelfie}/>
                              </figure>
                              <figcaption>
                                <div class="GuestUserHead">
                                  <aside>
                                    <p>
                                      <label>Name </label>
                                      <span>
                                        {cryptData !== null
                                          ? cryptData?.name
                                          : ele?.name}
                                      </span>
                                    </p>
                                    <p>
                                      <label>Guest ID</label>
                                      <span>
                                        {item?.guestId?.[0]?.guestId}
                                      </span>
                                    </p>
                                    {ele?.type !== "kid" && (
<p>
                                      <label>Gender</label>
                                      <span>
                                        {cryptData !== null
                                          ? cryptData?.gender
                                              ?.charAt(0)
                                              ?.toUpperCase() +
                                            cryptData?.gender?.slice(1)
                                          : ele?.gender
                                              ?.charAt(0)
                                              ?.toUpperCase() +
                                            ele?.gender?.slice(1)}
                                      </span>
                                    </p>)}
                                    <p>
                                      <label>Date of Birth</label>
                                      <span>
                                        {cryptData !== null
                                          ? cryptData?.dob
                                            ? moment(cryptData?.dob).format(
                                                "ll"
                                              )
                                            : "NA"
                                          : moment(ele?.dob).format("ll")}
                                      </span>
                                    </p>
                                    <p>
                                      <label>Age</label>
                                      <span>
                                        {cryptData !== null
                                          ? cryptData?.age
                                            ? cryptData?.age
                                            : "NA"
                                          : ele?.age}
                                      </span>
                                    </p>
                                    {ele?.type == "adult" &&
                                      cryptData?.docFrontImg && (
                                        <div class="card-guest">
                                          <span>
                                          <ImageFetcher imageUrl={cryptData?.docFrontImg}/>
                                          </span>
                                          <span>
                                          <ImageFetcher imageUrl={cryptData?.docFrontImg}/>
                                          </span>
                                        </div>
                                      )}{" "}
                                  </aside>
                                  <aside>
                                  {ele?.type !== "kid" && (
                                      <p>
                                        <label>Email</label>
                                        {cryptData !== null
                                          ? cryptData?.email == "" ||
                                            cryptData?.email == "false"
                                            ? "NA"
                                            : cryptData?.email
                                          : ele?.email == "" ||
                                            ele?.email == "false"
                                          ? "NA"
                                          : ele?.email}{" "}
                                      </p>
                                    )}
                                    {ele?.type !== "kid" && (
                                      <p>
                                        <label>Phone Number</label>
                                        <span>
                                          {ele?.type == "kid"
                                            ? "NA"
                                            : cryptData !== null
                                            ? cryptData?.phone
                                            : ele?.phone}
                                        </span>
                                      </p>
                                    )}
                                    {ele?.type !== "kid" && (
                                      <p>
                                        <label>Address</label>
                                        <span>
                                          {cryptData !== null
                                            ? cryptData?.address
                                              ? cryptData?.address
                                              : "NA"
                                            : ele?.address}
                                        </span>
                                      </p>
                                    )}
                                {ele?.type !== "kid" && (
                                      <p>
                                        <label>
                                          Verified with Document
                                        </label>
                                        <span>
                                        {ele?.type == "kid"
                                      ? "NA"
                                      : cryptData !== null
                                      ? ele?.kyc_details
                                        ? ele?.kyc_details?.docType
                                        : ele.userId?.[0]?.kyc_details
                                            ?.docType
                                      : "NA"}
                                  </span>
                                      </p>
                                    )}

                                    <p>
                                      {" "}
                                      <label>Document Number</label>
                                      <span>
                                        {" "}
                                        {ele?.type == "kid"
                                          ? "NA"
                                          : cryptData !== null
                                          ? cryptData?.docNumber
                                          : "NA"}
                                      </span>
                                    </p>

                                    <p>
                                      <label>Billing info</label>
                                      <span>
                                        <span>
                                          <Link
                                            class="Green"
                                            state={
                                              "guests-checkout-request"
                                            }
                                            to={`/guests-billing-payment-view/${item?.guestId?.[0]?._id}/${id}`}
                                          >
                                            View
                                          </Link>
                                        </span>
                                      </span>
                                    </p>
                                  </aside>
                                </div>
                                {item?.luggagePickBy?.length == 0 &&
                                  i + 1 ==
                                    item?.guestId?.[0]?.co_occupants
                                      ?.length && (
                                    <div class="GuestUserBody">
                                      <h6>Requested for luggage pick Up</h6>
                                      <p>Assign Delivery Boy</p>
                                      <div class="form-group">
                                        <label>Delivery Time</label>
                                        <input
                                          type={"time"}
                                          onChange={(e) =>
                                            updateState({
                                              ...iState,
                                              deliveryTime: e.target.value,
                                            })
                                          }
                                          className="form-control"
                                        />

                                        <select
                                          onChange={(e) =>
                                            updateState({
                                              ...iState,
                                              deliveryBoy: e.target.value,
                                            })
                                          }
                                        >
                                          <option>Select </option>
                                          {availableStaffList?.map(
                                            (item) => (
                                              <option
                                                value={item?._id}
                                              >{`${item?.name} ------ Assigned Order : ${item?.orderCount}`}</option>
                                            )
                                          )}
                                        </select>
                                        <button
                                          onClick={(e) => {
                                              handleAssign(
                                                e,
                                                item?._id,
                                                item
                                              );
                                            
                                          }}
                                          disabled={
                                            deliveryBoy == "" &&
                                            deliveryTime == ""
                                              ? true
                                              : false
                                          }
                                        >
                                          Assign
                                        </button>
                                      </div>
                                    </div>
                                  )}

                                {item?.luggagePickBy?.length > 0 && (
                                  <div class="GuestUserBody">
                                    <h6>
                                      Luggage pick up completed by :{" "}
                                      <strong>
                                        {item?.luggagePickBy?.[0]?.name}
                                      </strong>{" "}
                                    </h6>
                                    <p>
                                      <Link
                                        class="Green"
                                        state={item}
                                        to={`/guests-checkout-approved-minbar`}
                                      >
                                        View Mini Bar Details
                                      </Link>
                                    </p>
                                  </div>
                                )}
                              </figcaption>
                            </div>
                          );
                      }
                          ) }
                        </div>
                      </div>
                    </div>
                  )
                })
                
                }
                                {loader ? "" : checkoutRequest?.length > 0 ? "" : <NoDataFound />}

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuestsCheckoutRequest;
