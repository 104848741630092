import { trendingConstant } from "../actionTypes";

import axios from "../../axiosInstance"
import { baseUrl } from "../../config/config";
import { isLoggedIn } from "../../utils";




// ADD TRENDING

export const addTrendingInitiate=() =>({
    type:trendingConstant.ADD_TRENDING_INITIATE

})

export const addTrendingSuccess=(data) =>({
    type:trendingConstant.ADD_TRENDING_SUCCESS,
    payload:data

})

export const addTrendingFailure=(data) =>({
    type:trendingConstant.ADD_TRENDING_FAILURE,
    payload : data

})


export const  AddTrendingAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(addTrendingInitiate());
        const response = await axios.post(`${baseUrl}/admin/trending/addList`, payload,{
            headers: {authorization:`${token}`}})

        if (response.data.success === true) {
          dispatch(addTrendingSuccess(response.data));
        } else {
          dispatch(addTrendingFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(addTrendingFailure(err));
        return err;
      }


}


// EDIT TRENDING

export const editTrendingInitiate=() =>({
    type:trendingConstant.UPDATE_TRENDING_INITIATE

})

export const editTrendingSuccess=(data) =>({
    type:trendingConstant.UPDATE_TRENDING_SUCCESS,
    payload:data

})

export const editTrendingFailure=(data) =>({
    type:trendingConstant.UPDATE_TRENDING_FAILURE,
    payload : data

})


export const  EditTrendingAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(editTrendingInitiate());
        const response = await axios.put(`${baseUrl}/admin/spa/update`, payload,{
            headers: {authorization:`${token}`}})

        if (response.data.sucess === true) {
          dispatch(editTrendingSuccess(response.data));
        } else {
          dispatch(editTrendingFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(editTrendingFailure(err));
        return err;
      }


}



// REMOVE TRENDING

export const removeTrendingInitiate=() =>({
    type:trendingConstant.REMOVE_TRENDING_INITIATE

})

export const removeTrendingSuccess=(data) =>({
    type:trendingConstant.REMOVE_TRENDING_SUCCESS,
    payload:data

})

export const removeTrendingFailure=(data) =>({
    type:trendingConstant.REMOVE_TRENDING_FAILURE,
    payload : data

})


export const  RemoveTrendingAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(removeTrendingInitiate());
        const response = await axios.delete(`${baseUrl}/admin/trending/remove`, {
          headers: {Authorization:`${token}`},params: payload})
          console.log(response)

        if (response.data.sucess === true) {
          dispatch(removeTrendingSuccess(response.data));
        } else {
          dispatch(removeTrendingFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(removeTrendingFailure(err));
        return err;
      }


}



//  TRENDING LIST

export const trendingListInitiate=() =>({
    type:trendingConstant.TRENDING_LIST_INITIATE

})

export const trendingListSuccess=(data) =>({
    type:trendingConstant.TRENDING_LIST_SUCCESS,
    payload:data

})

export const trendingListFailure=(data) =>({
    type:trendingConstant.TRENDING_LIST_FAILURE,
    payload : data

})


export const  TrendingListAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(trendingListInitiate());
        const response = await axios.get(`${baseUrl}/admin/trending/serviceslist`, {
          headers: {Authorization:`${token}`},params: payload})

          if (response.data.success === true) {
            dispatch(trendingListSuccess(response.data));
        } else {
          dispatch(trendingListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(trendingListFailure(err));
        return err;
      }


}




// ADDED TRENDING LIST

export const addtrendingListInitiate=() =>({
    type:trendingConstant.ADD_TRENDING_LIST_INITIATE

})

export const addtrendingListSuccess=(data) =>({
    type:trendingConstant.ADD_TRENDING_LIST_SUCCESS,
    payload:data

})

export const addtrendingListFailure=(data) =>({
    type:trendingConstant.ADD_TRENDING_LIST_FAILURE,
    payload : data

})


export const  AddedTrendingListAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(addtrendingListInitiate());
        const response = await axios.get(`${baseUrl}/admin/trending/get`, {
          headers: {Authorization:`${token}`},params: payload})

          if (response.data.success === true) {
            dispatch(addtrendingListSuccess(response.data));
        } else {
          dispatch(addtrendingListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(addtrendingListFailure(err));
        return err;
      }


}


