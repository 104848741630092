import React, { useEffect, useState } from "react";
import Header from "./header";
import Sidenav from "./sidenav";

import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  HotelInviterAction,
  HotelListAction,
  HotelStatusAction,
  RemoveHotelAction,
  EditSplitpercentageAction,
} from "../redux/actions/hotelAction";
import moment from "moment/moment";
import { toast } from "react-toastify";
import NoDataFound from "./noDataFound";
import Pagination from "react-js-pagination";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Loader from "./loader";
import { verifyPinAction } from "../redux/actions/authAction";

const initialState = {
  email: "",
  phone: "",
  hotelName: "",

  errorMsg: {},
};

const HotelManagement = () => {
  const dispatch = useDispatch();
  const [activePage, setActivePage] = useState(1);

  const hotelAdmin = JSON.parse(localStorage.getItem("admin"));
  const [DeleteModal, setDelete] = useState(false);
  const [BlockModal, setBlockShow] = useState(false);
  const handleBlockClose = () => setBlockShow(false);
  const handleBlockShow = (id, status) => {
    setUserId(id);
    setUserStatus(status);
    setBlockShow(true);
  };


  const [otp, setOtp] = useState({ otp: "", errorMsg: "" });

  const [QrModal, setQr] = useState(false);
  const handleQrModalClose = () => {setQr(false)
    setOtp({otp:"", errorMsg:""})
  };
  const handleQrModalShow = (type,id,status) => {
    if(type=="delete"){
      setDelete(true)
    }
    else{
      setBlockShow(true)

    }
    setUserId(id);
    setUserStatus(status);


    setQr(true);
  };



  const [ApproveModal, setApproveShow] = useState(false);
  const handleApproveClose = () => setApproveShow(false);
  const handleApproveShow = (id, status) => {
    setUserId(id);
    setApproveShow(true);
  };
  const [packageSplitPercentage, setPackageSplitPercentage] = useState(null);
  const [couponSplitPercentage, setCouponSplitPercentage] = useState(null);
  const [editSplitPercentageModal, setEditSplitPercentageModal] = useState(false);
  const [editSplitPercentageType, setEditSplitPercentageType] = useState("")
  const [editSplitPercentage, setEditSplitPercentage] = useState(null);
  const [selectedHotelId, setSelectedHotelId] = useState(null);
  
  const [AddHotelModal, setAddHotel] = useState(false);
  const handleAddHotelModalClose = () => setAddHotel(false);
  const handleAddHotelModalShow = (id) => {
    setAddHotel(true);
  };

 



  let handleValidation = () => {
    let errorMsg = {};
    let formIsValid = true;

    if (!email) {
      errorMsg.email = "* Please enter email";
      formIsValid = false;
    } else if (!/^.+?@.+?\..+$/.test(email)) {
      errorMsg.emailValid = "* Email format is not valid";
      formIsValid = false;
    }

    if (!phone) {
      errorMsg.phone = "* Please enter phone number.";
      formIsValid = false;
    } else if (phone.length < 10) {
      errorMsg.phoneValid = "* Please enter valid phone number.";
      formIsValid = false;
    }
    if (!hotelName) {
      errorMsg.hotelName = "* Please enter hotel name.";
      formIsValid = false;
    }

    updateState({ ...iState, errorMsg: errorMsg });
    return formIsValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateState({ ...iState, [name]: value });
  };

  const [iState, updateState] = useState(initialState);
  const { email, phone, errorMsg, hotelName } = iState;

  const { hotelList, loader } = useSelector((state) => state.hotelReducer);

  const [userId, setUserId] = useState("");
  const [userStatus, setUserStatus] = useState(null);
  const [filterData, setFilterData] = useState({
    search: "",
    fromDate: "",
    toDate: "",
    timeFrame: "",
    page: 1,
    status: "",
  });



  useEffect(() => {
    dispatch(HotelListAction({ type: "admin" }));
  }, []);

  const handleApproveAction = (type) => {
    let data;

    if (type == "block") {
      data = { _id: userId, active: userStatus ? false : true };
    } else {
      data = { _id: userId, status: "Approved" };
    }
    dispatch(HotelStatusAction(data)).then((res) => {
      if (res.status === 200) {
        setApproveShow(false);
        setBlockShow(false);
        dispatch(HotelListAction({ type: "admin" }));

        toast.success(
          type == "block"
            ? "Status change successfully"
            : "Hotel verify successfully",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      } else {
        console.log(res)
        toast.error(res.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleFilter = (e) => {
    dispatch(
      HotelListAction({
        type: "admin",
        search: filterData?.search,
        fromDate: filterData?.fromDate,
        toDate: filterData?.toDate,
        timeFrame: filterData?.timeFrame,
        status: filterData?.status,
      })
    );
  };

  const handleRefresh = (e) => {
    setFilterData({
      ...filterData,
      search: "",
      fromDate: "",
      toDate: "",
      timeFrame: "",
      status: "",
    });

    dispatch(HotelListAction({ type: "admin" }));
  };

  const handleSearch = (e) => {
    dispatch(HotelListAction({ type: "admin", search: e.target.value }));
    setFilterData((prev) => ({ ...prev, search: e.target.value }));
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    filterData.page = pageNumber;
    dispatch(HotelListAction({ type: "admin", page: pageNumber }));
  };

  const downloadRowData = (rowData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    let result = rowData?.map((item, i) => {
      return {
        "Sr. No": i + 1,
        "Hotel Id": item?.hotelId,
        "Hotel Name": item?.name,
        "Phone Number": item?.phone,
        Address: item?.address,
        "Register On": moment(item?.createdAt).format("ll"),
        Status: item?.status,
      };
    });

    // Combine the header row and row data into a single string
    const myJsonString = JSON.stringify(result);

    const ws = XLSX.utils.json_to_sheet(result);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "patner-hotels-record.xlsx");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formIsValid = handleValidation();

    if (formIsValid) {
      let data = {
        email,
        phone: `+${phone}`,
        hotelName,
      };

      dispatch(HotelInviterAction(data))
        .then((res) => {
          if (res.data.success === true) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setAddHotel(false);

            updateState({
              hotelName: "",
              email: "",
              phone: "",
              errorMsg: {},
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          alert(err);
        });
    }
  };



  const handleDelete = () => {
    dispatch(RemoveHotelAction({ hotelId:userId })).then((res) => {
      if (res.status >= 200 && res.status <= 300) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });

        setDelete(false);
        dispatch(HotelListAction({ type: "admin" }));
      }
    });
  };

  let handleValidationVerify = () => {
    let formIsValid = true;

    if(otp.otp==""){
      formIsValid=false

      

      setOtp({...otp, errorMsg:"* Please enter pin "})

    }




    return formIsValid

  }

  const handleSubmitOtp = () => {
    if (handleValidationVerify()) {
      dispatch(verifyPinAction({ pin: otp?.otp, email:hotelAdmin?.email })).then(
        (res) => {
          if (res.status === 200) {
            if(DeleteModal){
              handleDelete()
            }

            if(BlockModal){
              handleApproveAction("block")            }

            setQr(false)

        
          }
          else {
            setOtp({otp:"", errorMsg:""})
            toast.error(res.response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      );
    }
  };


  const handleEditPercentageModalOpen = (e, type, item) => {
    e.preventDefault();
    if(type==="package"){
      setEditSplitPercentageType("package");
      setPackageSplitPercentage(item.packageSplitPercentage);
    }
    else{
      setEditSplitPercentageType("coupon");
      setCouponSplitPercentage(item.couponSplitPercentage);
    }
    setSelectedHotelId(item._id)
    setEditSplitPercentageModal(true);
  }
  const handleEditPercentageModalClose = () => {
    setEditSplitPercentageModal(false);
    setEditSplitPercentage(null);
    setSelectedHotelId(null);
  }
  const handleEditSplitPercentage = (e) => {
    e.preventDefault();
    if(!editSplitPercentage && editSplitPercentage !== 0){
      toast.error(`Nothing to Update`,
        {position: toast.POSITION.TOP_RIGHT}
      )
    }
    else if(editSplitPercentage < 0 || editSplitPercentage > 100){
      toast.error(`Discount must be between 0 to 100`,
        {position: toast.POSITION.TOP_RIGHT}
      )
    }
    else {
      const payload = {splitPercentage: editSplitPercentage, type: editSplitPercentageType, hotelId: selectedHotelId} 
      dispatch(
        EditSplitpercentageAction(payload)
      ).then((res) => {
        if(res.status >= 200 && res.status < 300){
          toast.success(`Split Percentage Updated Successfully`,
            {position: toast.POSITION.TOP_RIGHT}
          )
          dispatch(HotelListAction({ type: "admin" }));
          handleEditPercentageModalClose();
        }
      }).catch((err) => {
        alert(err);
      })
    }
  }


  window.scrollTo(0, 0)

  return (
    <>
      <Header />
      <Sidenav />
   
        <div class="WrapperArea">
          <div class="WrapperBox">
            <div class="Small-Wrapper">
              <div class="TitleBox">
                <h4 class="Title">Partner Hotels Management</h4>
                <div style={{ display: 'flex', alignItems: 'center', gap: '7px'}}>
                <ul>
                  <li>
                  <Link to="/hotel-deletion-request" className="add2">
      Hotel Deletion Request
    </Link>
    </li>
    <li>
                    <a class="Button" onClick={handleAddHotelModalShow}>
                      Invite Partner Hotel
                    </a>
                  </li>
                  <li>
                    <a
                      class="add"
                      onClick={() => downloadRowData(hotelList?.[0]?.data)}
                    >
                      Export to Excel
                    </a>
                  </li>
                </ul>
                </div>
              </div>

              <div class="Filter">
                <div class="form-group">
                  <label>Search</label>
                  <input
                    type="text"
                    onChange={handleSearch}
                    value={filterData?.search}
                    class="form-control"
                    placeholder="Search by hotel name or Id"
                  />
                </div>
                <div class="form-group">
                  <label>Status</label>
                  <select
                    value={filterData?.status}
                    onChange={(e) =>
                      setFilterData({ ...filterData, status: e.target.value })
                    }
                    class="form-control"
                  >
                    <option value="">--Select--</option>
                    <option value="Pending">Pending</option>
                    <option value="Approved">Approved</option>
                  </select>
                </div>
                <div class="form-group">
                        <label>From Date</label>
                        <input type="date" value={filterData?.fromDate}  onChange={(e)=>setFilterData({...filterData, fromDate:e.target.value, timeFrame:""})} class="form-control" />
                    </div>
                    <div class="form-group">
                        <label>To Date</label>
                        <input type="date" disabled={!filterData?.fromDate} value={filterData?.toDate} min={filterData?.fromDate} onChange={(e)=> setFilterData({...filterData, toDate:e.target.value ,timeFrame:""})} class="form-control" />
                    </div> 
                    <div class="form-group">
                        <label>Timeframe</label>
                        <select value={filterData?.timeFrame} onChange={(e)=> setFilterData({...filterData,fromDate:"",toDate:"", timeFrame:e.target.value})} class="form-control">
                            <option value="">--Select--</option>
                            <option value="Today">Today</option>
                            <option value="Week">This Week</option>
                            <option value="Month">This Month</option>
                        </select>
                    </div>
                <div class="form-group">
                  <label>&nbsp;</label>
                  <button onClick={handleFilter} class="Button">
                    Apply
                  </button>
                  <button onClick={handleRefresh} class="Button Cancel">
                    <i class="fa fa-refresh"></i>
                  </button>
                </div>
              </div>
              {loader ? (
        <Loader />
      ) : (
              <div class="TableList">
                <table style={{ width: "130%" }}>
                  <thead>
                    <tr>
                      <th>Hotel ID</th>
                      <th>Hotel Name</th>
                      <th>Phone Number</th>
                      <th>Email Address</th>
                      <th>Registered On</th>
                      <th>Address</th>
                      <th>Package Split Percentage</th>
                      <th>Coupon Split Percentage</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {hotelList?.[0]?.data?.map((item, i) => (
                      <tr>
                        <td>{item?.hotelId}</td>
                        <td>{item?.name}</td>
                        <td>{item?.phone}</td>
                        <td>{item?.email}</td>
                        <td>{moment(item?.createdAt).format("ll")}</td>
                        <td>{item?.address}</td>
                        <td>
                          <div class="Actions">
                            {item?.packageSplitPercentage}%
                            <Link onClick={(e) => handleEditPercentageModalOpen(e, "package", item)}>
                              <i class="fa fa-pencil"></i>
                            </Link>
                          </div>
                        </td>
                        <td>
                          <div class="Actions">
                            {item?.couponSplitPercentage}%
                            <Link class="Green" onClick={(e) => handleEditPercentageModalOpen(e, "coupon", item)}>
                              <i class="fa fa-pencil"></i>
                            </Link>
                          </div>
                        </td>
                        <td>
                          <button
                            disabled={item?.status == "Approved" ? true : false}
                            className={
                              item?.status == "Pending" ? "Yellow2" : ""
                            }
                            onClick={() => handleApproveShow(item?._id)}
                          >
                            {item?.status}
                          </button>
                        </td>
                        <td>
                          <div class="Actions">
                            <label class="switch">
                              <input
                                type="checkbox"
                                checked={item?.active}
                                onChange={(e) =>
                                  handleQrModalShow('status',item?._id,item?.active)

                                }
                              />
                              <span class="slider"></span>
                            </label>

                            <Link
                              class="Blue"
                              to={`/hotel-details/${item?._id}`}
                              state={item}
                            >
                              <i class="fa fa-eye"></i>
                            </Link>

                            <Link class="Green" to={`/hotel-update`} state={item}>
                              <i class="fa fa-pencil"></i>
                            </Link>
                            <a
                              class="Red"
                              data-toggle="modal"
                              onClick={() => handleQrModalShow('delete',item?._id)}
                            >
                              <i class="fa fa-trash"></i>
                            </a>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>)}
              {loader ? "" : hotelList?.[0]?.data ? "" : <NoDataFound />}

              {hotelList?.[0]?.metadata?.[0]?.total > 0 ? (
                <div className="Pagination">
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={10}
                    totalItemsCount={hotelList?.[0]?.metadata?.[0]?.total}
                    pageRangeDisplayed={5}
                    onChange={(e) => handlePageChange(e)}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

      <Modal
        show={AddHotelModal}
        onHide={handleAddHotelModalClose}
        className="ModalBox "
      >
        <Modal.Body>
          <div class="Category">
            <a onClick={handleAddHotelModalClose} class="CloseModal">
              ×
            </a>
            <h3>Invite Partner Hotel</h3>
            <div class="form-group">
              <label>Hotel Name</label>
              <input
                type="text"
                onChange={handleChange}
                value={hotelName}
                name="hotelName"
                class="form-control"
              />
              {errorMsg.hotelName && !hotelName && (
                <p style={{ color: "red" }}>{errorMsg.hotelName}</p>
              )}
            </div>
            <div class="form-group">
              <label>Phone Number</label>
              <PhoneInput
                country={"in"}
                value={phone}
                enableSearch={true}
                countryCodeEditable={false}
                onChange={(phone) => updateState({ ...iState, phone })}
              />

              {errorMsg.phone && !phone && (
                <p style={{ color: "red" }}>{errorMsg.phone}</p>
              )}
              {errorMsg.phoneValid && (
                <p style={{ color: "red" }}>{errorMsg.phoneValid}</p>
              )}
            </div>
            <div class="form-group">
              <label>Email Address</label>
              <input
                type="text"
                value={email}
                name="email"
                onChange={handleChange}
                class="form-control"
              />

              {errorMsg.email && !email && (
                <p style={{ color: "red" }}>{errorMsg.email}</p>
              )}
              {errorMsg.emailValid && (
                <p style={{ color: "red" }}>{errorMsg.emailValid}</p>
              )}
            </div>
            <button class="Button" onClick={handleSubmit}>
              Send Invite{" "}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={ApproveModal} className="ModalBox">
        <Modal.Body>
          <div class="modal-body">
            <div class="Decline">
              <a className="CloseModal" onClick={handleApproveClose}>
                ×
              </a>
              <h3>Verify</h3>
              <p>Are you sure you want to verify this hotel?</p>
              {/* <h4> 
                                <a onClick={handleApproveClose}>No</a>
                                <a onClick={activeInactiveHandler}>Yes</a>
                            </h4> */}

              <div className="approve-dis-btn">
                <button
                  style={{ width: "120px" }}
                  class="Button"
                  onClick={() => handleApproveAction("approve")}
                >
                  Approve
                </button>
                <button
                  style={{ width: "120px" }}
                  class="Button"
                  onClick={handleApproveClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={editSplitPercentageModal} 
                           onHide={handleEditPercentageModalClose} 
                           style={{padding:"50px"}}
                           class="ModalBox"
                    >
                      <Modal.Body >
                              <h3 className="subscriptionUpdateModalheading">
                                {editSplitPercentageType==="package" ? 
                                "Edit Package Split Percentage" : 
                                "Edit Coupon Split Percentage"} 
                              </h3>
                              
                                <div className="form-group">
                                  <label>
                                    { editSplitPercentageType==="package" ? 
                                        "Package Split Percentage" :
                                        "Coupon Split Percentage"}
                                  </label>
                                    <input
                                        type={"number"}
                                        className="form-control"
                                        placeholder = {editSplitPercentageType==="package" ? packageSplitPercentage : couponSplitPercentage}  
                                        onChange={(e) => setEditSplitPercentage(e.target.valueAsNumber)}                         
                                    /> 
                                </div>
                                <div className="updateSubscriptionButtonContainer">
                                <button className="Button" onClick={(e) => handleEditSplitPercentage(e)}>
                                  Update
                                </button>
                                <button className="Button Cancel" onClick={(e) => handleEditPercentageModalClose(e)}> 
                                  Cancel
                                </button>
                              </div>
                      </Modal.Body>
                    </Modal>

      {/* <Modal show={BlockModal} className="ModalBox">
        <Modal.Body>
          <div class="modal-body">
            <div class="Decline">
              <a className="CloseModal" onClick={handleBlockClose}>
                ×
              </a>
              <h3> {userStatus ? "Block" : "Unblock"} this Hotel ?</h3>
              <p>
                Are you sure you want to {userStatus ? "block" : "unblock"} this
                Hotel ?
              </p>
              <h4>
                <a onClick={handleBlockClose}>No</a>
                <a onClick={() => handleApproveAction("block")}>Yes</a>
              </h4>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}
      {/* <Modal
        show={DeleteModal}
        onHide={handleDeleteModalClose}
        className="ModalBox "
      >
        <Modal.Body>
          <div className="Decline">
            <button className="CloseModal" onClick={handleDeleteModalClose}>
              ×
            </button>
            <h3>Delete</h3>
            <p>Are you sure you want to delete this Hotel ?</p>
            <h4>
              <a onClick={handleDeleteModalClose} className="Cancel">
                Cancel
              </a>
              <a onClick={handleDelete}>Delete</a>
            </h4>
          </div>
        </Modal.Body>
      </Modal> */}

      <Modal show={QrModal} onHide={handleQrModalClose} className="ModalBox ">
        <Modal.Body>
          <div class="Category">
            <a onClick={handleQrModalClose} class="CloseModal">
              ×
            </a>
            <div class="QRScanGuest">
              <h4>
                Please Verify pin
              </h4>
              {/* <figure>
                <img src={require("../assets/image/QRCode.png")} />
              </figure> */}
              {/* <p>
                <span class="qr-pera">Or</span>
              </p> */}

              <div class="form-group">
                <label>Enter pin</label>
                <input
                  onChange={(e) => setOtp({ ...otp, otp: e.target.value })}
                  type="number"
                  class="form-control"
                />
                {otp.errorMsg && !otp.otp&& <p style={{ color: "red" }}>{otp.errorMsg}</p>}
              </div>
              <a class="Button" onClick={handleSubmitOtp}>
                Confirm
              </a>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default HotelManagement;
