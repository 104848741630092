import { guestConstant } from "../actionTypes";

import axios from "../../axiosInstance"
import { baseUrl } from "../../config/config";
import { isLoggedIn } from "../../utils";




// ADD INVITE GUEST

export const addInviteGuestInitiate=() =>({
    type:guestConstant.ADD_GUEST_INITIATE

})

export const addInviteGuestSuccess=(data) =>({
    type:guestConstant.ADD_GUEST_SUCCESS,
    payload:data

})

export const addInviteGuestFailure=(data) =>({
    type:guestConstant.ADD_GUEST_FAILURE,
    payload : data

})


export const  AddInviteGuestAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(addInviteGuestInitiate());
        const response = await axios.post(`${baseUrl}/admin/guest/invite`, payload,{
            headers: {authorization:`${token}`}})

            if (response.status == 200) {
              dispatch(addInviteGuestSuccess(response.data));
        } else {
          dispatch(addInviteGuestFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(addInviteGuestFailure(err));
        return err;
      }



}


//  INVITED GUEST LIST

export const inviteGuestListInitiate=() =>({
    type:guestConstant.INVITED_GUEST_INITIATE

})

export const inviteGuestListSuccess=(data) =>({
    type:guestConstant.INVITED_GUEST_SUCCESS,
    payload:data

})

export const inviteGuestListFailure=(data) =>({
    type:guestConstant.INVITED_GUEST_FAILURE,
    payload : data

})


export const  InviteGuestListAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(inviteGuestListInitiate());
        const response = await axios.get(`${baseUrl}/admin/guest/invite/list`, {
            headers: {Authorization:`${token}`},params: payload})
  
        if (response.data.success === true) {
          dispatch(inviteGuestListSuccess(response.data));
        } else {
          dispatch(inviteGuestListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(inviteGuestListFailure(err));
        return err;
      }


}




//  CHECK IN  WITH OTP

export const checkinwithotpInitiate=() =>({
  type:guestConstant.CHECK_IN_wITH_OTP_INITIATE

})

export const checkinwithotpSuccess=(data) =>({
  type:guestConstant.CHECK_IN_wITH_OTP_SUCCESS,
  payload:data

})

export const checkinwithotpFailure=(data) =>({
  type:guestConstant.CHECK_IN_wITH_OTP_FAILURE,
  payload : data

})


export const  CheckinwithotpAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(checkinwithotpInitiate());

        const response = await axios.get(`${baseUrl}/admin/guest/checkIn/checkotp`, {
          headers: {Authorization:`${token}`},params: payload})



      if (response.data.success === true) {
        dispatch(checkinwithotpSuccess(response.data));
      } else {
        dispatch(checkinwithotpFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(checkinwithotpFailure(err));
      return err;
    }


}



//  MANUAL CHECKIN

export const manualCheckinInitiate=() =>({
  type:guestConstant.MANUAL_CHECK_IN_INITIATE

})

export const manualCheckinSuccess=(data) =>({
  type:guestConstant.MANUAL_CHECK_IN_SUCCESS,
  payload:data

})

export const manualCheckinFailure=(data) =>({
  type:guestConstant.MANUAL_CHECK_IN_FAILURE,
  payload : data

})


export const  ManualCheckinAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(manualCheckinInitiate());
      const response = await axios.post(`${baseUrl}/admin/guest/checkIn/mannualcheckinguest`, payload,{
        headers: {authorization:`${token}`}})


      if (response.data.success === true) {
        dispatch(manualCheckinSuccess(response.data));
      } else {
        dispatch(manualCheckinFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(manualCheckinFailure(err));
      return err;
    }


}




//  CHECKED_IN USER LIST

export const checkInListInitiate=() =>({
  type:guestConstant.CHECK_IN_USER_LIST_INITIATE

})

export const checkInListSuccess=(data) =>({
  type:guestConstant.CHECK_IN_USER_LIST_SUCCESS,
  payload:data

})

export const checkInListFailure=(data) =>({
  type:guestConstant.CHECK_IN_USER_LIST_FAILURE,
  payload : data

})


export const  CheckInListAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(checkInListInitiate());
      const response = await axios.get(`${baseUrl}/admin/guest/checkIn/checkinguest`, {
        headers: {Authorization:`${token}`},params: payload})

        if (response.data.success === true) {
          dispatch(checkInListSuccess(response.data));
      } else {
        dispatch(checkInListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(checkInListFailure(err));
      return err;
    }


}


//  AVAILABLE ROOM LIST

export const getAvailbleRoomInitiate=() =>({
  type:guestConstant.AVAILABLE_ROOMS_INITIATE

})

export const getAvailbleRoomSuccess=(data) =>({
  type:guestConstant.AVAILABLE_ROOMS_SUCCESS,
  payload:data

})

export const getAvailbleRoomFailure=(data) =>({
  type:guestConstant.AVAILABLE_ROOMS_FAILURE,
  payload : data

})




export const  GetAvailbleRoomAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(getAvailbleRoomInitiate());
      const response = await axios.get(`${baseUrl}/admin/room/checkIn/availableroom`, {
        headers: {Authorization:`${token}`},params: payload})


        if (response.status === 200) {
          dispatch(getAvailbleRoomSuccess(response.data));
      } else {
        dispatch(getAvailbleRoomFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getAvailbleRoomFailure(err));
      return err;
    }


}



//  ASSIGN GUEST

export const assingnGuestInitiate=() =>({
  type:guestConstant.MANUAL_CHECK_IN_INITIATE

})

export const assingnGuestSuccess=(data) =>({
  type:guestConstant.MANUAL_CHECK_IN_SUCCESS,
  payload:data

})

export const assingnGuestFailure=(data) =>({
  type:guestConstant.MANUAL_CHECK_IN_FAILURE,
  payload : data

})


export const  AssingnGuestAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(assingnGuestInitiate());
      const response = await axios.put(`${baseUrl}/admin/guest/checkIn/assignroom`, payload,{
        headers: {authorization:`${token}`}})


      if (response.data.success === true) {
        dispatch(assingnGuestSuccess(response.data));
      } else {
        dispatch(assingnGuestFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(assingnGuestFailure(err));
      return err;
    }


}





//  GUEST DETAILS

export const guestDataInitiate=() =>({
  type:guestConstant.GUEST_DATA_INITIATE

})

export const guestDataSuccess=(data) =>({
  type:guestConstant.GUEST_DATA_SUCCESS,
  payload:data

})

export const guestDataFailure=(data) =>({
  type:guestConstant.GUEST_DATA_FAILURE,
  payload : data

})


export const  GuestDataAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(guestDataInitiate());
      const response = await axios.get(`${baseUrl}/admin/guest/data`, {
          headers: {Authorization:`${token}`},params: payload})

      if (response.data.success === true) {
        dispatch(guestDataSuccess(response.data));
      } else {
        dispatch(guestDataFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(guestDataFailure(err));
      return err;
    }


}



// UPDATE KYC DETAILS

export const updateKycInitiate=() =>({
  type:guestConstant.UPDATE_KYC_DETAILS_INITIATE

})

export const updateKycSuccess=(data) =>({
  type:guestConstant.UPDATE_KYC_DETAILS_SUCCESS,
  payload:data

})

export const updateKycFailure=(data) =>({
  type:guestConstant.UPDATE_KYC_DETAILS_FAILURE,
  payload : data

})


export const  UpdateKycAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(updateKycInitiate());
      const response = await axios.post(`${baseUrl}/admin/guest/checkIn/mannual/kyc`, payload,{
          headers: {authorization:`${token}`}})

      if (response.data.success === true) {
        dispatch(updateKycSuccess(response.data));
      } else {
        dispatch(updateKycFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(updateKycFailure(err));
      return err;
    }



}





//  GUEST EXTEND CHECKOUT

export const extendCheckRequestInitiate=() =>({
  type:guestConstant.GUEST_EXTEND_CHECKOUT_INITIATE

})

export const extendCheckRequestSuccess=(data) =>({
  type:guestConstant.GUEST_EXTEND_CHECKOUT_SUCCESS,
  payload:data

})

export const extendCheckRequestFailure=(data) =>({
  type:guestConstant.GUEST_EXTEND_CHECKOUT_FAILURE,
  payload : data

})


export const  ExtendCheckRequestAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(extendCheckRequestInitiate());
      const response = await axios.get(`${baseUrl}/admin/guest/extendcheckout/get/new`, {
          headers: {Authorization:`${token}`},params: payload})

      if (response.data.success === true) {
        dispatch(extendCheckRequestSuccess(response.data));
      } else {
        dispatch(extendCheckRequestFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(extendCheckRequestFailure(err));
      return err;
    }


}




//  GUEST EXTEND APPROVE CHECKOUT 

export const extendCheckApproveRequestInitiate=() =>({
  type:guestConstant.GUEST_APPROVE_EXTEND_CHECKOUT_INITIATE

})

export const extendCheckApproveRequestSuccess=(data) =>({
  type:guestConstant.GUEST_APPROVE_EXTEND_CHECKOUT_SUCCESS,
  payload:data

})

export const extendCheckApproveRequestFailure=(data) =>({
  type:guestConstant.GUEST_APPROVE_EXTEND_CHECKOUT_FAILURE,
  payload : data

})


export const  ExtendCheckApproveRequestAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(extendCheckApproveRequestInitiate());
      const response = await axios.get(`${baseUrl}/admin/guest/extendcheckout/get/approved`, {
          headers: {Authorization:`${token}`},params: payload})

      if (response.data.success === true) {
        dispatch(extendCheckApproveRequestSuccess(response.data));
      } else {
        dispatch(extendCheckApproveRequestFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(extendCheckApproveRequestFailure(err));
      return err;
    }


}


// APPROVE EXTEND CHECKOUT

export const approveExtendCheckoutInitiate=() =>({
  type:guestConstant.APPROVE_EXTEND_CHECKOUT_INITIATE

})

export const approveExtendCheckoutSuccess=(data) =>({
  type:guestConstant.APPROVE_EXTEND_CHECKOUT_SUCCESS,
  payload:data

})

export const approveExtendCheckoutFailure=(data) =>({
  type:guestConstant.APPROVE_EXTEND_CHECKOUT_FAILURE,
  payload : data

})


export const  ApproveExtendCheckoutAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(approveExtendCheckoutInitiate());
      const response = await axios.put(`${baseUrl}/admin/guest/extendcheckout/approve`, payload,{
          headers: {authorization:`${token}`}})

      if (response.data.success === true) {
        dispatch(approveExtendCheckoutSuccess(response.data));
      } else {
        dispatch(approveExtendCheckoutFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(approveExtendCheckoutFailure(err));
      return err;
    }



}




//  GUEST  CHECKOUT

export const guestCheckRequestInitiate=() =>({
  type:guestConstant.GUEST_CHECKOUT_REQUEST_INITIATE

})

export const guestCheckRequestSuccess=(data) =>({
  type:guestConstant.GUEST_CHECKOUT_REQUEST_SUCCESS,
  payload:data

})

export const guestCheckRequestFailure=(data) =>({
  type:guestConstant.GUEST_CHECKOUT_REQUEST_FAILURE,
  payload : data

})


export const  GuestCheckRequestAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(guestCheckRequestInitiate());
      const response = await axios.get(`${baseUrl}/admin/guest/checkout/request/get`, {
          headers: {Authorization:`${token}`},params: payload})

      if (response.data.success === true) {
        dispatch(guestCheckRequestSuccess(response.data));
      } else {
        dispatch(guestCheckRequestFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(guestCheckRequestFailure(err));
      return err;
    }


}




//  ASSIGN LUGGAGE

export const assignLuggageInitiate=() =>({
  type:guestConstant.ASSIGN_LUGGAGE_INITIATE

})

export const assignLuggageSuccess=(data) =>({
  type:guestConstant.ASSIGN_LUGGAGE_SUCCESS,
  payload:data

})

export const assignLuggageFailure=(data) =>({
  type:guestConstant.ASSIGN_LUGGAGE_FAILURE,
  payload : data

})


export const  AssignLuggageAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(assignLuggageInitiate());
      const response = await axios.put(`${baseUrl}/admin/guest/checkout/luggagepick/assign`, payload,{
          headers: {authorization:`${token}`}})

      if (response.data.success === true) {
        dispatch(assignLuggageSuccess(response.data));
      } else {
        dispatch(assignLuggageFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(assignLuggageFailure(err));
      return err;
    }



}




//  APPROVE CHECKOUT

export const approveCheckoutInitiate=() =>({
  type:guestConstant.APPROVE_CHECKOUT_INITIATE

})

export const approveCheckoutSuccess=(data) =>({
  type:guestConstant.APPROVE_CHECKOUT_SUCCESS,
  payload:data

})

export const approveCheckoutFailure=(data) =>({
  type:guestConstant.APPROVE_CHECKOUT_FAILURE,
  payload : data

})


export const  ApproveCheckoutAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(approveCheckoutInitiate());
      const response = await axios.put(`${baseUrl}/admin/guest/checkout/request/approve`, payload,{
          headers: {authorization:`${token}`}})

      if (response.data.success === true) {
        dispatch(approveCheckoutSuccess(response.data));
      } else {
        dispatch(approveCheckoutFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(approveCheckoutFailure(err));
      return err;
    }



}



//  BILLING LIST

export const billingListInitiate=() =>({
  type:guestConstant.BILLING_LIST_INITIATE

})

export const billingListSuccess=(data) =>({
  type:guestConstant.BILLING_LIST_SUCCESS,
  payload:data

})

export const billingListFailure=(data) =>({
  type:guestConstant.BILLING_LIST_FAILURE,
  payload : data

})


export const  BillingListAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(billingListInitiate());
      const response = await axios.get(`${baseUrl}/admin/bills/get`, {
        headers: {Authorization:`${token}`},params: payload})



      if (response.data.success === true) {
        dispatch(billingListSuccess(response.data));
      } else {
        dispatch(billingListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(billingListFailure(err));
      return err;
    }

}




//  BILLING LIST

export const billingDetailsInitiate=() =>({
  type:guestConstant.BILLING_DETAILS_INITIATE

})

export const billingDetailsSuccess=(data) =>({
  type:guestConstant.BILLING_DETAILS_SUCCESS,
  payload:data

})

export const billingDetailsFailure=(data) =>({
  type:guestConstant.BILLING_DETAILS_FAILURE,
  payload : data

})


export const  BillingDetailsAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(billingDetailsInitiate());
      const response = await axios.get(`${baseUrl}/admin/bills/details/get`, {
        headers: {Authorization:`${token}`},params: payload})



      if (response.data.success === true) {
        dispatch(billingDetailsSuccess(response.data));
      } else {
        dispatch(billingDetailsFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(billingDetailsFailure(err));
      return err;
    }

}





//  ALL GUEST LIST

export const allGuestListInitiate=() =>({
  type:guestConstant.GUEST_ALL_LIST_INITIATE

})

export const allGuestListSuccess=(data) =>({
  type:guestConstant.GUEST_ALL_LIST_SUCCESS,
  payload:data

})

export const allGuestListFailure=(data) =>({
  type:guestConstant.GUEST_ALL_LIST_FAILURE,
  payload : data

})


export const  AllGuestListAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(allGuestListInitiate());
      const response = await axios.get(`${baseUrl}/admin/all/guests`, {
        headers: {Authorization:`${token}`},params: payload})



      if (response.data.success === true) {
        dispatch(allGuestListSuccess(response.data));
      } else {
        dispatch(allGuestListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(allGuestListFailure(err));
      return err;
    }

}


//  GUEST EXTEND APPROVE CHECKOUT 

export const checkOutApproveListInitiate=() =>({
  type:guestConstant.GUEST_CHECKOUT_APPROVED_LIST_INITIATE

})

export const checkOutApproveListSuccess=(data) =>({
  type:guestConstant.GUEST_CHECKOUT_APPROVED_LIST_SUCCESS,
  payload:data

})

export const checkOutApproveListFailure=(data) =>({
  type:guestConstant.GUEST_CHECKOUT_APPROVED_LIST_FAILURE,
  payload : data

})


export const CheckOutApproveListAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(checkOutApproveListInitiate());
      const response = await axios.get(`${baseUrl}/admin/guest/checkout/request/approved/get`, {
          headers: {Authorization:`${token}`},params: payload})

      if (response.data.success === true) {
        dispatch(checkOutApproveListSuccess(response.data));
      } else {
        dispatch(checkOutApproveListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(checkOutApproveListFailure(err));
      return err;
    }


}



//  COMPLETE CHECKOUT 

export const checkOutCompleteListInitiate=() =>({
  type:guestConstant.GUEST_CHECKOUT_COMPLETED_LIST_INITIATE

})

export const checkOutCompleteListSuccess=(data) =>({
  type:guestConstant.GUEST_CHECKOUT_COMPLETED_LIST_SUCCESS,
  payload:data

})

export const checkOutCompleteListFailure=(data) =>({
  type:guestConstant.GUEST_CHECKOUT_COMPLETED_LIST_FAILURE,
  payload : data

})



export const CheckOutCompleteListAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(checkOutCompleteListInitiate());
      const response = await axios.get(`${baseUrl}/admin/guest/checkout/complete/all`, {
          headers: {Authorization:`${token}`},params: payload})

      if (response.data.success === true) {
        dispatch(checkOutCompleteListSuccess(response.data));
      } else {
        dispatch(checkOutCompleteListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(checkOutCompleteListFailure(err));
      return err;
    }


}




//  COMPLETE CHECKOUT 

export const guestRewardListInitiate=() =>({
  type:guestConstant.GUEST_WALLET_LIST_INITIATE

})

export const guestRewardListSuccess=(data) =>({
  type:guestConstant.GUEST_WALLET_LIST_SUCCESS,
  payload:data

})

export const guestRewardListFailure=(data) =>({
  type:guestConstant.GUEST_WALLET_LIST_FAILURE,
  payload : data

})



export const GuestRewardListAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(guestRewardListInitiate());
      const response = await axios.get(`${baseUrl}/admin/userListWallet`, {
          headers: {Authorization:`${token}`},params: payload})

      if (response.data.success === true) {
        dispatch(guestRewardListSuccess(response.data));
      } else {
        dispatch(guestRewardListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(guestRewardListFailure(err));
      return err;
    }


}





export const SingleRewardInitiate=() =>({
  type:guestConstant.SINGLE_REWARD_INITIATE

})

export const SingleRewardSuccess=(data) =>({
  type:guestConstant.SINGLE_REWARD_SUCCESS,
  payload:data

})

export const SingleRewardFailure=(data) =>({
  type:guestConstant.SINGLE_REWARD_FAILURE,
  payload : data

})


export const  SingleRewardAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(SingleRewardInitiate());
      const response = await axios.post(`${baseUrl}/admin/addRewardSingleUser`, payload,{
          headers: {authorization:`${token}`}})

      if (response.data.success === true) {
        dispatch(SingleRewardSuccess(response.data));
      } else {
        dispatch(SingleRewardFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(SingleRewardFailure(err));
      return err;
    }



}



export const AllRewardInitiate=() =>({
  type:guestConstant.ALL_REWARD_INITIATE

})

export const AllRewardSuccess=(data) =>({
  type:guestConstant.ALL_REWARD_SUCCESS,
  payload:data

})

export const AllRewardFailure=(data) =>({
  type:guestConstant.ALL_REWARD_FAILURE,
  payload : data

})


export const  AllRewardAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(AllRewardInitiate());
      const response = await axios.post(`${baseUrl}/admin/rewardToAllUser`, payload,{
          headers: {authorization:`${token}`}})

      if (response.data.success === true) {
        dispatch(AllRewardSuccess(response.data));
      } else {
        dispatch(AllRewardFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(AllRewardFailure(err));
      return err;
    }



}




export const guestStatusInitiate=() =>({
  type:guestConstant.GUEST_STATUS_INITIATE

})

export const guestStatusSuccess=(data) =>({
  type:guestConstant.GUEST_STATUS_SUCCESS,
  payload:data

})

export const guestStatusFailure=(data) =>({
  type:guestConstant.GUEST_STATUS_FAILURE,
  payload : data

})


export const  GuestStatusAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(guestStatusInitiate());
      const response = await axios.get(`${baseUrl}/admin/guest/activeUpdate`, {
        headers: {Authorization:`${token}`},params: payload})



      if (response.data.success === true) {
        dispatch(guestStatusSuccess(response.data));
      } else {
        dispatch(guestStatusFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(guestStatusFailure(err));
      return err;
    }

}



export const guestDeleteInitiate=() =>({
  type:guestConstant.GUEST_STATUS_INITIATE

})

export const guestDeleteSuccess=(data) =>({
  type:guestConstant.GUEST_STATUS_SUCCESS,
  payload:data

})

export const guestDeleteFailure=(data) =>({
  type:guestConstant.GUEST_STATUS_FAILURE,
  payload : data

})


export const  GuestDeleteAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(guestDeleteInitiate());
      const response = await axios.delete(`${baseUrl}/admin/guest/delete`, {
        headers: {Authorization:`${token}`},params: payload})



      if (response.data.success === true) {
        dispatch(guestDeleteSuccess(response.data));
      } else {
        dispatch(guestDeleteFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(guestDeleteFailure(err));
      return err;
    }

}
