import React, { useState } from 'react'

import Logo  from "../assets/image/logo.png"
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {toast} from 'react-toastify'
import { Link } from 'react-router-dom';

import {EmailOtpAction} from '../redux/actions/authAction'
import { baseLink } from '../config/config';

const initialState = {
    email: "",
    type: "email",
    emailError: "",
    errorMsg: "",
    resend:false,

  };
  
const Forgot = () => {
  let timer
    const [iState, updateState] = useState(initialState);
    const navigate = useNavigate()
    const dispatch = useDispatch()


    const [otpTimerLink, setOtpLinkTimer] = useState(true);



    const { email,  emailError, errorMsg ,resend } = iState;

    let handleValidation = () => {
      let emailError = "";
      let passwordError = "";
      let formIsValid = true;
        if (!email) {
          emailError = "* Please enter email.";
          formIsValid = false;
        } else if (!/^.+?@.+?\..+$/.test(email)) {
          emailError = "* Email format is not valid";
          formIsValid = false;
        }
      
  
      updateState({
        ...iState,
        emailError,
        passwordError,
        errorMsg,
      });
      return formIsValid;
    };
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      updateState({
        ...iState,
        [name]: value,
        resend:false,

      });
    };
  

    const handleSubmit = (e) => {
        e.preventDefault();
    
        let formIsValid = handleValidation();
    
        if (formIsValid) {
            dispatch(EmailOtpAction({email:email?.toLowerCase()?.trim(),link: `${baseLink}/#/change-password`}))
            .then((res) => {
              updateState({...iState, resend:true,emailError:""})
              if (res.status === 200) {
                clearTimeout(timer);
          
                setOtpLinkTimer(false)
                runTimerLink()
  
                    toast.success(res?.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                } else {
                  toast.error(res.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });

                }
              })
              .catch((err) => {
                alert(err);
              });
          
  
            

            



          }      };
    
    


            
          const runTimerLink = () => {
            timer = window.setTimeout(() => {
              setOtpLinkTimer(true);
            }, 1000 * 1*120);
          };
  return (
    <div>    <div class="LoginArea">
    <div class="LoginBox">
        <figure>
            <img src={Logo} alt="" />
        </figure>
        <form>
            <h3>Forgot password</h3>
            <h5>No Problem! Just provide your e-mail address and we’ll <br/> send you a reset OTP.</h5>
            <div class="form-group">
                <label>Enter your email ID</label>
                <input
                  name="email"
                  value={email}
                  onChange={handleChange}
                  placeholder="Enter email Id"
                  type="text"
                  className="form-control"
                />
                {emailError && <p style={{ color: "red" }}>{emailError}</p>}
            </div>

            <button style={{width:"100%"}} onClick={handleSubmit} class="Button" disabled={otpTimerLink?false:true}>
             {resend?"RESEND LINK" :"SEND LINK"} 
            </button>
            <br />
            <Link to={'/'} class="Button">
              Back to Login
            </Link>

        </form>
    </div>
</div></div>
  )
}

export default Forgot