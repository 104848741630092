import { combineReducers } from "redux";

import authReducer from './reducer/authReducer';
import dashboardReducer from "./reducer/dashboardReducer";
// import dropdownReducer from "./reducer/dropdownReducer";
 import contentReducer from "./reducer/contentReducer";
 import subscriptionReducer from "./reducer/subscriptionReducer";

 import roomReducer from "./reducer/roomReducer";
 import wifiReducer from './reducer/wifiReducer';
 import guestReducer from "./reducer/guestReducer";
 import activitiesReducer from "./reducer/activitiesReducer";
 import hotelReducer from  "./reducer/hotelReducer"
 import foodBeverageReducer from "./reducer/foodBeverageReducer";
 import roleReducer from './reducer/roleReducer';
 import spaReducer from "./reducer/spaReducer";
 import housekeepingReducer from  "./reducer/housekeepingReducer";
 import trendingReducer from "./reducer/trendingReducer";
 import couponReducer from "./reducer/couponReducer";
 import restaurantReducer from "./reducer/restaurantReducer";
 import dropdownReducer from "./reducer/dropdownReducer";
 import chatReducer from "./reducer/chatReducer";
 import financeReducer from "./reducer/financeReducer";
 import notificationReducer from "./reducer/notificationReducer";



 
 
 














const rootReducer=combineReducers({
    authReducer,
    dashboardReducer,
    contentReducer,
    subscriptionReducer,
    roomReducer,
    wifiReducer,
    guestReducer,
    activitiesReducer,
    hotelReducer,
    foodBeverageReducer,
    roleReducer,
    spaReducer,
    housekeepingReducer,
    trendingReducer,
    couponReducer,
    restaurantReducer,
    dropdownReducer,
    chatReducer,
    financeReducer,
    notificationReducer,

})
    


export default rootReducer;