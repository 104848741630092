import React, { useEffect, useState } from 'react'
import Header from './header'
import Sidenav from './sidenav'
import Modal from "react-bootstrap/Modal";
import {toast} from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux';
import {   AddRoomCategoryAction,
  AddRoomMinAction,
  EditRoomMiniBarAction,
  RemoveRoomMiniAction,
  RoomCategoryListAction,
  RoomMinListAction,
 } from '../redux/actions/roomAction';
import { Link, useParams } from 'react-router-dom';
import Loader from './loader';

const initialState ={
        "name" : "",
        "quantity" : "",
        "price" :"" ,
    "category": "",
    errorMsg:{}

}

const RoomMinBar = () => {
  const {id} = useParams()
  const hotelDetails =  JSON.parse(window.localStorage.getItem("hotelDetails"))

  const [roomId, setroomId] = useState("");
  const [iState, updateState] = useState(initialState);
  const [Type, setType] = useState("add");
  const [roomCategory, setRoomCategory] = useState("");

  const dispatch = useDispatch();
  const { minBarList, categoryList,loader } = useSelector(
    (state) => state.roomReducer
  );

  const { name, quantity, price, category, errorMsg } = iState;

  const [DeleteModal, setDelete] = useState(false);
  const handleDeleteModalClose = () => setDelete(false);
  const handleDeleteModalShow = (id) => {
    setroomId(id);
    setDelete(true);
  };


  const [AddModal, setAddModel] = useState(false);
  const handleAddModalClose = () => {setAddModel(false)
    updateState({  name: "",
    quantity: "",
    price: "",
    category: "",
    errorMsg: {},})
  }
  const handleAddModalShow = (type, ele) => {
    if (type == "edit") {
      setroomId(ele?._id);

      updateState({
        ...iState,
        name: ele?.name,
        quantity: ele?.quantity,
        price: ele?.price,
        category: ele?.category,
      });
      setType(type);
    }
    setType(type);
    setAddModel(true);
  };

  let handleValidation = () => {
    let errorMsg = {};
    let formIsValid = true;

    if (!name) {
      errorMsg.name = "* Please enter name";
      formIsValid = false;
    }

    if (!quantity) {
      errorMsg.quantity = "* Please enter quantity";
      formIsValid = false;
    }

    if (!price) {
      errorMsg.price = "* Please enter price";
      formIsValid = false;
    }
    if(Type !== "edit"){

    if (!category) {
      errorMsg.category = "* Please select category";
      formIsValid = false;
    }}

    updateState({ ...iState, errorMsg: errorMsg });
    return formIsValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if(name == "price" || name=="quantity"){
      if (!/^[0-9]{0,10}$/.test(value)) return
      {
        updateState({...iState ,[name]:value})
        
      }

    }
    else 
      {
        updateState({...iState ,[name]:value})
        
      }


  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formIsValid = handleValidation();

    if (formIsValid) {
      var data = {
        hotelId: id,
        items: {
          name,
          price,
          quantity,
        },
        category,
      };
      dispatch(AddRoomMinAction(data)).then((res) => {
        if (res.status === 201) {
          dispatch(
            RoomMinListAction({ hotelId: id })
          );

          setAddModel(false);
          updateState({
            name: "",
            quantity: "",
            price: "",
            category: "",
            errorMsg: {},
          });
        }
      });
    }
  };

  const handleUdate = (e) => {
    e.preventDefault();

    let formIsValid = handleValidation();

    if (formIsValid) {
      var data = {
          name,
          price,
          quantity,
          category,
          _id: roomId,
          hotelId:id
        
      };
      dispatch(EditRoomMiniBarAction(data)).then((res) => {
        if (res.status === 200) {
          dispatch(
            RoomMinListAction({ hotelId: id })
          );

          setAddModel(false);
          updateState({
            name: "",
            quantity: "",
            price: "",
            category: "",
            errorMsg: {},
          });
        }
      });
    }
  };

  useEffect(() => {

    dispatch(RoomMinListAction({ hotelId: id}));
    dispatch(RoomCategoryListAction({hotelId:id}));
  }, []);

  const handleAddCategory = (e) => {
    e.preventDefault();

    dispatch(AddRoomCategoryAction({ category: roomCategory ,hotelId:id})).then((res) => {
      if (res.status === 200) {
        dispatch(RoomCategoryListAction({hotelId:id}));
        setRoomCategory("");

        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleDelete = () => {
    dispatch(RemoveRoomMiniAction({ id: roomId, hotelId: id})).then((res) => {
      if (res.data.success === true) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        dispatch(RoomMinListAction({ hotelId: id}));

        setDelete(false);
      }
    });
  };




  return (
    <div>
        <Header />
        <Sidenav />
        {loader? <Loader />:

        <div class="WrapperArea">
        <div class="WrapperBox">
        <div class="HotelLocation HotelLocation2">
            <div class="back-btn">
              <Link to={`/hotel-room/${id}`}>
                <i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back{" "}
              </Link>
            </div>

            <aside>
              <h3>{hotelDetails?.name}</h3>
              <p>
                <span>
                  <i class="fa fa-map-marker"></i>
                </span>{" "}
                {hotelDetails?.address}
              </p>
            </aside>
          </div>

         

            <div class="Small-Wrapper">
            <div class="NewTitleBox">
                    <h4>Mini Bar Management</h4>
                    
                    <div> 
                        <a onClick={handleAddModalShow}>Add More</a>
                    </div> 
                </div>
           { minBarList?.map((item)=>
               
                  
                <div class="RoomMiniBar">
                    <p class="Deluxe">Room Category: {item?.category}</p>
                    <ul>
                  {item?.items?.map((ele) => (

                    <li>
                      <aside>
                        <p>{ele?.name} </p>
                        <input type="text" value={ele?.quantity} />
                        <p>{ele?.price} INR / per item</p>
                        <a
                          class="Green"
                          onClick={() => handleAddModalShow("edit", ele)}
                        >
                          <i class="fa fa-pencil"></i>
                        </a>
                        &nbsp;&nbsp;
                        <a
                          class="Red"
                          onClick={()=> handleDeleteModalShow(ele?._id)}
                        >
                          <i class="fa fa-trash"></i>
                        </a>
                      </aside>
                    </li>
                  ))}{" "}
                </ul>
                </div>
           )}
               
            </div>
        </div>
    </div>
}

    
    <Modal show={AddModal} onHide={handleAddModalClose} className="ModalBox ">
        <Modal.Body>
          <div class="Category">
            <a onClick={handleAddModalClose} class="CloseModal">
              ×
            </a>
            {Type == "edit" ? (
              <h3>Update Mini bar Item</h3>
            ) : (
              <h3>Add Mini bar Item</h3>
            )}
            <div class="form-group">
              <label>Enter Item name</label>
              <input
                value={name}
                name="name"
                onChange={handleChange}
                type="text"
                class="form-control"
              />
              {errorMsg.name && <p style={{ color: "red" }}>{errorMsg.name}</p>}
            </div>
            <div class="form-group">
              <label>Quantity Per Room</label>
              <input
                value={quantity}
                name="quantity"
                onChange={handleChange}
                type="text"
                class="form-control"
              />
              {errorMsg.quantity && (
                <p style={{ color: "red" }}>{errorMsg.quantity}</p>
              )}
            </div>
            <div class="form-group">
              <label>Price Per Quantity</label>
              <input
                value={price}
                name="price"
                onChange={handleChange}
                type="text"
                class="form-control"
              />
              {errorMsg.price && (
                <p style={{ color: "red" }}>{errorMsg.price}</p>
              )}
            </div>
            {Type !== "edit" &&
            <div class="form-group">
              <label>Select Category</label>
              <select
                value={category}
                name="category"
                onChange={handleChange}
                class="form-control"
              >
                <option>Select </option>
                {categoryList?.roomCategory?.map((item) => (
                  <option value={item}>{item}</option>
                ))}
                <option value="Add Category">Add Category</option>
              </select>
              {errorMsg.category && (
                <p style={{ color: "red" }}>{errorMsg.category}</p>
              )}
              </div>}
            {category == "Add Category" && (
              <div class="form-group">
                <div className="CategoryAdd">
                  <input
                    type="text"
                    class="form-control"
                    value={roomCategory}
                    placeholder="Enter category name"
                    onChange={(e) => setRoomCategory(e.target.value)}
                  />
                  <button
                    className="btn"
                    disabled={roomCategory == "" ? true : false}
                    onClick={handleAddCategory}
                  >
                    Add
                  </button>
                </div>
              </div>
            )}
            {Type == "edit" ? (
              <button class="Button" onClick={handleUdate}>
                Update Item
              </button>
            ) : (
              <button class="Button" onClick={handleSubmit}>
                Add Item
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={DeleteModal}
        onHide={handleDeleteModalClose}
        className="ModalBox "
      >
        <Modal.Body>
          <div className="Decline">
            <button className="CloseModal" onClick={handleDeleteModalClose}>
              ×
            </button>
            <h3>Delete</h3>
            <p>Are you sure you want to delete this Mini bar item ?</p>
            <h4>
              <a onClick={handleDeleteModalClose} className="Cancel">
                Cancel
              </a>
              <a onClick={handleDelete}>Delete</a>
            </h4>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default RoomMinBar