import { roomConstant } from "../actionTypes";

import axios from "../../axiosInstance"
import { baseUrl } from "../../config/config";
import { isLoggedIn } from "../../utils";




// ADD ROOM

export const addRoomInitiate=() =>({
    type:roomConstant.ADD_ROOM_INITIATE

})

export const addRoomSuccess=(data) =>({
    type:roomConstant.ADD_ROOM_SUCCESS,
    payload:data

})

export const addRoomFailure=(data) =>({
    type:roomConstant.ADD_ROOM_FAILURE,
    payload : data

})


export const  AddRoomAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(addRoomInitiate());
        const response = await axios.post(`${baseUrl}/admin/room/create`, payload,{
            headers: {Authorization:`${token}`}})

        if (response.status == 201) {
          dispatch(addRoomSuccess(response.data));
        } else {
          dispatch(addRoomFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(addRoomFailure(err));
        return err;
      }


}


// EDIT ROOM

export const editRoomInitiate=() =>({
    type:roomConstant.EDIT_ROOM_INITIATE

})

export const editRoomSuccess=(data) =>({
    type:roomConstant.EDIT_ROOM_SUCCESS,
    payload:data

})

export const editRoomFailure=(data) =>({
    type:roomConstant.EDIT_ROOM_FAILURE,
    payload : data

})


export const  EditRoomAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(editRoomInitiate());
        const response = await axios.put(`${baseUrl}/admin/room/update`, payload,{
            headers: {authorization:`${token}`}})

        if (response.succes === true) {
          dispatch(editRoomSuccess(response.data));
        } else {
          dispatch(editRoomFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(editRoomFailure(err));
        return err;
      }


}



// REMOVE ROOM

export const removeRoomInitiate=() =>({
    type:roomConstant.REMOVE_ROOM_INITIATE

})

export const removeRoomSuccess=(data) =>({
    type:roomConstant.REMOVE_ROOM_SUCCESS,
    payload:data

})

export const removeRoomFailure=(data) =>({
    type:roomConstant.REMOVE_ROOM_FAILURE,
    payload : data

})


export const  RemoveRoomAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(removeRoomInitiate());
        const response = await axios.delete(`${baseUrl}/admin/room/delete`, {
          headers: {Authorization:`${token}`},params: payload})

        if (response.status == 200) {
          dispatch(removeRoomSuccess(response.data));
        } else {
          dispatch(removeRoomFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(removeRoomFailure(err));
        return err;
      }


}



//  ROOM LIST

export const roomListInitiate=() =>({
    type:roomConstant.ROOM_LIST_INITIATE

})

export const roomListSuccess=(data) =>({
    type:roomConstant.ROOM_LIST_SUCCESS,
    payload:data

})

export const roomListFailure=(data) =>({
    type:roomConstant.ROOM_LIST_FAILURE,
    payload : data

})


export const  RoomListAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    console.log('TTT', token)
    try {
        dispatch(roomListInitiate());
        const response = await axios.get(`${baseUrl}/admin/room/all/get`, {
          headers: {Authorization:`${token}`},params: payload})

        if (response.status == 200) {
          dispatch(roomListSuccess(response.data));
        } else {
          dispatch(roomListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(roomListFailure(err));
        return err;
      }


}


// ADD ROOM CATEGORY

export const addRoomCategoryInitiate=() =>({
  type:roomConstant.ADD_ROOM_CATEGORY_INITIATE

})

export const addRoomCategorySuccess=(data) =>({
  type:roomConstant.ADD_ROOM_CATEGORY_SUCCESS,
  payload:data

})

export const addRoomCategoryFailure=(data) =>({
  type:roomConstant.ADD_ROOM_CATEGORY_FAILURE,
  payload : data

})


export const  AddRoomCategoryAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(addRoomCategoryInitiate());
      const response = await axios.post(`${baseUrl}/admin/room/category/add`, payload,{
          headers: {Authorization:`${token}`}})

      if (response.status == 201) {
        dispatch(addRoomCategorySuccess(response.data));
      } else {
        dispatch(addRoomCategoryFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(addRoomCategoryFailure(err));
      return err;
    }


}





//  ROOM CATEGORY LIST

export const roomCategoryListInitiate=() =>({
  type:roomConstant.ROOM_CATEGORY_LIST_INITIATE

})

export const roomCategoryListSuccess=(data) =>({
  type:roomConstant.ROOM_CATEGORY_LIST_SUCCESS,
  payload:data

})

export const roomCategoryListFailure=(data) =>({
  type:roomConstant.ROOM_CATEGORY_LIST_FAILURE,
  payload : data

})


export const  RoomCategoryListAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(roomCategoryListInitiate());
      const response = await axios.get(`${baseUrl}/admin/room/category/list`, {
          headers: {Authorization:`${token}`},params: payload})


      if (response.status == 200) {
        dispatch(roomCategoryListSuccess(response.data));
      } else {
        dispatch(roomCategoryListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(roomCategoryListFailure(err));
      return err;
    }


}





// ADD ROOM MIN BAR

export const addRoomMinInitiate=() =>({
    type:roomConstant.ADD_ROOM_MIN_BAR_INITIATE

})

export const addRoomMinSuccess=(data) =>({
    type:roomConstant.ADD_ROOM_MIN_BAR_SUCCESS,
    payload:data

})

export const addRoomMinFailure=(data) =>({
    type:roomConstant.ADD_ROOM_MIN_BAR_FAILURE,
    payload : data

})


export const  AddRoomMinAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(addRoomMinInitiate());
        const response = await axios.post(`${baseUrl}/admin/minibar/add`, payload,{
            headers: {Authorization:`${token}`}})

        if (response.status == 201) {
          dispatch(addRoomMinSuccess(response.data));
        } else {
          dispatch(addRoomMinFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(addRoomMinFailure(err));
        return err;
      }


}




//  ROOM MIN BAR LIST

export const roomMinListInitiate=() =>({
    type:roomConstant.ROOM_MIN_BAR_LIST_INITIATE

})

export const roomMinListSuccess=(data) =>({
    type:roomConstant.ROOM_MIN_BAR_LIST_SUCCESS,
    payload:data

})

export const roomMinListFailure=(data) =>({
    type:roomConstant.ROOM_MIN_BAR_LIST_FAILURE,
    payload : data

})


export const  RoomMinListAction=(payload)=> async(dispatch)=>{
    const token =isLoggedIn('admin');
    try {
        dispatch(roomMinListInitiate());
        const response = await axios.get(`${baseUrl}/admin/minibar/get`, {
            headers: {Authorization:`${token}`},params: payload})


        if (response.status == 200) {
          dispatch(roomMinListSuccess(response.data));
        } else {
          dispatch(roomMinListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(roomMinListFailure(err));
        return err;
      }


}


// EDIT ROOM MINI BAR

export const editRoomMiniInitiate=() =>({
  type:roomConstant.EDIT_ROOM_INITIATE

})

export const editRoomMiniSuccess=(data) =>({
  type:roomConstant.EDIT_ROOM_SUCCESS,
  payload:data

})

export const editRoomMiniFailure=(data) =>({
  type:roomConstant.EDIT_ROOM_FAILURE,
  payload : data

})


export const  EditRoomMiniBarAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(editRoomMiniInitiate());
      const response = await axios.put(`${baseUrl}/admin/minibar/update`, payload,{
          headers: {authorization:`${token}`}})

      if (response.succes === true) {
        dispatch(editRoomMiniSuccess(response));
      } else {
        dispatch(editRoomMiniFailure(response));
      }
      return response;
    } catch (err) {
      dispatch(editRoomMiniFailure(err));
      return err;
    }


}




// REMOVE ROOM MINI

export const removeRoomMiniInitiate=() =>({
  type:roomConstant.REMOVE_ROOM_INITIATE

})

export const removeRoomMiniSuccess=(data) =>({
  type:roomConstant.REMOVE_ROOM_SUCCESS,
  payload:data

})

export const removeRoomMiniFailure=(data) =>({
  type:roomConstant.REMOVE_ROOM_FAILURE,
  payload : data

})


export const  RemoveRoomMiniAction=(payload)=> async(dispatch)=>{
  const token =isLoggedIn('admin');
  try {
      dispatch(removeRoomMiniInitiate());
      const response = await axios.delete(`${baseUrl}/admin/minibar/remove`, {
        headers: {Authorization:`${token}`},params: payload})

      if (response.status == 200) {
        dispatch(removeRoomMiniSuccess(response.data));
      } else {
        dispatch(removeRoomMiniFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(removeRoomMiniFailure(err));
      return err;
    }


}


