export const authConstant = {
  SIGNUP_INITIATE: "SIGNUP_INITIATE",
  SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
  SIGNUP_FAILURE: "SIGNUP_INITIATE",

  VERIFY_ADMIN_INITIATE: "VERIFY_ADMIN_INITIATE",
  VERIFY_ADMIN_SUCCESS: "VERIFY_ADMIN_SUCCESS",
  VERIFY_ADMIN_FAILURE: "VERIFY_ADMIN_FAILURE",

  VERIFY_USER_INITIATE: "VERIFY_USER_INITIATE",
  VERIFY_USER_SUCCESS: "VERIFY_USER_SUCCESS",
  VERIFY_USER_FAILURE: "VERIFY_USER_FAILURE",

  LOGIN_INITIATE: "LOGIN_INITIATE",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_INITIATE",

  LOGIN_OTP_INITIATE: "LOGIN_OTP_INITIATE",
  LOGIN_OTP_SUCCESS: "LOGIN_OTP_SUCCESS",
  LOGIN_OTP_FAILURE: "LOGIN_OTP_FAILURE",

  VERIFY_LOGIN_OTP_INITIATE: "VERIFY_LOGIN_OTP_INITIATE",
  VERIFY_LOGIN_OTP_SUCCESS: "VERIFY_LOGIN_OTP_SUCCESS",
  VERIFY_LOGIN_OTP_FAILURE: "VERIFY_LOGIN_OTP_FAILURE",

  VERIFY_OTP_INITIATE: "VERIFY_OTP_INITIATE",
  VERIFY_OTP_SUCCESS: "VERIFY_OTP_SUCCESS",
  VERIFY_OTP_FAILURE: "VERIFY_OTP_FAILURE",

  EMAIL_OTP_INITIATE: "EMAIL_OTP_INITIATE",
  EMAIL_OTP_SUCCESS: "EMAIL_OTP_SUCCESS",
  EMAIL_OTP_FAILURE: "EMAIL_OTP_FAILURE",

  FORGET_PASSWORD_INITIATE: "FORGET_PASSWORD_INITIATE",
  FORGET_PASSWORD_SUCCESS: "FORGET_PASSWORD_SUCCESS",
  FORGET_PASSWORD_FAILURE: "FORGET_PASSWORD_FAILURE",

  UPDATE_PASSWORD_INITIATE: "UPDATE_PASSWORD_INITIATE",
  UPDATE_PASSWORD_SUCCESS: "UPDATE_PASSWORD_SUCCESS",
  UPDATE_PASSWORD_FAILURE: "UPDATE_PASSWORD_FAILURE",

  LOGOUT_INITIATE: "LOGOUT_INITIATE",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",

  ADMIN_DETAILS_INITIATE: "ADMIN_DETAILS_INITIATE",
  ADMIN_DETAILS_SUCCESS: "ADMIN_DETAILS_SUCCESS",
  ADMIN_DETAILS_FAILURE: "ADMIN_DETAILS_FAILURE",

  ADMIN_UPDATE_DETAILS_INITIATE: "ADMIN_UPDATE_DETAILS_INITIATE",
  ADMIN_UPDATE_DETAILS_SUCCESS: "ADMIN_UPDATE_DETAILS_SUCCESS",
  ADMIN_UPDATE_DETAILS_FAILURE: "ADMIN_UPDATE_DETAILS_FAILURE",


  CREATE_PIN_INITIATE: "CREATE_PIN_DETAILS_INITIATE",
  CREATE_PIN_SUCCESS:  "CREATE_PIN_DETAILS_SUCCESS",
  CREATE_PIN_FAILURE:  "CREATE_PIN_DETAILS_FAILURE",

  REVIEW_LIST_INITIATE: "REVIEW_LIST_INITIATE",
  REVIEW_LIST_SUCCESS:  "REVIEW_LIST_SUCCESS",
  REVIEW_LIST_FAILURE:  "REVIEW_LIST_FAILURE",


  SIGNUP_BONUS_INITIATE: "SIGNUP_BONUS_INITIATE",
  SIGNUP_BONUS_SUCCESS:  "SIGNUP_BONUS_SUCCESS",
  SIGNUP_BONUS_FAILURE:  "SIGNUP_BONUS_FAILURE",

  UPDATE_SIGNUP_BONUS_INITIATE: "UPDATE_SIGNUP_BONUS_INITIATE",
  UPDATE_SIGNUP_BONUS_SUCCESS:  "UPDATE_SIGNUP_BONUS_SUCCESS",
  UPDATE_SIGNUP_BONUS_FAILURE:  "UPDATE_SIGNUP_BONUS_FAILURE",

  VERIFY_TOKEN_INITIATE: "VERIFY_TOKEN_INITIATE",
  VERIFY_TOKEN_SUCCESS:  "VERIFY_TOKEN_SUCCESS",
  VERIFY_TOKEN_FAILURE:  "VERIFY_TOKEN_FAILURE",


  TWO_STEP_INITIATE: "TWO_STEP_INITIATE",
  TWO_STEP_SUCCESS:  "TWO_STEP_SUCCESS",
  TWO_STEP_FAILURE:  "TWO_STEP_FAILURE",


  NOTIFICATION_INITIATE: "NOTIFICATION_INITIATE",
  NOTIFICATION_SUCCESS:  "NOTIFICATION_SUCCESS",
  NOTIFICATION_FAILURE:  "NOTIFICATION_FAILURE",


};

export const dashboardContant = {
  DASHBOARD_INITIATE: "DASHBOARD_INITIATE",
  DASHBOARD_SUCCESS: "DASHBOARD_SUCCESS",
  DASHBOARD_FAILURE: "DASHBOARDFAILURE",

  NOTIFICATION_LIST_INITIATE: "NOTIFICATION_LIST_INITIATE",
  NOTIFICATION_LIST_SUCCESS:  "NOTIFICATION_LIST_SUCCESS",
  NOTIFICATION_LIST_FAILURE:  "NOTIFICATION_LIST_FAILURE",


  NOTIFICATION_READ_INITIATE: "NOTIFICATION_READ_INITIATE",
  NOTIFICATION_READ_SUCCESS:  "NOTIFICATION_READ_SUCCESS",
  NOTIFICATION_READ_FAILURE:  "NOTIFICATION_READ_FAILURE",

  GET_SPLITPERCENTAGE_INITIATE: "GET_SPLITPERCENTAGE_INITIATE",
  GET_SPLITPERCENTAGE_SUCCESS: "GET_SPLITPERCENTAGE_SUCCESS",
  GET_SPLITPERCENTAGE_FAILURE: "GET_SPLITPERCENTAGE_FAILURE",
  
  EDIT_SPLITPERCENTAGE_INITIATE: "EDIT_SPLITPERCENTAGE_INITIATE",
  EDIT_SPLITPERCENTAGE_SUCCESS: "EDIT_SPLITPERCENTAGE_SUCCESS",
  EDIT_SPLITPERCENTAGE_FAILURE: "EDIT_SPLITPERCENTAGE_FAILURE",

  
};


export const contentManagementConstant = {
    
  GET_CONTENT_PRIVACY_POLICY_INITIATE: 'GET_CONTENT_PRIVACY_POLICY_INITIATE',
  GET_CONTENT_PRIVACY_POLICY_SUCCESS:  'GET_CONTENT_PRIVACY_POLICY_SUCCESS',
  GET_CONTENT_PRIVACY_POLICY_FAILURE:  'GET_CONTENT_PRIVACY_POLICY_FAILURE',


  
  UPDATE_CONTENT_PRIVACY_POLICY_INITIATE: 'UPDATE_CONTENT_PRIVACY_POLICY_INITIATE',
  UPDATE_CONTENT_PRIVACY_POLICY_SUCCESS:  'UPDATE_CONTENT_PRIVACY_POLICY_SUCCESS',
  UPDATE_CONTENT_PRIVACY_POLICY_FAILURE:  'UPDATE_CONTENT_PRIVACY_POLICY_FAILURE',

  
  GET_CONTENT_EDUCATION_LOAN_INITIATE: 'GET_CONTENT_EDUCATION_LOAN_INITIATE',
  GET_CONTENT_EDUCATION_LOAN_SUCCESS:  'GET_CONTENT_EDUCATION_LOAN_SUCCESS',
  GET_CONTENT_EDUCATION_LOAN_FAILURE:  'GET_CONTENT_EDUCATION_LOAN_FAILURE',


  UPDATE_CONTENT_EDUCATION_LOAN_INITIATE: 'UPDATE_CONTENT_EDUCATION_LOAN_INITIATE',
  UPDATE_CONTENT_EDUCATION_LOAN_SUCCESS:  'UPDATE_CONTENT_EDUCATION_LOAN_SUCCESS',
  UPDATE_CONTENT_EDUCATION_LOAN_FAILURE:  'UPDATE_CONTENT_EDUCATION_LOAN_FAILURE',


  GET_CONTENT_ABOUT_US_INITIATE: 'GET_CONTENT_ABOUT_US_INITIATE',
  GET_CONTENT_ABOUT_US_SUCCESS:  'GET_CONTENT_ABOUT_US_SUCCESS',
  GET_CONTENT_ABOUT_US_FAILURE:  'GET_CONTENT_ABOUT_US_FAILURE',


  UPDATE_CONTENT_ABOUT_US_INITIATE: 'UPDATE_CONTENT_ABOUT_US_INITIATE',
  UPDATE_CONTENT_ABOUT_US_SUCCESS:  'UPDATE_CONTENT_ABOUT_US_SUCCESS',
  UPDATE_CONTENT_ABOUT_US_FAILURE:  'UPDATE_CONTENT_ABOUT_US_FAILURE',


  GET_CONTENT_CONTACT_US_INITIATE: 'GET_CONTENT_CONTACT_US_INITIATE',
  GET_CONTENT_CONTACT_US_SUCCESS:  'GET_CONTENT_CONTACT_US_SUCCESS',
  GET_CONTENT_CONTACT_US_FAILURE:  'GET_CONTENT_CONTACT_US_FAILURE',

  UPDATE_CONTENT_CONTACT_US_INITIATE: 'UPDATE_CONTENT_CONTACT_US_INITIATE',
  UPDATE_CONTENT_CONTACT_US_SUCCESS:  'UPDATE_CONTENT_CONTACT_US_SUCCESS',
  UPDATE_CONTENT_CONTACT_US_FAILURE:  'UPDATE_CONTENT_CONTACT_US_FAILURE',

  GET_CONTENT_TERM_CONDITION_INITIATE: 'GET_CONTENT_TERM_CONDITION_INITIATE',
  GET_CONTENT_TERM_CONDITION_SUCCESS:  'GET_CONTENT_TERM_CONDITION_SUCCESS',
  GET_CONTENT_TERM_CONDITION_FAILURE:  'GET_CONTENT_TERM_CONDITION_FAILURE',


  UPDATE_CONTENT_TERM_CONDITION_INITIATE: 'UPDATE_CONTENT_TERM_CONDITION_INITIATE',
  UPDATE_CONTENT_TERM_CONDITION_SUCCESS:  'UPDATE_CONTENT_TERM_CONDITION_SUCCESS',
  UPDATE_CONTENT_TERM_CONDITION_FAILURE:  'UPDATE_CONTENT_TERM_CONDITION_FAILURE',



}







export const subscriptionManagementConstant = {

  GET_SUBSCRIPTION_INITIATE: 'GET_SUBSCRIPTION_INITIATE',
  GET_SUBSCRIPTION_SUCCESS: 'GET_SUBSCRIPTION_SUCCESS',
  GET_SUBSCRIPTION_FAILURE: 'GET_SUBSCRIPTION_FAILURE',
    
  ADD_SUBSCRIPTION_INITIATE: 'ADD_SUBSCRIPTION_INITIATE',
  ADD_SUBSCRIPTION_SUCCESS:  'ADD_SUBSCRIPTION_SUCCESS',
  ADD_SUBSCRIPTION_FAILURE:  'ADD_SUBSCRIPTION_FAILURE',

  EDIT_SUBSCRIPTION_INITIATE: 'EDIT_SUBSCRIPTION_INITIATE',
  EDIT_SUBSCRIPTION_SUCCESS:  'EDIT_SUBSCRIPTION_SUCCESS',
  EDIT_SUBSCRIPTION_FAILURE:  'EDIT_SUBSCRIPTION_FAILURE',

  REMOVE_SUBSCRIPTION_INITIATE: 'REMOVE_SUBSCRIPTION_INITIATE',
  REMOVE_SUBSCRIPTION_SUCCESS:  'REMOVE_SUBSCRIPTION_SUCCESS',
  REMOVE_SUBSCRIPTION_FAILURE:  'REMOVE_SUBSCRIPTION_FAILURE',



  SUBSCRIPTION_LIST_INITIATE: 'SUBSCRIPTION_LIST_INITIATE',
  SUBSCRIPTION_LIST_SUCCESS:  'SUBSCRIPTION_LIST_SUCCESS',
  SUBSCRIPTION_LIST_FAILURE:  'SUBSCRIPTION_LIST_FAILURE',


  ACTIVE_SUBSCRIPTION_LIST_INITIATE: 'ACTIVE_SUBSCRIPTION_LIST_INITIATE',
  ACTIVE_SUBSCRIPTION_LIST_SUCCESS:  'ACTIVE_SUBSCRIPTION_LIST_SUCCESS',
  ACTIVE_SUBSCRIPTION_LIST_FAILURE:  'ACTIVE_SUBSCRIPTION_LIST_FAILURE',


  CANCELLED_SUBSCRIPTION_LIST_INITIATE: 'CANCELLED_SUBSCRIPTION_LIST_INITIATE',
  CANCELLED_SUBSCRIPTION_LIST_SUCCESS:  'CANCELLED_SUBSCRIPTION_LIST_SUCCESS',
  CANCELLED_SUBSCRIPTION_LIST_FAILURE:  'CANCELLED_SUBSCRIPTION_LIST_FAILURE',

  HOTEL_SUBSCRIPTION_LIST_INITIATE: 'HOTEL_SUBSCRIPTION_LIST_INITIATE',
  HOTEL_SUBSCRIPTION_LIST_SUCCESS: 'HOTEL_SUBSCRIPTION_LIST_SUCCESS',
  HOTEL_SUBSCRIPTION_LIST_FAILURE: 'HOTEL_SUBSCRIPTION_LIST_FAILURE',

  SET_CUSTOM_SUBSCRIPTION_FOR_HOTEL_INITIATE : 'SET_CUSTOM_SUBSCRIPTION_FOR_HOTEL_INITIATE',
  SET_CUSTOM_SUBSCRIPTION_FOR_HOTEL_SUCCESS : 'SET_CUSTOM_SUBSCRIPTION_FOR_HOTEL_SUCCESS',
  SET_CUSTOM_SUBSCRIPTION_FOR_HOTEL_FAILURE : 'SET_CUSTOM_SUBSCRIPTION_FOR_HOTEL_FAILURE',

  GET_SUBSCRIPTION_COUPON_INITIATE: 'GET_SUBSCRIPTION_COUPON_INITIATE',
  GET_SUBSCRIPTION_COUPON_SUCCESS: 'GET_SUBSCRIPTION_COUPON_SUCCESS',
  GET_SUBSCRIPTION_COUPON_FAILURE: 'GET_SUBSCRIPTION_COUPON_FAILURE',

  COUPON_STATUS_INITIATE: 'COUPON_STATUS_INITIATE',
  COUPON_STATUS_SUCCESS: 'COUPON_STATUS_SUCCESS',
  COUPON_STATUS_FAILURE: 'COUPON_STATUS_FAILURE',

  CREATE_COUPON_INITIATE: 'CREATE_COUPON_INITIATE',
  CREATE_COUPON_SUCCESS: 'CREATE_COUPON_SUCCESS',
  CREATE_COUPON_FAILURE: 'CREATE_COUPON_FAILURE',

  UPDATE_COUPON_INITIATE: 'UPDATE_COUPON_INITIATE',
  UPDATE_COUPON_SUCCESS: 'UPDATE_COUPON_SUCCESS',
  UPDATE_COUPON_FAILURE: 'UPDATE_COUPON_FAILURE',

  GET_SUBSCRIPTION_TYPE_INITIATE: 'GET_SUBSCRIPTION_TYPE_INITIATE',
  GET_SUBSCRIPTION_TYPE_SUCCESS: 'GET_SUBSCRIPTION_TYPE_SUCCESS',
  GET_SUBSCRIPTION_TYPE_FAILURE: 'GET_SUBSCRIPTION_TYPE_FAILURE',

  GET_SUBSCRIPTION_DETAILS_INITIATE: "GET_SUBSCRIPTION_DETAILS_INITIATE",
  GET_SUBSCRIPTION_DETAILS_SUCCESS: "GET_SUBSCRIPTION_DETAILS_SUCCESS",
  GET_SUBSCRIPTION_DETAILS_FAILURE: "GET_SUBSCRIPTION_DETAILS_FAILURE",

  UPDATE_SUBSCRIPTION_GST_INITIATE:"UPDATE_SUBSCRIPTION_GST_INITIATE",
  UPDATE_SUBSCRIPTION_GST_SUCCESS:"UPDATE_SUBSCRIPTION_GST_SUCCESS",
  UPDATE_SUBSCRIPTION_GST_FAILURE:"UPDATE_SUBSCRIPTION_GST_FAILURE",

}



export const roomConstant = {
    
  ADD_ROOM_INITIATE: 'ADD_ROOM_INITIATE',
  ADD_ROOM_SUCCESS:  'ADD_ROOM_SUCCESS',
  ADD_ROOM_FAILURE:  'ADD_ROOM_FAILURE',

  EDIT_ROOM_INITIATE: 'EDIT_ROOM_INITIATE',
  EDIT_ROOM_SUCCESS:  'EDIT_ROOM_SUCCESS',
  EDIT_ROOM_FAILURE:  'EDIT_ROOM_FAILURE',

  REMOVE_ROOM_INITIATE: 'REMOVE_ROOM_INITIATE',
  REMOVE_ROOM_SUCCESS:  'REMOVE_ROOM_SUCCESS',
  REMOVE_ROOM_FAILURE:  'REMOVE_ROOM_FAILURE',


  ROOM_LIST_INITIATE: 'ROOM_LIST_INITIATE',
  ROOM_LIST_SUCCESS:  'ROOM_LIST_SUCCESS',
  ROOM_LIST_FAILURE:  'ROOM_LIST_FAILURE',



  ADD_ROOM_MIN_BAR_INITIATE: 'ADD_ROOM_MIN_BAR_INITIATE',
ADD_ROOM_MIN_BAR_SUCCESS:  'ADD_ROOM_MIN_BAR_SUCCESS',
ADD_ROOM_MIN_BAR_FAILURE:  'ADD_ROOM_MIN_BAR_FAILURE',

EDIT_ROOM_MIN_BAR_INITIATE: 'EDIT_ROOM_MIN_BAR_INITIATE',
EDIT_ROOM_MIN_BAR_SUCCESS:  'EDIT_ROOM_MIN_BAR_SUCCESS',
EDIT_ROOM_MIN_BAR_FAILURE:  'EDIT_ROOM_MIN_BAR_FAILURE',

REMOVE_ROOM_MIN_BAR_INITIATE: 'REMOVE_ROOM_MIN_BAR_INITIATE',
REMOVE_ROOM_MIN_BAR_SUCCESS:  'REMOVE_ROOM_MIN_BAR_SUCCESS',
REMOVE_ROOM_MIN_BAR_FAILURE:  'REMOVE_ROOM_MIN_BAR_FAILURE',


ROOM_MIN_BAR_LIST_INITIATE: 'ROOM_MIN_BAR_LIST_INITIATE',
ROOM_MIN_BAR_LIST_SUCCESS:  'ROOM_MIN_BAR_LIST_SUCCESS',
ROOM_MIN_BAR_LIST_FAILURE:  'ROOM_MIN_BAR_MIN_BAR_LIST_FAILURE',



ADD_ROOM_CATEGORY_INITIATE: 'ADD_ROOM_CATEGORY_INITIATE',
ADD_ROOM_CATEGORY_SUCCESS:  'ADD_ROOM_CATEGORY_SUCCESS',
ADD_ROOM_CATEGORY_FAILURE:  'ADD_ROOM_CATEGORY_FAILURE',


ROOM_CATEGORY_LIST_INITIATE: 'ROOM_CATEGORY_LIST_INITIATE',
ROOM_CATEGORY_LIST_SUCCESS:  'ROOM_CATEGORY_LIST_SUCCESS',
ROOM_CATEGORY_LIST_FAILURE:  'ROOM_CATEGORY_LIST_FAILURE',



}



export const wifiConstant = {
    
  ADD_WIFI_INITIATE: 'ADD_WIFI_INITIATE',
  ADD_WIFI_SUCCESS:  'ADD_WIFI_SUCCESS',
  ADD_WIFI_FAILURE:  'ADD_WIFI_FAILURE',
  
  EDIT_WIFI_INITIATE: 'EDIT_WIFI_INITIATE',
  EDIT_WIFI_SUCCESS:  'EDIT_WIFI_SUCCESS',
  EDIT_WIFI_FAILURE:  'EDIT_WIFI_FAILURE',
  
  REMOVE_WIFI_INITIATE: 'REMOVE_WIFI_INITIATE',
  REMOVE_WIFI_SUCCESS:  'REMOVE_WIFI_SUCCESS',
  REMOVE_WIFI_FAILURE:  'REMOVE_WIFI_FAILURE',
  
  
  WIFI_LIST_INITIATE: 'WIFI_LIST_INITIATE',
  WIFI_LIST_SUCCESS:  'WIFI_LIST_SUCCESS',
  WIFI_LIST_FAILURE:  'WIFI_LIST_FAILURE',
  
}



export const activitiesConstant ={

  ADD_ACTIVITIES_INITIATE: 'ADD_ACTIVITIES_INITIATE',
  ADD_ACTIVITIES_SUCCESS:  'ADD_ACTIVITIES_SUCCESS',
  ADD_ACTIVITIES_FAILURE:  'ADD_ACTIVITIES_FAILURE',


  UPDATE_ACTIVITIES_INITIATE: 'UPDATE_ACTIVITIES_INITIATE',
  UPDATE_ACTIVITIES_SUCCESS:  'UPDATE_ACTIVITIES_SUCCESS',
  UPDATE_ACTIVITIES_FAILURE:  'UPDATE_ACTIVITIES_FAILURE',

  ACTIVITIES_LIST_INITIATE: 'ACTIVITIES_LIST_INITIATE',
  ACTIVITIES_LIST_SUCCESS:  'ACTIVITIES_LIST_SUCCESS',
  ACTIVITIES_LIST_FAILURE:  'ACTIVITIES_LIST_FAILURE',

  REMOVE_ACTIVITIES_INITIATE: 'REMOVE_ACTIVITIES_INITIATE',
  REMOVE_ACTIVITIES_SUCCESS:  'REMOVE_ACTIVITIES_SUCCESS',
  REMOVE_ACTIVITIES_FAILURE:  'REMOVE_ACTIVITIES_FAILURE',



}

export const hotelConstant ={

  HOTEL_RATING_INITIATE: 'HOTEL_RATING_INITIATE',
  HOTEL_RATING_SUCCESS:  'HOTEL_RATING_SUCCESS',
  HOTEL_RATING_FAILURE:  'HOTEL_RATING_FAILURE',

  HOTEL_LIST_INITIATE: 'HOTEL_LIST_INITIATE',
  HOTEL_LIST_SUCCESS:  'HOTEL_LIST_SUCCESS',
  HOTEL_LIST_FAILURE:  'HOTEL_LIST_FAILURE',

  HOTEL_REVIEW_LIST_INITIATE: 'HOTEL_REVIEW_LIST_INITIATE',
  HOTEL_REVIEW_LIST_SUCCESS:  'HOTEL_REVIEW_LIST_SUCCESS',
  HOTEL_REVIEW_LIST_FAILURE:  'HOTEL_REVIEW_LIST_FAILURE',

  HOTEL_REVIEW_REPLY_INITIATE: 'HOTEL_REVIEW_REPLY_INITIATE',
  HOTEL_REVIEW_REPLY_SUCCESS:  'HOTEL_REVIEW_REPLY_SUCCESS',
  HOTEL_REVIEW_REPLY_FAILURE:  'HOTEL_REVIEW_REPLY_FAILURE',



  HOTEL_STATUS_CHANGE_INITIATE: 'HOTEL_STATUS_CHANGE_INITIATE',
  HOTEL_STATUS_CHANGE_SUCCESS:  'HOTEL_STATUS_CHANGE_SUCCESS',
  HOTEL_STATUS_CHANGE_FAILURE:  'HOTEL_STATUS_CHANGE_FAILURE',


  HOTEL_DETAILS_INITIATE: 'HOTEL_DETAILS_INITIATE',
  HOTEL_DETAILS_SUCCESS:  'HOTEL_DETAILS_SUCCESS',
  HOTEL_DETAILS_FAILURE:  'HOTEL_DETAILS_FAILURE',



  INVITE_HOTEL_INITIATE: 'INVITE_HOTEL_INITIATE',
  INVITE_HOTEL_SUCCESS:  'INVITE_HOTEL_SUCCESS',
  INVITE_HOTEL_FAILURE:  'INVITE_HOTEL_FAILURE',

  HOTEL_TRANSACTION_DETAILS_INITIATE: 'HOTEL_TRANSACTION_DETAILS_INITIATE',
  HOTEL_TRANSACTION_DETAILS_SUCCESS:  'HOTEL_TRANSACTION_DETAILS_SUCCESS',
  HOTEL_TRANSACTION_DETAILS_FAILURE:  'HOTEL_TRANSACTION_DETAILS_FAILURE',

  
  HOTEL_TRANSACTION_INITIATE: 'HOTEL_TRANSACTION_INITIATE',
  HOTEL_TRANSACTION_SUCCESS:  'HOTEL_TRANSACTION_SUCCESS',
  HOTEL_TRANSACTION_FAILURE:  'HOTEL_TRANSACTION_FAILURE',

  HOTEL_DELETE_INITIATE: 'HOTEL_DELETE_INITIATE',
  HOTEL_DELETE_SUCCESS:  'HOTEL_DELETE_SUCCESS',
  HOTEL_DELETE_FAILURE:  'HOTEL_DELETE_FAILURE',


  HOTEL_UPDATE_INITIATE: 'HOTEL_UPDATE_INITIATE',
  HOTEL_UPDATE_SUCCESS:  'HOTEL_UPDATE_SUCCESS',
  HOTEL_UPDATE_FAILURE:  'HOTEL_UPDATE_FAILURE',



  EDIT_SPLITPERCENTAGE_INITIATE: "EDIT_SPLITPERCENTAGE_INITIATE",
  EDIT_SPLITPERCENTAGE_SUCCESS: "EDIT_SPLITPERCENTAGE_SUCCESS",
  EDIT_SPLITPERCENTAGE_FAILURE: "EDIT_SPLITPERCENTAGE_FAILURE",

}



export const foodBeverageConstant ={

  ADD_FOOD_BEVERAGE_INITIATE: 'ADD_FOOD_BEVERAGE_INITIATE',
  ADD_FOOD_BEVERAGE_SUCCESS:  'ADD_FOOD_BEVERAGE_SUCCESS',
  ADD_FOOD_BEVERAGE_FAILURE:  'ADD_FOOD_BEVERAGE_FAILURE',


  UPDATE_FOOD_BEVERAGE_INITIATE: 'UPDATE_FOOD_BEVERAGE_INITIATE',
  UPDATE_FOOD_BEVERAGE_SUCCESS:  'UPDATE_FOOD_BEVERAGE_SUCCESS',
  UPDATE_FOOD_BEVERAGE_FAILURE:  'UPDATE_FOOD_BEVERAGE_FAILURE',

  FOOD_BEVERAGE_LIST_INITIATE: 'FOOD_BEVERAGE_LIST_INITIATE',
  FOOD_BEVERAGE_LIST_SUCCESS:  'FOOD_BEVERAGE_LIST_SUCCESS',
  FOOD_BEVERAGE_LIST_FAILURE:  'FOOD_BEVERAGE_LIST_FAILURE',

  REMOVE_FOOD_BEVERAGE_INITIATE: 'REMOVE_FOOD_BEVERAGE_INITIATE',
  REMOVE_FOOD_BEVERAGE_SUCCESS:  'REMOVE_FOOD_BEVERAGE_SUCCESS',
  REMOVE_FOOD_BEVERAGE_FAILURE:  'REMOVE_FOOD_BEVERAGE_FAILURE',


  FOOD_BEVERAGE_CATEGORY_LIST_INITIATE: 'FOOD_BEVERAGE_CATEGORY_LIST_INITIATE',
  FOOD_BEVERAGE_CATEGORY_LIST_SUCCESS:  'FOOD_BEVERAGE_CATEGORY_LIST_SUCCESS',
  FOOD_BEVERAGE_CATEGORY_LIST_FAILURE:  'FOOD_BEVERAGE_CATEGORY_LIST_FAILURE',



}



export const roleConstant ={

  ADD_ROLE_INITIATE: 'ADD_ROLE_INITIATE',
  ADD_ROLE_SUCCESS:  'ADD_ROLE_SUCCESS',
  ADD_ROLE_FAILURE:  'ADD_ROLE_FAILURE',


  ROLE_MANAGEMENT_INITIATE: 'ROLE_MANAGEMENT_INITIATE',
  ROLE_MANAGEMENT_SUCCESS:  'ROLE_MANAGEMENT_SUCCESS',
  ROLE_MANAGEMENT_FAILURE:  'ROLE_MANAGEMENT_FAILURE',


  REMOVE_ROLE_INITIATE: 'REMOVE_ROLE_INITIATE',
  REMOVE_ROLE_SUCCESS:  'REMOVE_ROLE_SUCCESS',
  REMOVE_ROLE_FAILURE:  'REMOVE_ROLE_FAILURE',


  UPDATE_ROLE_INITIATE: 'UPDATE_ROLE_INITIATE',
  UPDATE_ROLE_SUCCESS:  'UPDATE_ROLE_SUCCESS',
  UPDATE_ROLE_FAILURE:  'UPDATE_ROLE_FAILURE',


  STATUS_ROLE_INITIATE: 'STATUS_ROLE_INITIATE',
  STATUS_ROLE_SUCCESS:  'STATUS_ROLE_SUCCESS',
  STATUS_ROLE_FAILURE:  'STATUS_ROLE_FAILURE',



}




export const spaConstant ={

  ADD_SPA_INITIATE: 'ADD_SPA_INITIATE',
  ADD_SPA_SUCCESS:  'ADD_SPA_SUCCESS',
  ADD_SPA_FAILURE:  'ADD_SPA_FAILURE',


  UPDATE_SPA_INITIATE: 'UPDATE_SPA_INITIATE',
  UPDATE_SPA_SUCCESS:  'UPDATE_SPA_SUCCESS',
  UPDATE_SPA_FAILURE:  'UPDATE_SPA_FAILURE',

  SPA_LIST_INITIATE: 'SPA_LIST_INITIATE',
  SPA_LIST_SUCCESS:  'SPA_LIST_SUCCESS',
  SPA_LIST_FAILURE:  'SPA_LIST_FAILURE',

  REMOVE_SPA_INITIATE: 'REMOVE_SPA_INITIATE',
  REMOVE_SPA_SUCCESS:  'REMOVE_SPA_SUCCESS',
  REMOVE_SPA_FAILURE:  'REMOVE_SPA_FAILURE',


  UPDATE_SPA_TIME_INITIATE: 'UPDATE_SPA_TIME_INITIATE',
  UPDATE_SPA_TIME_SUCCESS:  'UPDATE_SPA_TIME_SUCCESS',
  UPDATE_SPA_TIME_FAILURE:  'UPDATE_SPA_TIME_FAILURE',



  SPA_TAX_INITIATE: 'SPA_TAX_INITIATE',
  SPA_TAX_SUCCESS:  'SPA_TAX_SUCCESS',
  SPA_TAX_FAILURE:  'SPA_TAX_FAILURE',



}


export const houseKeepingConstant ={

  ADD_HOUSE_KEEPING_INITIATE: 'ADD_HOUSE_KEEPING_INITIATE',
  ADD_HOUSE_KEEPING_SUCCESS:  'ADD_HOUSE_KEEPING_SUCCESS',
  ADD_HOUSE_KEEPING_FAILURE:  'ADD_HOUSE_KEEPING_FAILURE',


  UPDATE_HOUSE_KEEPING_INITIATE: 'UPDATE_HOUSE_KEEPING_INITIATE',
  UPDATE_HOUSE_KEEPING_SUCCESS:  'UPDATE_HOUSE_KEEPING_SUCCESS',
  UPDATE_HOUSE_KEEPING_FAILURE:  'UPDATE_HOUSE_KEEPING_FAILURE',

  HOUSE_KEEPING_LIST_INITIATE: 'HOUSE_KEEPING_LIST_INITIATE',
  HOUSE_KEEPING_LIST_SUCCESS:  'HOUSE_KEEPING_LIST_SUCCESS',
  HOUSE_KEEPING_LIST_FAILURE:  'HOUSE_KEEPING_LIST_FAILURE',

  REMOVE_HOUSE_KEEPING_INITIATE: 'REMOVE_HOUSE_KEEPING_INITIATE',
  REMOVE_HOUSE_KEEPING_SUCCESS:  'REMOVE_HOUSE_KEEPING_SUCCESS',
  REMOVE_HOUSE_KEEPING_FAILURE:  'REMOVE_HOUSE_KEEPING_FAILURE',


  HOUSE_KEEPING_REQUEST_INITIATE :'HOUSE_KEEPING_REQUEST_INITIATE',
  HOUSE_KEEPING_REQUEST_SUCCESS : 'HOUSE_KEEPING_REQUEST_SUCCESS',
  HOUSE_KEEPING_REQUEST_FAILURE : 'HOUSE_KEEPING_REQUEST_FAILURE',


  REQUEST_STATUS_INITIATE: 'REQUEST_STATUS_INITIATE',
  REQUEST_STATUS_SUCCESS:  'REQUEST_STATUS_SUCCESS',
  REQUEST_STATUS_FAILURE:  'REQUEST_STATUS_FAILURE',

  REQUEST_ASSIGN_INITIATE: 'REQUEST_ASSIGN_INITIATE',
  REQUEST_ASSIGN_SUCCESS:  'REQUEST_ASSIGN_SUCCESS',
  REQUEST_ASSIGN_FAILURE:  'REQUEST_ASSIGN_FAILURE',



}



export const trendingConstant ={

  ADD_TRENDING_INITIATE: 'ADD_TRENDING_INITIATE',
  ADD_TRENDING_SUCCESS:  'ADD_TRENDING_SUCCESS',
  ADD_TRENDING_FAILURE:  'ADD_TRENDING_FAILURE',


  UPDATE_TRENDING_INITIATE: 'UPDATE_TRENDING_INITIATE',
  UPDATE_TRENDING_SUCCESS:  'UPDATE_TRENDING_SUCCESS',
  UPDATE_TRENDING_FAILURE:  'UPDATE_TRENDING_FAILURE',

  TRENDING_LIST_INITIATE: 'TRENDING_LIST_INITIATE',
  TRENDING_LIST_SUCCESS:  'TRENDING_LIST_SUCCESS',
  TRENDING_LIST_FAILURE:  'TRENDING_LIST_FAILURE',
  
  ADD_TRENDING_LIST_INITIATE: 'ADD_TRENDING_LIST_INITIATE',
  ADD_TRENDING_LIST_SUCCESS:  'ADD_TRENDING_LIST_SUCCESS',
  ADD_TRENDING_LIST_FAILURE:  'ADD_TRENDING_LIST_FAILURE',


  REMOVE_TRENDING_INITIATE: 'REMOVE_TRENDING_INITIATE',
  REMOVE_TRENDING_SUCCESS:  'REMOVE_TRENDING_SUCCESS',
  REMOVE_TRENDING_FAILURE:  'REMOVE_TRENDING_FAILURE',



}


export const couponConstant ={

  ADD_COUPON_INITIATE: 'ADD_COUPON_INITIATE',
  ADD_COUPON_SUCCESS:  'ADD_COUPON_SUCCESS',
  ADD_COUPON_FAILURE:  'ADD_COUPON_FAILURE',


  UPDATE_COUPON_INITIATE: 'UPDATE_COUPON_INITIATE',
  UPDATE_COUPON_SUCCESS:  'UPDATE_COUPON_SUCCESS',
  UPDATE_COUPON_FAILURE:  'UPDATE_COUPON_FAILURE',

  COUPON_LIST_INITIATE: 'COUPON_LIST_INITIATE',
  COUPON_LIST_SUCCESS:  'COUPON_LIST_SUCCESS',
  COUPON_LIST_FAILURE:  'COUPON_LIST_FAILURE',
  

  REMOVE_COUPON_INITIATE: 'REMOVE_COUPON_INITIATE',
  REMOVE_COUPON_SUCCESS:  'REMOVE_COUPON_SUCCESS',
  REMOVE_COUPON_FAILURE:  'REMOVE_COUPON_FAILURE',


  COUPON_USEAGE_INITIATE: 'COUPON_USEAGE_INITIATE',
  COUPON_USEAGE_SUCCESS:  'COUPON_USEAGE_SUCCESS',
  COUPON_USEAGE_FAILURE:  'COUPON_USEAGE_FAILURE',



}




export const guestConstant ={

  ADD_GUEST_INITIATE: 'ADD_GUEST_INITIATE',
  ADD_GUEST_SUCCESS:  'ADD_GUEST_SUCCESS',
  ADD_GUEST_FAILURE:  'ADD_GUEST_FAILURE',


  INVITED_GUEST_INITIATE: 'INVITED_GUEST_INITIATE',
  INVITED_GUEST_SUCCESS:  'INVITED_GUEST_SUCCESS',
  INVITED_GUEST_FAILURE:  'INVITED_GUEST_FAILURE',


  CHECK_IN_wITH_OTP_INITIATE: 'CHECK_IN_wITH_OTP_INITIATE',
  CHECK_IN_wITH_OTP_SUCCESS:  'CHECK_IN_wITH_OTP_SUCCESS',
  CHECK_IN_wITH_OTP_FAILURE:  'CHECK_IN_wITH_OTP_FAILURE',


  MANUAL_CHECK_IN_INITIATE: 'MANUAL_CHEC_IN_INITIATE',
  MANUAL_CHECK_IN_SUCCESS:  'MANUAL_CHEC_IN_SUCCESS',
  MANUAL_CHECK_IN_FAILURE:  'MANUAL_CHEC_IN_FAILURE',


  ASSIGN_GUEST_INITIATE: 'ASSIGN_GUEST_INITIATE',
  ASSIGN_GUEST_SUCCESS:  'ASSIGN_GUEST_SUCCESS',
  ASSIGN_GUEST_FAILURE:  'ASSIGN_GUEST_FAILURE',


  CHECK_IN_USER_LIST_INITIATE: 'CHECK_IN_USER_LIST_INITIATE',
  CHECK_IN_USER_LIST_SUCCESS:  'CHECK_IN_USER_LIST_SUCCESS',
  CHECK_IN_USER_LIST_FAILURE:  'CHECK_IN_USER_LIST_FAILURE',


  
  AVAILABLE_ROOMS_INITIATE:  'AVAILABLE_ROOMS_INTIATE',
  AVAILABLE_ROOMS_SUCCESS :  'AVAILABLE_ROOMS_SUCCESS',
  AVAILABLE_ROOMS_FAILURE :  'AVAILABLE_ROOMS_FAILURE',


  UPDATE_KYC_DETAILS_INITIATE: 'UPDATE_KYC_DETAILS_INITIATE',
  UPDATE_KYC_DETAILS_SUCCESS:  'UPDATE_KYC_DETAILS_SUCCESS',
  UPDATE_KYC_DETAILS_FAILURE:  'UPDATE_KYC_DETAILS_FAILURE',


  GUEST_DATA_INITIATE: 'GUEST_DATA_INITIATE',
  GUEST_DATA_SUCCESS:  'GUEST_DATA_SUCCESS',
  GUEST_DATA_FAILURE:  'GUEST_DATA_FAILURE',

  GUEST_EXTEND_CHECKOUT_INITIATE: 'GUEST_EXTEND_CHECKOUT_INITIATE',
  GUEST_EXTEND_CHECKOUT_SUCCESS:  'GUEST_EXTEND_CHECKOUT_SUCCESS',
  GUEST_EXTEND_CHECKOUT_FAILURE:  'GUEST_EXTEND_CHECKOUT_FAILURE',

  GUEST_APPROVE_EXTEND_CHECKOUT_INITIATE: 'GUEST_APPROVE_EXTEND_CHECKOUT_INITIATE',
  GUEST_APPROVE_EXTEND_CHECKOUT_SUCCESS:  'GUEST_APPROVE_EXTEND_CHECKOUT_SUCCESS',
  GUEST_APPROVE_EXTEND_CHECKOUT_FAILURE:  'GUEST_APPROVE_EXTEND_CHECKOUT_FAILURE',

APPROVE_EXTEND_CHECKOUT_INITIATE: 'APPROVE_EXTEND_CHECKOUT_INITIATE',
APPROVE_EXTEND_CHECKOUT_SUCCESS:  'APPROVE_EXTEND_CHECKOUT_SUCCESS',
APPROVE_EXTEND_CHECKOUT_FAILURE:  'APPROVE_EXTEND_CHECKOUT_FAILURE',


GUEST_CHECKOUT_REQUEST_INITIATE: 'GUEST_CHECKOUT_REQUEST_INITIATE',
GUEST_CHECKOUT_REQUEST_SUCCESS:  'GUEST_CHECKOUT_REQUEST_SUCCESS',
GUEST_CHECKOUT_REQUEST_FAILURE:  'GUEST_CHECKOUT_REQUEST_FAILURE',


ASSIGN_LUGGAGE_INITIATE: 'ASSIGN_LUGGAGE_REQUEST_INITIATE',
ASSIGN_LUGGAGE_SUCCESS:  'ASSIGN_LUGGAGE_REQUEST_SUCCESS',
ASSIGN_LUGGAGE_FAILURE:  'ASSIGN_LUGGAGE_REQUEST_FAILURE',


APPROVE_CHECKOUT_INITIATE: 'APPROVE_CHECKOUT_INITIATE',
APPROVE_CHECKOUT_SUCCESS:  'APPROVE_CHECKOUT_SUCCESS',
APPROVE_CHECKOUT_FAILURE:  'APPROVE_CHECKOUT_FAILURE',
  

BILLING_LIST_INITIATE: 'BILLING_LIST_INITIATE',
BILLING_LIST_SUCCESS:  'BILLING_LIST_SUCCESS',
BILLING_LIST_FAILURE:  'BILLING_LIST_FAILURE',


BILLING_DETAILS_INITIATE: 'BILLING_DETAILS_INITIATE',
BILLING_DETAILS_SUCCESS:  'BILLING_DETAILS_SUCCESS',
BILLING_DETAILS_FAILURE:  'BILLING_DETAILS_FAILURE',

GUEST_ALL_LIST_INITIATE: 'GUEST__ALL_LIST_INITIATE',
GUEST_ALL_LIST_SUCCESS:  'GUEST__ALL_LIST_SUCCESS',
GUEST_ALL_LIST_FAILURE:  'GUEST__ALL_LIST_FAILURE',



GUEST_CHECKOUT_APPROVED_LIST_INITIATE: 'GUEST_CHECKOUT_APPROVED_LIST_INITIATE',
GUEST_CHECKOUT_APPROVED_LIST_SUCCESS:  'GUEST_CHECKOUT_APPROVED_LIST_SUCCESS',
GUEST_CHECKOUT_APPROVED_LIST_FAILURE:  'GUEST_CHECKOUT_APPROVED_LIST_FAILURE',


GUEST_CHECKOUT_COMPLETED_LIST_INITIATE: 'GUEST_CHECKOUT_COMPLETED_LIST_INITIATE',
GUEST_CHECKOUT_COMPLETED_LIST_SUCCESS:  'GUEST_CHECKOUT_COMPLETED_LIST_SUCCESS',
GUEST_CHECKOUT_COMPLETED_LIST_FAILURE:  'GUEST_CHECKOUT_COMPLETED_LIST_FAILURE',


GUEST_WALLET_LIST_INITIATE: 'GUEST_WALLET_LIST_INITIATE',
GUEST_WALLET_LIST_SUCCESS:  'GUEST_WALLET_LIST_SUCCESS',
GUEST_WALLET_LIST_FAILURE:  'GUEST_WALLET_LIST_FAILURE',


SINGLE_REWARD_INITIATE: 'SINGLE_REWARD_INITIATE',
SINGLE_REWARD_SUCCESS:  'SINGLE_REWARD_SUCCESS',
SINGLE_REWARD_FAILURE:  'SINGLE_REWARD_FAILURE',

ALL_REWARD_INITIATE: 'ALL_REWARD_INITIATE',
ALL_REWARD_SUCCESS:  'ALL_REWARD_SUCCESS',
ALL_REWARD_FAILURE:  'ALL_REWARD_FAILURE',


GUEST_STATUS_INITIATE: 'GUEST_STATUS_INITIATE',
GUEST_STATUS_SUCCESS:  'GUEST_STATUS_SUCCESS',
GUEST_STATUS_FAILURE:  'GUEST_STATUS_FAILURE',



}

export const restaurantConstant = {
    
  ADD_SERVICE_CATEGORY_INITIATE: 'ADD_SERVICE_CATEGORY_INITIATE',
  ADD_SERVICE_CATEGORY_SUCCESS:  'ADD_SERVICE_CATEGORY_SUCCESS',
  ADD_SERVICE_CATEGORY_FAILURE:  'ADD_SERVICE_CATEGORY_FAILURE',
  
  EDIT_SERVICE_CATEGORY_INITIATE: 'EDIT_SERVICE_CATEGORY_INITIATE',
  EDIT_SERVICE_CATEGORY_SUCCESS:  'EDIT_SERVICE_CATEGORY_SUCCESS',
  EDIT_SERVICE_CATEGORY_FAILURE:  'EDIT_SERVICE_CATEGORY_FAILURE',
  
  REMOVE_SERVICE_CATEGORY_INITIATE: 'REMOVE_SERVICE_CATEGORY_INITIATE',
  REMOVE_SERVICE_CATEGORY_SUCCESS:  'REMOVE_SERVICE_CATEGORY_SUCCESS',
  REMOVE_SERVICE_CATEGORY_FAILURE:  'REMOVE_SERVICE_CATEGORY_FAILURE',
  
  
  SERVICE_CATEGORY_LIST_INITIATE: 'SERVICE_CATEGORY_LIST_INITIATE',
  SERVICE_CATEGORY_LIST_SUCCESS:  'SERVICE_CATEGORY_LIST_SUCCESS',
  SERVICE_CATEGORY_LIST_FAILURE:  'SERVICE_CATEGORY_LIST_FAILURE',




  ADD_FOOD_CATEGORY_INITIATE: 'ADD_FOOD_CATEGORY_INITIATE',
  ADD_FOOD_CATEGORY_SUCCESS:  'ADD_FOOD_CATEGORY_SUCCESS',
  ADD_FOOD_CATEGORY_FAILURE:  'ADD_FOOD_CATEGORY_FAILURE',
  
  EDIT_FOOD_CATEGORY_INITIATE: 'EDIT_FOOD_CATEGORY_INITIATE',
  EDIT_FOOD_CATEGORY_SUCCESS:  'EDIT_FOOD_CATEGORY_SUCCESS',
  EDIT_FOOD_CATEGORY_FAILURE:  'EDIT_FOOD_CATEGORY_FAILURE',
  
  REMOVE_FOOD_CATEGORY_INITIATE: 'REMOVE_FOOD_CATEGORY_INITIATE',
  REMOVE_FOOD_CATEGORY_SUCCESS:  'REMOVE_FOOD_CATEGORY_SUCCESS',
  REMOVE_FOOD_CATEGORY_FAILURE:  'REMOVE_FOOD_CATEGORY_FAILURE',
  
  
  FOOD_CATEGORY_LIST_INITIATE: 'FOOD_CATEGORY_LIST_INITIATE',
  FOOD_CATEGORY_LIST_SUCCESS:  'FOOD_CATEGORY_LIST_SUCCESS',
  FOOD_CATEGORY_LIST_FAILURE:  'FOOD_CATEGORY_LIST_FAILURE',


  ADD_RESTAURANT_TIME_INITIATE: 'ADD_RESTAURANT_TIME_INITIATE',
  ADD_RESTAURANT_TIME_SUCCESS:  'ADD_RESTAURANT_TIME_SUCCESS',
  ADD_RESTAURANT_TIME_FAILURE:  'ADD_RESTAURANT_TIME_FAILURE',

  FOOD_ORDER_REQUEST_LIST_INITIATE: 'FOOD_ORDER_REQUEST_LIST_INITIATE',
  FOOD_ORDER_REQUEST_LIST_SUCCESS:  'FOOD_ORDER_REQUEST_LIST_SUCCESS',
  FOOD_ORDER_REQUEST_LIST_FAILURE:  'FOOD_ORDER_REQUEST_LIST_FAILURE',


  CREATE_FOOD_ORDER_INITIATE: 'CREATE_FOOD_ORDER_INITIATE',
  CREATE_FOOD_ORDER_SUCCESS:  'CREATE_FOOD_ORDER_SUCCESS',
  CREATE_FOOD_ORDER_FAILURE:  'CREATE_FOOD_ORDER_FAILURE',

  ASSIGN_FOOD_ORDER_INITIATE: 'ASSIGN_FOOD_ORDER_INITIATE',
  ASSIGN_FOOD_ORDER_SUCCESS:  'ASSIGN_FOOD_ORDER_SUCCESS',
  ASSIGN_FOOD_ORDER_FAILURE:  'ASSIGN_FOOD_ORDER_FAILURE',

  AVAILABLE_STAFF_INITIATE:'AVAILABLE_STAFF_ORDER_INITIATE',
  AVAILABLE_STAFF_SUCCESS: 'AVAILABLE_STAFF_ORDER_SUCCESS',
  AVAILABLE_STAFF_FAILURE: 'AVAILABLE_STAFF_ORDER_FAILURE',


  ORDER_STATUS_INITIATE: 'ORDER_STATUS_INITIATE',
  ORDER_STATUS_SUCCESS:  'ORDER_STATUS_SUCCESS',
  ORDER_STATUS_FAILURE:  'ORDER_STATUS_FAILURE',


  RESTAURANT_TAX_INITIATE: 'RESTAURANT_TAX_INITIATE',
  RESTAURANT_TAX_SUCCESS:  'RESTAURANT_TAX_SUCCESS',
  RESTAURANT_TAX_FAILURE:  'RESTAURANT_TAX_FAILURE',






}


export const dropdownConstant = {

  OCCUPIED_ROOM_INITIATE: 'OCCUPIED_ROOM_INITIATE',
  OCCUPIED_ROOM_SUCCESS:  'OCCUPIED_ROOM_SUCCESS',
  OCCUPIED_ROOM_FAILURE:  'OCCUPIED_ROOM_FAILURE',


  GUEST_LIST_INITIATE: 'GUEST_LIST_INITIATE',
  GUEST_LIST_SUCCESS:  'GUEST_LIST_SUCCESS',
  GUEST_LIST_FAILURE:  'GUEST_LIST_FAILURE',



}


export const chatConstant = {
  CHAT_HISTORY_INITIATE: 'CHAT_HISTORY_INITIATE',
  CHAT_HISTORY_SUCCESS:  'CHAT_HISTORY_SUCCESS',
  CHAT_HISTORY_FAILURE:  'CHAT_HISTORY_FAILURE',


  CHAT_LIST_INITIATE: 'CHAT_LIST_INITIATE',
  CHAT_LIST_SUCCESS:  'CHAT_LIST_SUCCESS',
  CHAT_LIST_FAILURE:  'CHAT_LIST_FAILURE',


  CHAT_ROOM_HISTORY_INITIATE: 'CHAT_ROOM_HISTORY_INITIATE',
  CHAT_ROOM_HISTORY_SUCCESS:  'CHAT_ROOM_HISTORY_SUCCESS',
  CHAT_ROOM_HISTORY_FAILURE:  'CHAT_ROOM_HISTORY_FAILURE',

  CHAT_CLOSE_INITIATE: 'CHAT_CLOSE_INITIATE',
  CHAT_CLOSE_SUCCESS:  'CHAT_CLOSE_SUCCESS',
  CHAT_CLOSE_FAILURE:  'CHAT_CLOSE_FAILURE',


}


export const financeConstant ={


  FINANCE_LIST_INITIATE: 'FINANCE_LIST_INITIATE',
  FINANCE_LIST_SUCCESS:  'FINANCE_LIST_SUCCESS',
  FINANCE_LIST_FAILURE:  'FINANCE_LIST_FAILURE',


  ANALYTIC_LIST_INITIATE: 'ANALYTIC_LIST_INITIATE',
  ANALYTIC_LIST_SUCCESS:  'ANALYTIC_LIST_SUCCESS',
  ANALYTIC_LIST_FAILURE:  'ANALYTIC_LIST_FAILURE',

  HOTEL_ALL_COUPON_USAGE_INITIATE: 'HOTEL_ALL_COUPON_USAGE_INITIATE',
  HOTEL_ALL_COUPON_USAGE_SUCCESS:  'HOTEL_ALL_COUPON_USAGE_SUCCESS',
  HOTEL_ALL_COUPON_USAGE_FAILURE:  'HOTEL_ALL_COUPON_USAGE_FAILURE',





}




export const pushNotificationConstant={
  SEND_GUEST_NOTIFICATION_INITIATE: 'SEND_GUEST_NOTIFICATION_INITIATE',
  SEND_GUEST_NOTIFICATION_SUCCESS:  'SEND_GUEST_NOTIFICATION_SUCCESS',
  SEND_GUEST_NOTIFICATION_FAILURE:  'SEND_GUEST_NOTIFICATION_FAILURE',
  
  RESEND_GUEST_NOTIFICATION_INITIATE: 'RESEND_GUEST_NOTIFICATION_INITIATE',
  RESEND_GUEST_NOTIFICATION_SUCCESS:  'RESEND_GUEST_NOTIFICATION_SUCCESS',
  RESEND_GUEST_NOTIFICATION_FAILURE:  'RESEND_GUEST_NOTIFICATION_FAILURE',
  
  GUEST_NOTIFICATION_LIST_INITIATE: 'GUEST_NOTIFICATION_LIST_INITIATE',
  GUEST_NOTIFICATION_LIST_SUCCESS:  'GUEST_NOTIFICATION_LIST_SUCCESS',
  GUEST_NOTIFICATION_LIST_FAILURE:  'GUEST_NOTIFICATION_LIST_FAILURE',
  
  
  GUEST_NOTIFICATION_UPDATE_INITIATE: 'GUEST_NOTIFICATION_UPDATE_INITIATE',
  GUEST_NOTIFICATION_UPDATE_SUCCESS:  'GUEST_NOTIFICATION_UPDATE_SUCCESS',
  GUEST_NOTIFICATION_UPDATE_FAILURE:  'GUEST_NOTIFICATION_UPDATE_FAILURE',
  
  
  
  
}
